export const filters	= [
	{ 	
		name	: 'availability',
		type	: 'dropdown_calendar', 
		position: 'left',
		label	: '_AVAILABILITY',
		items	: [
			{ label: "calendar1", 			value: "calendar2" }
		],
		showWeek: true,
		selected: undefined
	},
	// { 	
	// 	name	: 'duration',
	// 	type	: 'dropdown',
	// 	multiple: true,
	// 	position: 'left',
	// 	label	: '_DURATION',
	// 	items	: [
	// 		{ label: "_4_HOURS", 			value: "4" },
	// 		{ label: "_6_HOURS", 			value: "6" },
	// 		{ label: "_8_HOURS", 			value: "8" }
	// 	],
	// 	selected: [ 4, 6, 8 ]
	// },	
	{ 	
		name	: 'sort',
		type	: 'dropdown',
		position: 'left', 
		label	: '_SORTBY',
		items	: [
			{ label: "_MOST_RELEVANT", 		value: "most_relevant" 	},
			{ label: "_MOST_POPULAR", 		value: "most_popular" 	},
			{ label: "_BEST_REVIEWED", 		value: "best_reviewed" 	},
			{ label: "_PRICE_LOW_TO_HIGH",	value: "price" 			},
			{ label: "_RECENTLY_ADDED", 	value: "recently_added"	}
		],
		selected: "most_relevant"
	}
];