import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild,
         ChangeDetectorRef,
         ElementRef,
		 ViewEncapsulation	} from '@angular/core';
import * as moment			  from 'moment';
import { extendMoment 		} from 'moment-range';
import { CommonsService 	} from 'src/app/demo/service/commons.service';

@Component({
  selector        	: 'app-tours-period-table',
  templateUrl     	: './tours-period-table.component.html',
  styleUrls       	: ['./tours-period-table.component.scss'],
  encapsulation		: ViewEncapsulation.None

})

export class ToursPeriodTableComponent implements OnInit {
    
    @ViewChild('lastRowEnd')    lastRowEnd      :   any;
    @ViewChild('lastRowStart')  lastRowStart    :   any;
    @ViewChild('calendarStart') calendarStart   :   any;
	@ViewChild('calendarEnd')   calendarEnd     :   any;
	
    @Input() tablePeriod                        :   any[]               = [];
    @Output() emiterOption	                    :   EventEmitter<any>   = new EventEmitter();

	pageInfo    								:   any = {}
	invalidDates                                :   Array<Date>;
    
	constructor(
		private changeDetector 	: ChangeDetectorRef, 
		private commons 		: CommonsService 
	){}
	
    ngOnInit(): void {
        this.invalidDates       =   this.checkDisabledDates();
		this.pageInfo			= 	{
			templateStartDate   :   '',
	        templateEndDate     :   '',
	        disabledDates       :   [],
	        lastRow             :   { start: '', end: '', title: null },
			tmpData				: 	null
	    }
    }

    toggleEditableRow(row){
        const tmp    = row.editMode ? !row.editMode : true;
        this.tablePeriod.forEach(el => el.editMode = false);
        row.editMode = tmp; 
        if(row.editMode){
            this.pageInfo.templateStartDate =   new Date(row.start);
            this.pageInfo.templateEndDate   =   new Date(row.end);
			this.pageInfo.titlePeriod       =   row.title || null;
			
            this.pageInfo.tmpData           =   {
                templateStartDate   :   JSON.parse(JSON.stringify(this.pageInfo.templateStartDate)),
                templateEndDate     :   JSON.parse(JSON.stringify(this.pageInfo.templateEndDate)),
                titlePeriod         :   JSON.parse(JSON.stringify(this.pageInfo.titlePeriod))
            }
        }else{
            row.start   			=   this.pageInfo.tmpData.templateStartDate;
            row.end     			=   this.pageInfo.tmpData.templateEndDate;
            row.title   			=   this.pageInfo.tmpData.titlePeriod;
            this.pageInfo.tmpData 	= 	null;
            // if(!this.commons.checkCompatibilityPeriodsSchedule({start: row.start, end: row.end}, this.tablePeriod)){
			// 	const badPeriod	=	this.tablePeriod.findIndex(el => el.id == row.id);
			// 	if(badPeriod > -1){
			// 		this.tablePeriod.splice(badPeriod,1);					
			// 	}
            // }
        }
	}	
	
    stopProp($event : MouseEvent)		{
									        this.changeDetector.detectChanges();
									        $event.stopImmediatePropagation();
									        $event.stopPropagation();
    									}

    selectRow(period)					{ 	
											const tmp       =   period.selected ? !period.selected : true;
	        								this.tablePeriod.map(el  => el.selected = false);
	        								period.selected = tmp;
    									}

	// PERIOD MANAGEMENT ----------------------------------------------------------------

    savePeriod(period)					{		
											if(!this.pageInfo.tmpData){ return false;}
											period.start    = this.pageInfo.templateStartDate;
									        period.end      = this.pageInfo.templateEndDate;
									        period.title    = this.pageInfo.titlePeriod;
									        if(!this.commons.isValidDate(period.start) || !this.commons.isValidDate(period.end)){
												 return this.commons.generateToast('_ERROR','_BAD_DATE_FORMAT','error'); 
											}
									        period.editMode = false;
									        this.tablePeriod.map(el => el.selected = false);
									        // period.selected = true;
									        this.handleOption({type: 'update', data : period});
										}
	addPeriod()							{	
											this.checkSomeInvalid();
									        this.handleOption({type:'add'});
									        this.tablePeriod.map(el => el.selected = false);
									        this.tablePeriod.map(el => el.editMode = false);
									        this.tablePeriod[this.tablePeriod.length-1].editMode = true;
									        this.pageInfo.templateStartDate =   null;
									        this.pageInfo.templateEndDate   =   null;
									        this.invalidDates = this.checkDisabledDates();
    									}
	deletePeriod(period)				{ 		this.handleOption({type:'delete', data: period})					}
	selectPeriodDate($event,type,period){
								        	console.log(this.lastRowEnd);
								        	switch(type){
									            case 'start':   this.pageInfo.templateStartDate = $event; console.log(this.pageInfo.templateStartDate); 
																this.calendarEnd.overlayVisible = true;
									                            // this.lastRowEnd.overlayVisible	= false;    
									                            break;
												case 'end'  :   this.pageInfo.templateEndDate   = $event; console.log(this.pageInfo.templateEndDate); 
									                            this.savePeriod(period);							
									                            break;
									        }  
    									}

    selectPeriodDateLastRow($event,type){
									        console.log(this.lastRowEnd);
									        switch(type){
									            case 'start':   this.pageInfo.lastRow.start     = $event; console.log(this.pageInfo.templateStartDate);
																this.lastRowEnd.overlayVisible  = true;
									                            // this.calendarEnd.overlayVisible = false; 
									                            break;
												case 'end'  :   this.pageInfo.lastRow.end       = $event; console.log(this.pageInfo.templateEndDate);	
									                            this.saveLastRow();						 
									                            break;
									        }
										}

	handleOption($event)				{ this.emiterOption.emit($event); 											}
	
	// DATES MANAGEMENT -----------------------------------------------------------------

    getFormatDate(date)					{	if(date == null){return '-'}; return this.commons.getFormatDate(date);	}
    checkDisabledDates()				{
									        const extendedMoment 	= 	extendMoment(moment);
											const validPeriods      =   this.tablePeriod.filter(el =>   
																			( el.start != null && el.end != null) 
																			&& ( this.commons.isValidDate(el.start) && this.commons.isValidDate(el.end )
																			&& ( el.start != this.pageInfo.templateStartDate && el.end != this.pageInfo.templateEndDate ))
																		);
									        let   disabledDates     =   [];
									        const currentPeriodSelected = this.tablePeriod.find(el => el.editMode);
									        validPeriods.forEach(period=>{
									            if(!(currentPeriodSelected && period.id == currentPeriodSelected.id)){
													const rangeDays     =   Array.from(extendedMoment().range(period.start, period.end).by('days'))
																				.map(date => moment(date.toDate()).toDate());
									                disabledDates       =   disabledDates.concat(rangeDays);
									            }
									            
									        });
									        return Array.from(new Set(disabledDates));
    									}

    checkSomeInvalid()					{
									        const currentPeriod = this.tablePeriod.findIndex(el => el.editMode);
									        if(currentPeriod > -1){
									            if(!this.commons.checkCompatibilityPeriodsSchedule(
													{
														start	: this.tablePeriod[currentPeriod].start, 
														end		: this.tablePeriod[currentPeriod].end
													}, this.tablePeriod
												)){
									                this.tablePeriod.splice(currentPeriod,1);
									            }
									        }
										}
	
	onFocusCalendar()					{ this.invalidDates = this.checkDisabledDates(); 						}
	
	// LAST ROW FNs ------------------------------------------------------------------------------------

	checkValidLastRow()					{ 	return !(this.pageInfo.lastRow.start && this.pageInfo.lastRow.end);	}
	restartLastRow()					{ 	this.pageInfo.lastRow = { start: null, end: null, title: null }		}
	saveLastRow()						{
									        if(!this.commons.checkCompatibilityPeriodsSchedule(
												{
													start	: this.pageInfo.lastRow.start,
													end		: this.pageInfo.lastRow.end
												},
												this.tablePeriod
											)){
									           return this.commons.generateToast('_ERROR','_BAD_PERIOD','error');
									        }
									        this.addLastRowToPeriods()
										}
	addLastRowToPeriods()				{
									        if(!this.commons.isValidDate(this.pageInfo.lastRow.start) || !this.commons.isValidDate(this.pageInfo.lastRow.end)){
												return this.commons.generateToast('_ERROR','_BAD_DATE_FORMAT','error');
											}
									        this.pageInfo.lastRow.start     =   moment(this.pageInfo.lastRow.start) .format();
									        this.pageInfo.lastRow.end       =   moment(this.pageInfo.lastRow.end)   .format();
									        this.pageInfo.lastRow.selected  =   false;
									        this.pageInfo.lastRow.editMode  =   false;
									        this.handleOption({type: 'lastRow', data : this.pageInfo.lastRow});
									        this.restartLastRow();
									        // this.invalidDates   =   this.checkDisabledDates();
    									}
}