import { Http			 						} from "@angular/http";
import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation, 
		NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { ConfirmationService 					} from 'primeng/primeng';
import { FirebaseService 						} from './../../../service/database/firebase.service';
import { StorageService 						} from './../../../service/storageservice';
import { entities								} from './data/items';

@Component({
	selector    	: 'app-company-fleet',
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class CompanyFleetComponent implements OnInit
{
    locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private http 			: Http,
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private confirmCtrl     : ConfirmationService
	)					{ this.init(); 					}
    async ngOnInit() 	{ await this.loadEntities();	}
    async init()		{
		//
		this.pageInfo.dataLoaded      	= false;
		this.pageInfo.header			= { height: 64, img: "/assets/layout/images/img/buses1.png" };
		this.userInfo					= ( this.storage.getItem('dmcSuite') || {} ).userInfo || {}; 
		this.pageInfo.i18n				= this.commons.getAllTranslates();
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_TYPES'		),	icon : 'fa fa-list'	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:1, label: this.commons.getTranslate('_VEHICLES' 	), 	icon : 'fa fa-bus' 	, command: ($event)=> { this.setTabItem('main',$event)} 	}					
				],
				selected		: 0
			}
		};		
		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
        };
		this.pageInfo.entities					= { types: {}, vehicles: {}};

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);			
		});

		console.log("ENTITIES",this.pageInfo.entities);

		this.pageInfo.entities["types"].table	= {
			ready	: false,
			cols	: [
				{ field: 'active',     		header: '',			    	width: '20px', 	type: 'check', renderer: 'check', align: 'center'		},
				{ field: 'id',     			header: '_ID', 		    	width: '50px', 	align: 'center'		},
				{ field: 'name',     		header: '_NAME', 	    	width: '200px', align: 'center'		},
				{ field: 'code',			header: '_CODE',		  	width: '50px', 	align: 'center'		},
				{ field: 'seats',			header: '_SEATS',  			width: '50px', 	align: 'center'		}
			],
			options	: { rowExpander: false, scrollHeight: '70vh' },
			filters	: []
		};
		this.pageInfo.entities["types"].form 	= { 
			ready		: false,
			entities	: this.pageInfo.entities,			
			form		: [
				{
					title		: "_INFO",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'active', 			label:'_ACTIVE',			editor	: 'toggle',			editable	: true						},
						{ mandatory: true, 	field	: 'type',				label:'_TYPE',				type	: 'text',			editable	: false,	legend: false	},
						{ mandatory: true, 	field	: 'code', 				label:'_CODE',				type	: 'text',			editable	: true						},
						{ mandatory: true, 	field	: 'category',			label:'_CATEGORY',			editor	: 'autocomplete', 	entityList	: 'categoryList' 			},
						{ mandatory: true, 	field	: 'seats_down',			label:'_SEATS_DOWN',		editor	: 'number',			editable	: true						},
						{ mandatory: true, 	field	: 'seats_up',			label:'_SEATS_UP',			editor	: 'number',			editable	: true						},

						{ mandatory: true, 	field	: 'cost_km',			label:'_COST_KM',			editor	: 'number',			editable	: true						},
						{ mandatory: true, 	field	: 'cost_maintenance',	label:'_COST_MAINTENANCE',	editor	: 'number',			editable	: true						}
					]
				},	
				{
					title		: "_DESCRIPTION",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ 	mandatory	: true, 	
							field		: 'description',	
							label		:'_DESCRIPTION',
							editor		: 'textarea',		
							editable	: true, 	
							height		: '17rem',
							// header		: 'normal'	// full, false
						}
					]
				},			
				{
					title		: "_SERVICES",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'adapted',				label:'_ADAPTED',				editor: 'toggle',	editable	: true			},
						{ mandatory: true, 	field	: 'service_wifi', 			label:'_SERVICE_WIFI',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_bath', 			label:'_SERVICE_BATH',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_coffee', 		label:'_SERVICE_COFFEE',		editor: 'toggle',	editable	: true			},
						{ mandatory: true, 	field	: 'service_tinted_glass',	label:'_SERVICE_TINTED_GLASS',	editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_dvd', 			label:'_SERVICE_DVD',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_micro',			label:'_SERVICE_MICRO',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_platform',		label:'_SERVICE_PLATFORM',		editor: "toggle",	editable	: false			}						
					]
				}
			],
			buttons	:	[
				{ id: 1, name: 'save', label: '_SAVE', action: 'save'	}
			]
		};

		this.pageInfo.entities["vehicles"].table	= {
			ready	: false,
			cols	: [
				{ field: 'active',     		header: '',			    	width: '20px', 	type: 'check', renderer: 'check', align: 'center'		},
				{ field: 'id',     			header: '_ID', 		    	width: '50px', 	align: 'center'		},
				{ field: 'name',     		header: '_NAME', 	    	width: '200px', align: 'center'		},
				{ field: 'code',			header: '_CODE',		  	width: '50px', 	align: 'center'		},
				{ field: 'seats',			header: '_SEATS',  			width: '50px', 	align: 'center'		}
			],
			options	: { rowExpander: false, scrollHeight: '70vh' },
			filters	: []
		};
		this.pageInfo.entities["vehicles"].form 	= { 
			ready		: false,
			entities	: this.pageInfo.entities,			
			form		: [
				{
					title		: "_INFO",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'id', 					label:'_ID',					type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'active', 				label:'_ACTIVE',				editor	: 'toggle',			editable	: true				},
						{ mandatory: true, 	field	: 'type',					label:'_TYPE',					editor	: 'autocomplete', 	entityList	: 'vehicleTypeList' },
						{ mandatory: true, 	field	: 'own_property',			label:'_OWN',					editor	: 'toggle',			editable	: true				},
						{ mandatory: true, 	field	: 'plate', 					label:'_CODE',					type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'model',					label:'_SEATS',					type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'description',			label:'_DESCRIPTION',			type	: 'text',			editable	: true				},						
						{ mandatory: true, 	field	: 'seats_down',				label:'_SEATS_DOWN',			type	: 'number',			editable	: true				},
						{ mandatory: true, 	field	: 'seats_up',				label:'_SEATS_UP',				type	: 'number',			editable	: true				},
						{ mandatory: true, 	field	: 'driver',					label:'_DRIVER',				type	: 'text',			editable	: true				}
						
					]
				},				
				{
					title		: "_MORE_INFO",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'card',					label:'_CARD_NUMBER',			type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'card_type', 				label:'_CARD_TYPE',				type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'card_period', 			label:'_CARD_PERIOD',			type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'chassis',				label:'_CHASSIS',				type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'height', 				label:'_HEIGHT',				type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'width',					label:'_WIDTH',					type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'work_number',			label:'_WORK_NUMBER',			type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'first_enrollment',		label:'_FIRST_ENROLLMENT',		type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'current_enrollment',		label:'_CURRENT_ENROLLMENT',	type	: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'chassis_warranty_end',	label:'_CHASSIS_WARRANTY_END',	type	: 'text',			editable	: true				}						
					]
				},
				{
					// clear		: true,
					title		: "_SERVICES",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'service_wifi', 			label:'_SERVICE_WIFI',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_bath', 			label:'_SERVICE_BATH',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_coffee', 		label:'_SERVICE_COFFEE',		editor: 'toggle',	editable	: true			},
						{ mandatory: true, 	field	: 'service_tinted_glass',	label:'_SERVICE_TINTED_GLASS',	editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_dvd', 			label:'_SERVICE_DVD',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_micro',			label:'_SERVICE_MICRO',			editor: "toggle",	editable	: false			},
						{ mandatory: true, 	field	: 'service_platform',		label:'_SERVICE_PLATFORM',		editor: "toggle",	editable	: false			}						
					]
				},
				{
					title		: "_VEHICLE_INSPECTION",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'itv_first_revision', label:'_FIRST_REVISION',	type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'itv_last_revision', 	label:'_LAST_REVISION',		type	: 'text',			editable	: false				},						
					]
				},
				{
					title		: "_TACOGRAPH",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'taco_first_revision', 	label:'_FIRST_REVISION',		type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'taco_last_revision', 	label:'_LAST_REVISION',			type	: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'taco_last_download',		label:'_LAST_DOWNLOAD',			type	: 'text',			editable	: false				}						
					]
				},
				{
					title		: "_NOTES",
					cols		: 3,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'notes', 				label:'_NOTES',				type	: 'textarea',		editable	: false				}
					]
				},
			]
		};
	}				

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: 	return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	:	return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "types"	:	return (this.pageInfo.entities[$entity].data||[]).map(item=>{ item.url="/assets/layout/images/fleet/"+item.type+".png"; return item; });
			case "vehicles"	:	return (this.pageInfo.entities[$entity].data||[]).filter(item=>{ return true; });
		}
	}
	selectItem($type,$item){
		switch($type){
			case "type":
				this.pageInfo.entities[$type].selected = (this.pageInfo.entities[$type].selected===$item)?undefined:$item;
				break;
		}
	}
	async loadEntities(){	this.load("types");
							this.load("vehicles");
							this.pageInfo.dataLoaded = true;
						}
    async load($entity)	{
		let response;
        switch($entity){
			case "types"	:	response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
								this.pageInfo.entities[$entity].data		= 	response["data"];
								this.pageInfo.entities["vehicleTypeList"]	= 	response["data"];
								this.pageInfo.entities[$entity].count 		= 	response["data"] ? response["data"].length : 0;
								this.paginate($entity, null);

								this.pageInfo.entities[$entity].table.ready	=	true;				
								// this.pageInfo.entities[$entity].form.info	= 	response["data"];
								this.pageInfo.entities[$entity].form.ready	=	true;		
								break;

			case "vehicles"	:	response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
								this.pageInfo.entities[$entity].data		= 	response["data"];
								this.pageInfo.entities[$entity].count 		= 	response["data"] ? response["data"].length : 0;
								this.paginate($entity, null);
								this.pageInfo.entities[$entity].table.ready	=	true;				
								this.pageInfo.entities[$entity].form.info	= 	response["data"];
								this.pageInfo.entities[$entity].form.ready	=	true;		
								break;
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length 								= !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
	}

	action($type,$event){
		switch($type){
			case "services"		: 	switch($event.action){
										case "provider_unselected"	:	this.pageInfo.currentProvider	= undefined;		break;
										case "provider_selected"	:	this.pageInfo.currentProvider	= $event["item"];	break;
										case "view_provider"		: 	if(undefined==this.pageInfo.currentProvider){
																			this.commons.generateToast("_ERROR","_PROVIDER_NOT_SELECTED","error");
																			return false;
																		}
																		this.pageInfo.status.providers 		= "providerDetail"; 	
																		break;
										case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
									}
									break;			

			case "conciliation"	: 	this.pageInfo.status.providers 		= "providerList"; 		break;		
		}
	}
}