import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';


@Component({
    selector   : 'app-marker-lodging-route',
    templateUrl: './markerLodgingRoute.component.html',
    styleUrls:['./markerLodgingRoute.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class MarkerLodgingRouteComponent implements OnInit{

    @Input() item   : any;
    
    ngOnInit(){
    }
}