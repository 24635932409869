import { FirebaseService 			} from '../database/firebase.service';
import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore			} from '@angular/fire/firestore';
import { ignoreElements } from 'rxjs/operators';

@Injectable()
export class TransportTransporterService {		
  	constructor(  
		private afs     	: AngularFirestore,
		private commons 	: CommonsService,
		private firebaseCtrl: FirebaseService
	){}
	
	/**
	 * Get transporters from DMC given a destination
	 * @param dmcId 
	 * @param destinationId 
	 */
	async getTransporters(dmcId,destinationId){	
		let response = {};
		try			{	
			let transporters	=	await this.afs.collection('dmcs/'+dmcId+'/destinations/'+destinationId+"/transporters").ref.get();
			response			=	{ 	success : 	true, 
										data 	: 	transporters.docs.map( transporter => { 
														return { ref : transporter.ref, ...transporter.data() }
													})
									}
		} catch(e)	{	
			response			= { success : false }																			 
		}
		return response;
	}

	/**
	 * saveTransporter create new transporter if does not exist
	 * and link it to destination structure and dmc destination
	 */
	async saveTransporter($info) {
		let $transporter	=	$info["transporter"		];
		let $dmc			=	$info["dmc"				];
		let $destination	=	$info["destination"		];
		let $country		=	$destination["country"	];
		let colPath;
		let currentId;
		let transporterDoc;

		try {
			if(!$transporter){
				this.commons.generateToastError("_TRANSPORTER_DOES_NOT_EXIST");
				return false;
			}

			// 1. Create GLOBAL transporter or update it
			colPath				=	'/transporters';

			let transporterInfo:any	= {
				id			:	$transporter.id,
				name		:	$transporter.name,
				api_code	:	$transporter.api_code || $transporter.name,
				has_api		: 	$transporter.has_api?true:false,
				to			:	$transporter.has_api?"api":"external"
			};
			
			if($transporter.transporter_ref){
				// Update transporter
				transporterDoc	=	await this.afs.doc($transporter.transporter_ref);
				transporterDoc.update(transporterInfo);				
			} else {
				// Add new transporter
				["destination","country","id_remote"].forEach(field=>{
					if($transporter[field]){
						transporterInfo[field] = $transporter[field];
					}
				});
				transporterDoc 		= 	await this.afs.collection(colPath).add(transporterInfo);
				transporterDoc.update({ id: transporterDoc.id });
				transporterInfo.transporter_ref = colPath+"/"+transporterDoc.id;
			}
			
			// 2. Link to destination structure
			colPath				=	'/countries/'+$country+'/destinations/'+$destination.id;
			let destinationDoc	=	await this.afs.doc(colPath).ref.get();
			if(destinationDoc.exists){
				let destinationData 	= destinationDoc.data();
				let currentTransporters	= destinationData["transporters"] || [];
				// Avoid duplicateds
				currentTransporters		= [...currentTransporters.filter(item=>item!=transporterInfo.id), transporterInfo.id];
				destinationDoc.ref.update({ transporters: currentTransporters });
			}

			// Link to dmc destination
			colPath				=	'/dmcs/'+$dmc.id+'/destinations/'+$destination.id+'/transporters';
			let transportersCol	=	this.afs.collection(colPath);
			let transporters	=	await transportersCol.ref.get();
			let transporter		=	transporters.docs.find(el => el.id == $transporter.id);
			
			if(transporter)	{	
				transportersCol.doc(transporter.id).update(transporterInfo);
			} else			{	
				let dmcTransporter	=	await transportersCol.add(transporterInfo);
				await dmcTransporter.update({ id : dmcTransporter.id });
			}
		} catch(e){
			this.commons.generateToastError("_COULD_NOT_SAVE_TRANSPORTER");
			console.log("[saveTransporter]",e);
			return false;
		}
	}

	async deleteTransporter(transporter, dmcId,destinationId)		{	
		if(!transporter.id){ return this.commons.generateToast('_ERROR','_NO_TRANSPORTER_FOUND','error');}
		let path = this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('transporters');
		await path.doc(transporter.id).delete()
	}

	/**
	 * Get the docs typesFleet and its info from transpoter document accessing by reference
	 * 
	 * @param infoTransporter data from transporter. Get ref to access
	 */
	async getTypeFleetTransporter(infoTransporter)					{	
		try					{	let types = await this.afs.doc(infoTransporter.ref).collection('types').ref.get();
								return { success : true, data : types.docs.map(item => item.data())}
		} catch(e)			{	return { success : false}	}																		
	}

	async saveFleetExternalTransporter(infoTransporter, dmcId, destinationId){
		console.log(infoTransporter);
		let transporter				=	infoTransporter['transporter'] ? infoTransporter['transporter'] : infoTransporter;
		let fleet					=	infoTransporter.fleet;																
		let pathTransporter			=	await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('transporters');
		let transporterFound		=	await pathTransporter.doc(transporter.id).ref.get();

		if(transporterFound.exists)	{	await pathTransporter.doc(transporter.id).collection('types').doc(String(fleet.typeId).toString()).set(fleet);	}
		else						{	await this.saveTransporter({	
											transporter	: transporter, 
											dmc			: this.commons.pageInfo.dmcSelected,
											destination	: this.commons.pageInfo.destinationSelected
										});
										await pathTransporter.doc(transporter.id).collection('types').doc(String(fleet.typeId).toString()).set(fleet);
									}
		console.log(transporter, fleet);
	}

	async deleteFleetExternalTransporter(infoTransporter, dmcId, destinationId){
		try				{	let transporter		=	infoTransporter.transporter;
							let fleet			=	infoTransporter.fleet;
					
							let transporterPath	=	this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('transporters');
							await transporterPath.doc(transporter.id).collection('types').doc(String(fleet.typeId).toString()).delete();	
							return { success : true}
		} catch(e)		{	console.log(e); return { success : false }	}
	}

	generateTransporterCalendar($params)							{	
		const routes 	=	this.afs.collection('dmcs')
									.doc($params["dmcId"])
									.collection('destinations')
									.doc($params["destinationId"])
									.collection('transporters')
									.doc($params["transporterId"])
									.collection("requests");																		
		return routes.doc($params["date"]).set($params["info"]);
	}	
}