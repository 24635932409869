import { DestinationsService                    } from './../../service/database/destinations.service';
import { FirebaseService                        } from './../../service/database/firebase.service';
import { CommonsService                         } from './../../service/commons.service';
import { StorageService                         } from './../../service/storageservice';
import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef   } from '@angular/core';
import { filters 								} from './data/filters';
import { EntityService                          } from '../../service/entity.service';
import { MapService                             } from '../../service/mapservice';
import { ToastrService                          } from 'ngx-toastr';
import { ConfirmationService                    } from 'primeng/api';


@Component({
    selector    	: 'app-map-create-zones',
    styleUrls		: ['./mapconfig.component.scss'],
	templateUrl		: './mapconfig.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class MapConfigComponent implements OnInit{

    @Input() fromWizard :   any;
    @Input() dmc        :   any;
    @Input() destination:   any;
    @Input() tmpData    :   any;
    @Input() mode       :   any;

    @ViewChild('inputNameZone') inputNameZone   : ElementRef; 
    pageInfo : any   = {
        zones           : [],
        currentZones    : [],
        legendZones     : [],
        createZone      : false,
        nameZone        : null,
        gifs            : {
            tutorial    :   'assets/demo/gifs/maps/gifCreateZones.gif'
        }
        
    };
    userInfo    :   any =   {};

    areasDestination    :   any     = {};
    zoom                :   number  = 10;
    lat                 :   number  = 39.638464;
    lng                 :   number  = 3.003831 ;
    
    constructor
    (
        private entityService	    : EntityService,
        private mapService 		    : MapService,
        private storageService      : StorageService,
        private commons             : CommonsService,
		private confirmationService : ConfirmationService,
        private firebaseCtrl		: FirebaseService,
        private destinationCtrl     : DestinationsService,
        private storage             : StorageService
    )
    {
    }
    
    async ngOnInit(){
        this.pageInfo.loading   =   true;

        this.userInfo	        =	( this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
        this.pageInfo.filters   =   filters.map(filter => { return {...filter, label :  this.commons.getTranslate(filter.label)}});
        // await this.loadEntities();
        // this.initMap();
        if(this.tmpData){
            this.pageInfo.listAreas                                         =   this.tmpData.listAreas;
            this.pageInfo.zones                                             =   this.tmpData.zones;
            this.pageInfo.filters.find(el => el.entity == 'zones').items    =   this.tmpData.zonesItems;
            this.pageInfo.mapConfig                                         =   this.tmpData.mapConfig;

            this.initMapConfig(this.pageInfo.mapConfig);
            this.paintCreatedsAreas();
        }else{
            await this.initAreasFromDestination();
        }
        
        this.pageInfo.unselectedHelp    =   false;
        this.pageInfo.loading           =   false;
        
        console.log('DATA DESTINATION', this.pageInfo.listAreas);
    }
    
    updateCenterChange($event){
        this.pageInfo.centerMap = $event;
    }
    /**
     * Get the areas from the destination, it can be loaded from the wizard form creation, or well from the dmc's config.
     */
    async initAreasFromDestination(){
        try{
            let areasFromDestination    :   any;
            if(this.fromWizard){
                if(this.destination.zones){
                    this.pageInfo.zones =   await Promise.all(this.destination.zones.map(async zone => {
                        return {...zone, areas	:	await this.firebaseCtrl.getInfoAreas(zone.refAreas)}
                    }));
                }
                this.pageInfo.filters.find(el => el.entity == 'zones').items = this.pageInfo.zones.map(zone => {return { label : zone.name, value: zone.name, id: zone.id}}) 
                this.pageInfo.listAreas = await this.destinationCtrl.getAreasFromDestination(this.destination.refAreas);
                this.pageInfo.listAreas.map(el => el.color = '#6495ED');    
                this.initMapConfig(this.destination.mapConfig || null);        
                this.paintCreatedsAreas();
                this.pageInfo.zones.length == 0 ? this.modeCreateZone() : null;
            }else{   
               this.initMap();
            }
            return areasFromDestination;
        }catch(e){
            console.error(e);
        }
    }
  
    initMapConfig(mapConfig?){
        console.log('MAP CONFIG FROM DESTINATION', mapConfig);
        this.pageInfo.mapConfig = {
            lat     :   mapConfig ? mapConfig.lat : this.pageInfo.listAreas[Math.ceil(this.pageInfo.listAreas.length/2)].coords[0].lat,
            lng     :   mapConfig ? mapConfig.lng : this.pageInfo.listAreas[Math.ceil(this.pageInfo.listAreas.length/2)].coords[0].lng,
            zoom    :   mapConfig ? mapConfig.zoom: 9
        }

        if(!this.fromWizard){
            console.log('get data from dmc');
        }
    }

    initMap(){
		this.firebaseCtrl.subscribeMainInfoDestinationByDmc(this.userInfo.idDmc,this.userInfo.currentDestination.id || '1').valueChanges().subscribe(async (data : any) =>{
			this.pageInfo.zones = data.zones || [];
			this.pageInfo.filters.find(el => el.entity == 'zones').items = [];
			this.pageInfo.zones = await Promise.all(data.zones.map(async zone => {
				return {
					id		:	zone.id,
                    name	:	zone.name,
                    refAreas:   zone.refAreas,
					areas	:	await this.firebaseCtrl.getInfoAreas(zone.refAreas)
				}
			}));
			this.pageInfo.filters.find(el => el.entity == 'zones').items = this.pageInfo.zones.map(zone => {return { label : zone.name, value: zone.name, id: zone.id}}) 
            let infoDestination : any   =   await this.firebaseCtrl.getDataByRef(data.refDestination);
            this.pageInfo.listAreas     =   await this.destinationCtrl.getAreasFromDestination(infoDestination.refAreas);
            console.log('restarting areas color');
            this.pageInfo.listAreas.forEach(area => area.color = '#6495ed');
            this.paintCreatedsAreas();
            this.initMapConfig(infoDestination.mapConfig ||  null);        
		});
        // this.pageInfo.filters.find(el => el.entity == 'zones').items    = this.storageService.getItem('filtersZones') || [];
       
        console.log(this.pageInfo.zones);
    }
    async loadEntities() {
		await this.load('areasDestination');  
    }
    
    async load($entity){
        switch ($entity)
        {
			case 'areasDestination':
			const areas = await this.firebaseCtrl.getAreasFromDestination('1');
			this[$entity].data = [];
			areas.forEach(data =>{
				if(!data.exists) return;
				this[$entity].data.push({ref: data.ref,...data.data()});
			})
			// await this.entityService.loadEntity($entity);
            // this[$entity].data = this.entityService.get($entity);

			// this[$entity].data.forEach(async area =>{
			// 	delete area.color;
			// 	await this.firebaseCtrl.addAreas(area);
			// 	console.log('added', area);
			// })
            this[$entity].data.map(el => el.color = '#6495ED' );
        }
    }

 


    modeCreateZone(){
        if(this.pageInfo.filters.some(el => el.selected['value'])){
            this.restartOptions()
        }else{
            this.pageInfo.createZone    = this.pageInfo.createZone  ? !this.pageInfo.createZone     : true;
            this.pageInfo.editZone      = this.pageInfo.editZone    ? !this.pageInfo.editZone       : false;
        }
        this.restartItems();
        this.paintCreatedsAreas();    
        this.pageInfo.filters.map(el => el.selected = []);

        if(this.pageInfo.createZone || this.pageInfo.editZone){
            setTimeout(()=>{this.inputNameZone.nativeElement.focus();})
        }
        console.log(this.pageInfo.createZone, this.pageInfo.editZone);
        console.log('Zones Destination',this.pageInfo.listAreas);
    }
    paintCreatedsAreas(currentZone?){
        this.pageInfo.legendZones = [];
        this.pageInfo.filters.forEach(zone =>{
            zone.items.forEach((area,index) =>{
                let findedArea = this.pageInfo.zones.find(el => el.name == area.value);
                if(findedArea){
                    let colorArea = (currentZone || {}).id == findedArea.id ? 'red' : this.mapService.getColor(index);
                    this.pageInfo.legendZones.push({name : findedArea.name, color : colorArea});
                    
                    findedArea.areas.forEach(el =>
                        {
                            this.pageInfo.listAreas.filter(subArea => subArea.properties.name == el.properties.name)
                                                    .map(areaFiltered => areaFiltered.color = colorArea);
                        })
                    }
                })
            })
    }

    getColorZone(zone){ return this.pageInfo.legendZones ? (this.pageInfo.legendZones.find(item => item.name == zone.value) || {}).color || 'black'
                                                         : 'black' }

    modeEditZone(){
        this.pageInfo.createZone    = false;
        this.pageInfo.editZone      = true;
    }

    getTotalAreasUnassigneds(){
        let areasAssigneds      = [].concat.apply([],this.pageInfo.zones.map(el => el.areas));
        let areasUnassigneds    = this.pageInfo.listAreas.filter(el => !areasAssigneds.find(area => el.properties.name == area.properties.name));
        return areasUnassigneds.length;
    }
    
    async fillRestZones(){
        let areasAssigneds      = [].concat.apply([],this.pageInfo.zones.map(el => el.areas));
        let areasUnassigneds    = this.pageInfo.listAreas.filter(el => !areasAssigneds.find(area => el.properties.name == area.properties.name));
        let refAreas            = areasUnassigneds.map(el => el.ref);
        let zoneAlreadyExists   = this.pageInfo.zones.findIndex(zone => zone.id == this.pageInfo.currentZoneId);
        if(refAreas.length == 0){
            return this.commons.generateToast('_OPTION_NOT_POSSIBLE', '_NO_EMPTY_AREAS', 'info');
        }

        if(zoneAlreadyExists > -1){

            const concatenatedRefAreas = this.pageInfo.zones[zoneAlreadyExists].refAreas.concat(refAreas);
            this.pageInfo.zones[zoneAlreadyExists].refAreas = concatenatedRefAreas;
            
            const concatenatedAreas =   this.pageInfo.zones[zoneAlreadyExists].areas.concat(areasUnassigneds);
            this.pageInfo.zones[zoneAlreadyExists].areas    =   concatenatedAreas;  

        }else{
            
            let newZone = {
                refAreas: areasUnassigneds.map(el => el.ref),
                areas   : areasUnassigneds,
                name    : this.pageInfo.nameZone,
                id      : Math.random().toString(36).substring(7)
            }
            this.pageInfo.zones.push(newZone);
        }

        if((!this.fromWizard || this.mode == 'edit') && (!this.destination.isNewDestination)){
            let infoEntities = this.mountEntities();
            await this.firebaseCtrl.updateZoneDestination(infoEntities.dmc,infoEntities.destination,this.cleanZones());
            this.mode == 'edit' ? null : this.restartItems();
        }
        this.updateZonesFilters();
        this.commons.generateToast('_SUCCESS','_MAP_COMPLETED','success');
        this.restartOptions();
        this.paintCreatedsAreas();
    }

    //IF edit on zone, must check if is the current zone. If it is, then splice 
    toggleArea(area){
        
        let findedArea      = this.pageInfo.zones.find(zone => zone.areas.find(el => el.properties.name == area.properties.name));
        
        if(!this.pageInfo.createZone && !this.pageInfo.editZone && findedArea){
            this.pageInfo.filters.find(el => el.entity == 'zones').selected = {value : findedArea.name};
            this.displayZone({selected : findedArea.name});
            return;     
        }
        
        let areaSelected    = this.pageInfo.filters.find(el => el.entity == 'zones').selected;
        
        if(!findedArea){
            this.pageInfo.createZone    =   true;
            area.color      = area.color == '#6495ED' ? '#FFB6C1' : '#6495ED';
            let findedArea  = ( this.pageInfo.currentZones || [] ).findIndex(el => el.properties.name == area.properties.name);
            if(findedArea > -1)	{   this.pageInfo.currentZones.splice(findedArea,1);		}
            else            	{   this.pageInfo.currentZones = this.pageInfo.currentZones || [];
                                    this.pageInfo.currentZones.push(area);				}
        } else {
            let colorArea       = findedArea.areas[0].color;
            if(findedArea.name == areaSelected.value){
                let zoneExists = findedArea.areas.findIndex(el => el.properties.name == area.properties.name);
                if(zoneExists > -1){
                    area.color  = '#6495ED'; 
                    findedArea.areas.splice(zoneExists,1);
                }else{
                    area.color  = colorArea;
                    findedArea.areas.push(area);
                }
            }else{
                this.commons.generateToast('_ERROR','_AREA_FROM_OTHER_ZONE','error');
            }            
        }
    }
    
    cleanZones(){
        return this.pageInfo.zones.map(el =>{ return {refAreas : el.refAreas, name : el.name, id : el.id}});
    }
    async createZone(){
        try{
            if(!this.checkStatusName(this.pageInfo.nameZone)){ return this.commons.generateToast('_ERROR','_NAME_ZONE_ALREADY_EXISTS','error');}
            let newZone = {
                refAreas: this.pageInfo.currentZones.map(el => el.ref),
                areas   : this.pageInfo.currentZones,
                name    : this.pageInfo.nameZone,
                id      : this.pageInfo.currentZoneId ? this.pageInfo.currentZoneId : Math.random().toString(36).substring(7) 
            };
            let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == newZone.id);
            if(zoneFinded > -1){    // UPDATE
                this.pageInfo.zones[zoneFinded].areas       = newZone.areas; 
                this.pageInfo.zones[zoneFinded].name        = newZone.name;
                this.pageInfo.zones[zoneFinded].refAreas    =   newZone.refAreas;
            }else{
                this.pageInfo.zones.push(newZone);
            }

            if(!this.fromWizard || (this.fromWizard && this.mode == 'edit')){
                if(!this.destination || (this.destination && !this.destination.isNewDestination)){
                    let infoEntities = this.mountEntities();
                    await this.firebaseCtrl.updateZoneDestination(infoEntities.dmc,infoEntities.destination, this.cleanZones());                        
                    this.updateZonesFilters();
                    (this.mode == 'edit') ?   this.paintCreatedsAreas() : this.restartItems()
                    this.commons.generateToast('_SUCCESS',zoneFinded > -1 ? '_ZONE_EDITED' : '_ZONE_CREATED','success');
                }
            }else{
                this.updateZonesFilters();
                this.paintCreatedsAreas();
            }
            this.restartOptions();

        }catch(e){
            console.error(e);
            this.commons.generateToast('_ERROR','_RESTART_PAGE','error');
        }   
    }
    updateZonesFilters(){
        this.pageInfo.filters.find(el => el.entity == 'zones').items = this.pageInfo.zones.map(zone => {return { label : zone.name, value: zone.name, id: zone.id}}) 
    }
    mountEntities(){
        return {
            dmc         :   this.mode   ?   this.dmc.id         :   this.userInfo.idDmc,
            destination :   this.mode   ?   this.destination.id :   this.userInfo.currentDestination.id   
        }
    }
    async deleteZone(){
        let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == this.pageInfo.currentZoneId);
        if (zoneFinded == -1){ return this.commons.generateToast('_ERROR', '_NO_ZONE_FINDED', 'error');}
        
        this.pageInfo.zones.splice(zoneFinded,1);
        
        if((!this.fromWizard || this.mode == 'edit') && (!this.destination.isNewDestination)){
            let infoEntities = this.mountEntities();
            await this.firebaseCtrl.updateZoneDestination(infoEntities.dmc,infoEntities.destination, this.cleanZones());                        
        }
        this.updateZonesFilters();
        this.commons.generateToast('_SUCCESS','_ZONE_REMOVED','success');
        this.restartItems();
        this.restartOptions();
        this.paintCreatedsAreas();
    }

    displayZone(zone){
        this.restartItems();
        let infoZone                = zone.selected['value'] ? zone.selected['value'] : zone.selected;
        console.log('INFO ZONE',infoZone);
        let zoneSelected            = this.pageInfo.zones.find(el => el.name == infoZone);
        this.pageInfo.nameZone      = zoneSelected.name;
        this.pageInfo.editZone      = true;
        this.pageInfo.currentZones  = zoneSelected.areas;
        this.pageInfo.currentZoneId = zoneSelected.id;
      
        this.paintCreatedsAreas(zoneSelected);
    }

    
    restartItems(){
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = [];
        this.pageInfo.legendZones   = [];
        this.pageInfo.listAreas.map(el => el.color = "#6495ED");
    }

    restartOptions(){
        this.pageInfo.createZone    = false;
        this.pageInfo.editZone      = false;
        this.pageInfo.filters.map(el => el.selected = []);
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = null;
        this.pageInfo.currentZoneId = null;
    }

    checkStatusName(nameZone){
        if(nameZone === null) return false;
        let areaFinded =  this.pageInfo.zones.find(el => el.name == nameZone);
        if(areaFinded){
            return areaFinded.id == this.pageInfo.currentZoneId;
        }else{
            return true;
        }
    }
    async deleteZones(){
        this.confirmationService.confirm({
            message     : 	this.commons.getTranslate('_MESSAGE_CONFIRM_REMOVE_ZONES'),
            header      : 	this.commons.getTranslate('_REMOVE_ZONES'),
            key         :   'removeZones',
            acceptLabel :   this.commons.getTranslate('_YES'),
            rejectLabel :   this.commons.getTranslate('_NO'),
            accept: async () => {
                try{
                    if((!this.fromWizard || this.mode == 'edit') && (!this.destination.isNewDestination)){
                        let infoEntities = this.mountEntities();
                        await this.firebaseCtrl.updateZoneDestination(infoEntities.dmc,infoEntities.destination, []);                        
                    }
                    this.storageService.deleteItem('zones');
                    this.storageService.deleteItem('filtersZones');
                    // this.initMap();
                    this.pageInfo.zones         = [];
                    this.updateZonesFilters();
                    this.restartOptions();
                    this.restartItems();
                }catch(e){}
            }
        });
    }
    sortList(list){
        if(!list) return [];
        return list.sort((a,b) =>{ return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1});
    }
}