export const filters = [
	{
		entity	: 'groups',
		name	: 'transfer',
		label	: 'Transfer',
		type	: 'multiple',
		field	: 'transfer',
		items	: [
			{ label: '_SHUTTLE', value: 'shuttle'	},
			{ label: '_PRIVATE', value: 'private' 	}
		],
		selected: [ "shuttle", "private" ]
	}
];

export const providerRestrictionFields = [
	{ name: "_MAX_WAIT_TIME", 		type: 'number',		default: 60			},
	{ name: "_USE_SPEEDY",			type: 'boolean',	default: false		},
	{ name: "_MAX_SPEEDY_STOPS",	type: 'number',		default: 0			}	
];

export const zonesFilter = {
	entity		: 'booking_groups',
	name		: 'zones',
	label		: '_ZONES',
	type		: 'simple',
	field		: 'zones'		
};