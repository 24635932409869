import { AggregatorsService } from './../../../service/database/aggregator.service';
import { CompanyService } from './../../../service/database/company.service';
import { Http			 						} from "@angular/http";
import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation, 
		NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { colFilters                             } from "./data/filters";

import { ConfirmationService 					} from 'primeng/primeng';
import { FirebaseService 						} from './../../../service/database/firebase.service';
import { StorageService 						} from './../../../service/storageservice';

@Component({
	selector		: 'app-company-providers',
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class CompanyProvidersComponent implements OnInit
{
    locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private http 			: Http,
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private companyCtrl		: CompanyService,
		private aggregatorCtrl	: AggregatorsService,
		private confirmCtrl     : ConfirmationService
	)					{ 			 					}
	async ngOnInit() 	{	await this.init();
							await this.loadEntities();	}
    async init()		{
		//
		this.pageInfo.dataLoaded      	= false;
        this.pageInfo.indexTabCalendar	= 0;
		
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
		
		console.log('UserInfo', this.userInfo);
		
		this.pageInfo.status			= {
			providers	: "providerList",			
		};
		this.pageInfo.i18n				= this.commons.getAllTranslates();
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_PROVIDERS'		), 	icon : 'fa fa-list' 			, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:1, label: this.commons.getTranslate('_SERVICES'		), 	icon : 'fa fa-llne-chart' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:2, label: this.commons.getTranslate('_CONCILIATION'	), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:3, label: this.commons.getTranslate('_STATS'			), 	icon : 'fa fa-pie-chart' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:4, label: this.commons.getTranslate('_API'				), 	icon : 'fa fa-cogs-o' 			, command: ($event)=> { this.setTabItem('main',$event)} 	}
				],
				selected		: 0
			}
		};		
		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
        };
        this.pageInfo.colFilters 		= await Promise.all(colFilters.map(async item=>await this.commons.translateRecursively(item)));
		this.pageInfo.loading			= {}
		this.pageInfo.entities			= {	'providers'	:	{},
											'dmc'		:	{},
										}
	}				

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

	async loadEntities(){	await this.load('providers'); 
							await this.load('dmc');
							this.pageInfo.dataLoaded = true;	}
    async load($entity)	{

        switch($entity){
			case 'dmc'				:	this.pageInfo.entities[$entity]			=	await this.companyCtrl.getDmc(this.userInfo.idDmc);
										break;
			case 'providers' 		:	this.pageInfo.entities[$entity].data	=	[];
										this.pageInfo.entities[$entity].data	= 	await this.aggregatorCtrl.getProviders();
										this.pageInfo.entities[$entity].data	= 	this.pageInfo.entities[$entity].data.map(item=>{
											item.logo = "/assets/layout/icons/providers/"+item.thumbnail+".png";
											return item;
										});
										console.log('LIST PROVIDERS',this.pageInfo.entities[$entity]);
										if(!this.userInfo.idDmc){ return; }
										await this.getDestinationsFromDmc();
										console.log('DESTINATIONS FROM DMC', this.pageInfo.destinationsFromDmc);
										this.pageInfo.providersFromCompany		=	await this.aggregatorCtrl.getProviderAccountsFromDmc(this.userInfo.idDmc);
										this.pageInfo.providersFromCompany		=	this.pageInfo.providersFromCompany || [];
										this.pageInfo.providersFromCompany.forEach(provider => {
											provider.accounts.forEach(account =>{	account.hire_end	=	new Date(this.commons.formatTimestamp(account.hire_end));
																					account.hire_init	=	new Date(this.commons.formatTimestamp(account.hire_init));
																				}
																	);
											}
										);

										break;
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	async action($type,$event){
		try{
			
		}catch(e){
			this.commons.generateToast('_ERROR','_ERROR','error');
		}
		switch($type){
			case "providerList"		: 	switch($event.action){
										case "provider_unselected"	:	this.pageInfo.currentProvider	= undefined;		break;
										case "provider_selected"	:	this.pageInfo.currentProvider	= $event["item"];	break;
										case "view_provider"		: 	if(undefined==this.pageInfo.currentProvider){
																			this.commons.generateToast("_ERROR","_PROVIDER_NOT_SELECTED","error");
																			return false;
																		}
																		this.pageInfo.status.providers 		= "providerDetail"; 	
																		break;
										case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
									}
									break;			

			case "providerDetail"	: 	this.pageInfo.status.providers 		= "providerList"; 		break;	
			case "providerMain"		:	console.log($event); switch($event.action){
										default 					:	return;
										case "save"					:	$event.item.data.id ? await this.aggregatorCtrl.updateProviderToDmc($event.item.data, this.userInfo.idDmc)
																							: await this.aggregatorCtrl.addProviderToDmc($event.item.data, this.userInfo.idDmc);
																		this.commons.generateToast('_SUCCESS','_DATA_UPLOADED','success');
																		break;
										case "delete"				:	await this.aggregatorCtrl.removeProviderFromDmc	($event.item.data, this.userInfo.idDmc);	
																		this.commons.generateToast('_SUCCESS','_DATA_DELETED','success');
																		break;
										case "deleteScheduler"		:	await this.aggregatorCtrl.updateProviderToDmc		($event.item.data, this.userInfo.idDmc);	
																		this.commons.generateToast('_SUCCESS','_DATA_DELETED','success');
																		break;
			}		
		}
	}

	async getDestinationsFromDmc(){
		if(!this.userInfo.idDmc){ this.pageInfo.destinationsFromDmc = []; return; }
			
		this.pageInfo.destinationsFromDmc		=	await this.companyCtrl.getDestinationsFromDmc(this.userInfo.idDmc);
		this.pageInfo.destinationsFromDmc		=	await Promise.all(this.pageInfo.destinationsFromDmc.map( async destination =>{
																												let data	: any =	await this.firebaseCtrl.getDataByRef(destination.refDestination);
																												return {...destination, 
																															id				: String(destination.id).trim(),
																															idDestination 	: String(data.id).trim(), 
																															img 			: data.img, 
																															customCreation	: data.customCreation || false,
																															mapConfig		: data.mapConfig ||  null,
																															refAreas 		: data.refAreas	}
																											}));
																										}
																										
}