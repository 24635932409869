export let detailItems = [	
	{
		id			: 1,
		title		: "_WIZARD_VARIANT_STEP_1_TITLE",
		description	: "_WIZARD_VARIANT_STEP_1_DESC"
	},
	{
		id			: 2,
		title		: "_WIZARD_VARIANT_STEP_2_TITLE",
		description	: "_WIZARD_VARIANT_STEP_2_DESC"
	},
	{
		id			: 3,
		title		: "_WIZARD_VARIANT_STEP_3_TITLE",
		description	: "_WIZARD_VARIANT_STEP_3_DESC"
	},
	{
		id			: 4,
		title		: "_WIZARD_VARIANT_STEP_4_TITLE",
		description	: "_WIZARD_VARIANT_STEP_4_DESC"
	},
	{
		id			: 5,
		title		: "_WIZARD_VARIANT_STEP_5_TITLE",
		description	: "_WIZARD_VARIANT_STEP_5_DESC"
	},
	{
		id			: 6,
		title		: "_WIZARD_VARIANT_STEP_6_TITLE",
		description	: "_WIZARD_VARIANT_STEP_6_DESC"
	},
	{
		id			: 7,
		title		: "_WIZARD_VARIANT_STEP_7_TITLE",
		description	: "_WIZARD_VARIANT_STEP_7_DESC"
	},
];

export const entities = {						
	stationTypeList		: [ 
		{ id: 1, name: 'airport', 	label: '_AIRPORT'	},
		{ id: 2, name: 'port', 		label: '_PORT'		},
		{ id: 3, name: 'train', 	label: '_TRAIN'		},
		{ id: 4, name: 'bus', 		label: '_BUS'		}
	]		
};