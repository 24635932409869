export const CS_FV = [
    {
        "Id": 23,
        "Name": "Aptos La Piramide",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Berlina, 5, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.4142603,-13.8523169"
    },
    {
        "Id": 84,
        "Name": "Globales Costa Tropical",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Maestro Leonardo, s/n, 35610 Costa de Antigua, Las Palmas, Spain",
        "Coordinates": "28.4117068,-13.8563317"
    },
    {
        "Id": 89,
        "Name": "Horizonte Bay",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Berlina, 5, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.4142603,-13.8523169"
    },
    {
        "Id": 148,
        "Name": "Elba Castillo Lucia Sport- Castillo de Elba Suite",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Teniente Coronel José Zerpa Guevara, 8, 35610 Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.414102,-13.8554709"
    },
    {
        "Id": 230,
        "Name": "Caleta Paraiso",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Isla de la Graciosa, 5, 35610 Antigua, Las Palmas, Spain",
        "Coordinates": "28.4110649,-13.8553242"
    },
    {
        "Id": 240,
        "Name": "VILLA ROMANA",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle la Maresía, 110, 35610 Antigua, Las Palmas, Spain",
        "Coordinates": "28.380637,-13.8689727"
    },
    {
        "Id": 295,
        "Name": "Cocosol Aptos.",
        "Island": "FV",
        "ZoneId": 301,
        "ZoneName": "Nuevo Horizonte",
        "Address": "Calle Isla de la Graciosa, 4, 35610 Antigua, Las Palmas, Spain",
        "Coordinates": "28.4102159,-13.8547113"
    },
    {
        "Id": 1,
        "Name": "Hotel La Casita",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "8 Calle A.Francisco Berriel Jordán Castillo Caleta de Fuste",
        "Coordinates": "28.3995781,-13.8612663"
    },
    {
        "Id": 2,
        "Name": "Miramar Apartamentos",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Miramar 64, Castillo, 35610 Caleta de Fuste, España ",
        "Coordinates": "28.402791877565832,-13.878355622291565"
    },
    {
        "Id": 3,
        "Name": "Castillo Mar",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. José Franchy Roca, 115, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.398463,-13.8547304"
    },
    {
        "Id": 30,
        "Name": "Barcelo Puerto El Castillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. del Castillo, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.39273294746488,-13.854711339517962"
    },
    {
        "Id": 35,
        "Name": "Barcelo Ftva Royal Level & Castillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. del Castillo, s/n, 35610 Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3947456,-13.8542143"
    },
    {
        "Id": 37,
        "Name": "Barcelo Ftva Mar-Barcelo Ftva Thalasso SPA",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. del Castillo, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3973646,-13.8582895"
    },
    {
        "Id": 41,
        "Name": "Barcelo Castillo Club Premium- Barceló castillo Royal Level",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Av. del Castillo, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3925783,-13.8544133"
    },
    {
        "Id": 46,
        "Name": "Broncemar Beach",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Ajicán, 4, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.39876,-13.8618444"
    },
    {
        "Id": 49,
        "Name": "Castillo Beach Low Cost",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. del Castillo, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3947992,-13.8542797"
    },
    {
        "Id": 50,
        "Name": "Castillo Beach Puerto",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Verol, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3967975,-13.8616635"
    },
    {
        "Id": 51,
        "Name": "Castillo Beach VIP Villas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Plaza San Isidro, 15, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3973328,-13.8661735"
    },
    {
        "Id": 52,
        "Name": "Castillo Beach Vistas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Virgen de la Caridad del Cobre, 4, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3990433,-13.8697034"
    },
    {
        "Id": 54,
        "Name": "Club Montecastillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "San Francisco, S/N, 35600",
        "Coordinates": "28.39912068222516,-13.86925220489502"
    },
    {
        "Id": 57,
        "Name": "Caleta Garden",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Alcalde Juan Ramón Soto Morales, 15, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3996456,-13.8588219"
    },
    {
        "Id": 61,
        "Name": "Castillo Beach Bungalows",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Virgen de Guadalupe, 3, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3990623,-13.8715374"
    },
    {
        "Id": 62,
        "Name": "Castillo Playa Bungalows",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Pitera, 8, 35610 Antigua, Las Palmas, Spain",
        "Coordinates": "28.3952457,-13.8613679"
    },
    {
        "Id": 63,
        "Name": "Cay Beach Caleta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Tabaiba, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3959464,-13.8626447"
    },
    {
        "Id": 64,
        "Name": "Club Caleta Dorada",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Barrilla, S/N, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3972114,-13.8665943"
    },
    {
        "Id": 66,
        "Name": "Costa Caleta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Alcalde Juan Ramón Soto Morales, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3990352,-13.8556802"
    },
    {
        "Id": 69,
        "Name": "Ereza Mar",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. José Franchy Roca, 6, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3977883,-13.854725"
    },
    {
        "Id": 73,
        "Name": "Elba Carlota Beach and Convention Resort",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. de las Marismas, 2, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3884968,-13.8643119"
    },
    {
        "Id": 74,
        "Name": "Elba Palace Golf and Vital Hotel",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle del Golf, 1, 35610 Antigua, Las Palmas, Spain",
        "Coordinates": "28.3895162,-13.874941"
    },
    {
        "Id": 75,
        "Name": "Elba Sara Beach and Golf Resort",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. de las Marismas, 7, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3841528,-13.8658722"
    },
    {
        "Id": 77,
        "Name": "Fuerteventura Beach Club",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle El Greco, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3993746,-13.8686615"
    },
    {
        "Id": 79,
        "Name": "Fuertesol",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Virgen de la Peña, 2, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3966481,-13.8650721"
    },
    {
        "Id": 83,
        "Name": "Eurostars Las Salinas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Poligono e Anex Calet Fust Oeste, 1P, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.39386,-13.86097"
    },
    {
        "Id": 125,
        "Name": "Primasol Club El Castillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle El Greco, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3993746,-13.8686615"
    },
    {
        "Id": 126,
        "Name": "Puerto Caleta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Lugar Urbanización Castillo Caleta de Fuste, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3984225,-13.8574795"
    },
    {
        "Id": 144,
        "Name": "Sheraton Fuerteventura",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Urb. Fuerteventura Sherton Golf Beach, 1, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3902426,-13.8640116"
    },
    {
        "Id": 149,
        "Name": "Elba Castillo San Jorge and Antigua Suite Hotel",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. José Franchy Roca, s/n, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3994917,-13.8534918"
    },
    {
        "Id": 152,
        "Name": "SMY TAHONA FUERTEVENTURA",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Alcalde Marcial Sánchez Velázquez, 17, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.4000816,-13.8570003"
    },
    {
        "Id": 154,
        "Name": "V.I.P. Villas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Plaza San Isidro, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3974403,-13.866303"
    },
    {
        "Id": 157,
        "Name": "VILLAS EL DESCANSO",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Jardines de Ayose, 7, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3903502,-13.8702069"
    },
    {
        "Id": 158,
        "Name": "Villas Senorio Del Castillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Urb. Castillo 3 Fase E, 46, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.4004408,-13.8771949"
    },
    {
        "Id": 159,
        "Name": "Villas Siesta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Urbanización Fuerteventura Golf Club, 35610 Caleta de Fuste, España ",
        "Coordinates": "28.39184917563412,-13.874297335023826"
    },
    {
        "Id": 161,
        "Name": "Villa Florida",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Av. Alcalde Juan Ramón Soto Morales, 17, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.399164,-13.857563"
    },
    {
        "Id": 162,
        "Name": "Villas Caleta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Plaza San Isidro, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3974403,-13.866303"
    },
    {
        "Id": 163,
        "Name": "Villas Golf Salinas de Antigua",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Golf Club Salinas de Antigua, Carretera de Jandía, Km 12 - Antigua, 35610 Fuerteventura, Las Palmas, Las Palmas, Spain",
        "Coordinates": "28.38063,-13.876419"
    },
    {
        "Id": 170,
        "Name": "Castillo Beach Lake",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Virgen de Guadalupe, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3995148,-13.8707141"
    },
    {
        "Id": 171,
        "Name": "Las Cascadas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Angel Jove, 18, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3917469,-13.8700727"
    },
    {
        "Id": 236,
        "Name": "Villas Castillo",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Urb. Castillo 3 Fase E, 2, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.4023178,-13.8762257"
    },
    {
        "Id": 243,
        "Name": "Amuley Mar Aptos.",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Marcial Sánchez Velazque, 35610 Castillo Caleta de Fuste, Las Palmas",
        "Coordinates": "28.399644472372756,-13.85574996471405"
    },
    {
        "Id": 244,
        "Name": "Apartamentos Esmeralda",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Alcalde Juan Ramón Soto Morales, 9A, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.4003387,-13.8614694"
    },
    {
        "Id": 245,
        "Name": "Caleta Alta",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.401772,-13.875356"
    },
    {
        "Id": 247,
        "Name": "Casa Ellay",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 248,
        "Name": "Casa Kerisa",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 249,
        "Name": "La Colina Villas",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle la Luna, 4, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3921407,-13.8743818"
    },
    {
        "Id": 250,
        "Name": "Las Salinas Golf Resort",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Golf Club Salinas de Antigua, Carretera de Jandía, Km 12 - Antigua, 35610 Fuerteventura, Las Palmas, Las Palmas, Spain",
        "Coordinates": "28.38063,-13.876419"
    },
    {
        "Id": 251,
        "Name": "MARINA GOLF Residencial ",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Urb. Alta Vista, 19, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.4003754,-13.8709657"
    },
    {
        "Id": 255,
        "Name": "Villa Zante",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Unnamed Road, Lagopodo 290 92, Grecia",
        "Coordinates": "37.7494069,20.8120378"
    },
    {
        "Id": 256,
        "Name": "Villa Sammia",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Barranco del Valle de, 35610 Miraflor, Las Palmas, Spain",
        "Coordinates": "28.3859699,-13.8735427"
    },
    {
        "Id": 257,
        "Name": "Villa Mimosa",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Av. Angel Jove, 81, 35610 Antigua, Las Palmas, España",
        "Coordinates": "28.3880101,-13.8763344"
    },
    {
        "Id": 258,
        "Name": "Villa Lantana",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 260,
        "Name": "Villa Casa Colleen",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 262,
        "Name": "Castillo Beach Park",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "35000,, Calle Virgen de la Caridad del Cobre, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.3974785,-13.8718057"
    },
    {
        "Id": 267,
        "Name": "Puerta Del Sol",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Marcial Sánchez Velazque, 15, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.4004399,-13.8580671"
    },
    {
        "Id": 273,
        "Name": "Villas Cruymar",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Avda. Salinas de Antigua s/n, . Caleta De Fuste, Fuerteventura",
        "Coordinates": "28.380463798683376,-13.876480226210614"
    },
    {
        "Id": 284,
        "Name": "Apto. Bahia Sol",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle Alcalde, Calle Marcial Sánchez Velazque, 35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.4009267,-13.8604188"
    },
    {
        "Id": 304,
        "Name": "Elite Fuerteventura Club",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "35610 Castillo Caleta de Fuste, Las Palmas, Spain",
        "Coordinates": "28.4000291,-13.8671494"
    },
    {
        "Id": 340,
        "Name": "Villas Caleta Beach",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "AVDA SALINAS GOLF, 35610, Las Palmas, España",
        "Coordinates": "28.380686,-13.876401"
    },
    {
        "Id": 397,
        "Name": "Dulce de leche",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "Calle A.Francisco Berriel Jordán, 163, 35610 Castillo Caleta de Fuste, Las Palmas, España",
        "Coordinates": "28.3998884,-13.86244"
    },
    {
        "Id": 413,
        "Name": "Urbanización Los Arcos REVISAR",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "",
        "Coordinates": ""
    },
    {
        "Id": 414,
        "Name": "Urbanización El Vergel REVISAR",
        "Island": "FV",
        "ZoneId": 302,
        "ZoneName": "Caleta De Fuste",
        "Address": "",
        "Coordinates": ""
    },
    {
        "Id": 33,
        "Name": "Bahia Grande",
        "Island": "FV",
        "ZoneId": 303,
        "ZoneName": "Playitas",
        "Address": "urb las playitas",
        "Coordinates": "28.22836906971126,-13.988792896270752"
    },
    {
        "Id": 124,
        "Name": "Playitas Hotel",
        "Island": "FV",
        "ZoneId": 303,
        "ZoneName": "Playitas",
        "Address": "Urb. las Playitas",
        "Coordinates": "28.22986321509195,-13.988679121790634"
    },
    {
        "Id": 167,
        "Name": "Villas Playitas",
        "Island": "FV",
        "ZoneId": 303,
        "ZoneName": "Playitas",
        "Address": "Urbn. Las Playitas S/N, 35629 Las Playitas, España",
        "Coordinates": "28.229938231078144,-13.994865417480469"
    },
    {
        "Id": 229,
        "Name": "Cala Del Sol",
        "Island": "FV",
        "ZoneId": 303,
        "ZoneName": "Playitas",
        "Address": "urb las playitas",
        "Coordinates": "28.22836906971126,-13.988792896270752"
    },
    {
        "Id": 238,
        "Name": "Apartahotel Las Playitas",
        "Island": "FV",
        "ZoneId": 303,
        "ZoneName": "Playitas",
        "Address": "Urb. Las playitas s/n",
        "Coordinates": "28.22702972973233,-13.99165065683698"
    },
    {
        "Id": 4,
        "Name": "R2 Bahia Playa Design",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "Av. las Palmeras, s/n, 35627 Tarajalejo, Las Palmas, Spain",
        "Coordinates": "28.1921905,-14.1186358"
    },
    {
        "Id": 34,
        "Name": "Bahia Playa Hotel",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "Av. las Palmeras, s/n, 35627 Tarajalejo, Las Palmas, Spain",
        "Coordinates": "28.1921905,-14.1186358"
    },
    {
        "Id": 215,
        "Name": "Romantic Fantasia",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "Av. las Palmeras, 35629 Tarajalejo, Las Palmas, Spain",
        "Coordinates": "28.1935394,-14.1182232"
    },
    {
        "Id": 269,
        "Name": "Apartamentos Varadero",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "35629 Tarajalejo, Las Palmas, Spain",
        "Coordinates": "28.192285,-14.1174541"
    },
    {
        "Id": 429,
        "Name": "Tarajalejo Village",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "Av. las Palmeras, 15, 35629 Tarajalejo, Las Palmas, España",
        "Coordinates": "28.1931685,-14.1204488"
    },
    {
        "Id": 2474,
        "Name": "Tofio Resort Bahía Playa",
        "Island": "FV",
        "ZoneId": 304,
        "ZoneName": "Tarajalejo",
        "Address": "Av. las Palmeras, 0, 35629 Tarajalejo, Las Palmas",
        "Coordinates": "28.192733305642115,-14.118332546619785"
    },
    {
        "Id": 19,
        "Name": "Althay",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1716944,-14.2223945"
    },
    {
        "Id": 21,
        "Name": "Apartamentos Las Lomas",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Valle de los Mosquitos, 6, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1727783,-14.2227017"
    },
    {
        "Id": 31,
        "Name": "Barlovento Club",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, 2, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1659394,-14.2206553"
    },
    {
        "Id": 32,
        "Name": "Bahia Calma",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Urbanización, Calle Angostura, 3, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1579499,-14.2292482"
    },
    {
        "Id": 42,
        "Name": "Matas Blancas",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta Pesebre, 6, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1733993,-14.221605"
    },
    {
        "Id": 59,
        "Name": "Casa Catalina",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 70,
        "Name": "Drago Park",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta Pesebre, 5, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1734997,-14.2230932"
    },
    {
        "Id": 76,
        "Name": "Esmeralda Maris",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta del Roquito, 1, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1493037,-14.2356865"
    },
    {
        "Id": 80,
        "Name": "Fuerteventura Playa",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Urbanización Cañada del Río, Av. Jahn Reisen, 1, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1669455,-14.2206471"
    },
    {
        "Id": 85,
        "Name": "Golden Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1568013,-14.2326451"
    },
    {
        "Id": 86,
        "Name": "H10 Playa Esmeralda",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta del Roquito, 2, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1488878,-14.2351431"
    },
    {
        "Id": 87,
        "Name": "H10 Tindaya ",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta del Roquito, s/n, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1524998,-14.2303912"
    },
    {
        "Id": 108,
        "Name": "Maryvent Apart",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Caleta Mansa, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1584875,-14.2272417"
    },
    {
        "Id": 115,
        "Name": "R2 Higos Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Baja de los Erizos, 1, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1551873,-14.23013"
    },
    {
        "Id": 121,
        "Name": "Pajara Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta del Roquito, 9, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1500976,-14.2327687"
    },
    {
        "Id": 128,
        "Name": "R2 Rio Calma Hotel & Spa & Conference",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Urbanización El Granillo, Calle Artistas Canarios, 8, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1674,-14.2096"
    },
    {
        "Id": 136,
        "Name": "Royal Suite Hotel",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Urbanización Cañada del Rio, Valle de los Escobones, 35627 Costa Calma-Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.1713436,-14.2234855"
    },
    {
        "Id": 138,
        "Name": "SBH Costa Calma Beach Resort",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Agustín Millares, 2, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1673118,-14.2176885"
    },
    {
        "Id": 139,
        "Name": "SBH Costa Calma Palace",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, s/n, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1608227,-14.2273515"
    },
    {
        "Id": 140,
        "Name": "SBH Cristal Beach Hotel",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle L.T.U., 3, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1639916,-14.2236438"
    },
    {
        "Id": 142,
        "Name": "SBH Monica Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, S/N, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1631254,-14.2250014"
    },
    {
        "Id": 143,
        "Name": "SBH Taro Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle L.T.U., 1, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1636921,-14.2227457"
    },
    {
        "Id": 146,
        "Name": "Solymar Calma",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Puerto de la Cebada, 1, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1646565,-14.2287069"
    },
    {
        "Id": 147,
        "Name": "Sotavento Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, 3, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.165434,-14.221943"
    },
    {
        "Id": 160,
        "Name": "Vik Suite Hotel Risco del Gato",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Sicasumbre, 2, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1524859,-14.2323988"
    },
    {
        "Id": 222,
        "Name": "Las Pardelas",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Urbanización, Calle Angostura, 3, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1579499,-14.2292482"
    },
    {
        "Id": 225,
        "Name": "Apartamentos Sunrise Monica Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, S/N, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1631254,-14.2250014"
    },
    {
        "Id": 231,
        "Name": "Club Ancora",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Happag Lloyd, 2, 35627 Costa Calma, Las Palmas",
        "Coordinates": "28.170010147603126,-14.220600128173828"
    },
    {
        "Id": 286,
        "Name": "Serenity Fuerteventura",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Comunidad Mirador del Sur, 39, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1727885,-14.2157667"
    },
    {
        "Id": 300,
        "Name": "Club Green Oasis- Fuerte Holiday Duplex-AGPI fuerteventura",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Puerto de la Cebada, 5, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.1632314,-14.2309946"
    },
    {
        "Id": 302,
        "Name": "Nautilus Beach",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, 2A, 35627 Costa Calma, Las Palmas, España",
        "Coordinates": "28.167398,-14.2168797"
    },
    {
        "Id": 309,
        "Name": "Jardin del Rio",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Av. Jahn Reisen, 21, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1585072,-14.2314604"
    },
    {
        "Id": 322,
        "Name": "Solymar Palmera",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Playa de la Jaqueta, 10, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1644069,-14.232975"
    },
    {
        "Id": 327,
        "Name": "SERENITY  BED AND BREAKFAST",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Doonierin, Sligo, Ireland",
        "Coordinates": "54.31206,-8.504533"
    },
    {
        "Id": 334,
        "Name": "Los Hibiscos",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Punta de Barlovento, 2, 406, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1550654,-14.229666"
    },
    {
        "Id": 347,
        "Name": "TAIMAR COOEE",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Valle de los Mosquitos, 4, 35627 Costa Calma, Las Palmas, Spain",
        "Coordinates": "28.1716811,-14.2224072"
    },
    {
        "Id": 2289,
        "Name": "Solymar Palmeras",
        "Island": "FV",
        "ZoneId": 305,
        "ZoneName": "Costa Calma",
        "Address": "Calle Playa de la Jaqueta, 10, 35627 Costa Calma, Las Palmas",
        "Coordinates": "28.164581110602263,-14.233163595199585"
    },
    {
        "Id": 111,
        "Name": "Melia Fuerteventura",
        "Island": "FV",
        "ZoneId": 306,
        "ZoneName": "Playa Barca",
        "Address": "Calle Crisálida, 1, 35627 Pájara, Las Palmas, España",
        "Coordinates": "28.1368936,-14.2455227"
    },
    {
        "Id": 332,
        "Name": "Innside by Melia - Sol beach house ",
        "Island": "FV",
        "ZoneId": 306,
        "ZoneName": "Playa Barca",
        "Address": "Calle Crisálida Playa Barca, Fuerteventura, 35627, Las Palmas",
        "Coordinates": "28.13667492077148,-14.24728810787201"
    },
    {
        "Id": 20,
        "Name": "Ambar Beach Hotel-Alua village",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Gran Canaria, 1, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0773117,-14.3053846"
    },
    {
        "Id": 22,
        "Name": "Aparthotel Esquinzo",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Escanfraga, 2, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0783128,-14.2994472"
    },
    {
        "Id": 81,
        "Name": "Fuerteventura Princess",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Gran Canaria, 13 Urb. Butihondo 35625, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0705387,-14.3076212"
    },
    {
        "Id": 90,
        "Name": "Hotel Esquinzo Beach ",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Gran Canaria, 8, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0737412,-14.3083297"
    },
    {
        "Id": 100,
        "Name": "Jandia Princess",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "C/ Gran Canaria,11 Urb, Esquinzo-Butihondo, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0725,-14.3058333"
    },
    {
        "Id": 106,
        "Name": "Magic Life Fuerteventura Imperial",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Pueblos, 1, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0680664,-14.3094887"
    },
    {
        "Id": 112,
        "Name": "Monte Del Mar",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Escanfraga, 2, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0783128,-14.2994472"
    },
    {
        "Id": 113,
        "Name": "Monte Marina Naturist Resort",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Jandia Fuerteventura Calle Volcán de Vayuyo, N° 8, E, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0779365,-14.2991622"
    },
    {
        "Id": 133,
        "Name": "Robinson Esquinzo Playa",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Urbanizacion Butihondo C, Calle Gran Canaria, 2, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.076187,-14.302321"
    },
    {
        "Id": 137,
        "Name": "SBH Club Paraiso Playa ",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Lomo de Acebuche, s/n, 35620 Esquinzo, Pájara, Fuerteventura, Las Palmas de Gran Canaria, Las Palmas, Las Palmas, Spain",
        "Coordinates": "28.0812743,-14.295029"
    },
    {
        "Id": 150,
        "Name": "Suitehotel Marina Playa",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Calle Volcán de Vayuyo, 8, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0780693,-14.2990116"
    },
    {
        "Id": 283,
        "Name": "Sensimar Royal Palm Resort & SPA",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Avda. de los Pueblos, s/n Fuerteventura, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.06428559605674,-14.313372483505201"
    },
    {
        "Id": 339,
        "Name": "Esencia de Fuerteventura",
        "Island": "FV",
        "ZoneId": 307,
        "ZoneName": "Esquinzo",
        "Address": "Terreno Esquinzo, 6, 35626 Esquinzo, Las Palmas, España",
        "Coordinates": "28.070487,-14.3073606"
    },
    {
        "Id": 7,
        "Name": "Ocean World",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Colibrí, 8, 35625 Solana Matorral, Las Palmas, España",
        "Coordinates": "28.0527011,-14.3294382"
    },
    {
        "Id": 13,
        "Name": "Alameda De Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Bentejuí, 4, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.049784,-14.3409948"
    },
    {
        "Id": 14,
        "Name": "Alberto Apts",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Mafasca, 16, 35625 Morro Jable, Las Palmas, España",
        "Coordinates": "28.0518642,-14.3543475"
    },
    {
        "Id": 15,
        "Name": "Aldiana Club",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Playa de Jandia, Calle Melindraga, s/n, 35626 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0593319,-14.3170099"
    },
    {
        "Id": 18,
        "Name": "Altamarena",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 28, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0483946,-14.3406731"
    },
    {
        "Id": 24,
        "Name": "Aquamarin Hotel",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Flamenco, 2, 35625 Solana Matorral, Playa, Spain",
        "Coordinates": "28.0526407,-14.3295102"
    },
    {
        "Id": 26,
        "Name": "Atalaya De Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle los Guanches, 46, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0491664,-14.3540947"
    },
    {
        "Id": 38,
        "Name": "Occidental Jandia Royal Level",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Sancho Panza, 1, 35625 Pájara, Las Palmas, España",
        "Coordinates": "28.05468,-14.32487"
    },
    {
        "Id": 39,
        "Name": "Occidental Jandia Mar",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Sancho Panza, 2P, 35625 Pájara, Las Palmas, España",
        "Coordinates": "28.0564081,-14.3267023"
    },
    {
        "Id": 40,
        "Name": "Occidental Jandia Playa",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Sancho Panza, 1, 35625 Pájara, Las Palmas, España",
        "Coordinates": "28.05468,-14.32487"
    },
    {
        "Id": 47,
        "Name": "Buganvilla Hotel",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle las Afortunadas, 2, 35626 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0495316,-14.337582"
    },
    {
        "Id": 60,
        "Name": "Casablanca",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Faro, 6, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.051802,-14.3536877"
    },
    {
        "Id": 91,
        "Name": "Hotel Jandia Golf",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle la Mancha, 8P, 35625 Pájara, Las Palmas, España",
        "Coordinates": "28.06397,-14.33612"
    },
    {
        "Id": 93,
        "Name": "Ib. Playa Gaviotas Wellness",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Pje. Playas, 2, 35625, Las Palmas, Spain",
        "Coordinates": "28.0571924,-14.3188976"
    },
    {
        "Id": 94,
        "Name": "Igramar Morrojable",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Jandía, Fuerteventura, Calle Peatonal las Gaviotas, 3, 35625 Las Palmas, Spain",
        "Coordinates": "28.0497785,-14.3508776"
    },
    {
        "Id": 95,
        "Name": "Iberostar Fuerteventura Palace",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Pje. Playas, 1, 35626 Las Gaviotas, Las Palmas, Spain",
        "Coordinates": "28.0568188,-14.3207891"
    },
    {
        "Id": 96,
        "Name": "Ib. Playa Gaviotas",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Pje. Playas, 2, 35625, Las Palmas, Spain",
        "Coordinates": "28.0571924,-14.3188976"
    },
    {
        "Id": 97,
        "Name": "Ib. Playa Gaviotas Park",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Pje. Playas, 3, 35626 Jandia, Las Palmas, Spain",
        "Coordinates": "28.0581541,-14.3200582"
    },
    {
        "Id": 98,
        "Name": "Jandia Luz Blue Sea",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 1, 35625 Solana Matorral, Las Palmas, España",
        "Coordinates": "28.0492946,-14.3437794"
    },
    {
        "Id": 105,
        "Name": "Mur Faro Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 17, 35626 Solana Matorral, Las Palmas, Spain",
        "Coordinates": "28.0490781,-14.3342193"
    },
    {
        "Id": 109,
        "Name": "Matorral Studios",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Bentejuí, 6, 35626 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0503195,-14.3423004"
    },
    {
        "Id": 114,
        "Name": "Monte Solana Villas",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle la Hubara, 9, 35625 Solana Matorral, Las Palmas, Spain",
        "Coordinates": "28.0506579,-14.3361124"
    },
    {
        "Id": 122,
        "Name": "Palm Garden",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 5, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0489768,-14.3396177"
    },
    {
        "Id": 127,
        "Name": "Punta Marina",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle las Arenas, 3, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0498529,-14.3462839"
    },
    {
        "Id": 129,
        "Name": "Sensimar Calypso",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Playa de Jandía, s/n, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0501232,-14.3490353"
    },
    {
        "Id": 131,
        "Name": "Riu Palace Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Playa de Jandía, 35625 Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.0489389,-14.3458683"
    },
    {
        "Id": 134,
        "Name": "Robinson Jandia Playa",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 6, 35626 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0482709,-14.3431437"
    },
    {
        "Id": 135,
        "Name": "Rocamar Beach",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle el Sol, 1, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0499353,-14.3481694"
    },
    {
        "Id": 141,
        "Name": "SBH Maxorata Resort",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Biocho, 4, 35625 Jandia, Las Palmas",
        "Coordinates": "28.05269008549159,-14.327412936631783"
    },
    {
        "Id": 145,
        "Name": "Sol FTVA Jandia -Sol Jandia Mar",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Bentejuí, 8, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0499772,-14.3437943"
    },
    {
        "Id": 168,
        "Name": "XQ El Palacete",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Acantilado, S/N. Fuerteventura, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0504615,-14.3512473"
    },
    {
        "Id": 223,
        "Name": "Altavista APTS",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Abubilla, 8, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.049922,-14.3535513"
    },
    {
        "Id": 226,
        "Name": "Villamar Apar",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. de Jandía, 11, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0508605,-14.3502098"
    },
    {
        "Id": 253,
        "Name": "Tajinaste Morrojable Aptos.",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0511096,-14.351552"
    },
    {
        "Id": 254,
        "Name": "Villas Stella Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Biocho, 3, 35625 Solana Matorral, Las Palmas, Spain",
        "Coordinates": "28.0520434,-14.3286218"
    },
    {
        "Id": 263,
        "Name": "Cactus Garden Labranda",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle las Afortunadas, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0497646,-14.338541"
    },
    {
        "Id": 265,
        "Name": "Hotel Garonda Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Saladar, 28, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0483946,-14.3406731"
    },
    {
        "Id": 268,
        "Name": "Coronado Beach",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "2 Calle Colina del So",
        "Coordinates": "28.04984486311224,-14.34713004351654"
    },
    {
        "Id": 288,
        "Name": "Bungalows El Jardin",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Colibrí, N° 1, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0528557,-14.3293053"
    },
    {
        "Id": 290,
        "Name": "Bungalows La Mirada",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Istmo, 10, 35625 Solana Matorral, Las Palmas, España",
        "Coordinates": "28.0513133,-14.3294962"
    },
    {
        "Id": 318,
        "Name": "Tao hotel (MORRO JABLE)",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Gambuesas, 7, 35625 Morro Jable, Las Palmas",
        "Coordinates": "28.0518806930413,-14.351545572280884"
    },
    {
        "Id": 335,
        "Name": "Natalis Apartamentos",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Av. del Faro, 10, 35625 Morro Jable, Las Palmas, España",
        "Coordinates": "28.0520094,-14.353686"
    },
    {
        "Id": 337,
        "Name": "Balcón de Jandía",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "c/ LA PARDELA",
        "Coordinates": "28.050222372298222,-14.33538160356889"
    },
    {
        "Id": 341,
        "Name": "Mar Suite Jandia",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Tres Piedras, 7, 35626 Esquinzo, Las Palmas, Spain",
        "Coordinates": "28.0780347,-14.3017691"
    },
    {
        "Id": 344,
        "Name": "La Colina hotel",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Mafasca, 18, 35625 Morro Jable, Las Palmas, España",
        "Coordinates": "28.0519244,-14.354272"
    },
    {
        "Id": 348,
        "Name": "Garden & sea boutique-  Garden Beach",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle el Sol, 1, 35625 Morro Jable, Las Palmas",
        "Coordinates": "28.049939646295734,-14.348171353340149"
    },
    {
        "Id": 417,
        "Name": "P.AUX Bungalows La Mirada",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle Biocho, 35625 Solana Matorral, Las Palmas",
        "Coordinates": "28.051572, -14.328473"
    },
    {
        "Id": 419,
        "Name": "C/Avda del Faro (REVISAR 400 Y 419)",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "Calle los Guanches, 10, 35625 Morro Jable, Las Palmas, España",
        "Coordinates": "28.0519968,-14.3536855"
    },
    {
        "Id": 431,
        "Name": "P.AUX Aquamarin Hotel REVISAR",
        "Island": "FV",
        "ZoneId": 308,
        "ZoneName": "Morro Jable",
        "Address": "",
        "Coordinates": ""
    },
    {
        "Id": 8,
        "Name": "Katis Villas Boutique",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Plan Geafond P11, 1, 35660 La Oliva, Las Palmas, España",
        "Coordinates": "28.70891,-13.87216"
    },
    {
        "Id": 9,
        "Name": "VILLA NEPTUNO HOTEL",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 7, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7303181,-13.8657426"
    },
    {
        "Id": 11,
        "Name": "Mar Adentro Beach Front",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 83, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7306655,-13.8579886"
    },
    {
        "Id": 12,
        "Name": "Aptos Las Palmeras I-II",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Las Palmeras, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7413828,-13.8691399"
    },
    {
        "Id": 16,
        "Name": "Alisios Playa",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 1, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.731012093825626,-13.867932558059692"
    },
    {
        "Id": 17,
        "Name": "Aloe Corralejo",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pedro y Guy Vandaele, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.725207216821286,-13.860803246498108"
    },
    {
        "Id": 25,
        "Name": "Arena Suites Hotel",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle del Moro, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.72598,-13.85911"
    },
    {
        "Id": 27,
        "Name": "Atlantic Garden Beach mate",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 4, 35660 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.729454,-13.866048"
    },
    {
        "Id": 28,
        "Name": "Secrets Bahia Real",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, s/n, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.728208,-13.855583"
    },
    {
        "Id": 29,
        "Name": "Atlantis Fuert. Suites Resort ",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle las Dunas, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7300238,-13.8593754"
    },
    {
        "Id": 36,
        "Name": "Barcelo Corralejo Bay",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 12, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7320611,-13.867125"
    },
    {
        "Id": 43,
        "Name": "Barcelo Corralejo Sands",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "La Acacia,1 Fuerteventura, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7329705,-13.8706159"
    },
    {
        "Id": 44,
        "Name": "Brisas del Mar",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7195196,-13.8712421"
    },
    {
        "Id": 45,
        "Name": "Bristol Playa Hesperia",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Lago de Bristol, 1, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7435807,-13.8693252"
    },
    {
        "Id": 48,
        "Name": "Tao caleta mar - Gran Hotel Natura",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 125, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7263122,-13.8497918"
    },
    {
        "Id": 53,
        "Name": "Club Las Olas I y II",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Lugar Diseminado Tiscamanita, 1, 35638 Tuineje, Las Palmas, España",
        "Coordinates": "28.35913,-14.05227"
    },
    {
        "Id": 56,
        "Name": "Caledonia Dunas Club",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle el Pulpo, 6, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7431401,-13.865359"
    },
    {
        "Id": 58,
        "Name": "Caleta Playa",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 9, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7328983,-13.8670754"
    },
    {
        "Id": 65,
        "Name": "Corralejo Garden Fresh",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Juan Carlos I, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.738016,-13.871862"
    },
    {
        "Id": 71,
        "Name": "H10 Ocean Dunas - Duna park Atlantis",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle la Red, 1, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7339683,-13.8690653"
    },
    {
        "Id": 72,
        "Name": "Labranda Corralejo village",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Marcelino Camacho Abad, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7315407,-13.8693495"
    },
    {
        "Id": 78,
        "Name": "Fuentepark",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Anguila, 1, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7338441,-13.8707261"
    },
    {
        "Id": 82,
        "Name": "Galera Beach",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Poseidón, 1, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7330556,-13.8655556"
    },
    {
        "Id": 88,
        "Name": "HV Villas Corralejo GOLF",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Geafond, Calle Tarabilla, 1, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.707663793726063,-13.875856722374952"
    },
    {
        "Id": 102,
        "Name": "Bahia de Lobos",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "C. Gran Canaria, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7309737599598,-13.872033769187917"
    },
    {
        "Id": 103,
        "Name": "La Concha Bung.",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Marcelino Camacho Abad, 18, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.731299,-13.8644357"
    },
    {
        "Id": 104,
        "Name": "Las Marismas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Huriamen, s/n, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7264929,-13.8690319"
    },
    {
        "Id": 107,
        "Name": "Mar Azul",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle el Rincón, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7319254,-13.8685909"
    },
    {
        "Id": 110,
        "Name": "Maxorata Beach",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 1, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7304821,-13.8670784"
    },
    {
        "Id": 116,
        "Name": "Oasis Tamarindo I",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Guirre, 96, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7221263,-13.8676773"
    },
    {
        "Id": 117,
        "Name": "Oasis Papagayo Sport & Family",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pedro y Guy Vandaele, 25, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.722837415183314,-13.871803308447845"
    },
    {
        "Id": 118,
        "Name": "Oasis Dunas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Maxorata, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7294163,-13.8693907"
    },
    {
        "Id": 120,
        "Name": "Oasis Village",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 3, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7281136,-13.8664462"
    },
    {
        "Id": 123,
        "Name": "Playa Park Club",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Lanzarote, 4, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7268615,-13.8630347"
    },
    {
        "Id": 130,
        "Name": "Riu Oliva Beach Resort",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, s/n, 35660 Corralejo - Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.7086841,-13.839986"
    },
    {
        "Id": 132,
        "Name": "Riu Palace Tres Islas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Avenida Grandes Playas, s/n, 35660 Corralejo - Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.7126162,-13.8385452"
    },
    {
        "Id": 151,
        "Name": "Surfing Colors Aptos",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pejín, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7424126,-13.8701334"
    },
    {
        "Id": 153,
        "Name": "The Corralejo Beach",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Victor Grau Bassas, 1, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7370939,-13.868062"
    },
    {
        "Id": 155,
        "Name": "Villas Chema",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle la Palma, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7262975,-13.8608702"
    },
    {
        "Id": 156,
        "Name": "VILLAS CORRALEJO",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pardelas, 7, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7087049,-13.8721812"
    },
    {
        "Id": 164,
        "Name": "Villas Las Tinaja",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "antigua Fuerteventura, Calle Luis de San Pío Herrera, 29, 35639 Triquivijate, Las Palmas, Spain",
        "Coordinates": "28.4284175,-13.9708129"
    },
    {
        "Id": 165,
        "Name": "The View",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Geafond, 35660 La Oliva, Las Palmas, España",
        "Coordinates": "28.7074777,-13.8759374"
    },
    {
        "Id": 166,
        "Name": "Villas Oasis Papagayo",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pedro y Guy Vandaele, 25, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7224337,-13.8717781"
    },
    {
        "Id": 169,
        "Name": "Villa Zeus",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Sabina, 35660 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.69143,-13.89284"
    },
    {
        "Id": 172,
        "Name": "Villa Bella Vista",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Villas Bellavista, Calle Montaña Mesa, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8719173,-13.8463488"
    },
    {
        "Id": 173,
        "Name": "El Sultan",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle el Roque, 17, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7249239,-13.8661589"
    },
    {
        "Id": 174,
        "Name": "Casa Luka",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 175,
        "Name": "Infinity",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle los Matos, 6, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7262237,-13.8602322"
    },
    {
        "Id": 176,
        "Name": "Casa Cloe",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle la Calera, 11, nº215, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8615429,-13.8135273"
    },
    {
        "Id": 177,
        "Name": "Residencial Las Dunas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 29, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.72694,-13.85892"
    },
    {
        "Id": 178,
        "Name": "Palm Spring",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle la Palma, 12, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7272369,-13.8598756"
    },
    {
        "Id": 179,
        "Name": "Los delfines",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle El Pozo, 3, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7292125,-13.8596806"
    },
    {
        "Id": 180,
        "Name": "Villa Moana",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6884368,-14.0098526"
    },
    {
        "Id": 181,
        "Name": "Dormidero",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35640 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.6887372,-13.8315193"
    },
    {
        "Id": 182,
        "Name": "Villa Dalhia ",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 183,
        "Name": "Villa Silvia 1 y 2 ",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 15, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.727777722259777,-13.861356981849339"
    },
    {
        "Id": 184,
        "Name": "Villa Maxogarden",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 185,
        "Name": "Oasis Royal",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Maxorata, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.729487,-13.869832"
    },
    {
        "Id": 186,
        "Name": "Royal Park",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Royal Park guesthouse, Calle El Medano, 5, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7245117,-13.8684045"
    },
    {
        "Id": 187,
        "Name": "Las Fuentes",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Guirre, 46, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7197563,-13.8692542"
    },
    {
        "Id": 188,
        "Name": "Las Amerias 1 y 2",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Guirre, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7182212,-13.8697387"
    },
    {
        "Id": 189,
        "Name": "Vistas del Mar",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Plan Geafond P9, 8, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7185632,-13.8721653"
    },
    {
        "Id": 190,
        "Name": "Casas Ducales",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Plan Geafond P14, 13-17, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7171483,-13.8731222"
    },
    {
        "Id": 191,
        "Name": "Villa Palmera",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle de la Palmera, 35640 Villaverde, Las Palmas, Spain",
        "Coordinates": "28.62915,-13.90733"
    },
    {
        "Id": 192,
        "Name": "Villa Paraiso",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Tórtola, 6, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7148884,-13.8735163"
    },
    {
        "Id": 193,
        "Name": "Marina Village",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Mari y Miguelín, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7373,-13.86732"
    },
    {
        "Id": 194,
        "Name": "Villa Bugamvilla",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle las Afortunadas, 2, 35626 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0495316,-14.337582"
    },
    {
        "Id": 195,
        "Name": "Villa Perla",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 196,
        "Name": "Villa Club",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pardelas, 7, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7087049,-13.8721812"
    },
    {
        "Id": 197,
        "Name": "Villa Santorini",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Plan Geafond P11, 1, 35660 La Oliva, Las Palmas, España",
        "Coordinates": "28.70891,-13.87216"
    },
    {
        "Id": 198,
        "Name": "Mirador de las Dunas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 29, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.72694,-13.85892"
    },
    {
        "Id": 199,
        "Name": "Las Brisas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7195196,-13.8712421"
    },
    {
        "Id": 200,
        "Name": "Top Kapi",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Guirre, 35660 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.7138531,-13.8690367"
    },
    {
        "Id": 202,
        "Name": "Los Abanico",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7171488,-13.8698061"
    },
    {
        "Id": 203,
        "Name": "Villa Melissa",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 4b, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7294249,-13.8641287"
    },
    {
        "Id": 204,
        "Name": "Villa Galleria",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Poseidón, 1, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7330556,-13.8655556"
    },
    {
        "Id": 205,
        "Name": "Parque Elena",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 21, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7323571,-13.8654498"
    },
    {
        "Id": 206,
        "Name": "Villas Tara",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 207,
        "Name": "Estrella del Mar",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 42, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7296952,-13.8626716"
    },
    {
        "Id": 209,
        "Name": "Villa Jable",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle del Agua, 12, 35625 Morro Jable, Las Palmas, Spain",
        "Coordinates": "28.0492012,-14.3468928"
    },
    {
        "Id": 211,
        "Name": "Aptos. Erika",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "14 Calle de Arístides Hernández Morán Corralejo",
        "Coordinates": "28.7343236,-13.8676295"
    },
    {
        "Id": 212,
        "Name": "Avanti Boutique",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Marítima, numero 35, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7382418,-13.8675444"
    },
    {
        "Id": 213,
        "Name": "Pura vida",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Lepanto, 6, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.738488,-13.86875"
    },
    {
        "Id": 214,
        "Name": "Villas del Sol de lux",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Acebuche, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.6986504,-13.8936752"
    },
    {
        "Id": 227,
        "Name": "Arena BEACH - Aparthotel Lobosol",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle La Gomera, 5, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.72513,-13.86306"
    },
    {
        "Id": 228,
        "Name": "Bahiazul Villas & Club",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pardelas, 7, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7087049,-13.8721812"
    },
    {
        "Id": 232,
        "Name": "Las Palmeras -H10 OCEAN SUITES",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Las Palmeras, 1, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7315767,-13.8723293"
    },
    {
        "Id": 233,
        "Name": "MIRA LOBOS",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Avenida De, Calle Gran Canaria, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7306895,-13.8718937"
    },
    {
        "Id": 235,
        "Name": "Suite Hotel Atlantis Fuerteventura Resort - ALUA SUITES",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle las Dunas, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7300238,-13.8593754"
    },
    {
        "Id": 239,
        "Name": "AQUIS LOBOS  RESORT",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7306598,-13.8716652"
    },
    {
        "Id": 242,
        "Name": "VILLAS MARGARITAS",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 17, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7279376,-13.8596925"
    },
    {
        "Id": 246,
        "Name": "Casa Clara",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "c/lentisco",
        "Coordinates": "28.694166395685716,-13.889502252705007"
    },
    {
        "Id": 252,
        "Name": "Muelle Corralejo",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle El Muelle Caseta 14, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.740528469207558,-13.863592431181043"
    },
    {
        "Id": 259,
        "Name": "VILLA DOLPHIN",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pardelas, 7, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7087049,-13.8721812"
    },
    {
        "Id": 261,
        "Name": "Las Pergolas III",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Pergolas III Calle d Villas, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7255452,-13.8615981"
    },
    {
        "Id": 264,
        "Name": "Caleta Del Mar",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "C, Av. Corralejo Grandes Playas, 125, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.726326821107207,-13.849811553955078"
    },
    {
        "Id": 270,
        "Name": "VILLA AURYTUR",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Iglesia, 35, Bajo derecha, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7413052,-13.8674158"
    },
    {
        "Id": 271,
        "Name": "VILLA LOS REMOS",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gueldera, 20, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7266,-13.85205"
    },
    {
        "Id": 272,
        "Name": "VILLA SAN ANTONI",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Antigua, 35610, Las Palmas, Spain",
        "Coordinates": "28.3806451,-13.8692204"
    },
    {
        "Id": 275,
        "Name": "SAN VALENTIN APARTAMENTOS",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 33, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7267,-13.8570682"
    },
    {
        "Id": 276,
        "Name": "Ineika Funcente",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Bahia, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7328377,-13.8664531"
    },
    {
        "Id": 278,
        "Name": "FUE - Fuerteventura Sun Club",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle los Matos, 6, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7262237,-13.8602322"
    },
    {
        "Id": 280,
        "Name": "GOLDEN PARK",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pedro y Guy Vandaele, 19, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7217678,-13.8706735"
    },
    {
        "Id": 281,
        "Name": "APARTAMENTOS PLAYA LOS BARQUERO",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle la Red, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7337731,-13.8689466"
    },
    {
        "Id": 282,
        "Name": "Las Magnolia",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Verilitos, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7263053,-13.8585685"
    },
    {
        "Id": 285,
        "Name": "Las Dunas Primasol",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 29, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.72694,-13.85892"
    },
    {
        "Id": 289,
        "Name": "Corralejo Lodge",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Pedro y Guy Vandaele, N°21, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7239136,-13.8648855"
    },
    {
        "Id": 294,
        "Name": "Aptos. Casas Quemadas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "El, Calle El Charco, 4, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7432545,-13.8684124"
    },
    {
        "Id": 296,
        "Name": "Villa Rezon",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 297,
        "Name": "H10 Ocean Suites",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Las Palmeras, 1, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7315767,-13.8723293"
    },
    {
        "Id": 298,
        "Name": "Surf Rider",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle El Medano, 14, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7272222,-13.8661111"
    },
    {
        "Id": 303,
        "Name": "Jardin Canario",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Urb. Plan Geafond P9, 1, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7174341,-13.8737666"
    },
    {
        "Id": 305,
        "Name": "H10 Ocean Dreams",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Las Palmeras, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7324449,-13.8720565"
    },
    {
        "Id": 306,
        "Name": "Estudio Lili",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Corralejo Grandes Playas, 10, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7328161,-13.8683531"
    },
    {
        "Id": 308,
        "Name": "Villa Bahia bed and b.",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Bahia, 2, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7329001,-13.8661356"
    },
    {
        "Id": 310,
        "Name": "Billabong Surf Camp&Schoo",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "ES Corralejo Las Palmas",
        "Coordinates": "28.7339333,-13.8674278"
    },
    {
        "Id": 312,
        "Name": "Bristol Sunset Beach",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Avenida Juan Carlos I, nº3, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.741558,-13.870729"
    },
    {
        "Id": 314,
        "Name": "Badel Aptos",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "calle la caballa 2, Bloque 3 Local 44, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.729694,-13.86715"
    },
    {
        "Id": 315,
        "Name": "Kalma Aptos REVISAR",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "calle la caballa 2, Bloque 3 Local 44, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.729694,-13.86715"
    },
    {
        "Id": 316,
        "Name": "Perseo Villas",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 319,
        "Name": "Planet SurfCamp",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Caleta de Fuste, 8, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7276834,-13.8658994"
    },
    {
        "Id": 320,
        "Name": "Kala Privilege II",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gueldera",
        "Coordinates": "28.726197102504262,-13.852468544343083"
    },
    {
        "Id": 324,
        "Name": "Villa Dream Cinco",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7198019,-13.8727223"
    },
    {
        "Id": 331,
        "Name": "Line up Surfcamp",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Chorlitejo 7, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7204721,-13.8725656"
    },
    {
        "Id": 333,
        "Name": "Verdemar",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Fuerteventura, 13, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7278051,-13.861659"
    },
    {
        "Id": 338,
        "Name": "La Marquesina",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Av. Marítima, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7391091,-13.8672355"
    },
    {
        "Id": 342,
        "Name": "Neptuno Bung",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 7, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.730264157749577,-13.865373730659485"
    },
    {
        "Id": 343,
        "Name": "Dunasol",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "C. Pedro y Guy Vandaele, 4BIS, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.7256305980031,-13.858598470687866"
    },
    {
        "Id": 345,
        "Name": "Buendia Corralejo hotel",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "C. la Atalaya, 2, 35660 Corralejo, Las Palmas",
        "Coordinates": "28.74426646061471,-13.867909838733231"
    },
    {
        "Id": 427,
        "Name": "Las Olas Apart",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Lugar Diseminado Tiscamanita, 1, 35638 Tuineje, Las Palmas, España",
        "Coordinates": "28.35913,-14.05227"
    },
    {
        "Id": 428,
        "Name": "La Libelula",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Guirre, 10, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.7159417,-13.8692437"
    },
    {
        "Id": 432,
        "Name": "Aptos. Serenade ",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Agua Marina, 6, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7334741,-13.8717208"
    },
    {
        "Id": 2169,
        "Name": "Test ABM",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Gran Canaria, 17, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7301437,-13.8653822"
    },
    {
        "Id": 2318,
        "Name": "Casa Federico",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "Calle Anzuelo, 15, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7359675,-13.8720566"
    },
    {
        "Id": 2535,
        "Name": "Hotel Vacanzy Urban Boutique",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "C. Poril, 2, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.74064598045079,-13.868204149471332"
    },
    {
        "Id": 2550,
        "Name": "Corralejo Garden & Relax",
        "Island": "FV",
        "ZoneId": 309,
        "ZoneName": "Corralejo",
        "Address": "FV-1, 27, 35660 Corralejo, Las Palmas, España",
        "Coordinates": "28.7428608,-13.8659465"
    },
    {
        "Id": 216,
        "Name": "Origo Mare o Villas Lajares",
        "Island": "FV",
        "ZoneId": 310,
        "ZoneName": "Majanicho",
        "Address": "Calle Majanicho, 100, 35650 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.7309355,-13.9480922"
    },
    {
        "Id": 241,
        "Name": "VILLAS INDIRA MORRITOS",
        "Island": "FV",
        "ZoneId": 310,
        "ZoneName": "Majanicho",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 279,
        "Name": "Supermercado Inpescasa Lajares",
        "Island": "FV",
        "ZoneId": 310,
        "ZoneName": "Majanicho",
        "Address": "Calle el Cebo, 4, 35650 La Oliva, Las Palmas, España",
        "Coordinates": "28.67977,-13.94101"
    },
    {
        "Id": 321,
        "Name": "P.Bus Campo de FÃºtbol de Lajares",
        "Island": "FV",
        "ZoneId": 310,
        "ZoneName": "Majanicho",
        "Address": "35650 Lajares, Las Palmas, Spain",
        "Coordinates": "28.6792599,-13.9414954"
    },
    {
        "Id": 2465,
        "Name": "PARADA DE BUS CAMPO DE FUTBOL LAJARES",
        "Island": "FV",
        "ZoneId": 310,
        "ZoneName": "Majanicho",
        "Address": "PARADA DE BUS CAMPO DE FUTBOL LAJARES",
        "Coordinates": "28.679442265758937,-13.933014572824177"
    },
    {
        "Id": 5,
        "Name": "Star Surf Camp",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Av. los Lagos, 35650 El Cotillo, Las Palmas",
        "Coordinates": "28.68475700344078,-14.010280072689056"
    },
    {
        "Id": 6,
        "Name": "BOUTIQUE COTILLO MAR",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Av. los Lagos, 45, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6892617,-14.0105158"
    },
    {
        "Id": 10,
        "Name": "Cotillo Ocean View",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle Francisca Franquis Gonzalez, 10, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6825304,-14.0120184"
    },
    {
        "Id": 55,
        "Name": "Cotillo Lagos",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Urb. Los Lagos Sn, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.69086718972753,-14.011125015938562"
    },
    {
        "Id": 67,
        "Name": "Cotillo Beach Coral",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Av. los Lagos, s/n, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6862664,-14.0101221"
    },
    {
        "Id": 68,
        "Name": "Cotillo Sunset",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Av. los Lagos, 35, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6908468,-14.0112841"
    },
    {
        "Id": 217,
        "Name": "Soul Surfers",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle San Pedro, 2, 35650 El Cotillo, Las Palmas",
        "Coordinates": "28.68503486488101,-14.01188174555733"
    },
    {
        "Id": 218,
        "Name": "Fresh Surf",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "El Carmen, Av. Hermanas del Castillo Carreras, 4, Local 3, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6813521,-14.0076251"
    },
    {
        "Id": 219,
        "Name": "Aptos. Marfolin ",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle la Gomera, 5, 35650 El Cotillo, Las Palmas, España",
        "Coordinates": "28.6888535,-14.0114599"
    },
    {
        "Id": 220,
        "Name": "Balcon De Cotillo",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Urb. los Lagos H4, 15, 35650 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.6902233,-14.0105281"
    },
    {
        "Id": 224,
        "Name": "APARTAMENTOS OLIASTUR",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "de 3, Calle Juan Bethencourt, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6827912,-14.0112927"
    },
    {
        "Id": 266,
        "Name": "APtos BENITEZ",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle la Caleta, 4, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6813925,-14.0109985"
    },
    {
        "Id": 291,
        "Name": "Maravilla Bungalow",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Avenida los Lagos s.n., Maravilla Bungalows, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.692356,-14.012449"
    },
    {
        "Id": 292,
        "Name": "TAO EL COTILLO - APARTAMENTOS EL COTILLO",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Av. Hermanas del Castillo Carreras, 4, 35650 El Cotillo, Las Palmas, España",
        "Coordinates": "28.6833586,-14.0066599"
    },
    {
        "Id": 299,
        "Name": "Denebola Aptos. ",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle Gran Canaria",
        "Coordinates": "28.687160877828372,-14.012264313790682"
    },
    {
        "Id": 313,
        "Name": "Turquesa apartamentos",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Fuerteventura, Las Palmas, Spain",
        "Coordinates": "28.3587436,-14.053676"
    },
    {
        "Id": 325,
        "Name": "PARADA BUS CAMPO FÃšTBOL COTILLO",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.6855572,-14.0108625"
    },
    {
        "Id": 330,
        "Name": "Cotillo Ocean Sunset",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Urb. los Lagos A16, 5, 35650 Los Lagos, Las Palmas, España",
        "Coordinates": "28.6956768,-14.0142031"
    },
    {
        "Id": 336,
        "Name": "Laif hotel",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle San Pedro, 2, 35650 El Cotillo, Las Palmas, Spain",
        "Coordinates": "28.68496,-14.01191"
    },
    {
        "Id": 346,
        "Name": "Parada bus  urb. El roque-Cotillo",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "35650 El Roque, Las Palmas, Spain",
        "Coordinates": "28.6856529,-13.9963524"
    },
    {
        "Id": 399,
        "Name": "Campo de futbol Cotillo",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Calle 3 de Abril de 1979, 35650 El Cotillo, Las Palmas",
        "Coordinates": "28.682396, -14.007950"
    },
    {
        "Id": 2281,
        "Name": "Parada bus  urb. El roque-Cotillo",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "Lugar el Cotillo, 24, 35650 El Roque, Las Palmas, España",
        "Coordinates": "28.68358,-13.99571"
    },
    {
        "Id": 2530,
        "Name": "Cotillo House",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "C. San Pedro, 2, 35650 El Cotillo, Las Palmas, España",
        "Coordinates": "28.684920539032962,-14.0118008852005"
    },
    {
        "Id": 2571,
        "Name": "LACASA Apartments Cotillo",
        "Island": "FV",
        "ZoneId": 311,
        "ZoneName": "Cotillo",
        "Address": "C. Tenerife, 4, 35650 El Cotillo, Las Palmas, España",
        "Coordinates": "28.6870331,-14.0114603"
    },
    {
        "Id": 92,
        "Name": "Mahoh Hotel Rural",
        "Island": "FV",
        "ZoneId": 312,
        "ZoneName": "Villaverde",
        "Address": "Sitio de Juan Bello, s/n, 35640 Villaverde, Las Palmas, Spain",
        "Coordinates": "28.620956,-13.910687"
    },
    {
        "Id": 119,
        "Name": "Villas Casa Vieja & OASIS boutique hotel",
        "Island": "FV",
        "ZoneId": 312,
        "ZoneName": "Villaverde",
        "Address": "Calle el Almendrero, 12, 35640 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.61885,-13.914323"
    },
    {
        "Id": 234,
        "Name": "OASIS RURAL VILLAVERDE",
        "Island": "FV",
        "ZoneId": 312,
        "ZoneName": "Villaverde",
        "Address": "Calle el Almendrero, 12, 35640 La Oliva, Las Palmas, Spain",
        "Coordinates": "28.61885,-13.914323"
    },
    {
        "Id": 301,
        "Name": "Aeropuerto Fuerteventura (FUE)",
        "Island": "FV",
        "ZoneId": 313,
        "ZoneName": "Aeropuerto",
        "Address": "Aeropuerto de Fuerteventura, Ctra, El Matorral, s/n, 35610 Puerto del Rosario, Las Palmas, España",
        "Coordinates": "28.452384,-13.866851"
    },
    {
        "Id": 99,
        "Name": "JM Puerto Del Rosario",
        "Island": "FV",
        "ZoneId": 315,
        "ZoneName": "Puerto Del Rosario",
        "Address": "Av de Ruperto González Negrín, 9, 35600 Puerto del Rosario, Las Palmas, Spain",
        "Coordinates": "28.4990806,-13.8577056"
    },
    {
        "Id": 326,
        "Name": "El Parador Playa Blanca-Mirador de Fuerteventura",
        "Island": "FV",
        "ZoneId": 315,
        "ZoneName": "Puerto Del Rosario",
        "Address": "Ctra. Playa Blanca, 45, 35600 Puerto del Rosario, Las Palmas, Spain",
        "Coordinates": "28.473816,-13.865657"
    },
    {
        "Id": 274,
        "Name": "Wave Guru",
        "Island": "FV",
        "ZoneId": 317,
        "ZoneName": "La Pared",
        "Address": "Av. del Istmo, 17, 35627 La Pared, Las Palmas, España",
        "Coordinates": "28.2124906,-14.219544"
    },
    {
        "Id": 293,
        "Name": "La Pared by Playitas",
        "Island": "FV",
        "ZoneId": 317,
        "ZoneName": "La Pared",
        "Address": "Av. del Istmo, 6, 35627 La Pared, Las Palmas, España",
        "Coordinates": "28.2136831,-14.219261"
    },
    {
        "Id": 329,
        "Name": "WELLENKIND SURFSCHOOL",
        "Island": "FV",
        "ZoneId": 317,
        "ZoneName": "La Pared",
        "Address": "Av. del Istmo, 6, 35627 La Pared, Las Palmas, España",
        "Coordinates": "28.2138535,-14.2192929"
    },
    {
        "Id": 287,
        "Name": "PARQUE HOLANDES",
        "Island": "FV",
        "ZoneId": 318,
        "ZoneName": "Parque Holandes",
        "Address": "35649 Parque Holandés, Las Palmas, Spain",
        "Coordinates": "28.605761,-13.8375669"
    }
];