import { CommonsService 		} from './../../demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild,
		 ViewEncapsulation		} from '@angular/core';
import { ConfirmationService 	} from 'primeng/primeng';

@Component({
 	selector    	: 'app-tours-price-table',
	templateUrl 	: './tours-price-table.component.html',
	styleUrls   	: ['./tours-price-table.component.scss'],
	encapsulation	: ViewEncapsulation.None
})
export class ToursPriceTableComponent implements OnInit {
    
    @Input() 	tablePrice     					:   any               	= {};
    @Output() 	emiterOption					:   EventEmitter<any>   = new EventEmitter();
		@ViewChild('calendarPicker') 	calendarPicker	:	any;
		@ViewChild('execTemplate') 	 	execTemplate	:	any;
		@ViewChild('execDay') 	 		execDay			:	any;
	
	pageInfo:any;

	constructor	( private commons : CommonsService, private confirmCtrl	: ConfirmationService){}
    ngOnInit() 	{ this.init(); }
	init()		{
		this.pageInfo =   {
			contextMenuExecOptions	: [],
			useMode					:	[
				"_TOUR_PRICE_USE_MODE_LIST_1",
				"_TOUR_PRICE_USE_MODE_LIST_2",
				"_TOUR_PRICE_USE_MODE_LIST_3",
				"_TOUR_PRICE_USE_MODE_LIST_4",
				"_TOUR_PRICE_USE_MODE_LIST_5",
				"_TOUR_PRICE_USE_MODE_LIST_6"
			],
			daySelected     		:   null,
			currentTimeSelected		:	null,
			contextMenuExec			:	{ execsCopied	:	[{ idDay: null, execs: [] }]},
			contextMenuDay			:	{ rightClick	:	{}, tmpRightClick : { day: null, idDay: null, execs: [] }, idDay: null },
			contextMenuDayOptions	:	[],
			displayContextExecOpt	:	null,
			displayContextDayOpt	:	null,
			execsSelecteds			:	{ idDay: null, execs: [] },
			lastRowPriceTable		:	{
				id          : null,
				start_hour	: null, 
				prices		: [	{ type:'adult',		price:null},
								{ type:'children',	price:null},
								{ type:'infant',	price:null} 
							],						
			},
			templateWeek    		:   [
				{ name : "_MONDAY", 	turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_TUESDAY",	turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_WEDNESDAY",	turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_THURSDAY",	turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_FRIDAY",		turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_SATURDAY",	turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]},
				{ name : "_SUNDAY",		turns:[{ label:'_MORNING', id:0 },{ label:'_AFTERNOON',id:1 }]}
			],
			controllerGButton	:	{
				day	:	{
					displayCopy		:	false,
					displayDelete	:	false,
					displayOverWrite:	false,
					displayMergeDay	:	false
				},
				exec:	{
					displayCopy		:	false,
					displayDelete	:	false,
					displayPaste	:	false
				}
			}
		};
		this.pageInfo.contextMenuExecOptions = [
			{
				label	: 	this.commons.getTranslate('_COPY'),
				id		:	'copy',
				icon	: 	'pi pi-fw pi-chevron-right',
				command	:	($event) => { this.pageInfo.contextMenuExec.execsCopied = this.pageInfo.execsSelecteds.execs; }
			},
			{
				label 	: 	this.commons.getTranslate('_DELETE'),
				id		:	'copy',
				icon 	:	'pi pi-fw pi-chevron-right',
				command	:	() =>{ this.deleteExecs(); }
			}
		];
		this.pageInfo.contextMenuDayOptions = [
			{
				label		: 	this.commons.getTranslate('_COPY_DAY'),
				icon		: 	'pi pi-fw pi-chevron-right',
				id			:	'copy',
				command		: 	($event) => { 
					this.pageInfo.contextMenuDay.rightClick		=	this.pageInfo.contextMenuDay.tmpRightClick;
					this.pageInfo.execsSelecteds				=	this.pageInfo.contextMenuDay.tmpRightClick;
				}					
			},
			{
				label		: 	this.commons.getTranslate('_PASTE_DAY'),
				icon		: 	'pi pi-fw pi-chevron-left',
				id			:	'paste',
				command		: 	($event) => { this.mergeDay(true); }
			},
			{
				label: this.commons.getTranslate('_DELETE_DAY'),
				icon: 'pi pi-fw pi-chevron-left',
				command		: 	($event) => {
					this.confirmCtrl.confirm({
						message : 	this.commons.getTranslate('_CONFIRM_REMOVE_DAY_PERIOD'),
						header	:	this.commons.getTranslate('_DELETE_DAY'),
						icon	:	'pi pi-exclamation-triangle',
						accept	:	()=>{
							const foundDay = this.tablePrice.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
							if(foundDay == -1) return;
							this.pageInfo.daySelected = this.tablePrice.template[foundDay];
							this.restartRow();   
						}
					});
				}
			}
		];
		this.tablePrice.template.forEach(el => {el.selected = false; el.scheduler = el.scheduler||[]; el.scheduler.forEach(schedule => schedule.editMode = false)});
	}

    toggleEditableRow(row){
        const tmp    = row.editMode ? !row.editMode : true;
        this.tablePrice.template.map(el => el.editMode = false);
		row.editMode = tmp; 
		if(row.editMode){
			this.pageInfo.currentTimeSelected 				= 	JSON.parse(JSON.stringify(row));
			var today 										=	new Date();
			const hours										=	this.pageInfo.currentTimeSelected.start_hour.split(':')[0];
			const minutes									=	this.pageInfo.currentTimeSelected.start_hour.split(':')[1];
			var myToday 									= 	new Date(today.getFullYear(), today.getMonth(), today.getDate(), +hours, +minutes, 0);
			this.pageInfo.currentTimeSelected.start_hour	=	myToday;
			
			console.log(this.pageInfo.currentTimeSelected);
		}else{
			this.emitOption({type: 'update', data : this.pageInfo.currentTimeSelected})
			// this.pageInfo.currentTimeSelected = row;	
		}
    }
    handleOption($event	){	this.emiterOption.emit($event);	}
	deleteRow	(row	){
		console.log('ROW IS', row);
		console.log(this.pageInfo.daySelected);
		this.confirmCtrl.confirm({
			message : 	this.commons.getTranslate('_CONFIRM_REMOVE_DAY_PERIOD'),
			header	:	this.commons.getTranslate('_DELETE_PERIOD'),
			icon	:	'pi pi-exclamation-triangle',
			accept	:	()=>{
					this.emitOption({type:'delete',data : {daySelected : this.pageInfo.daySelected, exec :row}});   
			}
		});
	}
	addRow		()		{
		this.emitOption({type:'add', data : {daySelected : this.pageInfo.daySelected}});
		this.pageInfo.currentTimeSelected = JSON.parse(JSON.stringify(this.pageInfo.daySelected.scheduler.find(el => el.editMode)));
		
	}
	async saveRow(row,copiedValue?, action?){
		// if(this.calendarPicker.value === null) return await this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error'); 
		if(!copiedValue){
			if(this.pageInfo.currentTimeSelected.start_hour == null) return await this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
			this.pageInfo.currentTimeSelected.start_hour =  this.commons.getFormatDateHour(this.pageInfo.currentTimeSelected.start_hour);
			if(this.pageInfo.daySelected.scheduler.find(el => (el.start_hour == this.pageInfo.currentTimeSelected.start_hour) && (el.id != this.pageInfo.currentTimeSelected.id))) return await this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');
			if(!this.checkPricingRow()) return await this.commons.generateToast('_ERROR','_BAD_FORMED_PRICE','error');	
		}
		this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec :copiedValue ? copiedValue : this.pageInfo.currentTimeSelected, action : action}});
	}
    selectDay(day, indexDay, turn){
	  const tmp		=	day.selected ? !day.selected : true;
	  this.pageInfo.templateWeek.map(el => el["selected"] = false);
	  day.selected 	= tmp;
	  const index 	= indexDay == 6 ? 0 : (indexDay +1);

	  if(this.pageInfo.execsSelecteds.idDay == indexDay){
		this.pageInfo.execsSelecteds.idDay	=	null;
		this.pageInfo.execsSelecteds.execs	=	[];
	  }
	  if(day.selected){
		  	const foundDay	=	this.tablePrice.template.find(el => el.day == indexDay);
			if( foundDay ){
				foundDay.selected 			= true;
				this.pageInfo.daySelected 	= foundDay; 
			}else{
				this.tablePrice.template.push(
					{	day			:	indexDay, 
						selected	:	true,
						turns		:	['M','A'],
						scheduler	: [
					]
				});
				this.pageInfo.daySelected 			= 	this.tablePrice.template[this.tablePrice.template.length -1];
			};
			this.pageInfo.contextMenuDay.idDay	=	this.pageInfo.daySelected.day;

			if(this.pageInfo.daySelected.scheduler.length >0){
				this.changeStatusGButton('day','displayCopy',true);
				this.changeStatusGButton('day','displayDelete',true);
			}
			if(this.pageInfo.execsSelecteds.execs.length > 0){
				this.changeStatusGButton('exec','displayDelete',true);
				if(this.pageInfo.daySelected.scheduler.length >0){
					this.changeStatusGButton('day','displayMerge',true);
					this.changeStatusGButton('day','displayOverwrite',true);
					this.changeStatusGButton('day','displayCopy',true);
				}else{
					this.changeStatusGButton('day','displayOverwrite',true);
				}
				if( this.pageInfo.contextMenuExec.execsCopied && (this.pageInfo.contextMenuExec.execsCopied["execs"] && this.pageInfo.contextMenuExec.execsCopied["execs"].length > 0)){
					this.changeStatusGButton('exec','displayPaste',true);
				}
			}
	  }else{
		this.pageInfo.daySelected = null;	
		this.restartGButton(); 
	  }
	}
	getScheduler(indexDay, idTurn){
		if(!this.tablePrice.template) return [];
		// const index = indexDay == 6 ? 0 : (indexDay +1);
		const foundDay	=	this.tablePrice.template.find(el => el.day == indexDay);
		if(!foundDay) return [];
		switch(idTurn){
			case 0 :  	return foundDay.scheduler.filter(el=> el.start_hour <= '12:00').sort((a,b)=> {return a.start_hour > b.start_hour ? 1 : -1});
			case 1 :	return foundDay.scheduler.filter(el=> el.start_hour > '12:00').sort((a,b)=> {return a.start_hour > b.start_hour ? 1 : -1});
		}
		// return foundDay.scheduler;
	}
	
	emitOption			(data)	{	this.emiterOption.emit(data);	}
	getFormatDateHour	(date)	{
		if(date == null){return '-'};
		return this.commons.getFormatDateHour(date);
	}
	getSelectDay		()		{
		const findSelectedDay = this.pageInfo.templateWeek.findIndex(el => el["selected"]);
		if(findSelectedDay < 0) return [];
		const findItem	=	this.tablePrice.template.find(el => el.day == (findSelectedDay));
		if(findItem && findItem.scheduler) return findItem.scheduler.sort((a,b)=> {return a.start_hour > b.start_hour ? 1 : -1});
		return [];
	}
	selectTimePicker	($event){ console.log('TIME PICKER'); console.log($event);	}

	async saveRowKeyUp($event, row){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		if(this.pageInfo.currentTimeSelected.start_hour == null) return await this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		console.log('ROW IS', row);
		this.pageInfo.currentTimeSelected.start_hour =  this.commons.getFormatDateHour(this.pageInfo.currentTimeSelected.start_hour);

		if(this.pageInfo.daySelected.scheduler.find(el => (el.start_hour == this.pageInfo.currentTimeSelected.start_hour) && (el.id != this.pageInfo.currentTimeSelected.id))) return await this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');


		if(!this.checkPricingRow()) return await this.commons.generateToast('_ERROR','_BAD_FORMED_PRICE','error');
		
		this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec :this.pageInfo.currentTimeSelected}});
	}

	checkPricingRow()	{	return !this.pageInfo.currentTimeSelected.prices.some(element => element.price === null || element.price < 0);}

	onRightClickDay($event, contextMenu,idDay, day){

		this.pageInfo.displayContextDayOpt	=	this.pageInfo.contextMenuDayOptions;
		const findExecs						=	this.tablePrice.template.find(el => el.day == idDay);
		if(findExecs && findExecs.scheduler.length > 0){
			if(this.pageInfo.execsSelecteds.execs.length == 0){
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions.filter( el => el.id != 'paste');
			}else{
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions;
				let pasteLabel							=	this.pageInfo.displayContextDayOpt.find(el => el.id == 'paste');
				pasteLabel.label 						= 	this.pageInfo.execsSelecteds.execs.length > 1 ?  (this.commons.getTranslate('_PASTE_MULTIPLES')) : (this.commons.getTranslate('_PASTE_HOUR')+' '+this.pageInfo.execsSelecteds.execs[0].start_hour);  
			}
			this.pageInfo.contextMenuDay["tmpRightClick"] = { day : day, idDay : idDay, execs :findExecs.scheduler }; 
		}else{
			if(this.pageInfo.execsSelecteds.execs.length > 0){
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions.filter(el => el.id == 'paste');
				let pasteLabel							=	this.pageInfo.displayContextDayOpt.find(el => el.id == 'paste');
				pasteLabel.label 						= 	this.pageInfo.execsSelecteds.execs.length > 1 ?  (this.commons.getTranslate('_PASTE_MULTIPLES')) : (this.commons.getTranslate('_PASTE_HOUR')+' '+this.pageInfo.execsSelecteds.execs[0].start_hour);  

			}else{
				this.pageInfo.displayContextDayOpt		=	[];
			}
			// this.pageInfo.contextMenuDay.tmpRightClick	=	{day : day,idDay : idDay}
		}

		this.pageInfo.contextMenuDay["day"	]			=	day;
		this.pageInfo.contextMenuDay["idDay"]			=	idDay;
		contextMenu.show($event); 
		$event.stopPropagation();
	}
	 restartRow(){
		this.emiterOption.emit({type:'restart', data : {daySelected : this.pageInfo.daySelected}});
	}
	onRightClickExec($event, contextMenu){
		// this.pageInfo.displayContextExecOpt	=	this.pageInfo.contextMenuExec.execsCopied ? this.pageInfo.contextMenuExecOptions : [this.pageInfo.contextMenuExecOptions[0]];

		this.pageInfo.displayContextExecOpt	=	this.pageInfo.contextMenuExecOptions;
		let labelCopy						=	this.pageInfo.displayContextExecOpt.find(el => el.id == 'copy');
		if(this.pageInfo.execsSelecteds.execs.length > 1){
			labelCopy.label = this.commons.getTranslate('_COPY_SELECTEDS');
		}else{
			labelCopy.label = this.commons.getTranslate('_COPY_HOUR');
		}

		contextMenu.show($event);$event.stopPropagation();
	}

	selectExec($event,execs,item,indexExec, indexDay,exec?){
		$event.stopPropagation();
		if($event && ($event.shiftKey || $event.ctrlKey)){
			const fillUntil	=	execs[indexExec];
			execs = this.tablePrice.template.find(el=> el.day == indexDay).scheduler;
			switch(true){
					case $event.shiftKey===true :
						this.pageInfo.execsSelecteds.idDay	=	+(this.pageInfo.execsSelecteds.idDay.toString() || indexDay.toString());
						if(indexDay == this.pageInfo.execsSelecteds.idDay){
							const fromValue	=	execs.findIndex(el => el.id == this.pageInfo.execsSelecteds.execs[0].id);
							const fromIndex	=	execs.findIndex(el => el.id == fillUntil.id);
							const deducedIndex	=	(fromValue > fromIndex)  ? (fromIndex) : (fromIndex+1);
							execs.slice(Math.min(deducedIndex,fromValue), Math.max(deducedIndex, fromValue)).forEach(el =>{
								const findExec = this.pageInfo.execsSelecteds.execs.find(item => item.id == el.id);
								if(findExec) return;
								this.pageInfo.execsSelecteds.execs.push(el);
							})	
						}else{
							this.commons.generateToast('_ERROR', '_DIFFERENT_DAY_SELECTED', 'error');
							return;
						}
					break;
					case $event.ctrlKey===true :
						console.log('CLICKED CTRL');
						this.pageInfo.execsSelecteds.idDay	=	+(this.pageInfo.execsSelecteds.idDay.toString() || indexDay.toString());
						if(indexDay == this.pageInfo.execsSelecteds.idDay){
							const findExec	= this.pageInfo.execsSelecteds.execs.findIndex(el => el.id == exec.id);
							if(findExec > -1 ){
								this.pageInfo.execsSelecteds.execs.splice(findExec,1);
							}else{
								this.pageInfo.execsSelecteds.execs.push(exec);
							}
						}else{
							this.pageInfo.execsSelecteds.idDay = indexDay;
							this.pageInfo.execsSelecteds.execs = [];
							this.pageInfo.execsSelecteds.execs.push(execs[indexExec]);
						}
							
					break;
					default: return;
			}
		}else{
			const findExec	= this.pageInfo.execsSelecteds.execs.findIndex(el => el.id == execs[indexExec].id);
			if(findExec > -1 ){
				this.pageInfo.execsSelecteds.idDay	=	null;
				this.pageInfo.execsSelecteds.execs	=	[];
				// this.pageInfo.execsSelecteds.splice(findExec,1);
			}else{
				this.pageInfo.execsSelecteds.execs	=	[];
				this.pageInfo.execsSelecteds.idDay	=	indexDay
				this.pageInfo.execsSelecteds.execs.push(execs[indexExec])
			}	
		}
		if(this.pageInfo.execsSelecteds.execs.length > 0){
			this.changeStatusGButton('exec','displayCopy',true);
			this.changeStatusGButton('exec','displayDelete',true);
		}else{
			this.changeStatusGButton('exec','displayCopy',false);
			this.changeStatusGButton('exec','displayDelete',false);
		}
	}
	getBackgroundRibbon(execs,i){
		if(!this.pageInfo.execsSelecteds) return '#3e9ac3';
		return this.pageInfo.execsSelecteds.execs.find(el => el.id == execs[i].id) ? 'tomato' : '#3e9ac3';
	}
	compareExecs(el, execCopied){
		console.log('COMPARE');
		const execDay 		= 	JSON.parse(JSON.stringify(el));
		const execToCopy	=	JSON.parse(JSON.stringify(execCopied));
		delete execDay.id;
		delete execDay.editMode;
		delete execToCopy.id;
		delete execToCopy.editMode;
		return JSON.stringify(execDay) == JSON.stringify(execToCopy);
	}

	mergeDay(fromContextMenu?){
		const foundDay = this.tablePrice.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
		if(foundDay > -1){
			let tmpDaySelected			= JSON.parse(JSON.stringify(this.tablePrice.template[foundDay]));
			this.pageInfo.daySelected 	= this.tablePrice.template[foundDay];

			this.pageInfo.execsSelecteds.execs.forEach(el=>{
				if(this.tablePrice.template[foundDay].scheduler.some(execCopied => el.start_hour == execCopied.start_hour)) return;
				const newExec 	= 	JSON.parse(JSON.stringify(el));
				newExec.id		=	this.commons.generateHash();
				tmpDaySelected.scheduler.push(newExec);
			});
			this.mergeHoursPeriod(tmpDaySelected);
		}else{
			this.addHoursToEmptyDay();
		}
		this.commons.generateToast('_SUCCESS','_EXECS_COPIED','success');
		// CHECK IF FROM CONTEXT; IF CONTEXT, TRUE, ELSE; FALSE
		if(fromContextMenu){
			this.selectDay(this.pageInfo.contextMenuDay["day"],this.pageInfo.contextMenuDay["idDay"],''); 
		}				
	}
	overwriteDay(fromContextMenu){
		const foundDay	=	this.tablePrice.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
		if(foundDay > -1){
			let tmpDaySelected			=	JSON.parse(JSON.stringify(this.tablePrice.template[foundDay]));
			this.pageInfo.daySelected 	= this.tablePrice.template[foundDay];

			tmpDaySelected.scheduler	=	[];
			tmpDaySelected.scheduler	=	this.pageInfo.execsSelecteds.execs
												.map( el =>{
													const newExec = JSON.parse(JSON.stringify(el));
													newExec.id = this.commons.generateHash(); 
													return newExec; 
												});
			this.mergeHoursPeriod(tmpDaySelected);
		}else{
			this.addHoursToEmptyDay();
		}
		if(fromContextMenu){
			this.selectDay(this.pageInfo.contextMenuDay["day"], this.pageInfo.contextMenuDay["idDay"],'');
		}
	}
	addHoursToEmptyDay(){
		this.tablePrice.template.push({
			day			:	this.pageInfo.contextMenuDay["idDay"],
			selected	:	true,
			turns		:	['M','A'],
			scheduler	: 	[]
		});
		let tmpDaySelected		 =	JSON.parse(JSON.stringify(this.tablePrice.template[this.tablePrice.template.length-1]));
		tmpDaySelected.scheduler =	this.pageInfo.execsSelecteds.execs
										.map( el =>{
											const newExec = JSON.parse(JSON.stringify(el));
											newExec.id = this.commons.generateHash(); 
											return newExec; 
										});
		this.pageInfo.daySelected = this.tablePrice.template[this.tablePrice.template.length -1];
		this.mergeHoursPeriod(tmpDaySelected);
	}
	mergeHoursPeriod(tmpDaySelected){
		this.emiterOption.emit({type:'merge', data : {daySelected : this.pageInfo.daySelected, tmp	:	tmpDaySelected}});
	}
	restartLastRow(){
		this.pageInfo.lastRowPriceTable	=	{
			id          : null,
			start_hour	: null, 
			prices		: [ 
				{ type: 'adult', 	price: null },
				{ type: 'children', price: null },
				{ type: 'infant', 	price: null }
			]
		}
	}
	saveLastRow(){
		if(this.pageInfo.lastRowPriceTable.start_hour == null) return this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		this.pageInfo.lastRowPriceTable.start_hour =  this.commons.getFormatDateHour(this.pageInfo.lastRowPriceTable.start_hour);
		
		if(this.pageInfo.daySelected.scheduler.find(el => (el.start_hour == this.pageInfo.lastRowPriceTable.start_hour))) return this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');
		if(this.pageInfo.lastRowPriceTable.prices.some(el => el.price <0 || el.price == null)) return this.commons.generateToast('_ERROR','_BAD_FORMAT_PRICE','error');

		this.emiterOption.emit({type:'lastRow', data : {daySelected : this.pageInfo.daySelected, exec : JSON.parse(JSON.stringify(this.pageInfo.lastRowPriceTable))}});
		this.restartLastRow();
	}
	saveLastRowKeyUp($event){	if($event.keyCode != 13 && $event.code != 'Enter') return; this.saveLastRow(); }
	checkIfStartGButton()	{
		return (Object.values(this.pageInfo.controllerGButton.day).every(el => el==false) && Object.values(this.pageInfo.controllerGButton.exec).every(el => el==false));
	}
	changeStatusGButton(type,property,value){
		this.pageInfo.controllerGButton[type][property] = value;
		console.log(this.pageInfo.controllerGButton);
	}
	getStatusGButton(type,property){ return !this.pageInfo.controllerGButton[type][property];	}
	restartGButton(){
		let entriesDay	=	this.pageInfo.controllerGButton.day;
		let entriesExec	=	this.pageInfo.controllerGButton.exec;
		Object.keys(entriesDay).forEach(entry =>{
			this.pageInfo.controllerGButton.day[entry] = false;
		});
		Object.keys(entriesExec).forEach(entry =>{
			this.pageInfo.controllerGButton.exec[entry] = false;
		})
	}
	copyDayGB(){
		// this.pageInfo.contextMenuDay.idDay				=	this.pageInfo.daySelected.day;
		this.pageInfo.contextMenuDay.tmpRightClick		=	{day:null, idDay:this.pageInfo.daySelected.day, execs : this.pageInfo.daySelected.scheduler};
		this.pageInfo.execsSelecteds					=	{idDay	:	this.pageInfo.daySelected.day, execs	:	this.pageInfo.daySelected.scheduler};
	}
	deleteDayGB(){
		this.confirmCtrl.confirm({
			message : 	this.commons.getTranslate('_CONFIRM_REMOVE_DAY_PERIOD'),
			header	:	this.commons.getTranslate('_DELETE_PERIOD'),
			icon	:	'pi pi-exclamation-triangle',
			accept	:	()=>{
				// const foundDay = this.tablePrice.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
				// if(foundDay == -1) return;
				// this.pageInfo.daySelected = this.tablePrice.template[foundDay];
				this.restartRow();   
			}
		});	}

	copyExecsGB(){
		this.pageInfo.contextMenuExec.execsCopied =   this.pageInfo.execsSelecteds;
	}
	deleteExecs(){
		//Search the day on the template with the current execs selecteds that have to be delete it
		let foundDay				=	this.tablePrice.template.find(item => item.day == this.pageInfo.execsSelecteds.idDay);
		if(!foundDay)	return this.commons.generateToast('_ERROR','_COULD_NOT_FIND_DAY','error');
		this.pageInfo.daySelected	=	foundDay;

		//filter the execs from the day
		console.log(this.pageInfo.execsSelecteds);
		let execsToDelete	=	foundDay.scheduler.filter(el => this.pageInfo.execsSelecteds.execs.some(item => item.id == el.id));
		console.log(execsToDelete);
		this.deleteMultipleExecs(execsToDelete);

	}

	deleteMultipleExecs(execsToDelete){
		this.emiterOption.emit({type:'deleteMultiple', data : {daySelected : this.pageInfo.daySelected, execsToDelete	:	execsToDelete}});
	}
}