export const filters = [			
	{
		entity	: 'zones',
		name	: 'zones',
		label	: '_ZONES',
		type	: false,
		field	: 'zones',
		items	: [],
		selected: []
	}
];

// {
// 	entity	: 'transfers',
// 	name	: 'transfers',
// 	label	: 'Transfers',
// 	type	: 'multiple',
// 	field	: 'type',
// 	items	: [
// 		{ label: 'Arrival', 	value: 'arrival' 	},
// 		{ label: 'Departure', 	value: 'departure' 	}
// 	],
// 	selected: [ "arrival", "departure" ]
// }