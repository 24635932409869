import { CommonsService } from './../../service/commons.service';
import { FirebaseService } from 'src/app/demo/service/database/firebase.service';
import { EntityService } from 'src/app/demo/service/entity.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { Component, OnInit, ViewEncapsulation, NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { ToastrService                          } from 'ngx-toastr';
import { MapsAPILoader 							} from '@agm/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/primeng';

@Component({
    styleUrls		: ['./test.component.scss'],
	templateUrl		: './test.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class TestComponent implements OnInit{

    booking   = {
        base                        : "PMI",
		direction					: "arrival",
		addressInfo					: "Carrer de font i monteros, 1",
        adults						: "3",
        area						: "Sa Coma",
        arrival_Date				: "2019-04-09",
        arrival_From				: "Majorca (Palma de Mallorca Airport) (PMI)",
        arrival_GatewayFrom			: "Liverpool John Lennon Airport (LPL), United Kingdo",
        arrival_GatewayInfo			: "U2/EZY7143",
        arrival_GatewayTo			: "Liverpool John Lennon Airport (LPL), United Kingdo",
        arrival_PickupDate			: "",
        arrival_PickupTime			: "",
        arrival_StartingPointType	: "AP",
        arrival_Time				: "22:20",
        arrival_To					: "Sa Coma",
        bookingDate					: "20190319",
        children					: "0",
        company						: "RH",
        customer					: "MR Healey John",
        date						: "20190319",
        departure_Date				: "2019-04-12",
        departure_From				: "Sa Coma",
        departure_GatewayFrom		: "Liverpool John Lennon Airport (LPL), United Kingdo",
        departure_GatewayInfo		: "EZY7144",
        departure_GatewayTo			: "Liverpool John Lennon Airport (LPL), United Kingdo",
        departure_PickupDate		: "",
        departure_PickupTime		: "",
        departure_StartingPointType	: "RT",
        departure_Time				: "22:55",
        departure_To				: "Majorca (Palma de Mallorca Airport) (PMI)",
        error						: true,
        expanded					: false,
        id							: 101585,
        infants						: "0",
        location					: "allsun Hotel Orient Beach, Sa Coma, Spain",
        pax							: "3",
        phone						: "44  7847778720",
        price						: "56.7",
        reference					: "G2090114",
        status						: "AM",
        transferType				: "RETURN",
        vehicle						: "Shared shuttle",
        vehicles					: "3",
        verified					: "no",
    }
    hotels 			: 	any = 	{};
	areas			:	any	=	{};
	flights			:	any = 	{};
    tourvariants	:	any = 	{};
    userInfo        :   any =   {};
	pageInfo:	any = 	{
        dataLoaded          :   false,
        indexTabCalendar    :   0,
        variant             :   {}
	}
	constructor(private entityService 	: EntityService, 
				private commons 		: CommonsService, 
                private storage 		: StorageService,
                private firebaseCtrl    : FirebaseService,
                private confirmCtrl     : ConfirmationService){}

	async loadEntitites(){
		await this.load('tourvariants');        

		await this.load('areas');
        // await this.load('flights');
		await this.load('hotels');
		this.pageInfo.dataLoaded = true;		
	}

    async ngOnInit(){
        this.userInfo	=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
        console.log('MY USER INFO', this.userInfo);
		this.pageInfo.providers	= [
            { source: 'Aviation Edge'   , field : 'aviation_edge'},
			{ source: 'AENA' 			, field : 'aena'},
			{ source: 'SkyScanner' 		, field : 'skyscanner'},
			{ source: 'FlightStats' 	, field : 'flightstats'},
			{ source: 'FlightRadar24'	, field : 'flightradar24'},
			{ source: 'FlightWise' 		, field : 'flightwise'},
			{ source: 'ViewTrip' 		, field : 'viewtrip'}
		];
		await this.loadEntitites();
    }

    async load($entity){
        switch($entity){
            case 'hotels'	:
                this[$entity].data 		= 	[];
                this[$entity].loading   =   true;
                await this.entityService.loadEntity($entity);
                this[$entity].data 		= 	this.commons.getEntity($entity);
                this.paginate($entity,null);
                this[$entity].count 	= 	this[$entity].data ? this[$entity].data.length : 0;
                this[$entity].loading   =   false;
                // console.log(this.hotels.data);
				// this.pageInfo.lodgingsFilteredByArea = this.hotels.data; 
				console.log("HOTEL DATA",this[$entity]);                              
                break;

            case 'areas'	:
                this[$entity].data 		= 	[];
                this[$entity].loading   =   true;
                await this.entityService.loadEntity($entity);
                this[$entity].data 		= 	this.commons.getEntity($entity);
                this.paginate($entity,null);
                this[$entity].count 	= 	this[$entity].data ? this[$entity].data.length : 0;
                this[$entity].loading   =	false;
                console.log("AREA DATA",this[$entity].data);              
                break;

            case 'flights'	:
                // this[$entity].data 		= [];
                // this[$entity].loading   =   true;
                // await this.entityService.loadEntity($entity);
				// // this.commons.setEntity('flights', 		await this.entityService.loadEntity($entity));
				// // this.commons.setEntity('flightsList', 	await this.entityService.getList('flights'));
				// // this[$entity].data 		=	this.commons.getEntity($entity);
				// this[$entity].data		= this.entityService.get("flights");
                // this.paginate($entity,null);
                // this[$entity].count 	= 	this[$entity].data ? this[$entity].data.length : 0;
                // this[$entity].loading   =	false;
                // console.log("FLIGHT DATA 2",this[$entity].data);              
                break;
            case 'tourvariants'  :
                this[$entity].data 		        = 	[];
                this[$entity].loading           =   true;
                this[$entity].count 	        = 	this[$entity].data ? this[$entity].data.length : 0;
                this[$entity].loading           =   false;
                console.log(this.userInfo);
                this.firebaseCtrl.getVariantInfo(this.userInfo.idDmc,this.userInfo.currentDestination.id, '0', '0').subscribe(variant =>{
                    console.log('variant test', variant);
                    if(variant){
                        this.pageInfo.variant          = {};
                        this.pageInfo.variant          = variant;
                        this.pageInfo.variant.calendar.compositor   = this.pageInfo.variant.calendar.compositor || this.mountCalendar(); 
                        this.pageInfo.displayPricing                = true;
                    }else{
                        this.initCalendarVariant();
                    }
                });
                console.log('VARIANTS!!',this[$entity]);
            break;
        }
    }
    initCalendarVariant(){
        this.pageInfo.variant           =   {}
                        this.pageInfo.variant.calendar  = {
                            schedule	:	{
                                periods     :   [],
                                days        :   []
                            },
                            offers		:	{
                                periods		:	[],
                                days		:	[]
                            },
                            compositor      :   {}
                        };
    }
	
	handleOption($event){
		console.log('ROW DATA SELECTED', $event);
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length 			= !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate 	= !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}
	
    getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: switch($col.field) {
                                                case 'profile'          : return $items[$col.field];
                                                case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
												default 		 		: return $items[$col.field]}
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
    }

    onChangeTabView($event){
        console.log($event);
        console.log(this.pageInfo.variant);
        if($event.index == 2){
            this.pageInfo.variant.calendar.compositor   = this.mountCalendar(); 
        }
        this.pageInfo.indexTabCalendar = $event.index;
    }
    mountCalendar(){
        // console.log('MOUNTING CALENDAR');
        if(undefined==this.pageInfo.variant.calendar){ return false; }
        const listDays  =   Array.from(new Set(this.pageInfo.variant.calendar.offers.days.map(el => el.day).concat(this.pageInfo.variant.calendar.schedule.days.map(el => el.day))));
        return listDays.map(day => {
            let foundComputed;
            if(Object.keys(this.pageInfo.variant.calendar.compositor).length ==0){
                foundComputed = null;
            }else{
                foundComputed    =   this.pageInfo.variant.calendar.compositor.find(el => el.day == day);
                if(foundComputed){
                    foundComputed = foundComputed.computed;
                }else{
                    foundComputed = null;
                }
            }
            console.log('computed', foundComputed);
            return {    day         : day, 
                        computed    : foundComputed || null,
                        offers      : this.pageInfo.variant.calendar.offers.days.find(el => el.day == day)		|| null,
                        schedule    : this.pageInfo.variant.calendar.schedule.days.find(el => el.day == day) 	|| null
                    }
				});
	}
    saveVariant(){
        this.storage.setItem('variant', this.pageInfo.variant); 
        this.commons.generateToast('_INFO','Variante temporal guardada','info');
        let tourId  =   '0';
        console.log('SAVING VARIANT TO FIREBASE',this.pageInfo.variant);
        this.firebaseCtrl.setVariantInfo(this.userInfo.idDmc,this.userInfo.currentDestination.id,tourId,'0', this.pageInfo.variant);
    }
    restartVariantData(){
        this.confirmCtrl.confirm({
            message : 	this.commons.getTranslate('_CONFIRM_RESTART_CALENDAR'),
            header	:	this.commons.getTranslate('_RESTART_CALENDAR'),
            icon	:	'pi pi-exclamation-triangle',
            accept	:	()=>{
                this.initCalendarVariant();
                this.saveVariant();
            }
        })
    }
    restartDialogConfig(){
        this.storage.deleteItem('answeredDialogPeriod');
        this.storage.deleteItem('answeredDialogCalendar');
        this.commons.generateToast('_INFO','_PERIOD_CONFIG_RESTARTED','info');
    }
}