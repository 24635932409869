import { SuperformService 				} from './../../../service/super-form/superform.service';
import { AggregatorsService 			} from './../../../service/database/aggregator.service';
import { AuthFirebaseService 			} from './../../../service/database/authfirebase.service';
import { ConfirmationService 			} from 'primeng/api';
import { CompanyService 				} from './../../../service/database/company.service';
import { DestinationsService 			} from 'src/app/demo/service/database/destinations.service';
import { FirebaseService 				} from 'src/app/demo/service/database/firebase.service';
import { StorageService 				} from 'src/app/demo/service/storageservice';
import { Component, 
		 OnInit, 
		 ViewEncapsulation, 
		 ViewChild 						} from '@angular/core';
import { EntityService 					} from '../../../service/entity.service';
import { CommonsService 				} from 'src/app/demo/service/commons.service';
import { Router							} from '@angular/router';
import { pageInfo 						} from './data/pageInfo';
import { TransportService 				} from 'src/app/demo/service/transports/transports.service';

@Component({
	styleUrls		: ['./create-destination.component.scss'],
	templateUrl		: './create-destination.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class CreateDestinationComponent implements OnInit {
    
	pageInfo	:	any	=	{};
	
	@ViewChild('createZonesComponent'	)	createZonesComponent	:	any;
	@ViewChild('companyComponent'		)	companyComponent		:	any;

    constructor(
        private commons				: CommonsService,
		private entityService		: EntityService,
		private storage				: StorageService,
		private firebaseCtrl		: FirebaseService,
		private destinationServices	: DestinationsService,
		private companyCtrl			: CompanyService,
		private router				: Router,        
		private authService			: AuthFirebaseService,
		private confirmationService	: ConfirmationService,
		private superFormCtrl		: SuperformService,
		private aggregatorCtrl		: AggregatorsService,
		private transportCtrl		: TransportService
    ) {
		this.init();
	}

	init()	{	
		this.pageInfo							= pageInfo;
		this.pageInfo.displayData				= false;
		this.pageInfo.mode						= "create";
		this.commons.pageInfo.creationProcess	= true;
		this.pageInfo.listMonths 	= this.pageInfo.listMonths.map( month => {
			let info = {
				...month, 
				label : this.commons.getTranslate(month.label)
			};
			return info;
		});

		this.restartSteps();
		console.log(this.pageInfo);
	}

	async ngOnInit() {
		await this.loadEntities(this.pageInfo.globalEntities);
		this.pageInfo.displayData	= true;
		switch(this.commons.pageInfo.creationProcess)	{
			case true	:	this.initForms(); 		break;
			case false	:	this.disableSteps();	break;
		}
	}
	
	async loadEntities(entities) 				{	
		for( let entity of entities ){ 
			await this.load(entity);
		}
	}


	// CONTROL PANEL --------------------------------------------------------------------------------------------------

	getInfo($type,$info,$params={})				{	
		switch($type)	{
			case "steps"		:
				switch($info.type){
					case "visible"	: return this.checkIfDisplaySteppers();
				}
				break;
					
			case "step"			:
				let step = this.getCurrentStep();				
				switch($info.type){
					case "visible"	: return this.checkIfDisplaySteppers();
					case "class"	: return (step||{}).class;
					default			:
					case "value"	: 
						return (step||{}).value;
				}
				break;

			case "flags"		:	
				return 'https://raw.githubusercontent.com/hjnilsson/country-flags/master/png100px/'+$info+'.png';
				
			case "button"		:	
				switch($info["action"]){
					case "translate":	
						return this.commons.getTranslate(Array.isArray($info.item)?$info.item.label:$info.item);					
				}
		}
	}

	getTabInfo($type,$tab,$panel,$params={})	{	
		switch($type)	{	
			case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
			case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
								return this.isDisabled($tab["isDisabled"],$tab);
			case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
		}
	}

	isDisabled($type,$item)						{	
		switch($type){	
			case "transferBookings"	:	
				return !this.pageInfo.selectedRoutes.bookingPanelOpen; break;	
			}
	}

	// END CONTROL PANEL ----------------------------------------------------------------------------------------------

	initForms(){
		console.group("initForms");
		const destinationEntities		=	{}
		
		Object.entries(this.pageInfo.entities).forEach(([key,value]) => 
			destinationEntities[key] = value['data'] ? value['data'] : []
		);
		console.log('Destination entities',destinationEntities);

		this.pageInfo.accounts			= {
			rowExpander	:	[
				{ label: '_USER_ACCOUNT', 	type: 'field',	field: 'account' 																	},
				{ label: '_HIRE_INIT', 		type: 'fn',		field: 'hire_init',		fn: (item)=>{ return this.commons.formatTimestamp(item.hire_init) 	}},
				{ label: '_HIRE_END', 		type: 'fn',		field: 'hire_end',		fn: (item)=>{ return this.commons.formatTimestamp(item.hire_init) 	}},
				{ label: '_DESTINATIONS', 	type: 'reduce',	field: 'destinations',	fn:	(item)=>{ return item;									}}
			]
		};

		this.pageInfo.destinationForm		=	{	ready		: true,
													entities	: [],
													form		: pageInfo.destinationForm.form
												};

		this.pageInfo.providerForm			=	{	ready		: false,
													entities	: this.pageInfo.entities,			
													form		: pageInfo.providerForm.form,
													buttons		: pageInfo.providerForm.buttons
												};

		this.pageInfo.timerFormProvider		=	{	ready		:	true,
													entities	:	null,
													form		:	pageInfo.timerFormProvider.form,
													info	:	{	start_hour	:	new Date()	}
												};
		
		this.pageInfo.settingsDestination	=	{	ready		:	true,
													entities	:	null,
													info		:	{},
													form		:	pageInfo.settingsDestination.form,
													butons		:	pageInfo.settingsDestination.buttons
												}
		console.groupEnd();
	}
	
	async loadStations()				{	

		if( this.pageInfo.listCurrentStations ){	return;	}
		
		this.setLoading('stations',true);
		
		let areas				=	await this.destinationServices.getAreasFromDestination( this.commons.pageInfo.destinationSelected.refAreas );											
		this.pageInfo.infoAreas	=	{
			id			:	this.commons.pageInfo.destinationSelected.id,
			areas		:	areas,
			stations	:	await this.destinationServices.loadStationsFromAreas(this.pageInfo.countrySelected,areas)			
		}

		// if(this.commons.pageInfo.destinationSelected.workingStations){	this.setUpStations();	}											
		this.setLoading('stations',false);
	}

	setLoading(type, value)				{	
		this.pageInfo.loading[type]	=	value;	
	} 
	
    async load($entity){
		let response  : any = {};
		let item;

		switch($entity){
			case 'modules'			:	

				response =	await this.companyCtrl.getModules();
				this.pageInfo.entities[$entity].data	=	response.modules;
				break;

			case 'countries'		:

				// From Firebase
				const data								=	await this.destinationServices.getCountries();
				data.forEach((item : any) => item.name 	=	this.commons.getTranslate(item.label));
				this.pageInfo.entities[$entity].data	=	data;
				console.log('COUNTRIES DMC',this.pageInfo.entities[$entity].data);						
				break;
			
			case 'destinations'		:

				// FROM DB
				response								= await Promise.resolve(this.entityService.getComplexRequest('entity',{ entity: 'destination' },{}));
				this.pageInfo.entities[$entity].data	= response["data"].map(item=>{ item.selected = item.id==1; return item; });
				console.log('DESTINATIONS DMC',this.pageInfo.entities[$entity].data);
				
				// Refresh dropdown filter
				item = this.pageInfo.filters.find(el => el.name == 'destination');
				if(item){
					item.items	= this.pageInfo.entities[$entity].data.map(item=>{
						let info = { 
							label: item.name, 
							value: item.id, 
							selected: item.id==1 
						};
						return info;
					});
				}
				break;

			case 'providers' 		:

				this.pageInfo.entities[$entity].data	=	[];
				this.pageInfo.form.providers			=	[];
				
				this.pageInfo.entities[$entity].data	= 	await this.aggregatorCtrl.getProviders();
				this.pageInfo.entities[$entity].data	= 	this.pageInfo.entities[$entity].data.map(item=>{
					item.logo = "/assets/layout/icons/providers/"+item.thumbnail+".png";
					return item;
				});
				
				console.log('LIST PROVIDERS:', this.pageInfo.entities[$entity]);

				if(!this.commons.pageInfo.dmcSelected){ 
					return; 
				}
				if( !this.commons.pageInfo.dmcSelected.id || this.commons.pageInfo.dmcSelected.isNew ){ 
					this.pageInfo.providersFromCompany = []; 
					return; 
				}
				
				this.pageInfo.providersFromCompany		=	await this.aggregatorCtrl.getProviderAccountsFromDmc(this.commons.pageInfo.dmcSelected.id);
				this.pageInfo.providersFromCompany		=	this.pageInfo.providersFromCompany || [];
				
				// Set hire duration
				this.pageInfo.providersFromCompany.forEach(provider => {
					provider.accounts.forEach(account =>{
						account.hire_end			=	new Date(this.commons.formatTimestamp(account.hire_end));
						account.hire_init			=	new Date(this.commons.formatTimestamp(account.hire_init));
						})
					}
				);

				console.log('AGGREGATORS FROM COMPANY', this.pageInfo.providersFromCompany);										
				break;
		}
	}

	getFilteredEntity($entity,$info)	{	
		switch($entity){
			case "destinations"		:	return ($info["country"] || []).dmcDestinations;
			case "dmcs"				:	return this.commons.getEntity($entity);																		
			case "staff"			: 	return ( this.pageInfo.entities[$entity].data||[]).filter(item=>{ return true; });
		}
	}
	
	checkIfDisplaySteppers()			{
		let currentStep = this.getCurrentStep();
		if(!currentStep){ return false; }
		switch(currentStep.id){
			default		:	return true;
			case	0	:	return this.pageInfo.mode == "edit";
		}
	}

	async onClickStepper($event)		
	{	
		let response;

		switch($event.step.directClick){
			default								:	
				response 	= 	{ success : true }; 
				break;

			case	'checkDmcSelected'			:	
				response	=	{ 
					success		: this.commons.pageInfo.dmcSelected	? true	: false, 
					msgError 	: this.commons.pageInfo.dmcSelected ? null 	: '_NO_DMC_SELECTED'
				}; 							
				break;

			case	'checkDestinationSelected'	:	
				response	=	{ 
					success 	: this.commons.pageInfo.destinationSelected	? true : false, 
					msgError 	: this.commons.pageInfo.destinationSelected ? null : '_NO_DESTINATION_SELECTED'
				};
				break;

			case	'checkAggregatorAssigned'	:	
				response	=	{ 
					success		: true,
					msgError	: null
				}
				break;
		}

		if(!response.success){
			return this.commons.generateToast('_ERROR',response.msgError, 'error');
		}
		
		this.pageInfo.steps.forEach(el => {el.active = false; el.class = null});
		this.pageInfo.steps[$event.step.id].active = 	true;
		this.checkIfInfoMapZones();
		await this.execInitFunctionStep();

	}

	createNewDmc()						{	
		this.pageInfo.mode							= "create";
		this.initForms();
		this.pageInfo.filterableListDestinations	=	[];
		
		this.commons.entities.dmcs.forEach(dmc => { 
			dmc.selected = false; 
			this.commons.userInfo.selectedDmc = dmc;
		});

		this.commons.pageInfo.dmcSelected			=	null;
		this.pageInfo.displayFooterButtons 			=	true;
		this.changeMultiplePropertyWizardButton('back',{ disabled: false, label: '_CANCEL', icon: 'fa fa-fw fa-remove', customAction: 'cancelEditDmc'});
		this.changeMultiplePropertyWizardButton('next',{ disabled: false, label: '_NEXT', 	icon: 'fa fa-fw fa-arrow-right' });
	}
	
	async selectDmc($event,dmc)		{	
		this.commons.toggleItem(dmc,this.commons.entities.dmcs,'selected');

		this.commons.pageInfo.dmcSelected			=	dmc.selected	? dmc		:	null;
		this.pageInfo.refDmcSelected				=	dmc.selected	? dmc.ref	:	null;
		this.commons.pageInfo.destinationSelected	=	null;
		this.pageInfo.infoAccountsProvider			=	null;

		if(this.commons.pageInfo.dmcSelected){	
			this.pageInfo.displayFooterButtons	=	true;
			this.pageInfo.destinationsFromDmc	=	null;
			await this.load('providers');
			this.changeMultiplePropertyWizardButton('back',{ disabled : false, label: '_CANCEL', icon: 'fa fa-fw fa-remove', customAction: 'cancelEditDmc' });
			this.handleCrudButton($event,'editDmc');
		} 
		else {	
			this.pageInfo.displayFooterButtons	=	false;	
		}
	}

	/**
	 * handle DMC creation process
	 * @param $event 
	 * @returns 
	 */
	async handleDmcCreation($event)		{	
		if(!$event.success)	{ 	return false; } 
		switch($event.type){	
			case 'create'	:	
				this.commons.pageInfo.dmcSelected			=	$event.data;
				this.commons.pageInfo.dmcSelected.isNew		=	true;
				this.commons.pageInfo.dmcSelected.name		=	$event.data.company_name; 
				this.pageInfo.displayFooterButtons 	= 	true;
					
				this.changeMultiplePropertyWizardButton(
					'back',
					{
						label			:	'_BACK',
						icon			:	'fa fa-fw fa-arrow-left',
						customAction	:	null,
						disabled		:	false
					}
				);

				await this.load('providers');

				await this.nextStep({validateStep : false});
				break;

			case 'cancel'	:	
				this.pageInfo.mode					= "edit";
				this.pageInfo.displayFooterButtons 	= false;
				this.commons.entities.dmcs.forEach(dmc =>{ dmc.selected = false });
				break;

			case 'edit'		:	
				this.commons.pageInfo.dmcSelected	= $event.data; 
				this.pageInfo.displayFooterButtons 	= true;	
				
				await this.persistDmc();
				await this.nextStep({ validateStep : false });
				
				this.commons.generateToast('_SUCCESS','_DMC_EDITED','success');
				break;

			case 'remove'	:	break;
			default			:	return;								
		}
	}

	createCustomDestination()			{	
		this.pageInfo.creatingCustomDestination	=	true;
		this.pageInfo.createDestinationMode		=	'create';
		this.changeMultiplePropertyWizardButton('back', { label: '_CANCEL', icon: 'fa fa-fw fa-remove', customAction: 'restartCreateDestination', disabled: false });
	}

	removeDestination($event,destination){	
		this.toggleDestination($event,destination,this.pageInfo.destinationsFromDmc,true);
		this.handleCrudButton($event,'deleteDest')
	}

// DRAG & DROP	----------------------------------------------------------------------------------------

	dragStart($event,destination) 		{ 	this.pageInfo.currentDestinationDragged = destination;	}
	dragEnd(event,destination) 			{}
	dragOver($event)					{}

	dropDestination($info){	
		$info.dragOver 			= false;
		$info.dmcDestinations 	= $info.dmcDestinations || [];

		try {
			let item 		= this.pageInfo.currentDestinationDragged;
			item.dragged 	= true;

			// Save into DMC destination list
			const path = "/dmcs/"+this.commons.userInfo.dmc.id+"/destinations";
			const data = {
				id 				:	item.name,
				country			:	item.country,
				name			:	item.name,
				refDestination	:	item.ref.path
			}
			this.firebaseCtrl.setDoc(path,data);

			// Add to DMC destinations on screen
			$info.dmcDestinations.push(item);
			
			// Remove from available destinations
			let destFound 	= $info.destinations.findIndex(el => el.name == item.name);
			$info.destinations.splice(destFound,1);

			this.commons.pageInfo.selectedDestination = item;

		}catch(e){
			console.log("[dropDestionation] Error",e);
		}

		this.pageInfo.currentDestinationDragged = null;		
	}

	drop($type,$info)				{
		switch($type){
			case "destination":	this.dropDestination($info.item); break;
		}											
	}

	removeDraggableDestination($event,destination,country){
		country.dmcDestinations.splice(country.dmcDestinations.findIndex(el => el.name == destination.name),1);
		country.destinations.push(destination);
	}

	getBackgroundDestination(destination){
		if(!destination)		{	return;												}
		if(destination.selected){	return destination.drabbed ? 'salmon' : 'crimson';	}
	}

	getBackgroundLabelDestination(destination){
		if(!destination)		{	return;												}
		return destination.dragged?'salmon':'crimson';
	}
	
	/**
	 * Handle the action from the floating buttons from the DMC and Destination
	 * @param $event event from angular. To use stopPropagation
	 * @param value value from the button
	 */
	async handleCrudButton($event,value, item?){
		$event.stopPropagation();	 
				
		switch(value){
			case 'nextStep'				:	
				await this.nextStep(); 
				break;

			case 'editDmc'				:
				this.pageInfo.mode					=	"edit";
				this.pageInfo.displayFooterButtons	=	true;
				break;

			case 'removeDmc'			:	
				this.confirmationService.confirm({	
					message 	:	this.commons.getTranslate('_REMOVE_DMC_QUESTION'),
					header		:	this.commons.getTranslate('_CONFIRMATION'),
					icon		:	'pi pi-exclamation-triangle',
					acceptLabel	:	this.commons.getTranslate('_REMOVE'),
					rejectLabel	:	this.commons.getTranslate('_CANCEL'),
					accept: async () => {
						this.commons.pageInfo.dmcSelected 			= item; 
						this.commons.pageInfo.dmcSelected.selected 	= true;
						this.pageInfo.refDmcSelected				= this.commons.pageInfo.dmcSelected.ref;
						await this.deleteDmc();
					}
				});
				break;

			case 'deleteDest'			:	
				this.confirmationService.confirm({	
											message 	:	this.commons.getTranslate('_QUESTION_REMOVE_DESTINATION'),
											header		:	this.commons.getTranslate('_REMOVE_DESTINATION'),
											icon		:	'pi pi-exclamation-triangle',
											acceptLabel	:	this.commons.getTranslate('_REMOVE'),
											rejectLabel	:	this.commons.getTranslate('_CANCEL'),
											accept: async () => {
												console.log('remove destination', this.commons.pageInfo.destinationSelected);
												$event.stopPropagation();
												await this.deleteDestination();

											}
										});	
				break;

			case 'goToDestinations'		:	
				this.pageInfo.displayFooterButtons 		= 	true; 
				await this.nextStep(); 	
				break;

			case 'editDest'				:	
				this.changeMultiplePropertyWizardButton(
					'back', 
					{	
						label 			: 	'_CANCEL',
						icon			:	'fa fa-fw fa-remove',
						customAction	:	'restartCreateDestination',
						disabled		:	false
					}
				);
				this.changePropertyWizardButton('next','visible',false);
				this.pageInfo.createDestinationMode		=	'edit';
				this.pageInfo.creatingCustomDestination	=	true;
				break;

		}
	}

	/**
	 * Check if the floating button from the entity selected ( dmc or company ) have to be displayed or not
	 * @param type type of check
	 * @param value item to compare
	 */
	checkDisplayCrudButtons(type,value){
		switch(type){
			default					:	return true;
			case 'myDestinations'	:	return this.pageInfo.destinationStepView == 'myDestinations';
			case 'customCreation'	:	return value.item.customCreation || false;
		}
	}

	/**
	 * Depending the current step, the buttons can have one behaviour or other, as do as their properties.
	 * @param value back or next
	 */
	checkIfButtonDisabled(value){
		let currentStep	=	this.getCurrentStep();
		switch(currentStep.value){
			// case 'dmcs'			:	this.changePropertyWizardButton('next','disabled',this.commons.pageInfo.dmcSelected == null || this.commons.pageInfo.dmcSelected == undefined); break;
			case 'destinations'		:	this.changePropertyWizardButton('next','disabled',this.commons.pageInfo.destinationSelected == null || this.commons.pageInfo.destinationSelected == undefined)	
										this.changePropertyWizardButton('back','disabled',false);
										break;
			case 'config_stations'	:	this.changePropertyWizardButton('next','disabled',false);	
										this.changePropertyWizardButton('back','disabled',false);
										break;
			default					:	return;
		}
		return this.pageInfo.wizardButtons.find(el => el.value == value).disabled;
	}

// STEPS	-----------------------------------------------------------------------

	getCurrentStep():any		{	
		let step = (this.pageInfo.steps.find(el => el.active ) || {});
		return step;
	}

	getTutorialStep()			{	
		let step				=	0;
		let currentStep			=	this.getCurrentStep(); 
		let currentTutorialStep	=	this.pageInfo.tutorialSteps.find(step => step.value == currentStep.value)
		if(!currentStep			){ return null; }
		if(!currentTutorialStep	){ return null }
		
		switch(currentStep.value){
			case 'dmcs'				: step = this.pageInfo.mode=="edit"?0:1; 			break;
			case 'destinations'		: step = this.pageInfo.createDestinationMode?2:0; 	break;
		}
		return currentTutorialStep.views[step].template;
	}

	/**
	 * Determine if the current step is allowed to persist the actual data from the step avoiding all the process. 
	 * This is just enabled on the edit mode
	 */
	checkIfSaveStep()			{	let currentStep		=	this.getCurrentStep();
									let creatingNewDest	=	this.commons.pageInfo.destinationSelected && this.commons.pageInfo.destinationSelected.isNewDestination;
									if(creatingNewDest){ return false;}
									switch(currentStep.value){
										case 'dmcs'	:	return this.commons.pageInfo.dmcSelected ? true : false;
										default		:	return currentStep.saveSingleStep;
									}
									// return creatingNewDest ? false : ( currentStep.saveSingleStep || false );
								}

	changePropertyWizardButton(value, atribute, valueAtribute){
									this.pageInfo.wizardButtons.find(el => el.value == value)[atribute]	=	valueAtribute;
								}

	changeMultiplePropertyWizardButton(value, params){
		let button	=	this.pageInfo.wizardButtons.find(el => el.value == value);
		Object.keys(params).forEach(param =>{ button[param] = params[param]});
	}

	disableSteps()				{	
		this.pageInfo.steps.forEach(el => el.active = false);						
	}

	restartSteps()				{	
		this.pageInfo.steps.forEach(el => {el.active = false; el.class = null});
		this.pageInfo.steps[0].active = true;
	}

	confirmValidatorStep(step)	{	switch(step){
										case 'check_zones'	:	this.confirmationService.confirm({	
																	header		:	this.commons.getTranslate('_MISSING_AREAS_UNASSIGNEDS'),
																	message 	:	this.commons.getTranslate('_SUBTITLE_MISSING_AREAS'),
																	icon		:	'pi pi-exclamation-triangle',
																	acceptLabel	:	this.commons.getTranslate('_CONTINUE'),
																	rejectLabel	:	this.commons.getTranslate('_CANCEL'),
																	accept 		: 	() => { 
																						this.nextStep({ validateStep : false })	
																					},
																	reject 		: 	() => {}
																});
																break;

										case 'check_dmc'	:	this.confirmationService.confirm({
																	header		:	this.commons.getTranslate('_CHANGES_UNSAVED'),
																	message 	:	this.commons.getTranslate('_SAVE_INFO_MODIFIED_QUESTION'),
																	icon		:	'pi pi-exclamation-triangle',
																	acceptLabel	:	this.commons.getTranslate('_SAVE'),
																	rejectLabel	:	this.commons.getTranslate('_CANCEL'),
																	accept 		:	async () => { 
																						await this.companyComponent.saveCompany() 
																					},
																	reject 		: 	() => {}
																});
																break;
									}
								}

	async nextStep(params?){
		const currentActive	=	this.pageInfo.steps.findIndex(el => el.active);
		let breakProcess 	=	false;
		let noErrors;

		if(!params || params['validateStep']){ // if no params ( always check ) or validateStep concrete param is set to true, check. Else, avoid it. 
			if(this.pageInfo.steps[currentActive].actionValidateStep){
				switch(this.pageInfo.steps[currentActive].actionValidateStep){
					case 'check_dmc'	:	
						switch(this.pageInfo.mode){
							case "create"	:
								await this.companyComponent.saveCompany(); breakProcess = true;
								break;
							default			:
								noErrors = false;
								if(this.companyComponent){	
									noErrors = this.companyComponent.checkFields();
								}
								if(!noErrors)	{ 
									breakProcess = true; 
								}
								else			{			
									let newInfo	=	this.companyComponent.checkIfInfoModified();
									if(newInfo){ 
										breakProcess = true; 
										this.confirmValidatorStep('check_dmc');	
									}
								}
								break;
						}
						break;

					case 'check_zones'	:	
						if(this.createZonesComponent){
							if(this.createZonesComponent.getTotalAreasUnassigneds() > 0){
								breakProcess = true;
								this.confirmValidatorStep('check_zones');
							}
						}
						break;
				}
			}
		}

		if(breakProcess){ return; }
		
		this.pageInfo.steps.forEach(el => el.active = false);

		if(currentActive == -1 || currentActive +1 >= this.pageInfo.steps.length){ 
			this.commons.pageInfo.creationProcess =	false;
			return;
		;}

		this.pageInfo.steps[currentActive+1].active = 	true;
		// this.pageInfo.steps[currentActive+1].class	=	'animated fadeInLeft slow';

		if(currentActive + 1 == this.pageInfo.steps.length -1){
			switch(this.pageInfo.mode){
				case "edit"	:
					if(this.commons.pageInfo.destinationSelected && this.commons.pageInfo.destinationSelected.isNewDestination){
						this.changeMultiplePropertyWizardButton('next',{ visible :true, label : '_CREATE_DESTINATION'})
					} else {
						this.changeMultiplePropertyWizardButton('next', { label : '_NEXT', visible : true	})
					}
					break;
				default		:
					this.changePropertyWizardButton('next','label','_CREATE_DESTINATION');
					break;
			}
		}
		else {
			this.changeMultiplePropertyWizardButton('next', { label : '_NEXT', visible : true	})
			this.changeMultiplePropertyWizardButton('back', { disabled : false 					})
		}

		this.checkIfInfoMapZones();
		await this.execInitFunctionStep();
	}

	private cancelEditDmc(){
		this.pageInfo.mode							= 'edit';
		this.pageInfo.displayFooterButtons 			= false;
		this.commons.pageInfo.dmcSelected			= null;
		this.commons.pageInfo.destinationSelected	= null;

		this.commons.entities.dmcs.forEach(dmc => dmc.selected = false);

		this.changeMultiplePropertyWizardButton('back',{
			label			:	'_BACK',
			icon			:	'fa fa-fw fa-arrow-left',
			disabled		:	false,
			customAction	:	null
		});
	}

	/**
	 * Works as nextStep, just with the order inverted on the trigger actions
	 */
	async previousStep(){
		let backButton		=	this.pageInfo.wizardButtons.find(el => el.value == 'back');	
		let breakProcess	=	false;

		if(backButton.customAction){
			switch(backButton.customAction){
				case 'reloadSelectedDMC'		:		
					this.commons.pageInfo.dmcSelected.ref = this.pageInfo.refDmcSelected;
					this.changeMultiplePropertyWizardButton('back',{customAction : null}); 
					break;

				case 'cancelEditDmc'			:	
					this.cancelEditDmc();					
					break;

				case 'restartCreateDestination'	:		
					this.pageInfo.creatingCustomDestination	=	false;
					this.changeMultiplePropertyWizardButton('back',{
						label			:	'_BACK',
						icon			:	'fa fa-fw fa-arrow-left',
						disabled		:	false,
						customAction	:	null
					});
					this.changePropertyWizardButton('next','visible',true);
					// this.pageInfo.destinationForm[0].info	=	this.pageInfo.countrySelectedFormat;
					// this.pageInfo.destinationForm[0].item	=	this.pageInfo.countrySelectedFormat;
					breakProcess	=	true;
					break;

				case 'restartCreateDmc'			:		
					this.pageInfo.mode = "edit";					
					this.changeMultiplePropertyWizardButton('back',
														{
															label			:	'_BACK',
															icon			:	'fa fa-fw fa-arrow-left',
															disabled		:	true,
															customAction	:	null
														});
					breakProcess		=	true;
					break;

				case 'zonesFooter'				:		
					this.changeMultiplePropertyWizardButton('back',
														{	visible 		:	this.commons.pageInfo.destinationSelected ? true : false,
															initFunction	:	'loadStations'
														});
					this.changePropertyWizardButton('next','visible',this.commons.pageInfo.dmcSelected ? true : false);
					break;
			}
		}

		if(breakProcess){ return;	}

		const currentActive	=	this.pageInfo.steps.findIndex(el => el.active);
		// this.pageInfo.steps[currentActive].class	=	'animated fadeOutLeft slow';
		this.pageInfo.steps.forEach(el => el.active = false);
	
		if(currentActive == -1 || currentActive -1 < 0){ return ; }
		
		this.pageInfo.steps[currentActive-1].active = true;
		// this.pageInfo.steps[currentActive-1].class	=	'animated fadeInRight slow';

		if(currentActive -1 == 0){
			this.changeMultiplePropertyWizardButton('back',	{ 	disabled 	: !this.pageInfo.displayFooterButtons,
																label		: this.pageInfo.displayFooterButtons ? '_CANCEL' : '_BACK',
																icon		: this.pageInfo.displayFooterButtons ? 'fa fa-fw fa-remove' : 'fa fa-fw fa-arrow-left',
																customAction: this.pageInfo.displayFooterButtons ? 'cancelEditDmc' : null	
															})
			this.changeMultiplePropertyWizardButton('next',	{ 	visible  	: this.pageInfo.displayFooterButtons, 
																disabled 	: !this.pageInfo.displayFooterButtons
															})
			return;
		}

		this.checkIfInfoMapZones();
		await this.execInitFunctionStep();
	}

	/**
	 * Check the mapConfigZones ViewChild and store the actual values. It would be nice to find a better way to get the info.
	 */
	checkIfInfoMapZones()				{	
		if(this.createZonesComponent){ 
			this.pageInfo.infoZonesCreated = {
				zones 		: 	this.createZonesComponent.pageInfo.zones,
				listAreas	:	this.createZonesComponent.pageInfo.listAreas,
				zonesItems	:	this.createZonesComponent.pageInfo.filters.find(el => el.entity == 'zones').items,
				mapConfig	:	this.createZonesComponent.pageInfo.mapConfig
			}
		}
	};

	/**
	 * Some steps need to load data or do some changes to work properly. This method is called when the step is going to be active depending the initFunction
	 */
	async execInitFunctionStep()
	{
		let current	=	this.pageInfo.steps.find(el => el.active);
		if(!current || !current.initFunction)	{	return	};
		switch(current.initFunction){
			case 'listDmcs'					:	
				this.pageInfo.displayFooterButtons	=	true;
				if(this.commons.pageInfo.dmcSelected)	{	this.changeMultiplePropertyWizardButton('back',{	visible 	: true, 
																										disabled	: false, 
																										label	 	: '_CANCEL', 
																										customAction:'cancelEditDmc', 
																										icon : 'fa fa-fw fa-remove'
																									});
												}
				else							{	this.changePropertyWizardButton('back','visible',false);		}

				this.changeMultiplePropertyWizardButton('next',	{ 	visible 	: true, 
																	disabled	: false, 
																	label 		: '_NEXT'
																});
				break;

			case 'loadStations'				:	
				this.changePropertyWizardButton('back','visible',true);
				this.changeMultiplePropertyWizardButton('next',	{	visible		: true, 
																	label 		: '_NEXT'
																});
				return await this.loadStations();

			case 'getDestinationsFromDmc'	:

				this.setLoading('destinationsFromCountry',true);
				this.pageInfo.creatingCustomDestination = false;
				this.pageInfo.displayFooterButtons		= true;
				this.changeMultiplePropertyWizardButton('back',	{	
					visible 	:	true,
					icon		: 	this.pageInfo.creatingCustomDestination ? 'fa fa-fw fa-remove' : 'fa fa-fw fa-arrow-left',
					label		:	this.pageInfo.creatingCustomDestination ? '_CANCEL' : '_BACK',
					customAction:	'reloadSelectedDMC'
				});

				this.changeMultiplePropertyWizardButton('next',{visible:this.commons.pageInfo.destinationSelected ? true : false, label : '_NEXT'});
				
				if(!this.commons.pageInfo.destinationSelected){
					await this.getAllDestinations();
				}
				return this.setLoading('destinationsFromCountry', false);

			case 'loadTransports'			:	
				
				this.setLoading('loadingTransports',true);
				
				await this.loadFleetFromDestination();
				await this.loadTransportersFromDestination();
				await this.loadDriversFromDestination();

				this.pageInfo.displayFooterButtons = this.commons.pageInfo.destinationSelected ? true : false;
				
				this.changeMultiplePropertyWizardButton('back',	{	
					visible 	: 	this.commons.pageInfo.destinationSelected ? true : false, 
					customAction:   'zonesFooter'
				});

				this.changeMultiplePropertyWizardButton('next',	{	
					visible 	: true,
					label		: true
				});
				
				this.setLoading('loadingTransports',false);

				break;

			case 'loadListPrices'			:	
			
				this.setLoading('listPrices',true);
				this.pageInfo.displayFooterButtons	=	true;
				this.changeMultiplePropertyWizardButton('back',{visible:true, customAction:null, label : '_BACK',icon : 'fa fa-fw fa-arrow-left'})
				this.changeMultiplePropertyWizardButton('next',{visible : true});
				// this.pageInfo.aggregatorsFromDestination	=	this.pageInfo.providersFromCompany.map(el => el.provider);
				if(!this.pageInfo.infoAreas)				{ await this.loadStations();}
				if(!this.pageInfo.transportData || !this.pageInfo.transportData.dmcFleet)	{ await this.loadFleetFromDestination();}

				console.log(this.pageInfo);
				console.log(this.pageInfo.providersFromCompany);
				this.setLoading('listPrices',false);
				break;

			case 'loadProviders'			:	

				this.pageInfo.displayFooterButtons	=	true;
				this.changeMultiplePropertyWizardButton('back',{visible: true, customAction: null, label : '_BACK', icon : 'fa fa-fw fa-arrow-left'});
				this.changeMultiplePropertyWizardButton('next',{visible : true});
				!this.pageInfo.form.providers 		? await this.load('providers') 			: null;
				!this.pageInfo.destinationsFromDmc	? await this.getDestinationsFromDmc() 	: null;
				// this.toggleProvider(this.pageInfo.entities.providers.data[0]);
				return;	

			case 'loadMapZones'				:	
			
				this.setLoading('mapZones', true);
				this.changePropertyWizardButton('back','visible',true);
				this.changeMultiplePropertyWizardButton('next',{
					visible	: this.pageInfo.mode=="create", 
					label 	: "_FINISH"
				});
				
				if(!this.pageInfo.infoAreas){ 
					await this.loadStations();
				}	

				this.setLoading('mapZones', false);
				break;

			default							:	
			
				console.log("Unknown step");
												
		}

		switch(this.pageInfo.mode){
			case "create"	:	
				let currentIndex	=	this.pageInfo.steps.findIndex(el => el.active);
				if(currentIndex == this.pageInfo.steps.length -1){
					this.changeMultiplePropertyWizardButton('next',{label: '_FINISH', icon : 'fa fa-fw fa-check-circle'});
				}
				break;

			case "edit"		:
				break;
		}
	}

	/**
	 * Get all destinations from our database. Get too the destinations from the DMC selected and unify all of them 
	 */
	async getAllDestinations()			{	
		
		this.pageInfo.destinationsFromCountry		=	await Promise.all(this.pageInfo.entities.countries.data.map(async country => {
															let destinationsCountry	=	await this.destinationServices.getDestinationsFromCountry(country.value);
															return { country : country, destinations : destinationsCountry.filter((item : any ) => !item.customCreation)};
														}));

		this.pageInfo.resellerDestinationsAssigned	= 	this.commons.userInfo.resellerId 	
															? 	await this.destinationServices.getAssignedsDestinations({resellerId : this.commons.userInfo.resellerId, userId : this.commons.userInfo.id})
															:	this.pageInfo.destinationsFromCountry;
		await this.getDestinationsFromDmc();
		
		// Destinations from DMC
		this.pageInfo.destinationsFromDmc.forEach(destination =>{
			let findCountry	=	this.pageInfo.destinationsFromCountry.find(item => item.country.value == destination.country);
			if(!findCountry){ return; }
			let findDestination	=	findCountry.destinations.findIndex(destCountry => destCountry.id == String(destination.idDestination).trim()); // some id has an space at the beggining ( have to check why )
			findCountry.destinations.splice(findDestination,1);
		});

		// Destinations from COUNTRY which are not activated by DMC
		this.pageInfo.destinationsFromCountry.forEach((currentCountry,index)=>{ // remove all destinations which are not assigned to the reseller
			let foundCountryAssigned	=	this.pageInfo.resellerDestinationsAssigned.findIndex(item => item.country.value == currentCountry.country.value);
			if( foundCountryAssigned == -1){this.pageInfo.destinationsFromCountry.splice(index,1)}
			currentCountry.destinations.forEach((currentDestination,indexDestination) => {
				let destinationAssigned	=	this.pageInfo.resellerDestinationsAssigned[foundCountryAssigned].destinations.findIndex(item => item.id == currentDestination.id);
				if(destinationAssigned == -1) {
					currentCountry.destinations.splice(indexDestination,1);
				}
			});
			currentCountry.dmcDestinations	=	this.pageInfo.destinationsFromDmc.filter(destination => destination.country == currentCountry.country.value); 
		});
		
		this.toggleCountry(this.pageInfo.destinationsFromCountry[0]);

		console.log(this.pageInfo.destinationsFromDmc);
		console.log(this.pageInfo.destinationsFromCountry);

		this.setLoading('destinationsFromCountry',false);

	}

	toggleCountry(country)				{	this.commons.toggleItem(country,this.pageInfo.destinationsFromCountry,'toggled');	}

	/**
	 * load destintaions already assigned by a dmc. If create mode or not dmc Selected have to ignore the block. 
	 */
	async getDestinationsFromDmc()		{	
		
		this.setLoading('destinationsFromDestination',false);
		console.log('LIST COUNTRY', this.pageInfo.entities['countries'])
		if(!this.commons.pageInfo.dmcSelected || this.commons.pageInfo.dmcSelected.isNew ){ this.pageInfo.destinationsFromDmc = []; return; }
			
		this.pageInfo.destinationsFromDmc		=	await this.companyCtrl.getDestinationsFromDmc(this.commons.pageInfo.dmcSelected.id);
		this.pageInfo.destinationsFromDmc		=	await Promise.all(this.pageInfo.destinationsFromDmc.map( async destination =>{
																												let data	: any =	await this.firebaseCtrl.getDataByRef(destination.refDestination);
																												return {...	destination, 
																															idDestination 	: data.id, 
																															img 			: data.img, 
																															customCreation	: data.customCreation || false,
																															mapConfig		: data.mapConfig ||  null,
																															refAreas 		: data.refAreas	}
																											}));
		this.setLoading('destinationsFromDestination',false);
		// this.changeViewDestinations(this.pageInfo.destinationsFromDmc.length > 0 ? 'myDestinations' : 'availablesDestinations');
	}

	async getDmcDestinations($entity)	{	
		switch($entity){
			default			:
			case "country"	:	
				if	(this.commons.pageInfo.dmcSelected.isNew){	
					return [this.commons.pageInfo.destinationSelected]		
				} else{									
					return Array.isArray(this.pageInfo.destinationsFromDmc) ? this.pageInfo.destinationsFromDmc : []	
				}
		}
	}

	/**
	 * Get the custom destination created from the user. 
	 * It marks the new destination as custom and then restart the back button behaviour. 
	 * @param $event Emiter event
	 */
	async handleCustomCreation($event){
		if(!$event.success){ return;	}

		switch($event.mode){
			case 'edit'		:	let updateInfoDestination	=	{
									name		:	$event.data.name 		|| '_NO_NAME',
									description	:	$event.data.description || null,
									img			:	$event.data.img			|| null,
									refAreas	:	$event.data.refAreas	|| []	
								}
								
								let callUpdate	=	await this.firebaseCtrl.updateItemByRef(this.commons.pageInfo.destinationSelected.refDestination,updateInfoDestination);
								
								if(callUpdate.success){
									await this.firebaseCtrl.updateItemByRef(this.commons.pageInfo.destinationSelected.ref,{name : updateInfoDestination.name});
									this.commons.generateToast('_SUCCESS','_AREAS_UPDATED','success');
									this.pageInfo.creatingCustomDestination	=	false;
									this.commons.pageInfo.destinationSelected 		= 	null;
									this.pageInfo.infoZonesCreated			=	null;
									this.pageInfo.listCurrentStations		=	null;
									await this.getDestinationsFromDmc();
								} else 	{	
									this.commons.generateToast('_ERROR','_ERROR_ON_UPDATE','error');
								}
								break;
			
			case 'create'	:	this.commons.pageInfo.destinationSelected					=	$event.success ? $event.data : null;
								this.commons.pageInfo.destinationSelected.isNewDestination	=	true;
								this.commons.pageInfo.destinationSelected.customCreation	=	true;
								
								if(this.commons.pageInfo.destinationSelected){
									// this.pageInfo.creatingCustomDestination	=	false;
									this.nextStep();
								}	
								break;

			default			:	break;
		}
		// this.pageInfo.destinationForm[0].info	=	this.pageInfo.countrySelectedFormat;
		// this.pageInfo.destinationForm[0].item	=	this.pageInfo.countrySelectedFormat;

		this.changeMultiplePropertyWizardButton('back',{label : '_BACK', icon : 'fa fa-fw fa-arrow-left', visible : true, customAction	:	null, disabled : false});
		this.changeMultiplePropertyWizardButton('next',{label : '_NEXT', visible : true});
	}

	/**
	 * Set the currentDestination as selected or well set as empty. 
	 * If the mode is edit and new destination button is clicked, the property 'isNewDestination' is added as a flag
	 * @param destination destination clicked 
	 */
	async toggleDestination($event,destination, destinations, toRemove?, entity?){
		
		this.changeMultiplePropertyWizardButton('next', { visible :false });
		
		let countrySelected =	this.pageInfo.destinationsFromCountry.find(el => el.toggled);
		let types			=	[ 'dmcDestinations', 'draggedDestinations', 'destinations' ];
		
		types.filter(el => el!=entity).forEach(item => {
			if(!countrySelected[item]){return;}
			countrySelected[item].forEach(el => el.selected = false);
		});

		this.commons.toggleItem(destination,destinations,'selected');

		this.commons.pageInfo.destinationSelected	=	destination.selected 				? destination : null;
		this.pageInfo.countrySelected		=	this.commons.pageInfo.destinationSelected 	? destination.country : null;

		if(!this.pageInfo.countrySelected || !this.commons.pageInfo.destinationSelected)	{ 	
			this.pageInfo.mode 					= 'create'; 
			this.pageInfo.destinationsFromDmc	=	[];
			this.changeMultiplePropertyWizardButton('next', {'disabled' : false});
			return; 
		}

		this.pageInfo.destinationsFromDmc 	= 	this.pageInfo.destinationsFromDmc.filter(el => !el.isNewDestination);

		await this.loadAggregatorsFromDestination();

		if(this.commons.pageInfo.destinationSelected.isNewDestination){ // Add the destination to destinations from dmc to assign on the aggregators form
			let foundDestination		=	this.pageInfo.destinationsFromDmc.findIndex(el => el.id == destination.id);
			if(foundDestination == -1)	{	
				this.pageInfo.mode = "create";	
				this.pageInfo.destinationsFromDmc.push(this.commons.pageInfo.destinationSelected);
			}
			else						{	
				this.pageInfo.mode = "edit";		
				this.pageInfo.destinationsFromDmc.splice(foundDestination,1)
			}
		}
		else							{
			this.pageInfo.listCurrentStations	=	null;
			this.pageInfo.mode 					=	"edit";
		}
		
		this.pageInfo.listCurrentStations	=	null;

		await this.loadStations();
		this.mountMapZones();
		this.mountListPrices();

		this.changePropertyWizardButton('next','visible',this.commons.pageInfo.destinationSelected ? true : false);
		if(this.commons.pageInfo.destinationSelected == null || this.commons.pageInfo.destinationSelected.customCreation){ return; }

		this.pageInfo.settingsDestination.item	=	this.commons.pageInfo.destinationSelected.transportSettings || {};
		
		if(this.pageInfo.settingsDestination.item){
			this.superFormCtrl.restartErrors(this.pageInfo.settingsDestination.form);
			this.superFormCtrl.changeItemProperties(this.pageInfo.settingsDestination.form,'max_speedy_stops',{mandatory : true, disabled : false });
		}

		console.log('******	DESTINATION INFO	*********', this.commons.pageInfo.destinationSelected);
		this.changeMultiplePropertyWizardButton('next', {'visible' : true});
		// !toRemove ? this.handleCrudButton($event,'nextStep') : null;

	}

	async loadAggregatorsFromDestination()
	{
		if(this.commons.pageInfo.destinationSelected && !this.commons.pageInfo.destinationSelected.idDestination){
			this.commons.pageInfo.destinationSelected.isNewDestination 		=	true;
			this.pageInfo.aggregatorsFromDestination				=	[];
		}else{
			(this.commons.pageInfo.destinationSelected && this.commons.pageInfo.destinationSelected.isNewDestination) ? delete this.commons.pageInfo.destinationSelected.isNewDestination : null;
			this.pageInfo.aggregatorsFromDestination				=	await this.aggregatorCtrl.getAggregatorsFromDestination(this.commons.pageInfo.dmcSelected.id, this.commons.pageInfo.destinationSelected.id);
			this.pageInfo.aggregatorsFromDestination				=	Array.isArray(this.pageInfo.aggregatorsFromDestination)?this.pageInfo.aggregatorsFromDestination:[this.pageInfo.aggregatorsFromDestination];
			this.pageInfo.aggregatorsFromDestination				=	this.pageInfo.aggregatorsFromDestination.filter(el => el.providersAccounts && el.providersAccounts.length > 0);
			console.log('AGGREGATORS FOR DESTINATION', this.pageInfo.aggregatorsFromDestination);
		}
	}

	async handleSettingsDestination($event){
		console.log('settings destination',$event);
		switch($event.type){
			case 'groupButton'	:	if($event.item.value == 'on' && $event.item.selected){
										this.superFormCtrl.changeItemProperties(this.pageInfo.settingsDestination.form,'max_speedy_stops',{mandatory : true, disabled : false, validators	:	[{type : 'min', min : 0}]  });

									}else{
										this.superFormCtrl.changeItemProperties(this.pageInfo.settingsDestination.form,'max_speedy_stops',{mandatory : false, disabled : true });
										this.superFormCtrl.restartErrors(this.pageInfo.settingsDestination.form);
									}
			return;
			case 'save'			:	this.commons.pageInfo.destinationSelected.transportSettings	=	$event.item;
									if(this.commons.pageInfo.destinationSelected.idDestination){
										await this.firebaseCtrl.updateItemByRef(this.commons.pageInfo.destinationSelected.ref, {transportSettings : $event.item});
									}
									this.commons.generateToast('_SUCCESS','_SAVED','success');
									return;
			default				:	return;
		}
	}

	//	TRANSPORTER	----------------------------------------------------------------

	async loadFleetFromDestination()			{	
		this.pageInfo.transportData	=	this.pageInfo.transportData || {};
		if(this.pageInfo.mode == 'edit'){
			let response								=	await Promise.resolve(this.transportCtrl.getFleet(this.commons.pageInfo.dmcSelected.id, this.commons.pageInfo.destinationSelected.id));
			this.pageInfo.transportData.dmcFleet		=	response.data || [] ;
		}
	}

	async loadTransportersFromDestination()		{	
		this.pageInfo.transportData	=	this.pageInfo.transportData || {};
		if(this.pageInfo.mode == 'edit'){
			let response:any							=	await Promise.resolve(this.transportCtrl.getTransporters(this.commons.pageInfo.dmcSelected.id, this.commons.pageInfo.destinationSelected.id))
			this.pageInfo.transportData.transporters	=	response.data || [] ;
		}
	}
	
	async loadDriversFromDestination()			{	
		this.pageInfo.transportData	=	this.pageInfo.transportData || {};
		if(this.pageInfo.mode == 'edit'){
			let response:any					=	await Promise.resolve(this.transportCtrl.getDrivers(this.commons.pageInfo.dmcSelected.id, this.commons.pageInfo.destinationSelected.id));
			this.pageInfo.transportData.drivers	=	response.data || [] ;
		}
	}

	async handleTransportAction($event){
		this.pageInfo.transportData	=	this.pageInfo.transportData || {};
		if(!$event.type){ return this.commons.generateToast('_ERROR','_UNHANDLED_ACTION','error')}
		
		switch($event.type){
			case 'dmcFleet'				:		
				this.pageInfo.transportData.dmcFleet	=	$event.data; 
				break;

			case 'manageDrivers'		:
				this.pageInfo.transportData.drivers		=	this.pageInfo.transportData.drivers || [];
				let findDriver							=	this.pageInfo.transportData.drivers.findIndex(el => el.id == $event.data.data.id);
				switch($event.data.type){
					case "saveDriver"		:	await this.transportCtrl.saveDriver($event.data.data,this.commons.pageInfo.dmcSelected.id,this.commons.pageInfo.destinationSelected.id);	break;
					case "deleteDriver"		:	
						const ref = $event.data.data.info.ref;
						await this.transportCtrl.deleteDriver($event.data.data); 
						break;
				}
				break;

			case 'manageTransporters'	:		
				this.pageInfo.transportData.transporters		=	this.pageInfo.transportData.transporters || [];
				let findTransporter								=	this.pageInfo.transportData.transporters.findIndex(el => el.id == $event.data.data.transporter.id);
				let fleetTransporter;
				
				switch($event.data.action){
					case 'saveTransporter'	:	findTransporter	> -1 ? this.pageInfo.transportData.transporters[findTransporter]	=	$event.data.data.transporter
																		: this.pageInfo.transportData.transporters.push($event.data.data.transporter);
												break;

					case 'deleteTransporter':	this.pageInfo.transportData.transporters.splice(findTransporter,1); 
												break;

					case 'saveFleet'		:	this.pageInfo.transportData.transporters[findTransporter].fleet	=	this.pageInfo.transportData.transporters[findTransporter].fleet || []
												fleetTransporter		=	this.pageInfo.transportData.transporters[findTransporter].fleet;
												let findTypeId			=	fleetTransporter.findIndex(el => el.typeId == $event.data.data.fleet.typeId);
												findTypeId 				> 	-1	?	fleetTransporter[findTypeId] = $event.data.data.fleet.typeId
																		:			fleetTransporter.push($event.data.data.fleet);
												break;

					case 'deleteFleet'		:	fleetTransporter		=	this.pageInfo.transportData.transporters[findTransporter].fleet || [];
												let fleetToRemove		=	fleetTransporter.findIndex(el => el.typeId == $event.data.data.fleet.typeId);
												fleetToRemove 			> 	-1 	?	fleetTransporter.splice(fleetToRemove,1)
																				:	null;	
												break;
				}
				console.log($event);
				break;

			default						:		return;
		}
	}

	// PROVIDERS	------------------------------------------------------------------

	async handleAggregatorAction($event)
	{
		console.log('TMP AGGREGATORS', $event);
		console.log(this.pageInfo.providersFromCompany);
		console.log(this.pageInfo.aggregatorsFromDestination);
		let foundAggregator;

		switch($event.action){
			case 'updateAggregatorsDest':	
				await this.loadAggregatorsFromDestination();		
				break;

			case 'assignDestination'	:	
				if(!this.commons.pageInfo.destinationSelected.id == $event.data.destination){return;}
				foundAggregator	=	this.pageInfo.aggregatorsFromDestination.find(aggregator => aggregator.id == $event.data.aggregator);
				if(foundAggregator){
					let foundAccount	=	foundAggregator.providersAccounts.findIndex(el => el.accountId == $event.data.accountSelected.id);
					foundAccount		==	-1	?	foundAccount.providersAccounts.push({accountId : $event.data.accountSelected.id, providerId : $event.data.aggregator})
												:	foundAccount.providersAccounts.splice(foundAccount,1);												
					return;}
				this.pageInfo.aggregatorsFromDestination.push({	aggregatorId		: 	$event.data.aggregator, 
																id					:	$event.data.aggregator,
																providersAccounts	:	[{accountId : $event.data.accountSelected.id, providerId : $event.data.aggregator}]
															}
															);
				break;
			
			case 'unassignDestination'	:	
				if(!this.commons.pageInfo.destinationSelected.id == $event.data.destination){return;}
				foundAggregator	=	this.pageInfo.aggregatorsFromDestination.findIndex(aggregator => aggregator.id == $event.data.aggregator);
				if(foundAggregator == -1){return;}
				this.pageInfo.aggregatorsFromDestination.splice(foundAggregator,1);
				break;
		}

		console.log('[handleAggregatorAction] AGGREGATORS FROM DESTINATION', this.pageInfo.aggregatorsFromDestination	);
		console.log('[handleAggregatorAction] AGGREGATORS FROM COMPANY	  ', this.pageInfo.providersFromCompany			);
	}

	
	// LIST PRICES	-------------------------------------------------------------------
	
	async handleListPricing($event){
		console.log('LIST PRICING DATA', $event);
	
		this.pageInfo.pricingListData	=	this.pageInfo.pricingListData || [];
		this.pageInfo.pricingListData	=	this.pageInfo.pricingListData.filter(el => el.aggregatorId != undefined);
	
		switch($event.action){
			case 'goToAggregators'	:	
				return await this.onClickStepper({step : this.pageInfo.steps.find(el => el.value == 'config_providers')});
			case 'goToFleet'		:	
				return await this.onClickStepper({step : this.pageInfo.steps.find(el => el.value == 'config_transport')});
			case 'goToStations'		:	
				return await this.onClickStepper({step : this.pageInfo.steps.find(el => el.value == 'config_stations')});
			
			case 'savePrices'		:	
				let findAggregator		=	this.pageInfo.pricingListData.findIndex(el => ((el.aggregator || {}).providerId || el.aggregatorId) == ($event.data.aggregator.aggregatorId || $event.data.aggregator.providerId));	
					findAggregator	> -1	
						? 	this.pageInfo.pricingListData[findAggregator].listPrices = $event.data.listPrices
						: 	this.pageInfo.pricingListData.push({
								aggregatorId 	: $event.data.aggregator.aggregatorId, 
								listPrices 		: $event.data.listPrices
							});
			break;
			default				:	console.log('unhandled option', $event.action);
		}
		// this.pageInfo.pricingListData = this.pageInfo.pricingListData.map(el => 
		console.log('LIST PRICING TMP', this.pageInfo.pricingListData);	
	}

	mountListPrices()				{	this.pageInfo.pricingListData	=	this.pageInfo.aggregatorsFromDestination.map(aggregator=>
																				{	return {
																						aggregatorId 	: 	aggregator.id,
																						listPrices		:	aggregator.listPrices
																					}
																				});
																			console.log('LIST INIT PRICING DATA', this.pageInfo.pricingListData);
		
									}

	/*---------------------------------------------------------------------------
		MAP ZONES AREAS
	---------------------------------------------------------------------------*/

	async handleMapZones($event)	{	
		this.commons.generateToast("_WARNING","handlemapzones is not using aggregators","error");
		return true;
		
		this.pageInfo.mapZonesAggregators	=	this.pageInfo.mapZonesAggregators || [];
		let findAggregator					=	this.pageInfo.mapZonesAggregators.findIndex(el => ((el.aggregator || {}).aggregatorId || el.aggregatorId ) == ($event.data.data.aggregator.aggregatorId || $event.data.data.aggregator.aggregatorId$event.data.data.aggregator.id));
		
		switch($event.action){
			case 'goToAggregators'	:	
				let aggregatorStepper	=	this.pageInfo.steps.find(el => el.value == 'config_providers');
				return await this.onClickStepper({step : aggregatorStepper});
				break;

			case 'saveZones'		:	
				findAggregator > -1	
					?	this.pageInfo.mapZonesAggregators[findAggregator].zones = $event.data.data.zones
					:	this.pageInfo.mapZonesAggregators.push({aggregator : $event.data.data.aggregator, zones : $event.data.data.zones});
				break;
		}

		this.pageInfo.mapZonesAggregators	=	this.pageInfo.mapZonesAggregators.map(item =>{
			return {	
				aggregatorId	:	item.aggregatorId || item.aggregator.id,
				...item
			}
		});

		// this.pageInfo.mapZonesAggregators	=	this.pageInfo.mapZonesAggregators.filter(aggregator => aggregator.aggregatorId === undefined); // clean undefined items
	}

	mountMapZones()					{	
		this.pageInfo.mapZonesAggregators	=	this.pageInfo.aggregatorsFromDestination.map(aggregator => {
													return {
														aggregatorId 	: aggregator.id, 
														zones 			: aggregator.zones
													}
												});
	}

	cancelResumme()					{
		this.commons.pageInfo.creationProcess						=	true;	
		this.pageInfo.steps[this.pageInfo.steps.length-1].active 	=	true;
		this.pageInfo.steps[this.pageInfo.steps.length-1].class		=	null;
	}
	
	/**
	 * Clean the object from the dmc zones and areas
	 */
	cleanZonesDestination()			{	return (this.pageInfo.infoZonesCreated.zones || []).map(el => { return {refAreas : el.refAreas, name : el.name, id : el.id}});	}

	// ************************** METHODS PERSIST ENTITIES TO DB ***********************************
										
	async handleStationAction($event){
		let updatedAreas									=	this.pageInfo.infoAreas.stations.map(station => {return { type : station.type, items : station.items.filter(el => el.selected)}});
		this.commons.pageInfo.destinationSelected.workingStations	=	updatedAreas;
		if(this.pageInfo.mode == 'edit'){
			let callUpdate										=	await this.firebaseCtrl.updateItemByRef(this.commons.pageInfo.destinationSelected.ref,{workingStations : updatedAreas});
			callUpdate.success									?	null 
																: 	this.commons.generateToast('_ERROR','_STATIONS_PERSIST_ERROR','error');
		}
		
	}
	
	/**
	 * save stations from config destination
	 */
	async saveStations(){
		let response;
		let updatedAreas		=	this.pageInfo.infoAreas.stations.map(station => {
			let item = { type : station.type, items : station.items.filter(el => el.selected) };
			return item;
		});
		
		response 				=	await this.firebaseCtrl.updateItemByRef(
										this.commons.pageInfo.destinationSelected.ref,{
											workingStations : updatedAreas
										});
		if(response.success){
			this.commons.generateToast('_SUCCESS','_LIST_UPDATED','success');
		} else 				{
			this.commons.generateToast('_ERROR','_STATIONS_PERSIST_ERROR','error');
		}
	}

	/**
	 * Save the current step. Option only available when the mode is edit and want to update some section
	 */
	async saveCurrentStep(){
		console.group("saveCurrentStep");
		console.log("Init");

		if(this.commons.pageInfo.destinationSelected && this.commons.pageInfo.destinationSelected.isNewDestination){ 
			this.commons.generateToast("_ERROR","_NEW_DESTINATION_MOT_PERSISTING","error");
			console.log("[saveCurrentStep] New destination, so not persisting");
			return;
		}
		
		this.confirmationService.confirm({
			message 	: 	this.commons.getTranslate('_SAVE_EDITS_QUESTION'),
			header		: 	this.commons.getTranslate('_CONFIRMATION'),
			icon		: 	'pi pi-exclamation-triangle',
			acceptLabel	:	this.commons.getTranslate('_SAVE'),
			rejectLabel	:	this.commons.getTranslate('_CANCEL'),
			accept		: 	async () => {

				let currentStep	=	this.getCurrentStep();
				let callUpdate : any;

				console.log("persisting currentStep",currentStep);

				switch(currentStep.value){
					case 'dmcs'				:	await this.companyComponent.saveCompany();
												break;

					case 'config_stations'	:	this.saveStations(); break;
						
					// case 'config_providers'	:	callUpdate				=	await this.aggregatorCtrl.updateProvidersToDmc(	
					// 																							this.commons.pageInfo.dmcSelected.id, 
					// 																							this.commons.pageInfo.destinationSelected.ref,	
					// 																							{	providersDmc		:	this.pageInfo.providersFromCompany,
					// 																								accountsDestination	:	this.pageInfo.providersFromDestination	
					// 																							});
					// 							callUpdate.success 		?	this.commons.generateToast('_SUCCESS','_LIST_UPDATED','success')
					// 													:	this.commons.generateToast('_ERROR','_PROVIDER_PERSIST_ERROR','error');
					// 							break;
				}
			},
			reject: () => {}
		})
		console.groupEnd();
	}

	async handleSummaryAction($event){
		switch($event.action){
			default			:	return;
			case 'cancel'	:	return this.cancelResumme();
			case 'save'		:	return await this.persistCreateWizzard();
		}
	}

	/**
	 * Process to create new dmc or a destination to one dmc. 
	 */
	async persistCreateWizzard()
	{
		try{
			this.setLoading('updatingDMC',true);
			const isNewDmc			=	this.commons.pageInfo.dmcSelected.isNew;
			let infoDmc				=	await this.persistDmc();
			let infoDestination:any	=	await this.persistDestination(infoDmc);	
	
			let infoToDestination	=	{
				name				:	infoDestination.name,
				country				:	this.pageInfo.countrySelected || null,
				refDestination		:	infoDestination.ref,
				transportSettings	:	this.commons.pageInfo.destinationSelected.transportSettings || null,
				workingStations		:	this.pageInfo.infoAreas.stations.map(station => { 
											return {
												type 	: station.type, 
												items 	: station.items.filter(el => el.selected) || []
											}
										})
									}

			let infoCreatedDestination	=	await this.companyCtrl.addDestination(infoDmc.id,infoToDestination);
			
			if(infoCreatedDestination.success &&  this.pageInfo.listUsersCreateds.length > 0){
				await this.companyCtrl.assignDestinationToNewUsers(infoDmc.id, this.pageInfo.listUsersCreateds,infoCreatedDestination.destinationId);
			}
			
			await this.persistTransportInfo(infoDmc.id, infoCreatedDestination.destinationId);

			if(isNewDmc){	await this.aggregatorCtrl.persistProviders(infoDmc.id,this.pageInfo.providersFromCompany);						}
			else		{	await this.saveAggregatorsNewDest(infoDmc.id, infoCreatedDestination.destinationId, infoToDestination.name);	}
			
			await this.persistListPrices(infoDmc.id,infoCreatedDestination.destinationId);
			await this.persistListZones(infoDmc.id,infoCreatedDestination.destinationId);
			await this.commons.generateToast('_SUCCESS','_PROCESS_COMPLETED','success');

			this.setLoading('updatingDMC',false);
			this.ngOnInit();

		} catch(e){
			this.setLoading('updatingDMC',false);
			console.log(e);
			this.commons.generateToast('_ERROR',String(e).toString(),'error');
			this.ngOnInit();
		}
	}

	async saveAggregatorsNewDest(dmcId,destinationId, destinationName)
	{
		if(!this.pageInfo.aggregatorsFromDestination){return;}
		await Promise.all(this.pageInfo.aggregatorsFromDestination.map(async aggregator =>{
			let filteredAccounts	=	aggregator.providersAccounts.filter(aggregator => !aggregator.providersAccounts || aggregator.providersAccounts.length == 0);
			await Promise.all(filteredAccounts.map(async account => {
				let response = await this.aggregatorCtrl.getAccountToProviderDmc(dmcId,aggregator.id,account.accountId)
				let accountAggregator	=	response.success ? response.data : null;
				if(accountAggregator){
					let findDestination =	(accountAggregator.destinations || []).findIndex(destination => destination.id == destinationId);
					findDestination == -1 	? accountAggregator.destinations.push({id : destinationId, name : destinationName})
											: accountAggregator.destinations.splice(findDestination,1);
					await this.aggregatorCtrl.updateAccountToProviderDmc(dmcId,aggregator.id,accountAggregator);	
				}
				await this.aggregatorCtrl.toggleAccountToDestination(dmcId,destinationId,aggregator.id,account)
			}));
		}));
	}

	async persistDmc()
	{
		let infoDmc;

		if(this.commons.pageInfo.dmcSelected.isNew){	//Check if has to be created
			delete this.commons.pageInfo.dmcSelected.isNew;
			this.commons.pageInfo.dmcSelected.createdBy	=	this.commons.userInfo.id;
			infoDmc	=	await this.companyCtrl.createCompany(this.commons.pageInfo.dmcSelected); //Once created, obtain the data from firebase to assign the next steps data
			await this.createUserToDmc(infoDmc);
		}else{
			infoDmc	=	this.commons.pageInfo.dmcSelected;
			this.commons.pageInfo.dmcSelected.selected ? delete this.commons.pageInfo.dmcSelected.selected : null;
			await this.companyCtrl.editCompany(this.commons.pageInfo.dmcSelected);
		}
		return infoDmc;
	}

	/**
	 * Check if creating a new destination and add it to the dmc introduced as param. If it is edit just assign it and return it. 
	 * @param infoDmc 
	 */
	async persistDestination(infoDmc){
		let infoDestination;
		if(this.commons.pageInfo.destinationSelected.customCreation){
			let newDest	=	{
				description		:	this.commons.pageInfo.destinationSelected.description 	|| null,
				customCreation	:	true,
				createdBy		:	infoDmc.id,
				refAreas		:	this.commons.pageInfo.destinationSelected.refAreas 		|| [],
				name			:	this.commons.pageInfo.destinationSelected.name 			|| null,
				img				:	this.commons.pageInfo.destinationSelected.img			|| null	
			}
			infoDestination	=	await this.destinationServices.createDestination(this.pageInfo.countrySelected,newDest);
		}else{
			infoDestination	=	this.commons.pageInfo.destinationSelected; // delete info merged when select destination ( refAreas )
		}
		return infoDestination;
	}

	async createUserToDmc(dmc)		{
		this.pageInfo.listUsersCreateds	=	[]; // list users created when create dmc
		let userInfo	=	{
			email	:	dmc.account_email,
			name	:	dmc.contact_name || null,
			isAdmin	:	true,
			profile :	'dmc'
		}
		let response =  await this.authService.signIn(userInfo);
			console.log("ITEMS",response);
			for(let item of response["items"]){
				if(item.uid)	{ 				
					// Persist into DMC info
					let message	= await this.firebaseCtrl.addUserToStaff({ userInfo: userInfo, uid: item.uid, entityId: dmc.id });
					if(message.success)	{	this.pageInfo.listUsersCreateds.push(message.userId); await this.commons.generateToast('_SUCCESS','_USER_CREATED','success',2);		} 
					else 				{	await this.commons.generateToast('_ERROR','_USER_NOT_CREATED','error');			}			
				} else {
					console.log("User has no uid");
				}
			}
	}

	/**
	 * Persist the transport information from DMC. If no have data, just return;
	 */
	async persistTransportInfo(dmcId, destinationId){
		if(!this.pageInfo.transportData){ return; }

		if(this.pageInfo.transportData.dmcFleet){ // persist fleet dmc
			this.pageInfo.transportData.dmcFleet.forEach(typeVehicle => typeVehicle.fleet.forEach(fleet => delete fleet.selected)); // clean tmp data
			await Promise.all(this.pageInfo.transportData.dmcFleet.map(async typeVehicle =>{
				await this.transportCtrl.saveTypeTransport(dmcId,destinationId,typeVehicle);				
			}));
		}
		
		if(this.pageInfo.transportData.drivers){ // persist drivers from dmc
			await Promise.all(this.pageInfo.transportData.drivers.map(async driver =>{
				await this.transportCtrl.saveDriver(driver,dmcId,destinationId);
			}));
		}

		if(this.pageInfo.transportData.transporters){ // persist external transporters and it fleets
			await Promise.all(this.pageInfo.transportData.transporters.map(async transporter =>{
				let transportFleet	=	JSON.parse(JSON.stringify(transporter.fleet));
				delete transporter.fleet;
				await this.transportCtrl.saveTransporter({	
					transporter	: transporter, 
					dmc			: this.commons.pageInfo.dmcSelected,
					destination	: this.commons.pageInfo.destinationSelected
				});
				await Promise.all(transportFleet.map(async transporterFleet =>{
					await this.transportCtrl.saveFleetExternalTransporter({	transporter : transporter, fleet : transporterFleet},
																			dmcId, 
																			destinationId);
				} ))
			}))
		}
	}

	/**
	 * Persist list prices once aggregator doc is created in the destination
	 * @param dmcId 
	 * @param destinationId 
	 */
	async persistListPrices(dmcId,destinationId){
									if(!this.pageInfo.pricingListData){return;}
									await Promise.resolve(this.pageInfo.pricingListData.map(async aggregator =>{
										await this.aggregatorCtrl.addListPricesToAggregator(dmcId,destinationId,aggregator.aggregatorId, aggregator.listPrices);
									}))
								}

	/**
	 * Persist list zones once aggregator doc is created in the destination
	 * @param dmcId 
	 * @param destinationId 
	 */
	async persistListZones(dmcId, destinationId){
		if(!this.pageInfo.mapZonesAggregators){ return;} 
		await Promise.resolve(this.pageInfo.mapZonesAggregators.map(async item =>{
			await this.aggregatorCtrl.addConfigZonesToAggregator(dmcId,destinationId, item.aggregatorId || item.aggregator.aggregatorId, item.zones);
		}))
	}

	async deleteDestination()	{
		if(!this.commons.pageInfo.dmcSelected || !this.commons.pageInfo.destinationSelected){ return this.commons.generateToast('_ERROR','_MISSING_INFO','error')}

		let accountsAggregator	=	await this.aggregatorCtrl.getProviderAccountsFromDmc(this.commons.pageInfo.dmcSelected.id);		
		await Promise.all(accountsAggregator.map(async aggregator =>{
			let accountsToUpdate	=	aggregator.accounts.filter( el => el.destinations.find(dest => dest.id == this.commons.pageInfo.destinationSelected.id));
			accountsToUpdate.forEach(account => account.destinations.splice(account.destinations.findIndex(dest => dest.id == this.commons.pageInfo.destinationSelected.id),1));
			await Promise.all(accountsToUpdate.map(async account =>{
				await this.aggregatorCtrl.updateAccountToProviderDmc(this.commons.pageInfo.dmcSelected.id,aggregator.provider.id,account);
			} ))
		}));
		const removedDest = await this.companyCtrl.deleteDestination(this.commons.pageInfo.destinationSelected.ref);
		if(!removedDest.success){ return this.commons.generateToast('_ERROR',removedDest.error,'error')}
		console.log('ACCOUNTS AGGREGATOR', accountsAggregator);
		


		this.commons.generateToast('_SUCCESS','_DESTINATION_DELETED','success');
		this.commons.pageInfo.destinationSelected = null;
		let aggregatorStepper	=	this.pageInfo.steps.find(el => el.value == 'destinations');
		return await this.onClickStepper({step : aggregatorStepper});
	}

	async deleteDmc()			{
		// this.restartRoute('create');
		if(!this.commons.pageInfo.dmcSelected || !this.pageInfo.refDmcSelected){ return this.commons.generateToast('_ERROR','_MISSING_INFO','error')}
		const deletedDmc	=	await this.companyCtrl.deleteDmc(this.pageInfo.refDmcSelected);
		if(!deletedDmc.success){ return this.commons.generateToast('_ERROR',deletedDmc.error,'error')}
		this.commons.generateToast('_SUCCESS','_DMC_DELETED','success');
		this.ngOnInit();
	}

	restartRoute(pathToGo)		{	this.router.navigateByUrl(this.router.url.replace(pathToGo == 'edit' ? 'create' : 'edit', pathToGo));}

	checkIfDisplayTutorial()	{	
		try			{	
			return this.pageInfo.displayTutorial && this.commons.pageInfo.creationProcess;	
		}catch(e)	{	
			return false;															
		}
	}

	async doAction($type,$info){
		switch($type){
			case "dmc"		:	
				switch($info.action){
					case "create"	: 	this.createNewDmc();						break;
					case "select"	:	this.selectDmc($info.event,$info.dmc); 	break;
				}
				break;

			case "wizard"	:	
				switch($info.action){
					case "button"	: 	switch($info.button.value){
											default		:	return;
											case 'back'	:	await this.previousStep(); 	break;
											case 'next'	:	await this.nextStep();		break;
										}
										break;
				}
				break;
		}
	}

}
