import { CommonsService } from './../../demo/service/commons.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
		 ViewChild} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector    : 'tours-pov',
  templateUrl : './tours-pov.component.html',
  styleUrls   : ['./tours-pov.component.scss'] ,

})

export class ToursPovComponent implements OnInit{

    @Input() pov_list 	: 	any[] = [];
	@Input() items		:	any[] = [];
	@Input() mapInfo	:	any	  = {};
	@ViewChild('inputFilterPov') inputFilterPov : any;
    pageInfo	:	any	=	{
		filteredPovList : 	[],
		displayRoute	:	false
	}
    constructor(private commons : CommonsService){

    }
    ngOnInit(){
		this.pageInfo.filteredPovList	=	this.items;
	}
	
	markMainPovPoint(pov){
        pov.selected = pov.selected ? !pov.selected : true;
        if(pov.selected){
            this.mapInfo.lat = pov.latitude;
            this.mapInfo.lng = pov.longitude;
            this.pov_list.push(pov);
        }else{
            this.pov_list.splice(this.pov_list.findIndex(el => el.name == pov.name),1);
        }
    }
    searchFilterPov($event){
        this.pageInfo.filteredPovList =  $event != ""   ? this.items.filter(item => {return item.name.toLowerCase().includes($event.toLowerCase())})
                                                        : this.items;
    }
    restartFilterPov(){
        this.pageInfo.filteredPovList  = this.items;
        this.inputFilterPov.nativeElement.value = null;  
    }
    getCurrentSearchPov(){
        if(!this.inputFilterPov) return false;
        return this.inputFilterPov.nativeElement.value;
    }
    displayAllPov(){
        this.pageInfo.displayPovs = !this.pageInfo.displayPovs; 
    }
}
