import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';


@Component({
    selector   : 'app-marker-lodging',
    templateUrl: './markerLodging.component.html',
    styleUrls:['./markerLodging.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class MarkerLodgingComponent implements OnInit{

    @Input() item   : any;
    
    ngOnInit(){
        console.log('HOTEL IS', this.item);
    }
}