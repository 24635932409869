import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { providerCols 			} from './data/columns';
import { entities 				} from './data/items';

@Component({
  selector    		: 'app-company-expenses',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyExpensesComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: {},
		entity			: {}
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService	
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		// INFO
		this.pageInfo.entity.form = { 
			ready		: false,
			entities	: this.pageInfo.entities,			
			form		: [
				{
					title		: "_MAIN",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'id', 			label:'_CODE',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'company', 		label:'_COMPANY',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'cif', 			label:'_CIF',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'zipcode', 		label:'_ZIPCODE',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'address', 		label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				},
						
					]
				}
				,{
					title		: "_LOCATION",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-map-marker",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'municipality', 	label:'_MUNICIPALITY',	type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'region', 		label:'_REGION',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'country',		label:'_COUNTRY',		type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	},
						{ mandatory: true, 	field	: 'register', 		label:'_REGISTER',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'maincompany', 	label:'_MAIN_COMPANY',	type: 'toggle',	editor: 'toggle',		editable	: true				}
					]
				}
				,{
					title		: "_COMPANY_CONTACT",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-phone",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'phone', 			label:'_PHONE',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'fax', 			label:'_FAX',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'email', 			label:'_COMPANY',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'web', 			label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				}						
					]
				}				
				,{
					clear		: true,
					title		: "_CONTACT_PERSON",
					showTitle	: true,
					icon		: "fa fa-user",
					cols		: 4,
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'contact_name', 	label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'contact_role', 	label:'_ROLE',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'contact_phone', 	label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'contact_email',	label:'_EMAIL',			type: 'text',	editor: 'number',		editable	: true				}						
					]
				}
				,{
					title		: "_INVOICING",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'vat', 			label:'_VAT',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'billdigits',		label:'_BILLDIGITS',	type: 'text',	editor: 'number',		editable	: true				}
					]
				}
			]
		}
	}

	async loadEntities()		{ await this.load("main"); 					}
  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();
		this.selectProvider(this.currentProvider);
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)	{
		switch($type){
			case "toggle_provider"	:	this.selectProvider($item); break;			
			case "button"			:	switch($item.name){		
											case "new_provider"	: 	this.emitter.emit({ action: 'new_provider' });						break;
											case "reload"		: 	this.load("providers"); this.selectProvider(this.currentProvider);	break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;
											case "import"		: 	this.commons.generateToast("Button pressed","Import","info");		break;
										}
										break;
		}
	}
	selectProvider($item){
		if(undefined==$item){ this.pageInfo.itemSelected = false; return false; }
		this.pageInfo.entity.map(item=>{item.selected=item.id==$item.id?(item.selected?false:true):false;});
		this.pageInfo.itemSelected 	= this.pageInfo.entity.some(item=>item.selected);
		if(this.pageInfo.itemSelected){ 
			this.emitter.emit({'action':'provider_selected','item': this.pageInfo.entity.find(item=>item.selected) });
		} else {
			this.emitter.emit({'action':'provider_unselected'});
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "main": 	return (this.pageInfo.entity.data||[]).filter(item=>{
									return true;
									// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
									// item.view 		= isSelected;
									// item.color		= "red";
									// return isSelected;
								});
		}
	}
	async load($entity){
		switch($entity){
			case 'main' :
				let response 					= 	await Promise.resolve(this.entityService.getComplexRequest("companyInfo",{ id: 1 },{}));
				this.pageInfo.entity.form.info	= 	response["data"];
				this.pageInfo.entity.form.ready	=	true;		
				break;
		}
	}
}
