import { Injectable 		} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { IProviderService 	} from './iproviderservice';
import { HttpClient 		} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProviderSunTransfersService implements IProviderService {		
	pageInfo 	= { providerId: 31 };

  	constructor(
		private commons 	: CommonsService,
		private http		: HttpClient
	){
		// this.commons.generateToast("_REGISTER_PROVIDER","_STF_SERVICE_PROVIDER","info");
	}
    
	init($controller){
		$controller.registerProviderService({ 
			id		: this.pageInfo.providerId,
			name	: "stf", 
			service	: this 
		});
	}

    // Process bookings into STF format
    // convert to Tourinia format
    // and generate groups for tripulation

	filterBooking($params):boolean{
		const $booking 			= $params["booking"			];
		const $show_cancelled	= $params["show_cancelled"	];

		if(!$params["show_cancelled"]){
			switch($booking.status){
				case "ACAN":
				case "PCAN":	return false;
			}
		}
		return true;		
	}

	mapBooking($booking):any{
		switch($booking.status){
			case undefined	:
			case null		:
				$booking.status = "PCON";
			case "PCON":
			case "PAMM":
			case "PCAN":
				$booking.verified = false;
				break;
			case "ACON":
			case "AAMM":
			case "ACAN":
				$booking.verified = true;
				break;
		}
		return $booking;
	}

    processBookings($info){
		let groups		= [];
		let bookingsMap	= [];
		let response;

		($info.bookings||[]).forEach((booking,index) => {
			let data = {};
			
			// Generate booking map from header and data
			($info.header||[]).forEach((field,index)=>{
				data[field]=booking[index];
			});

			if(response["success"]){
				data = response.data;
			}
			bookingsMap.push(data);
		});

        return bookingsMap;
	}
	
	processArrival($info):any{}

	processDeparture($info):any{}

	check4Validation($params):any{
		let $item = $params["item"];

		// NOTAS OBLICATORIAS ( p.e. OTBT-20016798 )
		// 	Si es privado
		// 	Si es PR3, PR4 o PR7
		// 	Si tienen niños o bebés
		// 		Obligatorio mandar nota antes de validar
		// 		Marcar como error o warning
		switch($item.shared){
			case "private":
				switch($item.vehicle){
					case "Private Standard Car 1 - 3"	:
					case "Private Standard Car 1 - 4"	:
					case "Private Standard Car 1 - 7"	:
					case "Private Premium Car 1 - 3"	:
					case "Private Premium Car 1 - 4"	:
					case "Private Premium Car 1 - 7"	:
						if($item.children>0 || $item.infants>0){
							if(undefined==$item.notes || $item.notes==""){
								this.commons.generateToast("_STD_SERVICE","_CHECK_VALIDATION_FOR_TYPE_1","info");
								return false;
							}
						}
						break;
				}
				break;
		}

		if($params["notify"]){
			this.commons.generateToast("_STF_SERVICE","_CHECK_4_VALIDATION_CORRECT","info");
		}
		
		return true;
	}

	async sendErrors($params){
		console.log("[sendError] Params",$params);
		await this.sendErrorNotification($params["booking"]);
	}
	
	async sendErrorNotification(booking:any) {
		const errorData:any = {
			address			: (booking.arrival_error_address 			|| booking.departure_error_address 			) ? 1 : 0,
			private_address	: (booking.arrival_error_private_address 	|| booking.departure_error_private_address 	) ? 1 : 0,
			flight			: (booking.arrival_error_flight 			|| booking.departure_error_flight 			) ? 1 : 0,
			cruiser			: (booking.arrival_error_cruiser 			|| booking.departure_error_cruiser 			) ? 1 : 0,
			train			: (booking.arrival_error_train 				|| booking.departure_error_train 			) ? 1 : 0,
			stop			: (booking.arrival_error_stop 				|| booking.departure_error_stop 			) ? 1 : 0,
			customer		: (booking.arrival_error_customer 			|| booking.departure_error_customer 		) ? 1 : 0
		};

		const hasErrors = parseInt(Object.keys(errorData).reduce((acc,k)=>acc+=errorData[k],0));
		errorData.note	= booking.arrival_error_note || booking.departure_error_note || '';
		const formData 	= {
			errors	: new URLSearchParams(errorData).toString(),
			link	: booking.link || ''
		};

		console.log("Data sent",formData);

		if(hasErrors==0 && errorData.note==""){
			console.log("No hay errores");
		} else {
			console.log("Mandando los errores");
			const reference		= booking.real_reference || booking.reference;
			// const apiUrl 		= `http://localhost:3000/api/v3.0/bookings/errors/?provider=31&booking=${reference}`;
			const apiUrl 		= `${environment.openApi.url}/bookings/errors/?provider=31&booking=${reference}`;
			const response:any 	= await this.http.post(apiUrl, formData).toPromise();
		
			if (response.success) {
				console.log(`✔ Error reportado para ${booking.reference}`);
			} else {
				console.error(`❌ Error al enviar notificación para ${booking.reference}`);
			}
		}
	}

    /**
     * 
     * @param booking 
     */
    async acceptBooking(booking:any)
    {		
        const reference		= booking.real_reference || booking.reference;
        const apiUrl 		= `${environment.openApi.url}/bookings/accept/?provider=31&booking=${reference}`;
        const response:any 	= await this.http.post(apiUrl, { reference: reference }).toPromise();
    
        if (response.success) {
            console.log(`✔ Reserva ${booking.reference} aceptada`);
        } else {
            console.error(`❌ Error al aceptar la reserva ${booking.reference}`);
        }
	}

    /**
     * notify pickups creating an XLSX
     * @param $params 
     */
    notifyPickups($params) {
		const bookings = $params["bookings"];
		console.log("[STF:notifyPickups] booking", bookings);

		// Filtrar solo reservas aceptadas
		const confirmedBookings = bookings.filter(b => b.transporter_status === "_ACCEPTED");

		if (confirmedBookings.length === 0) {
			this.commons.generateToast("No hay reservas confirmadas para enviar", "info");
			return;
		}

		// 🔹 Enviar a la API de Tourinia en lugar de generar un XLSX
		this.processAndNotifyPickups({ bookings: confirmedBookings });
	}

	async processAndNotifyPickups($params: any) {
		try {
			const bookings 	= $params["bookings"];
			const payload 	= { bookings };
			// const apiUrl = "http://localhost:3000/api/v3.0/bookings/notify?provider=31";
            const apiUrl 	= `${environment.openApi.url}/bookings/notify?provider=31`;
			
			const response 	= await this.http.post(apiUrl, payload).toPromise();

			console.log("✅ Pickups notificados con éxito", response);
			this.commons.generateToast("Pickups notificados con éxito", "success");

		} catch (error) {
			console.error("❌ Error en el proceso:", error);
			this.commons.generateToast("Error al notificar pickups", "error");
		}
	}
}