export const filters	= [
	{ 	
		name	: 'availability',
		type	: 'dropdown_calendar', 
		position: 'left',
		label	: '_AVAILABILITY',
		items	: [
			{ label: "calendar1", 			value: "calendar2" }
		],
		showWeek: true,
		selected: undefined
	},
	{ 	
		name	: 'price_range',
		type	: 'dropdown_price',
		position: 'left',
		label	: '_PRICE_RANGE',
		items	: [
			{ label: "_ITEM_1", 				value: "item1" },
			{ label: "_ITEM_2", 				value: "item2" }
		],
		selected: undefined
	},
	{ 	
		name	: 'confirmation',
		type	: 'dropdown',
		position: 'left',
		label	: '_INSTANT_CONFIRMATION',
		items	: [
			{ label: "_YES", 				value: "yes" },
			{ label: "_NO", 					value: "no" }
		],
		selected: "yes"
	},
	{ 	
		name	: 'duration',
		type	: 'dropdown',
		multiple: true,
		position: 'left',
		label	: '_DURATION',
		items	: [
			{ label: "_4_HOURS", 			value: "4" },
			{ label: "_6_HOURS", 			value: "6" },
			{ label: "_8_HOURS", 			value: "8" }
		],
		selected: [ 4, 6, 8 ]
	},
	{ 	
		name	: 'turns',
		type	: 'dropdown',
		multiple: true,
		position: 'left',
		label	: '_TURNS',
		items	: [
			{ label: "_TURN_EARLY_MORNING", value: "early_morning" 	},
			{ label: "_TURN_MORNING", 		value: "morning" 		},
			{ label: "_TURN_AFTERNOON", 	value: "afternoon" 		},
			{ label: "_TURN_NIGHT", 		value: "night" 			},
			
		],
		selected: [ "early_morning", "morning", "afternoon", "night" ]
	},
	{ 	
		name	: 'offers',
		type	: 'dropdown',
		multiple: true,
		position: 'left',
		label	: '_OFFER',
		items	: [
			{ label: "_YES", 				value: "yes" },
			{ label: "_NO", 					value: "no" }
		],
		selected: [ "yes", "no" ]
	},
	{ 	
		name	: 'sort',
		type	: 'dropdown',
		position: 'right', 
		label	: '_SORTBY',
		items	: [
			{ label: "_MOST_RELEVANT", 		value: "most_relevant" 	},
			{ label: "_MOST_POPULAR", 		value: "most_popular" 	},
			{ label: "_BEST_REVIEWED", 		value: "best_reviewed" 	},
			{ label: "_PRICE_LOW_TO_HIGH",	value: "price" 			},
			{ label: "_RECENTLY_ADDED", 		value: "recently_added"	}
		],
		selected: "most_relevant"
	}
];