import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { FirebaseService 		} from './../../../demo/service/database/firebase.service';
import { StorageService 		} from './../../../demo/service/storageservice';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { providerCols 			} from './data/columns';
import { entities 				} from './data/items';

@Component({
  selector    		: 'app-company-accounting',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyAccountingComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	userInfo			: any =	{};	
	pageInfo  			: any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { accounts: {} },
		entity			: { form: { ready: false }, table: { ready: false }}
	}
	  
	constructor( 
		private commons				: CommonsService,
		private entityService		: EntityService,
		private firebaseCtrl		: FirebaseService,
		private storage 			: StorageService
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		// INFO
		this.pageInfo.entity.form = { 
			ready		: false,
			entities	: this.pageInfo.entities,			
			form		: [
				{
					title		: "_REGISTER",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'cif', 						label:'_CIF',						type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'fiscal_name', 				label:'_FISCAL_NAME',				type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'commercial_register',		label:'_COMMERCIAL_REGISTER',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'fiscal_closing', 			label:'_FISCAL_CLOSING',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'recharging_equiv',			label:'_RECHARGING_EQUIV',			type: 'text',	editor: 'text',			editable	: true				}
					]
				},
				{
					title		: "_SERIES",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'prefix_invoices', 			label:'_PREFIX_INVOICES',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'prefix_invoices_rectified',	label:'_PREFIX_INVOICES_RECTIFIED',	type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'prefix_tickets', 			label:'_PREFIX_TICKETS',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'prefix_tickets_rectified',	label:'_PREFIX_TICKETS_RECTIFIED',	type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'prefix_budget', 				label:'_PREFIX_BUDGET',				type: 'text',	editor: 'text',			editable	: false				}						
					]
				},
				{
					title		: "_TAXES",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 0.75rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'vat_type', 					label:'_VAT_TYPE',					type: 'number',	editor: 'number',		editable	: true				},
						{ mandatory: true, 	field	: 'vat_intra', 					label:'_VAT_INTRA',					type: 'text',	editor: 'text',			editable	: true				}						
					]
				},
				{
					title		: "_REFERENCES",
					cols		: 4,
					showTitle	: true,
					icon		: "fa fa-file-text-o",
					padding		: "0rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'accounting_digits', 			label:'_ACCOUNTING_DIGITS',			type: 'number',	editor: 'number',		editable	: true				},
						{ mandatory: true, 	field	: 'accounting_program_id',		label:'_ACCOUNTING_PROGRAM_ID',		type: 'text',	editor: 'text',			editable	: true				}
					]
				}						
			]
		}

		this.pageInfo.entity.table	= {
			ready	: false,
			cols	: [
				{ field: 'active',     	header: '_ACTIVE',	    width: '50px', 	align: 'center'		},
				{ field: 'id',     		header: '_ID', 		    width: '50px', 	align: 'center'		},
				{ field: 'name',     	header: '_NAME', 	    width: '200px', align: 'center'		},
				{ field: 'entity',		header: '_ENTITY',  	width: '200px', align: 'center'		},
				{ field: 'iban',	    header: '_IBAN',  		width: '200px', align: 'center'		},
				{ field: 'swift',	    header: '_SWIFT',  	    width: '200px',	align: 'center'		}
			],
			options	: { rowExpander: true },
			filters	: [
				// {
				// 	entity	: 'type',
				// 	name	: 'type',
				// 	label	: '_TYPE',
				// 	type	: 'multiple',
				// 	field	: 'type',
				// 	icon	: 'share-alt',
				// 	items	: [
				// 		{ label: '_PRIVATE',	value: 'private'},
				// 		{ label: '_SHARED',		value: 'shared'	}
				// 	],
				// 	selected: [ 'private', 'shared' ]
				// }
			],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{ field: 'active',     	label: '_ACTIVE',	    width: '50px', 		align: "center",    editor : 'input',       editable:true,		options : {type : 'text'},        			mandatory : true 	},
		            { field: 'id',     		label: '_ID', 		    width: '50px', 		align: "center",    editor : 'input',       editable:true,		options : {type : 'text'},        			mandatory : true 	},
		            { field: 'name',     	label: '_NAME', 	    width: '200px', 	align: "center",    editor : 'input',       editable:true,		options : {type : 'text'},        			mandatory : true 	},
		            { field: 'entity',		label: '_ENTITY',  	    width: '200px',		align: "center",    editor : 'input',       editable:true,		options : {type : 'text'},       	 		mandatory : true 	},						
		            { field: 'IBAN',	    label: '_IBAN',  		width: '200px', 	align: "center",    editor : 'input',       editable : true,  	options : {type : 'text'},    				mandatory : false	},
		            { field: 'SWIFT',	    label: '_SWIFT',  	    width: '200px', 	align: "center",    editor : 'input',       editable : false,  	options : {type : 'text'},   				mandatory : true 	}		           
				]
			}
		};
	}

	async loadEntities()		{ 	this.userInfo =	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
									await this.load("main"); 
									await this.load("accounts"); 
								}
  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();
		this.selectProvider(this.currentProvider);
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)	{
		switch($type){
			case "toggle_provider"	:	this.selectProvider($item); break;			
			case "button"			:	switch($item.name){		
											case "new_provider"	: 	this.emitter.emit({ action: 'new_provider' });						break;
											case "reload"		: 	this.load("providers"); this.selectProvider(this.currentProvider);	break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;
											case "import"		: 	this.commons.generateToast("Button pressed","Import","info");		break;
										}
										break;
		}
	}
	selectProvider($item){
		if(undefined==$item){ this.pageInfo.itemSelected = false; return false; }
		this.pageInfo.entity.map(item=>{item.selected=item.id==$item.id?(item.selected?false:true):false;});
		this.pageInfo.itemSelected 	= this.pageInfo.entity.some(item=>item.selected);
		if(this.pageInfo.itemSelected){ 
			this.emitter.emit({'action':'provider_selected','item': this.pageInfo.entity.find(item=>item.selected) });
		} else {
			this.emitter.emit({'action':'provider_unselected'});
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "accounts"	:	return (this.pageInfo.entities[$entity].data||[]).filter(item=>{ return true; });
		}
	}
	async load($entity){
		let response;
		switch($entity){
			case 'main' :
				response 								= 	await Promise.resolve(this.entityService.getComplexRequest("companyInfo",{ id: 1 },{}));
				this.pageInfo.entity.form.info			= 	response["data"];
				this.pageInfo.entity.form.ready			=	true;		
				break;

			case 'accounts' :
				response 								= 	await Promise.resolve(this.entityService.getRequest("bank_accounts",{}));
				this.pageInfo.entities[$entity].data	= 	response["data"];
				this.pageInfo.entities[$entity].count 	= 	response["data"] ? response["data"].length : 0;
				this.paginate($entity, null);
				this.pageInfo.entity.table.ready		=	true;
				break;
		}
	}

	paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
    }
}
