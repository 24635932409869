export const CS_LP = [
    {
      "Id": 2170,
      "Name": "APARTAMENTOS ISA",
      "Island": "LP",
      "ZoneId": 501,
      "ZoneName": "Tazacorte",
      "Address": "Calle Progreso, 14, 38770 Tazacorte, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6404137,-17.9338904"
    },
    {
      "Id": 2172,
      "Name": "APTOS ATLANTIS",
      "Island": "LP",
      "ZoneId": 501,
      "ZoneName": "Tazacorte",
      "Address": "Calle Mariano Benlliure, 14, 38770 Tazacorte, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6422289,-17.9324652"
    },
    {
      "Id": 2200,
      "Name": "HOTEL HACIENDA DE ABAJO",
      "Island": "LP",
      "ZoneId": 501,
      "ZoneName": "Tazacorte",
      "Address": "Calle Miguel de Unamuno, 11, 38770 Tazacorte, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6433261,-17.9349355"
    },
    {
      "Id": 2171,
      "Name": "APTOS ADJOVIMAR",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle de, Calle Pedro Miguel Hernández Camacho, 52, 38760 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6529803,-17.9113273"
    },
    {
      "Id": 2195,
      "Name": "HOTEL ARIDANE",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle Glorieta Castillo Olivares, 3, 38760 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6582187,-17.9160201"
    },
    {
      "Id": 2196,
      "Name": "HOTEL EDEN",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle Ángel, 1, 38760 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.658978,-17.912334"
    },
    {
      "Id": 2207,
      "Name": "HOTEL TROCADERO PLAZA - Hotel Benahoare",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle las Adelfas, 12, 38760 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6566242,-17.910791"
    },
    {
      "Id": 2208,
      "Name": "LA PLANTACION",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "s/n KM 4 La Laguna, Camino el Pedregal, 38760 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6256999,-17.9023494"
    },
    {
      "Id": 2209,
      "Name": "LA VILLA",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle Urb Celta, S/n, 38769 Dos Pinos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.648765,-17.8965974"
    },
    {
      "Id": 2213,
      "Name": "RESIDENCIAL LAS NORIAS",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle las Norias, 26, 38770 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6069737,-17.9088595"
    },
    {
      "Id": 2495,
      "Name": "La Palma Jardin",
      "Island": "LP",
      "ZoneId": 502,
      "ZoneName": "Llanos De Aridane",
      "Address": "Calle Ur Celta Segunda, 20, 38759 Dos Pinos, Santa Cruz de Tenerife, España",
      "Coordinates": "28.647802960648384,-17.896041871940447"
    },
    {
      "Id": 2173,
      "Name": "APTOS BREÑAS GARDEN",
      "Island": "LP",
      "ZoneId": 503,
      "ZoneName": "San Jose- Breña Baja",
      "Address": "38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.637263,-17.775062"
    },
    {
      "Id": 2189,
      "Name": "APTOS LOS MOLINOS",
      "Island": "LP",
      "ZoneId": 503,
      "ZoneName": "San Jose- Breña Baja",
      "Address": "C. Finca Amado II, 38712 Breña Baja",
      "Coordinates": "28.635619087671714,-17.774945328561124"
    },
    {
      "Id": 2191,
      "Name": "APTOS MIRANDA",
      "Island": "LP",
      "ZoneId": 503,
      "ZoneName": "San Jose- Breña Baja",
      "Address": "Ctra. el Zumacal, 83, 38710 Breña Alta, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6571272,-17.7760322"
    },
    {
      "Id": 2212,
      "Name": "PARADOR DE TURISMO",
      "Island": "LP",
      "ZoneId": 503,
      "ZoneName": "San Jose- Breña Baja",
      "Address": "Ctra. el Zumacal, s/n, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6511583,-17.7748605"
    },
    {
      "Id": 2175,
      "Name": "APTOS CASTILLETE",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Av. Marítima, 75, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6873913,-17.7598743"
    },
    {
      "Id": 2185,
      "Name": "APTOS LA FUENTE",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Calle Anselmo Pérez de Brito, 49, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife",
      "Coordinates": "28.685849, -17.762683"
    },
    {
      "Id": 2198,
      "Name": "HOTEL GALEON",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Carretera Galeón, 10, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6783529,-17.7713473"
    },
    {
      "Id": 2204,
      "Name": "HOTEL SAN TELMO",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Calle San Telmo, 5, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.68205,-17.76678"
    },
    {
      "Id": 2482,
      "Name": "Muelle Sta. Cruz de La Palma",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Antigua Sede Estación Marítima de SCLP Locales 9-11, 38700 Santa Cruz de la Palma",
      "Coordinates": "28.67975803784693,-17.76649504120669"
    },
    {
      "Id": 2522,
      "Name": "Hotel Holiday time",
      "Island": "LP",
      "ZoneId": 504,
      "ZoneName": "Sta. Cruz De La Palma",
      "Address": "Av. Marítima, nº 33, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife, España",
      "Coordinates": "28.683684102004214,-17.763438826985332"
    },
    {
      "Id": 2177,
      "Name": "APTOS CHARCO VERDE",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Calle Charco Verde, 5, 38769 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5732119,-17.9026288"
    },
    {
      "Id": 2179,
      "Name": "APTOS DELFIN-PLAYA",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Avenida José Guzman Pérez, 1, 38769 Los Llanos de Aridane-Puerto Naos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.586659,-17.9103988"
    },
    {
      "Id": 2186,
      "Name": "APTOS LA MURALLA",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Ctra. Puerto Naos, s/n, 38769 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5916722,-17.9097424"
    },
    {
      "Id": 2188,
      "Name": "APTOS LOS LAJONES",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Calle Mauricio Duque Camacho, 2, 38769 Puerto de Naos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5882,-17.91082"
    },
    {
      "Id": 2190,
      "Name": "APTOS LOS ROQUES",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Calle Costa Gris, 27, 38769 Puerto de Naos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5882623,-17.9114027"
    },
    {
      "Id": 2193,
      "Name": "APTOS ROQUE MONICA",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Calle Costa Gris, 27, 38769 Puerto de Naos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5882623,-17.9114027"
    },
    {
      "Id": 2194,
      "Name": "APTOS SOL LA PALMA",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Ctra. Puerto Naos A, 419A, 38769, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5827045,-17.908397"
    },
    {
      "Id": 2205,
      "Name": "HOTEL SOL LA PALMA",
      "Island": "LP",
      "ZoneId": 505,
      "ZoneName": "Puerto Naos",
      "Address": "Calle Punto del Pozo, 24, 38769 Los Llanos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5821619,-17.9090912"
    },
    {
      "Id": 2197,
      "Name": "HOTEL FATO PUNTA CUMPLIDA",
      "Island": "LP",
      "ZoneId": 506,
      "ZoneName": "Barlovento",
      "Address": "Calle Lomo Machín Bajo, 66, 38726, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.839,-17.77818"
    },
    {
      "Id": 2202,
      "Name": "HOTEL LA PALMA ROMANTICA",
      "Island": "LP",
      "ZoneId": 506,
      "ZoneName": "Barlovento",
      "Address": "Santa Cruz de Tenerife ES, Las llanadas, 38726 Barlovento, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.8223018,-17.8050708"
    },
    {
      "Id": 2178,
      "Name": "APTOS COLON",
      "Island": "LP",
      "ZoneId": 507,
      "ZoneName": "Fuencaliente",
      "Address": "Camino Nicho la Cruz, 73, 38749 Los Quemados, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.4915778,-17.8564999"
    },
    {
      "Id": 2201,
      "Name": "HOTEL LA PALMA PRINCESS",
      "Island": "LP",
      "ZoneId": 507,
      "ZoneName": "Fuencaliente",
      "Address": "Ctra. la Costa Cerca Vieja, 10, 38740, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5035118,-17.8741616"
    },
    {
      "Id": 2206,
      "Name": "Esencia de La Palma by Princess",
      "Island": "LP",
      "ZoneId": 507,
      "ZoneName": "Fuencaliente",
      "Address": "Ctra. la Costa Cerca Vieja, 10, 38740, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.5035118,-17.8741616"
    },
    {
      "Id": 2214,
      "Name": "VILLAS FUENCALIENTE",
      "Island": "LP",
      "ZoneId": 507,
      "ZoneName": "Fuencaliente",
      "Address": "38740 Los Canarios, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.4908085,-17.8467963"
    },
    {
      "Id": 2174,
      "Name": "APTOS CANCAJOS",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Travesía de los Cancajos, 52, 38712 Los Cancajos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6506731,-17.7599547"
    },
    {
      "Id": 2176,
      "Name": "APTOS CENTRO CANCAJOS",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Travesía de los Cancajos, 52, 38712 Los Cancajos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6506731,-17.7599547"
    },
    {
      "Id": 2180,
      "Name": "APTOS EL CERRITO",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Calle de la Tabaiba Dulce, 34, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6470636,-17.7598567"
    },
    {
      "Id": 2181,
      "Name": "APTOS H10 COSTA SALINAS",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Calle los Cancajos, 14, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6525114,-17.7633397"
    },
    {
      "Id": 2182,
      "Name": "APTOS HACIENDA SAN JORGE",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Playa de Los Cancajos, no 22, 38712, 38712 Los Cancajos, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6511766,-17.7612259"
    },
    {
      "Id": 2183,
      "Name": "APTOS LA CALETA",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Calle La Corvina, 2, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6459784,-17.7592288"
    },
    {
      "Id": 2184,
      "Name": "APTOS LA CASCADA",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Urbanización La Cascada, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6463014,-17.7582983"
    },
    {
      "Id": 2187,
      "Name": "APTOS LAGO AZUL",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Urbanizacion San Antonio del Mar, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6461111,-17.7575"
    },
    {
      "Id": 2192,
      "Name": "APTOS OASIS SAN ANTONIO",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "San Antonio de Mar, s/n, 38712 Los Cancajos, Breña Baja, La Palma, Santa Cruz de Tenerife, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6458333,-17.76"
    },
    {
      "Id": 2199,
      "Name": "HOTEL H10 TABURIENTE PLAYA",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Travesía de los Cancajos, 36, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6494442,-17.7580869"
    },
    {
      "Id": 2203,
      "Name": "HOTEL LAS OLAS",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Travesía de los Cancajos, 2, 38712 Breña Baja, Santa Cruz de Tenerife, Spain",
      "Coordinates": "28.6443618,-17.7590601"
    },
    {
      "Id": 2210,
      "Name": "LAS ADELFAS",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Av. de Francia, 36, 35510 Puerto del Carmen, Las Palmas, Spain",
      "Coordinates": "28.9250077,-13.6417187"
    },
    {
      "Id": 2211,
      "Name": "Los Rosales",
      "Island": "LP",
      "ZoneId": 508,
      "ZoneName": "Cancajos",
      "Address": "Calle La Corvina, 21, 38712 Breña Baja, Santa Cruz de Tenerife",
      "Coordinates": "28.646681,-17.759"
    },
    {
      "Id": 501,
      "Name": "Aeropuerto La Palma (SPC)",
      "Island": "LP",
      "ZoneId": 509,
      "ZoneName": "Aeropuerto",
      "Address": "Camino la Bajita, s/n, 38739 Villa de Mazo",
      "Coordinates": "28.622356,-17.7548144"
    }
  ];