export const filters = [	
	{
		entity	: 'activities',
		name	: 'activities',
		label	: 'Activities',
		type	: 'multiple',
		field	: 'activities',
		items	: [
			{ label: 'Transfers', 		value: 'transfers'	},
			{ label: 'Tours', 			value: 'tours'		},
			{ label: 'Lodgings', 		value: 'lodgings' 	},
			{ label: 'Customers', 		value: 'customers' 	}
		],
		selected: ['transfers','tours']
	},		
	{
		entity	: 'zones',
		name	: 'zones',
		label	: 'Zones',
		type	: 'multiple',
		field	: 'zones',
		items	: [
			{ label : 'Airport',		value: 'airport'}
		], 
		selected: [ ]
	}
];

export const entitiesToDisplay = []

export const subEntitiesToDisplay = []
// {
// 	entity	: 'transfers',
// 	name	: 'transfers',
// 	label	: 'Transfers',
// 	type	: 'multiple',
// 	field	: 'type',
// 	items	: [
// 		{ label: 'Arrival', 	value: 'arrival' 	},
// 		{ label: 'Departure', 	value: 'departure' 	}
// 	],
// 	selected: [ "arrival", "departure" ]
// }