import { Component,
         Input,
         Output,
         EventEmitter,
		 ChangeDetectionStrategy,
		 ViewChild	     			} from '@angular/core';
import { CommonsService 			} from 'src/app/demo/service/commons.service';
import { LyTheme2, ThemeVariables 	} from '@alyle/ui';
import { LyResizingCroppingImages,
		 ImgCropperConfig,
		 ImgCropperEvent 			} from '@alyle/ui/resizing-cropping-images';

import { detailStyles				} from './styles/detail.styles';
import { listStyles					} from './styles/list.styles';

@Component({
  selector    		: 'app-picture-editor',
  styleUrls			: ['./component.scss'],
  changeDetection	: ChangeDetectionStrategy.OnPush,
  templateUrl 		: 'component.html'
})
export class PictureEditorComponent {
	@ViewChild("_fileInput")			 fileInput;
	@ViewChild(LyResizingCroppingImages) imgCropper		: LyResizingCroppingImages;
	// @ViewChild(LyResizingCroppingImages) 	detailCropper	: LyResizingCroppingImages;
	// @ViewChild(LyResizingCroppingImages) 	listCropper	: LyResizingCroppingImages;

	@Input("type")      type	:string	= "";
	@Input("info")		info	:any	= {};
	@Output()			emitter			= new EventEmitter<any>();

	pageInfo  			: any = { view: 'detail' };
	draggedItem			: any = {};

	constructor(
		private commons		: CommonsService,
		private theme		: LyTheme2
	){
		this.init();
	}	

	init()
	{		
		this.pageInfo.detail	= {
			classes 		: this.theme.addStyleSheet(detailStyles),
			croppedImg		: "",
			scale			: 1,	
			config			: {
				width	: 533,
				height	: 300,
				// fill	: '#ff2997'
				fill	: 'red'
			}
		};
		this.pageInfo.list	= {
			classes 		: this.theme.addStyleSheet(listStyles),
			croppedImg		: "",
			scale			: 1,	
			config			: {
				width	: 150,
				height	: 100,
				// fill	: '#ff2997'
				fill	: 'blue'
			}
		};	
		this.pageInfo.noImage	= "/assets/layout/images/tours/simulation/no_image.jpg";
		this.pageInfo.tabs		= {
			items 			: [
				{ id:0, label: this.commons.getTranslate('_PICTURES'),	icon : 'fa fa-picture-o' 	, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:1, label: this.commons.getTranslate('_VIDEOS'	), 	icon : 'fa fa-video-camera' , command: ($event)=> { this.setTabItem($event)} 	},
				{ id:2, label: this.commons.getTranslate('_AUDIOS'	), 	icon : 'fa fa-volume-up' 	, command: ($event)=> { this.setTabItem($event)} 	},
			],
			selected		: 0
		};
		this.pageInfo.buttons	= {
			view	: [
				{ label : this.commons.getTranslate("_MOBILE_PICTURE_LIST")		,value : "list", 	icon: "fa fa-list"		},
				{ label : this.commons.getTranslate("_MOBILE_PICTURE_DETAIL") 	,value : "detail",	icon: "fa fa-asterisk"	}
			],
			menu	: [
				{ 	name: 'close',			label: '_CLOSE', 			icon: 'fa fa-close' 		, type: 'button'	},
				{ 	name: 'save',			label: '_SAVE', 			icon: 'fa fa-save'	 		, type: 'button'	},
				{ 	name: 'upload',			label: '_UPLOAD', 			icon: 'fa fa-upload' 		, type: 'upload'	},
				{ 	name: 'crop',			label: '_CROP', 			icon: 'fa fa-crop' 			, type: 'crop'		}
			],
			aspect	: [
				{	label: "_MOBILE",
					items: [ 
						{ 	name: 'mobile_list',	label: '_MOBILE_LIST' 	},
						{ 	name: 'mobile_detail',	label: '_MOBILE_DETAIL'	}
					]
				},
				{
					label: "_WEB",
					items: [
						{ 	name: 'web_list',		label: '_WEB_LIST'		},
						{ 	name: 'web_detail',		label: '_WEB_DETAIL'	}
					]
				}
			]
		}
	}

	onCrop($type,e: ImgCropperEvent) {
		this.pageInfo[this.pageInfo.view].croppedImg = e.dataURL;		
		console.log(e);
	}
	getCroppedImg() 			{		
		const img = this.imgCropper.crop();
		console.log(img);
		return img.dataURL;
	}
	setEditorClasses($view?)	{	this.pageInfo.view = $view?$view:this.pageInfo.view;			}
	setTabItem($event)			{	this.pageInfo.tabs.selected = $event.item.id;					}
  	ngOnInit()					{
									// this.info.url = this.info.src!==undefined?undefined:this.info.url;
									// this.info.url = this.info.src!==undefined?this.info.src:this.info.url;									
									this.action('setImage',this.info);								
								}
	ngOnDestroy() 				{}
    action($type,$item)			{
		switch($type){
			case "upload"			: 	this.fileInput.nativeElement.click(); break;
			case "setImage"			: 	//const img = "/assets/layout/images/tours/1/"+$item.id+".jpg";
										// this.pageInfo.view=="detail"
										// 	?this.detailCropper.setImageUrl(img)
										// 	:this.listCropper.setImageUrl(img);
										// this.info.url = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64,"+btoa(this.info.url));
										if( undefined!==this.imgCropper ){ this.imgCropper.setImageUrl(this.info.url); }
										break;
			case "close"			: 	this.emitter.emit({ 'action': 'close' }); break;
			case "save"				: 	this.emitter.emit({ 
											'action'	: 'save',
											'detail'	: this.pageInfo["detail"].croppedImg,
											'list'		: this.pageInfo["list"].croppedImg
										}); 
										break;
			case "viewChange"		:	this.setEditorClasses(); break;			
		}
	}
}
