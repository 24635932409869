import { MenuItem } from 'primeng/primeng';
import { 	HttpClient 					} from '@angular/common/http';
import { 	CommonsService 				} from '../../service/commons.service';
import { 	FlightService 				} from '../../service/flight.service';
import { 	EntityService 				} from '../../service/entity.service';
import { 	MessageService 				} from 'primeng/components/common/messageservice';
import { 	OnInit,
			Component,
			ViewChild,
			ViewEncapsulation,
			Inject,						
			ElementRef} from '@angular/core';
import { groupsCols 					} from './columns/groups.columns';
import { fleetCols 						} from './columns/fleet.columns';
import { driversCols 					} from './columns/drivers.columns';
import { DOCUMENT 						} from '@angular/common';
import { filters 						} from './data/filters';
import { ignoreElements } from 'rxjs/operators';

interface marker {
	lat				: number;
	lng				: number;
	label?			: string;
	draggable		: boolean;
}

interface Calendar {
	value			: any,
	date			: string,
	last			: string
};
@Component({
    styleUrls		: ['./transportsCalendars.component.scss'],
	templateUrl		: './transportsCalendars.component.html',	
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})

export class TransportsCalendarsComponent implements OnInit
{
	@ViewChild('calendarVehiclesHeader') 	calendarVehiclesHeader;
	@ViewChild('calendarDriversHeader') 	calendarDriversHeader;

	pageInfo			: any 		= { fullScreen: false };
	zoom				: number 	= 10;
	lat					: number	= 39.638464;
	lng					: number	= 3.003831;

	markers				: marker[] = [
		{ lat: 39.575505, lng: 2.652774, label: 'Headquarters', draggable: true 	}
	];

	calendarView		: string	= 'drivers';
	calendar			: Calendar 	= <Calendar>{ last: ''};
	calendarFullView	: boolean	= false;
	entities			: any[]		= [ "groups", "fleet", "drivers" ];
	groups      		: any 		= { cols: [], filters: {} };
	fleet				: any 		= { cols: [], filters: {} };
	drivers				: any 		= { cols: [], filters: {} };
	arrivals			: any		= {};
	departures			: any		= {};
	transportsCalendar	: any 		= { rows: [{ empty: true, items: [] }]};
	driversCalendar		: any 		= { rows: [{ empty: true, items: [] }]};
	companies			: any		= { items: [], selected: [] };

	draggedItem			: any;
	chartData			: any;
	linkable			: any 		= {};
	showCalendar		: any 		= true;
	viewMode			: any		= {
		label	: 'Calendar',
		type	: 'single',
		items	: [
			{ label: 'EDITABLE', 	value: 'editable'	},
			{ label: 'BIG', 		value: 'big'		},
			{ label: 'FULL', 		value: 'full'		}			
		],
		selected: [ "editable" ]
	};

    constructor(
		private commons			: CommonsService,
        private entityService	: EntityService,
		@Inject(DOCUMENT) private document: any
    ) {
		this.init();
        this.loadEntities();
    }

	ngOnInit()
	{
		this.pageInfo.elem 		= document.documentElement;
		this.pageInfo.height 	= {
			container		: "70vh",
			drop			: "9vh",
			windowed		: {
				small		: "45vh",
				big			: "64vh",
				bigChild	: "62vh",
				item		: "20vh",
			},
			fullScreen		: {
				big			: "68vh",
				bigChild	: "66vh",
				full		: "78vh",
				fullChild	: "76vh",
			},
			width			: {
				driverItem	: "200px"
			},
			assigned		: "20vh",
			chart			: "70vh",
			chart3			: "24vh"
		};
		this.pageInfo.width	= {
			driverItem		: "250px"
		};
		this.pageInfo.colors	= {
			empty			: "coral",
			active			: "rgba(255,255,255,1)",
			linked			: "lightblue"
		};
		this.pageInfo.export	= {
			items			: [
				{ label: 'CSV', icon: 'pi pi-refresh', command: () => { this.export('csv') }},
				{ label: 'PDF', icon: 'pi pi-refresh', command: () => { this.export('pdf') }},
			]
		}
		// this.toggleFullScreen(); // Set fullScreen by default
	}

	export($format){
		this.commons.generateToast("Export","Generating "+$format+" for "+this.calendarView,"success");
	}

	async init()
	{
		this.pageInfo.filters 		= filters;
		this.arrivals.container		= null;
		this.departures.container	= null;
		this.arrivals.transports 	= [];
		this.departures.transports	= [];

		this.companies				= this.commons.translateRecursively({	items	: [
																				{ label: "_ALL_ITEMS", 				value: 0 	},
																				{ label: "Transportes Transunion",	value: 1 	},
																				{ label: "Transportes Comas", 		value: 2 	}
																			],
																			selected: "all"
																		});

		this.generateMenuCols('groups');		
	}

	async loadEntities() {
		// await this.load('groups');
		await this.load('drivers');
		await this.load('fleet');
		await this.mockCalendarCall('20190505');

		// Chart.js ( https://www.chartjs.org/docs/latest/ )
		this.chartData = {
            labels: ['1st','2nd','3rd','4th','5th','6th','7th','8th','9th','10th','11th','12th','13th','14th'],
            datasets: [
                {
                    label						: '1st Solution',
                    data						: [65,59,80,81,56,55,54,78,55,40,45,32,67,85],
                    fill						: false,
				    lineTension					: 0.1,
				    backgroundColor				: "blue",
				    borderColor					: "blue",
				    borderCapStyle				: 'butt',
				    borderDash					: [],
				    borderDashOffset			: 0.0,
				    borderJoinStyle				: 'miter',
		            _pointBorderColor			: "rgba(75,192,192,1)",
		            _pointBackgroundColor		: "#fff",
		            _pointBorderWidth			: 1,
		            _pointHoverRadius			: 5,
		            _pointHoverBackgroundColor	: "rgba(75,192,192,1)",
		            _pointHoverBorderColor		: "rgba(220,220,220,1)",
		            _pointHoverBorderWidth		: 2,
		            _pointRadius					: 1,
		            _pointHitRadius				: 10,
		            yAxisID						: "y-axis-0",
                },
                {
                    label						: '2nd Solution',
                    data						: [28,48,40,19,86,27,69,44,78,95,25,22,76,56],
                    fill						: false,
					borderColor					: 'red',
					backgroundColor				: "red",
		            lineTension					: 0.1,
				    borderJoinStyle				: 'miter',
		            _pointBorderColor			: "red",
		            _pointBackgroundColor		: "#fff",
		            _pointBorderWidth			: 1,
		            _pointHoverRadius			: 5,
		            _pointHoverBackgroundColor	: "red",
		            _pointHoverBorderColor		: "red",
		            _pointHoverBorderWidth		: 2,
				},
				{
                    label						: '3rd Solution',
                    data						: [38,75,28,48,40,27,96,25,35,22,76,56,34,52],
                    fill						: false,
					borderColor					: 'green',
					backgroundColor				: "green",
					lineTension					: 0.1,
				    borderJoinStyle				: 'miter'
				},
				{
                    label						: '4th Solution',
                    data						: [48,40,19,86,27,34,56,92,58,28,34,87,76,56],
                    fill						: false,
					borderColor					: 'orange',
					backgroundColor				: 'orange',
					lineTension					: 0.1,
				    borderJoinStyle				: 'miter'
                }
            ]
        };
	}

	generateTransportFromGroup(type){}
	clearContainer(type){}

	checkLink(item)
	{
		switch(item.status){
			case "linked"	:
				// Break link
				item.status 		= "open";
				item.color			= undefined;
				item.pair.status 	= "open";
				item.pair.color		= undefined;
				break;

			case "pending"	:
				if(item===this.linkable.source){
					// Remove linkables
					this.linkable.source.status = "open";
					this.linkable.source.color	= undefined;
					this.linkable.source		= undefined;
				}
				// If I'm not source I have to be target and clear it anyway
				if(undefined!==this.linkable.target) {
					this.linkable.target.status = "open";
					this.linkable.target.color	= undefined;
					this.linkable.target		= undefined;
				}
				break;

			case "open"		:
			default			:
				if(undefined===this.linkable.source){
					// I am Source element
					this.linkable.source 		= item;
					item.status			 		= "pending";
					item.color					= "cadetblue";
				} else {
					// I am target element
					// 1. Check if source is my type arrival or departure
					if(this.linkable.source.type==item.type){
						this.commons.generateToast("Error","Select one arrival and one departure","error");
						return false;
					}
					// 2. Check if we are in the same row
					if(this.linkable.source.row!==item.row){
						this.commons.generateToast("Error","Items must be in the same row","error");
						return false;
					}
					// 3. Check if items are consecutives
					if(Math.abs(item.index-this.linkable.source.index)>1){
						this.commons.generateToast("Error","Items must be consecutive","error");
						return false;
					}
					this.linkable.target 		= item;
					this.link();
				}
				break;
		}
	}

	link(){
		this.linkable.source.status = "linked";
		this.linkable.source.color	= "tomato";
		// this.linkable.source.color	= "crimson";
		this.linkable.source.pair	= this.linkable.target;

		this.linkable.target.status	= "linked";
		this.linkable.target.color	= "tomato";
		// this.linkable.target.color	= "crimson";
		this.linkable.target.pair	= this.linkable.source;

		this.linkable.source		= undefined;
		this.linkable.target		= undefined;
	}

	remove(type,info={}){
		switch(type){
			case 'calendarEmptyRows':
				this.transportsCalendar.rows = this.transportsCalendar.rows.filter(item=>item.empty || item.items.length>0);
				break;

			case 'calendarVehicle':
				// Mark fleet vehicles as assigned
				this.fleet.draggableSet.forEach( type => ( type.vehicles || [] ).filter(current=>current.plate==info["row"].plate)
																				.forEach(current=>current.status="unassigned"));
				info["row"].plate 			= undefined;
				break;

			case 'calendarTransport':

				// Check
				// 1. Linked item could not be removed
				if(info["item"].status=="linked"){
					this.commons.generateToast("Error","Linked item could not be removed","error");
					return false;
				}

				// Remove from calendar row
				info["row"].items = info["row"].items.filter(item=>item.item!=info["item"].item);

				// Remove empty rows
				this.remove('calendarEmptyRows');

				// Mark as unassigned
				this[info["item"].type=="arrival"?"arrivals":"departures"]
					.draggableSet
					.forEach(group=>{
						group.transports.forEach(item=>{
							if(item.item == info["item"].item){
								item.assigned = false;
							}
						});
					});
				break;
		}
	}

	getGroupTransports(item){
		let pax = item.pax;
		return item.transports.map(transport=>{
			transport.type		= item.type;
			transport.zone 		= item.zone;
			transport.firstTime	= item.firstTime;
			transport.lastTime	= item.lastTime;
			transport.pax		= pax>transport.seats?transport.seats:pax;
			pax				   	= pax-transport.seats>0?pax-transport.seats:0;
			return transport;
		});
	}

	getTransportsCalendar(){
		this.transportsCalendar.rows = this.transportsCalendar.rows.map(row=>{
			let offset 	= 0;
			//let factor	= 3.333333;
			let factor	= 1;
			row.items 	= row.items.map(item=>{
				item.init 	= item.firstTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.end 	= item.lastTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.offset = ( item.init - offset ) * factor;
				item.width	= ( item.end  - item.init 	) * factor;
				offset		= item.end;
				return item;
			});
			return row;
		});
		return this.transportsCalendar.rows;
	}

	getDriversCalendar()
	{
		let driverSet				= this["drivers"].draggableSet;
		let driverRows 				= {};
		this.driversCalendar.rows 	= [];

		// Generate driver rows
		this.transportsCalendar.rows.forEach(row=>{
			row.items
				.filter(item=>item.driver!==undefined)
				.forEach(item=>{
					if(undefined===driverRows[item.driver.id]){ driverRows[item.driver.id] = []; }
					driverRows[item.driver.id].push(item);
				});
		});

		// Generate array from driver keys

		Object.keys(driverRows).forEach(driver=>{
			let info = driverSet.filter(current=>current.id==driver);
			this.driversCalendar.rows.push({
				driver 		: info[0],				
				items		: driverRows[driver]
			});
		});

		// Filter each driver rows by time
		this.driversCalendar.rows.forEach(row=>{
			row.items = row.items.sort((a,b)=>a.firstTime>b.firstTime?1:-1);
		})

		this.driversCalendar.rows = this.driversCalendar.rows.map(row=>{
			let offset 	= 0;
			let factor	= 3.333333;
			row.items 	= row.items.map(item=>{
				item.init 	= item.firstTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.end 	= item.lastTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
				item.offset = ( item.init - offset ) * factor;
				item.width	= ( item.end  - item.init 	) * factor;
				offset		= item.end;
				return item;
			});
			return row;
		});
		return this.driversCalendar.rows;
	}

// DRAG AND DROP SUPPORT ----------------------------------------------------------------

	dragStart(event,item,extra={})	{
		if( undefined !== item.qty && item.qty<=0 ){ return false }
		this.draggedItem 		= item;
		this.draggedItem.source	= extra["source"]?extra["source"]:undefined;
	}

	dragEnd(event) 			{}

	scroll($type,$event){
		switch($type){
			case "calendarVehicles"	:	
				this.calendarVehiclesHeader.nativeElement.scrollTop = $event.srcElement.scrollTop; 
				// this.calendarTimesHeader.nativeElement.scrollLeft 	= $event.srcElement.scrollLeft; 
				break;
			case "calendarDrivers"	:	
				// this.calendarDriversHeader.nativeElement.scrollTop 	= $event.srcElement.scrollTop; 
				break;
		}
	}

	async drop(type,event,extra={})
	{
		console.group("Drop");
		console.log("Event",type,event);
		console.log("DraggedItem",this.draggedItem);
		console.groupEnd();

		let me		= this;
		let item 	= {};
		let row;

		if(this.draggedItem) {
			switch(type){

				case "driver":
					item			= extra["item"];
					item["driver"]	= this.draggedItem.id;
					break;

				case "calendar":

					// Linked items could not be moved
					switch(this.draggedItem.status){
						case "linked":
							await this.commons.generateToast("Error","Linked items could not be moved","error");
							return false;
					}

					// Check if we can drop
					// 1. type calendarItem or !calendarItem && !assigned
					switch(this.draggedItem.source){
						case "calendarItem"	: break;
						default				: if(this.draggedItem.assigned){
							await this.commons.generateToast('_ERROR','_TRANSPORT_ALREADY_ASSIGNED','error');
							return false;
						}
					}

					// Get current row or create new one
					row	= extra["row"].empty?{ empty:true, items:[] }:extra["row"];

					// 2. Check if transport type match
					if(undefined!==row.seats && row.seats<this.draggedItem.pax){
						await this.commons.generateToast('_ERROR','_TARGET_TRANSPORT_NOT_SEATS','error');
						return false;
					}

					// 3. If element is already in row return
					if( row.items.filter(item=>item.item==me.draggedItem.item).length>0 ){
						await this.commons.generateToast('_ERROR','_TRANSPORT_ALREADY_IN_CALENDAR','error');
						return false;
					}

					// 4. Is there a GAP ?
					let gap = this.findCalendarGap(this.draggedItem,row);

					if(gap==-1){
						await this.commons.generateToast('_ERROR','_NO_GAP_FOUND','error');
						return false;
					}

					// MOVABLE ITEM -----------------------------------------------------

					// First remove item from previous row
					this.transportsCalendar.rows.forEach(row=>{ row.items = row.items.filter(item=>item.item!=me.draggedItem.item); return row;});

					// Add item into vehicles
					let firstHour		= Math.trunc(this.draggedItem.init/60);
					let firstMin 		= this.draggedItem.init%60;
					let lastHour		= Math.trunc(this.draggedItem.end/60);
					let lastMin 		= this.draggedItem.end%60;
					let firstTime		= (firstHour<10?"0":"")+firstHour+":"+(firstMin<10?"0":"")+firstMin;
					let lastTime		= (lastHour <10?"0":"")+lastHour +":"+(lastMin <10?"0":"")+lastMin;
					
					// Add item to current row
					// at right position ( from gap )
					row.items.splice(gap,0,{
						row			: row,
						item		: this.draggedItem.item,
						name		: this.draggedItem.name,
						zone		: this.draggedItem.zone,
						type		: this.draggedItem.type,
						pax			: this.draggedItem.pax,
						seats		: this.draggedItem.seats,
						// firstTime	: this.draggedItem.firstTime,
						// lastTime	: this.draggedItem.lastTime
						firstTime	: firstTime,
						lastTime	: lastTime
					});

					// Set index into row
					row.items.forEach((item,index)=>{ item.index=index });

					row.name					= this.draggedItem.name;
					row.seats 					= undefined!==row.seats?row.seats:this.draggedItem.seats;	// Set row pax
					this.draggedItem.assigned 	= true;														// Mark element as assigned

					if(row.empty){
						row.empty	= undefined; 							// Unmark as empty row
						this.transportsCalendar.rows.splice(1,0,row);		// Set row as 2nd element
					}

					// Remove empty rows
					this.remove('calendarEmptyRows');
					break;

				case "vehicles":

					row			= extra["row"];

					if(row.empty){ return false; }

					// Check if row has already something assigned
					if( undefined===row.seats ){
						await this.commons.generateToast('Error','You need to add at least one item to calendar','error');
						return false;
					}

					// Check if transport seats and vehicle seats match
					if( row.seats>0 && row.seats!==this.draggedItem.seats ){
						await this.commons.generateToast('Error','Transport type is not the same','error');
						return false;
					}

					if(this.transportsCalendar.rows.filter(item=>item.plate==this.draggedItem.plate).length>0){
						await this.commons.generateToast('Error','Plate already assigned','error');
						return false;
					}

					// Restore old vehicle status to unassigned
					this.fleet.draggableSet.forEach( type => ( type.vehicles || [] ).filter(current=>current.plate==row.plate)
																					.forEach(current=>current.status="unassigned"));

					row.plate					= this.draggedItem.plate;
					row.seats					= this.draggedItem.seats;
					row.alias					= this.draggedItem.name;
					
					// Set current vehicle status as assigned
					this.draggedItem.status 	= "assigned";

					break;

				case "arrivalGroup":
					// alert("Arrival Group");
					this.arrivals.container			= this.draggedItem;
					// this.arrivals.draggableSet 		= this.arrivals.draggableSet.filter((val,i) => i!=0);
					break;

				case "departureGroup":
					// alert("Departure Group");
					this.departures.container		= this.draggedItem;
					// this.departures.draggableSet	= this.arrivals.draggableSet.filter((val,i) => i!=0);
					break;

				case "arrivalTransport":
					// alert("Arrival Transport");

					// Check DROP
					if(this.arrivals.container===null	){ await this.commons.generateToast('Error','Set an arrival group first',	'error'); return false;	}
					if(this.draggedItem.qty <= 0		){ await this.commons.generateToast('Error','Drop not allowed',				'error'); return false;	}

					// Clone item
					item = {
						pax		: this.draggedItem.pax,
						qty		: this.draggedItem.qty,
						name	: this.draggedItem.name
					};

					// Check Link
					// this.checkLinks("arrivals");

					// Assign to arrivals transports
					this.arrivals.transports		= [ ...this.arrivals.transports, item ];

					// Discount vehicle
					this.fleet.draggableSet 		= this.fleet.draggableSet.map(item=>{
														if( item.id === this.draggedItem.id ){
															item.qty -= item.qty>0?1:0;
														};
														if( item.qty <= 0){ item.qty = 0; item.color = this.pageInfo.colors.empty; }
														return item;
													});
					// Draw item connectors
					// this.drawConnectors();
					//this.drawConnector(1,1);

					break;

				case "departureTransport":
					// alert("Departure Transport");

					// Check DROP
					if(this.departures.container===null	){ await this.commons.generateToast('Error','Set a departure group first',	'error'); return false;	}
					if(this.draggedItem.qty <= 0		){ await this.commons.generateToast('Error','Drop not allowed',				'error'); return false;	}

					// Clone item
					item = {
						pax		: this.draggedItem.pax,
						qty		: this.draggedItem.qty,
						name	: this.draggedItem.name
					};

					// Check Link
					// this.checkLinks("departures");

					// Assign to departures transports
					this.departures.transports		= [ ...this.departures.transports, item ];

					// Discount vehicle
					this.fleet.draggableSet 		= this.fleet.draggableSet.map(item=>{
														if( item.id === this.draggedItem.id ){
															item.qty -= item.qty>0?1:0;
														};
														if( item.qty <= 0){ item.qty = 0; item.color = this.pageInfo.colors.empty; }
														return item;
													});
					// Draw item connectors
					//this.drawConnectors();
					//this.drawConnector(0,0);

					break;
			}
		}
		this.draggedItem = null;
	}

	findCalendarGap(item,row)
	{
		// Set if first item
		if(row.items.length==0){ return 0; }

		// let init 	= item.firstTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));
		// let end 	= item.lastTime.split(':').reduce((acc,v)=>parseInt(acc)*60+parseInt(v));

		// Check boundaries
		if(item.end  < row.items[0].init){ return 0; }
		if(item.init > row.items[row.items.length-1].end){ return row.items.length; }

		// Find item to compare with
		let i = 1; while( i < row.items.length && item.end >= row.items[i].init ){ i++; }

		if( i >= row.items.length ){ return -1; }		// Reached last item
		return ( item.init > row.items[i-1].end )?i:-1;		// Inside gap item[i-1] and item[i]
	}

	async calendarChange(){
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];
		console.log("CALENDAR",this.calendar);
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last = this.calendar.date;
			await this.load('groups');
		}
	}

	async mockCalendarCall($date){
		this.calendar.date = $date;
		await this.load('groups');
	}

	getCalendarTimes(){
		return [ ...Array.from(Array(24).keys()).map(hour=>(hour<10?'0':'')+hour+":00"), ...Array.from(Array(4).keys()).map(hour=>'0'+hour+":00") ];
	}

	getActiveFleet(){
		return undefined===this.fleet.draggableSet ? [] : this.fleet.draggableSet.filter(item=>item.qty>0);
	}

	getItemFleetVehicles(item){
		// console.log("Vehicles for item",item);
		return item.open ? item.vehicles: [];
	}

	setViewMode(selected){
		switch(selected){
			case "editable"		: this.calendarFullView   = false; 	break;
			case "big"			: this.calendarFullView   = true; 	break;
			case "full"			: this.calendarFullView   = true; 	break;
		}
	}

	toggle($type,$item=null){
		switch($type){
			case 'itemFleet'	:	$item.open				= $item.open==1?0:1;						break;
			case 'itemCalendar'	:	$item.open				= $item.open==1?0:1;						break;
			case 'fullscreen'	:	this.toggleFullScreen(); 											break;
			// case 'fullcalendar'	:	this.calendarFullView   = this.calendarFullView==false?true:false; 	break;
			case 'group'		:	$item.open				= $item.open==1?0:1;						break;
			case 'fleet'		:
				this.fleet.open			= this.fleet.open==1?0:1;
				this.fleet.draggableSet = this.fleet.draggableSet.map(item=>{ item.open = this.fleet.open; return item; });
				break;
			case 'drivers'		:	this.drivers.open		= this.drivers.open==1?0:1;					break;
		}
	}

	validate($type,$item){
		switch($type){
			case 'upgradeItemSellRequest':		$item.open = false; break;
			case 'upgradeItemSellProcess':		$item.open = false; this.commons.generateToast('Ticket','Your selling has been emmited','info'); break;
			case 'upgradeItemSellCancel':		$item.open = false; break;	
		}
	}

// CALENDAR			---------------------------------------------------------------------

	generateCalendarFromData(items)
	{
		let vehicles = [];

		items.forEach(item=>{
			item.init	= "0";
			item.end	= "0";
			item.transports
				.filter(transport=>undefined!==transport.vehicle && undefined!==transport.vehicle.id)
				.forEach(transport=>{
					// Create vehicle list by id
					if(undefined==vehicles[transport.vehicle.id]){
						transport.vehicle.seats = transport.seats;
						vehicles[transport.vehicle.id] = {
							vehicle	: transport.vehicle,
							items	: []
						};
					}
					// Add item into vehicles
					let firstHour		= Math.trunc(transport.departure/60);
					let firstMin 		= transport.departure%60;
					let lastHour		= Math.trunc(transport.delivery/60);
					let lastMin 		= transport.delivery%60;
					let firstTime		= (firstHour<10?"0":"")+firstHour+":"+(firstMin<10?"0":"")+firstMin;
					let lastTime		= (lastHour <10?"0":"")+lastHour +":"+(lastMin <10?"0":"")+lastMin;

					vehicles[transport.vehicle.id].items.push({
						item		: transport.item,
						name		: item.name,
						zone		: item.zone,
						type		: item.type,
						pax			: transport.pax,
						seats		: transport.seats,
						// firstTime: item.firstTime,
						// lastTime	: item.lastTime,
						// firstTime: transport.departure>=1440?"23:59":(firstHour<10?"0":"")+firstHour+":"+(firstMin<10?"0":"")+firstMin,
						// lastTime	: transport.delivery >=1440?"23:59":(lastHour <10?"0":"")+lastHour +":"+(lastMin <10?"0":"")+lastMin,
						firstTime	: firstTime,
						lastTime	: lastTime,
						driver		: transport.driver,
						link		: transport.link
					});
					transport.assigned 	= true;
					item.init			= item.init>firstTime?item.init:firstTime; 
					item.end			= item.init>lastTime?item.end:lastTime; 
				})
		});

		// Get all items by vehicle type and generate row items
		Object.keys(vehicles).forEach(vehicle=>{
			
			// Mark fleet vehicles as assigned
			if(undefined!==this.fleet.draggableSet){
				this.fleet.draggableSet.forEach( type => ( type.vehicles || [] ).filter(current=>current.id==vehicle)
																				.forEach(current=>current.status="assigned"));
			}

			let row	= {
				name	: vehicles[vehicle].vehicle.name,
				alias	: vehicles[vehicle].vehicle.name,
				seats	: vehicles[vehicle].vehicle.seats,
				plate	: vehicles[vehicle].vehicle.plate,
				items	: []
			};

			// Set row items
			row.items	= vehicles[vehicle]
							.items
							.sort((a,b)=>a.firstTime>b.firstTime?1:-1)	// Order by time
							.map((transport,index)=>{
								transport.row 			= row;
								transport.index			= index;								
								if(undefined!==transport.link){
									this.linkable.source 	= transport;
									this.linkable.target	= vehicles[vehicle].items.filter(item=>item.id==transport.link.id);
									this.link();
								}
								return transport;
							});

			this.transportsCalendar.rows.splice(1,0,row);		// Set row as 2nd element
		});

	}

// LOAD ENTITIES 	---------------------------------------------------------------------

	async load($entity) {
        switch ($entity) {
			case 'groups':
                this[$entity].spinner		= true;
				// this[$entity].data			= [];
				await this.entityService.loadEntity($entity,{ date: this.calendar.date, solution: 1, solutionTransport: 1, checkins: false, transports: true, vehicles: true, drivers: true });
				//await this.entityService.loadEntity($entity,{ date: this.calendar.date, checkins: false, transports: true, vehicles: false, drivers: false });
				this[$entity].data        	= this.entityService.get($entity);
				this[$entity].spinner		= false;

				this.transportsCalendar		= { rows: [{ empty: true, items: [] }]};

				// Clear entities
				if(undefined!==this.fleet.draggableSet){
					this.fleet.draggableSet.forEach(type=>( type.vehicles || [] ).forEach(current=>current.status="unassigned"));
				}
				
				// Check solutions
				if(this[$entity].data.length==0){
					this.commons.generateToast('Load solutions','No solution found','error');
					this["arrivals"]	 	= {};
					this["departures"]		= {};
					return false;
				}
				
				if(this.showCalendar){ this.generateCalendarFromData(this[$entity].data[0].items); }
				break;

			case 'fleet':
                // this[$entity].data = Array.from(Array(100).keys()).map((item, index) => ({id: index} ));
				this[$entity].spinner		= true;
				this[$entity].data			= [];
				await this.entityService.loadEntity($entity);
				this[$entity].data        	= this.entityService.get($entity);
				this[$entity].spinner		= false;

				this[$entity].draggableSet  = this[$entity].data.sort((a,b)=>(a.pax>b.pax)?-1:1);
				// this[$entity].draggableSet  = this[$entity].data.filter((item,index)=>index<15);
				this[$entity].draggableSet	= this[$entity].draggableSet.map(item=>{ item.color = ( item.qty > 0 )?this.pageInfo.colors.active:this.pageInfo.colors.empty; return item; });

				this[$entity].assigned		= [];

				// console.log("DATA",this[$entity].data);
				break;

			case 'drivers':
                // this[$entity].data = Array.from(Array(100).keys()).map((item, index) => ({id: index} ));
				this[$entity].spinner		= true;
				this[$entity].data			= [];
				await this.entityService.loadEntity($entity);
				this[$entity].data        	= this.entityService.get($entity);
				this[$entity].spinner		= false;

				this[$entity].draggableSet  = this[$entity].data;
				// this[$entity].draggableSet  = this[$entity].data.filter((item,index)=>index<15);
				this[$entity].newSet		= [];

				// console.log("DATA",this[$entity].data);
				break;				
		}
		this.filterEntity($entity);
	}

	generateMenuCols($entity) {
		switch($entity){
			case "groups"	: this[$entity].cols = groupsCols; 		break;
			case "fleet"	: this[$entity].cols = fleetCols; 		break;
			case "drivers"	: this[$entity].cols = driversCols;		break;
		}
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
	}

// FILTER SUPPORT -----------------------------------------------------------------------

	initFilters(){
		this.entities.forEach(entity=>{
			console.log("Init Filter for ",entity);
			this[entity].filters = this[entity].filters || [];
			this[entity].filters.forEach(filter=>this.checkFilter(filter));
		});
	}

	checkFilter(filter){
		console.log("Checking Entity",filter.entity,"Filter",filter.name);
		switch(filter.type){
			case "multiple":
				this.checkMultipleFilter(filter);
				break;
			default:
				this.checkSimpleFilter(filter);
				break;
		}
	}

    checkMultipleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}

		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};

		this.filterEntity(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}
		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterEntity(filter.entity);
	}

	getFilteredEntity($entity)
	{
		if(undefined===this[$entity].draggableSet){  return []; }
		this[$entity].draggableSet.forEach(item=>{
			item.color 	= item.transports.every(transport=>transport.assigned)?'red':'green';
			item.transports.forEach(transport=>{
				transport.init	= transport.departure;
				transport.end	= transport.delivery;
			});
		});
		return this[$entity].draggableSet;
	}

	filterEntity($entity){
		let data 					= this[$entity].data			|| [];
		let filters 				= this[$entity].activeFilters 	|| {};
		this[$entity].filteredData	= this.filterData(data,filters);
		this[$entity].count 		= this[$entity].filteredData ? this[$entity].filteredData.length : 0;

		switch($entity){
			case "groups":
				// this["arrivals"].draggableSet	= this[$entity].data[0].items.filter(item=>item.type=="arrival");
				// this["departures"].draggableSet	= this[$entity].data[0].items.filter(item=>item.type=="departure");
				this["arrivals"].draggableSet	= this[$entity].filteredData[0].items.filter(item=>item.type=="arrival");
				this["departures"].draggableSet	= this[$entity].filteredData[0].items.filter(item=>item.type=="departure");
				break;
		}
	}

	filterData(data,filters){

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let me 			= this;
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "zones":
						console.log("Filtering zones");
						['arrivals','departures'].forEach(direction=>{
							me[direction].draggableSet = me[direction].draggableSet.filter(group=>{
								selected.reduce((result,filter)=>result || group.zone===filter,false);
							});
						});
				}
			});
		}
		return data;
	}

// FULLSCREEN SUPPORT -------------------------------------------------------------------

	toggleFullScreen(){
		// this.pageInfo.fullScreen = this.pageInfo.fullScreen?false:true;
		// this.pageInfo.fullScreen = this.openFullscreen() : this.closeFullscreen();
		this.pageInfo.fullScreen = this.pageInfo.fullScreen?!this.closeFullscreen():this.openFullscreen();
	}

	openFullscreen() {
		try {
			if 		( this.pageInfo.elem.requestFullscreen		) { this.pageInfo.elem.requestFullscreen();											}
			else if ( this.pageInfo.elem.mozRequestFullScreen	) {	this.pageInfo.elem.mozRequestFullScreen();		/* Firefox */					}
			else if ( this.pageInfo.elem.webkitRequestFullscreen) {	this.pageInfo.elem.webkitRequestFullscreen();	/* Chrome, Safari and Opera */  }
			else if ( this.pageInfo.elem.msRequestFullscreen	) {	this.pageInfo.elem.msRequestFullscreen(); 		/* IE/Edge */ 					}
			return true;
		} catch(e){ return false; }		
	}

	closeFullscreen() {
		try {
			if 		( this.document.exitFullscreen		) {	this.document.exitFullscreen();											}
			else if ( this.document.mozCancelFullScreen	) {	this.document.mozCancelFullScreen();	/* Firefox */ 					}
			else if ( this.document.webkitExitFullscreen) {	this.document.webkitExitFullscreen();	/* Chrome, Safari and Opera */ 	}
			else if ( this.document.msExitFullscreen	) {	this.document.msExitFullscreen();		/* IE/Edge */					}
			return true;
		} catch(e){ return false; }		
	}

// LINK ELEMENTS ------------------------------------------------------------------------

	createSVG(){
		let svg;
		if (null === document.getElementById("svg-canvas")) {
			svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

			svg.setAttribute('id', 		'svg-canvas');
			// svg.setAttribute('style', 	'position:absolute;top:0px;left:0px;');
			svg.setAttribute('style', 	'position:absolute;top:0px;left:0px;');
			svg.setAttribute('width', 	document.body.clientWidth);
			svg.setAttribute('height', 	document.body.clientHeight);
			svg.setAttribute('class',	'unselectable');

			svg.setAttributeNS(	"http://www.w3.org/2000/xmlns/",
								"xmlns:xlink",
								"http://www.w3.org/1999/xlink"
								);

			  document.body.appendChild(svg);
		} else {
			svg = document.getElementById("svg-canvas");
		}
		return svg;
	}

	findAbsolutePosition(htmlElement) {
		let x = htmlElement.offsetLeft;
		let y = htmlElement.offsetTop;
		let el;
		for (x=0, y=0, el=htmlElement; el != null; el = el.offsetParent) {
			x += el.offsetLeft;
			y += el.offsetTop;
		}
		return { "x": x, "y": y };
	}

	drawCircle(x, y, radius, color)
	{
		var svg 	= this.createSVG();
		var shape 	= document.createElementNS("http://www.w3.org/2000/svg", "circle");

		shape.setAttributeNS(null, "cx", 	x		);
		shape.setAttributeNS(null, "cy", 	y		);
		shape.setAttributeNS(null, "r",  	radius	);
		shape.setAttributeNS(null, "fill", 	color	);

		svg.appendChild(shape);
	}

	drawCurvedLine(x1, y1, x2, y2, color, tension)
	{
		var svg 	= this.createSVG();
		var shape 	= document.createElementNS("http://www.w3.org/2000/svg", "path");

		var delta 	= (x2-x1)*tension;
		var hx1		= x1+delta;
		var hy1		= y1;
		var hx2		= x2-delta;
		var hy2		= y2;

		var path 	= "M "  + x1 	+ " " + y1 +
						 " C " + hx1 	+ " " + hy1 +
					  " "  	+ hx2 	+ " " + hy2 +
					  " " 	+ x2 	+ " " + y2;

		shape.setAttributeNS(null, "d", 			path	);
		shape.setAttributeNS(null, "fill", 			"none"	);
		shape.setAttributeNS(null, "stroke",		color	);
		shape.setAttributeNS(null, "stroke-width", 	"3"		);

		svg.appendChild(shape);
	}

	connectDivs(leftId, rightId, color, tension, offset=20) {
		var left		= document.getElementById(leftId);
		var right 		= document.getElementById(rightId);

		if(null===left || null===right){ return false; }

		var leftPos 	= this.findAbsolutePosition(left);
		var x1 			= leftPos.x - offset;
		var y1 			= leftPos.y;
		x1 				+= left.offsetWidth;
		y1 				+= (left.offsetHeight / 2);

		var rightPos 	= this.findAbsolutePosition(right);
		var x2 			= rightPos.x + offset;
		var y2 			= rightPos.y;
		y2 				+= (right.offsetHeight / 2);

		var width		= x2-x1;
		var height 		= y2-y1;

		// this.drawCircle(x1, y1, 10, color);
		// this.drawCircle(x2, y2, 10, color);
		this.drawCurvedLine(x1, y1, x2, y2, color, tension);
	}

	drawConnectors(color="rgba(0,0,0,0.7)",offset=20){
		this.arrivals.transports.forEach((arrival,i)=>{
			this.departures.transports.forEach((departure,j)=>{
				this.drawConnector(i,j,color,1,offset);
			});
		});
	}

	drawConnector(i,j,color="rgba(0,0,0,0.7)",tension=1,offset=30){
		console.log("Connecting",i,j);
		this.connectDivs("arrival_item_"+(i), "departure_item_"+(j), color, tension, offset);
	}

	/*
	setLink(type,item,i)
	{
		console.group("setLink");
		switch(item.linked){
			case "pending"	: item.linked = "open";	break;
			case "open"		:
			default			:
				this[type].pending		= i;
				this[type].transports 	= this[type].transports.map((current,index) => {
					if(index===i){
						current.linked = "pending";
					} else {
						switch(current.linked){
							case "pending": current.linked = "open"; break;
						}
					}
					return current;
				});
				break;
		}
		this.checkLinks(type);
		console.groupEnd();
	}

	checkLinks(type){
		this.checkConnectors();
		this[type].transports.forEach( item => { this.checkLink(type,item); });
	}

	checkLink(type,item)
	{
		switch(item.linked){
			case "pending"	: item.linkedColor 	= this.pageInfo.colors.linked;	break;
			case "linked"	: item.linkedColor	= this.pageInfo.colors.linked;	break;
			case "open"		:
			default			: item.linkedColor	= "white";	break;
		}
	}

	checkConnectors()
	{
		console.group("checkConnectors");
		console.log("Arrivals",this["arrivals"].pending,"Departure",this["departures"].pending);
		if(	undefined!==this["arrivals"  ].pending &&
			undefined!==this["departures"].pending
		){
			this.addLinkedTransport();
		}
		console.groupEnd();
	}

	addLinkedTransport()
	{
		let arrival 		= this["arrivals"  ].transports[this["arrivals"  ].pending];
		let departure 		= this["departures"].transports[this["departures"].pending];

		// Link items
		arrival.linked 		= "linked";
		departure.linked 	= "linked";

		// Draw line between
		//this.drawConnector(this.arrivals.pending,this.departures.pending);

		// Add items to linked transport list
		this.fleet.assigned.push({
			arrivalPax			: arrival.pax,
			arrivalZone			: this.arrivals.container.zone,
			arrivalFirstTime	: this.arrivals.container.firstTime,
			arrivalLastTime		: this.arrivals.container.lastTime,
			departurePax		: departure.pax,
			departureZone		: this.departures.container.zone,
			departureFirstTime	: this.departures.container.firstTime,
			departureLastTime	: this.departures.container.lastTime,
		});

		//this["arrivals"].transports[this["arrivals"].pending]);

		// Remove items
		console.log("Pre arrivals",		this["arrivals"  ].transports);
		console.log("Pre departures",	this["departures"].transports);

		this["arrivals"  ].transports 	= this["arrivals"  ].transports.filter((item,index)=>index!==this["arrivals"  ].pending);
		this["departures"].transports 	= this["departures"].transports.filter((item,index)=>index!==this["departures"].pending);

		console.log("Post arrivals",	this["arrivals"  ].transports);
		console.log("Post departures",	this["departures"].transports);

		// Clear pendings
		this["arrivals"	 ].pending 		= undefined;
		this["departures"].pending		= undefined;
	}
	*/
}