export let wizardItems = [	
	{
		id			: 1,
		title		: "_WIZARD_SIGHT_STEP_1_TITLE",
		description	: "_WIZARD_SIGHT_STEP_1_DESC"
	},
	{
		id			: 2,
		title		: "_WIZARD_SIGHT_STEP_2_TITLE",
		description	: "_WIZARD_SIGHT_STEP_2_DESC"
	},
	{
		id			: 3,
		title		: "_WIZARD_SIGHT_STEP_3_TITLE",
		description	: "_WIZARD_SIGHT_STEP_3_DESC"
	}
];