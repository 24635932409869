export const CS_TF = [
    {
        "Id": 201,
        "Name": "Aeropuerto Tenerife Sur Reina Sofí­a (TFS) ",
        "Island": "TF",
        "ZoneId": 210,
        "ZoneName": "Aeropuerto Sur",
        "Address": "Aeropuerto Tenerife Sur (T), 38610, Santa Cruz de Tenerife, España",
        "Coordinates": "28.046500116877535, -16.572706413129964"
    },
    {
        "Id": 210,
        "Name": "Aeropuerto Tenerife Norte Los Rodeos (TFN)",
        "Island": "TF",
        "ZoneId": 201,
        "ZoneName": "Aeropuerto Norte",
        "Address": "Aeroporto Los rodeos, 38206 Santa Cruz de Tenerife, España",
        "Coordinates": "28.4862244,-16.343355"
    },
    {
        "Id": 420,
        "Name": "TestABM2511",
        "Island": "TF",
        "ZoneId": 201,
        "ZoneName": "Aeropuerto Norte",
        "Address": "GC-605, 35368 Tejeda, Las Palmas, España",
        "Coordinates": "27.9470885,-15.6348713"
    },
    {
        "Id": 502,
        "Name": "Tenerife Sur",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Ámsterdam, 7, 38650 Arona, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0532474,-16.7109214"
    },
    {
        "Id": 503,
        "Name": "Playa Canaria ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Guerrero, S/n, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4064508,-16.5395919"
    },
    {
        "Id": 504,
        "Name": "Flamingo Beach Mate ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0790126,-16.7338071"
    },
    {
        "Id": 505,
        "Name": "Flamingo Suites",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Avenida de españa 6 san eugenio bajo Costa adeje tenerife 38660, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0800261,-16.7333911"
    },
    {
        "Id": 506,
        "Name": "Marola Portosin ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Antonio Dominguez, 20, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0570748,-16.7231299"
    },
    {
        "Id": 507,
        "Name": "Udalla Park ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Antonio Dominguez, 16, 38660 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0564338,-16.724461"
    },
    {
        "Id": 508,
        "Name": "Las Mimosas ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle el Hibisco, 2, 38683 Santiago del Teide, Santa Cruz de Tenerife",
        "Coordinates": "28.24245052207438,-16.839495599269867"
    },
    {
        "Id": 509,
        "Name": "Drago Apartamentos",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. 8 Marzo, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.406765,-16.5518532"
    },
    {
        "Id": 510,
        "Name": "Muelle Santa Cruz ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "38001 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4688166,-16.244241"
    },
    {
        "Id": 511,
        "Name": "Malibu Park ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Italia, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0785088,-16.7256638"
    },
    {
        "Id": 512,
        "Name": "Adonis Villas Fañabe",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urbanizacion Playa Fañabe, 18, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.08765,-16.73339"
    },
    {
        "Id": 513,
        "Name": "Aguamar Apartments",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Albani, s/n, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0538302,-16.7094939"
    },
    {
        "Id": 514,
        "Name": "App. Santa Ana",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Unnamed Road, 38630, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0118802,-16.646985"
    },
    {
        "Id": 515,
        "Name": "Apartamentos Albatros",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Jable, 42, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.1290454,-16.7831213"
    },
    {
        "Id": 516,
        "Name": "Casa Maria",
        "Island": "TF",
        "ZoneId": 212,
        "ZoneName": "Caletillas",
        "Address": "Prolongacion Cuevas, 8, 38540 Candelaria, Santa Cruz de Tenerife, España",
        "Coordinates": "28.35551,-16.40413"
    },
    {
        "Id": 517,
        "Name": "Casablanca",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calz. Martianez, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.41396511861636,-16.543098092079163"
    },
    {
        "Id": 518,
        "Name": "Pension Casa Blanca ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Ramon Pino, 28, 38650 Los Cristianos, Santa Cruz de Tenerife",
        "Coordinates": "28.052210904213524,-16.718919575214386"
    },
    {
        "Id": 519,
        "Name": "7lizards ocen view aptos",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Jose Gonzalez Forte, 73, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2409162,-16.8374155"
    },
    {
        "Id": 520,
        "Name": "Ocean View San Eugenio",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Paises Bajos, 25, 38660 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.080495229183423,-16.722312569618225"
    },
    {
        "Id": 521,
        "Name": "Tigaiga Suites",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Camino del Coche, 7, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4095524,-16.5503949"
    },
    {
        "Id": 522,
        "Name": "Tigaiga",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "38416 Tigaiga, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.410194642667324,-16.548714635508965"
    },
    {
        "Id": 523,
        "Name": "GARAJONAY APARTAMENTOS",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de los Pueblos, 14, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.074428,-16.7298756"
    },
    {
        "Id": 524,
        "Name": "Ponderosa ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Venezuela, 605, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0725714,-16.7292179"
    },
    {
        "Id": 525,
        "Name": "Iberostar Anthelia ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 15, 38660 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.08960578517277,-16.737585067749023"
    },
    {
        "Id": 526,
        "Name": "Jardin Tropical",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Gran Bretaña, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0759858,-16.7337274"
    },
    {
        "Id": 527,
        "Name": "Jardines de Nivaria",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle París, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0887871,-16.7364056"
    },
    {
        "Id": 528,
        "Name": "LA CARABELA",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Richard J. Yeoward, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4137718,-16.5396415"
    },
    {
        "Id": 529,
        "Name": "Las Carabelas (C. Adeje)",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "x",
        "Coordinates": "28.081455142501227,-16.73313260677063"
    },
    {
        "Id": 530,
        "Name": "Las Fuentes ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. la Habana, 7, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0512904,-16.7212067"
    },
    {
        "Id": 531,
        "Name": "Oasis Mango - Apartamentos Ohasis Boutique Suites",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. San Francisco, 15, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0558317,-16.7046101"
    },
    {
        "Id": 532,
        "Name": "Tenerife apartments Oasis",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urbanizacion Oasis de Fanabe 2. Calle el Peralillo., 38679 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.09349,-16.7281892"
    },
    {
        "Id": 533,
        "Name": "REGENCY CLUB",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Cataluña, 2, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.085703,-16.7257182"
    },
    {
        "Id": 534,
        "Name": "Regency Country Club Aptos ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Armiche, 1, 38652 Chayofa, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.07147,-16.700415"
    },
    {
        "Id": 535,
        "Name": "Tivoli La Caleta Tenerife Resort",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle la Enramada, 9, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0959533,-16.7481023"
    },
    {
        "Id": 536,
        "Name": "TRH Taoro Garden",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Dinamarca, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4109676,-16.5462597"
    },
    {
        "Id": 537,
        "Name": "Pearly Grey Ocean Club - Perla Gris",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle la Lava, 4, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.1291828,-16.7839406"
    },
    {
        "Id": 538,
        "Name": "Aparthotel los geranios",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0784935,-16.733037"
    },
    {
        "Id": 539,
        "Name": "Los Geranios Aptos ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Robles, 5, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4134642,-16.536407"
    },
    {
        "Id": 540,
        "Name": "Club Olympus Garden City APTOS",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0746531,-16.7307197"
    },
    {
        "Id": 541,
        "Name": "El Marques ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle las Rosas, 4, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2407191,-16.8399921"
    },
    {
        "Id": 542,
        "Name": "Island Village",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Austria, 46, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0808753,-16.7262066"
    },
    {
        "Id": 543,
        "Name": "Island Village Heights",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Italia, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0795137,-16.7254892"
    },
    {
        "Id": 544,
        "Name": "Hapimag Hotel",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Bélgica, 31, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4107701,-16.5448335"
    },
    {
        "Id": 545,
        "Name": "Primavera Complex ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Odisea, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.012276,-16.6517887"
    },
    {
        "Id": 546,
        "Name": "Marina Primavera Complex ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 34, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0800687,-16.7318974"
    },
    {
        "Id": 547,
        "Name": "APTOS.PARADISE COURT",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Irlanda, 5, 38660 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0824705,-16.722843"
    },
    {
        "Id": 548,
        "Name": "WYNDHAM RESIDENCES TENERIFE GOLF DEL SUR ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Golf del Sur, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0286685,-16.6091169"
    },
    {
        "Id": 549,
        "Name": "Wyndham Residences Tenerife Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Finlandia, 8, 38660 Costa Adeje-San Eugenio, Santa Cruz de Tenerife",
        "Coordinates": "28.07571483608873,-16.72453075647354"
    },
    {
        "Id": 550,
        "Name": "Wyndham Residences Tenerife Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Finlandia, San Eugenio Alto, No. 8, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0760159,-16.7244703"
    },
    {
        "Id": 551,
        "Name": "Ramada Residences by Wyndham Tenerife Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Torviscas Alto, Calle Galicia, 6, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0868333,-16.7237222"
    },
    {
        "Id": 552,
        "Name": "Ramada Residences by Wyndham Tenerife Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Torviscas Alto, Calle Galicia, 6, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0868333,-16.7237222"
    },
    {
        "Id": 553,
        "Name": "O7 Tenerife",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Haya, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4145299,-16.5392393"
    },
    {
        "Id": 554,
        "Name": "Marola Park",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Antonio Dominguez, 0, 38650 Playa De Las Américas ( Arona ), Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0566732,-16.7239476"
    },
    {
        "Id": 555,
        "Name": "Adonis Capital ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle de Cruz Verde, 24, 38003 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.466707,-16.249087"
    },
    {
        "Id": 556,
        "Name": "NH Tenerife ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Candelaria, 3, 38003 Santa Cruz de Tenerife",
        "Coordinates": "28.46633104293243,-16.24857008457184"
    },
    {
        "Id": 557,
        "Name": "Pelinor Hotel ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Bethencourt Alfonso, 8, 38002 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4674976,-16.2485486"
    },
    {
        "Id": 558,
        "Name": "Plaza Tenerife ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Londres Plaza del Duque, s/n, C.C, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0909547,-16.7391483"
    },
    {
        "Id": 559,
        "Name": "Horizonte ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle de Sta. Rosa de Lima, 11, 38002 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4699694,-16.2496722"
    },
    {
        "Id": 560,
        "Name": "Hotel Principe Paz ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle de Valentin Sanz, 33, 38002 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4688237,-16.2507674"
    },
    {
        "Id": 561,
        "Name": "Adonis Plaza ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Plaza de la Candelaria, 10, 38002 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4671411,-16.2485814"
    },
    {
        "Id": 562,
        "Name": "Atlantico Hotel ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle del Castillo, 12, 38002 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4670158,-16.2498772"
    },
    {
        "Id": 563,
        "Name": "Muelle Cristianos ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Muelle de Pescadores, puerto de Los Cristianos., 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.049274,-16.718653"
    },
    {
        "Id": 564,
        "Name": "4Dreams Hotel Chimisay ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Agustín de Betancourt, 14, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.416469,-16.549292"
    },
    {
        "Id": 565,
        "Name": "Abama Golf & Spa Packages",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Carretera general, TF-47, KM9, 38687 Guía de Isora, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1708532,-16.7926111"
    },
    {
        "Id": 566,
        "Name": "Aidu Alborada",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. de Fernando Salazar Gonzalez, S/N, 38631 Las Galletas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.009067,-16.6531458"
    },
    {
        "Id": 567,
        "Name": "Bambi Aptos ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle de Enrique Talg, 15, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4164663,-16.5453447"
    },
    {
        "Id": 568,
        "Name": "Alua Atlantico Golf Resort",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. del Atlàntico, 11, 38620, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0247222,-16.6111111"
    },
    {
        "Id": 569,
        "Name": "Alborada Ocean Club - Ex. Annapurna",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. de Fernando Salazar Gonzalez, S/N, 38631 Las Galletas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.009067,-16.6531458"
    },
    {
        "Id": 570,
        "Name": "Alondras Park",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Minerva, 16, 38630 Costa del Silencio, Arona, Santa Cruz de Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0105862,-16.6410442"
    },
    {
        "Id": 571,
        "Name": "Andorra Hotel",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Antonio Dominguez, 10, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0563889,-16.7286111"
    },
    {
        "Id": 572,
        "Name": "Andreas Hotel ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "C. Amalia Alayon, 26, 38650 Arona, Santa Cruz de Tenerife, España",
        "Coordinates": "28.052912417080588,-16.71454760991034"
    },
    {
        "Id": 573,
        "Name": "Apartamentos Alta",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Aguilar y Quesada, s/n, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4164212,-16.5429942"
    },
    {
        "Id": 574,
        "Name": "Astoria ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle la Hoya, 58, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4166068,-16.5452041"
    },
    {
        "Id": 575,
        "Name": "Aparthotel Columbus",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Santiago Puig, 8, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0609123,-16.72773"
    },
    {
        "Id": 576,
        "Name": "Aparthotel Cordial Golf Plaza-Grand Muthu Golf Plaza",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, s/n, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0276661,-16.6109981"
    },
    {
        "Id": 577,
        "Name": "Appt. Bellavista Gomera",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Sta. Ana, 29, 38811 Laguna de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.03316,-17.19454"
    },
    {
        "Id": 578,
        "Name": "Aptos. Aguamarina Golf",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. del Atlàntico, 11, 38620, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0247222,-16.6111111"
    },
    {
        "Id": 579,
        "Name": "Arenas del Mar",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "38612 El Médano, Santa Cruz de Tenerife",
        "Coordinates": "28.045984063319,-16.536312103271484"
    },
    {
        "Id": 580,
        "Name": "Atlantic Holiday Center",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle la Lava, 10, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.127464,-16.7833184"
    },
    {
        "Id": 581,
        "Name": "Atlantida Los Cristianos",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Londres, 3, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0506008,-16.7102662"
    },
    {
        "Id": 582,
        "Name": "Baobab Suites",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle Roques del Salmor, 5, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.09576,-16.74063"
    },
    {
        "Id": 583,
        "Name": "Bahia Del Duque Resort ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Av. de Bruselas, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.09323,-16.742085"
    },
    {
        "Id": 584,
        "Name": "Sholeo Los Gigantes",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. la Gaviota, 3, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2265465,-16.8387544"
    },
    {
        "Id": 585,
        "Name": "Bahia Playa ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Manuel Yanes Barreto nº1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4111682,-16.5593575"
    },
    {
        "Id": 586,
        "Name": "Princess Inspire Tenerife",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Bruselas, 1, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0853826,-16.7333382"
    },
    {
        "Id": 587,
        "Name": "Bahia Principe Costa Adeje",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle Idafe, s/n, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1179122,-16.7737251"
    },
    {
        "Id": 588,
        "Name": "Bahia Principe San Felipe",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. de Cristobal Colón, 22, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.416935030971818,-16.541248630554154"
    },
    {
        "Id": 589,
        "Name": "Bahia Principe Tenerife Resort",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle Idafe, s/n, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1188932,-16.769251"
    },
    {
        "Id": 590,
        "Name": "Barcelo Contemporaneo -occidental contemporaneo",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Rambla de Santa Cruz, 116, 38001 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4740334,-16.2529183"
    },
    {
        "Id": 591,
        "Name": "Barcelo Santiago",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Tenerife, Calle la Hondura, 8, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2396236,-16.8445315"
    },
    {
        "Id": 592,
        "Name": "Allegro Isora",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. la Gaviota, 1, 38686 Playa, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2250459,-16.8373566"
    },
    {
        "Id": 593,
        "Name": "Be Live La Niña",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.081351,-16.7334687"
    },
    {
        "Id": 594,
        "Name": "Be Live Orotava",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Tenerife, Av. Aguilar y Quesada, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4162787,-16.5433273"
    },
    {
        "Id": 595,
        "Name": "Landmar Playa la Arena",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Lajial, 4, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2325166,-16.8391584"
    },
    {
        "Id": 596,
        "Name": "Sol Costa Atlantis",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Venezuela, 15, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4174153,-16.5422837"
    },
    {
        "Id": 597,
        "Name": "Atlantic Mirage Suite & Spa",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Eucaliptus, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4164352,-16.5368933"
    },
    {
        "Id": 598,
        "Name": "Best Semiramis",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Leopoldo Cólogan Zulueta, 12, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4161233,-16.5356099"
    },
    {
        "Id": 599,
        "Name": "Best Tenerife",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Antonio Dominguez Alfonso, 6, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.057181,-16.731657"
    },
    {
        "Id": 600,
        "Name": "Blue Sea Callao Garden",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Jable, 39, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.13131,-16.778454"
    },
    {
        "Id": 601,
        "Name": "Blue Sea Interpalace",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 21, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4155842,-16.5360632"
    },
    {
        "Id": 602,
        "Name": "Blue Sea Lagos De Cesar",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle José González González, s/n, 38683 Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.234388,-16.840365"
    },
    {
        "Id": 603,
        "Name": "Blue Sea Puerto Resort",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 8, 10, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4152224,-16.5359561"
    },
    {
        "Id": 604,
        "Name": "Bonanza (Las Americas)",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Aceviño, 6, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4148807,-16.5357944"
    },
    {
        "Id": 605,
        "Name": "Hotel Botanico",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Richard J. Yeoward, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4112396,-16.538084"
    },
    {
        "Id": 606,
        "Name": "Checkin Bung. Atlantida",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Londres, 3, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0506008,-16.7102662"
    },
    {
        "Id": 607,
        "Name": "Alegria Barranco",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Santiago Puig, 3, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0621951,-16.7286843"
    },
    {
        "Id": 608,
        "Name": "Bungalows Parque Cristobal",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Avda. Rafael Puig Liviana, 15, 38660 Playa de la Américas, Santa Cruz de Tenerife",
        "Coordinates": "28.060581832917315,-16.731984615325928"
    },
    {
        "Id": 609,
        "Name": "Caribe Aptos. ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. V Centenario, 3, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0675692,-16.7272849"
    },
    {
        "Id": 610,
        "Name": "Chinyero Aptos. ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Carretera general del Botánico, 35, Carr. Botánico, 35, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.41287049505073,-16.54357820749283"
    },
    {
        "Id": 611,
        "Name": "Caledonia Hotel Nautico",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Av. Profesor Peraza Ayala, 13, 38001 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4797325,-16.245985"
    },
    {
        "Id": 612,
        "Name": "Callaomar",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Tenique, 4, 38678 Callao Salvaje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1297974,-16.7758556"
    },
    {
        "Id": 613,
        "Name": "Canarife Palace ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 10, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4151535,-16.5346204"
    },
    {
        "Id": 614,
        "Name": "Casa Del Sol ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Urb. San Fernando, Calle Finlandia, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4095593,-16.5443744"
    },
    {
        "Id": 615,
        "Name": "Casa Rural Acoroma",
        "Island": "TF",
        "ZoneId": 212,
        "ZoneName": "Caletillas",
        "Address": "Camino Lomo de Acoroma, 49, 38520 Candelaria, Santa Cruz de Tenerife, España",
        "Coordinates": "28.3841,-16.37827"
    },
    {
        "Id": 616,
        "Name": "Castle Harbour ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. San Francisco, 12, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0554913,-16.7045077"
    },
    {
        "Id": 617,
        "Name": "Catalonia Las Vegas ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. de Cristobal Colón, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4173179,-16.5446978"
    },
    {
        "Id": 618,
        "Name": "Catalonia Oro Negro ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 14, 38660 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.063197,-16.7275497"
    },
    {
        "Id": 619,
        "Name": "Catalonia Punta Del Rey",
        "Island": "TF",
        "ZoneId": 212,
        "ZoneName": "Caletillas",
        "Address": "Av. Maritima, 165, 38520 Las Caletillas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3773048,-16.36221"
    },
    {
        "Id": 620,
        "Name": "Chiripa Garden ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Bélgica, 54, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4084651,-16.5456093"
    },
    {
        "Id": 621,
        "Name": "Cleopatra Palace - Mare Nostrum R.",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.054054,-16.731451"
    },
    {
        "Id": 622,
        "Name": "Club Ambassador",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Granados, 18, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.407675,-16.5380667"
    },
    {
        "Id": 623,
        "Name": "Colon Guanahani",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Playa de Fañabé, Av. de Bruselas, S/N, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0886601,-16.735261"
    },
    {
        "Id": 624,
        "Name": "Compostela Beach Apt.",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 8, 38660 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0539991,-16.7281237"
    },
    {
        "Id": 625,
        "Name": "Compostela Beach Golf Club",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Campo de Golf de las Américas Los, Calle Arenales, 16, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0591292,-16.7204005"
    },
    {
        "Id": 626,
        "Name": "Concordia Playa ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Familia de Betancourt y Molina, 32, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4176674,-16.5428886"
    },
    {
        "Id": 627,
        "Name": "Costamar",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 40, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0452228,-16.7099724"
    },
    {
        "Id": 628,
        "Name": "Cristian Sur",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Ámsterdam, 4, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0526805,-16.7108755"
    },
    {
        "Id": 629,
        "Name": "Aluasoul Orotava Valley",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Manuel Yanes Barreto, s/n, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4084091,-16.5566311"
    },
    {
        "Id": 630,
        "Name": "Diamond Apartamentos ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Cl. Tabaiba, 5, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2454382,-16.8387356"
    },
    {
        "Id": 631,
        "Name": "Blue sea Costa Jardin",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Guerrero, 14, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4056416,-16.5399399"
    },
    {
        "Id": 632,
        "Name": "Don Manolito",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "doctor madam, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4158353,-16.5556182"
    },
    {
        "Id": 633,
        "Name": "Dos Barrancos",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Dos Barrancos, 38180 María Jiménez, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.5034337,-16.2285165"
    },
    {
        "Id": 634,
        "Name": "Dragos del Norte",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. 8 Marzo, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.406765,-16.5518532"
    },
    {
        "Id": 635,
        "Name": "Dragos del Sur",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Marítima Puerto de Santiago, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2357652,-16.8411952"
    },
    {
        "Id": 636,
        "Name": "Dream Gran Tacande ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "C/Alcalde Walter Paetzmann, s/n, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0918938,-16.7398868"
    },
    {
        "Id": 637,
        "Name": "Tigotan Lovers & Friends",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Noelia Afonso Cabrera, 6, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0598907,-16.7292555"
    },
    {
        "Id": 638,
        "Name": "TAGORO family & fun",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Galicia, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0877252,-16.7264821"
    },
    {
        "Id": 639,
        "Name": "Eden Esplanade Parque Vacacion",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Francia, 8, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4090376,-16.5433955"
    },
    {
        "Id": 640,
        "Name": "Eden Luz Parque Vacacional",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Francia, 8, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4090376,-16.5433955"
    },
    {
        "Id": 641,
        "Name": "El Cortijo Apts ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Argentina, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0697212,-16.7282733"
    },
    {
        "Id": 642,
        "Name": "Aparthotel El Duque",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Jardines del Duque, 57, 38679 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.092934372463063,-16.734373605396993"
    },
    {
        "Id": 643,
        "Name": "El Sombrero",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av Jose Gonzalez Fortes. 28, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.242756,-16.8389788"
    },
    {
        "Id": 644,
        "Name": "El Tope Hotel",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "C, Calz. Martianez, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4145824,-16.5437594"
    },
    {
        "Id": 645,
        "Name": "Hotel AF Valle Orotava",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Cupido, 12, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4146304,-16.5521881"
    },
    {
        "Id": 646,
        "Name": "Elegance Miramar",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Parque Taoro, 23, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4119249,-16.5471976"
    },
    {
        "Id": 647,
        "Name": "Elegance Palmeras Playa ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Venezuela, 12, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.416973,-16.542516"
    },
    {
        "Id": 648,
        "Name": "Europe Villa Cortes",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 38, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0561088,-16.7347188"
    },
    {
        "Id": 649,
        "Name": "FLORIDA PARK CLUB",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Pje. Austria, 10, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0754756,-16.7279413"
    },
    {
        "Id": 650,
        "Name": "FamilyGarden Compostela Beach",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 8, 38660 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0539991,-16.7281237"
    },
    {
        "Id": 651,
        "Name": "Ferienpark Eden",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Francia, 8, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4090888,-16.5435293"
    },
    {
        "Id": 652,
        "Name": "Hard Rock Hotel Tenerife-Fiesta Hotel Playa Paraiso",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Avenida Adeje 300, Av. Playa Paraíso, s/n, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1210533,-16.7756664"
    },
    {
        "Id": 653,
        "Name": "Finca La Paz",
        "Island": "TF",
        "ZoneId": 212,
        "ZoneName": "Caletillas",
        "Address": "Calle Santo Domingo, 21, 38540 Candelaria, Santa Cruz de Tenerife, España",
        "Coordinates": "28.35709,-16.40571"
    },
    {
        "Id": 654,
        "Name": "Finca San Juan",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Orilla de la Vera, 2, 38428 San Juan de la Rambla, Santa Cruz de Tenerife, España",
        "Coordinates": "28.3884243,-16.642781"
    },
    {
        "Id": 655,
        "Name": "Fonda Central ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle Grande, 26, 38670 Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.1220967,-16.7246867"
    },
    {
        "Id": 656,
        "Name": "GF Costa Adeje Gran Hotel",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Bruselas, 16, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0934091,-16.7365611"
    },
    {
        "Id": 657,
        "Name": "GF Fañabe Costa Sur",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Bruselas, 13, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.090398,-16.735338"
    },
    {
        "Id": 658,
        "Name": "GF Hotel Isabel",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Moscú, 2, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.091462,-16.7342"
    },
    {
        "Id": 659,
        "Name": "Gala Hotel",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 3, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0664018,-16.7298447"
    },
    {
        "Id": 660,
        "Name": "Girasol ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle del Dr. Ingram, 8, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4154077,-16.5512319"
    },
    {
        "Id": 661,
        "Name": "Globales Acuario",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Parque de las Flores, 36, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4042301,-16.551897"
    },
    {
        "Id": 662,
        "Name": "Globales Tamaimo Tropical",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle la Hondura, s/n, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2388413,-16.8432572"
    },
    {
        "Id": 663,
        "Name": "Gran Melia Palacio De Isora",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Av. de los Océanos, s/n, 38686 Alcalá, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.20465,-16.831656"
    },
    {
        "Id": 664,
        "Name": "Gran Oasis Resort",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Meandro, 3, 38640 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0630493,-16.7135944"
    },
    {
        "Id": 665,
        "Name": "Granada Park",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Meandro, 2, Campo de Golf Las Américas, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0628428,-16.7128007"
    },
    {
        "Id": 666,
        "Name": "Grand Hotel Callao ",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Gran Hotel Callao, Calle el Jable, s/n, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1335739,-16.7708854"
    },
    {
        "Id": 667,
        "Name": "Green Garden Resort",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Landa Golf, 1, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0637336,-16.7199264"
    },
    {
        "Id": 668,
        "Name": "Guayarmina Princess",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 1, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0849792,-16.7344797"
    },
    {
        "Id": 669,
        "Name": "H10 Big Sur",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 28, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.049068,-16.712844"
    },
    {
        "Id": 670,
        "Name": "H10 Conquistador",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 36, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0572869,-16.7347415"
    },
    {
        "Id": 671,
        "Name": "H10 Costa Adeje Palace",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Playa La Enramada, 38679 La Caleta, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0972177,-16.7492026"
    },
    {
        "Id": 672,
        "Name": "H10 Gran Tinerfe",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 13, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.072093,-16.731877"
    },
    {
        "Id": 673,
        "Name": "H10 Las Palmeras",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 28, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0608196,-16.7323875"
    },
    {
        "Id": 674,
        "Name": "H10 Tenerife Playa ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. de Cristobal Colón, 12, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4187737,-16.5426145"
    },
    {
        "Id": 675,
        "Name": "Hotel Suite Villa Maria",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Av. de los Acantilados, 38679 Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.1071263,-16.7505424"
    },
    {
        "Id": 676,
        "Name": "HOVIMA Atlantis",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Colón, 3, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0779429,-16.7332003"
    },
    {
        "Id": 677,
        "Name": "HOVIMA La Pinta",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 5, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0804271,-16.7340096"
    },
    {
        "Id": 678,
        "Name": "HOVIMA Panorama",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Gran Bretaña, 8, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0763313,-16.7340682"
    },
    {
        "Id": 679,
        "Name": "HOVIMA Santa Maria",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de España, 10, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0815241,-16.7324607"
    },
    {
        "Id": 680,
        "Name": "Hacienda Del Sol ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 5, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0662627,-16.7293085"
    },
    {
        "Id": 681,
        "Name": "Hotel Alhambra REVISAR PUNTO DE ENCUENTRO",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Nicandro González Borges, 19, 38300 La Orotava, Santa Cruz de Tenerife, España",
        "Coordinates": "28.392674,-16.523442"
    },
    {
        "Id": 682,
        "Name": "Hotel Ventus",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Av. Príncipes de España, 22, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0465416,-16.5384513"
    },
    {
        "Id": 683,
        "Name": "Hotel Escuela Santa Cruz",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Av. de San Sebastián, 152, 38005 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4639116,-16.2597695"
    },
    {
        "Id": 684,
        "Name": "Riu Garoe",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Dr. Celestino Gonzàlez Padrón, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.412579795273306,-16.531602872526943"
    },
    {
        "Id": 685,
        "Name": "Hotel Laguna Nivaria ",
        "Island": "TF",
        "ZoneId": 221,
        "ZoneName": "La Laguna",
        "Address": "Plaza del Adelantado, 11, 38201 San Cristóbal de La Laguna, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4867522,-16.3131941"
    },
    {
        "Id": 686,
        "Name": "Hotel Medano ",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Calle Picachos, 2, 38612 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0440715,-16.5379872"
    },
    {
        "Id": 687,
        "Name": "Hotel Reveron Plaza ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Los Playeros, 26, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0519589,-16.7167884"
    },
    {
        "Id": 688,
        "Name": "Hotel Taburiente",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Dr. Jose Naveiras, 24, 38001 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4734246,-16.253061"
    },
    {
        "Id": 689,
        "Name": "Hovima Altamira",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Urbanización el Beril, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0937892,-16.7463488"
    },
    {
        "Id": 690,
        "Name": "Hovima Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 8, 38660 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0837268,-16.7331614"
    },
    {
        "Id": 691,
        "Name": "Hovima Jardin Caleta",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Av. de Las Gaviotas, 19, 38679 La Caleta, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0992691,-16.7539242"
    },
    {
        "Id": 692,
        "Name": "Iberostar Bouganville Playa ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Eugenio Dominguez Alfonso, 23, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0737431,-16.7331813"
    },
    {
        "Id": 693,
        "Name": "Iberostar Grand Hotel Mencey",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Dr. Jose Naveiras, 38, 38004 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4745031,-16.253194"
    },
    {
        "Id": 694,
        "Name": "Iberostar Grand Hotel Salome",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 15, A, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.089607,-16.73889"
    },
    {
        "Id": 695,
        "Name": "Iberostar Las Dalias ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Gran Bretaña, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0766251,-16.7310034"
    },
    {
        "Id": 696,
        "Name": "Iberostar Mirador ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Urbanizacion Poligono 8, 1, 38679 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.09259,-16.74443"
    },
    {
        "Id": 697,
        "Name": "Iberostar Sabila",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 5, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0842785,-16.7340753"
    },
    {
        "Id": 698,
        "Name": "Labranda Suites Costa Adeje",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Bruselas, 8, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0891342,-16.7343457"
    },
    {
        "Id": 699,
        "Name": "Isla Verde ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Morro Chayofa, 38652 Chayofa, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0726357,-16.6933399"
    },
    {
        "Id": 700,
        "Name": "Jacaranda",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Bruselas, 4-6, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0882345,-16.7322652"
    },
    {
        "Id": 701,
        "Name": "La Correa",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle de la Cruz de San Antonio, Nº57, 38640 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1093391,-16.6774666"
    },
    {
        "Id": 702,
        "Name": "La Siesta",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 21, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0589886,-16.7332153"
    },
    {
        "Id": 703,
        "Name": "Lagos De Fañabe",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 7, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0866278,-16.7357182"
    },
    {
        "Id": 704,
        "Name": "Laguna Park I",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Avenida de los Pueblos, Urb. San Eugenio, 26, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0795794,-16.7307147"
    },
    {
        "Id": 705,
        "Name": "Laguna Park II",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle País Vasco, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.083116,-16.726195"
    },
    {
        "Id": 706,
        "Name": "Las Aguilas",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Dr.Barajas, 19, 38400 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3998521,-16.545893"
    },
    {
        "Id": 707,
        "Name": "Las Floritas ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 16, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0625108,-16.7271408"
    },
    {
        "Id": 708,
        "Name": "Las Madrigueras",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Meandro, 3, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0623497,-16.7140023"
    },
    {
        "Id": 709,
        "Name": "Las Piramides Resort",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Víctor Zurita Soler, 4, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0630417,-16.7286913"
    },
    {
        "Id": 710,
        "Name": "Los Cardones Boutique",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Los Cardones Building, 38660 Playa de la Américas, Santa Cruz de Tenerife, España",
        "Coordinates": "28.059948,-16.732415"
    },
    {
        "Id": 711,
        "Name": "Allsun Los Hibiscos",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urbanizacion San Eugenio no name s/n, 38660 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.075648571719107,-16.732360124588013"
    },
    {
        "Id": 712,
        "Name": "Los Olivos",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle París, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0876279,-16.7367906"
    },
    {
        "Id": 713,
        "Name": "Be Smart Apartamentos Florida ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "C/ Calafete, 3",
        "Coordinates": "28.412966065838845,-16.55683459526183"
    },
    {
        "Id": 714,
        "Name": "Landmar Costa Los Gigantes",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "C/ Juan Manuel Capdevielle, 8, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.234123,-16.8367909"
    },
    {
        "Id": 715,
        "Name": "Be Smart Florida Plaza ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Puerto Viejo, 30, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.416174,-16.5534832"
    },
    {
        "Id": 716,
        "Name": "Be live Adults Only Tenerife",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "González, Tenerife, Av. Blas Pérez González, 11, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4114163,-16.5562068"
    },
    {
        "Id": 717,
        "Name": "Mar y Sol",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Ámsterdam, 8, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0497091,-16.7074622"
    },
    {
        "Id": 718,
        "Name": "Maga ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "C. de Iriarte, 11, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.415563,-16.549684"
    },
    {
        "Id": 719,
        "Name": "Marino Tenerife & Marina Club",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Minerva, 2, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0079569,-16.640724"
    },
    {
        "Id": 720,
        "Name": "Precise Resort Puerto de la Cruz ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Los Realejos ES-TF ES El Burgado 1, 38410 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4064081,-16.5705396"
    },
    {
        "Id": 721,
        "Name": "Marquesa",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Quintana, 11, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4171449,-16.5490572"
    },
    {
        "Id": 722,
        "Name": "Marte ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle del Dr. Ingram, 20, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4151535,-16.5523071"
    },
    {
        "Id": 723,
        "Name": "Marylanza Suites & Spa",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Los, Calle Arenales, 20, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0597026,-16.7226521"
    },
    {
        "Id": 724,
        "Name": "Masaru",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Robles, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4147784,-16.5376683"
    },
    {
        "Id": 725,
        "Name": "Mediterranean Palace",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0542421,-16.7294614"
    },
    {
        "Id": 726,
        "Name": "Melia Jardines Del Teide",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Urbanización Mirador del Duque, Calle Agando, s/n, 38679 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.096391,-16.738912"
    },
    {
        "Id": 727,
        "Name": "Molino Blanco ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Almácigo, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.41295306403318,-16.536988019943237"
    },
    {
        "Id": 728,
        "Name": "Monopol ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Quintana, 15, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.417354,-16.548598"
    },
    {
        "Id": 729,
        "Name": "Neptuno Hotel",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Cataluña, 3, 38670 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.084828164027172,-16.725869178771973"
    },
    {
        "Id": 730,
        "Name": "Noelia Playa",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "9, Calle Hnos. Fdez. Perdigón, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4123182,-16.5555696"
    },
    {
        "Id": 731,
        "Name": "Nopal ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle San Juan, 17, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4156956,-16.54941"
    },
    {
        "Id": 732,
        "Name": "Ole Tenerife Tropical",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Bolivia, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.066706391681073,-16.727712606216546"
    },
    {
        "Id": 733,
        "Name": "Oro Blanco ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 12, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0639274,-16.7274239"
    },
    {
        "Id": 734,
        "Name": "Parque de las Americas ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 16, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0714142,-16.7307397"
    },
    {
        "Id": 735,
        "Name": "PlayaFlor Chill-Out Resort ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 3, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0659622,-16.7315033"
    },
    {
        "Id": 736,
        "Name": "Palia Don Pedro",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Hercules, 3, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0080482,-16.6458598"
    },
    {
        "Id": 737,
        "Name": "Palm Beach Tenerife",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. V Centenario, 11, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0687829,-16.7320755"
    },
    {
        "Id": 738,
        "Name": "Panoramica Garden",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "La Longuera, s/n, 38410 Los Realejos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3974857,-16.5814724"
    },
    {
        "Id": 739,
        "Name": "Panoramica Heights",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Baleares, 17, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0858127,-16.7201159"
    },
    {
        "Id": 740,
        "Name": "Paradero I ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 7, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.066106,-16.728495"
    },
    {
        "Id": 741,
        "Name": "Paradero II ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 7, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.066106,-16.728495"
    },
    {
        "Id": 742,
        "Name": "Paradise Park",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Hawai, 2, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0537365,-16.705884"
    },
    {
        "Id": 743,
        "Name": "Paraiso Floral",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Playa Paraíso, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1213815,-16.7678237"
    },
    {
        "Id": 744,
        "Name": "Paraiso Royal ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Víctor Zurita Soler, 10, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0626734,-16.7294682"
    },
    {
        "Id": 745,
        "Name": "Park Club Europe",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 23, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0565171,-16.7340033"
    },
    {
        "Id": 746,
        "Name": "Parque Cattleya ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Santiago Puig, 5, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0619444,-16.7280556"
    },
    {
        "Id": 747,
        "Name": "Parque Del Sol",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 11, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0892982,-16.735857"
    },
    {
        "Id": 748,
        "Name": "Parque Don Jose",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Hercules, 12, 38630 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0095312,-16.6440939"
    },
    {
        "Id": 749,
        "Name": "Parque Santiago III",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 2, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0550716,-16.7332837"
    },
    {
        "Id": 750,
        "Name": "Parque Santiago IV  ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Avenida del Litoral, s/n, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0555005,-16.7350955"
    },
    {
        "Id": 751,
        "Name": "Parque de la Paz",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Luis Diaz de Losada, 2, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0559215,-16.7287108"
    },
    {
        "Id": 752,
        "Name": "Perla Tenerife ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Isla de la Gomera, 3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4082661,-16.5411434"
    },
    {
        "Id": 753,
        "Name": "Pez Azul",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Rector Benito Rodríguez Rios, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4125592,-16.5427209"
    },
    {
        "Id": 754,
        "Name": "Playa De Los Roques ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Cañaveras, 6, 38418 Los Realejos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4035789,-16.5771027"
    },
    {
        "Id": 755,
        "Name": "Playa Olid Suites & Apartaments",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Ernesto Sartí, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0862,-16.7301"
    },
    {
        "Id": 756,
        "Name": "Playa Real Resort",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Moscú, 111, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.09212,-16.73139"
    },
    {
        "Id": 757,
        "Name": "Playa Sur Tenerife",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Calle Gaviota, s/n, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0413758,-16.5430894"
    },
    {
        "Id": 758,
        "Name": "Playazul ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 6, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0655783,-16.7287844"
    },
    {
        "Id": 759,
        "Name": "PrimeComfort California",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Noelia Afonso Cabrera, 2, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0606069,-16.7295086"
    },
    {
        "Id": 760,
        "Name": "PrimeSelect Los Alisios",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Carretera Vieja a Guaza, s/n, 38627 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0562136,-16.7079868"
    },
    {
        "Id": 761,
        "Name": "Pueblo Torviscas",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Pueblo Torviscas, Calle Valencia, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.08196,-16.734824"
    },
    {
        "Id": 762,
        "Name": "Puerto Palace",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Dr. Celestino Cobiella Zaera, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.40842284642825,-16.550546913304014"
    },
    {
        "Id": 763,
        "Name": "FERGUS Puerto De La Cruz",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calz. Martiánez, 17, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.413978722813436,-16.542597373948702"
    },
    {
        "Id": 764,
        "Name": "Punta Negra ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle el Drago, 48, 38683 Puerto de Santiago, Santa Cruz de Tenerife",
        "Coordinates": "28.22701494052931,-16.84056580066681"
    },
    {
        "Id": 765,
        "Name": "Riu Arecas",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Playa del Duque, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.095269,-16.7459743"
    },
    {
        "Id": 766,
        "Name": "Riu Buenavista",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle El Horno, 35, Urbanizacion, 38678 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1235426,-16.778392"
    },
    {
        "Id": 767,
        "Name": "Riu Palace Tenerife",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Urbanización La Herradura, 38670, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0945606,-16.7475016"
    },
    {
        "Id": 768,
        "Name": "Red Level Palacio de Isora",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Av. de los Océanos, s/n, 38686 Alcalá, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.20465,-16.831656"
    },
    {
        "Id": 769,
        "Name": "Olivina Aparthotel",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Ámsterdam, 6, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0504153,-16.7081202"
    },
    {
        "Id": 770,
        "Name": "Roca Nivaria",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Adeje 300, s/n, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1204375,-16.7769933"
    },
    {
        "Id": 771,
        "Name": "Royal Garden Villas ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle Alcojora, s/n, 38670 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1130145,-16.7481934"
    },
    {
        "Id": 772,
        "Name": "Royal Parque Albatros",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, 38639 Oasis del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.026074,-16.610722"
    },
    {
        "Id": 773,
        "Name": "Royal Sun Resort ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Geranio, 16, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2451314,-16.8381526"
    },
    {
        "Id": 774,
        "Name": "Royal Sunset Beach Club",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 6, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.086998,-16.7346229"
    },
    {
        "Id": 775,
        "Name": "Royal Tenerife Country Club San Andrés",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle de San Andrés, 38620, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0353544,-16.6096438"
    },
    {
        "Id": 776,
        "Name": "Sun Holiday Hotal ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle La Peñita, 6, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.416823,-16.553755"
    },
    {
        "Id": 777,
        "Name": "Saint George",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Finlandia, 5, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0563647,-16.7229991"
    },
    {
        "Id": 778,
        "Name": "Barcelo Tenerife",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Avenida de Grenamora 1 Urbanization, C. San Blas, 38639 Oasis del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0306639,-16.5983629"
    },
    {
        "Id": 779,
        "Name": "San Borondon",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Agustin Espinosa, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.414837213389603,-16.555528143815184"
    },
    {
        "Id": 780,
        "Name": "Hotel Silken Saaj Maar",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Playa de San Telmo, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4174211,-16.5466682"
    },
    {
        "Id": 781,
        "Name": "Santa Barbara",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. del Atlàntico, s/n, Urbanizacion, 38639 Golf del Sur, Santa Cruz de Tenerife",
        "Coordinates": "28.025592,-16.607677"
    },
    {
        "Id": 782,
        "Name": "Silken Atlantida",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Av. Tres de Mayo, 3, 38005 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4584134,-16.2574421"
    },
    {
        "Id": 783,
        "Name": "Sir Anthony ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, s/n, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0528545,-16.7297242"
    },
    {
        "Id": 784,
        "Name": "Alua Parque San Antonio",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Ctra. General Puerto Cruz - Arenas, 51, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4078298,-16.5492559"
    },
    {
        "Id": 785,
        "Name": "Tryp Tenerife - Sol Arona",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 25, 38640 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0506749,-16.7120254"
    },
    {
        "Id": 786,
        "Name": "TRYP Puerto de la Cruz - Sol Puerto de la Cruz",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Jose Maria del Campo Llarena, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4149188,-16.5569325"
    },
    {
        "Id": 787,
        "Name": "Sol Sun Beach",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0859158,-16.7346956"
    },
    {
        "Id": 788,
        "Name": "Sol Tenerife ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lliviana 12, 38660 Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0643917,-16.7317713"
    },
    {
        "Id": 789,
        "Name": "Spa La Quinta Park Suites",
        "Island": "TF",
        "ZoneId": 223,
        "ZoneName": "Puerto de la Cruz 2",
        "Address": "Calle Bicacarera, 21, 38390 La Quinta, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4293637,-16.4958728"
    },
    {
        "Id": 790,
        "Name": "Spring Arona Gran Hotel",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 38, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0467224,-16.7097892"
    },
    {
        "Id": 791,
        "Name": "Spring Bitacora",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle California, 1, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.058271,-16.731147"
    },
    {
        "Id": 792,
        "Name": "Spring Vulcano",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Avenida Antonio Domínguez Alfonso, 8, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0572331,-16.7302655"
    },
    {
        "Id": 793,
        "Name": "Tui Blue Los Gigantes",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Flor de Pascua, 8, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.243907,-16.841966"
    },
    {
        "Id": 794,
        "Name": "Sunclub Paraiso Del Sol",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. V Centenario, 7, 38660 Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.067909,-16.728839"
    },
    {
        "Id": 795,
        "Name": "Sunprime Coral Suites ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Noelia Afonso Cabrera, 10, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0582099,-16.7296557"
    },
    {
        "Id": 796,
        "Name": "Sunset Bay Club",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Antonio Navarro, Urb. Los Atamanes, 1, 38660 Tenerife, Santa Cruz de Tenerife",
        "Coordinates": "28.082866414531022,-16.733229160308838"
    },
    {
        "Id": 797,
        "Name": "Sunset Harbour Club",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urb. Pueblo Torviscas, Calle Valencia, 3, 38660 Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0819519,-16.7348222"
    },
    {
        "Id": 798,
        "Name": "Sunset View Club",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle San Blas, s/n 38639 Golf del Sur, 38620 Santa Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0290002,-16.6020906"
    },
    {
        "Id": 799,
        "Name": "Tenerife Ving Apartamentos",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av de los Hermanos Fernández Perdigón, 8, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4123156,-16.556044"
    },
    {
        "Id": 800,
        "Name": "TINERFE GARDEN",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de los Pueblos, 24, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0794124,-16.7310262"
    },
    {
        "Id": 801,
        "Name": "Tanausu Hotel",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Padre Anchieta, 8, 38005 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4638417,-16.2534949"
    },
    {
        "Id": 802,
        "Name": "Teide Mar",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 6, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4150332,-16.5367151"
    },
    {
        "Id": 803,
        "Name": "Teneguia",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Melchor Luz, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4136658,-16.5565247"
    },
    {
        "Id": 804,
        "Name": "Tenerife Tour",
        "Island": "TF",
        "ZoneId": 212,
        "ZoneName": "Caletillas",
        "Address": "Los Geranios (T), 38530, Santa Cruz de Tenerife, España",
        "Coordinates": "28.3751,-16.3627"
    },
    {
        "Id": 805,
        "Name": "The Ritz-Carlton Abama",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Carretera General, Tf-47, Km 9, 38687 Guía de Isora, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.17104,-16.7993562"
    },
    {
        "Id": 807,
        "Name": "Hc Magec",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Cupido, 11, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4144449,-16.5520347"
    },
    {
        "Id": 808,
        "Name": "Tropical Park Apartamentos",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Jable, 1, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.13064,-16.78049"
    },
    {
        "Id": 809,
        "Name": "Tropical Park Plaza ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Josè de Arroyo, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4160022,-16.5513586"
    },
    {
        "Id": 810,
        "Name": "Trovador",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Puerto Viejo, 38, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.41572,-16.55439"
    },
    {
        "Id": 811,
        "Name": "Troya",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 2, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0675846,-16.7317183"
    },
    {
        "Id": 812,
        "Name": "Alua Tenerife",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Antonio Ruiz Alvarez, 7, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.410517,-16.5578437"
    },
    {
        "Id": 813,
        "Name": "Villas Bahia del Duque ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Av. de Bruselas, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.09323,-16.742085"
    },
    {
        "Id": 814,
        "Name": "Vigilia Park ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Quinto Centenario, 58, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2395417,-16.84087"
    },
    {
        "Id": 815,
        "Name": "Villa Adeje Beach",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de los Pueblos, 41, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0803374,-16.7307803"
    },
    {
        "Id": 816,
        "Name": "Villa Rosalba la Orotava ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Camino de los Pérez, Ctra. de las Dehesas, 37, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4061094,-16.5561743"
    },
    {
        "Id": 817,
        "Name": "Vincci La Plantacion",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle Roque Nublo, 1, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0946116,-16.7427459"
    },
    {
        "Id": 818,
        "Name": "Vincci Tenerife Golf",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Urbanizacion San Blas, s/n, 38620 tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0276895,-16.6000228"
    },
    {
        "Id": 819,
        "Name": "Vistasur ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 10, BAJO;LOCAL-16, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0532103,-16.7271093"
    },
    {
        "Id": 820,
        "Name": "Xibana Park  Ahora. Discovering ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle de Valois, 22, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.415,-16.5461111"
    },
    {
        "Id": 822,
        "Name": "Zentral Center Hotel",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0578878,-16.7281889"
    },
    {
        "Id": 823,
        "Name": "Sea Hostel Dive Alive ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Playa de, Calle el Coronel, 3, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0499597,-16.7141268"
    },
    {
        "Id": 824,
        "Name": "Apartamentos el Nautico Suites",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle San Miguel, 0, 38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0260599,-16.604168"
    },
    {
        "Id": 825,
        "Name": "Apartamentos Comodoro",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Obispo Antonio Tavira 14, planta 1 puerta 1, Los Cristianos, Av. Juan Carlos I, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0488128,-16.7122402"
    },
    {
        "Id": 826,
        "Name": "Apartamentos Green Park",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Apartmentos Parque Verde, 38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0296619,-16.6115363"
    },
    {
        "Id": 827,
        "Name": "Apartamentos Paraiso del Sol",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. V Centenario, 7, 38660 Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.067909,-16.728839"
    },
    {
        "Id": 828,
        "Name": "Aptos. parque royal",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 10, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0845172,-16.732571"
    },
    {
        "Id": 829,
        "Name": "Apartamentos Sand Club",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Trasera S. Blas, 154, 38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0305748,-16.6034699"
    },
    {
        "Id": 830,
        "Name": "Apartamentos Summerland",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Summerland Apartments, Calle Boston, 4, Local 14, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0540919,-16.70833"
    },
    {
        "Id": 831,
        "Name": "Aparthotel caledonia park",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Alemania, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0820278,-16.7283019"
    },
    {
        "Id": 832,
        "Name": "APARTHOTEL OCEAN CLUB",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. de Fernando Salazar Gonzalez, S/N, 38631 Las Galletas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.009067,-16.6531458"
    },
    {
        "Id": 833,
        "Name": "APARTHOTEL OCEAN RESORT",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Avenida del Atlantico s/n Urbanizacion Golf del Sur, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.024179,-16.607352"
    },
    {
        "Id": 834,
        "Name": "Atlantic Resort & Villas",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Venezuela, 1, 38639 Las Chafiras, Santa Cruz de Tenerife",
        "Coordinates": "28.04673920978561,-16.61308228969574"
    },
    {
        "Id": 835,
        "Name": "Bahía fañabe suites & Labranda Villas",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urbanizacion Playa Fañabe, 16, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0882,-16.7342"
    },
    {
        "Id": 836,
        "Name": "Beverly Hills Club.",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Rodeo, 9, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.051881,-16.705082"
    },
    {
        "Id": 837,
        "Name": "Beverly Heights.",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Sierra Nevada, nº 1 Urbanización Oasis del Sur, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0511829,-16.7028579"
    },
    {
        "Id": 838,
        "Name": "Borinquen Apartamentos ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Venezuela, nº14, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0720606,-16.7289707"
    },
    {
        "Id": 839,
        "Name": "Carlomar ",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Calle Isla de el Hierro, 55, 38687 Guía de Isora, Santa Cruz de Tenerife",
        "Coordinates": "28.18023688260346,-16.817671366024594"
    },
    {
        "Id": 840,
        "Name": "Chayofa Country Club ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Urbanización Chayofa, Calle El Morro, 2, 38652 Arona, Santa Cruz de Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0741211,-16.6920039"
    },
    {
        "Id": 841,
        "Name": "Club La Mar",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Marítima Puerto de Santiago, 25, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2333889,-16.8408994"
    },
    {
        "Id": 842,
        "Name": "COLON II",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig, 12, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0613889,-16.7311111"
    },
    {
        "Id": 843,
        "Name": "Colon Rambla ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Viera y Clavijo, 49, 38004 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4712024,-16.2569814"
    },
    {
        "Id": 844,
        "Name": "Dinastia",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. San Francisco, 10, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0545994,-16.7054375"
    },
    {
        "Id": 845,
        "Name": "El Dorado",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Santiago Puig, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.061603,-16.7285288"
    },
    {
        "Id": 846,
        "Name": "ESMERALDA PLAYA HOTEL",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 8, 38660 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0661312,-16.7318515"
    },
    {
        "Id": 847,
        "Name": "hollywood mirage - The Suites at the Mirage.",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Sierra Nevada, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0520978,-16.7033295"
    },
    {
        "Id": 848,
        "Name": "Hotel Aeropuerto Sur ",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Calle Benchijigua, 04, 38611 San Isidro, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0730385,-16.5558176"
    },
    {
        "Id": 849,
        "Name": "Hotel Tejuma ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Perez Zamora, 49, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4134466,-16.5511737"
    },
    {
        "Id": 850,
        "Name": "Hotel Tropical ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Puerto Viejo, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4161985,-16.5512669"
    },
    {
        "Id": 851,
        "Name": "Hotel Vallemar ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. de Cristobal Colón, 4, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4180007,-16.5442788"
    },
    {
        "Id": 852,
        "Name": "Puerto Azul ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle del Lomo, 24, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4171255,-16.5530766"
    },
    {
        "Id": 853,
        "Name": "Hotel Dunas Paraiso ",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle El Horno, 35, Urbanizacion, 38678 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1235426,-16.778392"
    },
    {
        "Id": 854,
        "Name": "Los claveles ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Andorra, 2, Los Cristianos, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0558223,-16.7072438"
    },
    {
        "Id": 855,
        "Name": "MYND Adeje",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Jable, 36, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.13067716239145,-16.78063344244907"
    },
    {
        "Id": 856,
        "Name": "Paloma Beach Apartments",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 43, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0446629,-16.7092371"
    },
    {
        "Id": 857,
        "Name": "Parque Margarita ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Boston, 1, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0527367,-16.7094453"
    },
    {
        "Id": 858,
        "Name": "Parque Santiago I & II ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 2, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.058764424435257,-16.73359795606603"
    },
    {
        "Id": 859,
        "Name": "Poblado Marinero ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Poblado Marinero, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2466701,-16.8421413"
    },
    {
        "Id": 860,
        "Name": "Ritz Resorts Tenerife",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Carretera General, Tf-47, Km 9, 38687 Guía de Isora, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.17104,-16.7993562"
    },
    {
        "Id": 861,
        "Name": "Royal Palm.",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Rodeo, 6, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0509516,-16.7048434"
    },
    {
        "Id": 862,
        "Name": "Sunset Apartamentos",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Antonio Navarro, Urb. Los Atamanes, 1, 38660 Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0834267,-16.7313509"
    },
    {
        "Id": 863,
        "Name": "Tenerife Royals Garden",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av, Calle Luis Diaz de Losada, 5, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0530734,-16.7290707"
    },
    {
        "Id": 864,
        "Name": "Victoria Court I",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Asomada del Morisco, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.048322,-16.708934"
    },
    {
        "Id": 865,
        "Name": "Victoria Court II",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Asomada del Morisco, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.048322,-16.708934"
    },
    {
        "Id": 866,
        "Name": "villas las flores ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Avenida Eugenio Dominguez Alfonso, 4 Planta Superior, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.074095,-16.7301887"
    },
    {
        "Id": 867,
        "Name": "Viña del Mar",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "38660, Calle Guatemala, 4, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.073231,-16.729404"
    },
    {
        "Id": 868,
        "Name": "Wimpen las Rosas ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle las Rosas, 2, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2411615,-16.8406138"
    },
    {
        "Id": 869,
        "Name": "YUCCA PARK",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Londres, 8, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0879116,-16.7349942"
    },
    {
        "Id": 870,
        "Name": "Bahia Colon ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle la Paloma, 3, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0514629,-16.718705"
    },
    {
        "Id": 871,
        "Name": "Westhaven Bay",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle de la Te, 45, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0053461,-16.6465102"
    },
    {
        "Id": 873,
        "Name": "Solmar ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Leandra Los Christianos, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0509732,-16.7196461"
    },
    {
        "Id": 874,
        "Name": "CASTALIA LOS BREZOS",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0879708,-16.7332335"
    },
    {
        "Id": 875,
        "Name": "Julio Cesar Mare Nostrum",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, s/n, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0541667,-16.7297222"
    },
    {
        "Id": 876,
        "Name": "Holidays Aptos ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. las Américas, 10, BAJO;LOCAL-16, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0532103,-16.7271093"
    },
    {
        "Id": 877,
        "Name": "Harbour Club 1/2 ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Jose Gonzalez Forte, 41, 38683 Santiago del Teide, Santa Cruz de Tenerife",
        "Coordinates": "28.242722255267093,-16.8396458029747"
    },
    {
        "Id": 878,
        "Name": "Aptos Buenavista y Buenavista Park ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "No., Calle el Pino, 44, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2473083,-16.8395066"
    },
    {
        "Id": 879,
        "Name": "AapartmentelMedano El Medano Tenerife",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Centro Tama, Av. Príncipes de España, 3, Local 5, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.04573,-16.53859"
    },
    {
        "Id": 880,
        "Name": "Parque Cardon ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4045558,-16.5495129"
    },
    {
        "Id": 881,
        "Name": "Fairways Club",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Avenida Mª Ángeles Ascanio Cullen, 38639 San Miguel de Abona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0237971,-16.6171045"
    },
    {
        "Id": 882,
        "Name": "Aptos. El Balcon de los Gigantes ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Flor de Pascua, 2, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2445242,-16.8408092"
    },
    {
        "Id": 883,
        "Name": "Hibiscos 1 - Sport Center Gigantes",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle el Hibisco, 1, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2423171,-16.8389803"
    },
    {
        "Id": 884,
        "Name": "Hotel Atlantic View",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Hespérides, 1, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.005197,-16.642499"
    },
    {
        "Id": 885,
        "Name": "Belair",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Punta de la Carretera, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4144822,-16.5480012"
    },
    {
        "Id": 886,
        "Name": "Club Tarahal",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 49, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4157768,-16.5320386"
    },
    {
        "Id": 887,
        "Name": "Apartamentos Atlantico 1 No 5",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Tenerife, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2915637,-16.6291304"
    },
    {
        "Id": 888,
        "Name": "Wimpen sueño azul CallaoSalvaje",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle Duarte, S/N, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.132043,-16.78136"
    },
    {
        "Id": 889,
        "Name": "Apartamentos Altamar",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Avenida de Moscú CC, AquaMall, local 27, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0923066,-16.7325215"
    },
    {
        "Id": 890,
        "Name": "APTS. MARE VERDE MALUCA",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.086017,-16.7316486"
    },
    {
        "Id": 891,
        "Name": "Cactus ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Camino Samarines, 4, 38500 Güímar, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3074297,-16.3845879"
    },
    {
        "Id": 892,
        "Name": "CASA LAS FLORES ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Avenida Eugenio Dominguez Alfonso, 4 Planta Superior, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.074095,-16.7301887"
    },
    {
        "Id": 893,
        "Name": "Chayofita",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. de Chayofita, 38650, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.056819,-16.7172809"
    },
    {
        "Id": 894,
        "Name": "Club Paraiso Apts ",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Adeje 300, 10, piso 9 ap.910, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.12246,-16.77504"
    },
    {
        "Id": 895,
        "Name": "Club Tenerife Los Diamantes II",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Los Angeles, N.4, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0536897,-16.7042996"
    },
    {
        "Id": 896,
        "Name": "Colina Park ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Los Angeles, 6, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0528485,-16.704466"
    },
    {
        "Id": 897,
        "Name": "Hotel Don Candido ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle de Portugal, 10, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4103974,-16.5459592"
    },
    {
        "Id": 898,
        "Name": "Hotel Infinity Blues Hotels ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Avenida Adeje 300, Av. Playa Paraíso, s/n, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1210533,-16.7756664"
    },
    {
        "Id": 899,
        "Name": "Lavaggi Hotel",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Familia de Betancourt y Molina, 8,  38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.415693075867026,-16.54635789788745"
    },
    {
        "Id": 900,
        "Name": "Ocean Boulevard Apts.",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.031536,-16.6076073"
    },
    {
        "Id": 901,
        "Name": "OCEAN PARK",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Ocean Park, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0812074,-16.730064"
    },
    {
        "Id": 902,
        "Name": "Optimist Hote - Transfers",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "C. California, 13, 38660 Arona, Santa Cruz de Tenerife, España",
        "Coordinates": "28.057859662177865,-16.730935683802347"
    },
    {
        "Id": 903,
        "Name": "ORLANDO APTS.",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Antonio Navarro, 4, 38660 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.082679,-16.7315212"
    },
    {
        "Id": 904,
        "Name": "Port Royal Los Cristianos",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Sierra Nevada, 4, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0514854,-16.703627"
    },
    {
        "Id": 905,
        "Name": "Sur Y Sol",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Ctra. de las Cañadas del Teide, 38300 La Orotava, Santa Cruz de Tenerife, España",
        "Coordinates": "28.2613049,-16.5959903"
    },
    {
        "Id": 907,
        "Name": "TERRAZAS DEL DUQUE",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Helsinki, 16, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0956005,-16.7368991"
    },
    {
        "Id": 908,
        "Name": "Atlas Suites Tenerife",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. San Francisco, 1, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0514929,-16.7078916"
    },
    {
        "Id": 909,
        "Name": "The Suites at Beverly hills",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Rodeo, 9, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.051881,-16.705082"
    },
    {
        "Id": 910,
        "Name": "Villa Mandi Golf Resort",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Laderas del Espejo, 7, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0613258,-16.7156899"
    },
    {
        "Id": 911,
        "Name": "VILLAMAR ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Lugar Urbanizacion San Eugenio, 14D, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0747188,-16.733063"
    },
    {
        "Id": 912,
        "Name": "Villas Sun Bay",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Cornical, 9, 38630 Amarilla Golf, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0209018,-16.6174813"
    },
    {
        "Id": 913,
        "Name": "Callao Sports Hotel ",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Jable, 3, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.1335743,-16.7708927"
    },
    {
        "Id": 914,
        "Name": "Pinehurst Apartaments",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "av maria de los angles ascanio Cullen, 38629 San Miguel de abona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.027378,-16.612456"
    },
    {
        "Id": 915,
        "Name": "Duquesa del mar Apartaments",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. del Atlàntico, 12, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0247845,-16.6094821"
    },
    {
        "Id": 916,
        "Name": "Appartement Magallanes Complex",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "4 Av. José Miguel Galván Bello",
        "Coordinates": "28.04355089841271,-16.53545119750181"
    },
    {
        "Id": 917,
        "Name": "Terrazas de la Paz",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, 11, 38639 Oasis del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.027912,-16.60338"
    },
    {
        "Id": 918,
        "Name": "Strelitzia Park I ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Lucio Diaz Flores Feo, 24, 38639 Aldea Blanca, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.05498,-16.621954"
    },
    {
        "Id": 919,
        "Name": "Adelfas 2 ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle los Cardones, 204, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0318355,-16.6103307"
    },
    {
        "Id": 920,
        "Name": "Adelfas 1",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Montaña Amarilla, 0, 38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0092523,-16.639944"
    },
    {
        "Id": 921,
        "Name": "Los Tajinastes ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "38660 tenerife, Av. Santiago Puig, 7, 38660 Arona (Tenerife), Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.061854,-16.727498"
    },
    {
        "Id": 922,
        "Name": "Valle Luz ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. Melchor Luz, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4139886,-16.5562664"
    },
    {
        "Id": 923,
        "Name": "Club el Beril ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle el Beril, 19, 38679 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.094210462974733,-16.744993329048157"
    },
    {
        "Id": 924,
        "Name": "Tagara Apartaments  ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Blanco, 5, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4157842,-16.5502778"
    },
    {
        "Id": 925,
        "Name": "OROVERDE APPARTMENTS ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Ernesto Sarti, 14, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0856365,-16.7313198"
    },
    {
        "Id": 926,
        "Name": "Tamara Aptos ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "CALLE TAMARA, Nº4",
        "Coordinates": "28.245703254118386,-16.84113949573514"
    },
    {
        "Id": 927,
        "Name": "Aptos.Paraiso Del Sur ",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "38678 Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.122460071378534,-16.775040104985237"
    },
    {
        "Id": 929,
        "Name": "136 Pueblo Primavera",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Pueblo Primavera, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0256,-16.6084"
    },
    {
        "Id": 930,
        "Name": "Vera Mar",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. la Habana, 13, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0520124,-16.7224276"
    },
    {
        "Id": 931,
        "Name": "Casa Club Clansani Tenerife",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0312332,-16.6185158"
    },
    {
        "Id": 932,
        "Name": "Medusa ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Antonio Dominguez, 15, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0562956,-16.7247279"
    },
    {
        "Id": 933,
        "Name": "Vanila Garden ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Arquitecto Gómez Cuesta, 5, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0663708,-16.7293109"
    },
    {
        "Id": 934,
        "Name": "Coral Mar Vacances",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "CC Coral Mar, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0116479,-16.6408064"
    },
    {
        "Id": 935,
        "Name": "Torres del Sol ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. de Suecia, 8, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0541943,-16.725864"
    },
    {
        "Id": 936,
        "Name": "Riviera ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Marítima Puerto de Santiago, 18, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2317875,-16.8415333"
    },
    {
        "Id": 937,
        "Name": "Torres de Yomely ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "TORRES DE YOMELY, Av. Arquitecto Gómez Cuesta, 4, 10D, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.06601732918704,-16.73033521215806"
    },
    {
        "Id": 938,
        "Name": "El Mirador Apartementos ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. San Francisco, 1102, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0514565,-16.7070616"
    },
    {
        "Id": 939,
        "Name": "WINDSOR PARK APTS",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle País Vasco, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0842016,-16.7242049"
    },
    {
        "Id": 940,
        "Name": "Diamantes I",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Manuel Yanes Barreto, s/n, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4084091,-16.5566311"
    },
    {
        "Id": 941,
        "Name": "San Marino Aptos.",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0534864,-16.7116911"
    },
    {
        "Id": 942,
        "Name": "La Hacienda",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. de Bruselas, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0910021,-16.7422503"
    },
    {
        "Id": 943,
        "Name": "Regency Torviscas APTOS Suites",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Cataluña, 2, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.085669,-16.725814"
    },
    {
        "Id": 944,
        "Name": "Marazul",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "38678 Marazul, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1458883,-16.7923971"
    },
    {
        "Id": 945,
        "Name": "Balcon del Mar ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "C de la Te",
        "Coordinates": "28.0065677941183,-16.639668429262173"
    },
    {
        "Id": 946,
        "Name": "Casa las Cupulas",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Marítima Puerto de Santiago, 34, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2281519,-16.8407664"
    },
    {
        "Id": 947,
        "Name": "Gigansol ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Petunia, 3, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2402426,-16.8391526"
    },
    {
        "Id": 948,
        "Name": "Terrazas de los Gigantes ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Jose Gonzalez Forte, 71, 38683 Acantilados de Los Gigantes, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2413911,-16.8372427"
    },
    {
        "Id": 949,
        "Name": "Sansofe Puerto",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle la Hondura, 16, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2381241,-16.8432787"
    },
    {
        "Id": 950,
        "Name": "SANTIAGO BEACH ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle la Iglesia, 8, 38683 Santiago del Teide, Santa Cruz de Tenerife",
        "Coordinates": "28.237106689113766,-16.842038333415985"
    },
    {
        "Id": 951,
        "Name": "WinterGardens ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. del Atlàntico, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0248138,-16.6097822"
    },
    {
        "Id": 952,
        "Name": "Ona Sueño Azul",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle Duarte, S/N, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.132043,-16.78136"
    },
    {
        "Id": 953,
        "Name": "Apartamentos Europa",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Marítima Puerto de Santiago, 4, 38683 Santiago del Teide, Santa Cruz de Tenerife",
        "Coordinates": "28.233263169962584,-16.84127390384674"
    },
    {
        "Id": 954,
        "Name": "Hacienda Cristoforo",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle El Horno, 10, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.130112,-16.768124"
    },
    {
        "Id": 955,
        "Name": "ENDLESS SUMMER HOUSE",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Austria, 26, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0776894,-16.7280586"
    },
    {
        "Id": 956,
        "Name": "Hotel Los Principes  ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Plaza Doctor Víctor Pérez, 0, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.41681,-16.55027"
    },
    {
        "Id": 957,
        "Name": "Apartamentos Lago",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Phone, Av. Quinto Centenario, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2381432,-16.8423088"
    },
    {
        "Id": 958,
        "Name": "Aptos. Los Angeles",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. la Habana, 7, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0512904,-16.7212067"
    },
    {
        "Id": 959,
        "Name": "Apts. Ikarus ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Pitera, 5, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4159804,-16.5391599"
    },
    {
        "Id": 960,
        "Name": "Aptos. Chaparral ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. Jose Antonio Tavio, 4, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.011231,-16.6463416"
    },
    {
        "Id": 961,
        "Name": "Aptos. Achacay ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Carlos I, 38650 Los Cristianos, Santa Cruz de Tenerife",
        "Coordinates": "28.051793109979585,-16.713756322860718"
    },
    {
        "Id": 962,
        "Name": "Ocean azahara Playa ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. la Habana, 14B, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0547975,-16.7241759"
    },
    {
        "Id": 963,
        "Name": "Arena Suites ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Antonio González Barrios, 17A, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2337306,-16.8402503"
    },
    {
        "Id": 964,
        "Name": "Playa Honda II",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "38660 Playa de la Américas, Santa Cruz de Tenerife",
        "Coordinates": "28.05942677110312,-16.729264855384827"
    },
    {
        "Id": 965,
        "Name": "Apartamentos Chipeque",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Rodeo, 7, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0530105,-16.7054257"
    },
    {
        "Id": 967,
        "Name": "Oasis los Gigantes ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Av. Jose Gonzalez Forte, 7, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2449352,-16.8424534"
    },
    {
        "Id": 969,
        "Name": "BENIMAR ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Reykjavik, 22, 38679 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.093699367681893,-16.73215091228485"
    },
    {
        "Id": 970,
        "Name": "Villas La Quinta",
        "Island": "TF",
        "ZoneId": 223,
        "ZoneName": "Puerto de la Cruz 2",
        "Address": "Calle Violeta del Teide, 39, 38390 La Quinta, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4312398,-16.4961451"
    },
    {
        "Id": 971,
        "Name": "Apartamentos Cornisa REVISAR PUNTO DE ENCUENTRO",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Nayra, 154, 35120 Mogán, Las Palmas, España",
        "Coordinates": "27.7668753,-15.6827595"
    },
    {
        "Id": 972,
        "Name": "Aptos.El Cardon ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Finlandia, 3, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0752188,-16.7277251"
    },
    {
        "Id": 974,
        "Name": "The Palms ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, 38639 Oasis del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.032298,-16.6106898"
    },
    {
        "Id": 975,
        "Name": "Puerto Colon",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Colón, S/N, 38660 Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.077414029888295,-16.735857725143433"
    },
    {
        "Id": 976,
        "Name": "Parque Santiago V ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Avenida del Litoral, s/n, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0555005,-16.7350955"
    },
    {
        "Id": 977,
        "Name": "Parque De Las Flores",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Parque de las Flores, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4054915,-16.552212"
    },
    {
        "Id": 978,
        "Name": "Ocean Golf And Country Club",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, 38639 Oasis del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0274119,-16.606596"
    },
    {
        "Id": 979,
        "Name": "Melia Hacienda Del Conde ",
        "Island": "TF",
        "ZoneId": 228,
        "ZoneName": "Buenavista ",
        "Address": "Calle La Finca, s/n, 38480 Buenavista, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3694345,-16.8589076"
    },
    {
        "Id": 980,
        "Name": "Golf Park Resort",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, 165, 38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0273411,-16.6073509"
    },
    {
        "Id": 981,
        "Name": "Fiesta Oasis Paraiso ",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Playa Paraíso, 0, 38678, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1214396,-16.7677459"
    },
    {
        "Id": 982,
        "Name": "Fairway Village ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "38639 Golf del Sur, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.031536,-16.6076073"
    },
    {
        "Id": 983,
        "Name": "Costa Adeje Garden",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Europa, 29, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0813111,-16.7236597"
    },
    {
        "Id": 984,
        "Name": "Ten Bel Alborada",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Av. Jose Antonio Tavio, 0, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0117926,-16.6470065"
    },
    {
        "Id": 985,
        "Name": "Royal Marina Golf",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Flor de Lis, s/n, 38639 Amarilla Golf, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0228092,-16.6150403"
    },
    {
        "Id": 986,
        "Name": "Chitamar ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Avenida Jose Antonio Tavio 37G5, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0113673,-16.6423746"
    },
    {
        "Id": 987,
        "Name": "Tagoro Park ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Ulises, n23, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0068565,-16.6438047"
    },
    {
        "Id": 988,
        "Name": "Studio Bahama ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Venezuela, 7, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0707076,-16.7294786"
    },
    {
        "Id": 989,
        "Name": "Casa Grande Surf Hostel ",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "C. Galo Ponte, 7, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.04439,-16.537588"
    },
    {
        "Id": 990,
        "Name": "Cris-Sol Aptos ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Leandra Los Christianos, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0509732,-16.7196461"
    },
    {
        "Id": 991,
        "Name": "Aparthotel Marina Palace",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Calle El Horno, 38660 Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.125846307320682,-16.77312433719635"
    },
    {
        "Id": 993,
        "Name": "La Tortuga Hostel",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Reykjavik, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0943954,-16.7320208"
    },
    {
        "Id": 994,
        "Name": "Apartamentos Jardines del Mar",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "38683 Barranco de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2357378,-16.8406311"
    },
    {
        "Id": 995,
        "Name": "Balcón del Atlántico",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Asturias, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0895099,-16.7209007"
    },
    {
        "Id": 996,
        "Name": "Durazno Sur ",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Av. Príncipes de España, 1, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0452965,-16.5386514"
    },
    {
        "Id": 997,
        "Name": "Hotel Parque Verde",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "jose miguel galvan bello, 38620, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.029767,-16.611563"
    },
    {
        "Id": 998,
        "Name": "Reina Dunas",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "8,, Calle Girasol, 7, 38612 Arenas del Mar, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0560226,-16.5247702"
    },
    {
        "Id": 999,
        "Name": "Marco Antonio Mare Nostrum",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, s/n, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0541667,-16.7297222"
    },
    {
        "Id": 1000,
        "Name": "Marineda Apartamento",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Calle La Graciosa, 2, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.044716,-16.5346998"
    },
    {
        "Id": 1001,
        "Name": "Canaima",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Fernando Beautell, 25, 38009 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4485246,-16.2767852"
    },
    {
        "Id": 1002,
        "Name": "Las Barandas Apto ",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle la Lava, 12, 38678 Callao Salvaje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1272694,-16.7829861"
    },
    {
        "Id": 1003,
        "Name": "Residencial Adeje Paradise",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Adeje 300, 30, 38678 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.121567,-16.7704504"
    },
    {
        "Id": 1004,
        "Name": "Playa Graciosa III ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Calle Chalana, 38650 Arona, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0485013,-16.7078911"
    },
    {
        "Id": 1005,
        "Name": "Parque Tajinaste Apartamentos ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Retama, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4119888,-16.5345573"
    },
    {
        "Id": 1006,
        "Name": "Salamandra",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calz. Martianez, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4135359,-16.539341"
    },
    {
        "Id": 1007,
        "Name": "Aloha garden",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Madrid, 13, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0847939,-16.7240506"
    },
    {
        "Id": 1008,
        "Name": "The Blue Corner ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Carr. Botánico, 31A, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4129982,-16.5443085"
    },
    {
        "Id": 1009,
        "Name": "Route Active Hotel ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle la Longuera, s/n, 38418 Los Realejos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.3961216,-16.5819488"
    },
    {
        "Id": 1010,
        "Name": "Pebble Beach ",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Calle Tarajal, 38639 Amarilla Golf, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0233254,-16.6170334"
    },
    {
        "Id": 1011,
        "Name": "Seguros el Sol Aptos ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Manuel Ravelo, 2, 38683 Puerto de Santiago, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2308434,-16.8398821"
    },
    {
        "Id": 1012,
        "Name": "Pension Alcala ",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Calle Marruecos, 2, 38686 Alcalá, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.20261,-16.830427"
    },
    {
        "Id": 1013,
        "Name": "Costa Bella ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Ceuta, 1, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0964799,-16.7098158"
    },
    {
        "Id": 1014,
        "Name": "Sunflowers Aptos ",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Alpispa, 3, 38687 Guía de Isora, Santa Cruz de Tenerife",
        "Coordinates": "28.223461746765384,-16.837222427129745"
    },
    {
        "Id": 1015,
        "Name": "Guayero Apartamentos",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Juan Alfonso Batista, 14, 38650 Los Cristianos, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0497603,-16.7132214"
    },
    {
        "Id": 1016,
        "Name": "Los Corales ",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Av. Virgen de Guadalupe, 21, 38679 Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1001618,-16.7521297"
    },
    {
        "Id": 1017,
        "Name": "La Calavera Guesthouse Medano ",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Av. José Miguel Galván Bello, 9, 38612 El Médano, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.04458,-16.5366"
    },
    {
        "Id": 1018,
        "Name": "Aptos La Casa Verde ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Ctra. General Puerto Cruz - Arenas, 94, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4010715,-16.5508232"
    },
    {
        "Id": 1019,
        "Name": "Muelle de las galletas ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Torre de control, 2ª planta, 38631 Las Galletas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.006962,-16.661109"
    },
    {
        "Id": 1020,
        "Name": "Olivia Sunset Apartamentos",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle Caleta del Jurado, 13, 38683 Santiago del Teide, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.2357707,-16.8397973"
    },
    {
        "Id": 1021,
        "Name": "Rocas del Mar ",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "Calle Zeus, 6, 38630 Costa del Silencio, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0046711,-16.6457697"
    },
    {
        "Id": 1022,
        "Name": " Coral Ocean View / Bonanza",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. de los Pueblos, 38660 Playa de la Américas, Santa Cruz de Tenerife",
        "Coordinates": "28.071073864811293,-16.729238033294678"
    },
    {
        "Id": 1024,
        "Name": "Las Terrazas de Abama",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Las Terrazas de Abama, Carretera General del Sur, TF-47, km 8,9, 38687, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1696329,-16.7917176"
    },
    {
        "Id": 1025,
        "Name": "Bonanza Palace",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Aceviño, 6, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4148807,-16.5357944"
    },
    {
        "Id": 1026,
        "Name": "Villa del Mar Aptos. ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "38660, Calle Guatemala, 4, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.073231,-16.729404"
    },
    {
        "Id": 1027,
        "Name": "Acapulco Aptos",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Guatemala, 4, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0724811,-16.7306301"
    },
    {
        "Id": 1028,
        "Name": "Sueño Azul Beach Club",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle Isora, 15, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.13364,-16.78603"
    },
    {
        "Id": 1029,
        "Name": "Oro Negro Pto. Cruz ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Av. de Cristobal Colón, 24, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4165952,-16.5408164"
    },
    {
        "Id": 1030,
        "Name": "Happy Fish ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "C.C Pueblo Canario, Av. Eugenio Dominguez Alfonso, s/n, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0733673,-16.732174"
    },
    {
        "Id": 1031,
        "Name": "Casa Mafalda ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "38270 Valle de Guerra, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.5178767,-16.3900695"
    },
    {
        "Id": 1032,
        "Name": "Playa Veronica Aptos ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. las Américas, 10, BAJO;LOCAL-16, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0532103,-16.7271093"
    },
    {
        "Id": 1033,
        "Name": "HACIENDAS IV ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Dinamarca, 7, 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.080516,-16.725004"
    },
    {
        "Id": 1034,
        "Name": "LOS NARANJOS ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Suecia, 2, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0769809,-16.7261706"
    },
    {
        "Id": 1035,
        "Name": "Oasis Tropical ",
        "Island": "TF",
        "ZoneId": 207,
        "ZoneName": "Callao Salvaje",
        "Address": "Calle el Mojón, 22, 38678 Callao Salvaje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.130958,-16.7735845"
    },
    {
        "Id": 1036,
        "Name": "Coral Dreams ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Argentina, 38660 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0683564,-16.7292342"
    },
    {
        "Id": 1037,
        "Name": "Aptos Brujas ",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Av. de Bélgica, 11, 38007 Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4650046,-16.2668909"
    },
    {
        "Id": 1038,
        "Name": "Royal Hideaway Corales Beach",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Avenida Virgen de Guadalupe, Playa La Enramada, 23, 38679 La Caleta, Santa Cruz de Tenerife",
        "Coordinates": "28.101467280266366,-16.753544211387634"
    },
    {
        "Id": 1039,
        "Name": "Royal Hideaway Corales Suites",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Playa La Enramada, Av. Virgen de Guadalupe, 21, 38679 La Caleta, Santa Cruz de Tenerife",
        "Coordinates": "28.100170695512038,-16.752136051654816"
    },
    {
        "Id": 1040,
        "Name": "Dzamlingar ",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Urbr. Playa Paraíso, Av. Adeje 300, 38, 38678, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1223161,-16.7681581"
    },
    {
        "Id": 1041,
        "Name": "GF Victoria",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Fernando Lopez Arvelo, 1, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0917871,-16.7387218"
    },
    {
        "Id": 1042,
        "Name": "My City Hotel ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Nº 13, Calle Blanco, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4150652,-16.5500172"
    },
    {
        "Id": 1043,
        "Name": "Fantasia Bahia Principe Tenerife",
        "Island": "TF",
        "ZoneId": 215,
        "ZoneName": "Golf Del Sur",
        "Address": "Av. J.M. Galván Bello, S/N, 38639, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0269048,-16.6062647"
    },
    {
        "Id": 1044,
        "Name": "Oasis 7 Fañabe",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "El Peralillo 3 (puerta 7), 38660 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0927838,-16.7278397"
    },
    {
        "Id": 1045,
        "Name": "Gara Suite",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Landa Golf, 2, 38650 Playa de la Américas, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.0635104,-16.7217184"
    },
    {
        "Id": 1047,
        "Name": "Laguna Gran Hotel ",
        "Island": "TF",
        "ZoneId": 221,
        "ZoneName": "La Laguna",
        "Address": "Calle Nava y Grimon, 18, 38201 San Cristóbal de La Laguna, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.4892164,-16.3132684"
    },
    {
        "Id": 1048,
        "Name": "H10 Atlantic Sunset",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "Av. Adeje 300, s/n, 38678, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.119640905968847,-16.773243067502037"
    },
    {
        "Id": 1049,
        "Name": "Yucca Villas ",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Urbanizacion Playa Fañabe, 36, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.08943,-16.72793"
    },
    {
        "Id": 1050,
        "Name": "Royal River Luxury ",
        "Island": "TF",
        "ZoneId": 222,
        "ZoneName": "Caletas",
        "Address": "Calle Alcojora, s/n, 38670 Costa Adeje, Santa Cruz de Tenerife, Spain",
        "Coordinates": "28.1105757,-16.7498743"
    },
    {
        "Id": 1053,
        "Name": "EN LA GENERAL de la calle Madrid",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Calle Madrid, 4, 38660 Costa Adeje, Santa Cruz de Tenerife",
        "Coordinates": "28.086112, -16.725707"
    },
    {
        "Id": 1056,
        "Name": "Oficina de correos Calle la Hondura",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "Calle la Hondura, 2-4, 38683 Santiago del Teide, Santa Cruz de Tenerife",
        "Coordinates": "28.239968, -16.840471"
    },
    {
        "Id": 1060,
        "Name": "Parroquia San Amaro La Paz",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Leopoldo Cólogan Zulueta, 7-3, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.415308, -16.539277"
    },
    {
        "Id": 1105,
        "Name": "Bocacalle de entrada Calle Portugal",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle de Portugal, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.410320, -16.545833"
    },
    {
        "Id": 1111,
        "Name": "Iglesia San Amaro",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle Castaño, 1, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.415263, -16.539407"
    },
    {
        "Id": 1115,
        "Name": "Sunwing Resort and Spa Fanabe",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "C/ Londres 4 38660 Playa de Fañabé 38660 Adeje Santa Cruz de Tenerife",
        "Coordinates": "28.0858333,-16.7341667"
    },
    {
        "Id": 2275,
        "Name": "P. esq. C/ Irlanda con avda. Europa",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "",
        "Coordinates": "28.0813333333333,-16.72275"
    },
    {
        "Id": 2403,
        "Name": "AC Hotel Tenerife",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "Calle Candelaria, 31, 38003 Santa Cruz de Tenerife, España",
        "Coordinates": "28.466359,-16.2485938"
    },
    {
        "Id": 2404,
        "Name": "Marina",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "Calle San Juan, 2, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.41717,-16.5502"
    },
    {
        "Id": 2405,
        "Name": "Olympia Aptos.",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Calle Venezuela, 12, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0715136,-16.7292756"
    },
    {
        "Id": 2424,
        "Name": "Plaza del Medano",
        "Island": "TF",
        "ZoneId": 211,
        "ZoneName": "Medano",
        "Address": "Calle Evaristo Gómez González, 1, 38612 El Médano, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0444651,-16.5388958"
    },
    {
        "Id": 2426,
        "Name": "Parque Carolina",
        "Island": "TF",
        "ZoneId": 202,
        "ZoneName": "Tembel/Costa del Silencio",
        "Address": "C de la Te, 1, 38630 Costa del Silencio, Santa Cruz de Tenerife, España",
        "Coordinates": "28.00739,-16.6417823"
    },
    {
        "Id": 2438,
        "Name": "Bungalows Las Piteras",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Rafael Puig Lluvina, 11, 38650 Arona, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0641,-16.73065"
    },
    {
        "Id": 2447,
        "Name": "HOTEL B. UNIQUE",
        "Island": "TF",
        "ZoneId": 208,
        "ZoneName": "Playa San Juan/Abama",
        "Address": "Av. Reina Sofía, 2B, 38687 Playa San Juan, Santa Cruz de Tenerife, España",
        "Coordinates": "28.18307,-16.81652"
    },
    {
        "Id": 2466,
        "Name": "Ocean Garden Playa Paraiso",
        "Island": "TF",
        "ZoneId": 206,
        "ZoneName": "Playa Paraiso",
        "Address": "C. Aljibe, 9, 38678 Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.123778631217622,-16.77392577313998"
    },
    {
        "Id": 2467,
        "Name": "Colina Blanca - Apartment Indian",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "C. Irlanda, 4, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.08211021163872,-16.723136839159885"
    },
    {
        "Id": 2469,
        "Name": "Hapimag Hotel ",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "C. Bélgica, 31, 38400 Puerto de la Cruz, Santa Cruz de Tenerife",
        "Coordinates": "28.410771659658117,-16.544996215454457"
    },
    {
        "Id": 2479,
        "Name": "Hotel Club Atlantis",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. de Colón, 0, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.07788159139384,-16.734035858911934"
    },
    {
        "Id": 2480,
        "Name": "Funchal Apartamentos",
        "Island": "TF",
        "ZoneId": 203,
        "ZoneName": "Los Cristianos",
        "Address": "Carr. Gral. Tf-655, 2, 38650 Los Cristianos, Santa Cruz de Tenerife, España",
        "Coordinates": "28.0528351,-16.7098215"
    },
    {
        "Id": 2489,
        "Name": "Marola Park ",
        "Island": "TF",
        "ZoneId": 204,
        "ZoneName": "Las Americas",
        "Address": "Av. Antonio Dominguez, 18, 38650 Arona, Santa Cruz de Tenerife, España",
        "Coordinates": "28.056798748076837,-16.72414696188327"
    },
    {
        "Id": 2524,
        "Name": "Haciendas II",
        "Island": "TF",
        "ZoneId": 205,
        "ZoneName": "Costa Adeje",
        "Address": "Av. Europa, 31, 38660 Costa Adeje, Santa Cruz de Tenerife, España",
        "Coordinates": "28.08151317597102,-16.724659456728354"
    },
    {
        "Id": 2527,
        "Name": "Urban Anaga Hotel",
        "Island": "TF",
        "ZoneId": 213,
        "ZoneName": "Santa Cruz",
        "Address": "C. Imeldo Serís, 19, 38003 Santa Cruz de Tenerife, España",
        "Coordinates": "28.46569447876801,-16.249257280482652"
    },
    {
        "Id": 2549,
        "Name": "Puerto Nest Hostel",
        "Island": "TF",
        "ZoneId": 214,
        "ZoneName": "Puerto de la Cruz 1",
        "Address": "C. Victor Machado, 12, 38400 Puerto de la Cruz, Santa Cruz de Tenerife, España",
        "Coordinates": "28.4107246,-16.5654473"
    },
    {
        "Id": 2569,
        "Name": " Crystal Luxury Apartments Los Gigantes",
        "Island": "TF",
        "ZoneId": 209,
        "ZoneName": "Gigantes",
        "Address": "C. Geranio, 8, 38683 Santiago del Teide, Santa Cruz de Tenerife, España",
        "Coordinates": "28.242434485457558,-16.837539672307912"
    }
]