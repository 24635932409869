import { FirebaseService 			} from '../database/firebase.service';
import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore			} from '@angular/fire/firestore';

@Injectable()
export class TransportDriverService {		
  	constructor(  
		private afs     	: AngularFirestore,
		private commons 	: CommonsService,
		private firebaseCtrl: FirebaseService
	){}

	/**
	 * Get the drivers from DMC given a  destination
	 * @param dmcId 
	 * @param destinationId 
	 */
	async getDrivers(dmcId,destinationId)							{
		let info 	= {};
		let drivers;

		try	{ 	
			drivers	=	await this.afs
							.collection('dmcs')
							.doc(dmcId)
							.collection('destinations')
							.doc(destinationId)
							.collection('drivers')
							.ref.get();
			
			drivers	=	drivers.docs.map(driver => {
							return { 
								ref : driver.ref, 
								...driver.data() 
							}
						});

			info	=  	{ 	success : true, 
							data 	: drivers
						}
		} catch(e){ 
			console.log(e); 
			info	=	{ success : false } 
		}
		return info;
	}	

	/**
	 * 
	 * @param userInfo 
	 * @param dmc 
	 * @param destination 
	 */
	async saveDriver(userInfo,dmc,destination)						{	
		// this.pageInfo.loadings['creatingUser'] = true;
		userInfo.profile		=	"driver";
		if(userInfo.info.id && !userInfo.info.isNew)	{	
			let updated = await this.firebaseCtrl.updateDriver({userInfo : userInfo}); 
			if(updated)	{this.commons.generateToast('_SUCCESS','_USER_UPDATED','success');}
			else		{this.commons.generateToast('_ERROR','_COULD_NOT_UPDATE','error');}
			// this.pageInfo.loadings['creatingUser'] = false;
		} else {
			delete userInfo.info.isNew;
			let message	= await this.firebaseCtrl.addDriverToCompany({ 	
				userInfo		:	userInfo, 
				idDmc 			:	dmc,
				idDestination 	:	destination
			});
			if(message.success)	{	await this.commons.generateToast('_SUCCESS','_USER_CREATED','success',2);		} 
			else 				{	await this.commons.generateToast('_ERROR','_USER_NOT_CREATED','error');			}			
			// this.pageInfo.loadings['creatingUser'] = false;
		}		
	}
		
	deleteDriver(refDriver):Promise<void>{		
		return this.afs.doc(refDriver).delete();	
	}
	
	async generateDriverCalendar($params)							{	
		const routes 	= 	this.afs.collection('dmcs').doc($params["dmcId"])
									.collection('destinations').doc($params["destinationId"])
									.collection('routes');
		return await routes.add($params["info"]);
	}																										
}