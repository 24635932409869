import { EntityService	} from './entity.service';
import { Injectable 	} from '@angular/core';
import { HttpClient  	} from '@angular/common/http';
import { flights        } from './../view/bookings/data/flights';
import { CommonsService } from './commons.service';

var fuzz 				= require('fuzzball');

@Injectable()
export class FlightService {

    flights         : any[]	    = [];
    airlines		: any 		= { aliases : {} };
    
    constructor( 
        private entityService   : EntityService, 
        private http            : HttpClient,
        private commons         : CommonsService
    ){
        this.loadAirlines();
        // this.loadFlights();
    }

    checkBooking($booking){
        return true;
    }

    /**
     * get user current destination airports
     * @returns
     */
    getUserDestinationAirports():any[]{
        const isocode		= 	this.commons.userInfo.currentDestination.isocode;
        const airports		=	this.commons.userInfo.currentDestination.airports;
        
        return airports || [ isocode ];
    }

    /**
     * load destination flights
     * params
     *  date            flight date
     *  destination     iso_code for destination
     */
	async loadFlights($params={}) {
		let response;
		this.clearFlights();
		
        // const flightDataOrigin:string = "File";
		const flightDataOrigin:string = "database";
		// const flightDataOrigin:string = "internet";

        // sanitize params
        $params["date"] = ($params["date"]||"").replaceAll('-','/');
        
		switch(flightDataOrigin){
			case "file"     :   this["flights"] = flights;

			default:
            case "database"	:	
                await this.entityService.loadEntity("flights", $params);
                this["flights"]	= this.entityService.get("flights");
                // Add file items for testing
                this["flights"]= [ ...this["flights"], ...flights];
                // this["flights"].forEach(item => this.setFlight(item));
                break;

			case "internet"	:	
                response		= await Promise.resolve(this.entityService.getComplexRequest("flightsBySkyScanner",{ destination:'pmi' }));
                this["flights"]	= 	[ 	
                    ...response.arrivals.map	(item=>{item.direction="arrival"; 	return item;}), 
                    ...response.departures.map	(item=>{item.direction="departure";	return item;}) 
                ];
                break;
		}

        // console.log('list flights', this.flights);
        return this.flights;
    }

    private async loadAirlines(){
		await this.entityService.loadEntity("airlines");
		this.airlines.data =this.entityService.get("airlines").map(item=>{
            this.airlines.aliases_keys = this.airlines.aliases_keys || [];
			((item.codes||"").split(',')||[]).forEach(alias=>{
				if(!alias || alias==""){ return; }
				this.airlines.aliases[alias] = item;
                this.airlines.aliases_keys.push(alias);
			});
			return item;
		});
        console.log("AIRLINES",this.airlines);
	}

    getFlights()       	{ return this.flights; 									}
    setFlight($flight)	{ this.flights.push($flight); 							}
    clearFlights() 		{ this.flights = [];                                  	}

    /**
     * check current flight against database flights
     * @param $flight 
     * @returns 
     */
    checkFlight($flight){
        $flight         = this.normalizeFlight($flight);
        let numberArray = $flight.match(/\d+/g);
        let number      = Array.isArray(numberArray)?numberArray[0]:null;
        let airline     = $flight.replace(/\d+/,'');
        
        if(number==null || airline==null){
            return {
                error       : true,
                type        : "_BAD_FLIGHT",
                errorCode   : "57"
            };
        }

        // Get airline isocode and rewrite flight with it
        const airlineCheck  = (this.airlines.aliases_keys || []).some(item=>item==airline);
        if(airlineCheck){
            let isoCode = this.airlines.aliases[airline].iso_code;
            $flight     = isoCode+number;
        }

        // Complete name search
        let flightCheck = (this.flights||[]).some(item=>item.flightNumber===$flight);

        // CHeck for aliases
        if(undefined!==this.commons.pageInfo.flights_aliases){
            flightCheck = flightCheck || ( undefined!=this.commons.pageInfo.flights_aliases[$flight] );
        }

        return {    
            error       : !flightCheck, 
            type        : !flightCheck ? '_BAD_FLIGHT'  : null, 
            errorCode   : !flightCheck ? '57'           : null
        };
    }

    private normalizeFlight($flight){ 
        return ($flight||"")
                .replaceAll('\-','')
                .replaceAll('\ ','')
                .toUpperCase();        
    }

    fixFlight(flight){
        console.log('flight to check', flight);

        let possibleFlights =   this.flights.filter( el => fuzz.ratio(flight,el.code) > 60)
        console.log(possibleFlights);
    }
}
