export const driversForm = [{
    cols		: 12,
    icon		: "fa fa-user",
    items		: [{
        cols		: 4,
        title		: "_INFO",
        showTitle	: false,						
        padding		: "1rem 1rem 1rem 1rem",
        items		: [
            { clean: true, flex: 10, mandatory: true, 	field	: 'active', 	label:'_ACTIVE',		type: 'text',	editor: 'toggle',		editable	: true				},
            { clean: true, flex: 10, mandatory: true, 	field	: 'name', 		label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
            { clean: true, flex: 10, mandatory: true, 	field	: 'email',		label:'_EMAIL',			type: 'text', 	editor: 'text', 		editable	: true				},
            { clean: true, flex: 10, mandatory: false, 	field	: 'vehicle',	label:'_VEHICLE',		type: 'text', 	editor: 'text', 		editable	: true				},
            { 
                clean		: true, 
                flex		: 10, 
                mandatory	: true, 	
                field		: 'turn',		
                label		: '_TURN',
                editor		: 'groupButton',
                type		:'free', 
                validators	: [{ type : 'minOneSelected'}],
                vertical    : true,
                items 		: [
                    { label : '_TURN_EARLY_MORNING', 	default : false,  	value: 'turn_early_morning', 	_icon : 'fa fa-fw fa-user' },
                    { label : '_TURN_MORNING', 	 		default : true,  	value: 'turn_morning', 			_icon : 'fa fa-fw fa-user' },
                    { label : '_TURN_AFTERNOON', 		default : false, 	value: 'turn_afternoon', 		_icon : 'fa fa-fw fa-users'},
                    { label : '_TURN_NIGHT', 		 	default : false,	value: 'turn_night', 			_icon : 'fa fa-fw fa-user' }
                ]
            },
            { clean: true, flex: 10, mandatory: false, 	field	: 'code', 		label:'_CODE',			type: 'text',	editor: 'text',			editable	: true				},
            { clean: true, flex: 10, mandatory: false, 	field	: 'idcard', 	label:'_IDCARD',		type: 'text',	editor: 'text',			editable	: true				}
        ]
    }									
    ,{
        cols		: 4,
        title		: "_INFO",
        showTitle	: false,						
        padding		: "1rem 1rem 1rem 1rem",
        items		: [
            { clean: true, flex: 10, mandatory: false, 	field	: 'location', 	label:'_LOCATION',		type: 'text',	editor: 'text',			editable	: true				},
            { clean: true, flex: 10, mandatory: false, 	field	: 'address',	label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				},						
            { clean: true, flex: 10, mandatory: false, 	field	: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
            { clean: true, flex: 10, mandatory: false, 	field	: 'gender', 	label:'_GENDER',		type: 'text',	editor: 'text',			editable	: true				},
            {   clean       : true, 
                flex        : 10, 
                mandatory   : false,	
                field	    : 'birthday', 	
                label       :'_BIRTHDAY',		
                type        : 'text',	
                editor      : 'datetime',		
                editable	: true,	
                options     : { 
                    yearNavigator       : true, 
                    rangeYearNavigator  : '1930:2080' 
                }
            },							
        ]
    },
    {
        title		: "_AVATAR",
        cols		: 4,
        showTitle	: false,
        icon		: "fa fa-check",
        padding		: "1rem 1rem 1rem 1rem",
        items		: [
            { clean: true, flex: 10, mandatory : false,	field	: 'avatar',		label : '_IMAGE',		type : 'img',	editor : 'img'}
        ]
    }]
}
];