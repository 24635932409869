/**
 * {'id':4187,
 * 	'reference':'E1250395',
 * 'date':'20180814',
 * 'status':'OR',
 * 'company':'RH',
 * 'customer':'Mr Saangberg John Olof',
 * 'bookingDate':'11\/08\/2018',
 * 'phone':'+46707134617',
 * 'vehicles':'1',
 * 'vehicle':'Private transfer (1 -4 people)',
 * 'transferType':'RETURN',
 * 'location':'Fontanellas Playa Aparthotel',
 * 'addressInfo':'Caravella s\/n, Can Pastilla',
 * 'pax':'3',
 * 'adults':'3',
 * 'children':'0',
 * 'infants':'0','price':'37.08',
 * 'verified':'no',
 * 'arrival_StartingPointType':'AP',
 * 'arrival_From':'Majorca (Palma de Mallorca Airport) (PMI)',
 * 'arrival_To':'Can Pastilla',
 * 'arrival_Date':'16\/02\/2019',
 * 'arrival_Time':'16:10',
 * 'arrival_GatewayFrom':'ARN',
 * 'arrival_GatewayTo':'ARN',
 * 'arrival_GatewayInfo':'D86653',
 * 'arrival_PickupDate':'',
 * 'arrival_PickupTime':'',
 **/
export let groupsCols = [
    {
        field		: 'arrival_Time',
        header		: 'Arrival time',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'arrival_GatewayInfo',
        header		: 'Arrival Flight',
        width		: '120px',
        disabled	: false,
        editable	: false,
        renderer    : 'flight',
        align		: 'center'
    },
    {
        field		: 'error',
        header		: 'Error',
		width    	: '100px',
        disabled	: true
    },
    {
        field		: 'id',
        header		: 'Id',
        width		: '50px',
        disabled	: true
    },
    {
        field		: 'reference',
        header		: 'Reference',
        width		: '120px',
        disabled	: true
    },
    {
        field		: 'status',
        header		: 'Status',
        width		: '100px',
        disabled	: true
    },
    {
        field		: 'company',
        header		: 'Agent',
        width		: '100px',
        disabled	: true
    },
    // { field: 'date', 				header: 'date' 		},
    {
        field		: 'customer',
        header		: 'Tre',
        width		: '80px',
        disabled	: true,
        editable	: true,
        renderer	: 'treatment',
        align		: 'right'
    },
    {
        field		: 'customer',
        header		: 'Customer',
        width		: '250px',
        disabled	: true,
        editable	: true,
        renderer	: 'customer',
        align		: 'left'
    },
    {
        field		: 'customer',
        header		: 'Customer',
        width		: '200px',
        disabled	: false,
        editable	: true,
        renderer	: 'customer_full',
        align		: 'left'
    },
    {
        field		: 'pax',
        header		: 'Pax',
        width		: '50px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'pax',
        header		: 'Pax',
        width		: '120px',
        disabled	: true,
        editable	: false,
        renderer	: 'pax',
        align		: 'right'
    },
    {
        field		: 'price',
        header		: 'Price',
        width		: '100px',
        disabled	: true,
        editable	: false,
        renderer	: 'price',
        align		: 'right'
    },
    {
        field		: 'location',
        header		: 'Accomodation',
        width		: '300px',
        disabled	: true,
        editable	: false,
        _renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'addressInfo',
        header		: 'Address Info',
        width		: '300px',
        disabled	: true,
        editable	: false,
        _renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'transferType',
        header		: 'Type',
        width		: '100px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'vehicle',
        header		: 'Vehicle',
        width		: '200px',
        disabled	: false,
        editable	: false
    },
    {
        field		: 'direction',
        header		: 'Dir',
        width		: '100px',
        disabled	: true,
        editable	: false
    },
    {
        // field	: 'arrival_inner',
        field		: 'area',
        header		: 'Area',
        width		: '200px',
        disabled	: false,
        editable	: false,
        renderer	: 'area_inner',
        align		: 'center'
    },
    {
        field		: 'arrival_To',
        header		: 'Area To',
        width		: '300px',
        disabled	: true,
        editable	: true,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'arrival_From',
        header		: 'Area From',
        width		: '300px',
        disabled	: true,
        editable	: true,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'arrival_GatewayTo',
        header		: 'Arrival Origin',
        width		: '200px',
        disabled	: true,
        editable	: false
    },
    // {
    //     field		: 'arrival_GatewayInfo',
    //     header		: 'Arrival Flight',
    //     width		: '120px',
    //     disabled	: false,
    //     editable	: false,
    //     renderer    : 'flight',
    //     align		: 'center'
    // },
    {
        field		: 'arrival_Date',
        header		: 'Arrival date',
        width		: '150px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    // {
    //     field		: 'arrival_Time',
    //     header		: 'Arrival time',
    //     width		: '120px',
    //     disabled	: false,
    //     editable	: false,
    //     align		: 'center'
    // },
    {
        field		: 'departure_To',
        header		: 'Departure To',
        width		: '300px',
        disabled	: true,
        editable	: true,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'departure_From',
        header		: 'Departure From',
        width		: '300px',
        disabled	: true,
        editable	: true,
        editor		: 'autocomplete',
        renderer	: 'arrival',
        align		: 'center'
    },
    {
        field		: 'departure_GatewayTo',
        header		: 'Departure Origin',
        width		: '200px',
        disabled	: true,
        editable	: false
    },
    {
        field		: 'departure_GatewayInfo',
        header		: 'Departure Flight',
        width		: '120px',
        disabled	: true,
        editable	: false,
        renderer    : 'flight',
        align		: 'center'
    },
    {
        field		: 'departure_Date',
        header		: 'Departure date',
        width		: '150px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'departure_Time',
        header		: 'Departure time',
        width		: '120px',
        disabled	: true,
        editable	: false,
        align		: 'center'
    }
];
