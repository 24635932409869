export const tabsInfo	=  	{
	minutWidth			: 1,
	minutHeight			: 1,
	height				: '900px',
	itemWidth			: '220px',
	itemExtraWidth		: '250px',
	itemOptionsWidth	: '230px',
	itemMinWidth		: 100,
	itemMinHeight		: 20,
	emptyColWidth		: '-webkit-fill-available',	

	items				: {
		flights		: {
			items	: [
				{ name: 'flights', 			label: '_FLIGHTS', 		icon: 'bus'	 					}
			],
			cols		: 2,
			selected	: 'flights'
		},
		vehicles	: {
			items	: [
				{ name: 'fleet', 			label: '_FLEET', 		icon: 'bus'	 					},
				{ name: 'externals', 		label: '_EXTERNALS', 	icon: 'bus'	 					}
			],
			cols		: 2,
			selected	: 'fleet'
		},
		drivers	: {
			items	: [
				{ name: 'drivers', 			label: '_DRIVERS', 		icon: 'user'	 				}
			],
			cols		: 2,
			selected	: 'drivers'
		},
		services	: {
			items	: [
				{ 	name	: 'services',
					label	: '_SERVICES',
					icon	: 'bus',
				},
				{ 	name	: 'services_map',
					label	: '_SERVICES_MAP',
					icon	: 'bus',
				}
			],
			cols		: 2,
			selected	: 'services',
			buttons	: {
				services	: {
					direction	:	{
						cols		: 6,
						multiple	: true,
						entity		: 'service',
						name		: 'direction',
						minSelected	: 1,
						items		: [
							{ 	name: 'arrival',	label	: '_ARRIVALS', 		icon		: 'plane',	value: true	},
							{ 	name: 'departure',	label	: '_DEPARTURES', 	iconReverse	: 'plane',	value: true	}
						]
					},
					shared		:	{
						cols		: 6,
						multiple	: true,
						entity		: 'service',
						name		: 'shared',
						minSelected	: 1,
						items		: [
							{ 	name: 'private',	label	: '_PRIVATE', 		icon		: 'bus',	value: true	},
							{ 	name: 'shuttle',	label	: '_SHUTTLE', 		icon		: 'bus',	value: true	}
						]
					},
				}
			}
		},

		customers	: {
			items	: [
				{ name: 'customers', 		label: '_CUSTOMERS', 		icon: 'users'	 			}
			],
			cols		: 2,
			selected	: 'customers'
		},

		customer	: {
			items	: [
				{ name: 'info', 			label: '_INFO', 			icon: 'user'	 			}
			],
			cols		: 2,
			selected	: 'info'
		},

        customerForm: {
			items	: [
				{ name: 'info',     label: '_INFO', 		icon: 'info'	 			},
				{ name: 'notes',    label: '_NOTES', 		icon: 'info'	 			}
			],
			cols		: 2,
			selected	: 'info'
		},
	}
};