import { Injectable 			} from '@angular/core';
import { CommonsService 		} from '../commons.service';
import { service_filters		} from './service_filters';

const zones 	= [ "Palma", "Norte", "Sur", "Levente", "Tramontana" ];

@Injectable({
  providedIn: 'root'
})
export class ServiceFiltersService {

	fns			: any = {};
	pageInfo	: any = {};

	constructor(
		private commons	: CommonsService
	)
	{	this.init();
	}


	// async ngOnInit(){ await this.init(); }

	init(){
		let me = this;
		this.fns["basic_filter"				]	= ($params)	=> 	{	return this.basic_filter			($params);	}
		this.fns["vehicle_capacity"			]	= ($params)	=> 	{	return this.vehicle_capacity		($params);	}
		this.fns["fail_filter"				]	= ($params)	=> 	{	return this.fail_filter				($params);	}
		this.fns["inter_zone_distances"		]	= ($params)	=>	{	return this.inter_zone_distances	($params);	}
		this.fns["inter_zone_distances_2"	] 	= ($params)	=>	{	return this.inter_zone_distances_2	($params);	}
		this.fns["vehicle_turn"				] 	= ($params)	=>	{	return this.vehicle_turn			($params);	}
		this.fns["vehicle_turn_2"			] 	= ($params)	=>	{	return this.vehicle_turn2			($params);	}
		this.fns["vehicle_dynamic_turn"		] 	= ($params)	=>	{	return this.vehicle_dynamic_turn	($params);	}

		this.pageInfo.distances = this.commons.pageInfo.distances;
	}

  	public getServiceFilters():any[]{
		return service_filters;
  	}

	public getActiveFilters(){
		return service_filters.filter(filter=>filter["active"]);
	}

	public getSelectedFilters(){
		return this.getActiveFilters().filter(filter=>filter["selected"]);
	}

	public applyActiveFilters($params){
		let error 	= "none";
		let report	= [];
		let result 	= this.getSelectedFilters().reduce((r,filter)=>{
			if(undefined==this.fns[filter.name]){ return r; }
			let result = this.fns[filter.name]($params);
			if(!result){
				error = filter.error;
			}
			report.push({
				filter	: filter.name,
				status	: result
			});
			return r && result;
		},true);

		// console.log("[apployActiveFilters] report",report);

		return {
			success	: result,
			error	: error,
			report	: report
		}
	}

	/**
	 * look for a gap just from init and end time of services
	 *
	 * @param $params
	 * @returns
	 */
	private basic_filter($params){
		let item 		= $params["item"];
		let row 		= $params["row"];
		let previous, next;
		let gap			= true;

		item.pillow 	= item.pillow || 0;

		row.items.forEach(current=>{
			// previous item
			if(current.init <= item.init){
				if(undefined==previous				){ previous = current; }
				else if ( current.init>previous.init){ previous = current; }
			}

			// next item
			if(current.init > item.init){
				if(undefined == next				){ next		= current; }
				else if ( current.init<next.init	){ next 	= current; }
			}
		})

		if(previous){
			// if( previous.end > ( item.init - item.check_pillow )){ gap = false; }
			if( previous.end > item.init ){ gap = false; }
		}

		if(next){
			// if ( item.end > ( next.init - next.pillow )){ gap = false; }
			if ( item.end > next.init){ gap = false; }
		}

		// Restore pillows
		if(!gap){
			item.pillow = item.last_pillow || 0;
			if(next){ next.pillow = next.last_pillow || 0; }
		}
		return gap;
	}

	/**
	 * testing filter for failure
	 *
	 * @param $params
	 * @returns
	 */
	private fail_filter($params){
		return false;
	}

	/**
	 * check if vehicle has enough seats to accomodate paxes
	 *
	 * @param $params
	 */
	private vehicle_capacity($params){
		let item 	= $params["item"];
		let row 	= $params["row"];
		// let vehicle = row["vehicle"];
		let seats	= row["seats"] || 0;
		let gap		= false;

		if(seats==0 && row["vehicle"]){
			seats = row["vehicle"]["seats"];
		}

		if(seats>=item.pax){ return true; }

		return gap;
	}

	/**
	 * calculta distances from arrivals and departures
	 *
	 * @param $params
	 * @returns
	 */
	private inter_zone_distances($params){
		let item 	= $params["item"];
		let row 	= $params["row"];
		let previous, next;

		row.items.forEach(current=>{
			// previous item
			if(current.init <= item.init){
				if(undefined==previous				){ previous = current; }
				else if ( current.init>previous.init){ previous = current; }
			}

			// next item
			if(current.init > item.init){
				if(undefined == next				){ next		= current; }
				else if ( current.init<next.init	){ next 	= current; }
			}
		})

		let gap = true;

		if(previous){
			item.last_pillow 	= item.pillow || 0;
			item.check_pillow 	= this.getPillow(previous,item);
			item.pillow			= item.check_pillow;
			if( previous.end > ( item.init - item.check_pillow )){ gap = false; }			
		}

		if(next){
			next.last_pillow 	= next.pillow || 0;
			next.check_pillow	= this.getPillow(item,next);
			next.pillow			= next.check_pillow;
			if ( item.end > ( next.init - next.check_pillow )){ gap = false; }
		}

		return gap;
	}

	/**
	 * calculta distances from arrivals and departures
	 *
	 * @param $params
	 * @returns
	 */
	 private inter_zone_distances_2($params){
		let item 	= $params["item"];
		let row 	= $params["row"];
		let previous, next;

		row.items.forEach(current=>{
			// previous item
			if(current.init <= item.init){
				if(undefined==previous				){ previous = current; }
				else if ( current.init>previous.init){ previous = current; }
			}

			// next item
			if(current.init > item.init){
				if(undefined == next				){ next		= current; }
				else if ( current.init<next.init	){ next 	= current; }
			}
		})

		let gap = true;

		if(previous){
			item.last_pillow 	= item.pillow || 0;
			item.check_pillow 	= this.getPillow(previous,item);
			item.pillow			= item.check_pillow;
			if( previous.end > ( item.init - item.check_pillow )){ gap = false; }			
		}

		if(next){
			next.last_pillow 	= next.pillow || 0;
			next.check_pillow	= this.getPillow(item,next);
			next.pillow			= next.check_pillow;
			if ( item.end > ( next.init - next.check_pillow )){ gap = false; }
		}

		return gap;
	}

	/**
	 * check vehicle turn
	 *
	 * @param $params
	 * @returns
	 */
	 private vehicle_turn($params){
		let item 	= $params["item"];
		let row 	= $params["row"];

		let gap 	= false;

		try{
			switch(row["turn"]){
				default						:
				case "turn_early_morning"	:
				case "turn_morning"			:
					// gap = item.init>init && item.end<end; break;
				case "turn_afternoon"		:
				case "turn_night"			:
					// if( item.init >= row.init_timestamp || item.end <= row.end_timestamp ){
					if( item.init_timestamp >= row.yesterday_init_timestamp &&
						item.end_timestamp	<= row.yesterday_end_timestamp ){
						gap = true;
					}

					if( item.init_timestamp >= row.init_timestamp &&
						item.end_timestamp 	<= row.end_timestamp ){
						gap = true;
					}
					break;
			}
			return gap;

		} catch($e){
			return false;
		}
	}

	/**
	 * check vehicle turn 2
	 *
	 * @param $params
	 * @returns
	 */
	 private vehicle_turn2($params){
		let item 	= $params["item"];
		let row 	= $params["row"];
		let gap		= true;

		try{
			if(undefined==row.init_timestamp || isNaN(row.init_timestamp)){ return true; }
			// Not allow previous time to first assigned
			if(item.init_timestamp<row.init_timestamp){ gap = false; }
			// Not allow after end of turn
			if(item.init_timestamp>row.end_timestamp ){ gap = false; }

			return gap;

		} catch($e){
			return false;
		}
	}

	/**
	 * check vehicle dynamic turn
	 *
	 * @param $params
	 * @returns
	 */
	private vehicle_dynamic_turn($params){
		let item 				= $params["item"];
		let row 				= $params["row"];
		let turn_duration_min 	= parseInt(this.commons.userInfo.currentDestination.turn_duration_min || this.commons.pageInfo.vehicles.turn_duration_min);

		try{
			if((row.items||[]).length==0){
				return true; 
			}

			// row.items 			= row.items.sort((a,b)=>a.init_timestamp>=b.init_timestamp?1:-1);
			row.init_timestamp	= row.items[0].init_timestamp;
			row.end_timestamp	= row.init_timestamp + turn_duration_min *60;
			row.init			= row.items[0].init;
			row.end				= row.init + turn_duration_min;
			
			return true;

		} catch($e){
			return false;
		}
		return true;
	}

	/**
	 * get service time needed to move among zones
	 *
	 * @param $a
	 * @param $b
	 * @returns
	 */
	public getPillow($a,$b){
		let first, last, pillow;
		let distance;
		let base = this.commons.pageInfo.base;

		if(undefined==$a || undefined==$b){ return 0; }

		// Sort elements by time
		if($a.init<=$b.init){ first = $a; last  = $b;	}
		else 				{ first = $b; last  = $a;	}

		first.type 	= first.type || first.direction;
		last.type	= last.type	 || last.direction;

		// console.log("fist zone",first.zone,"last zone", last.zone);
		if(!first.zone || !last.zone){
			console.log("NULL FIRST",first);
			console.log("NULL LAST",last);
			return 1000;
		}

		if(this.pageInfo.distances[first.zone]==undefined){
			console.log("[serviceFilter:getPillow] zone",first.zone," not found in distances",this.pageInfo.distances);
		}

		console.log("First",first,"last",last,"distances",this.pageInfo.distances);

		if(first.type=="arrival" && last.type=="arrival"){
			pillow = this.pageInfo.distances[first.zone][base];
			// Distancia entre LD y origen
		}
		if(first.type=="arrival" && last.type=="departure"){
			pillow = this.pageInfo.distances[first.zone][last.zone];
			// Distancia entre LD y origen
		}
		if(first.type=="departure" && last.type=="arrival"){
			pillow = 0;
			// Distancia entre DD=PMI y AO=PMI es 0
		}
		if(first.type=="departure" && last.type=="departure"){
			pillow = this.pageInfo.distances[base][last.zone];
			// Distancia entre DD=PMI y origen departure
		}

		return pillow;
	}
}
