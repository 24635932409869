import { TranslateService				} from '@ngx-translate/core';
import { EventEmitter, Injectable		} from '@angular/core';
import { ToastrService 					} from 'ngx-toastr';
import { extendMoment 					} from 'moment-range';
import { AngularCsv						} from 'angular7-csv';
import { StorageService 				} from './storageservice';
import { CommonsService 				} from './commons.service';
import * as moment 			 			  from 'moment';

@Injectable()
export class CSVService {
	userInfo 		: any = {};
    pageInfo        : any = { importTrace: [] };
	eventEmitter	: EventEmitter<any>= new EventEmitter();
    
	constructor(
        private toastr      : ToastrService,
		private translate   : TranslateService,
		private storage		: StorageService,
		private commons		: CommonsService        
	){	}

	ngOnInit() 							{
		this.pageInfo.uploadedFiles	= [];							
	}

    onUpload($type,$info) 					{
		for(let file of $info.event.files) {  
			this.processFile({ ...$info, file: file });
		}		
  	}

	CSV2Array($info)	{
		let wrongDelimiter 	= false;
		let items			= [];
		let row;
	  
	  	( $info.split('\n') || [] ).forEach(($line,$index)=>{
			if(wrongDelimiter){ return; }
		  	row = $line.split(";") || [];
		  	if(row.length<2){ 
				switch(row[0]){
					case ""	:	console.log("Última linea",$index);	break;
					default	:	wrongDelimiter = true; 				break;
				}
		  	}
			else {			
				items.push(row); 
		  }
	  	});

		return items;
  	}

	/**
	 * 
	 * @param $info 
	 */
  	processFile($info)					{		
		let fileReader		= new FileReader();	
		let data			= [];	
		
		fileReader.onload 	= (() => { 
			data = this.CSV2Array(<string>fileReader.result);
			console.log("items",data);
			this.eventEmitter.emit({ type: "csv", data: data });
		});

		fileReader.onerror = (error) => {
			this.pageInfo.importTrace.push({	type	:	"error",
												label	:	this.commons.getTranslate("_FILE"),
												content	: 	this.commons.getTranslate("_PROCESSING_FILE_ERROR")
											})
		}

		fileReader.readAsText($info.file);
  	}
}
