import { Component, 
         Input, 
         Output, 
         EventEmitter,     	
		 ViewEncapsulation			} from '@angular/core';
import { CommonsService 			} from 'src/app/demo/service/commons.service';
import { DragulaService 			} from 'ng2-dragula';

@Component({
  selector    		: 'app-wizard-sight-step',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html',  
})
export class WizardSightStepComponent {

	@Input("type")      type	:string	= "";
	@Input("info")		info	:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	draggedItem			: any = {};	
	
	constructor(
		private commons		: CommonsService, 
		private dragulaCtrl : DragulaService
	){ 
		this.init(); 
	}

	init()			{}
  	ngOnInit()		{
		console.log("Init component sights");
		this.pageInfo.map		= this.info.sight.map;						
	}

    action($type,$item){		
		switch($type){
			case "setMarker"	: 	this.info.sight.marker = {
										latitude	: $item.coords.lat,
										longitude	: $item.coords.lng
									};
									// Save as persistable info
									this.info.latitude = $item.coords.lat;
									this.info.longitude= $item.coords.lng;
									break;									
		}
	}

	getMarkers($type){
		switch($type){
			case "sights"		: return [ this.info.sight.marker ];
		}
	}	
}
