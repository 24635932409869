export const videos = [
	{ id: 1, 	lang: "es",		title: 'Cuevas del drach' },
	{ id: 2, 	lang: "uk",		title: 'Cuevas del drach' },
	{ id: 3, 	lang: "de",		title: 'Cuevas del drach' },
	{ id: 4, 	lang: "es",		title: 'Cuevas del drach' },
	{ id: 5, 	lang: "de",		title: 'Cuevas del drach' },
	{ id: 6, 	lang: "fr",		title: 'Cuevas del drach' },
	{ id: 7, 	lang: "fr",		title: 'Cuevas del drach' },
	{ id: 8, 	lang: "it",		title: 'Cuevas del drach' },
	{ id: 9, 	lang: "es",		title: 'Cuevas del drach' },
	{ id: 10, 	lang: "uk",		title: 'Cuevas del drach' },
	{ id: 11, 	lang: "uk",		title: 'Cuevas del drach' },
	{ id: 12, 	lang: "it",		title: 'Cuevas del drach' }
];