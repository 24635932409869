import { Injectable               	} from '@angular/core';
import { CommonsService             } from 'src/app/demo/service/commons.service';
import { IProviderService 			} from './iproviderservice';
import { ProviderHoppaService 		} from './hoppa.service';
import { ProviderHTXService 		} from './htx.service';
import { ProviderWTRService 		} from './wtr.service';
import { ProviderSunTransfersService } from './suntransfers.service';

@Injectable()
export class ProviderServiceController {		
	pageInfo 	= { 
		services	: {},
		servicesInfo: {} 
	};
	
  	constructor(
		private commons 	: CommonsService,
		private htx			: ProviderHTXService,
		private hoppa		: ProviderHoppaService,
		private wtr			: ProviderWTRService,
        private stf         : ProviderSunTransfersService
	){
		this.htx.init(this);
		this.hoppa.init(this);
		this.wtr.init(this);
		this.stf.init(this);
	}

	public getList(){
		return this.pageInfo.servicesInfo;
	}

	public registerProviderService($info){
		if(undefined==this.pageInfo.services){ 
			this.pageInfo.services = {}
		}
		this.pageInfo.servicesInfo	[$info.id] = $info.name.toUpperCase();
		this.pageInfo.services		[$info.id] = $info.service;
	}

	getProviderService($id){
		if(undefined==this.pageInfo.services[$id]){
			this.commons.generateToastError("_PROVIDER_NOT_FOUND");
			return null;
		}
		return this.pageInfo.services[$id];
	}
}