export const transporterAssignOptions = [
	{ 	
		id		: 'assign_current',
		name	: 'assign_current',							
		title	: "_ASSIGN_TRANSPORTER_TO_CURRENT_SERVICES_TITLE",
		desc	: "_ASSIGN_TRANSPORTER_TO_CURRENT_SERVICES_DESC",
		group	: "_ASSIGN",
		selected: true,
	},
	{	
		id		: 'assign_empties',
		name	: 'assign_empties',							
		title	: "_ASSIGN_TRANSPORTER_TO_ALL_EMPTY_SERVICES_TITLE",
		desc	: "_ASSIGN_TRANSPORTER_TO_ALL_EMPTY_SERVICES_DESC",
		group	: "_ASSIGN"
	},
	{ 	
		id		: 'assign_all',
		name	: 'assign_all',					
		title	: "_ASSIGN_TRANSPORTER_TO_ALL_SERVICES_TITLE",
		desc	: "_ASSIGN_TRANSPORTER_TO_ALL_SERVICES_DESC",
		group	: "_ASSIGN"
	}
];


export const transportersCalendarFakeItem = {
	transporter: { 
		name	: "Fake transporter",
		phone	: "(+34) 610 234567",
	},
	items	: [
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_NOT_SEND",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_PENDING",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_ACCEPTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_REJECTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_NOT_SEND",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_PENDING",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_ACCEPTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_REJECTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_NOT_SEND",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_PENDING",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_ACCEPTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		},
		{	
			area			: "Playa de las Americas",
			arrival_Date	: "2024-01-15",
			bookings		: ['LM-19902994'],
			date			: "2024-01-15",
			type			: "private",
			direction		: "arrival",
			firstTime		: "13:30",
			hotels			: [],
			name			: "GR_PR_003",
			pax				: 2,
			pickupTime		: "14:20",
			private			: true,
			provider		: 1,
			serviceTime		: "14:20",
			transporter		: "Avantcab",
			vehicle			: "Avantcab",
			zone			: "Sur",
			status			: "_REJECTED",

			bookingsInfo	: [{
				addressInfo					: "AVDA. RAFAEL PUIG, 36",
				adults						: 2,
				area						: "Playa de las Americas",
				arrival_Date				: "2024-01-15",
				arrival_From				: "Tenerife - South Airport (Reina Sofia)",
				arrival_GatewayFrom			: "East Midlands Airport",
				arrival_GatewayInfo			: "LS-633",
				arrival_GatewayTo			: "East Midlands Airport",
				arrival_PickupTime			: "14:20",
				arrival_StartingPointType	: "AP",
				arrival_Time				: "13:30",
				arrival_To					: "Playa de las Americas",
				assigned					: 0,
				bookingDate					: "20240111",
				children					: 0,
				company						: "HTX",
				customer					: "Mr John Reasbeck",
				date						: "2024-01-15",
				direction					: "arrival",
				flight						: "LS-633",
				group						: "GR_PR_003",
				group_firstTime				: "13:30",
				group_pax					: 2,
				id							: 3,
				infants						: 0,
				mode						: "arrival",
				pax							: 2,
				phone						: "+44 07503935599",
				provider_name				: "HTX",
				reference					: "LM-19902994",
				shared						: "private",
				time						: "13:30",
				tourinia_area				: "Playa de las Americas",
				transporter					: "Avantcab",
				vehicle_type				: "Private Standard Car 1 - 4",
				vehicles					: 1,
				zone						: "Sur"
			}]
		}
	]
};

export const mainView 		=	 {
	items	: [
		{ name: 'providers', 	label: '_PROVIDERS', 	icon: 'globe',		isDisabled: (me)=>( false								)},
		{ name: 'controls', 	label: '_CONTROLS', 	icon: 'gamepad',	isDisabled: (me)=>( false								)},
		{ name: 'transfers', 	label: '_TRANSFERS',	icon: 'plane',		isDisabled: (me)=>( me.getInfo("groups","noItems")		)},
		{ name: 'transporters', label: '_TRANSPORTERS',	icon: 'bus',		isDisabled: (me)=>( me.getInfo("groups","noItems")		)},
		{ name: 'drivers', 		label: '_DRIVERS',		icon: 'user',		isDisabled: (me)=>( me.getInfo("groups","noItems")		)},
		// { name: 'help', 		label: '_HELP',			icon: 'question',	isDisabled: (me)=>( false								)}
	],
	selected: 'providers'
};

export const execParams		=	{ 
	ready		: false,
	form		: [
		{
			cols		: 12,
			widthButtons: '7rem',
			widthInput	: '4rem',
			widthNumber	: '4rem',
			lineHeight	: '1.8rem',
			height		: '1.8rem',
			fontSize	: '0.6rem',
			margin		: '0rem',
			padding		: "0rem 0rem",

			items		: [
				{ mandatory: true, 	field	: 'strategy',			label:'_STRATEGY',				editor: 'autocomplete',	editable	: true, entityList: 'strategies'},						
				{ mandatory: true, 	field	: 'driverRestTime',		label:'_DRIVER_REST_TIME',		editor: 'number',		editable	: true							},
				{ mandatory: true, 	field	: 'transferDelayTime', 	label:'_TRANSFER_DELAY_TIME',	editor: 'number',		editable	: true							},
				{ mandatory: true, 	field	: 'securityRange', 		label:'_SECURITY_RANGE',		editor: 'number',		editable	: true							}
			]
		}
	],			
};

export const controlPanels	=	{	
	control	:	{
		cols	:   4,
		buttons	: [	
					{ 	
						name	: 'controls',		
						label	: '_CONTROLS',
						icon	: 'gamepad',																	
						items	: [	
							{
								name		: 'execMode',
								title		: "_EXECMODE",
								action		: 'execMode',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'manual', 	label: '_EXECMODE_MANUAL', 		icon: 'user'	},
									{ name: 'auto', 	label: '_EXECMODE_AUTOMATIC',	icon: 'rocket'	}
								],
								selected	: 'auto'
							},
							{
								name		: 'transferType',
								title		: "_TRANSFER_TYPE",
								action		: 'transferType',
								icon 		: "bus",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.5rem",
								filterCols	: 12,
								cols		: 4,
								multiple	: true,
								minSelected	: 1,
								items: [
									{ name: 'private', 	label: '_PRIVATE', 	icon: 'user', 		value: true		},
									{ name: 'shared', 	label: '_SHARED', 	icon: 'users', 		value: true		},
									{ name: 'premium', 	label: '_EXPRESS', 	icon: 'users', 		value: false	}
								]
							},
							{
								name		: 'transferMode',
								title		: "_TRANSFER_MODE",
								action		: 'transferMode',
								icon 		: "bus",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.5rem",
								filterCols	: 12,
								cols		: 6,
								multiple	: false,
								minSelected	: 1,
								items: [
									{ name: 'join', 	label: '_JOIN', 	icon: 'users'	},
									{ name: 'split', 	label: '_SPLIT', 	icon: 'user'	}
								],
								// selected	: 'join'
								selected	: 'split'
							},
							{
								name		: 'servicePartition',
								title		: "_SERVICE_PARTITION",
								action		: 'servicePartition',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'partitioned', 	label: '_PARTITIONED', 	icon: 'user'	},
									{ name: 'monolithic', 	label: '_MONOLITHIC', 	icon: 'users'	}
								],
								selected	: 'monolithic'
							}
							,{
								name		: 'exec_command',
								title		: "_EXEC_COMMAND",
								action		: 'execCommand',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'yes', 	label: '_YES', 	icon: 'check'	},
									{ name: 'no', 	label: '_NO', 	icon: 'times'	}
								],
								selected	: 'yes'
							}
							,{
								name		: 'environment',
								title		: "_ENVIRONMENT",
								action		: 'setEnvironment',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'test', label: '_TEST', icon: 'gear'	},
									{ name: 'prod', label: '_PROD',	icon: 'gear'	}
								],
								selected	: 'prod'
							}
							,{
								name		: 'only_verified',
								title		: "_ONLY_VERIFIED",
								action		: 'showOnlyVerifiedBookings',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'yes', 	label: '_YES', 	icon: 'check'	},
									{ name: 'no', 	label: '_NO', 	icon: 'times'	}
								],
								selected	: 'yes'
							}
							,{
								name		: 'cancelled',
								title		: '_SHOW_CANCELLED',
								action		: 'showCancelledBookings',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'yes', 	label: '_YES', 	icon: 'check'	},
									{ name: 'no', 	label: '_NO', 	icon: 'times'	}
								],
								selected	: 'no'
							}
						]
					},
					{ 	name: 'parameters',		label: '_PARAMETERS',	icon: 'list'		},
					//{   name: 'transporters',	label: '_TRANSPORTERS',	icon: 'bus'			},
					{ 	
						name	: 'importer',		
						label	: '_IMPORTER',
						icon	: 'list',																	
						items	: [	
							{
								name		: 'mode',
								title		: "_IMPORT_MODE",
								action		: 'import_mode',
								icon 		: "gears",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								items: [
									{ name: 'bookings', label: '_BOOKINGS', _icon: 'user'	},
									{ name: 'groups', 	label: '_GROUPS',	_icon: 'rocket'	}
								],
								selected	: 'groups'
							},
							{
								name		: 'direction',
								title		: "_IMPORT_DIRECTION",
								action		: 'import_direction',
								icon 		: "bus",
								format		: 'vertical',
								showTitle	: true,
								showLabel	: true,
								showIcon	: true,
								fontSize	: "0.6rem",
								filterCols	: 12,
								cols		: 6,
								minSelected	: 1,
								items: [
									{ name: 'arrivals', 	label: '_ARRIVALS', 	_icon: 'user', 		value: true 	},
									{ name: 'departures', 	label: '_DEPARTURES', 	_icon: 'users', 	value: true		}
								],
								selected	: 'arrivals'
							}					
						]
					}																								
		],
		
		actions	: {
			title		: "_ACTIONS",
			icon 		: "caret-square-o-right",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			filterCols	: 12,
			cols		: 4,
			action		: 'doButton',
			items: [
				{ cols:	3, name: 'load_bookings', 		label: '_LOAD_BOOKINGS', 		icon: 'refresh',	value: false 	},
				{ cols: 3, name: 'load_solution', 		label: '_LOAD_SOLUTION', 		icon: 'file',		value: false 	},
				{ cols: 3, name: 'generate_groups',		label: '_GENERATE_GROUPS', 		icon: 'gear',		value: false 	},
				{ cols: 3, name: 'clear_groups', 		label: '_CLEAR_GROUPS', 		icon: 'eraser',		value: false 	},
				{ type: "line" },												
				// { cols: 3, name: 'set_vehicles',		label: '_SET_VEHICLES', 		icon: 'gear',		value: false 	},
				// { cols: 3, name: 'clear_row_vehicles', 	label: '_CLEAR_VEHICLES', 		icon: 'eraser',		value: false 	},
				// { cols: 3, name: 'generate_services',	label: '_GENERATE_SERVICES', 	icon: 'gear',		value: false 	},
				// { cols: 3, name: 'clear_services', 		label: '_CLEAR_SERVICES', 		icon: 'eraser',		value: false 	},
				{ type: "line" },
				{ cols: 3, name: 'save_solution', 		label: '_SAVE', 				icon: 'floppy-o',	value: false	},
				{ cols: 3, name: 'fomento', 			label: '_FOMENTO', 				icon: 'paper-plane',value: false	},												
				{ cols: 3, name: 'publish', 			label: '_PUBLISH', 				icon: 'upload',		value: false	},
				{ cols: 3, name: 'publish_clear', 		label: '_PUBLISH_CLEAR', 		icon: 'times',		value: false	}
			]
		},
		selected	: "controls"		
	},

	transfers		:	{
		cols	: 6,
		buttons	: [	
			{ 	name		: 'routes',		
				label		: '_ROUTES', 		
				icon		: 'bus'	 	
			},
			{ 	name		: 'zones',		
				label		: '_ZONES', 		
				icon		: 'map'	
			},
			{ 	name		: 'bookings',	
				label		: '_BOOKINGS', 		
				icon		: 'users', 	
				_isDisabled	: 'transferBookings'	
			}
		],
		selected	: "routes",
		direction	:	{
			cols		: 6,
			multiple	: true,
			action		: 'transferType',																		
			minSelected	: 1,																		
			items		: [	
				{ 	name: 'arrival',	label	: '_ARRIVALS', 		icon		: 'plane',	value: true	},
				{ 	name: 'departure',	label	: '_DEPARTURES', 	iconReverse	: 'plane',	value: true	}
			]
		},		
		pendings	:	{
			cols		: 4,
			multiple	: true,
			action		: 'transferType',																		
			minSelected	: 1,																		
			items		: [	
				{ 	name: 'external_pendings',	label	: '_EXTERNAL_PENDINGS', 	value: true	},
				{ 	name: 'own_pendings',		label	: '_OWN_PENDINGS', 			value: true	},
				{ 	name: 'rest_pendings',		label	: '_REST_PENDINGS', 		value: true	}
			]
		}
	},

	transporters	:	{
		cols	: 6,
		buttons	: [	
			{ 	name: 'own_fleet',			label	: '_OWN_FLEET', 				_icon	: 'bus'			},
			{ 	name: 'vehicles_plate',		label	: '_VEHICLES_PLATE', 			_icon	: 'bus'			},
			{ 	name: 'external_companies',	label	: '_EXTERNAL_TRANSPORTERS', 	_icon	: 'bus'			}
		],
		selected	: "own_fleet"		
	},

	externals		: 	{
		modes	:	{
			cols		: 6,
			multiple	: false,
			action		: 'transferType',																		
			minSelected	: 1,																		
			items		: [	
				{ 	name: 'assign',	label	: '_ASSIGN_MODE', 	icon		: 'gear',	value: true	},
				{ 	name: 'filter',	label	: '_FILTER_MODE', 	iconReverse	: 'filter',	value: false}
			],
			selected	: 'assign'
		}
	},

	drivers		:	{										
		cols		: 6,
		buttons		: [	
			{ 	name: 'simple',	label	: '_SIMPLE', 	value: true	},
			{ 	name: 'full',	label	: '_FULL', 		value: false}
		],
		selected	: "simple"										
	}											
};

export const heightInfo		=	{	
	container		: "70vh",
	drop			: "9vh",												
	windowed		: {
		small		: "45vh",
		big			: "71vh",
		bigChild	: "70vh",
		item		: "20vh",
	},
	fullScreen		: {
		mainControls	: "80vh",
		container		: "77vh",
		calendar		: "71vh",
	},
	assigned		: "20vh",
	chart			: "70vh",
	chart3			: "24vh"
};

export const colorInfo		= {
	empty					: "coral",
	active					: "rgba(255,255,255,1)",
	linked					: "green",
	pending					: "darkorchid",
	arrival					: "slategray",
	departure				: "slategray",
	transportAssigned		: "slategray",
	transportNotAssigned	: "brown"
};

export const servicesGrid	=	{									
	height				: '63vh', 
	border				: '1px solid #f0f0f0', 
	rowExpansionWidth	: '63vw',
};

export const bookingsGrid	=	{									
	height				: '63vh', 
	border				: '1px solid #f0f0f0', 
	rowExpansionWidth	: '63vw',
};

export const calendarInfo	=  	{
	heightVh			: '71vh',
	scaleFactor			: 1,
	scaleFactorYInitial	: 0.4,
	scaleFactorXInitial	: 1,												
	scaleOffset			: 70,
	scaleRange			: 1675,
	minutWidth			: 1,
	minutHeight			: 1,
	height				: '900px',
	itemWidth			: '220px',
	itemExtraWidth		: '250px',
	itemOptionsWidth	: '230px',
	itemMinWidth		: 100,
	// itemMinHeight		: 35,
	itemMinHeight		: 20,
	emptyColWidth		: '-webkit-fill-available',
	driverThumbnailSize	: 20,								

	view		: {	
		items	: [ 
			{ name: 'bookings_grid', 		label: '_BOOKINGS_GRID', 		icon: 'bus'	 					},											
			{ name: 'services_groups', 		label: '_SERVICES_GROUPS', 		icon: 'bus'	 					},
			{ name: 'services_calendar', 	label: '_SERVICES_CALENDAR', 	icon: 'bus'	 					},
			{ name: 'services_grid', 		label: '_SERVICES_GRID', 		icon: 'bus'	 					},											
			// { name: 'services_unassigned', 	label: '_SERVICES_uNASSIGNED', 	icon: 'bus'	 					},
			{ name: 'drivers', 				label: '_DRIVERS', 				icon: 'user'					},
			// { name: 'transporters', 		label: '_TRANSPORTERS',			icon: 'building'				}
		], 
		cols	: 2,
		// selected: 'services_calendar'
		selected	: 'bookings_grid'
	},

	buttons				: {

		'bookingsButtons'		: {			
			name		: 'bookings_buttons',
			title		: "_BOOKINGS_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [
				// { 	
				// 	name 	: 'show_errors',	
				// 	type	: "toggle", 
				// 	offLabel: '_BOOKINGS_SHOW_ERRORS', 
				// 	onLabel	: '_BOOKINGS_SHOW_ALL', 
				// 	onIcon	: 'fa-list', 
				// 	offIcon	: 'fa-times' 
				// },
				{ name	: 'show_search', 	label: '_SEARCH'		, 	icon: 'search'	},				
				// { name	: 'clear_groups', 	label: '_CLEAR_GROUPS'	, 	icon: 'users'	},				
				// { name	: 'clear_vehicles', label: '_CLEAR_VEHICLES', 	icon: 'bus'		},			
				// { name	: 'clear_plates', 	label: '_CLEAR_PLATES', 	icon: 'bus'		},			
				// { name	: 'clear_drivers', 	label: '_CLEAR_DRIVERS'	, 	icon: 'user'	},
				{ name	: 'notify_pickups', label: '_NOTIFY_PICKUPS',	icon: 'bus'		}						
			]
		},

		'bookingsMenuButtons'		: {			
			name		: 'bookings_menu_buttons',
			title		: "_CLEAR_OPTIONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'clear_groups', 							label: '_CLEAR_GROUPS'	, 					icon: 'users'	},				
				{ name	: 'clear_vehicles', 						label: '_CLEAR_VEHICLES', 					icon: 'bus'		},			
				{ name	: 'clear_plates', 							label: '_CLEAR_PLATES', 					icon: 'bus'		},			
				{ name	: 'clear_drivers', 							label: '_CLEAR_DRIVERS'	, 					icon: 'user'	},
				{ name	: 'clear_transporters', 					label: '_CLEAR_TRANSPORTERS'	, 			icon: 'user'	},
				
				// { name	: 'select_pending_services', 				label: '_SELECT_PENDING_SERVICES',			icon: 'bus'		},
				// { name	: 'send_selected_pending_services', 		label: '_SEND_SELECTED_PENDING_SERVICES',	icon: 'bus'		},
				// { name	: 'clear_services_external_transporters', 	label: '_CLEAR_TRANSPORTERS',				icon: 'bus'		},
				// { name	: 'toggle_pickups_import',					label: '_IMPORT_PICKUPS',					icon: 'bus'		},
			]				
		},

		'groupsButtons'		: {			
			name		: 'groups_buttons',
			title		: "_GROUPS_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				// { name	: 'add_arrival_group', 		label: '_ADD_ARRIVAL_GROUP', 	icon: 'plus'		},
				// { name	: 'add_departure_group', 	label: '_ADD_DEPARTURE_GROUP', 	icon: 'plus'		},
				{ name	: 'clear_groups', 			label: '_CLEAR_GROUPS', 		icon: 'bus'			}
			]
		},

		'group_assigned'	:	{
			cols		: 6,
			multiple	: true,
			action		: 'transferType',																		
			minSelected	: 1,																		
			items		: [	
				{ 	name: 'assigned',	label	: '_ASSIGNED', 		value: true	},
				{ 	name: 'unassigned',	label	: '_UNASSIGNED', 	value: true	}
			]
		},

		'servicesButtons'	: {			
			name		: 'services_buttons',
			title		: "_SERVICES_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items		: [							
				{	name	: 'export_services', 	
					label	: '_EXPORT_CSV', 			
					icon	: 'file'	
				},
				{ 	name	: 'toggle_lodgings', 	
					label	: '_SHOW_LODGINGS',		
					icon	: 'hotel'	
				},
				{ 	name	: 'get_fomento_info', 	
					label	: '_GET_FOMENTO_INFO',		
					icon	: 'bus'		
				},
				{ 	name	: 'clear_transporters', 
					label	: '_TRANSPORTERS',	
					icon	: 'bus'		
				}
			],
			selectors	: {
				'order'	:	{
					name		: 'direction',			
					title		: "_SERVICE_ORDER",
					action		: 'service_order',
					icon 		: "bus",
					format		: 'vertical',
					showTitle	: true,
					showLabel	: true,
					showIcon	: true,
					fontSize	: "0.6rem",
					filterCols	: 12,
					cols		: 6,
					multiple	: false,					
					minSelected	: 1,
					items: [
						{ 	name: 'service_order_by_first',	label	: '_FIRST_SERVICE'	},
						{ 	name: 'service_order_by_name',	label	: '_NAME'			}
					],
					selected	: 'service_order_by_name'
				}		
			}
		},

		'viewDriverButtons'		: {			
			name		: 'view_driver',
			title		: "_VIEW_DRIVER",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "viewDriver",
			items: [
				{ name	: 'calendar', 	label: '_CALENDAR'	, 	icon: 'calendar'	},
				{ name	: 'list', 		label: '_LIST'		, 	icon: 'list'		}
			],
			selected	: 'list'
		},

		'viewTransporterButtons'		: {			
			name		: 'view_driver',
			title		: "_VIEW_DRIVER",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "viewDriver",
			items: [
				// { name	: 'calendar', 	label: '_CALENDAR'	, 	icon: 'calendar'	},
				{ name	: 'list', 		label: '_LIST'		, 	icon: 'list'		}
			],
			selected	: 'list'
		},

		'viewTransferButtons'	: {			
			name		: 'view_transfer',
			title		: "_VIEW_TRANSFER",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "viewTransfer",
			items: [
				{ name	: 'vertical_stack', 	label: '_VERTICAL_STACK', 	icon: 'arrows-v'	},
				{ name	: 'vertical', 			label: '', 					icon: 'clock-o'		},
				{ name	: 'horizontal', 		label: '', 					icon: 'arrows-h'	},
				{ name	: 'horizontal_stack', 	label: '_HORIZONTAL_STACK', icon: 'clock-o'		}
			],
			// selected	: 'horizontal'
			selected	: 'vertical'
		},

		'exportButtons'	: {
			name		: 'export',
			title		: "_EXPORT",
			icon 		: "download",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 4,
			action		: "export",
			items: [
				{ name: 'xml', 	label: '_XML', 	icon: 'list', 		value: false 	},
				// { name: 'csv', 	label: '_CSV', 	icon: 'list', 		value: false 	},
				// { name: 'xls', 	label: '_XLS', 	icon: 'list', 		value: false	},
				{ name: 'pdf', 	label: '_PDF', 	icon: 'list', 		value: false	}
			]
		},

		'scaleButtons'	: {
			name		: 'factor',
			title		: "_SCALE",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			cols		: 3,
			action		: "scale",
			items		: [
				{ name: '1', 	label: 'x1', 		value: false	},
				{ name: '2', 	label: 'x2', 		value: false	},
				{ name: '4', 	label: 'x4', 		value: false	},
				{ name: '8', 	label: 'x8', 		value: false	}
			]
		},

		'calendarVehiclesButtons'		: {			
			name		: 'calendar_vehicles_buttons',
			title		: "_CALENDAR_VEHICLES_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'set_available_vehicles', 	label: '_SET_AVAILABLE_VEHICLES',	_icon: 'gear' },
				{ name	: 'set_vehicles', 				label: '_SET_ALL_VEHICLES',			_icon: 'gear' },
				{ name	: 'remove_unused_vehicles', 	label: '_REMOVE_UNUSED_VEHICLES',	_icon: 'gear' },
				{ name	: 'clear_row_vehicles', 		label: '_CLEAR_VEHICLES',			_icon: 'gear' },
				{ name	: 'clear_unused_vehicles', 		label: '_CLEAR_UNUSED_VEHICLES',	_icon: 'gear' }
			]				
		},

		'calendarServicesButtons'		: {			
			name		: 'calendar_services_buttons',
			title		: "_CALENDAR_SERVICES_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'set_first_services', 		label: '_SET_FIRST_SERVICES', 	_icon: 'gear'		},
				{ name	: 'generate_services',			label: '_GENERATE_SERVICES', 	_icon: 'gear'		},
				{ name	: 'clear_services', 			label: '_CLEAR_SERVICES', 		_icon: 'eraser'		}					
			]				
		},

		'calendarDriversButtons'		: {			
			name		: 'calendar_drivers_buttons',
			title		: "_CALENDAR_DRIVERS_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'set_services_drivers', 		label: '_SET_SERVICES_DRIVERS', 	_icon: 'gear'		},
				{ name	: 'clear_services_drivers', 	label: '_CLEAR_SERVICES_DRIVERS', 	_icon: 'eraser'		}
			]				
		},

		'calendarPlatesButtons'		: {			
			name		: 'calendar_plates_buttons',
			title		: "_CALENDAR_PLATES_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'set_services_plates', 		label: '_SET_SERVICES_PLATES', 		_icon: 'gear'		},
				{ name	: 'clear_services_plates', 		label: '_CLEAR_SERVICES_PLATES', 	_icon: 'eraser'		}
			]				
		},

		'servicesInfoButtons'		: {			
			name		: 'services_info_buttons',
			title		: "_SERVICES_INFO_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'show_lodgings', 		label: '_SHOW_LODGINGS', 	_icon: 'home'		}
			]				
		},

		'servicesFomentoButtons'		: {			
			name		: 'services_fomento_buttons',
			title		: "_SERVICES_FOMENTO_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'get_fomento_info', 	label: '_GET_FOMENTO_INFO',		icon: 'bus'		}
			
			]				
		},

		'servicesProvidersButtons'		: {			
			name		: 'services_providers_buttons',
			title		: "_SERVICES_PROVIDERS_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'notify_pickups', 	label: '_NOTIFY_PICKUPS',		icon: 'bus'		}				
			]				
		},

		'servicesTransportersButtons'		: {			
			name		: 'services_transporters_buttons',
			title		: "_SERVICES_TRANSPORTERS_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [							
				{ name	: 'select_pending_services', 				label: '_SELECT_PENDING_SERVICES',			icon: 'bus'		},
				{ name	: 'send_selected_pending_services', 		label: '_SEND_SELECTED_PENDING_SERVICES',	icon: 'bus'		},
				{ name	: 'clear_services_external_transporters', 	label: '_CLEAR_TRANSPORTERS',				icon: 'bus'		},
				{ name	: 'toggle_pickups_import',					label: '_IMPORT_PICKUPS',					icon: 'bus'		},
			]				
		},

		'servicesExportButtons'		: {			
			name		: 'services_export_buttons',
			title		: "_SERVICES_EXPORT_BUTTONS",
			icon 		: "eye",
			format		: 'vertical',
			showTitle	: true,
			showLabel	: true,
			showIcon	: true,
			fontSize	: "0.6rem",
			filterCols	: 12,
			cols		: 6,
			action		: "doButton",
			items: [
				{ name	: 'export_services', 	label: '_EXPORT_CSV', 			icon: 'file'	}	
			]				
		},

	}
};