import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { entities 				} from './data/items';
import { ChartService 			} from '../../../chart.service';

@Component({
  selector    		: 'app-company-conciliation',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyConciliationComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		views			: { items: [] },
		entities		: { services: {}, bookings: {}, invoice: {} }		
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService,
		private chartService	: ChartService
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'list',		label: '_LIST', 	icon: 'fa fa-list', 		type: 'button'	},
			{ 	name: 'bookings',	label: '_BOOKINGS',	icon: 'fa fa-users', 		type: 'button'	},
			{ 	name: 'invoice',	label: '_INVOICE',	icon: 'fa fa-file-text-o', 	type: 'button'	},
			{ 	name: 'stats',		label: '_STATS',	icon: 'fa fa-line-chart',	type: 'button'	}
		];
		this.pageInfo.status		= "list";
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };
				
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.entities["invoice"].form		= {
			ready		: true,
			entities	: this.pageInfo.entities,
			options		: { height: '60vh'	},
			buttons		: [
				{ name: 'save', 	label: '_SAVE', 	icon: 'floppy-o'	},
				{ name: 'cancel', 	label: '_CANCEL',	icon: 'times'		}
			],	
			form		: [
				{
					cols		: 4,
					showTitle	: false,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{	mandatory: true, 	field: 'invoice_number',			label		: '_INVOICE_NUMBER',			editor: 'text',			editable    : true,     type: 'text'			},   
						{	mandatory: true, 	field: 'status',					label		: '_STATUS',					editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_name',				label		: '_RECEPTOR_NAME',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_phone',			label		: '_RECEPTOR_PHONE',			editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_address',			label		: '_RECEPTOR_ADDRESS',			editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_zipcode',			label		: '_RECEPTOR_ZIPCODE',			editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_municipality',		label		: '_RECEPTOR_MUNICIPALITY',		editor: 'text',			editable    : true,     type: 'text'			},
						
					]
				}
				,{
					cols		: 4,
					showTitle	: false,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items	: [
						{	mandatory: true, 	field: 'receptor_region',			label		: '_RECEPTOR_REGION',			editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'receptor_country',			label		: '_RECEPTOR_COUNTRY',			editor: 'autocomplete',	editable    : true,     type: 'text',	entityList	: 'customerType'	},
						{	mandatory: true, 	field: 'service_name',				label		: '_SERVICE_NAME',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'service_description',		label		: '_SERVICE_DESCRIPTION',		editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'service_price_base',		label		: '_SERVICE_PRICE_BASE',		editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'service_price_discount',	label		: '_SERVICE_PRICE_DISCOUNT',	editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'service_price_vat',			label		: '_SERVICE_PRICE_VAT',			editor: 'text',			editable    : true,     type: 'text'			}						
					]
				}
				,{
					cols		: 4,
					showTitle	: false,
					icon		: "fa fa-check",
					padding		: "1rem 1rem 1rem 1rem",
					items	: [
						{	mandatory: true, 	field: 'service_price_taxes',		label		: '_SERVICE_PRICE_TAXES',		editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'service_payment_type',		label		: '_SERVICE_PAYMENT_TYPE',		editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'emision_date',				label		: '_EMISION_DATE',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'expiry_date',				label		: '_EXPIRY_DATE',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'payment_date',				label		: '_PAYMENT_DATE',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'payment_total',				label		: '_PAYMENT_TOTAL',				editor: 'text',			editable    : true,     type: 'text'			},
						{	mandatory: true, 	field: 'note',						label		: '_NOTE',						editor: 'text',			editable    : true,     type: 'text'			}
					]
				}
			]			
		}

		this.pageInfo.entities["services"].table	= {
			cols	: [
				{	field: 'id',						header		: '_ID',						width		:  '50px',	align: 'center', disabled	: true			},    
				{	field: 'date',						header		: '_DATE',						width		: '100px',	align: 'center', disabled	: false			},   
				{	field: 'provider',					header		: '_PROVIDER',					width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'transporter',				header		: '_TRANSPORTER',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'status',					header		: '_STATUS',					width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'service_type',				header		: '_SERVICE_TYPE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_direction',			header		: '_ROUTE_DIRECTION',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_from',				header		: '_ROUTE_FROM',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'route_destination',			header		: '_ROUTE_DESTINATION',			width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'paxes',						header		: '_PAXES',						width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'bookings_qty',				header		: '_BOOKINGS',					width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'vehicle_type',				header		: '_VEHICLE_TYPE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'vehicle_plate',				header		: '_VEHICLE_PLATE',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'vehicle_model',				header		: '_VEHICLE_MODEL',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'driver_name',				header		: '_DRIVER_NAME',				width		: '150px',	align: 'center', disabled	: true			},
				{	field: 'driver_license',			header		: '_DRIVER_LICENSE',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'issues_qty',				header		: '_ISSUES_QTY',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'departure_time_scheduled',	header		: '_DEPARTURE_TIME_SCHEDULED',	width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'departure_time_real',		header		: '_DEPARTURE_TIME_REAL',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'delivery_time_scheduled',	header		: '_DELIVERY_TIME_SCHEDULED',	width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'delivery_time_real',		header		: '_DELIVERY_TIME_REAL',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_distance',			header		: '_ROUTE_DISTANCE',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_duration',			header		: '_ROUTE_DURATION',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_cost',				header		: '_ROUTE_COST',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_earnings',			header		: '_ROUTE_EARNINGS',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_benefits',			header		: '_ROUTE_BENEFITS',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'inner_notes',				header		: '_INNER_NOTES',				width		: '200px',	align: 'center', disabled	: true			},
				{	field: 'driver_notes',				header		: '_DRIVER_NOTES',				width		: '200px',	align: 'center', disabled	: true			}
			],			
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh' },
			buttons	: [
				{ name: 'reload', 	label: '_RELOAD', 	icon: 'refresh'	},
				{ name: 'new', 		label: '_NEW', 		icon: 'plus'	}
			],
			filters	: [
				{
					entity	: 'services',
					name	: 'company_type',
					label	: '_COMPANY_TYPE',
					type	: 'multiple',
					field	: 'company_type',
					icon	: 'share-alt',
					items	: [
						{ label: '_PROVIDER',		value: 'provider' 		},
						{ label: '_TRANSPORTER',	value: 'transporter' 	}
					],
					selected: [ "provider", "transporter" ]
				},				
				{
					entity	: 'services',
					name	: 'status',
					label	: '_STATUS',
					type	: 'multiple',
					field	: 'shared',
					icon	: 'share-alt',
					items	: [
						{ label: '_PENDING',	value: 'shuttle' 	},
						{ label: '_ACTIVE',		value: 'private' 	},
						{ label: '_FINISHED',	value: 'private' 	}						
					],
					selected: [ "shuttle", "private" ]
				},
				{
					entity	: 'services',
					name	: 'service_type',
					label	: '_SERVICE_TYPE',
					type	: 'multiple',
					field	: 'service_type',
					icon	: 'share-alt',
					items	: [
						{ label: '_SHARED',		value: 'shuttle' 	},
						{ label: '_PRIVATE',	value: 'private' 	}
					],
					selected: [ "shuttle", "private" ]
				},								
				{
					entity	: 'services',
					name	: 'direction',
					label	: '_DIRECTION',
					type	: 'multiple',
					field	: 'direction',
					icon	: 'arrows',
					items	: [
						{ label: '_BOTH', 		value: 'both' 		},
						{ label: '_ARRIVAL', 	value: 'arrival' 	},
						{ label: '_DEPARTURE', 	value: 'departure' 	}
					],
					selected: [ "both", "arrival", "departure" ]
				}
			],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{
						_title	: "_INFO",
						items	: [
							// {	field: 'id',						label		: '_ID',						editable	: true, 	type: 'text'	},
							{	field: 'provider',					label		: '_PROVIDER',					editable	: false, 	type: 'text' 	},
							{	field: 'transporter',				label		: '_TRANSPORTER',				editable	: false, 	type: 'text' 	},
							{	field: 'status',					label		: '_STATUS',					editable	: false, 	type: 'text' 	},
							{	field: 'date',						label		: '_DATE',						editable	: false, 	type: 'text' 	},
							{	field: 'service_type',				label		: '_SERVICE_TYPE',				editable	: false, 	type: 'text' 	},
							{	field: 'route_direction',			label		: '_ROUTE_DIRECTION',			editable	: false, 	type: 'text' 	},
							{	field: 'route_from',				label		: '_ROUTE_FROM',				editable	: false, 	type: 'text' 	},
							{	field: 'route_destination',			label		: '_ROUTE_DESTINATION',			editable	: false, 	type: 'text' 	},
							{	field: 'paxes',						label		: '_PAXES',						editable	: false, 	type: 'text' 	},
							{	field: 'bookings_qty',				label		: '_BOOKINGS',					editable	: false, 	type: 'text' 	},
							{	field: 'issues_qty',				label		: '_ISSUES_QTY',				editable	: false, 	type: 'text' 	}							
						]
					}
					,{
						title	: "_DETAIL",
						_title	: "_ROUTE",
						items	: [
							{	field: 'vehicle_type',				label		: '_VEHICLE_TYPE',				editable	: false, 	type: 'text' 	},
							{	field: 'vehicle_plate',				label		: '_VEHICLE_PLATE',				editable	: true, 	type: 'text' 	},
							{	field: 'vehicle_model',				label		: '_VEHICLE_MODEL',				editable	: true, 	type: 'text' 	},
							{	field: 'driver_name',				label		: '_DRIVER_NAME',				editable	: false, 	type: 'text' 	},
							{	field: 'driver_license',			label		: '_DRIVER_LICENSE',			editable	: true, 	type: 'text' 	},
							{	field: 'departure_time_scheduled',	label		: '_DEPARTURE_TIME_SCHEDULED',	editable	: true, 	type: 'text' 	},
							{	field: 'departure_time_real',		label		: '_DEPARTURE_TIME_REAL',		editable	: false, 	type: 'text' 	},
							{	field: 'delivery_time_scheduled',	label		: '_DELIVERY_TIME_SCHEDULED',	editable	: true, 	type: 'text' 	},
							{	field: 'delivery_time_real',		label		: '_DELIVERY_TIME_REAL',		editable	: false, 	type: 'text' 	}
						]
					}
					,{
						_title	: "_MISC",
						items	: [
							{	field: 'route_distance',			label		: '_ROUTE_DISTANCE',			editable	: false, 	type: 'text' 	},
							{	field: 'route_duration',			label		: '_ROUTE_DURATION',			editable	: false, 	type: 'text' 	},
							{	field: 'route_cost',				label		: '_ROUTE_COST',				editable	: false, 	type: 'text' 	},
							{	field: 'route_earnings',			label		: '_ROUTE_EARNINGS',			editable	: false, 	type: 'text' 	},
							{	field: 'route_benefits',			label		: '_ROUTE_BENEFITS',			editable	: false, 	type: 'text' 	},
							{	field: 'inner_notes',				label		: '_INNER_NOTES',				editable	: true, 	type: 'text' 	},
							{	field: 'driver_notes',				label		: '_DRIVER_NOTES',				editable	: true, 	type: 'text' 	},
							// { 	field	: 'email', 		label:'_EMAIL', 	editable: false, 	type: 'text',	renderer: 'custom', 
							//   	rendererFn: ($me, $type, $col, $items) => { 
							// 		switch ($type) {
							// 			case 'header'	: return {};
							// 			case 'style'	: return { color: 'red' };
							// 			case 'content'	: return $items[$col.field];
							// 			case 'expander'	: return {
							//                 'color'			: 'green',
							// 				'font-weight'	: 700
							//             };
							// 		}								
							// 	}	
							// }
						]
					}
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'	},
					{ name: 'invoice',		label: '_INVOICE',		action: 'createInvoice', icon: 'text-o'		}
				]
			}
		};

		this.pageInfo.entities["bookings"].table	= {
			cols	: [
				{	field: 'id',						header		: '_ID',						width		:  '50px',	align: 'center', disabled	: true			},    
				{	field: 'date',						header		: '_DATE',						width		: '100px',	align: 'center', disabled	: false			},   
				{	field: 'provider',					header		: '_PROVIDER',					width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'transporter',				header		: '_TRANSPORTER',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'status',					header		: '_STATUS',					width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'service_type',				header		: '_SERVICE_TYPE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_direction',			header		: '_ROUTE_DIRECTION',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_from',				header		: '_ROUTE_FROM',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'route_destination',			header		: '_ROUTE_DESTINATION',			width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'paxes',						header		: '_PAXES',						width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'bookings_qty',				header		: '_BOOKINGS',					width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'vehicle_type',				header		: '_VEHICLE_TYPE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'vehicle_plate',				header		: '_VEHICLE_PLATE',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'vehicle_model',				header		: '_VEHICLE_MODEL',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'driver_name',				header		: '_DRIVER_NAME',				width		: '150px',	align: 'center', disabled	: true			},
				{	field: 'driver_license',			header		: '_DRIVER_LICENSE',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'issues_qty',				header		: '_ISSUES_QTY',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'departure_time_scheduled',	header		: '_DEPARTURE_TIME_SCHEDULED',	width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'departure_time_real',		header		: '_DEPARTURE_TIME_REAL',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'delivery_time_scheduled',	header		: '_DELIVERY_TIME_SCHEDULED',	width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'delivery_time_real',		header		: '_DELIVERY_TIME_REAL',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'route_distance',			header		: '_ROUTE_DISTANCE',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_duration',			header		: '_ROUTE_DURATION',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_cost',				header		: '_ROUTE_COST',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_earnings',			header		: '_ROUTE_EARNINGS',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'route_benefits',			header		: '_ROUTE_BENEFITS',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'inner_notes',				header		: '_INNER_NOTES',				width		: '200px',	align: 'center', disabled	: true			},
				{	field: 'driver_notes',				header		: '_DRIVER_NOTES',				width		: '200px',	align: 'center', disabled	: true			}
			],			
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh' },
			buttons	: [
				{ name: 'reload', 	label: '_RELOAD', 	icon: 'refresh'	},
				{ name: 'new', 		label: '_NEW', 		icon: 'plus'	}
			],
			filters	: [],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{
						items	: [
							// {	field: 'id',						label		: '_ID',						editable	: true, 	type: 'text'	},
							{	field: 'provider',					label		: '_PROVIDER',					editable	: false, 	type: 'text' 	},
							{	field: 'transporter',				label		: '_TRANSPORTER',				editable	: false, 	type: 'text' 	},
							{	field: 'status',					label		: '_STATUS',					editable	: false, 	type: 'text' 	},
							{	field: 'date',						label		: '_DATE',						editable	: false, 	type: 'text' 	},
							{	field: 'service_type',				label		: '_SERVICE_TYPE',				editable	: false, 	type: 'text' 	},
							{	field: 'route_direction',			label		: '_ROUTE_DIRECTION',			editable	: false, 	type: 'text' 	},
							{	field: 'route_from',				label		: '_ROUTE_FROM',				editable	: false, 	type: 'text' 	},
							{	field: 'route_destination',			label		: '_ROUTE_DESTINATION',			editable	: false, 	type: 'text' 	},
							{	field: 'paxes',						label		: '_PAXES',						editable	: false, 	type: 'text' 	},
							{	field: 'bookings_qty',				label		: '_BOOKINGS',					editable	: false, 	type: 'text' 	},
							{	field: 'issues_qty',				label		: '_ISSUES_QTY',				editable	: false, 	type: 'text' 	}							
						]
					}
					,{
						items	: [
							{	field: 'vehicle_type',				label		: '_VEHICLE_TYPE',				editable	: false, 	type: 'text' 	},
							{	field: 'vehicle_plate',				label		: '_VEHICLE_PLATE',				editable	: true, 	type: 'text' 	},
							{	field: 'vehicle_model',				label		: '_VEHICLE_MODEL',				editable	: true, 	type: 'text' 	},
							{	field: 'driver_name',				label		: '_DRIVER_NAME',				editable	: false, 	type: 'text' 	},
							{	field: 'driver_license',			label		: '_DRIVER_LICENSE',			editable	: true, 	type: 'text' 	},
							{	field: 'departure_time_scheduled',	label		: '_DEPARTURE_TIME_SCHEDULED',	editable	: true, 	type: 'text' 	},
							{	field: 'departure_time_real',		label		: '_DEPARTURE_TIME_REAL',		editable	: false, 	type: 'text' 	},
							{	field: 'delivery_time_scheduled',	label		: '_DELIVERY_TIME_SCHEDULED',	editable	: true, 	type: 'text' 	},
							{	field: 'delivery_time_real',		label		: '_DELIVERY_TIME_REAL',		editable	: false, 	type: 'text' 	}
						]
					}
					,{
						items	: [
							{	field: 'route_distance',			label		: '_ROUTE_DISTANCE',			editable	: false, 	type: 'text' 	},
							{	field: 'route_duration',			label		: '_ROUTE_DURATION',			editable	: false, 	type: 'text' 	},
							{	field: 'route_cost',				label		: '_ROUTE_COST',				editable	: false, 	type: 'text' 	},
							{	field: 'route_earnings',			label		: '_ROUTE_EARNINGS',			editable	: false, 	type: 'text' 	},
							{	field: 'route_benefits',			label		: '_ROUTE_BENEFITS',			editable	: false, 	type: 'text' 	},
							{	field: 'inner_notes',				label		: '_INNER_NOTES',				editable	: true, 	type: 'text' 	},
							{	field: 'driver_notes',				label		: '_DRIVER_NOTES',				editable	: true, 	type: 'text' 	}							
						]
					}
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'	},
					{ name: 'invoice',		label: '_INVOICE',		action: 'createInvoice', icon: 'text-o'		}
				]
			}
		};

		await this.initFilters("services");
		await this.initFilters("bookings");
		await this.initMenu("services");
		await this.initMenu("bookings");
	}

	async initFilters($entity)	{
		this.pageInfo.entities[$entity].filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		
	}

	checkFilter(filter)			{	switch(filter.type){
										case "multiple":	this.checkMultipleFilter(filter); 	break;
										default:			this.checkSimpleFilter(filter);		break;
									}
	}

    checkMultipleFilter(filter)	{
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= {
																				field	: filter.field,
																				options	: filter.items.map(item=>item.value),
																				selected: filter.selected
																			};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this.pageInfo.entities[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this.pageInfo.entities[$entity].data			|| [];
		let filters = this.pageInfo.entities[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "shared":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		this.pageInfo.entities[$entity].filteredData	= data;
		this.pageInfo.entities[$entity].count 		= this.pageInfo.entities[$entity].filteredData ? this.pageInfo.entities[$entity].filteredData.length : 0;
		// this.paginateLocal($entity, null);
	}

	filterPending(filter){}

	async initMenu($entity){
		let me									= this;
		this.pageInfo.entities[$entity].menu	= [];
		this.pageInfo.entities[$entity].filters.forEach(async filter=>{
			let item 		= {
				label		: await this.commons.getTranslate(filter.label),
				icon		: filter.icon?('fa fa-'+filter.icon):'',
				style		: { padding: '5px 10px' },
				items		: []
			};
			filter.items.forEach(async current=> {
				let element	= {
					label		: 	await this.commons.getTranslate(current.label),
					icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
					command		: 	($event) => { me.doMenuAction(current.value,filter,$entity); }
				}
				item["items"].push(element);
			});
			this.pageInfo.entities[$entity].menu.push(item);
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter,$entity){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		$filter.selected = $filter.selected.some(item=>item===$item)
							?$filter.selected.filter(item=>item!==$item)
							:[...$filter.selected,$item];
		this.checkFilter($filter);		// Execute filtering
		this.initMenu($entity);		// Regenerate menu to show selection
	}

	async loadEntities()		{ 	await this.load("services");
									await this.load("bookings");
								}
  	async ngOnInit()			{	await this.init(); await this.loadEntities();			}
    onUpload(event) 			{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	async action($type,$item)			{
		switch($type){
			case "button"			:	switch($item.name){		
											case "reload"		: 	this.loadEntities();												break;
											case "list"			:	this.pageInfo.status = $item.name;									break;
											case "bookings"		:	this.pageInfo.status = $item.name;									break;
											case "invoice"		:	this.pageInfo.status = $item.name;									break;
											case "stats"		:	this.pageInfo.status = $item.name;	
																	if(this.pageInfo.entities["services"].selected){
																		this.pageInfo.entities["services"].charts = this.chartService.generateCharts({
																			entity		:	this.pageInfo.entities["services"],
																			title		: 	await this.commons.getTranslate('_PAYMENTS'),
																			chartName	:	"payment",
																			datasets	:[
																				{ label: await this.commons.getTranslate("_TRANSPORTER3"), 	color: '#59c429', data: Array.from(Array(30).keys()).map(item=>item<10?0:-425) 				},
																				{ label: await this.commons.getTranslate("_PROVIDER"), 		color: '#9c4290', data: Array.from(Array(30).keys()).map(item=>item<15?0: 650)				},
																				{ label: await this.commons.getTranslate("_TOTAL"), 		color: '#c42931', data: Array.from(Array(30).keys()).map(item=>item<10?0:(item>15?125:-425))}
																			]
																		});
																	}
																	break;
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
										}
										break;
			case "tableServices"	:	switch($item.type){									
											case "new"			:	this.pageInfo.entities["services"].data.push({});					break;
											case "reload"		: 	this.loadEntities();												break;
											case "selectedRow"	:	//this.commons.generateToast("_INFO","Selected Item","info");
																	this.pageInfo.entities["services"].selected = $item.item;
																	break;
											case "saveRow"		:	this.commons.generateToast("_INFO","Save row","info");				break;
											case "createInvoice":	this.commons.generateToast("_INFO","Create invoice","info");		break;											
										}
										break;
		}
	}
	getFilteredEntity($entity)	{
		switch($entity){
			case "services"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
			case "bookings"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});	
			default			:	return [];
		}
	}
	async load($entity)			{
		let response = {};
		switch($entity){
			case 'services' :
				response 								= 	await Promise.resolve(this.entityService.getRequest('transfer_services'));
				// response["data"]						=	serviceFakeData;						
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;
			case 'bookings' :
				response 								= 	await Promise.resolve(this.entityService.getRequest('transfer_services'));
				// response["data"]						=	serviceFakeData;						
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;
		}
	}
}
