import { CommonsService } from 'src/app/demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild} from '@angular/core';
import * as moment                from 'moment';
import { CalendarMonthViewDay 	} from "angular-calendar";
import { filters 				} from './data/filters';
import { Message				} from 'primeng/components/common/api';
import { MessageService			} from 'primeng/components/common/messageservice';
@Component({
  selector    : 'tours-pricing',
  templateUrl : './tours-pricing.component.html',
  styleUrls   : ['./tours-pricing.component.scss'] ,

})
export class ToursPricingComponent implements OnInit {

	@Input() item	: any	= null;
    @ViewChild ('monthViewCalendar') monthViewCalendar   : any;
	
	@ViewChild('dayCalendar')	dayCalendar		:	any;	

	locale		: string	= 'es';
    items 		: any;
    pageInfo    : any 		=   {
		currency			:	'€',
		pvdBenefit			:	15,
		displayComputedDay	:	false,
		dateSelected		:	null,
		selectedOptionToAdd	:	null,
		templateDayOffer	:	{ offers: [] },
		execsSelecteds		:	[],
        listDays            :   [],
        viewDate            :	new Date(),
        selectedTypePrice   :   null
    }
    constructor( private commons :   CommonsService){}
    ngOnInit()	{ this.init(); }
    init()		{
        this.items = [{
            label: 'File',
            items: [
                {label: 'New', icon: 'fa fa-plus', command: (event) => {
                    //event.originalEvent: Browser event
                    //event.item: menuitem metadata
                    console.log(event);
                }}
            ]
        }]
        this.pageInfo.monthStepper  =   [
            { label : this.commons.getTranslate('_MONTH'), value : 'previous', 	iconPos:'left', 	icon:'fa fa-arrow-left'		},
            { label : this.commons.getTranslate('_MONTH'), value : 'next', 		iconPos:'left',		icon:'fa fa-arrow-right'	}
        ];
        this.pageInfo.typePrices    =   [
            { label :   this.commons.getTranslate('_PVP'), value : 'pvp'	},
            { label :   this.commons.getTranslate('_PVD'), value : 'pvd'	}
		];
		this.pageInfo.profiles		=	[
			{ label :	this.commons.getTranslate('_adult'), 	value 	: 	'adult'		},
			{ label :	this.commons.getTranslate('_CHILDREN'), value 	: 	'children'	},
			{ label :	this.commons.getTranslate('_infant'), 	value	:	'infant'	}
		];
        this.pageInfo.listPromotions	=	[
			{ label	: '2x1', value:	'2x1' },
			{ label	: '3x2', value:	'3x2' },
			{ label	: '4x3', value:	'4x3' },
			{ label	: '7x5', value:	'7x5' },
		];
		this.pageInfo.finalPriceMode	=	[
			{ value : 'computedPrice', 	title: this.commons.getTranslate('_COMPUTED_PRICE'	),	icon: 'fa fa-fw fa-bold'	},
            { value : 'fixedPrice', 	 title: this.commons.getTranslate('_FIXED_PRICE'		), 	icon: 'fa fa-fw fa-italic'	}
		];
		this.pageInfo.listDiscounts		=	[
			{ label	: this.commons.getTranslate('_ADD_OFFER'), 	value : 'offer'	}
		];

        this.initFilters();
        this.initMenu();
        this.mountCalendar();
    }
    
    initializeCalendarInfo(){
        
    }

    mountCalendar(){
        console.log('MOUNTING CALENDAR');
		this.mountComposedDays();
		console.log('MOUNTED DAYS', this.item);
		this.updateViewCalendar();
	}
	
	updateViewCalendar(){
		setTimeout(()=>{
			this.monthViewCalendar.view.days =  this.monthViewCalendar.view.days.map(el =>{
				const foundDay  =   this.item.calendar.compositor.find(item => moment(el.date).format() == moment(item.day).format());
	
				return {...el, infoDay : foundDay}
			});
		});
	}
    getTitleCalendar(){
		return moment(this.pageInfo.viewDate).locale(this.commons.getActiveLang()).format('MMMM, YYYY');
    }
    
    changeMonth(type){
		console.log(type);
		switch(type){
			case 'previous' : this.previousMonth(); break;
			case 'next'		: this.nextMonth();		break;
		}
		this.updateViewCalendar();
	}
    previousMonth(){
        this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() - 30));
        console.log(this.pageInfo.viewDate);

    }
    nextMonth(){
        this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() + 30));
        console.log(this.pageInfo.viewDate);

    }


    // --------------------------------------------------------------------------------------
// MENU
// --------------------------------------------------------------------------------------
    initFilters(){
        this.pageInfo.filters = filters;		
    }
	initMenu(){
		let me					= this;
		this.pageInfo.menu		= {};
		console.log("FILTERS",this.pageInfo.filters);
		Object.keys(this.pageInfo.filters).forEach(async entity=>{
			// Each entity could have many filters
			let entityFilters	= this.pageInfo.filters[entity];
			console.log("Generation menu for",entity,entityFilters);
			this.pageInfo.menu[entity]	= [];
			entityFilters.forEach( async filter => {
				let item 		= { 
					label		: await this.commons.getTranslate(filter.label),
					icon		: filter.icon?('fa fa-'+filter.icon):'',
					style		: { padding: '5px 10px' },
					items		: []
				};
				filter.items.forEach( async current=> {
					let element	= {
						label		: 	await this.commons.getTranslate(current.label),
						icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
						command		: 	($event) => { 
                                            console.log("MENU ITEM SELECTED",$event,current);
											me.doMenuAction(current.value,filter);
                                            this.displayCalendarElements();
										}
					}
					item["items"].push(element);
				});
				this.pageInfo.menu[entity].push(item);
			});
		});
    }
    
    doMenuAction($item,$filter){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		// $filter.selected = $filter.selected.some(item=>item===$item)
		// 					?$filter.selected.filter(item=>item!==$item)
		// 					:[...$filter.selected,$item];
		if($filter.selected.some(item=>item===$item)){
			$filter.selected = $filter.selected.filter(item=>item!==$item);
		} else {
			$filter.selected = [...$filter.selected,$item];
		}
		// this.checkFilter($filter);		// Execute filtering
		this.initMenu();				// Regenerate menu to show selection
    }

    displayCalendarElements(){
        console.log(this.pageInfo.filters.calendar.selected);
	}
	displayType(category,filter,type){
		try{
			return this.pageInfo.filters[category].find(el => el.name == filter).selected.some(el => el == type);
		}catch(e){
			return false;
		}
	}

	getDayBackgroundColor(day){
		if(day.selected){	return '#ff9b7b';}

        if(day.rangeMode){   return '#8a66ca';}

        
        if(day.isPast){      return '#e8e8e8';}
        if(day.isToday){     return '#b4eab4';}
        if(day.isWeekend){   return '#d1ecf5';}
	}
	getSchedulerBackground(schedule){
		return schedule.dirty ? '#009688' : 'tomato';
	}
	getOffersBackground(exec){
		return exec.custom	?	'#009688'	:	'#a815a8';
	}
	
	mountComposedDays(){
		this.item.calendar.compositor = this.item.calendar.compositor.map(day =>{
			{return {...day, computed : this.composeDay(day)}}
		});

	}
	composeDay(day){
		// if(!day.computed) return null;

		if(!day.schedule || !day.schedule.scheduler) return null;
		return day.schedule.scheduler.map(exec =>{
			return this.mountCompletedDay(exec,day,day.computed);
		});
	}

	mergeComposedDay(day){
		if(!day.schedule || !day.schedule.scheduler) return null;
		let test =  day.schedule.scheduler.map(exec =>{
			return this.mountCompletedDay(exec,day, day.computed);
		}); 
		console.log(day);
		console.log(test);
	}
    clickedDay(event, day){
		console.log(day);
		const temp = day.selected ? day.selected : false;
		this.unselectDays();
		day.selected = !temp;
		if(!day.selected){
			this.pageInfo.currentDaySelected = null;
			this.pageInfo.displayComputedDay = false;
			return;
		}
        if(!day.infoDay){
			this.pageInfo.currentDaySelected = null;
			this.pageInfo.displayComputedDay = false;
			return;	
		}
		this.pageInfo.dateSelected 		 	 = {formatedDate : moment(day.date).format('DD-MM-YYYY'), date	:	moment(day.date).format()}

		this.pageInfo.currentDaySelected = day.infoDay.computed;
		this.pageInfo.displayComputedDay = true;
	}

	getActiveOffers(apply_offers,filteredOffers,profile){
		return filteredOffers.filter(el => el.profiles.indexOf(profile) > -1).filter(exec => 
			apply_offers.find(el => ((el.id == exec.id) && (el.execs.find(item => item.target == profile).active == true))))
	}
	mountCompletedDay(exec,infoDay, computed?){
		let computedExec;
		if(computed){
			computedExec = computed.find(el => el.hour == exec.start_hour);
		}
		let filteredOffers		=	((infoDay.offers || {}).offers || []).filter(item => ((exec.start_hour >= item.startTime) && (exec.start_hour <= item.endTime)) && ((item.type.code == 'discount') || (item.type.code == 'absolute_price')));
		let filteredPromotions	=	((infoDay.offers || {}).offers || []).filter(item => ((exec.start_hour >= item.startTime) && (exec.start_hour <= item.endTime)) && (item.type.code == 'promotion'));
		let apply_offers		=	this.applyOffersExec(filteredOffers,computedExec || null);
		let apply_promotions	=	this.applyPromotionsExec(filteredPromotions,computedExec || null);
		let pvdPrices			=	{
			adult		:	this.getFinalPriceByType(this.getActiveOffers(apply_offers,filteredOffers,'adult'), JSON.parse(JSON.stringify(exec.prices.find(el=> el.type=='adult').price))),
			children	:	this.getFinalPriceByType(this.getActiveOffers(apply_offers,filteredOffers,'children'),JSON.parse(JSON.stringify(exec.prices.find(el=> el.type=='children').price))),
			infant		:	this.getFinalPriceByType(this.getActiveOffers(apply_offers,filteredOffers,'infant'),JSON.parse(JSON.stringify(exec.prices.find(el=> el.type=='infant').price)))
		}
		let pvpPrices			=	{
			adult		:	this.getPvpPrice(pvdPrices.adult),
			children	:	this.getPvpPrice(pvdPrices.children),
			infant		:	this.getPvpPrice(pvdPrices.infant)
		}
		const mountedDay		=	
		{
			hour			:	exec.start_hour,
			basePrice		:	exec.prices,
			dirty			:	exec.dirty || false ,
			apply_offers	:	apply_offers,
			apply_promotions:	apply_promotions,
			pvd		:	[
				{
					type		:	'adult',
					activePrice	:	'computedPrice',
					price		:	{
						computedPrice 	: pvdPrices.adult,
						fixedPrice		: computedExec ? computedExec.pvd.find(el=>el.type == 'adult').price.fixedPrice : null
					}
				},
				{
					type		:	'children',
					activePrice	:	'fixedPrice',
					price		:	{
						computedPrice 	: 	pvdPrices.children,
						fixedPrice		:	computedExec ? computedExec.pvd.find(el=>el.type == 'children').price.fixedPrice : null
					}
				},
				{
					type		:	'infant',
					activePrice	:	'computedPrice',
					price		:	{
						computedPrice	:	pvdPrices.infant,
						fixedPrice 		:	computedExec ? computedExec.pvd.find(el=>el.type == 'infant').price.fixedPrice : null 
					}
				}
			],
			pvp		:	[
				{
					type		:	'adult',
					activePrice	:	'computedPrice',
					price		:	{
						computedPrice 	: pvpPrices.adult,
						fixedPrice		: computedExec ? computedExec.pvp.find(el=>el.type == 'adult').price.fixedPrice : null
					}
				},
				{
					type		:	'children',
					activePrice	:	'fixedPrice',
					price		:	{
						computedPrice 	: 	pvpPrices.children,
						fixedPrice		:	computedExec ? computedExec.pvp.find(el=>el.type == 'children').price.fixedPrice : null
					}
				},
				{
					type		:	'infant',
					activePrice	:	'computedPrice',
					price		:	{
					computedPrice	:	pvpPrices.infant,
					fixedPrice 		:	computedExec ? computedExec.pvp.find(el=>el.type == 'infant').price.fixedPrice : null 
					}
				}
			]
		};
		return mountedDay;
	}

	applyOffersExec(filteredOffers,computed?){
		return filteredOffers.map(filteredOffer => {
			console.log('FILTEREDOFFER', filteredOffer);
			let activeOffers;
			if(computed){
				activeOffers	=	(computed.apply_offers || []).find(el => el.id == filteredOffer.id);
			}
			 
			return{
				id		:	filteredOffer.id,
				execs	:	this.mountOffersObject(filteredOffer,activeOffers)
		}});
	}
	mountOffersObject(filteredOffer, activeOffers?){
		return this.pageInfo.profiles.map(el => {
			let foundProfile	=	filteredOffer.profiles.find(profile => profile == el.value);
			
			if(foundProfile){
				let offerActive;
				if(activeOffers){
					offerActive = activeOffers.execs.find(item => item.target == el.value).active;
				}else{
					offerActive = true;
				}
				return {
					target 	: foundProfile, 
					type	: filteredOffer.type.code,
					value	: filteredOffer.value,
					label 	: "-"+filteredOffer.value + ( filteredOffer.type.code == "discount"?"%":this.pageInfo.currency ),	
					active	: offerActive
				}	
			}else{
				return {
					target	: el.value,
					type	: null,
					value	: null,
					label	: null,
					active	: false,
				}
			}
		});
	}
	applyPromotionsExec(filteredPromotions,computed?){
		return filteredPromotions.map(filteredPromotion => {
			let activePromotions;
			if(computed){
				activePromotions	=	(computed.apply_promotions || []).find(el => el.id == filteredPromotion.id);
			}
			return {
				id		:	filteredPromotion.id,
				execs	:	this.mountPromotionsObject(filteredPromotion,activePromotions)
			}
		});
	}

	mountPromotionsObject(filteredPromotion,activePromotions?){
		return this.pageInfo.profiles.map(el => {
			let foundProfile	=	filteredPromotion.profiles.find(profile => profile == el.value);
			if(foundProfile){
				let promotionActive;
				if(activePromotions){
					promotionActive = activePromotions.execs.find(item => item.target == el.value).active;
				}else{
					promotionActive = true;
				}
				return {
					id		: filteredPromotion.id,
					target 	: foundProfile, 
					type	: filteredPromotion.type.code,
					value	: filteredPromotion.value,
					label 	: filteredPromotion.value,
					active  : promotionActive,	
				}	
			}else{
				return {
					target	: el.value,
					active  : false,
					id		: null,
					type	: null,
					value	: null,
					label	: null
				}
			}
		});
	}
	getFinalPriceByType(filteredOffers, basePrice){
		let finalPrice	=	basePrice;
		filteredOffers.forEach(offer =>{
			let type	=	offer.type.code ? offer.type.code : offer.type;
			switch(type){
				case 'discount'			: 	
					const discountToApply 	= 	+(finalPrice * +(offer.value / 100));
					finalPrice 				= 	finalPrice - discountToApply;
					break;
				case 'absolute_price'	:	finalPrice	=	finalPrice - (+offer.value); break;
			}
		});

		return finalPrice < 0 ? 0 : finalPrice;
	}
	getPvpPrice(pvd){return (pvd / (1-(this.pageInfo.pvdBenefit / 100))).toFixed(2);}
	calcPvd(pvp){
	}
	unselectDays(){
		this.monthViewCalendar.view.days.map(el => el.selected = false);
	}
	getDaySelected(){
		return this.monthViewCalendar.view.days.find(el => el.selected);
	}
	changeValuePromotion($event, exec, item, type){
		const daySelected		=	this.getDaySelected();
		if(!daySelected) return;
		const findDaySchedule	=	this.item.calendar.offers.days.find(el => el.day == daySelected.infoDay.day);
		if(findDaySchedule){
			const foundOffer 	= findDaySchedule.offers.find(el => el.id == type.id);
			if(foundOffer){
				foundOffer.value = $event.value;
			}
		}else{
			console.log('not found');
		}
	}

	focusInputFinalPrice($event, item, exec){
		let foundExec	=	this.pageInfo.currentDaySelected.find(el => el.hour == exec.hour);
		if(!foundExec)	{	return this.pageInfo.tempInputFinalPrice = {}};
		let foundTarget	=	foundExec.pvp.find(el => item.type == el.type);
		if(!foundTarget){	return this.pageInfo.tempInputFinalPrice = {}};

		this.pageInfo.tempInputFinalPrice	=	JSON.parse(JSON.stringify(foundTarget));
	}
	blurInputFinalPrice($event,item,exec){
		console.log(this.pageInfo.tempInputFinalPrice);
		if(this.pageInfo.tempInputFinalPrice.price != item.price){
			const currentDay 	= moment(this.getDaySelected().date).format();
			let foundDay 		= this.item.calendar.compositor.find(el => moment(el.day).format() == currentDay);
			if(!foundDay && !foundDay.computed) return;
			foundDay.computed.find(el => el.hour == exec.hour).modified = true;
		}
	}

	toggleOffer($event,type,item,exec){
		this.reloadBasePrice(type,exec);	
	}

	changeValueInputOffer($event, type, exec){

		let value = $event.target.value;
		if(value <0 || value >= 100 || value == "" || value.includes('-')){
			type.value			= null;
			return;
		};
		this.reloadBasePrice(type,exec);
	}
	checkValueInputOffer($event, type, exec){
		if($event.target.value == ""){
			type.value = 0;
			this.reloadBasePrice(type,exec);
		}
	}
	reloadBasePrice(type, exec){

		let target 							= 	type.target;

		let foundProfile					=	exec.pvd.find(el => el.type == type.target);
		if(!foundProfile) return;
		let offersFromProfile				=	[].concat.apply([],exec.apply_offers.map(offer => offer.execs));
		
		let filteredTargetOffers			= 	offersFromProfile.filter(offers => (offers.target == type.target) && (offers.active));

		
		foundProfile.price.computedPrice = 	this.getFinalPriceByType(filteredTargetOffers, JSON.parse(JSON.stringify(exec.basePrice.find(el=> el.type==target).price)))
		let pvpTypeFound				 =	exec.pvp.find(el => el.type == type.target);
		if(pvpTypeFound){
			pvpTypeFound.price.computedPrice 	=	this.getPvpPrice(foundProfile.price.computedPrice);
		}	
	}

	selectExec(exec){
		const foundExec = this.pageInfo.execsSelecteds.findIndex(el => el.hour == exec.hour);
		foundExec > -1 ? this.pageInfo.execsSelecteds.splice(foundExec,1) : this.pageInfo.execsSelecteds.push(exec);
	}
	isExecSelected(exec){
		return this.pageInfo.execsSelecteds.find(el => el.hour == exec.hour) ? '#ffefd8' : 'white';
	}

	handleOffersDay($event){

	}
	
	handlePromotionsDay($event){
		console.log($event);
		switch($event.type){
			case 'lastRow'	: 	this.addPromotionDay($event.data.exec)	 ;break;
			case 'update'	:	this.updatePromotionDay($event.data.exec);break;
			case 'delete'	:	this.deletePromotionDay($event.data.exec);break;
		}
	}
	addPromotionDay(data){
		this.pageInfo.templateDayOffer.offers.map(el => el.editMode = false);
		let newExec	=	data;
		newExec.id	=	this.commons.generateHash();
		this.pageInfo.templateDayOffer.offers.push(newExec);
		this.updatePromotionDay(newExec);
	}
	addNewPromotionDay(){

        this.pageInfo.templateDayOffer.offers.map(el => el.editMode = false);
        this.pageInfo.templateDayOffer.offers.push({
            id          : this.commons.generateHash(),
            editMode    : false,
            startTime   : '00:00',
            endTime     : '23:59',
            type        : {},
            value       : null,
            profiles    : ['adult','children','infant'],
            turns       : []
        });
	}
	updatePromotionDay(data){
		let foundRow	=	this.pageInfo.templateDayOffer.offers.findIndex(el => el.id == data.id);
		if(foundRow > -1){
			this.pageInfo.templateDayOffer.offers[foundRow] = data;
			let foundDay	=	this.item.calendar.compositor.find(el => el.day == this.pageInfo.dateSelected.date);

			if(!foundDay){return false};

			// Actually commented because we let multiple proomotions apply
			const tmp = 25;
			if(data.type.code == 'promotion' && tmp != tmp){
				let alreadyPromotions	=	this.pageInfo.execsSelecteds.filter(exec =>{
					return data.profiles.filter(profile =>{
						let promotions	=	[].concat.apply([],exec.apply_promotions.map(offer => offer.execs))
						return promotions.find(promotion => (promotion.target == profile) && (promotion.value != null));
					}).length >0
				});
				if(alreadyPromotions.length >0){
					this.commons.generateToast('_ERROR','_SOME_PROFILES_ALREADY_PROMOTIONS','error');
					data.editMode 	=	true;
					data.wasValid	=	'exceedPromotions';
					return;	
				}
			}
			let badScheduleTime	=	this.pageInfo.execsSelecteds.filter(exec =>{
				return (exec.hour < data.startTime || exec.hour > data.endTime);
			});
			if(badScheduleTime.length >0){
				this.commons.generateToast('_ERROR','_SOME_EXECS_ARE_NOT_IN_SCHEDULE','error');
				data.editMode 	=	true;
				data.wasValid	=	'exceedPromotions';
				return;
			}
			

			this.pageInfo.templateDayOffer.offers.forEach(offer =>{
				this.pageInfo.execsSelecteds.forEach(execSelected =>{
					offer.custom	=	true;
					if(offer.type.code == 'promotion'){
						let foundExec 	= 	execSelected.apply_promotions.findIndex(el => el.id == offer.id);
						if(foundExec > -1){
							execSelected.apply_promotions[foundExec].execs = this.mountPromotionsObject(offer);
							
						}else{
							execSelected.apply_promotions.push({id:offer.id, execs : this.mountPromotionsObject(offer)});
							
						}
					}else{
						let foundExec = execSelected.apply_offers.findIndex(el => el.id == offer.id);
						if(foundExec > -1){
							execSelected.apply_offers[foundExec].execs = this.mountOffersObject(offer);
						}else{
							execSelected.apply_offers.push({id : offer.id, custom	:	offer.custom, execs : this.mountOffersObject(offer)});
							
						}
						offer.profiles.forEach(profile =>{
							console.log(profile);
							this.reloadBasePrice({target:profile}, execSelected);
						})
					}
					this.updateOfferConcreteDay(foundDay,offer);
					this.updateOfferDayCalendar(offer);
					this.updateScheduleDayCalendar(execSelected.hour);
					console.log('LIST UPDATED',this.item);
				});
			});
		}		
	}
	updateScheduleDayCalendar(hour){
		let foundDay	=	this.item.calendar.schedule.days.find(el => el.day == this.pageInfo.dateSelected.date);
		if(!foundDay){
			console.log('CONSIDER TO ADD NEW DAY');
		}else{
			let foundHour	=	(foundDay.scheduler || []).find(el => el.start_hour == hour);
			if(foundHour){
				foundHour.dirty	=	true;
			}
		}	
		console.log(this.item.calendar.schedule.days);
	}
	updateOfferConcreteDay(foundDay,offer){
		if(foundDay.offers != null){
			let foundOffer	=	foundDay.offers.offers.find(el => el.id == offer.id);
			if(foundOffer){foundOffer = offer;}
			else{
				foundDay.offers.offers = foundDay.offers.offers || [];
				foundDay.offers.offers.push(offer);
				foundDay.dirty 	= true;
			}
		}else{
			foundDay.offers = {
				day				:	this.pageInfo.dateSelected.date,
				offers			:	[offer],
				period_owner	:	null
			}
			// foundDay.offers.offers = [];
			// foundDay.offers.offers.push(offer);
			foundDay.dirty 	= true;
		}
	}
	updateOfferDayCalendar(offer){
		let foundDay = this.item.calendar.offers.days.find(day => day.day == this.pageInfo.dateSelected.date);
		if(!foundDay) {
			console.log('HEY, DAY NOT FOUND, THINK IF ADD TO LIST',this.pageInfo.dateSelected.date);
			this.item.calendar.offers.days.push({day         : this.pageInfo.dateSelected.date, 
				offers   : [offer],
				period_owner: null})
		}else{
			// foundDay.dirty =	true;
			let foundOffer =	(foundDay.offers || []).find(el => el.id == offer.id);
			if(foundOffer){
				foundOffer =	offer;
			}else{
				foundDay.offers	=	foundDay.offers || [];
				foundDay.offers.push(offer);
			}
		}

	}

	deletePromotionDay(data){
		let foundRow	=	this.pageInfo.templateDayOffer.offers.findIndex(el => el.id == data.id);
		if(foundRow > -1){
			let foundDay	=	this.item.calendar.compositor.find(el => el.day == this.pageInfo.dateSelected.date);
			if(!foundDay){return false};
			foundDay.computed.forEach(offer =>{
				if(data.type.code == 'promotion'){
					let foundExec = offer.apply_promotions.findIndex(el => el.id == data.id);
					if(foundExec == -1){return };
					offer.apply_promotions.splice(foundExec,1);

				}else{
					let foundExec = offer.apply_offers.findIndex(el => el.id == data.id);
					if(foundExec == -1){return};
					offer.apply_offers.splice(foundExec,1);
					data.profiles.forEach(profile =>{
						console.log(profile);
						this.reloadBasePrice({target:profile}, offer);
					})
				}
			});

			this.pageInfo.templateDayOffer.offers.splice(foundRow,1);
		}
	}
}