import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {DialogModule} from 'primeng/dialog';


@Component({
    selector   : 'app-message-driver',
    templateUrl: './messageDriver.component.html',
    styleUrls:['./messageDriver.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class MessageDriverComponent implements OnInit{

    @Input()    driver  : any; 
    @Output()   form    : EventEmitter<any> = new EventEmitter();
    
    pageInfo : any = {
        typeMessages : [
            {name: 'Delay',         code: 0},
            {name: 'Change Route',  code: 1},
            {name: 'Call me',       code: 2},
            {name: 'Wait',          code: 3},
            {name: 'Urgent',        code: 4}
        ],
        infoMessage  : {
			type 		: null, 
			description : null,
            recipient 	: null,
            status      : 'pending'
        },
        display     : true
    }
    ngOnInit(){}
    
    submitMessage(){
        this.pageInfo.infoMessage.recipient = this.driver.id;
        this.pageInfo.infoMessage.data      = this.pageInfo.infoMessage.date = new Date();
        this.form.emit(this.pageInfo.infoMessage);
        this.restartForm();
    }

    restartForm(){
        this.pageInfo.display       = false;
        this.pageInfo.infoMessage   = {
            type        : null,
            description : null,
            recipient   : null,
            status      : 'pending'
        }
    }

}