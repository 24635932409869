export const testExpandedForm = [
    {
        title: "_INFO",
        type: 'info',
        items: [
            { field: 'status',					label: '_STATUS', 				editable: false, 	type: 'text',   newBlocked: true					                		                },
            { field: 'verified', 				label: '_VERIFIED', 			editable: false, 	type: 'text',	editor: 'readonly_toggle'													},
            { field: 'shared', 					label: '_TYPE', 				editable: false, 	type: 'text' 																				},
            { field: 'reference', 				label: '_REFERENCE', 			editable: false, 	type: 'text' 																				},
            { field: 'date_Calendar',			label: '_RESERVATION_DATE',		editable: false, 	type: 'text',	editor: 'datetime' 															},
            { field: 'customer', 				label: '_CUSTOMER', 			editable: true, 	type: 'text' 																				},
            { field: 'pax', 					label: '_PAX', 					editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'adults', 					label: '_ADULTS', 				editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'children', 				label: '_CHILDREN', 			editable: false, 	type: 'text',	editor: 'number'															},
            { field: 'vehicle',				 	label: '_VEHICLE', 				editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            // { field: 'vehicle_mapped',			label: '_VEHICLE_MAPPED', 		editable: false, 	type: 'text',	autoFixIcon: 'wrench'														},
            { field: 'computed_price', 			label: '_COMPUTED_PRICE', 		editable: false, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 					},
            { field: 'price', 					label: '_PRICE', 				editable: true, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 					},
            { field: 'extras_price', 			label: '_EXTRAS_COMPUTED_PRICE',editable: false, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 					},
            { field: 'extras', 					label: '_EXTRAS', 				editable: false,	type: 'text',   editor: 'textarea' 															},
            { field: 'innerNote', 				label: '_INNER_NOTE', 			editable: true, 	type: 'text' 																				},
            { field: 'providerNote', 			label: '_NOTE', 		        editable: true, 	type: 'text' 																				},
            // { field: 'customerNote', 			label: '_CUSTOMER_NOTE', 		editable: true, 	type: 'text' 																				},
        ]
    }
    , {
        title: "_ARRIVAL",
        type: 'arrival',
        items: [
            { field: 'arrival_GatewayTo', 					label: '_AIRPORT_FROM', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 								},
            { field: 'arrival_From', 						label: '_AIRPORT_TO', 					editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight' 								},
            { field: 'arrival_GatewayInfo', 				label: '_FLIGHT', 						editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', renderer: 'flight' 			},
            { field: 'arrival_Date_Calendar',				label: '_ARRIVAL_DATE', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight',	editor: 'datetime'			},
            { field: 'arrival_Time_Calendar',				label: '_ARRIVAL_TIME', 				editable: false, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'flight', editor: 'time'				},                            
            { field: 'arrival_Zone', 						label: '_ZONE', 						editable: false, 	type: 'text'																		},
            { field: 'arrival_To', 							label: '_AREA', 						editable: false, 	type: 'text', 	check: 'hotel', _entityList: 'areasList', _editor: 'autocomplete' 	},
            { field: 'arrival_Location', 					label: '_ACCOMMODATION',				editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel', 	extra: ['field'] 			},
            { field: 'arrival_AddressInfo', 				label: '_ADDRESS', 						editable: true, 	type: 'text', 	autoFixIcon: 'wrench', 	check: 'hotel' 								    },
            // { field: 'arrival_Pickup_Date_Calendar',		label: '_ARRIVAL_PICKUP_DATE', 			editable: false, 	type: 'text', 	_autoFixIcon: 'wrench', _check: 'flight',	_editor: 'datetime'			},
            // { field: 'arrival_Pickup_Time_Calendar',	    label: '_ARRIVAL_PICKUP_TIME', 		    editable: false, 	type: 'text', 	_autoFixIcon: 'wrench', _check: 'flight',	_editor: 'time'				},				            
            { field: 'arrival_DriverNote', 		            label: '_DRIVER_NOTE', 			        editable: true, 	type: 'text' 																			},
            { field: 'arrival_computed_price', 			    label: '_ARRIVAL_COMPUTED_PRICE', 		editable: false, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 				}
        ]
    }
    , {
        title: "_DEPARTURE",
        type: 'departure',
        items: [
            { field: 'departure_Location', 					label: '_ACCOMMODATION', 				editable: true, 	type: 'text', autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_AddressInfo', 				label: '_ADDRESS', 						editable: true,		type: 'text', autoFixIcon: 'wrench', check: 'hotel' 									},
            { field: 'departure_From', 						label: '_AREA', 						editable: false,	type: 'text', check: 'hotel', _entityList: 'areasList', 	_editor: 'autocomplete' 	},
            { field: 'departure_Zone', 						label: '_ZONE', 						editable: false,	type: 'text'																			},
            { field: 'departure_To', 						label: '_AIRPORT_FROM', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight' 									},
            { field: 'departure_GatewayTo', 				label: '_AIRPORT_TO', 					editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight' 									},
            { field: 'departure_GatewayInfo', 				label: '_FLIGHT', 						editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		renderer: 'flight' 			},
            { field: 'departure_Date_Calendar',				label: '_DEPARTURE_DATE', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'datetime'			},
            { field: 'departure_PickupTime',		        label: '_DEPARTURE_PICKUP_TIME', 		editable: true, 	type: 'text', 			                                                                },
            { field: 'departure_Time_Calendar',				label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench'			                                            },
            // { field: 'departure_Time_Calendar',				label: '_DEPARTURE_TIME', 				editable: false, 	type: 'text', autoFixIcon: 'wrench', check: 'flight', 		editor: 'time'				},
            // { field: 'departure_Pickup_Date_Calendar',		label: '_DEPARTURE_PICKUP_DATE', 		editable: false, 	type: 'text', 	_autoFixIcon: 'wrench', _check: 'flight',	_editor: 'datetime'			},
            // { field: 'departure_Pickup_Time_Calendar',		label: '_DEPARTURE_PICKUP_TIME', 		editable: false, 	type: 'text', 	_autoFixIcon: 'wrench', _check: 'flight', 	_editor: 'time'				},            
            { field: 'departure_computed_price', 			label: '_DEPARTURE_COMPUTED_PRICE', 	editable: false, 	type: 'text', 	editor: 'price',		check: 'price', renderer: 'price' 					},
            { field: 'departure_DriverNote', 				label: '_DRIVER_NOTE', 					editable: true, 	type: 'text' 																			}            
        ]
    }
];