import { SuperformService 						} from './../../../service/super-form/superform.service';
import { TransportService 						} from '../../../service/transports/transports.service';
import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, 
	   	 OnInit, 
		 ViewEncapsulation,
		 Input, 
		 Output   								} from '@angular/core';
import { colFilters                             } from "./data/filters";

import { ConfirmationService 					} from 'primeng/primeng';
import { StorageService 						} from './../../../service/storageservice';
import { EventEmitter 							} from '@angular/core';
import { driversForm 							} from './data/info';
import { SimpleFirebaseService 					} from 'src/app/demo/service/database/simplefirebase.service';
import { TransportDriverService } from 'src/app/demo/service/transports/driver.service';

@Component({
	selector		: 'app-company-drivers',
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class CompanyDriverComponent implements OnInit
{
	@Input()		fromWizard	: Boolean = false;
	@Input() 		dmc			: any;
	@Input()		destination : any; 
	@Input()		mode 		: any;
	@Input()		drivers		: any;

	@Output()		emitter		= new EventEmitter<any>();	
    locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	hovering		: boolean 	= false;
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService,
		private storage 		: StorageService,
		private transportCtrl	: TransportService,
		private superFormCtrl	: SuperformService,
		private confirmCtrl     : ConfirmationService,
		private driverCtrl		: TransportDriverService
	){}

    async ngOnInit() 	{ 	
		this.init();
		this.initForms();
		await this.loadEntities();	
	}
    
	async init()		{
		//
		this.pageInfo.loadings			= 	{};
		this.pageInfo.dataLoaded      	= 	false;
        this.pageInfo.indexTabCalendar	= 	0;		
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 		
		this.pageInfo.status			= 	{
											drivers	: "list",			
											};
		this.pageInfo.i18n				= 	this.commons.getAllTranslates();
		
		this.pageInfo.tabs				= 	{
			main			: {
				items 			: [
					{ visible : true, id:0, label: this.commons.getTranslate('_INFO'			),	icon : 'fa fa-folder-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ visible : true, id:1, label: this.commons.getTranslate('_CALENDAR'		),	icon : 'fa fa-folder-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ visible : true, id:2, label: this.commons.getTranslate('_SERVICES'		), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ visible : true, id:3, label: this.commons.getTranslate('_PAYMENTS'		), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ visible : true, id:4, label: this.commons.getTranslate('_STATS'			),	icon : 'fa fa-map-marker' 		, command: ($event)=> { this.setTabItem('main',$event)} 	}
				],
				selected		: 0
			}
		};
		
		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
		};
		
		this.pageInfo.imgs				= {
			defaultAvatar	:	'assets/demo/images/general/default-avatar.jpg'
		}

		this.pageInfo.forms				= {};
		this.pageInfo.entities			= { drivers : {} }
        this.pageInfo.colFilters 		= await Promise.all(colFilters.map(async item=>await this.commons.translateRecursively(item)));
	}				

	initForms(){
		this.pageInfo.forms.driver = { 
			ready			: 	false,
			entities		: 	this.pageInfo.entities,	
			displayButtons	: 	true,		
			form			:  	driversForm,
			borderFooter	:	true,
			buttons	:	[
				{ id: 1, name: 'save', 		label: '_SAVE', 	action: 'save', 		icon : 'fa fa-fw fa-save'	},
				{ id: 2, name: 'delete', 	label: '_DELETE', 	action: 'delete',		icon : 'fa fa-fw fa-trash'	},
				{ id: 2, name: 'cancel', 	label: '_CANCEL', 	action: 'cancel',		icon : 'fa fa-fw fa-remove'	}
			],
			buttonsCrud		:	[				
				{
					label	:	"_SAVE",
					iconPos	:	'right',
					icon	:	'fa fa-fw fa-save',
					value	:	'saveFormType',
					disabled:	false,
					visible	:	true,
				},
				{
					label	:	'_CANCEL',
					icon	:	'fa fa-fw fa-remove',
					iconPos	:	'right',
					visible	:	true,
					disabled:	false,
					value	:	'cancelFormType'
				}
			]
		}

		this.pageInfo.forms.calendar = { 
			ready			: false,
			entities		: this.pageInfo.entities,	
			displayButtons	: true,		
			form			:  [
				{
					cols		: 12,
					icon		: "fa fa-user",
					items		: [
						{
							cols		: 6,
							title		: "_INFO",
							showTitle	: false,						
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ 	clean: true, flex: 10, mandatory: true, 	field	: 'type',				label:'_TYPE',				editor	: 'groupButton',	type:'free', validators	:	[{type : 'minOneSelected'}], 
									items : [
										{label : '_5+2', default: false, value:'5+2', _icon : 'fa fa-fw fa-user'	},
										{label : '_6+2', default: false, value:'6+2', _icon : 'fa fa-fw fa-users'}
									]
								}
							]
						},
						{
							cols		: 6,
							title		: "_INFO",
							showTitle	: false,						
							padding		: "1rem 1rem 1rem 1rem",
							items		: [						
								{ 	clean		: true, 
									flex		: 10, 
									mandatory	: true,	
									field		: 'season_period_init', 	
									label		:'_SEASON_PERIOD_INIT',		
									type		: 'text',	
									editor		: 'datetime',		
									editable	: true,	
									options 	: {}
								}
							]
						}												
					]
				}
			],
			borderFooter	:	true,
			buttons	:	[
				{ id: 1, name: 'save', 		label: '_SAVE', 	action: 'save', 		icon : 'fa fa-fw fa-save'	},
				{ id: 2, name: 'delete', 	label: '_DELETE', 	action: 'delete',		icon : 'fa fa-fw fa-trash'	},
				{ id: 2, name: 'cancel', 	label: '_CANCEL', 	action: 'cancel',		icon : 'fa fa-fw fa-remove'	}
			]
		}
	}

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.filter(el => el.visible).map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

    async loadEntities(){	await this.load('drivers'); this.pageInfo.dataLoaded = true;	}

    async load($entity)	{
		this.pageInfo.entities[$entity]	=	this.pageInfo.entities[$entity] || {};
		let response;
		switch($entity){
			case 'drivers'	:	this.loadDrivers(); break;
			default			:	return;
		};        
	}

	private async loadDrivers()
	{
		const $entity = "drivers";

		let response:any = { success: true, data: [] }
		
		if( this.fromWizard ){
			response["data"] = this.drivers || [];
		} else {
			response = 	await this.transportCtrl.getDrivers(
							this.userInfo.idDmc,
							this.userInfo.currentDestination.id
						);
		}
		this.pageInfo.entities[$entity].data	=	response.success ? response['data'] : [];

		// Unselect driver
		this.getFilteredEntity('drivers').forEach(driver => {
			driver.selected = false
		});								

		if(this.getFilteredEntity('drivers').length == 0){ 
			this.addDriver();
			return; 
		}

		if(this.pageInfo.currentDriver){
			const currentDriverEmail	=	this.pageInfo.currentDriver.email;
			let findCurrent = this.getFilteredEntity('drivers').findIndex(driver => driver.email == currentDriverEmail);
			this.toggleDriver(this.getFilteredEntity('drivers')[findCurrent > -1 ? findCurrent : 0]);
		}
		else {	
			this.toggleDriver(this.getFilteredEntity('drivers')[0]);	
		}
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	async action($type,$event){
		switch($type){
			case "services"		: 	switch($event.action){
										case "provider_unselected"	:	this.pageInfo.currentDriver	= undefined;		break;
										case "provider_selected"	:	this.pageInfo.currentDriver	= $event["item"];	break;
										case "view_provider"		: 	if(undefined==this.pageInfo.currentDriver){
																			this.commons.generateToast("_ERROR","_DRIVER_NOT_SELECTED","error");
																			return false;
																		}
																		this.pageInfo.status.providers 		= "providerDetail"; 	
																		break;
										case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
									}
									break;			

			case "conciliation"	: 	this.pageInfo.status.providers 		= "providerList"; 		break;	
			case "drivers"		:	switch($event.action){
										case "save"				:	alert("SAVE DRIVER"); break;
										case "delete"			:	alert("DELETE DRIVER"); break;
										case 'reloadDrivers'	:	await this.load('drivers'); break;
									}	
			
			case "manageDrivers":	this.emitter.emit({type : 'manageDrivers', data : $event });
		}
	}

	addingNewDriver(){
		return (this.getFilteredEntity('drivers') || []).some(el => el.adding);
	}

	addDriver(){
		this.pageInfo.tabs.main.selected=0;
		this.getFilteredEntity('drivers').unshift({isNew : true, adding : true, id : this.commons.generateHash(+new Date())});
		this.toggleDriver(this.getFilteredEntity('drivers')[0]);
	}

	toggleDriver(driver){
		if(this.pageInfo.currentDriver && this.pageInfo.currentDriver.id == driver.id){ return; }
		this.commons.toggleItem(driver,this.getFilteredEntity('drivers'),'selected');
		if(!driver.selected){return;}

		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver ,'delete', {visible : true});

		driver.adding ? this.setFooterAsAdding() : this.setFooterAsAdded();

		this.pageInfo.currentDriver		=	driver;
		this.pageInfo.forms.driver.info	=	driver;
		this.pageInfo.forms.driver.item	=	driver;
		this.superFormCtrl.prepareToFrontend(this.pageInfo.forms.driver);
	}

	setFooterAsAdded(){
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'delete', {visible : true});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'save', {label : '_UPDATE'});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'cancel', {visible : false});
	}

	setFooterAsAdding(){
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'delete', {visible : false});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'save',{label : '_SAVE'});
		this.superFormCtrl.changeFooterProperties(this.pageInfo.forms.driver,'cancel', {visible : true});
	}

	async handleDriverAction($info){
		console.log($info);

		if(undefined==$info.item.button){
			return false;
		}
		
		switch($info.item.button.action){
			case 'save'	:	this.saveDriver		({ item: $info.item }); break;			
			case 'delete':	this.deleteDriver	({ item: $info.item }); break;
		}
	}

	private async saveDriver($params){
		const $item 		= $params["item"];
		const dmcId			= this.commons.userInfo.dmc.id;
		const destinationId	= this.commons.userInfo.destination.id;

		// this.emitter.emit({type:'manageDrivers', data : { type : 'saveDriver' , data : $item }});
		let response = await this.driverCtrl.saveDriver( $item, dmcId, destinationId );
		console.log("[saveDriver] response",response);
	}

	private deleteDriver($params){
		const $item = $params["item"];
		this.confirmCtrl.confirm({
			message 	: this.commons.getTranslate('_DELETE_DRIVER_QUESTION'),
			header		: this.commons.getTranslate('_CONFIRMATION'),
			icon		: 'pi pi-exclamation-triangle',
			acceptLabel	:	this.commons.getTranslate('_DELETE'),
			rejectLabel	:	this.commons.getTranslate('_CANCEL'),
			accept		: async () => {
				console.log("[deleteDriver] Item",$item);
				let response = await this.driverCtrl.deleteDriver($item.info.ref);
				this.drivers = this.drivers.filter(item=>item.id!=$item.info.id);
				this.loadDrivers();
			}
		})
	}

	handleAction($event){
		if(!$event.type){return this.commons.generateToast('_ERROR','_UNHANDLED_ACTION','error');}
		
		switch($event.type){
			case 'saveDriver'	:	return this.emitter.emit({type : 'manageDrivers', data : $event });
			default				:	return;	
		}
	}

	doAction($type,$info){
		switch($type){
			case "driver":	switch($info.action){
				case "new"	: 	this.addDriver();
								// this.pageInfo.entities['drivers'].push({}); 
								break;
			}
		}
	}

	getFilteredEntity(type){
		switch(type){
			case 'drivers'	:	return ( this.pageInfo.entities[type].data || [] )
											.sort((a,b)=>a.vehicle>=b.vehicle?1:-1);
			default			:	break;
		}
	}

	async saveUser(userInfo){
		try{
			this.pageInfo.loadings['creatingUser'] = true;
		
			let idDmc 			=	this.dmc 			?	this.dmc 			:	this.userInfo.idDmc;
			let idDestination 	=	this.destination	?	this.destination	:	this.userInfo.currentDestination.id; 
			await this.transportCtrl.saveDriver(userInfo,idDmc,idDestination);
			this.pageInfo.loadings['creatingUser'] = false;

		}catch(e){
			this.commons.generateToast('_ERROR','_ERROR','error');
			this.pageInfo.loadings['creatingUser'] = false;
		}
	}

	async deleteUser(refUser){
		try{
			this.pageInfo.loadings['creatingUser']	=	true;
			await this.transportCtrl.deleteDriver(refUser);
			this.commons.generateToast('_SUCCESS','_DRIVER_DELETED','success');
			this.pageInfo.loadings['creatingUser']	=	false;
		}catch(e){
			this.commons.generateToast('_ERROR','_ERROR','error');
			this.pageInfo.loadings['creatingUser'] = false;
		}
	}
}