export const formData = [
    {
        entity    :   'consult',
        options   :   [
            {
                type    :   '_REJECTED',
                reasons :   [
                    {label:'_TIME_EXPIRED',     value:  {   id : 1, type: 'time_expired'    }},
                    {label:'_BAD_BEHAVIOR',     value:  {   id : 2, type: 'bad_behavior'    }},
                    {label:'_OFFTOPIC',         value:  {   id : 3, type: 'offtopic'        }},
                    {label:'_EXTERNAL_REASONS', value:  {   id : 4, type: 'external_reasons'}},
                    {label:'_OTHERS',           value:  {   id : 5, type: 'others'          }}
                ]
            },
            {
                type    :   '_BLOCKED',
                reasons :   [
                    {label : 'none', value : null}
                ]
            }
        ]
    },
    {
        entity    :   'issue',
        options   :   [
            {
                type    :   '_REJECTED',
                reasons :   [
                    {label:'_TIME_EXPIRED',     value:  {   id : 1, type: 'time_expired'    }},
                    {label:'_BAD_BEHAVIOR',     value:  {   id : 2, type: 'bad_behavior'    }},
                    {label:'_OFFTOPIC',         value:  {   id : 3, type: 'offtopic'        }},
                    {label:'_EXTERNAL_REASONS', value:  {   id : 4, type: 'external_reasons'}},
                    {label:'_OTHERS',           value:  {   id : 5, type: 'others'          }}
                ]
            },
            {
                type    :   '_BLOCKED',
                reasons :   [
                    {label : 'none', value : null}
                ]
            }
        ]
    }
]