import { ConfirmationService, 
		 TabView 						} from 'primeng/primeng';
import { SuperformService 				} from './../../demo/service/super-form/superform.service';
import { CommonsService 				} from './../../demo/service/commons.service';
import { OnInit, 
		 Component, 
		 Input, 
		 Output,
		 EventEmitter 					} from '@angular/core';

@Component({
    selector	: 'app-transport-fleet',
    templateUrl	: './app-transport-fleet.component.html',  
    styleUrls   : ['./app-transport-fleet.component.scss']
  })
  export class AppTransportFleetComponent implements OnInit{
	
	@Input()	fromWizard			:	Boolean	= false;
	@Input() 	dmcFleet			:	any = [];
	@Input() 	vehicles			:	any = [];
	@Input()	types				:	any = [];
	@Input()	vehiclesForm		:	any = [];
	@Input() 	typesForm			:	any = [];
	@Output()   emitter				:   EventEmitter<any>   = new EventEmitter();
	
	pageInfo:	any = {};

	constructor(
		private commons 			: 	CommonsService,
		private superFormCtrl		:	SuperformService,
		private confirmationService	:	ConfirmationService
	){}
	
	ngOnInit()						{	this.init();	}

	init()							{	
		this.pageInfo	=	{
			typeTransportSelected	:	null,
			mainTabIndex			:	0,
			buttonsCrudType			:	[
				{
					label	:	'_CANCEL',
					icon	:	'fa fa-fw fa-remove',
					iconPos	:	'right',
					visible	:	true,
					disabled:	false,
					value	:	'cancelFormType'
				},
				{
					label	:	"_SAVE",
					iconPos	:	'right',
					icon	:	'fa fa-fw fa-save',
					value	:	'saveFormType',
					disabled:	false,
					visible	:	true,
				}
			],
			buttonsCrudVehicle		:	[
				{
					label	:	'_CANCEL',
					icon	:	'fa fa-fw fa-remove',
					iconPos	:	'right',
					visible	:	true,
					disabled:	false,
					value	:	'cancelFormVehicle'
				},
				{
					label	:	"_SAVE",
					icon	:	'fa fa-fw fa-save',
					iconPos	:	'right',
					value	:	'saveFormVehicle',
					disabled:	false,
					visible	:	true,
				}
			],
		}
		this.selectTypeTransport(this.types[0]);
	}

	/**
	 * select transport type
	 * @param item
	 * @returns 
	 */
	selectTypeTransport(item)		{
		let transportType;

		// Do nothing if already set
		if(this.pageInfo.typeTransportSelected!=null){
			if((this.pageInfo.typeTransportSelected||[]).typeId == item.id ){ return false; }
		}

		this.commons.toggleItem(item,this.types,'selected');
		if(!item.selected){ 
			this.pageInfo.typeTransportSelected = null; 
			return false;
		}
		
		this.typesForm.form.info		=	{};	
		this.typesForm.form.item		=	{};
		
		this.restartVehicleForms();

		transportType	=	this.dmcFleet.find(type => type.typeId == item.id);
		if(!transportType ){ 	
			this.pageInfo.typeTransportSelected 	= null; 
			this.pageInfo.mainTabIndex 				= 0; 
			this.typesForm.form.item['seats_down']	=	item.seats;
			this.typesForm.form.item['seats_up']	=	0;
			this.typesForm.form.item['type']		=	[];
			return false;
		}

		transportType.vehicles = transportType.vehicles || [];										
		if(transportType.vehicles.length == 0)	{	this.pageInfo.mainTabIndex = 0; }
		
		this.pageInfo.typeTransportSelected	=	transportType;
		this.typesForm.form.info			=	transportType;
		this.typesForm.form.item			=	transportType;		
	}

	checkPaxType(type,item)			{	
		let result		=	{};
		let seatsUp		=	+item['seats_up'];
		let seatsDown	=	+item['seats_down'];
		if(seatsUp <0 || seatsDown <0){return result = {success : false, msgError : '_BAD_FORMAT_PAX'};}
		if(seatsUp + seatsDown == +type.seats){
			item.seats	=	+type.seats;
			return result = {success : true, msgError : null};
		}else{
			return result	=	{ success : false, msgError : '_SEATS_NOT_MATCH_TYPE'}
		}
	}

	saveTypeTransport()				{	
		let typeSelected	=	this.types.find(item => item.selected);
		if(null==typeSelected){
			return this.commons.generateToast('_ERROR','_NO_TRANSPORT_TYPE_SELECTED','error');
			return false;
		}
		if(!this.superFormCtrl.validate(this.typesForm.form.form,this.typesForm.form)){
			return this.commons.generateToast('_ERROR','_CHECK_PARAMS','error');
		}

		let checkSeats	=	this.checkPaxType(typeSelected, this.typesForm.form.item);
		if(!checkSeats.success){
			return this.commons.generateToast('_ERROR',checkSeats.msgError,'error');
		}

		let findType		=	this.dmcFleet.find(item => item.typeId == typeSelected.id);
		if(findType){
			findType = {
				vehicles 	: findType.vehicles, 
				typeId 		: typeSelected.id, 
				id			: typeSelected.id, 
				...this.typesForm.form.item
			};
			this.pageInfo.typeTransportSelected = findType;
			this.commons.generateToast('_SUCCESS','_CREATED','success');
		}
		else	{
			this.dmcFleet.push({	
				typeId 		: 	typeSelected.id, 
				id			:	typeSelected.id,
				vehicles	:	[],
				...this.typesForm.form.item,
			});	
			this.pageInfo.typeTransportSelected = this.dmcFleet[this.dmcFleet.length -1];
			this.commons.generateToast('_SUCCESS','_UPDATED','success');
		}

		this.emitter.emit({ 
			action	: 	'saveTypeTransport', 
			data	:	{ 
				typeTransport : this.pageInfo.typeTransportSelected
			} 
		});
	}

	getCountByType(typeVehicle)		{	
		if(!typeVehicle || !this.dmcFleet){ return 0};
		let transportType	=	this.dmcFleet.find(type => type.typeId == typeVehicle.id);
		if(!transportType){return 0};
		return (transportType.vehicles||[]).length;
	}

	getFilteredEntity($type)		{
		switch($type){
			case "types"			:	return this.types.sort((a,b)=>a.name=="GENERIC"?1:-1);
			case "vehicles"			:	return (null==this.pageInfo.typeTransportSelected)
												?	[]
												:	(this.pageInfo.typeTransportSelected.vehicles||[])
													.sort((a,b)=>a.code>=b.code?1:-1);
		}
	}

	handleVehicleForm(action)		{	
		switch(action){
			default						:	return this.commons.generateToast('_ERROR','_UNKNOWN_OPTION','error');
			case 	'saveFormType'		:	return this.saveTypeTransport();
			case 	'cancelFormType'	:	return this.restartTypeForm();
			case 	'cancelFormVehicle'	:	return this.restartVehicleForms(); 
			case	'saveFormVehicle'	:	return this.saveVehicle(); 				
		}
	}

	restartTypeForm()				{	
		this.pageInfo.typeTransportSelected	=	null;
		this.superFormCtrl.restartErrors(this.typesForm.form.form);
		this.typesForm.form.item	=	{};
		this.typesForm.form.info	=	{};	
	}

	restartVehicleForms()			{	
		if(this.pageInfo.typeTransportSelected && this.pageInfo.typeTransportSelected.vehicles){
			this.pageInfo.typeTransportSelected.vehicles.forEach(transport => transport.selected = false);
		}

		this.superFormCtrl.restartErrors(this.vehiclesForm.detailsVehicleForm.form);
		this.superFormCtrl.restartErrors(this.vehiclesForm.infoForm.form);
		this.vehiclesForm.detailsVehicleForm.item	=	{};
		this.vehiclesForm.infoForm.item				=	{};
	}

	clickVehicle(vehicle)			{	this.commons.toggleItem(vehicle,this.pageInfo.typeTransportSelected.vehicles,'selected');
										if(!vehicle.selected){ return this.restartVehicleForms(); }
										this.pageInfo.vehicleSelected 				=	vehicle; 
										this.vehiclesForm.detailsVehicleForm.item	=	vehicle;
										this.vehiclesForm.infoForm.item				=	vehicle;
									}

	saveVehicle()					{
										if(!this.superFormCtrl.validate(this.vehiclesForm.infoForm.form,this.vehiclesForm.infoForm)){
											return this.commons.generateToast('_ERROR','_CHECK_PARAMS','error');
										}
										if(!this.pageInfo.vehicleSelected){
											this.pageInfo.typeTransportSelected.vehicles.push(
												{id :	this.commons.generateHash(new Date().toString()),
													...	this.vehiclesForm.infoForm.item,
													...	this.vehiclesForm.detailsVehicleForm.item}
											);
											this.emitter.emit({action : 'saveTypeTransport', data	:	{ 	typeTransport 	: this.pageInfo.typeTransportSelected} })
											this.restartVehicleForms();

										}else{
											// this.pageInfo.vehicleSelected =	{id : this.pageInfo, ...this.vehiclesForm.infoForm.item,...this.vehiclesForm.detailsVehicleForm.item}; 
											this.emitter.emit({action : 'saveTypeTransport', data	:	{ 	typeTransport 	: this.pageInfo.typeTransportSelected}})

										}
										this.commons.generateToast('_SUCCESS',this.pageInfo.vehicleSelected ? '_VEHICLE_UPDATED' : '_VEHICLE_ADDED','success');
									}

	deleteTypeTransport()			{	this.confirmationService.confirm({	
											header		:	this.commons.getTranslate('_CONFIRMATION'),
											message 	:	this.commons.getTranslate('_REMOVE_TYPE_VEHICLE'),
											icon		:	'pi pi-exclamation-triangle',
											acceptLabel	:	this.commons.getTranslate('_REMOVE'),
											rejectLabel	:	this.commons.getTranslate('_CANCEL'),
											accept: async () => {
												let typeIndex	=	this.dmcFleet.findIndex(item => item.code == this.pageInfo.typeTransportSelected.code);
												if(typeIndex == -1){return this.commons.generateToast('_ERROR','_TYPE_NOT_FOUND', 'error');  }
												this.emitter.emit({action : 'deleteTypeTransport', data	:	{typeTransport	: this.dmcFleet[typeIndex]}})
												this.dmcFleet.splice(typeIndex,1);
												this.commons.generateToast('_SUCCESS','_TYPE_DELETED','success');
												this.restartTypeForm();
											}
										});	

									}

	deleteVehicle($event,vehicle)	{	$event.stopPropagation();
										this.confirmationService.confirm({	
											header		:	this.commons.getTranslate('_CONFIRMATION'),
											message 	:	this.commons.getTranslate('_REMOVE_VEHICLE'),
											icon		:	'pi pi-exclamation-triangle',
											acceptLabel	:	this.commons.getTranslate('_REMOVE'),
											rejectLabel	:	this.commons.getTranslate('_CANCEL'),
											accept: async () => {
												let vehicleIndex	=	this.pageInfo.typeTransportSelected.vehicles.findIndex(item => item.code == vehicle.code);
												if(vehicleIndex == -1){ return this.commons.generateToast('_ERROR','_VEHICLE_NOT_FOUND', 'error'); }
												this.pageInfo.typeTransportSelected.vehicles.splice(vehicleIndex,1);
												this.emitter.emit({action : 'saveTypeTransport', data	:	{typeTransport	: this.pageInfo.typeTransportSelected}})
												this.commons.generateToast('_SUCCESS','_VEHICLE_DELETED','success');
												this.pageInfo.vehicleSelected	=	null;
												this.restartVehicleForms();
											}
										});	
									}

	getImgVehicle(vehicle)			{	return vehicle.img || (this.types.find(type => type.id == this.pageInfo.typeTransportSelected.typeId) || {}).url
									}

	getLabel(type)					{	switch(type){
											case 'typeTransport'	:	return this.pageInfo.typeTransportSelected 	? 	'_UPDATE'	: 	'_SAVE';
											case 'vehicle'			:	return this.pageInfo.vehicleSelected		?	'_UPDATE'	:	'_SAVE';
										}
									}
}
