import { AuthFirebaseService } from 'src/app/demo/service/database/authfirebase.service';
import { auth } from 'firebase/app';
import { CompanyService } from './../../../demo/service/database/company.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { FirebaseService 		} from 'src/app/demo/service/database/firebase.service';
import { StorageService 		} from '../../../demo/service/storageservice';
import { detailItems, entities 	} from './data/items';

@Component({
  selector    		: 'app-company-staff',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyStaffComponent {

  	@Output()			emitter			= new EventEmitter<any>();	
	
	userInfo			: any =	{};    
	pageInfo  			: any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { staff: {}, countries: {}, destinations: {}, stations: {}, profiles: {} }
	}
	  
	constructor( 
		private commons				: CommonsService,
		private entityService		: EntityService,
		private firebaseCtrl		: FirebaseService,
		private storage 			: StorageService,
		private companyService 		: CompanyService,
		private authService			: AuthFirebaseService
  	) {}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'reload',			label: '_RELOAD', 			icon: 'fa fa-refresh' 		, type: 'button'	},
			{ 	name: 'new',			label: '_CREATE',	 		icon: 'fa fa-plus' 			, type: 'button' 	},
			{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	}			
		];
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.items			= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

				
	}

	async loadEntities()		{ 	this.userInfo	=	( this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
									await this.load("profiles");
									await this.load("countries");
									await this.load("destinations");
									await this.load("stations");
									await this.load("staff");

								}

  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();	
		this.initForms();	
	}

	initForms(){
		this.pageInfo.entities["staff"].table	= {
			cols	: [
				{ field: 'name',     	header: 'Name', 		width: '100px', 	align: "center",    editable:false,  	mandatory : true 	},
				{ field: 'surname',    	header: 'Surname', 		width: '300px', 	align: "center",    editable:false, 	mandatory : true 	},
				{ field: 'profile',		header: 'Profile',		width: '100px',		align: "center",    editable:false, 	mandatory : true 	},
				{ field: 'destination',	header: 'Destination',  width: '200px',		align: "center",    editable:false, 	mandatory : true 	},
				{ field: 'phone',	    header: 'Phone',  		width: '100px', 	align: "center",    editable:false, 	mandatory : false	},
				{ field: 'email',	    header: 'Email',  	    width: '300px', 	align: "center",    editable:false,		mandatory : true 	}
			],
			options	: { rowExpander: true, showMenu: false, scrollHeight: '55vh' },
			filters	: [],			
			form	: {
				showEmptyTitle	: false,
				items	: [
					{
						cols		: 4,
						padding		: "0rem",
						items		: [
							{ mandatory: true, 	field: 'name', 			label:'_NAME',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 	field: 'surname', 		label:'_SURNAME',		type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 	field: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
							{ mandatory: true, 	field: 'email', 		label:'_EMAIL',			type: 'text',	editor: 'text',			editable	: true				}							
						
						]
					},
					{
						cols		: 3,
						padding		: "0rem",
						template	: 'app-profile-destination',
						inputs		: {
							'destinations'	:	this.pageInfo.entities['destinations'].data,
							'profiles'		:	this.pageInfo.entities['profiles'].data
						},
						items		: [],
					}									
					// ,{
					// 	cols		: 4,
					// 	padding		: "0rem",
					// 	items		: [
					// 		{ mandatory: true, 	field: 'phone', 		label:'_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
					// 		{ mandatory: true, 	field: 'email', 		label:'_EMAIL',			type: 'text',	editor: 'text',			editable	: true				}							
					// 	]
					// }	
					// ,{
					// 	cols		: 4,
					// 	padding		: "0rem",
					// 	items		: [
					// 		{ mandatory: true, 	field: 'profile',		label:'_PROFILE',		type: 'text',	editor: 'autocomplete',	entityList	: 'profiles'	},
					// 		{ mandatory: true, 	field: 'destination', 	label:'_DESTINATION',	type: 'text',	editor: 'autocomplete',	entityList	: 'destinations'}
					// 	]
					// }						
				],
				buttons	: [
					{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'	},
					{ name: 'select', 		label: '_SELECT', 		action: 'selectRow',     icon: 'check'		}
				]
			}
		};
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	

	async doAction($type,$item)	{
		switch($type){
			case "table"			:	switch($item.type){
											case "new"			:	this.commons.generateToast("NEW","NEW","info");	break;
											case "reload"		:	this.load("staff");								break;
											case "saveRow"		:	await this.saveUser($item.item);						break;
										}
										break;
			case "button"			:	switch($item.name){		
											case "new"			: 	//this.emitter.emit({ action: 'new' });
																	this.pageInfo.entities["staff"].data.unshift({});
																	this.pageInfo.entities["staff"].count = this.pageInfo.entities["staff"].data.length;																	
																	break;
											case "reload"		: 	this.loadEntities();												break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
										}
										break;
		}
	}

	getFilteredEntity($entity,$info=undefined){
		switch($entity){
			case "staff"			: 	return (this.pageInfo.entities[$entity].data||[]).filter(item=>{return true;});
			case "stations"			: 	return (this.pageInfo.destination.tableStations.data		|| []).filter(item=>{return true;});
			case "meetingPoints"	: 	return (this.pageInfo.destination.tableMeetingPoints.data	|| []).filter(item=>{return true;});
			
			case 'formStations'		:	return 	{ 	info	: $info, 
													entities: this.pageInfo["destination"].tableStations.entities,
													form	: this.pageInfo["destination"].tableStations.form 
												};
			case 'formMeetingPoints':	return 	{ 	info	: $info, 
													entities: this.pageInfo["destination"].tableMeetingPoints.entities,
													form	: this.pageInfo["destination"].tableMeetingPoints.form 
												};
												
			case "countries"		:	return this.pageInfo.entities[$entity].data;
			case "destinations"		:	let country = this.pageInfo.entities['countries'].data.find(item=>item.openned);
										return undefined==country? [] : this.pageInfo.entities[$entity].data.filter(item=>item.country==country.name);
		}
	}

	async load($entity){
		let response : any = {};
		switch($entity){
			case 'staff' :
				this.pageInfo.entities[$entity].data	= [];
                this.firebaseCtrl.getStaffFromDmc(this.userInfo.idDmc).subscribe(users => {	
					this.pageInfo.entities[$entity].data  = users;				
					this.pageInfo.entities[$entity].data.forEach(user =>{
						user.destinations	=	(user.destinations || []).map(item => { let infoDest		=	this.pageInfo.entities['destinations'].data.find(el => el.value.id == item.destinationId) || {};
																						let infoProfile		=	this.pageInfo.entities['profiles'].data.find(el => el.value.id == item.profileId);
																						return { 	destination :	infoDest.value,
																						 			profile	 	:	(infoProfile || {}).value ||  null}});
					})
					this.pageInfo.entities[$entity].count = this.pageInfo.entities[$entity].data ? this.pageInfo.entities[$entity].data.length : 0;
					this.paginate($entity, null);
				});
				break;
			
			case 'profiles'			:
				// response								= await Promise.resolve(this.entityService.getRequest('profiles',{ type: 'dmc' }));
				response.data							= await Promise.resolve(this.firebaseCtrl.getProfiles({ types : ['dmc'] }));
				this.pageInfo.entities[$entity].data	= response.data.map(item=>{ item.text = this.commons.getTranslate(item.label); return item; });
				console.log('PROFILES', this.pageInfo.entities[$entity].data );
				this.pageInfo.entities[$entity].data	= this.pageInfo.entities[$entity].data.map(data => { return { label : data.text, value : data}}); 		

				// Refresh dropdown filter
				this.pageInfo.filters[3].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.text, value: item.name }));
				this.paginate($entity,null);
				break;			
			
			case 'countries'		:					// FROM DB
				response								= await Promise.resolve(this.entityService.getRequest('countries',{ dmc: 1 }));
				this.pageInfo.entities[$entity].data	= response["data"].filter(item=>item.id>1);	// Avoid unassigned		
				// this.pageInfo.entities[$entity].data	= [{ id: 1, active: true, name: 'Spain', openned: true }];
				console.log('COUNTRIES DMC',this.pageInfo.entities[$entity].data);
				// Refresh dropdown filter
				this.pageInfo.filters[0].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id }));
				this.paginate($entity,null);
				break;			
				
			case 'destinations'		:						// FROM DB
				response									= await Promise.resolve(this.entityService.getComplexRequest('entity',{ entity: 'destination' },{}));
				this.pageInfo.entities[$entity].data		= response["data"].filter(item=>item.id>1).map(item=>{ item.selected = item.id==1; return item; });
				this.pageInfo.entities[$entity].data		= this.pageInfo.entities[$entity].data.map(data => { return { label : data.name, value : data}});
															// FROM FIREBASE
				this.pageInfo.entities['destinations'].data	=	await this.companyService.getDestinationsFromDmc(this.userInfo.idDmc);
				this.pageInfo.entities[$entity].data		= this.pageInfo.entities[$entity].data.map(data => { return { label : data.name, value : data}});

				console.log('DESTINATIONS DMC',this.pageInfo.entities[$entity].data);
				// Refresh dropdown filter
				this.pageInfo.filters[1].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id, selected: item.id==1 }));
				this.paginate($entity,null);
				break;							
										
			case 'stations'			:	
				this.pageInfo.entities[$entity].data	= [{ id: 1, active: true, destination: 1, name: 'Airport PMI Son Sant Joan' }];
				this.pageInfo.entities[$entity].count	= this.pageInfo.entities[$entity].data ? this.pageInfo.entities[$entity].data.length : 0;
				// Refresh dropdown filter
				this.pageInfo.filters[2].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id }));
				this.paginate($entity, null);
				console.log('STATIONS DMC',this.pageInfo.entities[$entity].data)				
				break;
		}
	}

	paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
	}
	
	/**
	 * Save user for login
	 */
	async saveUser(userInfo){
		userInfo.profile		=	"dmc";
		userInfo.destinations	=	( userInfo.destinations || [] ).map(el => { return { destinationId : el.destination.id, profileId : el.profile.id }})
		let entityId			=  this.userInfo.idDmc || null;

		if(userInfo.id)	{	
			await this.firebaseCtrl.updateUserStaffDmc(userInfo, this.userInfo.idDmc); 
			this.commons.generateToast('_SUCCESS','_USER_UPDATED','success');
		} else {
			let response =  await this.authService.signIn(userInfo);
			console.log("ITEMS",response);
			for(let item of response["items"]){
				if(item.uid)	{ 				
					// Persist into DMC info
					let message	= await this.firebaseCtrl.addUserToStaff({ userInfo: userInfo, uid: item.uid, entityId: entityId });
					if(message.success)	{	await this.commons.generateToast('_SUCCESS','_USER_CREATED','success',2);		} 
					else 				{	await this.commons.generateToast('_ERROR','_USER_NOT_CREATED','error');			}			
				} else {
					console.log("User has no uid");
				}
			}
		}		
	}
}