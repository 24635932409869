// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  	production  : false,
	apiBaseUrl  : 'https://admin.tourinia.com/app_dev.php',
	openApi     : {
        url     : 'https://admin.tourinia.com/api/v3.0'
    },
	firebase    : {
		apiKey					: "AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM",
        translatorApiKey        : "AIzaSyA8fXZloW62Nj3KmdJ4cQKf62x8KZkZF5A",
		authDomain				: "tourinia.firebaseapp.com",
		databaseURL				: "https://tourinia.firebaseio.com",
		projectId				: "tourinia",
		storageBucket			: "tourinia.appspot.com",
		messagingSenderId		: "29132584927",
		appId					: "1:29132584927:web:f81909c8b14cc88348f66a",
		measurementId			: "G-NJKQXK3P2L"
	}
};


