import { HttpClient 				} from '@angular/common/http';
import { CommonsService 			} from '../../service/commons.service';
import { FlightService 				} from '../../service/flight.service';
import { EntityService 				} from '../../service/entity.service';
import { MessageService 			} from 'primeng/components/common/messageservice';
import { OnInit, Component, 	
		 ViewEncapsulation,			
		 ViewChild,
		 ElementRef					} from '@angular/core';
import { StorageService 			} from '../../service/storageservice';
import { FirebaseService 			} from './../../service/database/firebase.service';
import { CompanyService 			} from './../../service/database/company.service';
import { AggregatorsService 		} from './../../service/database/aggregator.service';
import { mainFilters, 
		 zonesFilter, 
		 providerRestrictionFields	} from './data/filters';
import { pageInfo 					} from './data/pageinfo';

interface Calendar {
	value	: any,
	date	: string,
	last	: string
};

@Component({
    styleUrls		: ['./groups.component.scss'],
	templateUrl		: './groups.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})
export class GroupsComponent implements OnInit {
	@ViewChild('proposal')	proposalPanel	: ElementRef;
	@ViewChild('custom')	customPanel		: ElementRef;

	pageInfo		: any		= {};
	userInfo		: any		= {};
	providers		: any 		= {};
	calendar		: Calendar	= <Calendar>{ last: '' };
	mainFilters		: any 		= [];	
    entities		: any		= [ "bookings", "booking_groups", "providers" ];
    bookings		: any 		= { cols: [], filters: {} };
	booking_groups 	: any 		= { cols: [], filters: {} };
	solutions		: any		= {};	
	groupCards		: any	 	= []; 
	draggedItem		: any;
	tabPanel		: any		= {
									options		: [ 
										{ label: '_GROUPS_SOLUTION_CUSTOM', 	value: 'custom' 	},
										{ label: '_GROUPS_SOLUTION_PROPOSALS', 	value: 'solutions' 	}										
									],
									selected	: "solutions"			
								};

    constructor(
		private http			: HttpClient,
        private commons			: CommonsService,
        private flightService	: FlightService,
		private entityService	: EntityService,
		private messageService	: MessageService,
		private firebaseCtrl	: FirebaseService,
		private storageCtrl		: StorageService,
		private companyService	: CompanyService,
		private aggregatorCtrl	: AggregatorsService		
    ) { 
		this.staticInit();
        // this.loadEntities();
    }

	async ngOnInit()				{	//this.userInfo = ( this.storageCtrl.getItem('dmcSuite') || {} ).userInfo || { idDmc: "1", currentDestination : { id: "1" }};										
										console.log("DMC",			this.commons.userInfo.dmc.id);
										console.log("Destination",	this.commons.userInfo.destination.id);
										
										// this.companyService.subscribeDestination(	this.commons.userInfo.dmc.id, 
										// 												this.commons.userInfo.destination.id
										// 										).subscribe(data => {
										// 	this.pageInfo.destinationInfo		= data.payload.data()
										// 	// this.bookingsService.setInfo('destinationRef', data.payload.ref);
										// 	// this.bookingsService.setInfo('lodging_alias', data.payload.data()['lodging_alias'] || {})
										// 	// this.pageInfo.destinationRef	=	data.payload.ref;
										// 	// this.pageInfo.lodging_alias 		= data.payload.data()['lodging_alias'] || {};
											
										// 	this.loadEntities();
										// 	this.initFilters();											
										// });

										this.loadEntities();
										this.initStaticFilters();

										this.tabPanel	= await this.commons.translateRecursively(this.tabPanel,{ children: 'options'});																			
									}

	staticInit()					{

		this.pageInfo.providerRestrictions	= 	providerRestrictionFields.map(item=>{ 
													item["label"] = this.commons.getTranslate(item.name); 
													return item; 
												});
		this.pageInfo.wheel					= 	{ xFactor: 2, yFactor: 1 };
		
		this.pageInfo.noData				= 	pageInfo.noData;
		this.pageInfo.digests				= 	pageInfo.digests;
		this.pageInfo.viewButtons 			= 	pageInfo.viewButtons;
		this.pageInfo.groupInfoButtons 		= 	pageInfo.groupInfoButtons;
		this.pageInfo.groupsInfoDetail		= 	pageInfo.groupsInfoDetail;
		this.pageInfo.bookingsInfoDetail	= 	pageInfo.bookingsInfoDetail;
		this.pageInfo.filterButtons 		= 	pageInfo.filterButtons;

		// Set initial selected value for all kind of filters
		this.pageInfo.viewButtons.forEach		( filter => { filter.items.forEach(item=>item.value=filter.multiple?item.value:item.name==filter.selected);})
		this.pageInfo.filterButtons.forEach		( filter => { filter.items.forEach(item=>item.value=filter.multiple?item.value:item.name==filter.selected);})		
	}

	toggleTask($attr,$info)				{	switch($attr){
												case "getClass"	:	return $info.buttonGroup.selected == $info.button.name ? 'selected' : '';
												case "click"	:	$info.buttonGroup.selected=$info.button.name; break;
											}
										}

	getFilterButtonClass($filter,$item)	{	if ( $filter.multiple )	{	return $filter.selected==$item.name?'selected':'';		}
											else 					{	return $item.value?'selected':'';						}
										}

	getZoneFilters($prop)				{	let zones 	= ( this.mainFilters || [] ).find(item=>item.name=='zones') || [];
											return $prop?zones[$prop]:zones;
										}

	getDigest($entity,$digest,$item)	{	return (!this[$entity].digests && !this[$entity].digests[$digest])?[]:this[$entity].digests[$digest][$item];	}
								
	generateDigests($entity,$digests)	{	switch($entity){
												case "booking_groups"				:	this[$entity].digests =	$digests;	break;			
											}
										}

	async initStaticFilters()			{	this.mainFilters 			= await Promise.resolve(this.commons.translateRecursively (mainFilters,{ children:'items' }));
											this.pageInfo.zonesFilter	= zonesFilter;
										}

	initFilters()						{	if(undefined==this.pageInfo.currentProvider){
												this.commons.generateToast("Error","_SELECT_PROVIDER","error");
												return false;
											}

											// Load zones for selected provider
											let providerZones					= 	this.commons.getEntity("provider_zones_object");
											this.pageInfo.zonesFilter.items		= 	providerZones[this.pageInfo.currentProvider.id];
											// Select Zone 0 by default

											let selected						= 	(this.pageInfo.zonesFilter.items||[]).find((item,index)=>index==0);
											if(undefined!=selected){
												this.pageInfo.zonesFilter.selected	= selected["name"];
											}

											console.log("PROVIDER ZONES",this.pageInfo.zonesFilter.items);

											// Process all filters
											this.mainFilters.forEach(filter=>this.checkFilter(filter));
										}

	async loadEntities() 				{	await this.load("providers");	}	

	getInfo($type,$info)				{	switch($type){
												case "provider"	:	switch($info.type){
													case "zones"		:	return ( this.pageInfo.zonesFilter || {}).items;
													case "isSelected"	:	return $info.item.id == (this.pageInfo.currentProvider||{}).id;
													case "restrictions"	:	let providerData = ( this["providers"].data || [] ).find(item=>item.selected) || {};
																			return this.pageInfo.providerRestrictions.map(item=>{
																				if(undefined!=providerData["restrictions"]){
																					item.value = undefined==providerData["restrictions"]
																									?item.default
																									:providerData["restrictions"][item.name];
																				}
																				return item;
																			});
												}
											}
										}
	
	getFilteredEntity($entity,$info)	{	switch($entity){
												case "groups"	:	//getFilteredEntity('groups', { panel: this.tabPanel.selected, zone: zone.name }) 
																	return this.getGroups( $info.panel,'zone',{ zone: $info.zone }); 																
											}
										}

	doAction(type, items, extra={}) 	{
		switch (type) {
			case "persistSolution"		:	this.persistSolution(); break;

			case "wheel"				:	switch(extra["type"]){
												case "X":	items.currentTarget.scrollLeft += items.deltaY * this.pageInfo.wheel.xFactor; break;
												case "Y":	items.stopPropagation(); break;												
											}
											// switch(items.entity){
											// 	case "proposal"	: this.proposalPanel.nativeElement.scrollLeft 	+= items.e.deltaY; break;
											// 	case "custom"	: this.customPanel.nativeElement.scrollLeft 	+= items.e.deltaY; break;
											// }
											break;

			case "reload"				:	this.load('bookings'); break;

			case "toggleProvider"		: 	this.toggle('provider', items); 
											if (!this.doAction('execRequest', items)) { this.commons.generateToast("_ERROR", "_FILTERING_BOOKINGS_ERROR", "error"); }	
											break;
											
			case "filterButton"			: 	if(items["multiple"])	{	this.toggle('filterButton', items["item"]); 											
																		if (!this.doAction('execRequest', items["item"])) {	
																			this.toggle('filterButton', items["item"]);
																			this.commons.generateToast("_ERROR", "_FILTERING_BOOKINGS_ERROR", "error"); 	
																		}
											} else 					{	items["filter"]["items"].forEach(item=>{ item.value=item.name==items["item"].name; })	}
											break;
			case "zonesButton"			: 	items["filter"]["selected"] = items["item"]["name"]; 
											this.filterData('booking_groups');
											break;

			case "execRequest"			: 	this.load('booking_groups');	return true;
			case "showVideo"			:	this.commons.generateToast("_INFO","_SHOW_VIDEO","info"); break;
		}
	}

	toggle($entity, $item) 				{	switch ($entity) {
												case "provider"		: 	//this.providers.data = this.providers.data.map(item => { item.selected = item.id == $item.id; return item; }); 
																		this.pageInfo.currentProvider = $item;
																		this.initFilters();
																		break;
												case "filterButton"	: 	$item.value = $item.value ? false : true; break;	
											}
										}
									

	message(str,translate=false)		{ 	this.commons.generateToast("_INFO",str,"info");		}
	async calendarChange()				{	this.calendar.value.setHours(12);
											this.calendar.date	= this.calendar.value.toISOString().split('T')[0];

											// FORCE CALENDAR
											let forced = false;
											if(forced){
												this.calendar.date 	= "2022-05-11";
												console.log("FORCED CALENDAR DATE", this.calendar.date);
												this.calendar.last = this.calendar.date;
												await this.load('booking_groups');										
											} else {
												if(this.calendar.date!==this.calendar.last){
													console.log("LOADING DATE",this.calendar.date);
													this.calendar.last = this.calendar.date;
													await this.load('booking_groups');
												}
											}
										}

	selectSolution()					{ 	this.commons.generateToast('_INFO',this.commons.getTranslate('_SET_GROUP_SOLUTION')+" "+this.booking_groups["solution"]["solution"],'info'); 
											this.makeSolutionAsCustom(this["booking_groups"].solution,this["booking_groups"]);
										}
	makeSolutionAsCustom(origin,target,notify=false){	
											if (undefined===origin)	{ return false; 															}
											if (notify)				{ 
												this.message("Assign group. This will overwrite your custom solution !"); }
												this.getItemsByDirection(origin,true).forEach(item=>{ 
													if(undefined!=item.items && item["count"]>0){ target[item.type] = item.items; }
												});
												// this.showSolution(0);
												// this.tabPanel.selected = "custom";
										}

	clearSolution()						{	[ "arrival", "departure" ].forEach(direction=>{
												if(undefined!==this["booking_groups"].solution){
													this["booking_groups"].solution[direction].groups		= [];
													this["booking_groups"].solution[direction].unasssigned	= [];
												}
												if(undefined!==this["booking_groups"][direction]){
													this["booking_groups"][direction].groups	= [];
													this["booking_groups"][direction].unassigned= [];
												}
											});
										}

	showSolution(i)						{	let entity 				= "booking_groups";
											this[entity].solution 	= this["booking_groups"].data[i];										
											this.filterData(entity);
										}

	dragStart(event,group,item)			{	if(this.tabPanel.selected!="custom"){ 
												this.commons.generateToast("_ERROR","_PROPOSAL_NO_DRAGGABLE","error");
												return false; 
											}
											group.zone				= group.name==="unassigned"?item.zone:group.zone;
											this.draggedItem 		= { group: group, item: item }; 
										}

	dragEnd(event) 						{	this.draggedItem = null; 	}

	drop(group,event) 					{	if(undefined==this.draggedItem){ return false; }
											console.group("Drop");
											console.log("Moving from group "+this.draggedItem.group.name+" to "+group.name);
									
											// Check if drop zone is valid from drag zone
											if(!this.isValidDrop(this.draggedItem,group)){
												this.changeItemColor(event.currentTarget.children[0],"tomato");
												return false;
											}
									
											if(this.draggedItem) {
												// Add booking to new group
												group.bookings					= this.sortEntity("timestamp",[ ...group.bookings, this.draggedItem.item ]);
												// Remove booking from old group
												this.draggedItem.group.bookings	= this.draggedItem.group.bookings.filter((val,i) => val.customer !== this.draggedItem.item.customer );
												// Check if old group is now empty
												if(this.draggedItem.group.bookings.length==0){
													this.removeGroup(this.draggedItem.group);
												}
												this.draggedItem 				= null;
											}

											group.empty = false;		// No more empty group
											console.groupEnd();
										}

	isValidDrop(item,group) 			{	
											let originZone 	= item.group.name==="unassigned"?item.item.zone:item.group.zone;
											let targetZone	= group.zone;
									
											// if(group.name===item.group.name)	{ return false;	}		
											// if(group.name==="unassigned")		{ return true;  }
											// if(targetZone==="EMPTY GROUP")		{ group.zone=item.group.zone; return true; }
											// if(targetZone!==originZone)			{ return false;	}

											return this.checkProviderRestrictions({ item: item, group: { bookings: [ ...group.bookings, item.item ] } });										
										}

	checkProviderRestrictions($params){
		let providerData 	= ( this["providers"].data || [] ).find(item=>item.selected) || {};
		let success			= true;

		// _MAX_SPEEDY_STOPS	4
		// _MAX_WAIT_TIME		100
		// _USE_SPEEDY			true

		return Object.keys(providerData["restrictions"]||{}).reduce((result,restriction)=>{
			let value = providerData["restrictions"][restriction];											
			switch(restriction){
				case "_MAX_WAIT_TIME"	: 	const diff = this.getGroupInfo("timeRange",$params["group"]);											 
											if(diff>value){	this.commons.generateToast("_ERROR","_GROUP_PROVIDER_OVERTIME","error"); return false;	}
											break;
			}
			return result;
		},true);		
	}

	changeItemColor(item,color,timeout=200)	{	let currentColor 		= item.style.background;
												item.style.background 	= color;
												setTimeout(() => { 	item.style.background = currentColor; }, timeout);
											}
	getGroups(type,mode='mixed',extra={})	{	let direction 	= this["booking_groups"].direction;
												let groups		= [];
												switch(type){
													case "solutions": 	groups =  this["booking_groups"].solution[direction].groups;//.filter(item=>item.visible);	
																		break;
													case "custom"	: 	groups = this["booking_groups"][direction].groups;//.filter(item=>item.visible);
																		break;
												}
												switch(mode){
													case "mixed"	:	groups	= groups.filter(item=>(item.zone||"")==(this.pageInfo.zonesFilter.selected||"")); break;
													case "groups"	:	break;
													case "zone"		:	groups	= groups.filter(item=>item.zone==extra["zone"]);	break;																		
												}
												
												// Filter by private or shuttle groups 
												switch(this.mainFilters[1].selected){
													case "private"	:	groups 	= groups.filter(item=> item.private); break;												
													default			:	groups 	= groups.filter(item=>!item.private); break;
												}

												// Generate digests
												this.generateDigests("booking_groups",	{   zones       : this.pageInfo.zonesFilter.items.length,
																							bookings    : groups.reduce((acc,group)=>( acc+group.bookings.length ),0),
																							groups      : groups.length,
																							privates    : groups.reduce((acc,group)=>(acc+(group.bookings.filter(item=>item.transferType=="private"	)||[]).length),0),
																							shuttles    : groups.reduce((acc,group)=>(acc+(group.bookings.filter(item=>item.transferType=="shuttle"	)||[]).length),0),
																							speedies    : groups.reduce((acc,group)=>(acc+(group.bookings.filter(item=>item.transferType=="speedy"	)||[]).length),0)
																						});

												return groups;
											}
	createGroup()							{	let name 		= new Date().getTime();
												let direction 	= this["booking_groups"].direction;	

												this["booking_groups"][direction].groups = [ { 	empty	:	true,
																								name	: 	"_EMPTY_GROUP",
																								zone	: 	this.pageInfo.zonesFilter.selected,
																								bookings:	[]
																							}, ...this["booking_groups"][direction].groups.filter(item=>!item["empty"]) ];

												this.filterData(this["booking_groups"]);
											}
	removeGroup(group)						{	let direction = this["booking_groups"].direction;
												this["booking_groups"][direction].groups 				= this["booking_groups"][direction].groups.filter(item=> item.name!==group.name );
												this["booking_groups"][direction].unassigned.bookings 	= [...this["booking_groups"][direction].unassigned.bookings,...group.bookings];												
											}
											
	getGroupInfo(type,group)				{	switch(type){
													case "flights"	:	//return [ { value: 'UX8879' }, { value: 'UX7732' }, { value: 'UX8879' }, { value: 'UX7732' } ];
																		return group.flights;[ { value: 'UX8879' }, { value: 'UX7732' }, { value: 'UX8879' }, { value: 'UX7732' } ];
													case "flag"		:	return "/assets/layout/icons/flags/es.png";
													case "pax"		:	return group.bookings.reduce((acc,item)=>acc+parseInt(item.pax),0);
													case "firstTime":	return group.bookings.reduce((min,item)=>min<item.time?min:item.time,100000);
													case "lastTime"	:	return group.bookings.reduce((max,item)=>max>item.time?max:item.time,0);
													case "timeRange":	return group.bookings.reduce((range,item)=>{
																			range['min'] = range.min<item.timestamp?range.min:item.timestamp;
																			range['max'] = range.max>item.timestamp?range.max:item.timestamp;									
																			range["diff"]= (range.max-range.min)/60;			
																			// console.log("REDUCE",range);
																			return range;
																		},{min:1000000000000,max:0})['diff'];
												}
											}

	async persistSolution()					{	let entity 		= "booking_groups";
								
												// Check if there area unassigned bookings
												let pending		= ['arrival','departure'].reduce((pending,direction)=>{
													if(null==this[entity][direction]					){ return false; }
													if(null==this[entity][direction].unassigned			){ return false; }
													if(null==this[entity][direction].unassigned.bookings){ return false; }													
													return pending || this[entity][direction].unassigned.bookings.length > 0;													
												},false);
										
												if( pending ){ 
													this.commons.generateToast('Save solution','Pending Unassigned','error'); 
													return false;	
												}
										
												let postGroups = [];

												if(null!=this[entity].arrival){
													let arrivalGroups	= this.getHardCopy(this[entity].arrival.groups).map(group=>{
														group.bookings = group.bookings.map(booking=>booking.reference);
														return group;
													});
													postGroups 		= arrivalGroups;
												}
										
												if(null!=this[entity].departure){												
													let departureGroups	= this.getHardCopy(this[entity].departure.groups).map(group=>{
														group.bookings = group.bookings.map(booking=>booking.reference);
														return group;
													});
													postGroups		= [...postGroups, ...departureGroups];
												}

												console.log("Persist groups",postGroups);
												
												// Check info before request
												if( postGroups.length==0 			 ){ this.commons.generateToast('Save solution','No groups found in solution','error'); return false; 	}
												if( undefined === postGroups[0].date ){ this.commons.generateToast('Save solution','Solution date not found','error'); return false; 		}
										
												this.commons.generateToast('Save solution','Saving custom group solution','info');
										
												let params	= {
													date	: postGroups[0].date,
													solution: 0,
													groups	: postGroups
												};
										
												let localResponse 	= await this.entityService.postJSON(
													this.entityService.getUrl('group_solution'),
													params
												);

												console.log("Solution result local",localResponse);
			}
	sortEntity(type,entity)				{	switch(type){
												case "time":		return entity.sort((a,b)=>a.timestamp>b.timestamp?1:-1);
												case "timestamp":	return entity.sort((a,b)=>a.timestamp>b.timestamp?1:-1);
												default:			return entity;
											}
										}
	toggleItem(type,item)				{	switch(type){
												case "booking":
													item.expanded = item.expanded?false:true; 
											}
										}

	async load($entity) 				{	switch ($entity) {								
												case 'booking_groups'	:	if(null==this.pageInfo.currentProvider){ return false; }
																			this[$entity].spinner		= true;																			
																			this[$entity].data			= [];
																			
																			await this.entityService.loadEntity($entity,{ 	dmc			: this.commons.userInfo.dmc.id,
																															provider	: this.pageInfo.currentProvider.id,
																															destination	: this.commons.userInfo.destination.id,
																															date		: this.commons.formatCustomDate(this.calendar.date,'YYYYMMDD'), 
																															bookings	: true,
																															generate	: true
																															// generate	: false
																														});
																			this[$entity].data        	= this.entityService.get($entity);
																			
																			// Set loaded solutions and custom one default values
																			this[$entity].current		= {};

																			this.clearSolution();
																			this[$entity].socurrentlutions		= this[$entity].data.map(solution=>{
																				this.getItemsByDirection(solution).forEach(type=>{
																					solution[type.type] = type.items;																					
																				});
																				return solution;
																			}).sort((a,b)=>a.solution>=b.solution);
															
																			this[$entity].spinner		= false;
																			
																			console.log("DATA",		this[$entity].data		);
																			console.log("SOLUTIONS",this[$entity].solutions	);
															
																			// this.generateGroups(this[$entity].current);
																			this.showSolution(0);
																			this.makeSolutionAsCustom(this[$entity].data[0],this[$entity]);																			
																			break;												

												case 'providers'		:	this[$entity].data 			= 	(await this.aggregatorCtrl.getProviders() || []).map(item => {
																				item["logo"] 			= "/assets/layout/icons/providers/" + item["thumbnail"] + ".png";
																				return item;
																			});
														
																			// Mark error if no providers selected
																			if(this[$entity].data.length==0){
																				this.commons.generateToast("_ERROR","_NO_PROVIDERS_FOUND_CONTACT_AGENT","error");
																				return false;
																			}
																			
																			// Select 1st item by default
																			this[$entity].data[0].selected = true;

																			// If only 1 item select it by default
																			// if(this[$entity].data.length==1){ this[$entity].data[0].selected = true;	}

																			// this.bookings.filters[0].items		= this[$entity].data.map(item=>({ label: item.name, value: item.id }));
																			// this.bookings.filters[0].selected	= this[$entity].data.map(item=>item.id);
																			break;
											}
											this.filterData($entity);
	}	

	getItemsByDirection(origin,hardcopy=false){
												return ["arrival","departure"].map(type=>{
													let info = {
														type	: type,
														items	: {
															"groups"	: this.getHardCopy(origin.items.filter(item=>item.type==type),hardcopy)
																			  	.map(	group => { 
																						//   group.bookings 	= undefined==group.bookings?[]:this.sortEntity("time",group.bookings);
																					  	group.bookings 	= undefined==group.bookings?[]:this.sortEntity("timestamp",group.bookings);
																					  	group.flights		= group.bookings.map(item=>item.flight).filter((item,index,self)=>self.indexOf(item)==index);
																					  	group.bookings.forEach(booking=>{
																							booking.colorIndex = group.flights.indexOf(booking.flight);																						  
																					  	});
																					  	return group; 
																				}),
															"unassigned": { name: "unassigned", pax: 0, bookings: [] }
														}
													};
													info["count"] = info["items"]["groups"].length;
													return info;
												});
										}

	getHardCopy(items,hardcopy=true)	{	return hardcopy?JSON.parse(JSON.stringify(items)):items;	}
	
	checkFilter(filter)					{	if (undefined==filter)	{ return false; }
											console.log("Checking Entity",filter.entity,"Filter",filter.name);
											switch(filter.type){
												case "multiple"	:	this.checkMultipleFilter(filter); break;
												default			:	this.checkSimpleFilter(filter); break;
											}
										}

    checkMultipleFilter(filter){
		if(	undefined===filter.entity || undefined===this[filter.entity] || undefined===filter.name ){ console.log("OOPS. Invalid filter",filter); return false; 	}

		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};
		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter)			{		
		if(	undefined===filter.entity || undefined===this[filter.entity] || undefined===filter.name ){	console.log("OOPS. Invalid filter",filter); return false;	}
		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};
		this.filterData(filter.entity);
	}

	filterData($entity){
		// let data 	= this[$entity].data			|| [];
		// let filters = this[$entity].activeFilters 	|| {};
		// let entity	= this[$entity];	

		let entity	= this[$entity] 		|| $entity;
		let data 	= entity.data			|| [];
		let filters = entity.activeFilters 	|| {};
		

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>
			{
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "direction":	
						console.log("Filtering direction");
						entity.direction 	= selected; 
						entity.current		= entity[selected];
						break;

					case "zones":
						console.log("Filtering zones");
						['arrival','departure'].forEach(direction=>{

							// Mark current groups as visible if in selected zones
							if(undefined===entity[direction]){ return }
							entity[direction].groups.forEach(group=>{
								group.visible = group.empty || selected.reduce((result,filter)=>result || group.zone===filter,false);								
							});

							// Mark solutions groups as visibles if in selected zones							
							if(undefined===entity.solution || undefined===entity.solution[direction]){ return }
							entity.solution[direction].groups.forEach(group=>{
								group.visible = group.empty || selected.reduce((result,filter)=>result || group.zone===filter,false);								
							});
						});
				}
			});
		}		
	}
}
