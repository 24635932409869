import { CommonsService 				} from '../../service/commons.service';
import { EntityService 					} from '../../service/entity.service';
import { MessageService 				} from 'primeng/components/common/messageservice';
import { OnInit,
		 Component,
		 ViewChild,
		 ViewEncapsulation,
		 ElementRef,
		 platformCore,
		 HostListener					} from '@angular/core';
import { filters, zonesFilter 			} from './data/filters';
import { filterEntity 					} from './fn/filters';
import { providerRestrictionFields 		} from './data/filters';
import { StorageService 				} from '../../service/storageservice';
import { help 							} from './data/help';
import { mainView,
		 calendarInfo,
		 colorInfo,
		 heightInfo,
		 servicesGrid,
		 transportersCalendarFakeItem,
		 transporterAssignOptions		} from './data/info';
import { servicesCols 					} from './columns/services.columns';
import { SimpleFirebaseService 			} from '../../service/database/simplefirebase.service';
import { bookingsCols 					} from './columns/bookings.columns';
import { tabs							} from './data/tabs';

interface Calendar {
	value			: any,
	date			: string,
	last			: string
};

@Component({
    styleUrls		: ['./tabletemplate.component.scss'],
	templateUrl		: './tabletemplate.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})
export class TabletemplateComponent implements OnInit
{
	// F2 opens SEARCH
	// HostListener makes some event trigger action
	@HostListener('document:keydown', ['$event'])
    keypress(e: KeyboardEvent) { this.keypressed(e.key); }

	@ViewChild('bookingsGrid'		)	bookingsGrid	: any;
	@ViewChild("search"				)	searchInput		: any;

	pageInfo			: any 		= { fullScreen: false, export: [] };
	userInfo			: any		= {};
	zoom				: number 	= 10;
	lat					: number	= 39.638464;
	lng					: number	= 3.003831;
	providers			: any 		= {};
	mainView			: any		= mainView;
	calendar			: Calendar 	= <Calendar>{ last: '' };
	calendarFullView	: boolean	= true;
	entities			: any[]		= [ "groups", "fleet", "drivers" ];
	bookings			: any 		= { cols: bookingsCols, filters: {}, data: [] };
	draggedItem			: any;
	chartData			: any;
	linkable			: any 		= {};

	plainServices		: any[]		= [];
	transportsCalendar	: any 		= { rows	: [{ empty: true, items: [] }],
										tmpRows	: [{ emtpy: true, items: []	}]
									};


	rowData				: any 		= { bookingsGrid: {}, servicesGrid: {}};

    constructor(
		private commons					: CommonsService,
		private entityService			: EntityService,
		private storageCtrl				: StorageService,
		private firebaseService			: SimpleFirebaseService
	){
		this.staticInit();
	}

	/** -----------------------------------------------------------------------
	 ** KEYPRESS HANDLER 
	 **	-----------------------------------------------------------------------*/
	keypressed($key){
		switch($key){
			case 'Enter'	: this.doEnter();		break;
			case 'F2'		: this.doF2(); 			break;
			case 'Escape'	: this.doEscape();		break;
		}
	}
	doF2()		{	this.doAction('search','show',{})	}
	doEscape()	{
		if(this.pageInfo.search.show){
			this.doAction("search","hide",{});
		} else {
			this.pageInfo.search_customer = undefined;
		}
	}
	doEnter()			{
		if(this.pageInfo.search.show){
			this.doAction("search",'exec',{});
		}
	}

	async ngOnInit()	{ await this.init(); }
	ngAfterViewInit()	{}

	async init()		{	
		
		this.pageInfo.tabs					=	tabs;
		this.pageInfo.filterFomentoByTime	= 	true;
		this.pageInfo.table 				=	{
			height				: '65vh',
			border				: '1px solid #f0f0f0',
			rowExpansionWidth	: '85vw'
		};
		this.pageInfo.search 				= 	{
			show	: 	false,
			content	: 	"",
			fields	: 	[ "reference", "customer" ]
		};
		this.pageInfo.filters 				= filters;
		this.generateMenuCols("groups");
	}

	async staticInit()									{
		this.pageInfo.debug					=	true;
		this.pageInfo.bookingsButtons		= 	{ showErrors: false };
		this.pageInfo.uploadedFiles			= 	[];
		this.pageInfo.providerRestrictions	= 	providerRestrictionFields.map(item=>{ item["label"] = this.commons.getTranslate(item.name); return item; });
		this.pageInfo.wheel					= 	{ xFactor: 2, yFactor: 1, sliderFactor: 50 };
		this.pageInfo.noData				= 	{										
			header	: {
				title	: "_NO_DATA_TITLE",
				message	: "_NO_DATA_DESC",
			},
			items	: [
				{ icon	: "bus",			message	: "_WELCOME_COMPOSITOR_ITEM_1" },
				{ icon	: "calendar",		message	: "_WELCOME_COMPOSITOR_ITEM_2" },
				{ icon	: "hand-o-right",	message	: "_WELCOME_COMPOSITOR_ITEM_3" },
				{ icon	: "floppy-o",		message	: "_WELCOME_COMPOSITOR_ITEM_4" }
			]
		};
		this.pageInfo.help					= 	help;this.pageInfo.height 				= 	heightInfo;
		this.pageInfo.colors				=	colorInfo;
		this.pageInfo.calendar				=	calendarInfo;
		this.pageInfo.rowData				= 	{};
	}

	async loadEntities() 				{
		await this.load("providers");
		this.calendar.date = this.commons.getToday('YYYY-MM-DD');
	}


	getInfo($entity, $params){
		switch($entity){
			case "rowData"		: 	
				switch($params.type){
					case "statusColor"	:
						if($params.rowData["assigned"]			){ return "purple"; }
						if($params.rowData['verified']=='yes'	){ return 'green';	}
						return 'gray';
				}
				break;

			case "grid"			:	
				switch($params.type){
					case 'cols'	: return $params["items"];
				}
				break;

			case "tab"			:	
				let cls 	= this.pageInfo.calendar.view.selected==$params["tab"].name?'selected':'';
				switch($params["tab"]["name"])	{
					case "<tabName>"	:	
						$params["tab"].disabled = false;
						break;
				}		
		}
	}

	async initFilters()				{	
		// this.pageInfo.zonesFilter			= zonesFilter;
		// this.pageInfo.zonesFilter.items		= ( this["providers"].selected || {}).zones;
		// this.pageInfo.zonesFilter.selected	= "";
	}

	getFilterButtonClass($filter,$item)	{	
		if ( $filter.multiple )	{	return $filter.selected==$item.name?'selected':'';		}
		else 					{	return $item.value?'selected':'';						}
	}


	// -----------------------------------------------------------------------------------
	// RENDERING METHODS
	// -----------------------------------------------------------------------------------
	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR'); }
	getFieldEditor($col) 				{ 	return $col.editor ? $col.editor : 'input'; }
	getRendererType($type) 				{	
		switch ($type) {
			case 'imported'		: return (type, col, items) => this.importedRenderer(type, col, items);
		}
	}
	getRenderer($type, $col, $items) 	{
		return $col.renderer
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items);
	}
	getRendererNew($params)				{
		let $type 	= $params["type"	];
		let $col	= $params["col"		];
		let $items	= $params["items"	];
		let $name	= $params["name"	];
		return $col.renderer
			? this.getRendererType($col.renderer)($type, $col, $items)
			: this.defaultRenderer($type, $col, $items);
	}
	defaultRenderer($type, $col, $items){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				return Array.isArray($items[$col.field])
						?$items[$col.field].join(",")
						:$items[$col.field];
		}
	}
	defaultStyleRenderer($col) 			{	return {
			'width'		: $col.width ? $col.width : '',
			'text-align': $col.align ? $col.align : ''
		};
	}
	importedRenderer($type, $col, $items){
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	:
				switch($items[$col.field]){
					case false	:	return "";
					case true	:	return "<i class='fa fa-times'></i>";
				}
		}
	}
	onSort(){}


	isDisabled($type,$item)				{	
		switch($type){
			case "<type>"	:	return true;
		}
	}

	// -----------------------------------------------------------------------------------
	// END RENDERING METHODS
	// -----------------------------------------------------------------------------------

	getTabInfo($type,$tab,$panel,$params={})	{	
		switch($type){
			case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
			case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
								return this.isDisabled($tab["isDisabled"],$tab);
			case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
		}
	}

	getItemInfo(type,info,item:any={})	{
		const scaleFactorXInitial 	= this.pageInfo.calendar.scaleFactorXInitial;
		const scaleFactorYInitial 	= this.pageInfo.calendar.scaleFactorYInitial;
		const scaleFactor			= this.pageInfo.calendar.scaleFactor;
		const scaleX 				= this.pageInfo.calendar.scaleX;
		const scaleY 				= this.pageInfo.calendar.scaleY;

		switch(type){
		}
	}

	min(a,b)					{ return a>=b?b:a; }
	max(a,b)					{ return a>=b?a:b; }

	async calendarChange()			{
		this.calendar.value.setHours(12);
		this.calendar.date	= this.calendar.value.toISOString().split('T')[0];
		if(this.calendar.date!==this.calendar.last){
			this.calendar.last 				= this.calendar.date;
			this.pageInfo.current_timestamp	= undefined;
		}
	}

	getCalendarTimes($mode="simple"){
		switch($mode){
			case "simple"	: 	return [ ...Array.from(Array(24).keys()).map(hour=>(hour<10?'0':'')+hour+":00"), ...Array.from(Array(4).keys()).map(hour=>'0'+hour+":00") ]
			case "complex"	:	return Array.from(Array(28).keys()).map(item=>item.toString());
		}
	}

	setViewMode(selected)			{	
		switch(selected){
			case "editable"		: this.calendarFullView   = false; 	break;
			case "big"			: this.calendarFullView   = true; 	break;
			case "full"			: this.calendarFullView   = true; 	break;
		}
	}

	toggle($type,$item=null,$status=undefined){
		switch($type){
			case "booking"				:	
				$item.expanded	= $item.expanded?false:true; 
				break;
		}
	}
	
	generateMenuCols($entity) {
		switch($entity){
			case "services"	: this[$entity].cols = servicesCols;	break;
		}
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
	}

	getEntity($entity,$info){
		switch($entity){
			case "booking"	: return this.bookings.data.find(b=>b.reference == $info.item );
		}
	}

	getFilteredBookings($info){
		let directions_selected	= 	this.pageInfo.controlPanels.transfers.direction.items
										.filter	(item => item.value	)
										.map	(item => item.name	);

		let bookings = this.bookings.data
			.filter	(item=>{
				let found = directions_selected.some(dir=>dir==item.direction);
				return found
			})
			.map((item,index)=>{
				item.id = index+1;
				return item;
			});

		if($info["filters"]){
			if ($info["filters"].some(item=>item=="not_grouped")){
				bookings = bookings.filter(item=>item.group==undefined);
			}
		}

		if(this.pageInfo.bookingsButtons.showErrors){
			bookings = bookings.filter(item=>undefined!=item.errors && item.errors.length>0);
		}

		bookings	= bookings.sort((a,b)=>a.group>b.group?1:-1);

		return bookings;
	}

	getFilteredEntity($entity,$info:any={}){
		let selected;
		let results;
		let plates;

		switch($entity)	{
			case "bookings"			:	return this.getFilteredBookings($info);
		}
	}

	async copyToClipboard($info){
		try {
			if(navigator && navigator["clipboard"]){
				await Promise.resolve(navigator["clipboard"].writeText($info.value));
				this.commons.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_SUCCESS","info");
			} else {
				new Error();
			}
		}catch(e){
			this.commons.generateToast("_CLIPBOARD","_COPY_TO_CLIPBOARD_ERROR","error");
		}
	}

	toggleRow($item) 				{ this.toggleDT($item); 						}
	expandRow($item) 				{ this.toggleDT($item); 						}
	collapseRow($item) 				{ this.toggleDT($item); 						}

	/**
	 * actions to take once we open row with expander
	 *
	 * @param $item
	 * @returns
	 */
	toggleDT($item) 				{
		console.log($item);
		if (undefined === $item) { return false; }
		if (undefined == this[$item.table].expandedRowKeys[$item.id]) {
			this[$item.table].expandedRowKeys 				= [];
			this[$item.table].expandedRowKeys[$item.id] 	= 1;
			this.rowData[$item.table] 						= $item;
		} else {
			this[$item.table].expandedRowKeys 				= [];
		}
	}

	/**
	 * execute action
	 *
	 * @param $type
	 * @param $action
	 * @param $info
	 * @returns
	 */
	doAction($type,$action,$info){
		let proposed = {};
		switch($type){
			case "table"				:
				switch($action){
					case "click":
						switch($info.type){
							case "row"		:	$info.rowData.selected = $info.rowData.selected ? false: true;
						}
						switch($info.col.field){
							case "reference": this.copyToClipboard($info); 	break;
						}
						break;
				}
				break;

			case "search"				:
				switch($action){
					case "autocomplete"	:	break;
					case "exec"			:	this.load("bookings_search");break;
					case "show"			:	this.pageInfo.search.show = true;
											if(this.searchInput){
												this.searchInput.nativeElement.focus();
											}
											break;
					case "hide"			:	this.pageInfo.search.show = false;
											this.pageInfo.search.content	= "";
											if(this.searchInput){
												this.searchInput.nativeElement.blur();
											}
											break;
				}
				break;

			case "row"					:
				switch($action){
					case "toggle":
						if($info.table){ $info.rowData.table = $info.table; }
						switch($info.rowData.table){
							case "bookingsGrid" : break;
							default				: this.toggleRow($info.rowData); break;
						}
						break;
					case "click":
						switch($info.col.field){
							case "reference"	: this.copyToClipboard({ value: $info.rowData[$info.col.field] }); break;
						}
						break;
				}
				break;

			case "tab"					:	
				switch($action)	{
					case "select"			: 	if(!$info["tab"].disabled){	this.pageInfo.calendar.view.selected=$info["tab"].name;}	break;
				}
				break;

			case "wheel"				:	
				switch($info["type"]){
					case "X"				:	
						if(undefined==$info["event"]){ return false; }
						$info["event"].currentTarget.scrollLeft += $info["event"].deltaY * this.pageInfo.wheel.xFactor;
						$info["event"].preventDefault();
						$info["event"].stopPropagation();
						break;

					case "Y"				:	
						if(undefined==$info["event"]){ return false; }
						$info["event"].stopPropagation();
						break;

					case "slider"			:	
						this.pageInfo.timeRangeValues.initTime = Math.max(	this.pageInfo.timeRangeValues.min,
																			Math.min(	this.pageInfo.timeRangeValues.max,
																						this.pageInfo.timeRangeValues.initTime + Math.ceil($info["event"].deltaY/this.pageInfo.wheel.sliderFactor)
																					)
																		);
						if(undefined==$info["event"]){ return false; }
						$info["event"].stopPropagation();
						break;
				}
				break;

			case "button"				:	
				switch($action){
					case "reload"			:	this.loadEntities();	break;					
				}
				break;
		}
	}

	/**
	 * order entities by field and criteria
	 *
	 * @param $entity
	 * @param $field
	 * @param $order
	 * @returns
	 */
	 private order($entity,$field,$order){
		let cloned = [];

		// Clone entity
		$entity.forEach(item=>{	cloned.push(item); });

		cloned.sort((a,b)=>{
			let aValue = parseInt(a[$field]);
			let bValue = parseInt(b[$field]);
			switch($order){
				default		:
				case "desc"	: return aValue>bValue?-1:1;
				case "asc"	: return aValue>bValue?1:-1;
			}
		});
		console.log("[order] Cloned",cloned);
		return cloned;
	}

	/**
	 * load all type of entities
	 *
	 * @param $entity
	 * @returns
	 */
	async load($entity) {
		let response 	= {};
		let realMode	= true;
        switch ($entity) {
		}
		filterEntity(this,$entity);
	}
}