import { steps, tutorialSteps			} from './steps';
import { filters						} from './filters';

export let pageInfo 		= {
    globalEntities				: [ 'countries','destinations','providers','companiesCreateds','modules'],
    destinations				: {	
        items	    : 	[
            { name: 'countries', 	label: '_COUNTRIES', 	icon: 'map-marker',	isDisabled: ()=>( false	)},
            { name: 'help', 		label: '_HELP',			icon: 'question',	isDisabled: ()=>( false	)}
        ],
        selected	: 'countries',
    },
    height						:	{	
                                    mainControls	: "66vh",
                                    container		: "60vh"				
                                },
    fromWizard					:	true,
    creationProcess				:	true,
    form						:	{},
    listUsersCreateds			:	[],
    displayTutorial				:	false,
    displaySummaryPricing		:	false,
    creatingCustomDestination	:	false,
    
    staff						:	{
        buttons		: [
            { 	name: 'reload',			label: '_RELOAD', 			icon: 'fa fa-refresh' 		, type: 'button'	},
            { 	name: 'new',			label: '_CREATE',	 		icon: 'fa fa-plus' 			, type: 'button' 	},
            { 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	}			
        ]
    },

    entities					:	{
                                        staff		: {}, 
                                        countries	: {}, 
                                        destinations: {}, 
                                        stations	: {}, 
                                        profiles	: {},
                                        dmcs		: [],
                                        providers 	: {},
                                        modules		: {}
                                    },
                                    
    imgs						:	{
        noImgDest	:	'https://icon-library.net/images/polygon-icon/polygon-icon-28.jpg',
        noImgComp	:	'https://img.icons8.com/bubbles/2x/company.png',
        stations	:	'assets/demo/images/general/collageStation.png',
        airport		:	{	
            selected 	:	'assets/demo/images/maps/airportSelected.png',
            unselected	:	'assets/demo/images/maps/airportUnselected.png'
        },
        port		:	{	
            selected 	:	'assets/demo/images/maps/portSelected.png',
            unselected	:	'assets/demo/images/maps/portUnselected.png'
        },
        train		:	{	
            selected 	:	'assets/demo/images/maps/trainSelected.png',
            unselected	:	'assets/demo/images/maps/trainUnselected.png'
        }
    },

    currentStationTab			:	0,
    stationsTypes				:	[	
        {type :'airport', 	label : '_AIRPORT', 	noItemsLabel : '_NO_AIRPORTS_ON_THIS_AREAS'},
        {type : 'port', 	label : '_PORT', 		noItemsLabel : '_NO_PORTS_ON_THIS_AREAS'},
        {type : 'train', 	label : '_TRAIN', 		noItemsLabel : '_NO_TRAINS_ON_THIS_AREAS'}
    ],
    filters					:	filters,
    steps					:	steps,
    tutorialSteps			:	tutorialSteps,
    displayFooterButtons	:	false,

    wizardButtons			:	[
        {
            label	:	'_BACK',
            icon	:	'fa fa-fw fa-arrow-left',
            iconPos	:	'left',
            visible	:	true,
            disabled:	true,
            value	:	'back'
        },
        {
            label	:	'_NEXT',
            iconPos	:	'right',
            icon	:	'fa fa-fw fa-arrow-right',
            value	:	'next',
            visible	:	true,
        }
    ],

    crudDmcButtons		    :	[
        {
            label	:	'_EDIT',
            icon	:	'fa fa-fw fa-edit',
            iconPos	:	'right',
            value	:	'editDmc'
        },
        {
            label	:	'_DESTINATIONS',
            icon	:	'fa fa-fw fa-map-marker',
            iconPos	:	'right',
            value	:	'goToDestinations'
        },
        {
            label	:	'_DELETE',
            icon	:	'fa fa-fw fa-remove',
            iconPos	:	'right',
            value	:	'removeDmc'
        }
    ],

    crudDestinationButtons	:	[
        {
            label			:	'_EDIT_CUSTOM_CREATION',
            icon			:	'fa fa-fw fa-edit',
            iconPos			:	'right',
            value			:	'editDest',
            checkVisible	:	'customCreation'
        },
        {
            label			:	'_NEXT',
            icon			:	'fa fa-fw fa-arrow-right',
            iconPos			:	'right',
            value			:	'nextStep',
        },
        {
            label			:	'_DELETE',
            icon			:	'fa fa-fw fa-remove',
            iconPos			:	'right',
            checkVisible	:	'myDestinations',
            value			:	'deleteDest'
        }
    ],

    loading		            :	{
        destinationsFromCountry	:	false
    },

    formScheduler	        :	[
        {
            id		:	0,
            value	:	[0,1,2,3,4],
            type	:	'template',
            label	:	'_FROM_MONDAY_TO_FRIDAY'
        },
        {
            id		:	1,
            value	:	[0,1,2,3,4,5,6],
            type	:	'template',
            label	:	'_FROM_MONDAY_TO_SUNDAY'
        },
        {
            id		:	2,
            value	:	[],
            type	:	'custom',
            label	:	'_CUSTOM'
        }
    ],

    displayTableAccounts	:	false,
    enableAddProviderAccount:	false,
    listSchedules			:	[],
    editingScheduler		:	false,
    currentSchedule			:	{},
    listDays				:   [
        { value : 0, 	label : '_MONDAY_FL' 		},
        { value : 1, 	label : '_TUESDAY_FL'		},
        { value : 2, 	label : '_WEDNESDAY_FL'		},
        { value : 3, 	label : '_THURSDAY_FL'		},
        { value : 4, 	label : '_FRIDAY_FL'		},
        { value : 5, 	label : '_SATURDAY_FL'		},
        { value : 6, 	label : '_SUNDAY_FL'		}
    ],
    listMonths 				:   [
        { value : 0, 	label : '_JANUARY' 			},
        { value : 1, 	label : '_FEBRUARY'			},
        { value : 2, 	label : '_MARCH'			},
        { value : 3, 	label : '_APRIL'			},
        { value : 4, 	label : '_MAY'				},
        { value : 5, 	label : '_JUNE'				},
        { value : 6, 	label : '_JULY'				},
        { value : 7, 	label : '_AUGUST'			},
        { value : 8, 	label : '_SEPTEMBER'		},
        { value : 9, 	label : '_OCTOBER'			},
        { value : 10, 	label : '_NOVEMBER'			},
        { value : 11, 	label : '_DECEMBER'			}
    ],

    destinationForm	        :	{                    
        form		: [
            {	cols		: 3			},
            {
                title		: "_REGISTER",
                cols		: 6,
                showTitle	: false,
                icon		: "fa fa-file-text-o",
                padding		: "1rem 0rem 1rem 1rem",
                items		: [
                    { mandatory: true, 	field	: 'name', 		label:'_COUNTRY',		type: 'text',	editor: 'autocomplete',	editable	: true, entityList: 'countries'	},
                ]
            }						
        ]			
    },

    providerForm	        :	{
        form    :   [
                        {
                            cols		:	6,
                            showTitle	: 	true,
                            title			:	'_CREDENTIALS',
                            icon			: 	"fa fa-user",
                            items			:	[
                                {	mandatory	:	true,	field: 'account',		label		: '_ACCOUNT',		editable	: true, 	type: 'text'		},
                                {	mandatory	:	true,	field: 'password',	label		: '_PASSWORD',	editable	: true, 	type: 'password' 		}								
                            ]
                        },
                        {
                            cols		:	6,
                            showTitle	: 	true,
                            title			:	'_CONTRACT',
                            icon			: 	"fa fa-file-text-o",
                            items			:	[
                                {	mandatory	:	true,	field: 'hire_init',	label		: '_HIRE_INIT',	editable	: true, 	editor: 'datetime', options : {yearNavigator: true}		},
                                {	mandatory	:	true,	field: 'hire_end',	label		: '_HIRE_END',	editable	: true, 	editor: 'datetime', options : {yearNavigator: true,	minDate : {field : 'hire_init'}, }	}							
                            ]
                        }		
                    ],
        buttons	:   {
            items:	[
                { name: 'save', 	label: '_SAVE',		action: 'save',  icon: 'fa fa-fw fa-save'},
                { name: 'remove', 	label: '_DELETE',	action: 'delete',  icon: 'fa fa-fw fa-remove'},
            ]
        }
    },


    timerFormProvider	    :	{
        form		:	[
            {
                cols		:	12,
                showTitle	:	false,
                title		:	'_HOUR',
                icon		:	"fa fa fa-timer",
                items		:	[
                    {	field	:	'start_hour',	label	:	'_START_HOUR',	editable	:	true,	editor	:	'datetime',	options :	{timeOnly : true}},
                    {	field	:	'interval'	,	label	:	'_INTERVAL_MIN',	editable	:	true,	type	:	'number',	placeholder	:	'_IN_MINUTES', }

                ]	
            }
        ]
    },

    settingsDestination     :	{
        form		:	[
            {
                cols		:	12,
                showTitle	:	true,
                title		:	'_SETTINGS',
                icon		:	"fa fa fa-timer",
                items		:	[
                    {	field	:	'waiting_time'		,	label	:	'_MAX_WAIT_TIME',		editable	:	true,	editor	:	'text', type:'number'	},
                    {	field	:	'use_speedy'		,	label	:	'_USE_SPEEDY',			editable	:	true,	editor	:	'groupButton', type:'toggleable', items : [{label : '_YES', default : true, value:'on', icon : 'fa fa-fw fa-check'},{label : '_NO', default: false, value:'off', icon : 'fa fa-fw fa-remove'}]	},
                    {	field	:	'max_speedy_stops'	,	label	:	'_MAX_SPEEDY_STOPS',	editable	:	true,	editor	:	'text', type:'number'	},
                ]	
            }
        ],
        buttons	: {
            items:	[
                { name: 'save', 	label: '_SAVE',		action: 'save',  icon: 'fa fa-fw fa-save'},
            ]
        }	
    }
}