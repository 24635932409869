import { DragulaService 	} from 'ng2-dragula';
import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';

let createPlayer = require('web-audio-player');
@Component({
  selector    	: 'app-tour-wizard-i18n',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class TourWizardI18nComponent {

	@Input("type")    	type		: string	= "";
	@Input("info")		info		: any		= {};	
	@Output()			emitter					= new EventEmitter<any>();	
	
	pageInfo  			: any 	= {};	
	draggedItem			: any 	= {};	
	
	constructor( public commons: CommonsService ){ this.init(); }	

	init(){
		this.pageInfo.legend		= "_STEP_I18N_LEGEND";				
		this.pageInfo.results		= {};
		this.pageInfo.editor		= {};
	}

	ngOnInit()					{
		this.pageInfo.langs			= this.info.langs || [];
		this.pageInfo.form 			= undefined!=this.info.forms?this.info.forms["i18n"]:[];

		// Generate items
		this.pageInfo.i18n			= [];
		this.pageInfo.form.forEach(col=>{
			col.items.forEach(item=>{ 
				this.pageInfo.i18n[item.field] = this.info[item.field];
			});
		});

		// Generate langs
		this.pageInfo.langs.forEach(lang=>{
			this.info.i18n[lang.code] = undefined==this.info.i18n[lang.code]?{}:this.info.i18n[lang.code];
		});
		this.pageInfo.entities		= this.info.entities;
		console.log("I18N entities",this.pageInfo.entities);
		// this.pageInfo.langSelected	= this.pageInfo.langSelected || ( undefined===this.pageInfo.langs || undefined===this.pageInfo.langs[0].code )?this.pageInfo.langs[0].code:"es";
		this.action("selectLang",this.pageInfo.langs[0]);
	}

	ngOnDestroy() 				{}

	createItem($field)			{ 	
		this.pageInfo.i18n[$field] = [ ...(this.pageInfo.i18n[$field] || []),""];
	}
	deleteItem($field,$item)	{ 	
		this.pageInfo.i18n[$field] = this.pageInfo.i18n[$field].filter(item=>item!=$item);			
	}

	getStatusIcon(item){
		if(item.mandatory){
			if(undefined==this.info[item.field] || this.info[item.field]==""){ return "fa fa-ban"; }
			return "fa fa-check";
		}
		return "";
	}

	getStatusColor(item){
		if(item.mandatory){
			if(undefined==this.info[item.field] || this.info[item.field]==""){ return "crimson"; }
			return "forestgreen";
		}
		return "cadetblue";
	}

	search(item,$event) {
		if(undefined==item.entityList){ return false; }
		this.pageInfo.results[item.field] =  this.pageInfo.entities[item.entityList].filter(item => item.name.includes($event.query)).map(item=>item.name);
	}
	
    action($type,$item,$event?){		
		switch($type){
			case "store"		:	let itemContent;
									if($event.isI18N){
										switch($event.isList||false){
											case true	:	this.pageInfo.i18n[$item.field] = this.pageInfo.i18n[$item.field].map((item,index)=>index==$event.index?$event.event.srcElement.value:item); break;
										}
										this.info.i18n[this.pageInfo.langSelected][$item.field] = this.pageInfo.i18n[$item.field];
									} else {
										switch($event.isList||false){
											case true	:	this.pageInfo.i18n[$item.field] = this.pageInfo.i18n[$item.field].map((item,index)=>index==$event.index?$event.event.srcElement.value:item); break;
										}
										this.info[$item.field] = this.pageInfo.i18n[$item.field];							
									}
									break;
			// case "getEditorText":	this.pageInfo.editor[$item.field] = $event.textValue; break;
			case "selectLang"	: 	if(this.pageInfo.langSelected==$item.code) { return; }
									// New element
									this.storeI18nItems();
									//this.pageInfo.langs.forEach(item=>item.selected=false);										
									//$item.selected 				= true;										
									this.pageInfo.langSelected	= $item.code;									
									this.restoreI18nItems();
									break;
			case "save"			:	this.storeI18nItems();
									break;									
			case "showInfo"		: 	if($item.info){ this.commons.generateToast("_INFO",$item.label+"_INFO","info"); } break;
		}
	}

	storeI18nItems(){
		if(undefined==this.pageInfo.langSelected){ return false; }		
		this.pageInfo.form.forEach(form=>{
			if(form.i18n!=true){ return false; }
			form.items.forEach(item=>{
				this.info.i18n[this.pageInfo.langSelected][item.field] = this.pageInfo.i18n[item.field];
			});
		});
	}

	restoreI18nItems(){
		if(undefined==this.pageInfo.langSelected){ return false; }		
		this.pageInfo.form.forEach(form=>{
			if(form.i18n!=true){ return false; }
			form.items.forEach(item=>{
				this.pageInfo.i18n[item.field] = this.info.i18n[this.pageInfo.langSelected][item.field];
			});
		});
	}

	remove($type,$item){
		switch($type){			
		}
	}

	dragStart(event,item) 		{ this.draggedItem = item;	}
	dragEnd(event) 				{ this.draggedItem = null; 	}
	
	drop(group,event) {
		switch(group){
			case "reorder"		: 
				if(this.draggedItem.position==event.position){ return false; }
				let tmp						= this.draggedItem.position;
				this.draggedItem.position 	= event.position;
				event.position				= tmp;
				this.pageInfo.selected		= this.pageInfo.selected.sort((a,b)=>a.position>b.position?1:-1);
				break;
		}
	}    
}
