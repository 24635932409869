import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { toursData1 		} from './data/tours';
import { buttons 			} from './data/buttons';
import { filters 			} from './data/filters';
import { EntityService 		} from 'src/app/demo/service/entity.service';

@Component({
  selector    		: 'app-tour',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class TourComponent {

  	@Input("current")   currentTour:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { tours: [] }
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService	
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };	
	}
	async loadEntities()		{ await this.load("tours"); 					}
  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();
		this.selectTour(this.currentTour);											
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)	{
		switch($type){
			case "toggle_tour"	:	this.selectTour($item); break;			
			case "button"		:	switch($item.name){		
										case "new_tour"		: 	this.emitter.emit({ action: 'new_tour' });						break;
										case "reload"		: 	this.load("tours"); this.selectTour(this.currentTour);			break;
										case "edit"			:	this.emitter.emit({ action: 'view_tour', tour: $item });		break;			
										case "export"		: 	this.commons.generateToast("Button pressed","Export","info");	break;
										case "import"		: 	this.commons.generateToast("Button pressed","Import","info");	break;
									}
									break;
		}
	}
	selectTour($item){
		if(undefined==$item){ this.pageInfo.itemSelected = false; return false; }
		this.pageInfo.entities["tours"].map(item=>{item.selected=item.id==$item.id?(item.selected?false:true):false;});
		this.pageInfo.itemSelected 	= this.pageInfo.entities["tours"].some(item=>item.selected);
		if(this.pageInfo.itemSelected){ 
			this.emitter.emit({'action':'tour_selected','item': this.pageInfo.entities["tours"].find(item=>item.selected) });
		} else {
			this.emitter.emit({'action':'tour_unselected'});
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "tours"	: 	return (this.pageInfo.entities[$entity]||[]).filter(item=>{
									return true;
									// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
									// item.view 		= isSelected;
									// item.color		= "red";
									// return isSelected;
								});
		}
	}
	async load($entity){
		switch($entity){
			case 'tours' :
				let response 					= 	await Promise.resolve(this.entityService.getRequest($entity));
				this.pageInfo.entities[$entity]	= 	response["data"].map((item,index)=>({ 																		
														id			: item["id"			],
														type		: item["type"		],
														
														active		: item["action"		],																		
														name		: item["name"		],																		
														title		: item["name"		].charAt(0).toUpperCase()+item.name.slice(1),																		
														
														langs		: item["langs"		],

														pictures	: item["pictures"	] 	|| 0,
														videos		: item["videos"		]	|| 0,
														audios		: item["audios"		]	|| 0,

														thumbnail	: item["thumbnail"	]	|| "/assets/layout/images/sights/no_sight.jpg",
														
														variants	: item["variants"	]	|| 0,

														value		: item["value"		]	|| 0,
														visits		: item["visits"		] 	|| 0,
														comments	: item["comments"	]	|| 0,
														books		: item["books"		]	|| 0,
														prize		: item["prize"		]	|| 0,
														offers		: item["offers"		]	|| [],
														promotions	: item["promotions"	]	|| [],
														legend		: item["legend"		]	|| "_NO_LEGEND",																		
													}));
				break;
		}
	}
}
