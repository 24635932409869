import { SuperformService 		} from './../../../service/super-form/superform.service';
import { AuthFirebaseService 	} from './../../../service/database/authfirebase.service';
import { CompanyService 		} from './../../../service/database/company.service';
import { Component, 
		 OnInit, 
		 ViewEncapsulation, 
		 Input, 
		 Output, 
		 EventEmitter, 
		 ViewChild 				} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';

@Component({
    selector        : 'app-main-create-company',
	styleUrls		: ['./create-company.component.scss'],
	templateUrl		: './create-company.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class CreateCompanyComponent implements OnInit {
    
	@Input()    fromWizard  :   any;
	@Input()	item		:	any;
	@Input()	mode		:	any; //create, edit
	@Input()	modules		:	any;
	@Output()	emitter		= new EventEmitter<any>();	

	@ViewChild('companyModules')	companyModules	:	any;
	@ViewChild('companyInfo')		companyInfo		:	any;
    pageInfo    :   any = {};
    
    constructor(
		private companyService  : CompanyService,
		private authCtrl		: AuthFirebaseService,
		private superFormCtrl	: SuperformService,	
        private commons         : CommonsService 
	){}

    ngOnInit(){
		console.log('fromWizard',this.fromWizard);
		
		this.pageInfo.currentViewIndex	=	0,

		this.pageInfo.sections			=	[
			{
				title		:	'_DMC_INFO',
				value		:	'dmc_info',
				subtitle	:	'_SUBTITLE_DMC_INFO',
				img			:	'https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/File-info-icon.png'

			},
			{
				title 		:	'_MODULES_INFO',
				value		:	'modules',
				subtitle	:	'_SUBTITLE_MODULES_INFO',
				img			:	'https://img.icons8.com/plasticine/2x/module.png'

			},
			{
				title		:	'_MULTIMEDIA_INFO',
				value		:	'mulimedia',
				subtitle	:	'_SUBTITLE_MULTIMEDIA_INFO',
				img			:	'https://pngimage.net/wp-content/uploads/2018/06/img-icon-png-3.png'
			}
		]
		
		this.pageInfo.modulesConfig		=	this.commons.getEntity("config_modules");
		
		if(null!=this.pageInfo.modulesConfig)	{	this.pageInfo.maxDaysFreeTrial	=	this.pageInfo.modulesConfig["trial_period"];			
												} 
		else 									{	this.pageInfo.maxDaysFreeTrial	=	14;			
												}

		let test = {
			cols		: 12,
			icon		: "fa fa-user",
			// title		: "_PROPERTY_PRICES_SELL",				
			// padding		: "1rem 1rem 1rem 1rem",
			items		: 	[
				{
					cols		: 4,
					offsetCols	: 0,
					showTitle	: false,
					title		: "_PROPERTY_PRICES_SELL",
					icon		: "fa fa-list",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [	
						{	field			: 'price_sell_takeover',					
							label			: '_PRICE_SELL_TAKEOVER',
						}
					]
				}
			]
		};

		this.pageInfo.form 				= { 
			ready			: 	true,
			entities		: 	this.pageInfo.entities,
			displayButtons	:	false,
			buttons			: 	{
					items:	[
						{ name: 'save', label: '_SAVE_DMC',	action: 'save',  icon: 'fa fa-fw fa-save'},
					]
			},
			form			: 	[
				{
					cols		: 12,
					icon		: "fa fa-user",
					// title		: "_PROPERTY_PRICES_SELL",				
					// padding		: "1rem 1rem 1rem 1rem",
					items		: 	[
						{
							cols		: 6,
							offsetCols	: 0,
							showTitle	: true,
							title		: "_COMPANY_INFO",
							icon		: "fa fa-check",
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ clean: true, flex: 10, mandatory: true, 		field	: 'company_name', 			label:'_DMC_NAME',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10, mandatory: true, 		field	: 'company_type', 			label:'_DMC_TYPE',					type: 'text',	editor: 'multiselect',	entityList	: 'companyTypeList'	},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'address',		 		label:'_DMC_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'zipcode', 				label:'_DMC_ZIPCODE',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'municipality', 			label:'_DMC_MUNICIPALITY',			type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'region', 				label:'_DMC_REGION',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'country',				label:'_DMC_COUNTRY',				type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	},						
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'activity_name', 			label:'_DMC_ACTIVITY_NAME',			type: 'text',	editor: 'multiselect',	entityList	: 'activityList'	},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_section', 		label:'_DMC_ACTIVITY_SECTION',		type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_epigraph', 		label:'_DMC_ACTIVITY_PARAGRAPH',	type: 'text',	editor: 'text',			editable	: true				}
							]
						},
						,{
							cols		: 6,
							offsetCols	: 0,
							showTitle	: true,
							title		: "_DMC_CONTACT_AND_ACCOUNT",
							icon		: "fa fa-fw fa-street-view",
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ clean: true, flex: 10,  mandatoty: true,	 	field	: 'phone', 					label:'_DMC_PHONE',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'email', 					label:'_DMC_EMAIL',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'web', 					label:'_DMC_URL',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'contact_name', 			label:'_DMC_CONTACT',				type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'contact_phone', 			label:'_DMC_PHONE',					type: 'text',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: false, 	field	: 'contact_lang',			label:'_DMC_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'account_email', 			label:'_DMC_USER_EMAIL',			type: 'email',	editor: 'text',			editable	: true				},
								{ clean: true, flex: 10,  mandatoty: true, 		field	: 'account_tmp_password',	label:'_DMC_ONE_USE_PASSWORD',		type: 'text',	editor: 'text',			editable	: true				}
							]
						}
					]
				}				
			]
		}

		this.pageInfo.modulesForm 		= { 
			ready			: true,
			entities		: this.pageInfo.entities,
			displayButtons	: false,
			buttons			: 	{
				items:	[
					{ name: 'save', label: '_SAVE_DMC',	action: 'save',  icon: 'fa fa-fw fa-save'},
				]
			},
			form			: {
				cols		: 12,
				icon		: "fa fa-user",
				// title		: "_PROPERTY_PRICES_SELL",				
				// padding		: "1rem 1rem 1rem 1rem",
				items		: 	[
					{
						cols		: 6,
						offsetCols	: 0,
						showTitle	: true,
						title		: this.commons.getTranslate("_FREE_TRIAL") + " " + this.pageInfo.maxDaysFreeTrial + " " + this.commons.getTranslate('_DAYS'),
						value		: "free_trial",
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",
						items		: [
							{ clean: true, flex: 10, mandatory: false, 	field	: 'free_trial_status',		label	: '_FREE_TRIAL',		type: 'toggle',		editor: 'toggle',	editable	: true										},
							{ clean: true, flex: 10, mandatory: false, 	field	: 'free_trial_days',		label	: '_DAYS_FREE_TRIAL',	type: 'number',		editor: 'text',		placeholder	: '', disabled	:	true,	editable	: true, validators	:	[{type : 'minmax', min : 0, max : 14}] 			},
							{ clean: true, flex: 10, mandatory: true,	field	: 'start_free_trial',		label	: '_HIRE_INIT',			editable	: true, 	editor: 'datetime',	disabled	:	true, options : {	minDate : {date : new Date()}	}	},
						]
					},				
					{
						cols		: 6,
						showTitle	: true,
						title		: "_PERIOD_HIRE",
						value		: "period_hire",
						icon		: "fa fa-check",
						padding		: "1rem 1rem 1rem 1rem",						
						items		: [
							{ clean: true, flex: 10, mandatory	: true,		field	: 'hire_init',			label	: '_HIRE_INIT',			editable	: true, 	editor: 'datetime',	options : {yearNavigator :true }	},
							{ clean: true, flex: 10, mandatory	: true,		field	: 'hire_end',			label	: '_HIRE_END',			editable	: true, 	editor: 'datetime', options : {	yearNavigator :true	, minDate : { field : 'hire_init'}} 		}	,						
							{ clean: true, flex: 10, mandatory	: false, 	field	: 'discount_module',	label	:'_DISCOUNT',			type: 'toggle',		editor: 'toggle',	editable	: true,										},
							{ clean: true, flex: 10, mandatory	: false, 	field	: 'discount_number',	label	:'_AMOUNT_DISCOUNT',	type: 'number',		editor: 'text',		disabled	:	true,	editable	: true, validators	:	[{type : 'minmax', min : 0, max : 100}]			},
						]
					}
				]
			}
		}
			
		this.pageInfo.multimediaForm	=	{
			ready			:	true,
			entities		:	[],
			displayButtons	: 	false,
			buttons			: 	{
				items:	[
					{ name: 'save', label: '_SAVE_DMC',	action: 'save',  icon: 'fa fa-fw fa-save'},
				]
			},
			form			:	[				
				{
					cols		: 12,
					icon		: "fa fa-user",
					items		: 	[
						{
							cols		: 12,
							offsetCols	: 0,
							showTitle	: true,
							title		: "_LOGO_COMPANY",
							icon		: "fa fa-check",
							padding		: "1rem 1rem 1rem 1rem",
							items		: [
								{ 	clean		: true, 
									flex		: 10, 
									mandatory 	: false,	
									field 		: 'img', 
									label 		: '_IMAGE',	
									type 		: 'img',	
									editor 		: 'img'
								}
							]
						}
					]
				}
			]
		}

		if(this.item){	console.log('REF WIZARD', this.item.ref);
						this.pageInfo.refItem 		=	this.item.ref;	// save the ref firebase separatelly to be able to clone the object
						delete this.item.ref;
						this.pageInfo.originalItem	=	JSON.parse(JSON.stringify(this.superFormCtrl.prepareToBackend(this.pageInfo.form))); // to check if some modify has been aplied when save 
						this.pageInfo.initialItem	=	JSON.parse(JSON.stringify(this.pageInfo.originalItem)); // to pass as parameter to form
		
		} else 		{	// Fake item
						this.item = {
							company_name 			: "tmt",
							company_type			: [ "dmc", "transporter" ],
							address					: "mi calle",
							zipcode					: "07001",
							municipality			: "Palma",
							region					: "Illes Balears",
							country					: "spain",
							activity_name			: [ "dmc", "transporter" ],						
							activity_section		: "receptivos",
							activity_epigraph		: "epígrafe 1",
							phone					: "612345678",
							email					: "miemail@foo.com",
							web						: "miweb",
							contact_name			: "Guillermo Almagro",
							contact_phone			: "612345678",
							contact_lang			: "es",
							account_email			: "tmt@gmail.com",
							account_tmp_password	: "test"
						};
		}

		this.pageInfo.form.info				= this.item;
		this.pageInfo.form.item				= this.item;
		this.pageInfo.multimediaForm.info	= this.item['multimedia'];
		
		// Assign modules company data 
		this.pageInfo.modulesCompany		=	this.item	? this.item['modules'] 		? this.item['modules'] 		: [] : [];
		this.pageInfo.modulesCompany 		== 	null 		? this.restartModules() 	: null;
	}

    async doAction($type,$info)	{	
		switch($type){			
			case 'info'		:	switch($info.action){
									case 'save'     :   this.pageInfo.currentViewIndex = 1;	break;
									case 'restart'  :   this.restartForm(); 				break;
									case 'cancel'	:	break;
								}
								break;
			case 'modules'	:	switch($info.action){
									case 'next'		:	this.pageInfo.currentViewIndex = 2;	break;
								}
								break;
			
			case 'copmany'	:	switch($info.action){
									case 'save'		:	this.saveCompany();					break;														
								}
								break;
		}
	}

	async saveCompany()		{	return this.mode == 'create'
									?	await this.createCompany(this.pageInfo.form.info || this.pageInfo.form.item) 
									:	await this.editCompany();
							}
	
	async cancelProcess()	{	return this.emitter.emit({success : true, type : 'cancel', data : null});				}
	
	clickSection(index)		{	this.pageInfo.currentViewIndex	=	index;												}

	checkFields()			{	let success	=	true;
								let checkFields = this.superFormCtrl.validate(this.pageInfo.form.form, this.pageInfo.form);
								if(!checkFields){ this.commons.generateToast('_ERROR','_CHECK_FIELDS_DMC_INFO','error'); return false;}
								return success;
							}

    async createCompany(info){
								console.log(info);
								console.log('Modules form',this.companyModules);
								if(!info){ return this.commons.generateToast('_ERROR','_FILL_DATA','error')}
								
								let checkFields = this.superFormCtrl.validate(this.pageInfo.form.form, this.pageInfo.form);
								if(!checkFields){ return this.commons.generateToast('_ERROR','_CHECK_FIELDS_DMC_INFO','error')}

								const company           =   JSON.parse(JSON.stringify(this.superFormCtrl.prepareToBackend(this.pageInfo.form)));
								console.log('simplified company', company);
								if(company.id){ delete company.id }
								if(!this.checkAndAssignModules(company)){ return this.pageInfo.currentViewIndex = 1; };
								
								company.company_type    =   company.company_type.map(el => { return el.value});
								company.activity_name   =   company.activity_name.map(el => { return el.value});
								
								this.assignMultimedia(company);
								
								let userAlreadyExist	=	await this.authCtrl.checkIfUserExists(company.account_email);
								if(userAlreadyExist){ return this.commons.generateToast('_ERROR','_EMAIL_ALREADY_EXIST','error');}
								
								if(this.fromWizard){
									console.log(company);
									this.emitter.emit({success : true, type : 'create', data : company});
								}else{
									await this.companyService.createCompany(company);
									this.commons.generateToast('_SUCCESS', '_COMPANY_CREATED', 'success');
								}   
							}


	editCompany()			{	let checkFields = this.superFormCtrl.validate(this.pageInfo.form.form, this.pageInfo.form);
								if(!checkFields){ return this.commons.generateToast('_ERROR','_CHECK_FIELDS_DMC_INFO','error')}
								
								let simplified : any = this.superFormCtrl.prepareToBackend(this.pageInfo.form);
								
								if(!this.checkAndAssignModules(simplified)){ return };
								this.assignMultimedia(simplified);
								
								if(this.fromWizard){
									simplified.ref	=	this.pageInfo.refItem;
									this.emitter.emit({success : true, type : 'edit', data : simplified});
								}
							}

	checkAndAssignModules(company){
								if(this.companyModules){
															let modulesFromCompany	=	this.companyModules.pageInfo.modulesCompany;
															if(!modulesFromCompany || modulesFromCompany.length == 0){ 
																this.commons.generateToast('_ERROR','_NO_MODULES_ASSIGNED','error'); 
																return false;
															};
															company.modules			=	modulesFromCompany;
															company.paymentModules	=	this.companyModules.getSumAllModules();	
															return true;
														}
								else					{
															company.modules			=	this.pageInfo.modulesCompany;
															company.paymentModules	=	this.pageInfo.paymentModules || null;		
															if(!company.modules || company.modules.length == 0){ 
																// this.commons.generateToast('_ERROR','_NO_MODULES_ASSIGNED','error'); 
																return false;
															}
															return true;
														}
							}

	getTotalPriceModules()	{	this.pageInfo.paymentModules	=	this.companyModules ? this.companyModules.getSumAllModules() : (this.item || {}).paymentModules;
								return true;
							}
							
	assignMultimedia(company){
								company['multimedia']	=	company['multimedia'] || {};
								this.pageInfo.multimediaForm.form.forEach(element =>{
									element.items.forEach(item =>{
										company['multimedia'][item.field]	=	this.pageInfo.multimediaForm.item ? this.pageInfo.multimediaForm.item[item.field] || null
																												: null;
									});
								});					
								console.log('On company multimedia',company);
							}
	
	/**
	 * Restart modules to get a initial modules form. This just happens when creating new company and no data found.
	 */
	restartModules()		{	if(!this.modules){ console.log('_NO_MODULES_LOADED'); return; }
								this.modules.forEach(module =>{
									module.selected = false;
									module.sections.forEach(section =>{
										section.selected = false;
									})
								})
							}

	checkIfSaveInfo()		{
								if( this.mode == 'edit'	){	return true; }
								if(!this.companyModules ){ 	return false; }
								let modulesFromCompany	=	this.companyModules.pageInfo.modulesCompany;
								return this.pageInfo.form.info && (modulesFromCompany && modulesFromCompany.length > 0);								
							}

	restartForm()			{	console.log('confirm?');	}
	
	checkIfInfoModified()	{	let infoForm = this.pageInfo.form.item ? this.pageInfo.form.item : this.pageInfo.form.info;
								console.log(this.pageInfo.originalItem, infoForm);
								// delete infoForm.ref;
								// delete this.pageInfo.originalItem.ref;
								let updatedItem 	=	(this.superFormCtrl.prepareToBackend(this.pageInfo.form));
								let originalInfo	=	this.pageInfo.originalItem;
								let areEqual 		= [];

								Object.keys(updatedItem).forEach(key =>{
									let updatedItemStr	=	JSON.stringify(updatedItem[key]);
									let originalInfoStr =	JSON.stringify(originalInfo[key]);
									if(updatedItemStr != originalInfoStr){
										areEqual.push(updatedItem[key]);
									}
								});
								
								// return JSON.stringify(infoModified) == JSON.stringify(updatedItem);
								// return !infoModified;
								console.log('THESE FIELDS ARE NOT EQUAL:',areEqual);
								return areEqual.length > 0;								
							}
}