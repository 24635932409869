import { ConfirmationService 	} from 'primeng/primeng';
import { StorageService 		} from './../../../../demo/service/storageservice';
import { FirebaseService 		} from './../../../../demo/service/database/firebase.service';
import { EntityService 			} from './../../../../demo/service/entity.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { variantsData1 			} from './data/variants';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';

@Component({
  selector    	: 'app-tour-variant',
  styleUrls		: ['./component.scss'],
  encapsulation	: ViewEncapsulation.None,
  templateUrl 	: 'component.html'  
})
export class TourVariantComponent {

	@Input("tour")   	currentTour		:any	= {};
	@Input("current")   currentVariant	:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  	: any = {};
	userInfo	: any = {};
	  
	constructor( 
		private commons				: CommonsService,
		private entityService		: EntityService,	
		private firebaseCtrl		: FirebaseService,
		private storageCtrl			: StorageService,
		private confirmCtrl     	: ConfirmationService
  	){}
	async ngOnInit()	{
		this.init();
		await this.loadEntities();
		this.selectVariant(this.currentVariant);
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };		
	}
	async init()				{
		this.userInfo	=	(this.storageCtrl.getItem('dmcSuite') || {}).userInfo || {}; 
        console.log('MY USER INFO', this.userInfo);
		
		this.pageInfo.today 		= new Date();
		// this.pageInfo.variants		= variantsData1;
		this.pageInfo.variants		= [];
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.entities		= { "variants" : [] };
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
	}
	async loadEntities(){ await this.load("variants"); 					}
	
	/**
	 * File upload handler
	 */
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}

	/**
	 * Main action controller
	 * @param $type
	 * @param $item 
	 */
	action($type,$item){
		switch($type){
			case "button"			:	switch($item.name){
											case "new_variant"	: 	this.createVariant(); 											break;
											case "reload"		: 	this.load("variants"); this.selectVariant(this.currentVariant);	break;
											case "edit"			:	this.emitter.emit({ action: 'view_variant',   	tour: $item });	break;			
											case "schedule"		:	this.emitter.emit({ action: 'variant_scheduler',tour: $item });	break;			
											case "offers"		:	this.emitter.emit({ action: 'variant_offers', 	tour: $item });	break;			
											case "pricing"		:	this.emitter.emit({ action: 'variant_prices', 	tour: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");	break;
											case "import"		: 	this.commons.generateToast("Button pressed","Import","info");	break;
										}
										break;
			case "toggle_variant"	:	this.selectVariant($item); 						break;
			case "view_variant"		: 	this.emitter.emit({ action: 'view_variant' }); 	break;									
		}
	}

	/**
	 * select especific variant to emit to parent
	 * @param $item 
	 */
	selectVariant($item?){
		if(undefined==$item){ 
			this.pageInfo.itemSelected = false; 
			return false; 
		}
		// Mark selected only to current one variant and unmark the rest
		this.pageInfo.entities["variants"].forEach(item=>{ item.selected = (item.id == $item.id)?(item.selected?false:true):false;});

		if(this.pageInfo.entities["variants"].some(item=>item.selected)){
			// Some variant is selected
			this.pageInfo.itemSelected 	= this.pageInfo.entities["variants"].find(item=>item.selected);
			this.currentVariant			= this.pageInfo.itemSelected;
			this.getCurrentVariantFirebaseInfo();
			this.emitter.emit({'action':'variant_selected','item': this.currentVariant });
		} else {
			// None selected
			this.emitter.emit({'action':'variant_unselected'});
		}
	}
	
	/**
	 * check if variant has specific turn
	 * @param $item 
	 * @param $turn 
	 */
	calendarHasTurn($item,$turn)	{ return $item.some(item=>$item==$turn);	}

	/**
	 * create empty variant
	 */
	createVariant()					{
		this.pageInfo.entities["variants"].push(
			this.generateItemCalendar({		
				new			: true,
				active		: true,																	
				title		: this.commons.getTranslate("_NEW_VARIANT"),
				tour		: (this.currentTour||{}).name,
				tourId		: this.currentTour.id,
				langs		: [],
				pictures	: 0,
				videos		: 0,
				audios		: 0,
				thumbnail	: "/assets/layout/images/sights/no_sight.jpg",
				value		: 0,
				visits		: 0,
				comments	: 0,
				books		: 0,
				prize		: false,
				offers		: [],
				promotions	: [],
				legend		: ""
			})
		);
	}

	/**
	 * generate fake calendar for variant
	 * @param item
	 */
	generateItemCalendar(item)		{
		item.weekCalendar = [
			{ day: 'l', turns: this.generateDayTurns() },
			{ day: 'm', turns: this.generateDayTurns() },
			{ day: 'x', turns: this.generateDayTurns() },
			{ day: 'j', turns: this.generateDayTurns() },
			{ day: 'v', turns: this.generateDayTurns() },
			{ day: 's', turns: this.generateDayTurns() },
			{ day: 'd', turns: this.generateDayTurns() }
		];
		return item;
	}

	/**
	 * generate fake random turns
	 */
	generateDayTurns(){
		const 	value 		= Math.random();
		let		response	= [];
		if(value>0.25 && value<=0.75	){ response.push("m"); }
		if(value>0.50					){ response.push("t"); }
		return response;
	}

	getFilteredEntity($entity){
		let items;
		switch($entity){
			case "variants"	: 	items =  (this.pageInfo.entities[$entity]||[]).filter(item=>{
									return true;
									// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
									// item.view 		= isSelected;
									// item.color		= "red";
									// return isSelected;
								});
		}
		return items;
	}

	/**
	 * Load entities
	 */
	async load($entity){
		switch($entity){
			case 'variants' :
				let response 					= 	await Promise.resolve(this.entityService.getRequest($entity,{tour:this.currentTour.id}));
				this.pageInfo.entities[$entity]	= 	response["data"].map((item,index)=>({ 																		
														id			: item["id"			],
														type		: item["type"		],
														active		: item["action"		],																		
														name		: item["name"		],																		
														title		: item["name"		].charAt(0).toUpperCase()+item.name.slice(1),																		
														tour		: item["tour"		],
														tourId		: item["tourId"		],
														langs		: item["langs"		],
														pictures	: item["pictures"	] 	|| 0,
														videos		: item["videos"		]	|| 0,
														audios		: item["audios"		]	|| 0,
														thumbnail	: item["thumbnail"	]	|| "/assets/layout/images/sights/no_sight.jpg",													
														value		: item["value"		]	|| 0,
														visits		: item["visits"		] 	|| 0,
														comments	: item["comments"	]	|| 0,
														books		: item["books"		]	|| 0,
														prize		: item["prize"		]	|| 0,
														offers		: item["offers"		]	|| [],
														promotions	: item["promotions"	]	|| [],
														legend		: item["legend"		]	|| "_NO_LEGEND",																		
													}))
													.map(item => this.generateItemCalendar(item));	
				break;
		}
	}

	// INTEGRATION WITH VARIANT OFFERS AND CALENDAR

	/**
	 * Mix database current variant with info from firebase ( schedule, offers and pricing )
	 * @param variant
	 */
	async getCurrentVariantFirebaseInfo()
	{
		// Initialize calendar variant
		this.initCalendarVariant(false);

		if(undefined!==this.currentVariant.id){
			// Get info 
			await this.firebaseCtrl
				.getVariantInfo(this.userInfo.idDmc,this.userInfo.currentDestination.id, this.currentTour.id, this.currentVariant.id)
				// .getVariantInfo(this.userInfo.idDmc,this.userInfo.currentDestination.id, "0", "0")
				.subscribe( variantInfo =>{
					if(variantInfo && variantInfo["calendar"]){
						this.currentVariant.calendar.schedule			= variantInfo["calendar"]["schedule"];
						this.currentVariant.calendar.offers				= variantInfo["calendar"]["offers"];
						this.currentVariant.calendar.compositor			= variantInfo["calendar"]["compositor"] || this.mountCalendar();
					} else { 
						this.initCalendarVariant(true); 
					}
			});
			console.log('VARIANT INFO',this.currentVariant);
		}
	}

	initCalendarVariant(force:boolean=true){
		const defaultCalendar			= {
											schedule	:	{ periods: [], days: []	},
											offers		:	{ periods: [], days: []	},
											compositor	:   []
										};
		this.currentVariant.calendar  = force ? defaultCalendar : ( this.currentVariant.calendar || defaultCalendar );
    }
	
	/**
	 * mount Calendar ???
	 */
    mountCalendar(){
        if(undefined==this.currentVariant.calendar){ return false; }
		
		const listDays  =   Array.from(new Set(this.currentVariant.calendar.offers.days.map(el => el.day).concat(this.currentVariant.calendar.schedule.days.map(el => el.day))));
		
		return listDays.map(day => {
            let foundComputed;
            if(Object.keys(this.currentVariant.calendar.compositor).length ==0)	{	foundComputed = null;
            } else	{
                foundComputed	= this.currentVariant.calendar.compositor.find(el=>el.day==day);
               	foundComputed	= foundComputed?foundComputed.computed:null;
            }
            console.log('computed', foundComputed);
            return {    
				day         : day, 
				computed    : foundComputed || null,
				offers      : this.currentVariant.calendar.offers.days.find	 (el=>el.day==day)	|| null,
				schedule    : this.currentVariant.calendar.schedule.days.find(el=>el.day==day) 	|| null
			}
		});
	}
	
	/**
	 * Restart dialog
	 */
    restartDialogConfig(){
        this.storageCtrl.deleteItem('answeredDialogPeriod');
        this.storageCtrl.deleteItem('answeredDialogCalendar');
        this.commons.generateToast('_INFO','_PERIOD_CONFIG_RESTARTED','info');
    }
}
