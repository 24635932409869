export const externalForm = [{
    cols		: 12,
    icon		: "fa fa-user",
    // title		: "_PROPERTY_PRICES_SELL",				
    // padding		: "1rem 1rem 1rem 1rem",
    items		: 	[
        {
            cols		: 6,
            offsetCols	: 0,
            showTitle	: true,
            title		: "_MAIN",
            icon		: "fa fa-check",
            padding		: "1rem 1rem 1rem 1rem",
            items		: [
                { clean: true, flex: 10,  mandatory: true, 	    field	: 'active',				    label:'_ACTIVE',				                        editor: 'toggle',		editable	: true				},
				{ clean: true, flex: 10,  mandatory: true, 	    field	: 'has_api',				label:'_HAS_API',				                        editor: 'toggle',		editable	: true				},
				{ clean: true, flex: 10,  mandatory: true, 		field	: 'api_code', 				label:'_API_CODE',					type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: true, 	    field	: 'global',				    label:'_GLOBAL',				                        editor: 'toggle',		editable	: true				},
				{ clean: true, flex: 10,  mandatory: false, 	field	: 'apikey', 				label:'_APIKEY',					type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: false, 	field	: 'username', 				label:'_USERNAME',					type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: false, 	field	: 'password', 				label:'_PASSWORD',					type: 'password',	editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: true, 		field	: 'name', 					label:'_DMC_NAME',					type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: true, 		field	: 'type', 					label:'_DMC_TYPE',					type: 'text',	    editor: 'multiselect',	entityList	: 'companyTypeList'	},
                { clean: true, flex: 10,  mandatoty: true, 		field	: 'address',		 		label:'_DMC_ADDRESS',				type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatoty: true, 		field	: 'zipcode', 				label:'_DMC_ZIPCODE',				type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatoty: true, 		field	: 'municipality', 			label:'_DMC_MUNICIPALITY',			type: 'text',	    editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatoty: true, 		field	: 'region', 				label:'_DMC_REGION',				type: 'text',	    editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatoty: true, 		field	: 'country',				label:'_DMC_COUNTRY',				type: 'text', 	    editor: 'autocomplete', entityList	: 'countryList' 	},						
                // { clean: true, flex: 10,  mandatoty: true, 		field	: 'activity_name', 			label:'_DMC_ACTIVITY_NAME',			type: 'text',	    editor: 'multiselect',	entityList	: 'activityList'	},
                // { clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_section', 		label:'_DMC_ACTIVITY_SECTION',		type: 'text',	    editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatoty: false, 	field	: 'activity_epigraph', 		label:'_DMC_ACTIVITY_PARAGRAPH',	type: 'text',	    editor: 'text',			editable	: true				}
            ]
        },
        {
            title		: "_COMPANY_CONTACT",
            cols		: 4,
            showTitle	: true,
            icon		: "fa fa-phone",
            _padding		: "1rem 1rem 1rem 1rem",
            items		: [
                { clean: true, flex: 10,  mandatoty: true, 		field	: 'region', 				label:'_DMC_REGION',				type: 'text',	    editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatoty: true, 		field	: 'country',				label:'_DMC_COUNTRY',				type: 'text', 	    editor: 'autocomplete', entityList	: 'countryList' 	},						
                { clean: true, flex: 10,  mandatory: false, 	field	: 'phone', 					label:'_TRANSPORTER_PHONE',		type: 'text',	editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatory: false, 	field	: 'fax', 					label:'_FAX',					type: 'text',	editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: false, 	field	: 'web', 					label:'_ADDRESS',				type: 'text',	editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatory: false, 	field	: 'contact_name', 			label:'_CONTACT_NAME',			type: 'text',	editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatory: true, 	field	: 'contact_role', 				label:'_ROLE',					type: 'text',	editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: false, 	field	: 'contact_phone', 		label:'_CONTACT_PHONE',			type: 'text',	editor: 'text',			editable	: true				},
                { clean: true, flex: 10,  mandatory: false, 	field	: 'contact_email',		label:'_CONTACT_EMAIL',			type: 'text',	editor: 'text',			editable	: true				},
                // { clean: true, flex: 10,  mandatory: false, 	field	: 'contact_lang',		label:'_LANG',					type: 'text',	editor: 'autocomplete', entityList	: 'langList' 		}
            ]
        }
        ,{
            title		: "_LOGO_TRANSPORTER",
            cols		: 4,
            showTitle	: true,
            icon		: "fa fa-check",
            padding		: "1rem 1rem 1rem 1rem",
            items		: [
                { clean: true, flex: 10,  mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
            ]
        }
    ]
}];