import { ConfirmationService 					} from 'primeng/primeng';
import { TranslateService 						} from '@ngx-translate/core';
import { CommonsService 						} from '../../demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
		 ViewChild,
         ViewChildren							} from '@angular/core';
import * as moment 						          from 'moment';
import { extendMoment 							} from 'moment-range';
import { isArray 								} from 'util';
import { CalendarMonthViewDay 					} from "angular-calendar";
import { StorageService 						} from '../../demo/service/storageservice';

@Component({
  selector    : 'app-tours-offers',
  templateUrl : './tours-offers.component.html',
  styleUrls   : ['./tours-offers.component.scss'] ,

})

export class ToursOffersComponent implements OnInit {

    @ViewChild("dayCalendar") dayCalendar:any;
    @ViewChild("dayExec")     dayExec:any;

	locale	: string = 'es';
    display	: boolean = false;
	pageInfo: any = {
		showPriceSidebar 				:	false,
        test							:	null,
        displayDialog   				:   false,
        contextExecDisplayDialog		:	false,
		contextDayDisplayDialog			:	false,
        dirtyDays       				:   [],
		modeViewDays    				: 	'template',
        viewDate						:	new Date(),
        contextMenu     				:   {dayCopied	:	{}},
        execsSelecteds  				:   {day    :   null,   execs   :   []},
		contextMenuExecs				:   {day    :   null,   execs   :   []},
        contextMenuDirtyDays			:	[],
        displayContextMenuOptions		:	null,
		displayCheckBoxDialogPeriod		:	true,
		displayCheckBoxDialogCalendar	:	true,
        colorPeriods    				:   ['#ffe595','#e8a5a5','#e6d398','tomato','#79b5e4'],
        templateColors					:	{
			isPast			:	'#e8e8e8',
			isToday 		:	'#b4eab4',
			isSelected		:	'tomato',
			isCopied		:	'purple',
			isDaySelected	:	'#ff9b7b',
			isWeekend		:	'#d1ecf5',
			isCustom		:	'#009688',
			isRangeMode		:	'#8a66ca',
			hasPeriod		:	'#346399',
		},
		useMode					:	[
			"_TOUR_PRICE_USE_MODE_LIST_1",
			"_TOUR_PRICE_USE_MODE_LIST_2",
			"_TOUR_PRICE_USE_MODE_LIST_3",
			"_TOUR_PRICE_USE_MODE_LIST_4",
			"_TOUR_PRICE_USE_MODE_LIST_5"
		]
	}
    
	@Input() 						offers        		: any=null;
	@Output() 						emiterOption		: EventEmitter<any> = new EventEmitter();
    @ViewChild ('monthViewCalendar')monthViewCalendar   : any;

	constructor(
		private commons 			: CommonsService, 
		private confirmationService : ConfirmationService,
		private storageCtrl         : StorageService)
	{}

    async ngOnInit(){ await this.init(); }
	init()			{
        let answeredDialogPeriod	                =	this.storageCtrl.getItem('answeredDialogPeriod');
        let answeredDialogCalendar	                =	this.storageCtrl.getItem('answeredDialogCalendar');
        this.pageInfo.displayCheckBoxDialogPeriod	=	(answeredDialogPeriod == null || answeredDialogPeriod == false) ? true : false;
        this.pageInfo.displayCheckBoxDialogCalendar	=	(answeredDialogCalendar == null || answeredDialogCalendar == false) ? true : false;
        this.pageInfo.modeDisplayCalendar 			= [
            { label : this.commons.getTranslate('_TEMPLATE'), value : 'template'},
            { label : this.commons.getTranslate('_CALENDAR'), value : 'calendar'}
		];
		this.pageInfo.monthStepper  				=   [
            { label : this.commons.getTranslate('_MONTH'), value : 'previous', 	iconPos:'left', 	icon:'fa fa-arrow-left'},
            { label : this.commons.getTranslate('_MONTH'), value : 'next', 		iconPos:'left',		icon:'fa fa-arrow-right'}
        ];
        this.pageInfo.contextMenuOptions 			= [
            {
                label: this.commons.getTranslate('_COPY'),
                icon: 'pi pi-fw pi-chevron-right',
                id  :   'copy',
                command		: 	($event) => { 
                    this.pageInfo.contextMenu.dayCopied =   this.pageInfo.contextMenu.rightClick;
                    this.restartExecsSelecteds();
                    this.pageInfo.execsSelecteds.day    =   this.pageInfo.contextMenu.dayCopied.day;
                    this.pageInfo.execsSelecteds.execs  =   this.pageInfo.contextMenu.dayCopied.offers;
                }
            },
            {
				label		:	this.commons.getTranslate('_DELETE_MULTIPLE'),
				id			:	'delete_multiple',
				icon		:	'pi pi-fw pi-chevron-right',
				command		:	() => {
					const getDaysRangeMode 	=   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
						if(getDaysRangeMode.length > 1){
							getDaysRangeMode.forEach(element => {
								let foundDay	=	this.offers.calendar.offers.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
								if(foundDay > -1){
									this.offers.calendar.offers.days.splice(foundDay,1);
								}
							});
							this.commons.generateToast('_SUCCESS','_DELETED_DAYS','success');
						}
						else{
							this.commons.generateToast('_ERROR','_NO_MULTIPLE_DAYS_SELECTEDS','error');
						}
						this.markRankDaysOffers(false);
						this.updateViewCalendar();

				}
			},
			{
				label		:	this.commons.getTranslate('_DELETE'),
				id			:	'delete',
				icon		:	'pi pi-fw pi-chevron-right',
				command		:	() => {
					console.log(this.pageInfo.contextMenuExecs);
					if(this.pageInfo.contextMenu.rightClick){
						let foundDay	=	this.offers.calendar.offers.days.find(el => el.day == this.pageInfo.contextMenu.rightClick.day);
						if(foundDay){
							this.confirmationService.confirm({
								message : 	foundDay.offers.some(el => el.custom) ? this.commons.getTranslate('_CONFIRMATION_DELETE_CUSTOMIZED_EXEC') : this.commons.getTranslate('_CONFIRMATION_DELETE_DAY'),
								header	:	this.commons.getTranslate('_DELETE_OFFER'),
								icon	:	'pi pi-exclamation-triangle',
								accept	:	()=>{
									foundDay.offers = [];
									this.commons.generateToast('_SUCCESS','_DAY_DELETED','success');
									this.markRankDaysOffers(false);
									this.updateViewCalendar();
								}
							})
						}
					}
				}
            },
            {
				label		: this.commons.getTranslate('_PASTE_OVERWRITING'),
				icon		: 'pi pi-fw pi-chevron-right',
				id			: 'paste_overwrite',
				command		:  ($event) =>{
					this.pageInfo.pasteMode	=	'overwrite';
					this.computePasteDayLogic();
				}
			},
            {
                label		: this.commons.getTranslate('_PASTE_MERGING'),
				icon		:	'pi pi-fw pi-chevron-right',
				id			: 'paste_merge',
                command		: ($event) => { 
					this.pageInfo.pasteMode = 'merge';
					this.computePasteDayLogic();
					
                    // this.restartExecsSelecteds();
				}
            },
        ];
        this.pageInfo.contextMenuOptionsExec    =   [
            {
                label   :   this.commons.getTranslate('_COPY'),
                id      :   'copy',
                icon    :   'pi pi-fw pi-chevron-right',
                command :   ($event) => {
                    this.pageInfo.contextMenuExecs  =   JSON.parse(JSON.stringify(this.pageInfo.execsSelecteds));
                    this.commons.generateToast('_INFO','_EXECS_COPIED','info');
                }
            },{
                label   :   this.commons.getTranslate('_DELETE'),
                id      :   'delete',
                icon    :   'pi pi-fw pi-chevron-right',
                command :   ($event) => {
					console.log('going to delete', this.pageInfo.execsSelecteds);
					console.log(this.offers.calendar.offers);
					this.confirmationService.confirm({
									message : 	'_CONFIRMATION_DELETE_CUSTOMIZED_EXEC',
									header	:	'_DELETE_OFFER',
									icon	:	'pi pi-exclamation-triangle',
									accept	:	()=>{
										// First find the day from the list and remove the selected execs
										let foundDay		=	this.offers.calendar.offers.days.find(el => el.day == this.pageInfo.execsSelecteds.day);
										if(!foundDay)	return this.commons.generateToast('_ERROR','_EXEC_NOT_FOUND','error');
										
										foundDay.offers	=	foundDay.offers.filter(exec => !this.pageInfo.execsSelecteds.execs.some(el => exec.id == el.id));
										this.commons.generateToast('_SUCCESS','_EXECS_DELETED','success');
										this.updateViewCalendar();
									}
					})
				}
            }
        ]
	}

	getPeriodSelectedOffers(){
		if (!this.offers){ return null; }
		return this.offers.calendar.offers.periods.find(el => el.selected) || null;
	}

    onChangeModeView($event){
        if($event.value=='calendar'){
            this.updateViewCalendar();
        }
    }
    updateViewCalendar(){
        console.log('updating calendar');
        setTimeout(()=>{
            this.monthViewCalendar.view.days =  this.monthViewCalendar.view.days.map(el =>{
                const foundDay  =   this.offers.calendar.offers.days.find(item => moment(el.date).format() == moment(item.day).format());
                return {...el, infoDay : foundDay}
            });
        },150);
    }
	async handleOffersPeriodEvent($event){
        console.log($event.type);
		switch($event.type){
            case 'add'      :           this.addNewPeriodOffer($event.data); break;
            case 'update'   :           this.updatePeriodOffer($event.data); break;
            case 'delete'   :   await   this.deletePeriodOffer($event.data); break;
            case 'lastRow'  :           this.lastRowPeriodOffer($event.data); break;
        }
    }
    
	lastRowPeriodOffer(data){
        this.offers.calendar.offers.periods.push({
            start       :   data.start,
            end         :   data.end,
            title       :   data.title,
            template    :   [],
            id          :   this.commons.generateHash()
        });
        console.log(this.offers.calendar.schedule.periods);
    }
    addNewPeriodOffer(data){
        this.offers.calendar.offers.periods.push({start:new Date(), end: new Date(), template : [], id : this.commons.generateHash(Math.random().toString())})
    }
    async updatePeriodOffer(data){
        let findPeriod  =   this.offers.calendar.offers.periods.find(el => el.id == data.id);
		if(this.commons.checkCompatibilityPeriodsSchedule(findPeriod,this.offers.calendar.offers.periods)){
            findPeriod          =   data;
            findPeriod.selected =   true;
            this.updateDaysFromPeriodOffers(findPeriod);
		}else{
			await this.commons.generateToast('_ERROR','_BAD_PERIOD','error');
			findPeriod.editMode = true;
		}
        
    }

    updateDaysFromPeriodOffers(period){
        const daysPeriod    =   this.getDaysFromPeriodOffers(period.id);
        daysPeriod.forEach(item =>{
            const isInPeriod    =  (moment(item.day).isBetween(period.start, period.end));
            if(!isInPeriod){
                const findDay = this.offers.calendar.offers.days.findIndex(el => moment(el.day).format() == moment(item.day).format());
                findDay > -1    ?   this.offers.calendar.offers.days.splice(findDay,1)   :   null;
            }   
        });
        
        period.template.forEach(item =>{
            if(!item.offers) return;
            item.offers.forEach(exec =>{
                this.fillCalendarDaysOffers(item.day,exec);
            })
        })
    }
    getDaysFromPeriodOffers(idPeriod){
        return this.offers.calendar.offers.days.filter(el => (el.period_owner != null) && (el.period_owner.id == idPeriod));
    }
    async deletePeriodOffer(data){
        let findPeriod  =   this.offers.calendar.offers.periods.findIndex(el => el.id == data.id);
        if(findPeriod > -1){
            let filteredDays    =   this.offers.calendar.offers.days.filter(el =>{ 
                if(!el.period_owner) return true;
                return el.period_owner.id != data.id
            });
            console.log(filteredDays);
            this.offers.calendar.offers.days = filteredDays;
            this.offers.calendar.offers.periods.splice(findPeriod,1)
        }else{
            await this.commons.generateToast('_ERROR','_ERROR','error');        
        }
    
	}
	fillSelectedDays(currentPeriod,filteredDays,data){
        console.log('FILLING DAYS!!!');
        console.log(this.pageInfo.fillDaysData);
        filteredDays.forEach(day =>{
			const foundDay	=	this.offers.calendar.offers.days.find(el => el.day &&  moment(day).format() == moment(el.day).format());
			if (foundDay){
                if(data.offers && data.offers.length == 0){
                    if(this.pageInfo.fillDaysData.data && this.pageInfo.fillDaysData.data.offersToDelete){
                        foundDay.offers = foundDay.offers.filter(el => !this.pageInfo.fillDaysData.data.offersToDelete.some(exec => exec.id == el.id));
                    }else{
                        foundDay.offers = [];
                    }
                }else{
                    const foundExec	=	foundDay.offers.findIndex(el => el.id == data.id);
                    if(foundExec > -1 ){
                        foundDay.offers[foundExec] = data;
                    }
                    if(data.offers){
						if (foundDay.offers.length > data.offers.length){
							if(foundDay.offers.some(el => el['custom'])){
								foundDay.offers = data.offers;
								foundDay.offers = foundDay.offers.map(el =>{ return {...el,custom : true}})
							}else{
								foundDay.offers = data.offers;
							}
						}
					}
                    else{
						foundDay.offers = [];
                        foundDay.offers.push(data);
                    }
                }
			}else{
				this.offers.calendar.offers.days.push(
					{	day 			: day.format(), 
						period_owner 	: {id: currentPeriod.id, start : currentPeriod.start, end : currentPeriod.end},
						offers		    : [data]
					});
			}
        });
        this.offers.calendar.offers.days =   this.offers.calendar.offers.days.filter(el => el.offers.length > 0);

    }

    getInfoFillCalendarDays(dayId){
        const currentPeriod = this.getPeriodSelectedOffers();
		if(!currentPeriod) return null;

		const extendedMoment 	= 	extendMoment(moment);
		const rangeDays			=	Array.from(extendedMoment().range(currentPeriod.start, currentPeriod.end).by('days'));
      
        const filteredDays		=	rangeDays.filter(day => day.day() == (dayId == 6 ? 0 : dayId+1));
        filteredDays.forEach(el => console.log(el.date()));
        console.log(dayId);
        this.pageInfo.dirtyDays  =   [];
        
        filteredDays.forEach(day =>{
            let foundDay = this.offers.calendar.offers.days.find(el =>(el.day &&  (moment(day).format() == moment(el.day).format())) );
            if(foundDay && (foundDay.offers.some(el => el.custom) || (foundDay.period_owner == null))){this.pageInfo.dirtyDays.push(foundDay);}
        });
        this.pageInfo.fillDaysData = {
			dayId			:	dayId,
            currentPeriod   :   currentPeriod,
            filteredDays    :   filteredDays,
            dirtyDays       :   this.pageInfo.dirtyDays
        } 
    }

	fillCalendarDaysOffers(dayId, data,action?){
        const currentPeriod = this.getPeriodSelectedOffers();
		if(!currentPeriod) return null;

		const extendedMoment 	= 	extendMoment(moment);
		const rangeDays			=	Array.from(extendedMoment().range(currentPeriod.start, currentPeriod.end).by('days'));
      
        const filteredDays		=	rangeDays.filter(day => day.day() == (dayId == 6 ? 0 : dayId+1));

        this.pageInfo.dirtyDays  =   [];
        this.pageInfo.fillDaysData  =   {
			dayId			:	dayId,
            currentPeriod   :   currentPeriod,
            filteredDays    :   filteredDays,
			data            :   data,
            dirtyDays		:	this.pageInfo.dirtyDays,
            action          :   action
        }
        filteredDays.forEach(day =>{
            let foundDay = this.offers.calendar.offers.days.find(el =>(el.day &&  (moment(day).format() == moment(el.day).format())) );
            if(foundDay && foundDay.offers.some(el => el.custom) || (foundDay.period_owner == null)){this.pageInfo.dirtyDays.push(foundDay);}
		});
	
        if(this.pageInfo.dirtyDays.length > 0 && !this.storageCtrl.getItem('answeredDialogPeriod')){
            this.pageInfo.displayDialog = true;

        }else{
            this.fillSelectedDays(currentPeriod,filteredDays,data);
            this.updateOfferFromTemplate();
        }
		console.log(' FIRST UPDATE DAYS!!', this.offers)
    }

    fillAllDaysPeriod(){
        
		if(!this.pageInfo.fillDaysData.action){
            this.updateOfferFromTemplate();
        }
        this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,this.pageInfo.fillDaysData.data);
		
		this.pageInfo.displayDialog = false;
        this.commons.generateToast('_INFO','','info');
        this.pageInfo.fillDaysData 	= null;
    }
    fillNoCustomizedDays(){

		this.updateOfferFromTemplate();

		let filteredDays	=	this.pageInfo.fillDaysData.filteredDays.filter(day => !this.pageInfo.dirtyDays.some(el => el.day == moment(day).format()))
		this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,filteredDays,this.pageInfo.fillDaysData.data);
		
		this.pageInfo.displayDialog = false;
        this.commons.generateToast('_INFO','_OFFERS_NO_CUSTOMIZED_UPDATED','info');
        this.pageInfo.fillDaysData 	= null;
	}
	updateOfferFromTemplate(){
        this.pageInfo.fillDaysData.data.editMode = false;

        let foundPeriod = 	this.offers.calendar.offers.periods.findIndex(el => el.id == this.pageInfo.fillDaysData.currentPeriod.id);
		let foundDay	=	this.offers.calendar.offers.periods[foundPeriod].template.findIndex(template => template.day == this.pageInfo.fillDaysData.dayId);
        if(!this.pageInfo.fillDaysData.data){
            this.offers.calendar.offers.periods[foundPeriod].template[foundDay].offers = [];
        }else{
            let foundExec   =   this.offers.calendar.offers.periods[foundPeriod].template[foundDay].offers.findIndex(offer => offer.id == this.pageInfo.fillDaysData.data.id);
            this.offers.calendar.offers.periods[foundPeriod].template[foundDay].offers[foundExec] = this.pageInfo.fillDaysData.data;
        }
	}
    cancelDialog(){
        return false;
    }
    

	async handleTableOffersEvent($event){
        switch($event.type){
            case 'add'      :           this.addNewExecOffer($event.data); break;
            case 'update'   :   await   this.updateExecOffer($event.data); break;
            case 'delete'   :   await   this.deleteExecOffer($event.data); break;
            case 'deleteMultiple'   :   this.deleteMultipleExecOffer($event.data); break;
            case 'lastRow'  :           this.addItemLastRow($event.data); break;
            case 'restart'  :           this.restartExecOffer($event.data);break;
            case 'merge'    :           this.mergeExecOffer($event.data);  break;
        }
    }
    mergeExecOffer(data){
        this.getInfoFillCalendarDays(data.daySelected.day);
        this.pageInfo.fillDaysData.data                     =   data.tmp;
        this.pageInfo.fillDaysData.multiple                 =   true;
        this.pageInfo.fillDaysData.daySelected				=	data.daySelected;

        if(this.pageInfo.fillDaysData.dirtyDays.length > 0){
            this.pageInfo.displayDialog =   true;
        }else{
            data.daySelected.offers = data.tmp.offers;
            data.daySelected.offers.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,exec));
        }
    }
    dialogMergePeriods(){
        this.pageInfo.fillDaysData.daySelected.offers    =   this.pageInfo.fillDaysData.data.offers;
        this.pageInfo.fillDaysData.daySelected.offers.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,exec));
        this.pageInfo.displayDialog = false;
    }
    dialogMergePeriodsNonCustomized(){
        let filtered    =   this.pageInfo.fillDaysData.filteredDays.filter(el => !this.pageInfo.fillDaysData.dirtyDays.some(dirty => moment(el).format() == moment(dirty.day).format()));
        
        this.pageInfo.fillDaysData.daySelected.offers    =   this.pageInfo.fillDaysData.data.offers;
        this.pageInfo.fillDaysData.daySelected.offers.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,filtered,exec));
        this.pageInfo.displayDialog = false;
    }
    addNewExecOffer(data){
        data.daySelected.offers = data.daySelected.offers || [];
        data.daySelected.offers.map(el => el.editMode = false);
        data.daySelected.offers.push({
            id          : this.commons.generateHash(),
            editMode    : true,
            startTime   : '00:00',
            endTime     : '23:59',
            type        : {},
            value       : null,
            profiles    : ['adult','children','infant'],
            turns       : []
        });
    }
    deleteMultipleExecOffer(data){
        this.getInfoFillCalendarDays(data.daySelected.day);
        if(this.pageInfo.fillDaysData == null) return;
        if(this.pageInfo.fillDaysData.dirtyDays.length >0 && !this.storageCtrl.getItem('answeredDialogPeriod')){
            this.pageInfo.displayDialog                 =   true;
            this.pageInfo.fillDaysData.deleteMultiple   =   true;
            this.pageInfo.fillDaysData.data             =   data;
        }else{
            data.offersToDelete.forEach(offerToDelete => {
                data.daySelected.offers.splice(data.daySelected.offers.findIndex(el => el.id == offerToDelete.id),1);
                this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, this.pageInfo.fillDaysData.filteredDays, data.daySelected);
            });
        }
    }
    deleteMultipleExecsPeriod(){
        this.pageInfo.fillDaysData.data.offersToDelete.forEach(offersToDelete => {
            this.pageInfo.fillDaysData.data.daySelected.offers.splice(this.pageInfo.fillDaysData.data.daySelected.offers.findIndex(el => el.id == offersToDelete.id),1);
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, this.pageInfo.fillDaysData.filteredDays,this.pageInfo.fillDaysData.data.daySelected);
        });
        this.pageInfo.displayDialog = false;
    }
    deleteMultipleExecsPeriodNonCustomized(){
        let filtered    =   this.pageInfo.fillDaysData.filteredDays.filter(el => !this.pageInfo.fillDaysData.dirtyDays.some(dirty => moment(el).format() == moment(dirty.day).format()));
        this.pageInfo.fillDaysData.data.offersToDelete.forEach(offerToDelete => {
            this.pageInfo.fillDaysData.data.daySelected.offers.splice(this.pageInfo.fillDaysData.data.daySelected.offers.findIndex(el => el.id == offerToDelete.id),1);
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, filtered,this.pageInfo.fillDaysData.data.daySelected);
        });
        this.pageInfo.displayDialog = false;
    }

    cancelUpdatePeriodDialog(){

        if(this.pageInfo.fillDaysData.daySelected){
            this.pageInfo.fillDaysData.daySelected.splice(this.pageInfo.fillDaysData.daySelected.findIndex(el => el.id == this.pageInfo.fillDaysData.data.id),1);
        }		                     

		this.pageInfo.displayDialog=false;
	}
    async updateExecOffer(data){
		
		// const checkNewInfo  =   data.exec.prices.some(el => el.price < 0);
        // if(checkNewInfo)	{   return await this.commons.generateToast('_ERROR','_BAD_PRICE','error');}
		
		let findedExec      =   data.daySelected.offers.findIndex(el => el.id == data.exec.id);
        if(findedExec < 0)	{   await this.commons.generateToast('_ERROR','_NO_EXEC_FOUND','error');};
       
        this.getInfoFillCalendarDays(data.daySelected.day);
        
        if(this.pageInfo.fillDaysData == null) return;
        if(this.pageInfo.fillDaysData.filteredDays.length == 0){
            this.pageInfo.fillDaysData.filteredDays = [moment(data.daySelected.day)];
        }
        // this.pageInfo.fillDaysData.data                     =   data.exec;
        // this.pageInfo.fillDaysData.offerTemplateSelected    =   data.daySelected.offers[findedExec];
        
        this.pageInfo.fillDaysData.data                     =   data.exec;
        this.pageInfo.fillDaysData.offerTemplateSelected    =   data.daySelected.offers[findedExec];
        this.pageInfo.fillDaysData.daySelected				=	data.daySelected.offers;

        if(this.pageInfo.fillDaysData.dirtyDays.length > 0){
            this.pageInfo.displayDialog = true;
        }else{
            data.exec.editMode  					=   false;
            data.daySelected.offers[findedExec] 	= 	data.exec;
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,data.daySelected.offers[findedExec]);
        }
        // this.fillCalendarDaysOffers(data.daySelected.day, data.daySelected.offers[findedExec]);
    }
    addItemLastRow(data){
        data.daySelected.offers = data.daySelected.offers || [];
        data.daySelected.offers.map(el => el.editMode = false);
        
        data.exec.id          =   this.commons.generateHash();
        data.exec.editMode    =   false;
        data.daySelected.offers.push(data.exec);
        
        this.updateExecOffer(data);
    }
    restartExecOffer(data){
        // data.daySelected.offers = [];
        let tmpDaySelected      =   JSON.parse(JSON.stringify(data.daySelected));
        tmpDaySelected.offers   =   [];
        this.fillCalendarDaysOffers(data.daySelected.day, tmpDaySelected);
    }
    deleteExecOffer(data){
        data.daySelected.offers.splice(data.daySelected.offers.findIndex(el => el.id == data.exec.id),1);
        if(data.exec.value !=null){
            this.fillCalendarDaysOffers(data.daySelected.day, data.daySelected);
        }
    }

    async handleCalSelectedDayOffers($event){
        switch($event.type){
            case 'update' : await this.updateCalExecTourOffer($event.data); break;
            case 'delete' : await this.deleteCalExecTourOffer($event.data); break;
            case 'add'    : await this.addCalExecTourOffer($event.data);    break;
            case 'lastRow':       this.addItemLastRow($event.data);
        }
        this.updateViewCalendar();

		console.log($event);
    }
    async updateCalExecTourOffer(data){
        const dayCalendar   =   this.getInfoDayCalendarOffers(this.pageInfo.calSingleDaySelected.day);
        if(isArray(dayCalendar)){
            this.offers.calendar.offers.days.push({ 
													day    		: this.pageInfo.calSingleDaySelected.day, 
                                                    offers		: [data.exec],
													period_owner: null
												});
            this.pageInfo.calSingleDaySelected = JSON.parse(JSON.stringify(this.offers.calendar.offers.days[this.offers.calendar.offers.days.length - 1]));
        }else{
            const findExec      =   dayCalendar.offers.findIndex(el => el.id == data.exec.id);
			if(findExec > -1)	{ dayCalendar.offers[findExec]     = data.exec; }
			else				{ dayCalendar.offers.push(data.exec) }
            this.pageInfo.calSingleDaySelected  = JSON.parse(JSON.stringify(dayCalendar));
        }
        data.exec.editMode = false;
    }
    deleteCalExecTourOffer(data){
		console.log('deleting',data);
		if(data.exec.custom){
			this.confirmationService.confirm({
				message : 	this.commons.getTranslate('_CONFIRM_DAY_WITH_CUSTOM_EXEC'),
				header	:	this.commons.getTranslate('_DELETE_OFFER'),
				icon	:	'pi pi-exclamation-triangle',
				accept	:	()=>{
					const dayCalendar   =   this.getInfoDayCalendarOffers(this.pageInfo.calSingleDaySelected.day);
					if(isArray(dayCalendar)) return this.commons.generateToast('_ERROR', '_BAD_DELETE_EXEC', 'error'); 
					const findExec  	=   dayCalendar.offers.findIndex(el => el.id == data.exec.id);
					if (findExec > -1){
						console.log(this.offers);
						console.log(this.pageInfo.test);
						dayCalendar.offers.splice(findExec,1);
						// this.offers.calendar.compositor.find(el => el.)
						this.pageInfo.calSingleDaySelected  =   JSON.parse(JSON.stringify(dayCalendar));
					}
				}
			})
		}else{
			const dayCalendar   =   this.getInfoDayCalendarOffers(this.pageInfo.calSingleDaySelected.day);
			if(isArray(dayCalendar)) return this.commons.generateToast('_ERROR', '_BAD_DELETE_EXEC', 'error'); 
			const findExec  	=   dayCalendar.offers.findIndex(el => el.id == data.exec.id);
			if (findExec > -1){
				dayCalendar.offers.splice(findExec,1);
				this.pageInfo.calSingleDaySelected  =   JSON.parse(JSON.stringify(dayCalendar));
			}
		}
    }
    addCalExecTourOffer(data){
        console.log(this.pageInfo.calSingleDaySelected);
        this.pageInfo.calSingleDaySelected.offers.push(
            {	
                id          : this.commons.generateHash(),
                editMode    : false,
                startTime   : '00:00',
                endTime     : '23:59',
                type        : {},
                value       : null,
                profiles    : ['adult','children','infant'],
                turns       : []
            }    
        );
    }
    getExecsDay(day){
		const dayFound = this.offers.calendar.offers.days.find(el => moment(el.day).format() == moment(day.date).format());
		if(!dayFound) return ;
		return dayFound.offers && dayFound.offers.length > 0;
	}
	changeMonth(type){
		switch(type){
			case 'previous' : this.previousMonth(); break;
			case 'next'		: this.nextMonth();		break;
        }
       this.updateViewCalendar();
	}
    previousMonth()			{ this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() - 30)); }
    nextMonth()				{ this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() + 30)); }
    getFormatDateHour(date)	{ return this.commons.getFormatDateHour(date); }
	getTitleCalendar()    	{ return moment(this.pageInfo.viewDate).locale(this.commons.getActiveLang()).format('MMMM, YYYY'); }

    clickedDateOffers($event, value : any, fromContextMenu?)
    {    
        if($event && ($event.shiftKey || $event.ctrlKey)){
            switch(true){
                case $event.shiftKey===true :
                    if(!this.pageInfo.calSingleDaySelected || !moment(this.pageInfo.calSingleDaySelected.day).isSameOrBefore(moment(value.date))) return;      
                    const extendedMoment 	= 	extendMoment(moment);
                    const rangeDays			=	Array.from(extendedMoment().range(moment(this.pageInfo.calSingleDaySelected.day), moment(value.date)).by('days')).map(el => el.toDate());
                    this.markRankDaysOffers(false);
                    this.markRankDaysOffers(true,rangeDays);
                break;
                case $event.ctrlKey===true :
                    const foundDay 		    = this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(value.date));
                    if(!foundDay) return;
                    // foundDay.rangeMode 	    = foundDay.rangeMode ? !foundDay.rangeMode : false;
                    foundDay.rangeMode 	    = !foundDay.rangeMode;
                break;
                default: return;
            }
        }
        else {
            if(fromContextMenu){
                this.monthViewCalendar.view.days.map(el => el.selected = false);
                const foundDay 		= this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(value.date));
                if(!foundDay) return;
                foundDay.selected 	= true;
            }else{
                this.markRankDaysOffers(false);
                const tmp    = value.selected ? !value.selected : true;
                this.monthViewCalendar.view.days.map(el => el.selected = false);
                value.selected = tmp;
                if(value.selected){
                    const dayInfo   = this.getInfoDayCalendarOffers(value.date);
                    if(isArray(dayInfo)){
                        this.pageInfo.calSingleDaySelected = {day:moment(value.date).format(), offers:[]};
                    }else{
                        this.pageInfo.calSingleDaySelected 	= JSON.parse(JSON.stringify(dayInfo));
                    }
                }else{
                    this.pageInfo.calSingleDaySelected  = null;
                }
    		    this.pageInfo.showPriceSidebar = this.pageInfo.calSingleDaySelected!=null;
            } 
        }
        // $event.day.backgroundColor = '#7c9baa';
    }

    onRightClickExec($event, contextMenuExec){
        let execsSelecteds  =   this.pageInfo.execsSelecteds.execs; 
        this.pageInfo.displayContextMenuOptionsExecs    =   [];

        if(execsSelecteds.length > 0){
            this.pageInfo.displayContextMenuOptionsExecs   =   this.pageInfo.contextMenuOptionsExec;
            if(execsSelecteds.length > 1){
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'copy').label      =   this.commons.getTranslate('_COPY_EXECS');
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'delete').label    =   this.commons.getTranslate('_DELETE_EXECS');
            }else{
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'copy').label 		= 	this.commons.getTranslate('_COPY_EXEC');
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'delete').label    =   this.commons.getTranslate('_DELETE_EXEC');
            }
        }else{
            this.pageInfo.displayContextMenuOptionsExecs    =   [];
            this.commons.generateToast('_INFO','_SELECT_EXEC_TO_COPY','info');
        }
        contextMenuExec.show($event); $event.stopPropagation();
    }
	
	markRankDaysOffers(mode,days?){
        if(days){
            days.forEach(day => {
                const foundDay 		= this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(day));
                if(!foundDay)   return;
                foundDay.rangeMode  =   mode;
            });
        }else{
            this.monthViewCalendar.view.days.map(el => el.rangeMode = mode);
        }
	}
	
	getInfoDayCalendarOffers(day){

        const foundDay = this.offers.calendar.offers.days.find(el => 
            moment((moment(el.day).format())).isSame(moment((moment(day).format()))));
        if ( !foundDay || !foundDay.offers ) return [];
        return foundDay;
    }

    getInfoDaysCurrentCalendar(day){
        if(!this.monthViewCalendar) return [];
        
        const foundDay = this.monthViewCalendar.view.days.find(el => el.day == day.date);
        if ( !foundDay || !foundDay.infoDay ) return [];
        return foundDay.infoDay;
    }
    onCalRightClickDayOffers($event, contextMenu, day){
        this.pageInfo.displayContextMenuOptions = [];
        const getDaysRangeMode                  =   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
        if(getDaysRangeMode.length > 1){
            let someExec    =   getDaysRangeMode.some(element => {
                let foundDay    =   this.offers.calendar.offers.days.find(el => moment(el.day).isSame(moment(element.date)));
                if(!foundDay)   return false;
                return ((foundDay.offers) && (foundDay.offers.length)) >0;
            });
            if(someExec && (this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.execsSelecteds.execs.length > 0)){
				this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => (el.id == 'delete_multiple') || (el.id == 'paste_overwrite') || (el.id == 'paste_merge'));
				this.setContextDayLabel('paste_overwrite','_OVERWRITE');
				this.setContextDayLabel('paste_merge','_MERGE')
			}else if(!someExec && (this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.execsSelecteds.execs.length > 0)){
				this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id == 'paste_overwrite');
				this.setContextDayLabel('paste_overwrite','_PASTE');
			}else if(someExec){
				this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => el.id == 'delete_multiple');
				this.setContextDayLabel('delete_multiple','_DELETE_DAYS')
			}
        }else{
            if(day.infoDay){
                if(this.pageInfo.contextMenu.dayCopied && this.pageInfo.contextMenu.dayCopied.day && this.pageInfo.contextMenu.dayCopied.day != null){
                    this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions;
                    // if(day.infoDay.offers.length > 0){
                    //     this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple' || el.id != 'copy');
                    // }
                    if(day.infoDay.offers.length == 0){
                        this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple' || el.id != 'copy' || el.id != 'paste_merge');
                    }else{
                        this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple' || el.id != 'copy');
                    }
					this.setContextDayLabel('paste_overwrite','_OVERWRITE');
					this.setContextDayLabel('paste_merge','_MERGE'); 
                }else if(this.pageInfo.contextMenuExecs.execs.length > 0){
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions;
                    // this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple');
                    if(day.infoDay.offers.length == 0){
                        this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id == 'paste_overwrite');
                        // this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple' || el.id != 'copy' || el.id != 'paste_merge');
    					this.setContextDayLabel('paste_overwrite','_PASTE');                    
                    }else{
                        if(day.infoDay.offers.length == 1){
                            this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id != 'delete_multiple');
                        }else{
                            this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id != 'delete');
                        }
                        // this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions;
                        this.setContextDayLabel('paste_overwrite','_OVERWRITE_EXECS');
    					this.setContextDayLabel('paste_merge','_MERGE_EXECS');
                                        
                    }
					// this.pageInfo.displayContextMenuOptions.find(el => el.id == 'paste').label = this.commons.getTranslate('_PASTE_HOUR');
				}else{
                    if(day.infoDay.offers.length >0){
                        this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id == 'copy' || el.id == 'delete'); 
                    }
				}
            }else{
                if(this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.contextMenuExecs.execs.length > 0){
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => el.id == 'paste_overwrite');
					this.setContextDayLabel('paste_overwrite','_PASTE');
				}
            }
            const dayFound  						=   this.getInfoDayCalendarOffers(day.date);
			const emptyDay 							= 
				{
					day 			: 	moment(day.date).format(),
					period_owner    :   null,
					offers		:	[]
				};
			this.pageInfo.contextMenu.rightClick	=	isArray(dayFound) ? emptyDay : dayFound;
        }
        contextMenu.show($event);$event.stopPropagation();
    }
    selectExecDay($event,exec,daySelected,execsDay){
        this.restartDayCopied();
        if(this.pageInfo.execsSelecteds.day && this.pageInfo.execsSelecteds.day != daySelected.day){
            this.restartExecsSelecteds();
		};
		// exec.prices	=	exec.prices ? exec.prices : this.displayMainPriceDay(execsDay);
        this.pageInfo.execsSelecteds.day    =   daySelected.day;
        if($event.ctrlKey){
            let foundExec   =   this.pageInfo.execsSelecteds.execs.findIndex(item => item.id == exec.id);
            foundExec       >   -1 ? this.pageInfo.execsSelecteds.execs.splice(foundExec,1) : this.pageInfo.execsSelecteds.execs.push(exec);    
        }else{
			if((this.pageInfo.execsSelecteds.execs.length == 1) && (this.pageInfo.execsSelecteds.execs[0].id == exec.id)){
				this.pageInfo.execsSelecteds.execs    =   [];
				this.restartDaysConfig();
			}else{
				this.pageInfo.execsSelecteds.execs    =   [];
				this.pageInfo.execsSelecteds.execs.push(exec);
			}
            
        }
        console.log(exec,daySelected);
        $event.stopPropagation();
    }
	getDayBackgroundColorOffers(day){
		if(day.selected)    return this.pageInfo.templateColors.isSelected;

        if(day.rangeMode)   return this.pageInfo.templateColors.isRangeMode;

        const indexPeriod   =   this.offers.calendar.offers.periods.findIndex(el => {
            return (moment(day.date).isBetween(moment(el.start), moment(el.end),null,'[]'));
        });
        if(indexPeriod > -1){return this.pageInfo.colorPeriods[indexPeriod];}
        
        if(day.isPast)      return this.pageInfo.templateColors.isPast;
        if(day.isToday)     return this.pageInfo.templateColors.isToday;
        if(day.isWeekend)   return this.pageInfo.templateColors.isWeekend;
    }
    getRibbonBackgroundColorOffer(day,exec){
        // const indexPeriod   =   this.offers.calendar.offers.periods.findIndex(el => {
        //     return (moment(day.date).isBetween(moment(el.start), moment(el.end),null,'[]'));
        // });
        // return indexPeriod > -1 ? '#39cf5d' : 'tomato';

        if(!day.infoDay) return this.pageInfo.templateColors.hasPeriod;
        if(this.pageInfo.execsSelecteds.day == day.infoDay.day){
            if(this.pageInfo.execsSelecteds.execs.find(el => el.id == exec.id)){
                return (this.pageInfo.contextMenuExecs.execs.length >0 || this.pageInfo.contextMenu.dayCopied.day ) ? this.pageInfo.templateColors.isCopied : this.pageInfo.templateColors.isSelected;
            }
        }
        return day.infoDay.period_owner == null ? this.pageInfo.templateColors.isCustom : this.pageInfo.templateColors.hasPeriod;

    }
    
    computePasteDayLogic(){
        if(this.pageInfo.contextMenuExecs.day != null){
            const getDaysRangeMode  =   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
            if(getDaysRangeMode.length > 1){
                this.pageInfo.contextMenuDirtyDays  =   [];
                this.pageInfo.duplicatedExecs       =   [];
                if(this.pageInfo.pasteMode == 'merge'){
                    getDaysRangeMode.forEach(element =>{
                        let foundDay    =   this.offers.calendar.offers.days.find(el => moment(el.day).isSame(moment(element.date)));
                        if(foundDay){
                            if(foundDay.offers && foundDay.offers.some(exec => this.pageInfo.contextMenuExecs.execs.some(contextExec => contextExec.id == exec.id))){
                                this.pageInfo.duplicatedExecs.push(foundDay);
                            }
                        }
                    });
                    if(this.pageInfo.duplicatedExecs.length > 0){
						let datesDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => moment(el.day).format('DD-MM-YYYY'));
						let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_OFFERS') +' :'+JSON.stringify(datesDuplicateds);
						return this.commons.generateToast('_DUPLICATED_OFFERS',strMessage,'error');
					}
                }
                getDaysRangeMode.forEach(element =>{
                    let foundDay    =   this.offers.calendar.offers.days.find(el => moment(el.day).isSame(moment(element.date)));
                    if(foundDay && foundDay.offers.some(el => el.custom)){
                        this.pageInfo.contextMenuDirtyDays.push(foundDay);
                    }
                });
                if(this.pageInfo.contextMenuDirtyDays.length > 0){
                    this.pageInfo.contextExecDisplayDialog = true;
                }else{
                    this.pasteAllContextExecs();
                }
            }else{
                let foundDay    =   this.offers.calendar.offers.days.find(el => el.day == this.pageInfo.contextMenu.rightClick.day);
                if(foundDay){
                    if(this.pageInfo.pasteMode == 'merge'){
                        this.pageInfo.duplicatedExecs   = foundDay.offers.filter(exec => this.pageInfo.contextMenuExecs.execs.some(copiedExec => copiedExec.id == exec.id));
                        if(this.pageInfo.duplicatedExecs.length > 0){
							let offersDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => el.start_hour);
							let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_OFFER') +' :'+JSON.stringify(offersDuplicateds);
							return this.commons.generateToast('_DUPLICATED_OFFERS',strMessage,'error');
						}
                    }
                    switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	foundDay.offers	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs)); break;
						case 'merge'		:	this.pageInfo.contextMenuExecs.execs.forEach(el => {
													if(!foundDay.offers.find(exec => exec.id == el.id)){
														foundDay.offers.push(JSON.parse(JSON.stringify(el)));
													}
												}); break;
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');
					}
                }else{
                    this.pageInfo.contextMenu.rightClick.offers	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs));
					this.offers.calendar.offers.days.push(JSON.parse(JSON.stringify(this.pageInfo.contextMenu.rightClick)));
					
                }
            }
        }else{
            const getDaysRangeMode  =   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
            if(getDaysRangeMode.length > 0){
                if(this.pageInfo.pasteMode == 'merge'){
                    this.pageInfo.duplicatedExecs   =   [];
                    getDaysRangeMode.forEach(element =>{
                        let foundDay	=	this.offers.calendar.offers.days.find(el => moment(el.day).isSame(moment(element.date)));
                        if(foundDay){
                            if(foundDay.offers && foundDay.offers.some(exec =>  this.pageInfo.contextMenu.dayCopied.offers.some(contextExec => contextExec.id == exec.id))){
                                this.pageInfo.duplicatedExecs.push(foundDay);
                            }
                        }
                    });
                    if(this.pageInfo.duplicatedExecs.length > 0){
                        let datesDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => moment(el.day).format('DD-MM-YYYY'));
						let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_OFFERS') +' :'+JSON.stringify(datesDuplicateds);
						return this.commons.generateToast('_DUPLICATED_EXECS',strMessage,'error');
                    }
                }
                this.pageInfo.contextMenuDirtyDays  =   [];
                getDaysRangeMode.forEach(element =>{
                    let foundDay	=	this.offers.calendar.offers.days.find(el => moment(el.day).isSame(moment(element.date)));
					if(foundDay && foundDay.offers.some(el => el.custom)){
						this.pageInfo.contextMenuDirtyDays.push(foundDay);
					}
                });
                if(this.pageInfo.contextMenuDirtyDays.length > 0){
					this.pageInfo.contextDayDisplayDialog = true;
				}else{
					this.pasteAllContextDay();
				}
            }else{
				const foundDay	=	this.offers.calendar.offers.days.findIndex(el => moment(el.day).isSame(moment(this.pageInfo.contextMenu.rightClick.day)));
                if(foundDay > -1){
                    if(this.pageInfo.pasteMode == 'merge'){
                        this.pageInfo.duplicatedExecs   =   this.offers.calendar.offers.days[foundDay].offers.filter(exec => this.pageInfo.contextMenu.dayCopied.offers.some(copiedExec => copiedExec.id == exec.id))
                        if(this.pageInfo.duplicatedExecs.length > 0){
							let hoursDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => el.id);
							let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_VALUE') +' :'+JSON.stringify(hoursDuplicateds);
							return this.commons.generateToast('_DUPLICATED_EXECS',strMessage,'error');
						}
                    }
                    switch(this.pageInfo.pasteMode){
                        case 'overwrite'	:	this.offers.calendar.offers.days[foundDay].offers	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.offers)); break;
						case 'merge'		:	this.pageInfo.contextMenu.dayCopied.offers.forEach(el => {
													if(!this.offers.calendar.offers.days[foundDay].offers.find(exec => exec.id == el.id)){
														this.offers.calendar.offers.days[foundDay].offers.push(JSON.parse(JSON.stringify(el)));
													}
												}); break;
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');						 	
					
                    }
                }else{
                    this.pageInfo.contextMenu.rightClick.offers	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.offers));
					this.offers.calendar.offers.days.push(JSON.parse(JSON.stringify(this.pageInfo.contextMenu.rightClick))); 
                }
                this.pageInfo.contextMenu.rightClick 					=	null;
            }
        }
        this.updateViewCalendar();
    }
    pasteAllContextDay(){
		const getDaysRangeMode	=	this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element=>{
			const foundDay = this.offers.calendar.offers.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				switch(this.pageInfo.pasteMode){
					case 'overwrite'	:	this.offers.calendar.offers.days[foundDay].offers = this.pageInfo.contextMenu.dayCopied.offers;	break;	
					case 'merge'		:	this.pageInfo.contextMenu.dayCopied.offers.forEach(exec => {
												if(!this.offers.calendar.offers.days[foundDay].offers.find(el => el.id == exec.id)){
													this.offers.calendar.offers.days[foundDay].offers.push(JSON.parse(JSON.stringify(exec)));
												} 
											}); break;
					default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');											 	
				}
			}else{
				this.offers.calendar.offers.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					offers	    	: JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.offers))
				});
			}
		});
		this.restartDaysConfig();
    }
    pasteAllContextExecs(){
        const getDaysRangeMode 	=   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element =>{
			let foundDay	=	this.offers.calendar.offers.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				switch(this.pageInfo.pasteMode){
					case 'overwrite'	:	this.offers.calendar.offers.days[foundDay].offers = JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs));break;	
					case 'merge'		:	this.pageInfo.contextMenuExecs.execs.forEach(exec =>{
												if(!this.offers.calendar.offers.days[foundDay].offers.find(el => el.id == exec.id)){
													this.offers.calendar.offers.days[foundDay].offers.push(JSON.parse(JSON.stringify(exec)));
												}
											});break;
					default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');						 	
				}
			}else{
				this.offers.calendar.offers.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					offers		: this.pageInfo.contextMenuExecs.execs.map(exec => {return { ...exec,id:this.commons.generateHash()}})
				});
			}
		});
        this.restartDaysConfig();  
    }
    pasteNonCustomizedContextDay(){
		const getDaysRangeMode	=	this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element=>{
			const foundDay = this.offers.calendar.offers.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				if(!this.pageInfo.contextMenuDirtyDays.find(el => moment(el.day).isSame(element.date))){
					switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	this.offers.calendar.offers.days[foundDay].offers = this.pageInfo.contextMenu.dayCopied.offers;	break;	
						case 'merge'		:	this.pageInfo.contextMenu.dayCopied.offers.forEach(exec => {
													if(!this.offers.calendar.offers.days[foundDay].offers.find(el => el.id == exec.id)){
														this.offers.calendar.offers.days[foundDay].offers.push(JSON.parse(JSON.stringify(exec)));
													} 
												}); break; 	
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');
					}
				}
				
			}else{
				this.offers.calendar.offers.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					offers		    : JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.offers))
				});
			}
		});
		this.restartDaysConfig();

	}
	
	restartDaysConfig(){
		this.pageInfo.contextExecDisplayDialog	=	false;
		this.pageInfo.contextDayDisplayDialog	=	false;
		// this.commons.generateToast('_SUCCESS','_EXECS_COPIED','success');
		this.pageInfo.contextMenu.rightClick 	= 	null;
		this.pageInfo.contextMenuExecs			=	{day    :   null,   execs   :   []};
		this.pageInfo.pasteMode					=	null;
        this.restartDayCopied();
        this.restartExecsSelecteds();
		this.markRankDaysOffers(false);
		this.updateViewCalendar();
    }

    restartExecsSelecteds(){
        this.pageInfo.execsSelecteds = {
            day     :   null,
            execs   :   []
        }
    }
    
    restartDayCopied(){
        this.pageInfo.contextMenu.dayCopied     =   {
            day             :   null,
            period_owner    :   null,
            offers          :   null
        }
    }

    setContextDayLabel(id,label){
		this.pageInfo.displayContextMenuOptions.find(el => el.id == id).label 	= this.commons.getTranslate(label);
    }
    
    changeCheckBoxDialog($event,typeDialog){
		console.log($event);
		switch(typeDialog){
			case 'period'	:	this.storageCtrl.setItem('answeredDialogPeriod',$event);break;
			case 'calendar'	:	this.storageCtrl.setItem('answeredDialogCalendar',$event);break;
		}
    }
    pasteNonCustomizedContextExecs(){}
}