import { Injectable               	} from '@angular/core';
import { CommonsService             } from 'src/app/demo/service/commons.service';
import { IProviderService 			} from './iproviderservice';

@Injectable()
export class ProviderHTXService implements IProviderService {		
	pageInfo 	= { providerId: 1 };

  	constructor(
		private commons 	: CommonsService
	){
		// this.commons.generateToast("_REGISTER_PROVIDER","_HTX_SERVICE_PROVIDER","info");
	}
    
	init($controller){
		$controller.registerProviderService({ 
			id		: this.pageInfo.providerId,
			name	: "htx", 
			service	: this 
		});
	}

    // Process bookings into HTX format
    // convert to Tourinia format
    // and generate groups for tripulation

	filterBooking($params):boolean{
		const $booking 			= $params["booking"			];
		const $show_cancelled	= $params["show_cancelled"	];

		if(!$params["show_cancelled"]){
			switch($booking.status){
				case "ACAN":
				case "PCAN":	return false;
			}
		}
		return true;		
	}

	mapBooking($booking):any{
		switch($booking.status){
			case undefined	:
			case null		:
				$booking.status = "PCON";
			case "PCON":
			case "PAMM":
			case "PCAN":
				$booking.verified = false;
				break;
			case "ACON":
			case "AAMM":
			case "ACAN":
				$booking.verified = true;
				break;
		}
		return $booking;
	}

    processBookings($info){
		let groups		= [];
		let bookingsMap	= [];
		let response;

		($info.bookings||[]).forEach((booking,index) => {
			let data = {};
			
			// Generate booking map from header and data
			($info.header||[]).forEach((field,index)=>{
				data[field]=booking[index];
			});

			if(response["success"]){
				data = response.data;
			}
			bookingsMap.push(data);
		});

        return bookingsMap;
	}
	
	processArrival($info):any{}

	processDeparture($info):any{}

	check4Validation($params):any{
		let $item = $params["item"];

		// NOTAS OBLICATORIAS ( p.e. OTBT-20016798 )
		// 	Si es privado
		// 	Si es PR3, PR4 o PR7
		// 	Si tienen niños o bebés
		// 		Obligatorio mandar nota antes de validar
		// 		Marcar como error o warning
		switch($item.shared){
			case "private":
				switch($item.vehicle){
					case "Private Standard Car 1 - 3"	:
					case "Private Standard Car 1 - 4"	:
					case "Private Standard Car 1 - 7"	:
					case "Private Premium Car 1 - 3"	:
					case "Private Premium Car 1 - 4"	:
					case "Private Premium Car 1 - 7"	:
						if($item.children>0 || $item.infants>0){
							if(undefined==$item.notes || $item.notes==""){
								this.commons.generateToast("_HTX_SERVICE","_CHECK_VALIDATION_FOR_TYPE_1","info");
								return false;
							}
						}
						break;
				}
				break;
		}

		if($params["notify"]){
			this.commons.generateToast("_HTX_SERVICE","_CHECK_4_VALIDATION_CORRECT","info");
		}
		
		return true;
	}
}