export const pageInfo:any = {};

pageInfo.noData     = {
    header	: {
        title	: "_WELCOME_GROUPS_TITLE",
        message	: "_WELCOME_GROUPS_DESC",
        video	: "_WELCOME_GROUPS_VIDEO"
    },
    items	: [
        { icon	: "filter",			message	: "_WELCOME_GROUPS_ITEM_1" },
        { icon	: "calendar",		message	: "_WELCOME_GROUPS_ITEM_2" },
        { icon	: "bus",			message	: "_WELCOME_GROUPS_ITEM_3" },
        { icon	: "hand-o-right",	message	: "_WELCOME_GROUPS_ITEM_4" },
        { icon	: "floppy-o",		message	: "_WELCOME_GROUPS_ITEM_5" }
    ]
};

pageInfo.digests    = [
    {
        cols 	: 2,
        title	: "_TOTAL",
        entity  : "total",
        items	: [				
            { icon		: "check", 		item: "total",			default: 0 }						
        ]
    },
    {
        cols	: 3,
        title	: "_VERIFIED",
        entity  : "verified",
        items	: [				
            { icon		: "check", 		item: "verified",		default: 0 },
            { icon		: "times", 		item: "not_verified",	default: 0 }
        ]
    },
    {
        cols	: 3,
        title	: "_ERRORS",
        entity  : "errors",
        items	: [				
            { icon		: "check",	 	item: "errors",			default: 0 },
            { icon		: "times", 		item: "no_errors",		default: 0 }
        ]
    },
    {
        cols	: 4,
        title	: "_DIRECTION",
        entity  : "direction",
        items	: [				
            { label		: "_ARRIVAL", 	item: "arrival",		default: 0 },
            { label		: "_DEPARTURE", item: "departure",		default: 0 },
            { label		: "_BOTH",		item: "both",			default: 0 }
        ]
    }
];

pageInfo.viewButtons = [
    {
        name		: 'view',
        title		: "_VIEW",
        format		: "vertical",
        showTitle	: true,
        showLabel	: true,
        cols		: 6,
        multiple	: false,
        items		: [
            { name: 'groups', 		label: '_GROUPS', 		icon: 'users'						},
            { name: 'zones', 		label: '_ZONES',		icon: 'bus'							},
            { name: 'mix', 			label: '_MIX',			icon: 'magic'						}
        ],
        selected	: 'groups'
    }
];

pageInfo.groupInfoButtons = {		
    entity	: 'booking_groups',
    name	: 'groupInfo',
    label	: '_GROUP_INFO',
    type	: 'simple',				
    items		: [
        { label: '_INFO_SIMPLE',value: 'simple', 	_icon: 'list'	},
        { label: '_INFO_FULL',	value: 'full',		_icon: 'list'	}
    ],
    selected	: 'simple'		
};

pageInfo.groupsInfoDetail	= { title: '_DETAIL', checked: false };

pageInfo.bookingsInfoDetail	= { title: '_DETAIL', checked: false };

pageInfo.filterButtons      = [			
    {
        name		: 'transferType',
        title		: "_TRANSFER_TYPE",
        format		: 'vertical',
        showTitle	: true,
        showLabel	: true,
        cols		: 6,
        multiple	: false,
        items: [
            { name: 'shared', 	label: '_SHARED', 			icon: 'users'						},
            { name: 'private', 	label: '_PRIVATE', 			icon: 'user' 						}					
        ],
        selected	: 'shared'
    }
    ,{
        name		: 'direction',
        title		: "_DIRECTION",
        format		: 'vertical',
        showTitle	: true,
        showLabel	: true,
        cols		: 6,
        multiple	: false,
        items		: [
            { name: 'arrivals', 	label: '_ARRIVALS', 	icon: 'plane', reverseIcon: true 	},
            { name: 'departures', 	label: '_DEPARTURES', 	icon: 'plane'						}
        ],
        selected	: 'arrivals'
    }					
];