export let fleetCols = [
    {
        field		: 'id',
        header		: 'Id',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'active',
        header		: 'Active',
        width		: '50px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'name',
        header		: 'Name',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'code',
        header		: 'Code',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'type',
        header		: 'Type',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'license',
        header		: 'License',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'pax',
        header		: 'Seats',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'qty',
        header		: 'Qty',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'desc',
        header		: 'Desc',
        width		: '200px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    }
];
