export const CS_LZ = [
    {
        "Id": 401,
        "Name": "Aeropuerto Lanzarote (ACE)",
        "Island": "LZ",
        "ZoneId": 401,
        "ZoneName": "Aeropuerto",
        "Address": "Arrecife Ciudad, s/n, 35500 Arrecife, Las Palmas",
        "Coordinates": "28.950438,-13.6057769"
    },
    {
        "Id": 2435,
        "Name": "OFICINA SIXT",
        "Island": "LZ",
        "ZoneId": 401,
        "ZoneName": "Aeropuerto",
        "Address": "Aeropuerto de Lanzarote, 35509 Aeropuerto de Lanzarote, Las Palmas, España",
        "Coordinates": "28.952487,-13.6090804"
    },
    {
        "Id": 1972,
        "Name": "La Concha Boutique",
        "Island": "LZ",
        "ZoneId": 402,
        "ZoneName": "El Cable",
        "Address": "Calle Isaac Albéniz, 2, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9549906,-13.5824196"
    },
    {
        "Id": 2018,
        "Name": "Villa Vik",
        "Island": "LZ",
        "ZoneId": 402,
        "ZoneName": "El Cable",
        "Address": "Calle Hermanos Díaz Rijo, 3, 35500 Arrecife, Las Palmas",
        "Coordinates": "28.956089632730123,-13.570303916931152"
    },
    {
        "Id": 1843,
        "Name": "Arrecife Gran Hotel & Spa",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Parque Islas Canarias, s/n, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.957367,-13.554348"
    },
    {
        "Id": 1909,
        "Name": "Hotel Diamar",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Av. Fred Olsen, 8, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9588104,-13.5579854"
    },
    {
        "Id": 1910,
        "Name": "Hotel Lancelot",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Av. de la Mancomunidad, 9, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9582583,-13.5557"
    },
    {
        "Id": 1911,
        "Name": "Hotel Miramar",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Casa de los Arroyos, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9587893,-13.5477865"
    },
    {
        "Id": 2053,
        "Name": "Islamar",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Calle Dr. Rafael González, 15, 35500 Arrecife, Las Palmas",
        "Coordinates": "28.95802816120796,-13.553169965744019"
    },
    {
        "Id": 2057,
        "Name": "Residencia Cardona",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Calle Democracia, 11, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9589083,-13.5536972"
    },
    {
        "Id": 2078,
        "Name": "Pension San Gines",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Calle Molino, 9, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9627481,-13.5495727"
    },
    {
        "Id": 2109,
        "Name": "Luxury Marabo Suites",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Manolo Millares 121 bloque D piso 6 D, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9585953,-13.5600415"
    },
    {
        "Id": 2117,
        "Name": "Ginory",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Calle Juan de Quesada, 9, 35500 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.96188413539588,-13.543970500594321"
    },
    {
        "Id": 2150,
        "Name": "P.Bus de Bankia Av. Olof Palme",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Av. Olof Palme",
        "Coordinates": "28.95942099909576,-13.546295447498299"
    },
    {
        "Id": 2641,
        "Name": "(Arrecife) Plus Fariones Habitat",
        "Island": "LZ",
        "ZoneId": 403,
        "ZoneName": "Arrecife",
        "Address": "Av. de la Mancomunidad, 2B, 35500 Arrecife, Las Palmas, España",
        "Coordinates": "28.9579004,-13.5541224"
    },
    {
        "Id": 1794,
        "Name": "Radisson Blu Resort Lanzarote",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 26, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9930556,-13.4994444"
    },
    {
        "Id": 1799,
        "Name": "Barcelo Teguise Beach",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Paseo Marítimo, s/n, 35508 Costa Teguise, Las Palmas",
        "Coordinates": "28.99586678483476,-13.489521145820618"
    },
    {
        "Id": 1802,
        "Name": "Oasis Lanz Club",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, 5, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.995286,-13.49131"
    },
    {
        "Id": 1805,
        "Name": "Club Tahiti",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle Isla de Lobos, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0058224,-13.4909436"
    },
    {
        "Id": 1806,
        "Name": "Apartamentos Club Timanfaya",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle Isla de Lobos, 2, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0061928,-13.4872265"
    },
    {
        "Id": 1814,
        "Name": "Grand Teguise Playa",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, s/n, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.993914,-13.4898992"
    },
    {
        "Id": 1816,
        "Name": "Playa Roca",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 20, 35508 Costa Teguise, Las Palmas",
        "Coordinates": "28.99059751959793,-13.503168225288391"
    },
    {
        "Id": 1824,
        "Name": "Las Coronas Apartamentos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 29, 35508 Costa Teguise, Las Palmas",
        "Coordinates": "28.99237469494199,-13.499964997172356"
    },
    {
        "Id": 1835,
        "Name": "Mansion Nazaret",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "A, Av. de las Islas Canarias, 1, A, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9966611,-13.4928477"
    },
    {
        "Id": 1839,
        "Name": "Allsun Albatros",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, 7, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9960146,-13.4922906"
    },
    {
        "Id": 1848,
        "Name": "Barceló Lanzarote Active Resort",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Lanzarote, Av. del Mar, 5, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9880087,-13.5109288"
    },
    {
        "Id": 1849,
        "Name": "Barceló Lanzarote Royal Level",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 6, 35508 Teguise, Las Palmas, Spain",
        "Coordinates": "28.9869257,-13.5096794"
    },
    {
        "Id": 1850,
        "Name": "Beatriz Costa & SPA",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle la Atalaya, 8 35508 Teguise, Las Palmas",
        "Coordinates": "29.010599, -13.488628"
    },
    {
        "Id": 1853,
        "Name": "BlueBay Lanzarote",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Palmeras, 30, 35509 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9967787,-13.4991594"
    },
    {
        "Id": 1860,
        "Name": "Camel Spring",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle la Rosa, 3, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9946722,-13.4956477"
    },
    {
        "Id": 1867,
        "Name": "Celeste",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 21, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9984426,-13.4905751"
    },
    {
        "Id": 1880,
        "Name": "Costa Teguise Beach BlueSea",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, 5, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9934328,-13.4917634"
    },
    {
        "Id": 1881,
        "Name": "Blue Sea Costa Teguise Gardens",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. el Golf, s/n, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.998342,-13.500999"
    },
    {
        "Id": 1883,
        "Name": "Blue Sea Costa Bastian antes Diverhotel Lanzarote",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Palmeras, 20, 35509 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9918899,-13.5049171"
    },
    {
        "Id": 1887,
        "Name": "El Guarapo",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle de las Tabaibas, 10, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9958221,-13.4951898"
    },
    {
        "Id": 1888,
        "Name": "El Trebol",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Palmeras, 10, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0050836,-13.4866298"
    },
    {
        "Id": 1893,
        "Name": "Ficus",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle los Hervideros, 8-10, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0040748,-13.4912247"
    },
    {
        "Id": 1896,
        "Name": "Galeon Playa",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, s/n, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.994030497726005,-13.487976950152682"
    },
    {
        "Id": 1901,
        "Name": "H10 Lanzarote Gardens",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 13, 35580 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0017184,-13.4880687"
    },
    {
        "Id": 1906,
        "Name": "HD Beach Resort",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, s/n, 35509 Costa Teguise, Lanzarote, Las Palmas, Spain",
        "Coordinates": "29.0024166,-13.4829282"
    },
    {
        "Id": 1925,
        "Name": "Lanzarote Paradise",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle Argentina, s/n, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9987981,-13.5059094"
    },
    {
        "Id": 1930,
        "Name": "Las Caletas Village",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 1, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9858703,-13.5138664"
    },
    {
        "Id": 1932,
        "Name": "Las Colinas",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "ARGENTINA PARCELA 318-22, 35508 Teguise",
        "Coordinates": "28.99848020322289,-13.505743145942688"
    },
    {
        "Id": 1947,
        "Name": "Los Zocos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 15, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0019064,-13.4857571"
    },
    {
        "Id": 1948,
        "Name": "Be Live Exp. Lanzarote Beach",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Plaza el Janubio, 2, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9966016,-13.4896502"
    },
    {
        "Id": 1952,
        "Name": "Paradisus Salinas Lanzarote",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, s/n, 35509 Lanzarote, Las Palmas, Spain",
        "Coordinates": "29.0007721,-13.4848378"
    },
    {
        "Id": 1953,
        "Name": "Molino de Guatiza",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle la Rosa, 6, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9953139,-13.4955825"
    },
    {
        "Id": 1958,
        "Name": "Nazaret Apartamentos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 1, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9969108,-13.4923938"
    },
    {
        "Id": 1959,
        "Name": "Neptuno Suites",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, s/n, 35530 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9936336,-13.4878715"
    },
    {
        "Id": 1962,
        "Name": "Oceano Ct. Teguise",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Jablillo, 12, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.995578,-13.4903135"
    },
    {
        "Id": 1994,
        "Name": "Sands Beach Resort",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 18, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0039262,-13.4830006"
    },
    {
        "Id": 1995,
        "Name": "Santa Rosa",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 19, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9933204,-13.5012331"
    },
    {
        "Id": 1996,
        "Name": "Hotel Siroco",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 7, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0044532,-13.4835795"
    },
    {
        "Id": 1997,
        "Name": "Hotel Siroco",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Islas Canarias, 7, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0044532,-13.4835795"
    },
    {
        "Id": 1998,
        "Name": "Sol Apartamentos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle los Hervideros, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.003919,-13.488258"
    },
    {
        "Id": 2009,
        "Name": "Tabaiba",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle las Piteras, 20, 35508 Costa Teguise, Las Palmas",
        "Coordinates": "28.99574479241631,-13.506783843040466"
    },
    {
        "Id": 2010,
        "Name": "Teguisol",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle las Adelfas, 3, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0063889,-13.4844444"
    },
    {
        "Id": 2029,
        "Name": "Vitalclass Lanzarote -antes Las Marinas Club",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. de las Palmeras, 15, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9930847,-13.5054568"
    },
    {
        "Id": 2034,
        "Name": "Aptos. Playa Bastian",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "35508 Costa Teguise, Las Palmas",
        "Coordinates": "28.992393463627497,-13.496075123548508"
    },
    {
        "Id": 2047,
        "Name": "Club Los Molinos Aptos.",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Urb. Los Molinos, Local 2, Av. de las Palmeras, 28, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9946639,-13.5010976"
    },
    {
        "Id": 2054,
        "Name": "Golf y Mar",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle las Piteras, 6, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9979697,-13.5030392"
    },
    {
        "Id": 2064,
        "Name": "Alborada",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9949667,-13.4951205"
    },
    {
        "Id": 2070,
        "Name": "Tuscan Apartamentos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 18, 35508 Costa Teguise, Las Palmas, España",
        "Coordinates": "28.990229365475265,-13.503656298609549"
    },
    {
        "Id": 2083,
        "Name": "Nautilus Aptos Costa Teguise",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle el Salao, 5, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "28.9931963,-13.487076"
    },
    {
        "Id": 2084,
        "Name": "Los Gurrupitos",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Apartamentos Los Gurrupitos, Calle de los Crotos, N.11, Local 6,7,8 y 9, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0006853,-13.497495"
    },
    {
        "Id": 2088,
        "Name": "Las Cucharas",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "14 Avenida de, 35508 Costa Teguise, Las Palmas",
        "Coordinates": "29.00111182276649,-13.48647298818352"
    },
    {
        "Id": 2090,
        "Name": "Occidental Allegro Oasis",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Av. del Mar, 6, 35508 Teguise, Las Palmas, Spain",
        "Coordinates": "28.9869257,-13.5096794"
    },
    {
        "Id": 2094,
        "Name": "Villa Las Calas",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.000352,-13.491472"
    },
    {
        "Id": 2098,
        "Name": "Villas Reina",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle Isla de Lobos, 6, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.005937,-13.488549"
    },
    {
        "Id": 2102,
        "Name": "Aptos Los Carmenes",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "Calle los Hervideros, 2, 35508 Costa Teguise, Las Palmas, Spain",
        "Coordinates": "29.0042725,-13.4878558"
    },
    {
        "Id": 2216,
        "Name": "A 100m en la media luna frente Oceano",
        "Island": "LZ",
        "ZoneId": 404,
        "ZoneName": "Costa Teguise",
        "Address": "",
        "Coordinates": "28.9954444444444,-13.4903888888889"
    },
    {
        "Id": 1788,
        "Name": "Sentido Aequora Lanzarote Suites",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Urano, s/n, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.932514,-13.629561"
    },
    {
        "Id": 1791,
        "Name": "Suite Montana Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Timón, 3, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.923143,-13.650244"
    },
    {
        "Id": 1795,
        "Name": "Hyde Park Lane",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Hungría, 6, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9274547,-13.6421092"
    },
    {
        "Id": 1796,
        "Name": "Hyde Park",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Bélgica, 20, 35510 Tías, Las Palmas",
        "Coordinates": "28.92484756687002,-13.645808100700378"
    },
    {
        "Id": 1800,
        "Name": "Las Marinas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Mato, 26, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.936413,-13.6220042"
    },
    {
        "Id": 1803,
        "Name": "POCILLOS PLAYA",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Burgao, 4, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9297294,-13.6332915"
    },
    {
        "Id": 1804,
        "Name": "Playa Pocillos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Malva, 6",
        "Coordinates": "28.935856840106887,-13.617724203461918"
    },
    {
        "Id": 1807,
        "Name": "Timanfaya Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Ico, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9231639,-13.6599249"
    },
    {
        "Id": 1810,
        "Name": "Apartamento Las Terracitas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Toscon, 1, 35518 Tías, Las Palmas, España",
        "Coordinates": "28.9235363,-13.6755478"
    },
    {
        "Id": 1811,
        "Name": "Tropicana Apartament",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Alcorce, 1, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9224375,-13.6610783"
    },
    {
        "Id": 1812,
        "Name": "Las Acacias",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Guayarmina, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9228854,-13.6603237"
    },
    {
        "Id": 1813,
        "Name": "Princesa Ico",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Ico, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9230697,-13.6591897"
    },
    {
        "Id": 1815,
        "Name": "Beatriz Playa & SPA",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9333372,-13.6176034"
    },
    {
        "Id": 1817,
        "Name": "Aptos. HG. Club Valena",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Malva, 25, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9357529,-13.6182709"
    },
    {
        "Id": 1818,
        "Name": "Parque Tropical",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Anzuelo, 7, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.921036,-13.6539296"
    },
    {
        "Id": 1819,
        "Name": "San Francisco II",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Teide, 48, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9230911,-13.6757993"
    },
    {
        "Id": 1820,
        "Name": "Luz Y Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Juan Carlos I, 18, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9245798,-13.6651248"
    },
    {
        "Id": 1821,
        "Name": "Los Fragosos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Lanzarote, Las Palmas, Spain",
        "Coordinates": "29.0468535,-13.5899733"
    },
    {
        "Id": 1822,
        "Name": "Bandama Bungalows",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Lapa, 18, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9304951,-13.6347306"
    },
    {
        "Id": 1823,
        "Name": "La Peñita",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Chalana, 2, 35510 Tías, Las Palmas, España",
        "Coordinates": "28.9212121,-13.6489566"
    },
    {
        "Id": 1825,
        "Name": "Salinas de Matagorda Villas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Agonal, 3, 35519 Tías, Las Palmas, España",
        "Coordinates": "28.9320481,-13.6242858"
    },
    {
        "Id": 1829,
        "Name": "Sun Seekers Bungalow",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "C/ Tomillo Nº 5, Matagorda, 35510 Puerto del Carmen - Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9348002,-13.6232512"
    },
    {
        "Id": 1831,
        "Name": "Harbour Lights",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle los Infantes, 14, 35518 Tías, Las Palmas, España",
        "Coordinates": "28.9219702,-13.6736915"
    },
    {
        "Id": 1836,
        "Name": "Acuario Sol",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Rociega, 46, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9219984,-13.6465145"
    },
    {
        "Id": 1837,
        "Name": "Agua Marina",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Teide, 35, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9222026,-13.6753582"
    },
    {
        "Id": 1838,
        "Name": "Aguazul",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9229415,-13.6503586"
    },
    {
        "Id": 1840,
        "Name": "Aloe",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Bocaina, 11, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9240104,-13.652474"
    },
    {
        "Id": 1841,
        "Name": "Villas Alondra",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle los Volcanes, 16, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9272222,-13.6741667"
    },
    {
        "Id": 1842,
        "Name": "Arena Dorada",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Tanausú, Av. de las Playas, n.1, 35510 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9210959,-13.656814"
    },
    {
        "Id": 1844,
        "Name": "Atalaya",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 17, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.922137,-13.6515762"
    },
    {
        "Id": 1846,
        "Name": "Atlantis Las Lomas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Tinguatón, 7, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9223196,-13.6628794"
    },
    {
        "Id": 1847,
        "Name": "Barcarola Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 53, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9201006,-13.6458812"
    },
    {
        "Id": 1851,
        "Name": "BelleVue Aquarius",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Teide, 6, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9220301,-13.67199"
    },
    {
        "Id": 1852,
        "Name": "Bitacora",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Alcorce, 11, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9251184,-13.6611932"
    },
    {
        "Id": 1854,
        "Name": "BlueSea Europa",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Géiser, 11, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9246285,-13.6760811"
    },
    {
        "Id": 1855,
        "Name": "R2 Bahia Kontiki",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guanapay, 5, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9219525,-13.6625422"
    },
    {
        "Id": 1856,
        "Name": "BlueSea Los Fiscos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle los Cabezos, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9245063,-13.6760577"
    },
    {
        "Id": 1858,
        "Name": "Bungalows Velazquez",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9193312,-13.6519715"
    },
    {
        "Id": 1859,
        "Name": "Cabrera Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 72, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9198803,-13.6462884"
    },
    {
        "Id": 1863,
        "Name": "Casas Heddy",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Traspiés, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9295299,-13.6743305"
    },
    {
        "Id": 1868,
        "Name": "Cinco Plazas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Anzuelo, 56, 35510 Las Palmas, Spain",
        "Coordinates": "28.9218365,-13.6535952"
    },
    {
        "Id": 1869,
        "Name": "Club Atlantico",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 16, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9231667,-13.6510625"
    },
    {
        "Id": 1870,
        "Name": "Club Las Vistas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Las Majadas",
        "Coordinates": "28.923118532921958,-13.666643500328064"
    },
    {
        "Id": 1872,
        "Name": "Club Pocillos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 77, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.928279,-13.6359"
    },
    {
        "Id": 1873,
        "Name": "Club del Carmen",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Urb. Los Pocillos, Calle Noruega, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9243482,-13.6407238"
    },
    {
        "Id": 1875,
        "Name": "Corona Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 21, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9230607,-13.651478"
    },
    {
        "Id": 1877,
        "Name": "Costa Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Playa de los Pocillos s/n, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9298799,-13.6352596"
    },
    {
        "Id": 1879,
        "Name": "Costa Sal Suites",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Agonal, 16, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.934137,-13.6237536"
    },
    {
        "Id": 1882,
        "Name": "Costa Volcan",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle la Graciosa, 5, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9215832,-13.669425"
    },
    {
        "Id": 1884,
        "Name": "Don Rafael Villas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Barqueta, 12, 35510 Puerto del Carmen, Lanzarote, Las Palmas, Las Palmas, Spain",
        "Coordinates": "28.9262543,-13.6625682"
    },
    {
        "Id": 1886,
        "Name": "El Dorado",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle la Graciosa, 7, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9219005,-13.6673492"
    },
    {
        "Id": 1890,
        "Name": "Elena Aptos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Nicaragua, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9209483,-13.6553897"
    },
    {
        "Id": 1891,
        "Name": "Plus Fariones Apart",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Timanfaya, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9218997,-13.6665001"
    },
    {
        "Id": 1892,
        "Name": "Fayna",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 5, 35510 Tías, Las Palmas",
        "Coordinates": "28.920497060202468,-13.651492841692798"
    },
    {
        "Id": 1894,
        "Name": "Flamingo Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 51, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9196815,-13.6520207"
    },
    {
        "Id": 1895,
        "Name": "Floresta",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Mercurio, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9304262,-13.6286795"
    },
    {
        "Id": 1897,
        "Name": "Gloria Izaro Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Salinas, 9, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9247692,-13.6721155"
    },
    {
        "Id": 1898,
        "Name": "Green Bay Bungalows",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Urbanización Matagorda, Calle Mato, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9328811,-13.6187345"
    },
    {
        "Id": 1899,
        "Name": "Guacimeta",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Junco, 3, 35510 Matagorda, Las Palmas, Spain",
        "Coordinates": "28.935705,-13.620053"
    },
    {
        "Id": 1900,
        "Name": "Guinate Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Teguise, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9244121,-13.6633843"
    },
    {
        "Id": 1913,
        "Name": "Isabel Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle César Manrique, 29, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.923999,-13.6562204"
    },
    {
        "Id": 1914,
        "Name": "Isla De Lobos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guanapay, 8, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.922941284771177,-13.662357330322266"
    },
    {
        "Id": 1915,
        "Name": "Jable Bermudas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Bernegal, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.921717,-13.6610722"
    },
    {
        "Id": 1918,
        "Name": "La Colina",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Diseminado las Coloradas, 14, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8665006,-13.8000286"
    },
    {
        "Id": 1919,
        "Name": "La Florida",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Reina Sofía Apertementos, 35518 La Florida, Las Palmas, Spain",
        "Coordinates": "28.9233385,-13.671484"
    },
    {
        "Id": 1920,
        "Name": "La Geria Hipotels",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Júpiter, 5, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.93037,-13.631758"
    },
    {
        "Id": 1921,
        "Name": "La Isla y el Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Reina Sofía, 23, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9245045,-13.6738752"
    },
    {
        "Id": 1922,
        "Name": "La Laguneta",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Rambla Islas Canarias, Puerto del Carmen, esq, Calle Finlandia, nº23, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9246525,-13.6474407"
    },
    {
        "Id": 1923,
        "Name": "La Tegala",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle César Manrique, 27, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9236667,-13.6560963"
    },
    {
        "Id": 1924,
        "Name": "Sentido Aequora Lanzarote Suites",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Urano, s/n, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.932514,-13.629561"
    },
    {
        "Id": 1927,
        "Name": "Lanzarote Village",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Suiza, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9256307,-13.6403599"
    },
    {
        "Id": 1928,
        "Name": "Las Adelfas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de Francia, 36, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9250077,-13.6417187"
    },
    {
        "Id": 1931,
        "Name": "Las Cascadas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Roque Nublo, 3, 5, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9212621,-13.6704895"
    },
    {
        "Id": 1933,
        "Name": "Las Costas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 88, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9227525,-13.6406908"
    },
    {
        "Id": 1934,
        "Name": "Las Gaviotas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Urbanización Matagorda, Calle Mato, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9328811,-13.6187345"
    },
    {
        "Id": 1935,
        "Name": "Las Lilas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 10, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9221736,-13.6513185"
    },
    {
        "Id": 1936,
        "Name": "Las Palmeras 1",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Guayarmina, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.922702,-13.6604759"
    },
    {
        "Id": 1937,
        "Name": "Las Terrazas del Puerto",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Punta El Barquillo, 12, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9232798,-13.677587"
    },
    {
        "Id": 1938,
        "Name": "Lomo Blanco",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guadarfía, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9230556,-13.6644444"
    },
    {
        "Id": 1939,
        "Name": "Los Arcos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 49, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9197222,-13.6522222"
    },
    {
        "Id": 1940,
        "Name": "Los Cocoteros",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 17, 35510 Puerto del Carmen, Las Palmas, España",
        "Coordinates": "28.920887,-13.6603277"
    },
    {
        "Id": 1941,
        "Name": "Hotel Los Fariones",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Roque del Este, 1, 35510 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9198119,-13.6662565"
    },
    {
        "Id": 1942,
        "Name": "Los Gracioseros",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Ancla, 14, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9229105,-13.6494361"
    },
    {
        "Id": 1943,
        "Name": "Ereza Los Hibiscos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Doramas, 15, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9222071,-13.6598482"
    },
    {
        "Id": 1944,
        "Name": "Los Jameos Playa Seaside",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Marte, 2, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9289679,-13.6281956"
    },
    {
        "Id": 1945,
        "Name": "Los Pueblos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Testeyna, 3, 35510 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9237232,-13.6766081"
    },
    {
        "Id": 1946,
        "Name": "Los Tulipanes",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Finlandia, 18, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9243254,-13.6469679"
    },
    {
        "Id": 1950,
        "Name": "Mar Azul Playa Bungalows",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Grama, 1, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9321385,-13.6234867"
    },
    {
        "Id": 1951,
        "Name": "Maribel Apartments",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Frankfurt, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9204837,-13.6468412"
    },
    {
        "Id": 1954,
        "Name": "Moraña",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guanapay, 2, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.921682931572832,-13.662073016166687"
    },
    {
        "Id": 1955,
        "Name": "Aptos Livvo Morromar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Urbanización Matagorda, Calle Mato, 13, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.935336,-13.622189"
    },
    {
        "Id": 1957,
        "Name": "Nautilus Lanzarote",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Gramillo, 5, 35500 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9340259,-13.6195549"
    },
    {
        "Id": 1960,
        "Name": "RELAXIA OLIVINA",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "C. Grecia, 11, 35510 Puerto del Carmen, Las Palmas, España",
        "Coordinates": "28.923215257094004,-13.643585013491819"
    },
    {
        "Id": 1961,
        "Name": "Oasis Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Arpón, 5, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.923011,-13.6481553"
    },
    {
        "Id": 1964,
        "Name": "Panorama",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9228852,-13.6646146"
    },
    {
        "Id": 1968,
        "Name": "Pension Magec",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Hierro, 11, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9209991,-13.6704993"
    },
    {
        "Id": 1969,
        "Name": "Labranda Playa Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Pedro Barba, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9203882,-13.6510424"
    },
    {
        "Id": 1974,
        "Name": "Playamar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Doramas, 13, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9214,-13.6598033"
    },
    {
        "Id": 1975,
        "Name": "Plaza Azul",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Anzuelo, 19, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9231334,-13.6543469"
    },
    {
        "Id": 1976,
        "Name": "Lanzarote Palm antes Plaza Palmeras",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Anzuelo, 60, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9234078,-13.6540672"
    },
    {
        "Id": 1977,
        "Name": "Princesa Guayadeque",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guayadeque, 4, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9238889,-13.6602778"
    },
    {
        "Id": 1981,
        "Name": "Bakour Lanzarote Splash",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Juan Carlos I, 35, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.92570679093871,-13.665366768836975"
    },
    {
        "Id": 1982,
        "Name": "Relaxia Lanzaplaya",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle México, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.921481,-13.654589"
    },
    {
        "Id": 1985,
        "Name": "Riu Paraiso Lanzarote Resort",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Suiza, 4, 35519 Tías, Las Palmas, Spain",
        "Coordinates": "28.9266661,-13.6390753"
    },
    {
        "Id": 1986,
        "Name": "Riviera Park",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Timón, 5, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9234058,-13.6487435"
    },
    {
        "Id": 1987,
        "Name": "Rocas Blancas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 39, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9203378,-13.6548455"
    },
    {
        "Id": 1988,
        "Name": "Rosamar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Arpón, 3, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.922529,-13.650515"
    },
    {
        "Id": 1991,
        "Name": "Sal y Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Chabusquillo, 5, 35519 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9324113,-13.6216804"
    },
    {
        "Id": 1992,
        "Name": "San Francisco Park",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Chalana, 7, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9212652,-13.6498498"
    },
    {
        "Id": 1993,
        "Name": "San Marcial Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Alpahor, 4, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9345,-13.6182243"
    },
    {
        "Id": 1999,
        "Name": "Sol Lanzarote",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Grama, 2, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9309766,-13.6239216"
    },
    {
        "Id": 2000,
        "Name": "Soulea Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guanapay, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9216798,-13.66207"
    },
    {
        "Id": 2001,
        "Name": "Aqua Suites Lanzarote -antes Spice Lanzarote",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Anzuelo, 23, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9248009,-13.6547069"
    },
    {
        "Id": 2006,
        "Name": "THB Flora",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Reina Sofía, 25, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9248984,-13.674718"
    },
    {
        "Id": 2011,
        "Name": "Teneguia",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Finlandia, 4, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.92285207366675,-13.646470606327057"
    },
    {
        "Id": 2012,
        "Name": "Tisalaya Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Montaña Tisalaya, 1, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.925246,-13.6555672"
    },
    {
        "Id": 2014,
        "Name": "Vik San Antonio ",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 84, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.921133571097155,-13.641334176063538"
    },
    {
        "Id": 2015,
        "Name": "Villa Bocaina",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Urb la Bocaina, 5, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9245823,-13.6529042"
    },
    {
        "Id": 2016,
        "Name": "Villa Canaima",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "C/ Tomillo Nº 5, Matagorda, 35510 Puerto del Carmen - Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9348002,-13.6232512"
    },
    {
        "Id": 2017,
        "Name": "Villa Tamarindo",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Lanzarote, Av. de las Canarias, 29, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8570334,-13.8384901"
    },
    {
        "Id": 2028,
        "Name": "Vista Mar Pto del carmen",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle las Majadas, 4, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9231568,-13.6664227"
    },
    {
        "Id": 2030,
        "Name": "Apartamentos Club Oceano Pto. Carmen",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Alcorce, 5, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9230859,-13.6610569"
    },
    {
        "Id": 2031,
        "Name": "Lanzamar Aptos.",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle las Majadas, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.923692,-13.6653971"
    },
    {
        "Id": 2032,
        "Name": "Apartamentos Las Mimosas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Lapa, 28, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.930981,-13.6365881"
    },
    {
        "Id": 2033,
        "Name": "Aptos. Los Rosales",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle César Manrique, 11, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9218935,-13.6560122"
    },
    {
        "Id": 2035,
        "Name": "Atlantic View Apts",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Salinas, 10, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9249434,-13.670698"
    },
    {
        "Id": 2036,
        "Name": "Caleton Blanco Apartments",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "11, Av. de las Playas, 9, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9220729,-13.6636901"
    },
    {
        "Id": 2037,
        "Name": "Columbus Apts.",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Nicaragua, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9200086,-13.6540209"
    },
    {
        "Id": 2038,
        "Name": "Diamond Club Calypso",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. Italia, 5, 35510 Tías, Las Palmas",
        "Coordinates": "28.921318452836847,-13.644037822337337"
    },
    {
        "Id": 2039,
        "Name": "Diamond Club La Cumbre",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Manguia, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.92361,-13.66884"
    },
    {
        "Id": 2040,
        "Name": "Diamond Club Maritima",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Mirafondo, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9230393,-13.6463177"
    },
    {
        "Id": 2041,
        "Name": "Diamond Las Calas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guayadeque, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9242261,-13.6604165"
    },
    {
        "Id": 2043,
        "Name": "Lago Verde Suites",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "35518 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9240122,-13.6752436"
    },
    {
        "Id": 2044,
        "Name": "Las Orquideas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Cesto, 1, 35510 Tías, Las Palmas, España",
        "Coordinates": "28.9236522,-13.6620883"
    },
    {
        "Id": 2045,
        "Name": "Portonovo Apts.",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle los Afrechos, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9231431,-13.6741"
    },
    {
        "Id": 2052,
        "Name": "Club del Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Roque del Este, 1, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9198119,-13.6662565"
    },
    {
        "Id": 2056,
        "Name": "Playa Park",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de Francia, 38, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9248543,-13.6413785"
    },
    {
        "Id": 2058,
        "Name": "Balcon del Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 36, 35510 Arrecife, Las Palmas, Spain",
        "Coordinates": "28.9202823,-13.6478034"
    },
    {
        "Id": 2062,
        "Name": "Costa Luz",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Inglaterra, 1, 35510 Tías, Las Palmas, España",
        "Coordinates": "28.9221222,-13.6401404"
    },
    {
        "Id": 2067,
        "Name": "Aparthotel Las Palmeras Tres",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Zonzamas 16",
        "Coordinates": "28.923708966629334,-13.658052384853363"
    },
    {
        "Id": 2068,
        "Name": "CP Coloni Club",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Tinguatón, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9220655,-13.6646141"
    },
    {
        "Id": 2071,
        "Name": "Barranco Seco",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Rociega, 38, 35510 Tías, Las Palmas",
        "Coordinates": "28.921351907019297,-13.652154207229614"
    },
    {
        "Id": 2072,
        "Name": "Flamboyant Apartamentos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Lapa, 26, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9308368,-13.6361955"
    },
    {
        "Id": 2073,
        "Name": "Lanis Suites Deluxe",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 26, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.920149,-13.6496001"
    },
    {
        "Id": 2075,
        "Name": "Colina del Sol",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "-1703 Spanien, Calle Volcán de la Corona, 522, 35518 Tías, Las Palmas, Spain",
        "Coordinates": "28.9249374,-13.668409"
    },
    {
        "Id": 2077,
        "Name": "San Fermin",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Centro Commercial La Penita Puerto del Carmen, Lanzarote, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9200986,-13.6452775"
    },
    {
        "Id": 2079,
        "Name": "Las Palmeras 2",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guayadeque, 2, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9237633,-13.6606813"
    },
    {
        "Id": 2081,
        "Name": "Sol y Playa Aptos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle César Manrique, 35510 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.922213,-13.655726"
    },
    {
        "Id": 2085,
        "Name": "Alegranza",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Cenobio, 4, 35510 Tías, Las Palmas",
        "Coordinates": "28.920467995852736,-13.666123151779175"
    },
    {
        "Id": 2086,
        "Name": "Aptos Cactus",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Barqueta, 4, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9243835,-13.6623651"
    },
    {
        "Id": 2091,
        "Name": "Rocas Negras",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. Italia, 3, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9208572,-13.6441357"
    },
    {
        "Id": 2096,
        "Name": "El Rincon Aptos",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle los Infantes, 51, 35518 Tías, Las Palmas",
        "Coordinates": "28.921891875605205,-13.675706684589386"
    },
    {
        "Id": 2097,
        "Name": "Panelolanza",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Agrupación Acatife, 2, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9243835,-13.6671367"
    },
    {
        "Id": 2099,
        "Name": "Aptos Isla de la Graciosa",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Reina Sofía, 22, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.922691501127062,-13.670590924253455"
    },
    {
        "Id": 2103,
        "Name": "Salmarina Bungalows",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Matagorda, Calle Grama, 6, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9325154,-13.6227285"
    },
    {
        "Id": 2104,
        "Name": "Jardin Del Atlantico",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Guacimeta, 2, 35510 Tías, Las Palmas",
        "Coordinates": "28.92260322123297,-13.662142753601074"
    },
    {
        "Id": 2105,
        "Name": "Tagoror Lanzarote",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Tanausú, 8, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9214076,-13.6566"
    },
    {
        "Id": 2106,
        "Name": "Vistas del Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle de Sálamo, 5, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9261285,-13.6606769"
    },
    {
        "Id": 2107,
        "Name": "Apartamentos Las Caracolas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Princesa Teguise, 16, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9245457,-13.6617406"
    },
    {
        "Id": 2111,
        "Name": "PELICANOS APARTAMENTOS",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "-10 Spain, Calle Guadarfía, 8, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9237638,-13.6640341"
    },
    {
        "Id": 2112,
        "Name": "Luz del Mar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Chalana",
        "Coordinates": "28.921008240484177,-13.650274143345639"
    },
    {
        "Id": 2114,
        "Name": "Apartahotel Las Tabaibas",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Av. de las Playas, 13A, 35510 Tías, Las Palmas",
        "Coordinates": "28.92076615530876,-13.661627098917961"
    },
    {
        "Id": 2115,
        "Name": "Paradise Club Pto. Carmen",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Alemania, 2, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9208496,-13.6447775"
    },
    {
        "Id": 2118,
        "Name": "Lava Beach",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "Calle Marte, 6, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9309383,-13.6262362"
    },
    {
        "Id": 2119,
        "Name": "Apartamentos Zalabar",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "11, Calle Palangre, 1, 35510 Tías, Las Palmas, Spain",
        "Coordinates": "28.9193962,-13.6530608"
    },
    {
        "Id": 2224,
        "Name": "Plus Fariones Suite Hotel",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "C. Acatife, 1, 35510 Puerto del Carmen, Las Palmas",
        "Coordinates": "28.92055790326923,-13.665741768519121"
    },
    {
        "Id": 2473,
        "Name": "Los Zafiros",
        "Island": "LZ",
        "ZoneId": 405,
        "ZoneName": "Puerto del Carmen",
        "Address": "C. Princesa Teguise, 25, 35510 Tías, Las Palmas, España",
        "Coordinates": "28.9251392,-13.6580316"
    },
    {
        "Id": 1876,
        "Name": "Costa Calero Hotel",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "Urbanización Puerto Calero s/n, 35571 Puerto Calero, Las Palmas, Spain",
        "Coordinates": "28.9197913,-13.7047526"
    },
    {
        "Id": 1907,
        "Name": "Secrets Lanzarote",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "Cortijo Viejo, s/n, 35570 Puerto Calero, Las Palmas, Spain",
        "Coordinates": "28.917161,-13.7110689"
    },
    {
        "Id": 2019,
        "Name": "Villa del Puerto",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "Calle Roque del Este, 138, 35571 Puerto Calero, Las Palmas, Spain",
        "Coordinates": "28.9185262,-13.7049123"
    },
    {
        "Id": 2026,
        "Name": "Villas del Mar",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "Calle Benahore, 7A, 35571 Puerto Calero, Las Palmas, Spain",
        "Coordinates": "28.9219498,-13.7035577"
    },
    {
        "Id": 2060,
        "Name": "Vientos del Sur",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "Pantalán E9, Velero Vientos del Sur, Oficina de Capitanía, Marina Puerto Calero, 35570 Puerto Calero, Las Palmas, Spain",
        "Coordinates": "28.9170009,-13.7039195"
    },
    {
        "Id": 2509,
        "Name": "Royal Marina Suites Boutique Hotel",
        "Island": "LZ",
        "ZoneId": 406,
        "ZoneName": "Puerto Calero",
        "Address": "C. Barranco del Agua, 22, 35571 Puerto Calero, Las Palmas",
        "Coordinates": "28.916997245935896,-13.708497591550518"
    },
    {
        "Id": 1789,
        "Name": "La Avenida Apartamentos",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle el Terminito, 12, 35580 Playa Blanca, Las Palmas",
        "Coordinates": "28.862822614424132,-13.828288167715073"
    },
    {
        "Id": 1792,
        "Name": "Alyssa Suite Hotel",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle España, 1, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.8656448,-13.8473851"
    },
    {
        "Id": 1797,
        "Name": "Jardin del Sol Bungalows",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urb, Montaña Roja, Calle Francia, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8663501,-13.8436069"
    },
    {
        "Id": 1798,
        "Name": "Jardines del Sol",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urb, Montaña Roja, Calle Francia, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8663501,-13.8436069"
    },
    {
        "Id": 1809,
        "Name": "Las Terracitas Apartamentos",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Punta El Barquillo, 12, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9232798,-13.677587"
    },
    {
        "Id": 1826,
        "Name": "Vista Lobos Villas",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. Marítima, 32, 35660 Corralejo, Las Palmas, Spain",
        "Coordinates": "28.738542,-13.8675906"
    },
    {
        "Id": 1828,
        "Name": "Casa Colina",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle las Margaritas, 22, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8623948,-13.8387092"
    },
    {
        "Id": 1830,
        "Name": "Las Brisas Apartamentos",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Gomera, 1, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.864398265048496,-13.832352795008735"
    },
    {
        "Id": 1834,
        "Name": "Los Erizos Apts.",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Limones, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8618528,-13.83153"
    },
    {
        "Id": 1845,
        "Name": "Atlantic Gardens Marconfort",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urbanización, Calle el Hierro, 1, 35580 Montaña Roja, Las Palmas, Spain",
        "Coordinates": "28.8625,-13.8469444"
    },
    {
        "Id": 1861,
        "Name": "Casa Domingo Apts.",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "49 Calle Limones Es la Calle Rociega esquina con, Calle Limones, 49, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8623829,-13.8311917"
    },
    {
        "Id": 1864,
        "Name": "Casas del Sol",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Casas del Sol, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8610254,-13.8355952"
    },
    {
        "Id": 1871,
        "Name": "Club Playa Blanca Hl",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. Faro Pechiguera, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8682165,-13.8346204"
    },
    {
        "Id": 1903,
        "Name": "H10 Rubicon Palace",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urbanización Montaña Roja, s/n, 35570 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8626246,-13.8572409"
    },
    {
        "Id": 1904,
        "Name": "H10 Timanfaya Palace",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "C/ Gran Canaria, 1. Urb, 35580 Montaña Roja, Las Palmas, Spain",
        "Coordinates": "28.8597717,-13.8442974"
    },
    {
        "Id": 1908,
        "Name": "Hoopoe Villas Lanzarote",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urbanizacion Montaña Roja Calle Australia 1, 35580 Yaiza, Las Palmas, Spain",
        "Coordinates": "28.863842,-13.8586391"
    },
    {
        "Id": 1916,
        "Name": "Kamezi Heredad Villas",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Monaco, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8603356,-13.8654515"
    },
    {
        "Id": 1926,
        "Name": "Iberostar Selection Lanzarote Park",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. Archipiélago, 7, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8565567,-13.8392856"
    },
    {
        "Id": 1929,
        "Name": "Las Buganvillas Villas",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Las Buganvillas, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8730224,-13.8345532"
    },
    {
        "Id": 1956,
        "Name": "Natura Palace Hipotels",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Urbanización Montaña Roja, Calle Lanzarote, 9, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.862553,-13.852257"
    },
    {
        "Id": 1963,
        "Name": "Palmeras Garden",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Lanzarote, 18, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8634558,-13.8520699"
    },
    {
        "Id": 1966,
        "Name": "Paradise Island HL",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Francia, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8679561,-13.8434477"
    },
    {
        "Id": 1971,
        "Name": "Flamingo Beach Resort",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. de las Canarias, S/N, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8592012,-13.8420632"
    },
    {
        "Id": 1973,
        "Name": "Playa Limones",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. de las Canarias, 6, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8592979,-13.8408326"
    },
    {
        "Id": 1983,
        "Name": "Relaxia Lanzasur Club",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Gran Canaria, 26, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8616258,-13.8435352"
    },
    {
        "Id": 1984,
        "Name": "Rio Playa Blanca HL",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. Archipiélago, 4, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.862944,-13.841845"
    },
    {
        "Id": 1989,
        "Name": "SBH ROYAL MONICA",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Irlanda, 1, 35570 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8614051,-13.8621239"
    },
    {
        "Id": 2005,
        "Name": "Elba Lanzarote Royal Village Resort",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. Faro Pechiguera, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8655625,-13.8385094"
    },
    {
        "Id": 2013,
        "Name": "Vik Club Coral Beach",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Austria, S/N, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8621991,-13.874971"
    },
    {
        "Id": 2020,
        "Name": "Villas Bellavista",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Villas Bellavista, Calle Montaña Mesa, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8719173,-13.8463488"
    },
    {
        "Id": 2021,
        "Name": "Villas Blancas Lanzarote",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Suecia, s/n, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.870866,-13.8447"
    },
    {
        "Id": 2023,
        "Name": "Villas Coral Deluxe",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Austria, 10, 35570 Yaiza, Las Palmas, Spain",
        "Coordinates": "28.8613853,-13.8727765"
    },
    {
        "Id": 2025,
        "Name": "Villas Parque del Rey",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Monta a Roja, 61, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8691734,-13.8382205"
    },
    {
        "Id": 2042,
        "Name": "Faro Park",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Irlanda, 179, 35580 Montaña Roja, Las Palmas, Spain",
        "Coordinates": "28.8639483,-13.8705794"
    },
    {
        "Id": 2046,
        "Name": "Puerto Chico Bungalows",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. de las Canarias, 0, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8579617,-13.8372945"
    },
    {
        "Id": 2048,
        "Name": "Muelle Playa Blanca",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Puerto de Playa Blanca, s/n, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.8587948,-13.8347622"
    },
    {
        "Id": 2049,
        "Name": "Las Casitas",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "C. Fuerteventura, s/n, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.8626251,-13.8336324"
    },
    {
        "Id": 2051,
        "Name": "Las Brisas Bungalows",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Gomera, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.864388954833416,-13.83234414843236"
    },
    {
        "Id": 2059,
        "Name": "Los Claveles",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Residencial, Calle los Claveles, 33a, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8676201,-13.8462315"
    },
    {
        "Id": 2061,
        "Name": "Villa Mar",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle las Majadas, 4, 35510 Puerto del Carmen, Las Palmas, Spain",
        "Coordinates": "28.9231568,-13.6664227"
    },
    {
        "Id": 2066,
        "Name": "Las Clacas Apartamentos",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Limones, 85, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8619241,-13.8314277"
    },
    {
        "Id": 2069,
        "Name": "Virginia Park",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8701865,-13.8435208"
    },
    {
        "Id": 2074,
        "Name": "Residencial el Puerto",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Tanausú, Av. de las Playas, n.1, 35510 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.9210959,-13.656814"
    },
    {
        "Id": 2080,
        "Name": "Estación de guaguas de Playa Blanca",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Av. de las Canarias, 30, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.8644895,-13.8314663"
    },
    {
        "Id": 2082,
        "Name": "Vista Mar Playa Blanca",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Playa Blanca",
        "Coordinates": "28.8644895,-13.8314663"
    },
    {
        "Id": 2092,
        "Name": "Villa Primera",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Lanzarote, Calle Limones, 121, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8612987,-13.8328989"
    },
    {
        "Id": 2093,
        "Name": "Villa Mango",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8725553,-13.8336818"
    },
    {
        "Id": 2101,
        "Name": "Villa Emilia",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle la Concha, 49, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8763888,-13.8228024"
    },
    {
        "Id": 2108,
        "Name": "Ereza Aptos Sara",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle la Tegala, 4, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8628786,-13.8291208"
    },
    {
        "Id": 2110,
        "Name": "El Shangrila Park",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Suiza, 35580 Montaña Roja, Las Palmas, Spain",
        "Coordinates": "28.8671714,-13.866344"
    },
    {
        "Id": 2113,
        "Name": "Villas Playa Real",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Calle Lanzarote, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8628902,-13.8492335"
    },
    {
        "Id": 2120,
        "Name": "Villas Lanzasuites",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "Lanzarote, Av. de las Canarias, 29, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8570334,-13.8384901"
    },
    {
        "Id": 2500,
        "Name": "Las Terracitas Pl.Blanca",
        "Island": "LZ",
        "ZoneId": 407,
        "ZoneName": "Playa Blanca 1",
        "Address": "C. Madeira, 19, 35580 Playa Blanca, Las Palmas, España",
        "Coordinates": "28.858701112511298,-13.838467622095193"
    },
    {
        "Id": 1793,
        "Name": "Bahia Playa Blanca",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Urbanización Las Coloradas, 35570 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8627637,-13.7997111"
    },
    {
        "Id": 1801,
        "Name": "Las Marinas Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle las Palmeras, 3, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8609517,-13.7978534"
    },
    {
        "Id": 1808,
        "Name": "Costa Papagayo Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8682346,-13.82658"
    },
    {
        "Id": 1827,
        "Name": "Mirador Papagayo by Livvo",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Las Palmas, 5, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.858439,-13.804158"
    },
    {
        "Id": 1832,
        "Name": "Casa Nimbara",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Gaveta, 3D, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.858432,-13.809404"
    },
    {
        "Id": 1833,
        "Name": "Marina Rubicon",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "C.C. Marina Rubicón, Calle el Berrugo, nº 2, Local 51b-23a, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8594261,-13.8156473"
    },
    {
        "Id": 1857,
        "Name": "Bungalows Coloradamar",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle de las Buganvillas, 4, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.862151,-13.800135"
    },
    {
        "Id": 1862,
        "Name": "Casa Embajador",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "C/ Bienvenida Martín La Practicanta, 58, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8623817,-13.8274184"
    },
    {
        "Id": 1865,
        "Name": "Castillo Papagayo Dreamplace",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Playa de Afre, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8602478,-13.8009816"
    },
    {
        "Id": 1866,
        "Name": "Cay Beach Sun",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Las Breñas, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.868311,-13.8241959"
    },
    {
        "Id": 1874,
        "Name": "Coloradas Playa Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle los Llanos, 48, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8600046,-13.8032571"
    },
    {
        "Id": 1878,
        "Name": "Costa Papagayo II",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8682346,-13.82658"
    },
    {
        "Id": 1885,
        "Name": "Gran Castillo Tagoro Family & Fun",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Playa de Afre, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8602478,-13.8009816"
    },
    {
        "Id": 1889,
        "Name": "Villas Varadero",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "De la Rosa, s/n, Residencial El Varadero, 35570 ???? ??????, Las Palmas, Spain",
        "Coordinates": "28.8651364,-13.8028811"
    },
    {
        "Id": 1902,
        "Name": "H10 Lanzarote Princess",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Maciot, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8648295,-13.8254183"
    },
    {
        "Id": 1905,
        "Name": "H10 White Suites ",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Janubio, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8651943,-13.8217487"
    },
    {
        "Id": 1912,
        "Name": "Hotel Volcan Lanzarote",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Castillo del Águila, 1, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8578854,-13.8111277"
    },
    {
        "Id": 1917,
        "Name": "DREAMPLACE BOCAYNA VILLAGE",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle el Moral, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8639445,-13.8001966"
    },
    {
        "Id": 1949,
        "Name": "Mamma Mia Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Murillo, 1, 35570 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8640656,-13.8160433"
    },
    {
        "Id": 1965,
        "Name": "Papagayo Beach Resort Sandos",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle las Acacias, 6, 35580 Yaiza, Las Palmas, Spain",
        "Coordinates": "28.858985,-13.798635"
    },
    {
        "Id": 1967,
        "Name": "Paradise Villas Club",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "C. Las Mercedes s/n, Playa Blanca",
        "Coordinates": "28.873020533296728,-13.824578114000147"
    },
    {
        "Id": 1970,
        "Name": "Dreams Lanzarote Playa Dorada",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Av. de Papagayo, 24, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8630556,-13.8205556"
    },
    {
        "Id": 1978,
        "Name": "Princesa Yaiza Suite Hotel Resort",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Av. de Papagayo, 22, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.864388,-13.822251"
    },
    {
        "Id": 1979,
        "Name": "Pueblo Marinero",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Berrugo, 1, 35580 Playa Blanca, Las Palmas",
        "Coordinates": "28.865842902166253,-13.803759797825673"
    },
    {
        "Id": 1980,
        "Name": "Puerto Rubicon Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Calera, 11, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8615429,-13.8135273"
    },
    {
        "Id": 1990,
        "Name": "Cordial Marina Blanca",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Cocinilla, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8596744,-13.8128309"
    },
    {
        "Id": 2002,
        "Name": "Sun Grove Villas & SPA",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Callao Negro, 7, 15, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.875248,-13.8272641"
    },
    {
        "Id": 2003,
        "Name": "MYND Yaiza",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Janubio, 3, 35580 Playa Blanca, Las Palmas",
        "Coordinates": "28.867601584646092,-13.821433782577515"
    },
    {
        "Id": 2004,
        "Name": "Sun Tropical",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Fragata, 2, 35580 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.8713341,-13.8242868"
    },
    {
        "Id": 2007,
        "Name": "THB Royal",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Janubio, 5, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.869286,-13.821917"
    },
    {
        "Id": 2008,
        "Name": "THB Tropical Island",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Fragata, 2, 35580 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.8713341,-13.8242868"
    },
    {
        "Id": 2022,
        "Name": "Villas Brisa Marina",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8643263,-13.8308479"
    },
    {
        "Id": 2024,
        "Name": "Villas La Granja",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Cyca, 35580 Yaiza, Las Palmas",
        "Coordinates": "28.865285532145695,-13.802631497383118"
    },
    {
        "Id": 2027,
        "Name": "Villas del Sur",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Strelitzia, 35580 Yaiza, Las Palmas, Spain",
        "Coordinates": "28.862312,-13.8057895"
    },
    {
        "Id": 2050,
        "Name": "Villas Susaeta",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Callao Negro, 31, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8761926,-13.8273796"
    },
    {
        "Id": 2055,
        "Name": "Oceandreams Marina & Spa Villages",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Castillo del Águila, 2, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8570431,-13.8104934"
    },
    {
        "Id": 2063,
        "Name": "El Nido",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle las Palmeras, 4, 35580 Playa Blanca, Las Palmas",
        "Coordinates": "28.861726512975324,-13.79664499456497"
    },
    {
        "Id": 2076,
        "Name": "El Aulagar",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Aulaga, 35572 Tías, Las Palmas, Spain",
        "Coordinates": "28.9549333,-13.6523715"
    },
    {
        "Id": 2087,
        "Name": "Lanzarote Green Villas",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle Cyca, 35580 Playa Blanca, Las Palmas",
        "Coordinates": "28.865511032425722,-13.802883625030518"
    },
    {
        "Id": 2089,
        "Name": "VILLAS NOHARA",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Av. A Femés, 3, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8681342,-13.8259576"
    },
    {
        "Id": 2095,
        "Name": "Villa Laura",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Gaveta, 53A, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8585836,-13.8089866"
    },
    {
        "Id": 2100,
        "Name": "Laura Park",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Gaveta, 53A, 35580 Playa Blanca, Las Palmas, Spain",
        "Coordinates": "28.8585836,-13.8089866"
    },
    {
        "Id": 2121,
        "Name": "Villas Playa Blanca Beach Mate",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "C. Malvasía, 35580, Las Palmas",
        "Coordinates": "28.865834894602276,-13.80374162676162"
    },
    {
        "Id": 2122,
        "Name": "La Cala Suites Hotel",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "Calle la Cómoda, s/n, 35580 Lanzarote, Las Palmas, Spain",
        "Coordinates": "28.858000919558727,-13.808444381068714"
    },
    {
        "Id": 2222,
        "Name": "P.Bus C/Maciot esq. C/Las breÃ±as cerca stop",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "",
        "Coordinates": "28.8679166666667,-13.8251944444444"
    },
    {
        "Id": 2643,
        "Name": "Barceló Playa Blanca",
        "Island": "LZ",
        "ZoneId": 408,
        "ZoneName": "Playa Blanca 2",
        "Address": "28.863479717498812, -13.81754016368884",
        "Coordinates": "28.86338285405473,-13.81756067276001"
    },
    {
        "Id": 2116,
        "Name": "PUERTO ORZOLA",
        "Island": "LZ",
        "ZoneId": 412,
        "ZoneName": "Orzola",
        "Address": "35541 Órzola, Las Palmas, Spain",
        "Coordinates": "29.2226873,-13.4533444"
    },
    {
        "Id": 2065,
        "Name": "Club La Santa",
        "Island": "LZ",
        "ZoneId": 413,
        "ZoneName": "La Santa",
        "Address": "Avenida Krogager, s/n, 35560 Tinajo, Las Palmas, Spain",
        "Coordinates": "29.118652,-13.65055"
    }
];