import { AgmMarkerCluster 					} from '@agm/js-marker-clusterer';
import { MapService						    } from 'src/app/demo/service/mapservice';
import { EntityService 						} from '../../demo/service/entity.service';
import { CommonsService 					} from '../../demo/service/commons.service';
import { 	Component, 
         	Input, 
         	Output, 
         	EventEmitter,      
         	OnInit,
         	ViewChild,
		 	NgZone,
		 	ElementRef,
		 	PLATFORM_ID, 						
		 	Inject                          } from '@angular/core';
import { 	MapsAPILoader, 
			AgmCircle,
			AgmMap 							} from '@agm/core';
import { isPlatformBrowser 					} from '@angular/common';
import { LodgingService                     } from 'src/app/demo/service/lodging/lodging.service';

// declare var require: any
var fuzz 									= require('fuzzball');
declare var MarkerClusterer:any;
declare const google: any;

@Component({
  	selector    	: 'app-lodging-checker',
	templateUrl 	: './lodging-checker.component.html',
	styleUrls   	: ['./lodging-checker.component.scss'] ,
    // encapsulation	: ViewEncapsulation.None
})

export class LodgingCheckerComponent implements OnInit 
{
	@ViewChild('mainTable') 							mainTable 			: any;
	@ViewChild("searchAddress")  						searchOrigin		: ElementRef;
    @ViewChild('circle', 	{ read: AgmCircle       }) 	circle				: AgmCircle;
    @ViewChild('agmCluster',{ read: AgmMarkerCluster})	agmCluster			: any;
    // @ViewChild('map',     	{ read : AgmMap         })	map					: AgmMap;
    
	@ViewChild('streetviewMap')     					streetviewMap   	: any;
    @ViewChild('streetviewPano')    					streetviewPano  	: any;
    @ViewChild('searchLodging')    						searchLodging   	: ElementRef;
    @ViewChild('dropDownArea')      					dropDownArea    	: any;
    @ViewChild('inputFilterLodging')					inputFilterLodging 	: any;

    @Input()    field           : any;
    @Input()	booking        	: any   = null;
    @Input() 	lodgings        : any   = {};
    @Input()    aliases         : any   = {};
    @Input() 	areas          	: any	= {};
    @Output() 	emiterOption	: EventEmitter<any> = new EventEmitter();

    item        : any;
    mapInstance	: any;
    pageInfo 	: any = {
        place                   : {},
        tourinia_location       : '',
        tourinia_locations      : [],
        selectedFilter          : 'area',
		rangeDistance           : 200,
		indexTabView			: 0,
        displayRadius			: false,
		displayNearbyLodgings   : false,
		displayAddressMarker	: true,
        tableLodgings           : [],
		lodgingSelected         : {},
		addressSelected 		: {},
		nearbyLodgingSelected	: null,
        lodgingsSelecteds       : [],
        lodgingsInsideCircle    : [],
        lodgingsFilteredByArea  : [],
        draggableCircle         : true,
        listClusters            : [],
        inputSearchLodging      : null,
		toggleMapView			: { items: [], selected: [] },
		iconLodging : {
            url: 'assets/demo/images/maps/hotelMap.png',
            scaledSize: {
              width: 40,
              height: 50
            }
		},
		iconAddress : {
			url: 'http://www.gentechusa.com/wp-content/uploads/2015/09/marker.png',
            scaledSize: {
              width: 40,
              height: 50
            }
		}
    };

    // lodgings              :   any     = {};
    zoom                :   number  = 15;
    lat                 :   number  = 39.7714543;
    lng                 :   number  = 3.1455784 ;
    
	constructor(private commons 		: CommonsService, 
				private entityService 	: EntityService,
				private mapsAPILoader	: MapsAPILoader,
                private ngZone			: NgZone,
                private mapCtrl         : MapService,
                private lodgingService  : LodgingService,
				@Inject(PLATFORM_ID) private platformId: Object,
				){}

    async ngOnInit(){

        // this.ngZone.runOutsideAngular(() => {
        //     alert("1. NGZONE RUN");
        // });

        this.pageInfo.filters       = [     {label : await this.commons.getTranslate('_AREA'),     value : 'area'},
                                            {label : await this.commons.getTranslate('_DISTANCE'), value : 'distance'}];
        this.pageInfo.tableLodgings = [		
            { field: 'name',     	header: 'Name', 	width: '200px', 	align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},
            { field: 'area',		header: 'Area',     width: '100px',		align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},						
            { field: 'address',	    header: 'Address',  width: '150px', 	align: "center",    editor : 'input',       editable : true,  options : {type : 'text'},    mandatory : false		},
		];

		this.pageInfo.searchItem    = { step: 'hotel' };
		
        this.pageInfo.toggleMapView	= { items: [ 
											{ label: await this.commons.getTranslate('_MAP'), 			value: 'map' 		},
											{ label: await this.commons.getTranslate('_STREET_VIEW'), 	value: 'streetview' }
										], selected: 'map' };
        
        this.pageInfo.lodgingsFilteredByArea = this.lodgings.data;
    }

    ngOnChanges(data) 			{ 	
		console.log("DATA",data);
        if(data.booking && !data.booking.firstChange){
            this.restartForm();
        }
	}

	ngAfterViewInit()				{
        this.restartForm();
        // this.map.mapReady.subscribe(map => {
        //     this.listenGoogleMapDirections(); // to call the autocompletes            
        // });
        this.listenGoogleMapDirections(); // to call the autocompletes
    }
 
	onChangeZoomMap($event)		{ /*setTimeout(() =>{ this.mountCluster();},1000)*/ }
	
	listenGoogleMapDirections()		{
		this.mapsAPILoader.load().then(() => {
			this.listenAddressLodgings();   //  address
            this.listenGoogleLodgings();    // lodgings
		});
	}
	
    listenGoogleLodgings()			{
        let autocompleteLodging = new google.maps.places.Autocomplete(this.searchLodging.nativeElement);		
        autocompleteLodging.addListener("place_changed", () => {
              this.ngZone.runOutsideAngular(() => {
                switch(this.pageInfo.searchItem.step){
                    case "hotel"		: 
                        this.pageInfo.place = autocompleteLodging.getPlace();
                        console.log('PLACED ITEM ON HOTEL', this.pageInfo.place);
                        if (this.pageInfo.place.geometry === undefined || this.pageInfo.place.geometry === null) {
                            return this.pasteTitleLodging({step:'address',value:this.booking.addressInfo});								
                        }
                        this.mountLodgingFromGoogle(this.pageInfo.place);				
                        break;
                    case "address"	: 
                        this.pageInfo.place = autocompleteLodging.getPlace();
                        console.log('PLACED ITEM ON ADDRESS', this.pageInfo.place);
                        this.pageInfo.searchItem.step = "hotel";
                        if (this.pageInfo.place.geometry === undefined || this.pageInfo.place.geometry === null) { return; }
                        break;
                  }
              })
        });
    }

    /**
     * Get info from google
     */
    listenAddressLodgings(){
        let autocompleteOrigin = new google.maps.places.Autocomplete(this.searchOrigin.nativeElement);
        autocompleteOrigin.addListener("place_changed", () => {
            this.ngZone.runOutsideAngular(() => {
			    this.pageInfo.place = autocompleteOrigin.getPlace();
				this.pageInfo.searchItem.step 	= "hotel";
                if (this.pageInfo.place.geometry === undefined || this.pageInfo.place.geometry === null) { return; }
                // setTimeout(()=>{
                //     this.mountAddressFromGoogle(this.pageInfo.place);					
                // },1000)
                console.log("GOOGLE PLACE",this.pageInfo.place);
                this.mountAddressFromGoogle(this.pageInfo.place);	
            })
        });
    }

    async testQuery(){
        let result = await this.getPlacePredictions('Hotel Tonga');
    }

    getPlacePredictions(query: string): Promise<any> {
        let autocompleteSrv = new google.maps.places.AutocompleteService();
        return new Promise((resolve, reject) => {
            autocompleteSrv.getPlacePredictions({
                // types: ['geocode','establishment'],
                input: query,
                // componentRestrictions: { country: 'AR' }
            }, function (predictions, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(predictions);
                } else {
                    reject(status);
                }
            });
        });
    }   

    onWrittingLodging($event)   {   
        console.log(document.getElementsByClassName('pac-item-query'));         
    }
    improveSearch(type)         {   this.pasteTitleLodging({step : type, value : this.booking.location});   }
    getNearbyData( )            {
		let self 				= this;
		self.pageInfo.povPoints = [];
		let service 			= new google.maps.places.PlacesService(this.mapInstance);

		service.nearbySearch(
			{location: {lat : 39.549559, lng : 2.7357214}, radius: 500 },
			function(results, status, pagination) {
				if (status !== 'OK'){ return }
				self.pageInfo.povPoints = self.pageInfo.povPoints.concat(
						results.map(el => (
							{ ...el, 
								location : { 
									latitude	: el.geometry.location.lat(), 
									longitude 	: el.geometry.location.lng()
								}
							}
						))
                );	
                console.log(self.pageInfo.povPoints);
				setTimeout(() =>{ pagination.nextPage() }, 2000 );
			}
        );
        
    }

    mountLodgingFromGoogle(place){
        this.pageInfo.lodgingSelected               =   this.lodgingService.mountLodgingFromGoogle(place);
        this.lat	                            	=	place.geometry.location.lat();
        this.lng	                            	=	place.geometry.location.lng();
        this.pageInfo.displayNearbyLodgings     	=   false;
		
		this.searchOrigin.nativeElement.value   	=   place.formatted_address;
		this.pageInfo.indexTabView              	=   1;        
        this.dropDownArea.selectedOption			=	this.findArea({label : place.vicinity});
        if(this.dropDownArea.selectedOption){
            this.pageInfo.lodgingSelected.area			=	this.dropDownArea.selectedOption.label ? this.dropDownArea.selectedOption.label : null; 
        }else{
            this.pageInfo.lodgingSelected.area          =   null;
        }
	}

	mountLodgingFromDB(place){
		this.searchLodging.nativeElement.value		=	place.name;
		this.searchOrigin.nativeElement.value   	=   place.address;
		this.pageInfo.lodgingSelected.municipality	=	this.pageInfo.lodgingSelected.zipcode + ", " + this.pageInfo.lodgingSelected.area;			
		this.dropDownArea.selectedOption			=	this.findArea({label : this.pageInfo.lodgingSelected.area});
	}
	
    /**
     * 
     * @param place
     */
	mountAddressFromGoogle(place){
		this.pageInfo.lodgingSelected				=	{};
		this.pageInfo.addressSelected				=	place;
		this.pageInfo.addressSelected.latitude		=	place.geometry.location.lat();
		this.pageInfo.addressSelected.longitude		=	place.geometry.location.lng();
		this.lat									=	place.geometry.location.lat();
		this.lng									=	place.geometry.location.lng();
		this.pageInfo.addressSelected.municipality	=	place.address_components[place.address_components.length -1].long_name +", "
														+
														place.address_components[0].long_name;
		// this.searchOrigin.nativeElement.value   	=   place.formatted_address;
		this.dropDownArea.selectedOption			=	this.findArea({label : place.vicinity});
		this.pageInfo.addressSelected.area			=	this.dropDownArea.selectedOption ? this.dropDownArea.selectedOption.label : null;
		this.pageInfo.displayNearbyLodgings    		=   false;
		this.pageInfo.indexTabView      			=   1;

	}

	getPositionCircle(){
		if(!this.pageInfo.lodgingSelected && !this.pageInfo.addressSelected) return {};
		// if((this.pageInfo.lodgingSelected == {}) && (this.pageInfo.addressSelected=={})) return {};
		return this.pageInfo.lodgingSelected.latitude 	? 	{lat : this.pageInfo.lodgingSelected.latitude,lng	: this.pageInfo.lodgingSelected.longitude}
														: 	{lat : this.pageInfo.addressSelected.latitude,lng	: this.pageInfo.addressSelected.longitude}
	}

	loadStreetMapView(){
		if(isPlatformBrowser(this.platformId)){
			this.mapsAPILoader.load().then(() => {		
				let center 			= { lat: this.lat, lng: this.lng };
				let map 			= new window['google'].maps.Map(this.streetviewMap.nativeElement, { center: center, zoom:11 , scrollwheel: true });
				let panorama 		= new window['google'].maps.StreetViewPanorama(
				this.streetviewPano.nativeElement, {
					position		: center,
					pov				: { heading: 20, pitch: 5 },
					scrollwheel		: true
				});
				var icon 			= {
					url				: "https://images.vexels.com/media/users/3/142675/isolated/preview/84e468a8fff79b66406ef13d3b8653e2-house-location-marker-icon-by-vexels.png", // url
					scaledSize		: new google.maps.Size(50, 50), // scaled size
					origin			: new google.maps.Point(0,0), // origin
					anchor			: new google.maps.Point(0, 0) // anchor
				};
				var lodging 		= new google.maps.LatLng(this.lat, this.lng);
				var lodgingMarker 	= new google.maps.Marker({
					position		: 	lodging,
					map				: 	panorama,
					icon			:	icon,
					title			: 	'Lodging'
				});
				map.setStreetView(panorama);
			});
		}
	}

    mountCluster(){
        try{
            // const clusterInfo = this.agmCluster._clusterManager._clustererInstance.__zone_symbol__value.clusters_.map((cluster,i) => {
            //    return { index : i, markers : cluster.markers_.map(marker => this.lodgings.data.find(lodging => lodging.latitude == marker.position.lat()))}})
            // this.pageInfo.listClusters = clusterInfo;            
        }catch(e){
            // this.pageInfo.listClusters = [];
            console.error(e);
        }
	}
	
	onChangeTabView($event){
        // this.agmCluster
        // this.mountCluster();
		this.pageInfo.indexTabView = $event.index; 

        switch($event.index){
            case 0: alert("TAB 0");             break;
            case 1: alert("TAB 1");             break;
            case 2: this.loadStreetMapView();   break;
        }
	}

    /**
     * update action
     * 
     * @returns 
     */
    private update(){
        let location:any = {};

        if(undefined==this.pageInfo.lodgingSelected || Object.keys(this.pageInfo.lodgingSelected).length==0){
            if(undefined==this.pageInfo.tourinia_location || this.pageInfo.tourinia_location==""){
                this.commons.generateToast("_ERROR","_NO_LOCATION_SELECTED","error");
                return false;
            }
        } else {
            location        = JSON.parse(JSON.stringify(this.pageInfo.lodgingSelected));        
        }

        location.field                  = this.field;
        location.lodging_tourinia_name  = this.pageInfo.tourinia_location;
        location.lodging_google_name    = location.name;
        location.booking                = this.booking;

        this.restartForm();
        this.emiterOption.emit({action: 'accept', data : location });
    }

    private cancel(){
        this.restartForm();
        this.emiterOption.emit({action: 'reject'});
	}

    private createLodgingALias(){
        if(null==this.pageInfo.lodgingSelected || undefined==this.pageInfo.lodgingSelected){
            alert("Select a lodging");
            return false;
        }
        const lodgingSelected = JSON.parse(JSON.stringify(this.pageInfo.lodgingSelected));
        if(undefined==lodgingSelected){
            alert("Select a lodging");
            return false;
        }
        this.restartForm();
        alert("Crearing alias for "+this.pageInfo.lodgingSelected+" with value "+this.booking.location);
        this.emiterOption.emit({action: 'create_alias', lodging: lodgingSelected, alias: this.booking.location});
    }
	
    /**
     * copy booking location into google search
     * 
     * @param $item 
     * @returns 
     */
    pasteTitleLodging($item){
        console.log(this.booking);
		if(undefined===$item || undefined===$item.step ){
			this.commons.generateToast('Error','Item not found','error');
			return false;
        }
        this.pageInfo.searchItem.step = $item.step;
        switch($item.step){
            case    'hotel'     :
                $item.value                             =   this.lodgingService.fixFormatText($item.value);
                this.booking.location                   =   $item.value;
                let infoQuery                           =   this.lodgingService.getMinimalFactibleValue($item.value, this.pageInfo.currentItemSplitted);
                this.pageInfo.currentItemSplitted       =   infoQuery.currentItemSplitted;
                // this.pageInfo.currentItemSplitted       =   false;
                this.searchLodging.nativeElement.value 	=   infoQuery.query //this.pageInfo.searchItem[this.pageInfo.searchItem.step];
                
                setTimeout(()=>{
                    this.searchLodging.nativeElement.focus(); 
                    this.onWrittingLodging(null);
                },550);            
                break;

            case    'lodging'   :
                this.searchOrigin.nativeElement.value   = $item.value;
                this.searchOrigin.nativeElement.focus()
                break;

            default : return;
        }
    }

    selectArea($event){
        this.pageInfo.lodgingsFilteredByArea = 
        this.lodgings.data.filter(lodging => 
            lodging.area.toLowerCase() == $event.value.name.toLowerCase());
        
    }

    toggleLodging(rowData){
        rowData                             =   rowData.originalEvent ? rowData.value : rowData;
        this.pageInfo.lodgingSelected       =   rowData;
		this.pageInfo.displayNearbyLodgings =   false;
		this.mountLodgingFromDB(rowData);
        this.lat	=	rowData.latitude;
		this.lng	=	rowData.longitude;
      
        this.pageInfo.indexTabView = 1;
        // this.checkLodgingsInsideCircle();
	}
    
    async changeRadiusCircle($event, radius){
        this.circle.radius = $event.fromInput ? (this.pageInfo.rangeDistance * 1000) : $event;
        await this.checkLodgingsInsideCircle();
    }

    dragStartCircle($event, radius)		{ console.log('START DRAGGING', $event, radius); }
    async dragEndCircle($event, radius)	{ await this.checkLodgingsInsideCircle();	}

    async displayLodgingsInsideCircle(){
        this.pageInfo.displayNearbyLodgings = !this.pageInfo.displayNearbyLodgings;
        if(this.pageInfo.displayNearbyLodgings){
          await this.checkLodgingsInsideCircle()  
        } else {
            this.pageInfo.lodgingsInsideCircle = [];
        }
	}
	
    async checkLodgingsInsideCircle(){
		const centerCircle = await this.circle.getCenter();
        this.pageInfo.lodgingsInsideCircle = [];
        this.mapsAPILoader.load().then(()=>{
            const center                        = new google.maps.LatLng(centerCircle.lat(), centerCircle.lng());

            this.pageInfo.lodgingsInsideCircle  = this.lodgings.data.filter(hotel =>{ 
                if(hotel.id == this.pageInfo.lodgingSelected.id) { return false;};
                const marker        = new google.maps.LatLng(hotel.latitude,hotel.longitude);
                const distanceInKm  = google.maps.geometry.spherical.computeDistanceBetween(center,marker);
                return distanceInKm < this.circle.radius; 
            }); 
            this.pageInfo.filteredLodgingsInsideCircle = this.pageInfo.lodgingsInsideCircle;                
        });
	}
	
    rightClickCircle($event)		{ console.log('right click', $event);							}
	markRadius()					{ this.pageInfo.displayRadius = !this.pageInfo.displayRadius;	}
	markNearbyLodging(lodging)		{
        // let tmp = lodging.selected;
        // this.pageInfo.lodgingsInsideCircle.map(lodging => lodging.selected = false);
        // lodging.selected = tmp ? false : true;
        lodging.selected = !lodging.selected;
        if(lodging.selected){
            this.pageInfo.nearbyLodgingSelected     =   lodging;
            this.lat                                =   this.pageInfo.nearbyLodgingSelected.latitude;
            this.lng                                =   this.pageInfo.nearbyLodgingSelected.longitude;
            this.zoom                               =   18;
            this.pageInfo.draggableCircle           =   false;
        }else{
            this.pageInfo.nearbyLodgingSelected     =   null;
        }
	}

	setCurrentLodging()				{}

	markLodgingAsActive()			{
		this.pageInfo.addressSelected				=	null;
		this.pageInfo.displayAddressMarker			=	false;
		this.pageInfo.lodgingSelected 			    = 	this.pageInfo.nearbyLodgingSelected;
		this.pageInfo.nearbyLodgingSelected			=	null;
		this.pageInfo.displayNearbyLodgings 	    =   false;
		this.searchLodging.nativeElement.value	    =   this.pageInfo.lodgingSelected.name;
		this.searchOrigin.nativeElement.value       =   this.pageInfo.lodgingSelected.address;
		this.pageInfo.lodgingSelected.municipality	=	this.pageInfo.lodgingSelected.zipcode + ", " + this.pageInfo.lodgingSelected.area;			

        this.dropDownArea.selectedOption			=	this.findArea({label : this.pageInfo.lodgingSelected.area});
        this.pageInfo.filteredLodgingsInsideCircle.map(el => el.selected = false);
        this.checkLodgingsInsideCircle();
    }
    
    findArea(item){
        const splittedItem	= item.label.toString().split(',');
        console.log('DROP DOWN AREA',this.dropDownArea);
        if(this.dropDownArea && this.dropDownArea.options){
            const areaFinded 	= this.dropDownArea.options.find( area => {	return area.label == item.label || splittedItem.find(el => fuzz.ratio(area.label,el) > 75)});
            return areaFinded ? areaFinded : null;

        }else{
            return null;
        }
    }
    
    searchFilterLodging($event){
        this.pageInfo.filteredLodgingsInsideCircle =  $event != "" ? this.pageInfo.lodgingsInsideCircle.filter(item => {return item.name.toLowerCase().includes($event.toLowerCase())})
                                                                    : this.pageInfo.lodgingsInsideCircle;
    }

    getCurrentSearchLodging(){
        if(!this.inputFilterLodging) return false;
        return this.inputFilterLodging.nativeElement.value;
    }

    restartFilterLodging(){
        this.pageInfo.filteredLodgingsInsideCircle  = this.pageInfo.lodgingsInsideCircle;
        this.inputFilterLodging.nativeElement.value = null;
    }

	restartForm($copy=true){
        this.pageInfo.selectedFilter 			    = 	'area';
		this.pageInfo.rangeDistance				    =	200;
		this.pageInfo.indexTabView				    =	0;
		this.pageInfo.displayRadius				    =	false;
		this.pageInfo.displayNearbyLodgings		    =	false;
		this.pageInfo.displayAddressMarker		    =	true;
		// this.pageInfo.tableLodgings				=	[];
		this.pageInfo.lodgingSelected			    = 	null;
		this.pageInfo.addressSelected			    =	null;
		this.pageInfo.nearbyLodgingSelected 	    =	null;
		this.pageInfo.lodgingsSelecteds			    =	[];
		this.pageInfo.lodgingsInsideCircle		    =	[];
		this.pageInfo.lodgingsFilteredByArea	    =	[];

        let copyValue;
        switch($copy){
            case true   : copyValue = this.booking.location; break;
            default     :
            case false  : copyValue = null; break;
        }

        // Copy booking location into tourinia hotel and google hotel
        this.pageInfo.tourinia_location =   copyValue;
        if(this.searchLodging.nativeElement){
            this.searchLodging.nativeElement.value	=   copyValue;
            this.searchOrigin.nativeElement.value   =   copyValue;
        }
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}
	
    getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: switch($col.field) {
                                                case 'profile'          : return $items[$col.field];
                                                case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
												default 		 		: return $items[$col.field]}
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
    }

    doAction($type,$info){
        switch($type){           
            case "lodging": 
                switch($info.action){
                    case "copy"         :   this.pasteTitleLodging({ step: 'hotel', value: $info.value}); 
                                            //this.pasteTitleLodging({ step: 'lodging', value: $info.value});
                                            this.pageInfo.tourinia_location = $info.value;
                                            break; 
                    
                    case "search"       :   // No results if entry length is small
                                            if((this.pageInfo.tourinia_location||"").length<3){
                                                this.pageInfo.tourinia_locations = [];
                                            }
                                            this.pageInfo.tourinia_locations = this.lodgings.data.filter(item=>
                                                    item.name.toLowerCase().includes(this.pageInfo.tourinia_location.toLowerCase())
                                                ).map(item=>{
                                                    item.label  = item.name;
                                                    return item;
                                                })
                                                .sort((a,b)=>a.label>b.label?1:-1);
                                            break;

                    case "autocomplete" :   
                        this.onWrittingLodging($info.event);
                        break;
                    case "update"       :   this.update();              break;
                    case "cancel"       :   this.cancel();              break;
                    case "create_alias" :   this.createLodgingALias();  break;
            }
        }
    }
}

