// FILTER SUPPORT -----------------------------------------------------------------------

export const initFilters = (me)=>{
	me.entities.forEach(entity=>{
		console.log("Init Filter for ",entity);
		me[entity].filters = me[entity].filters || [];
		me[entity].filters.forEach(filter=>me.checkFilter(filter));
	});
}

export const checkFilter = (me,filter)=>{
	console.log("Checking Entity",filter.entity,"Filter",filter.name);
	switch(filter.type){
		case "multiple"		:	checkMultipleFilter(me,filter);	break;
		default				: 	checkSimpleFilter(me,filter);	break;
	}
}

export const checkMultipleFilter = (me,filter)=>{
	if(	undefined===filter.entity 		||
		undefined===me[filter.entity] ||
		undefined===filter.name
	){
		console.log("OOPS. Invalid filter",filter);
		return false;
	}

	me[filter.entity].activeFilters 				= me[filter.entity].activeFilters || {};
	me[filter.entity].activeFilters[filter.name] 	= {
														field	: filter.field,
														options	: filter.items.map(item=>item.value),
														selected: filter.selected
													};

	me.filterEntity(filter.entity);
}

export const checkSimpleFilter = (me,filter)=>{
	if(	undefined===filter.entity 		||
		undefined===me[filter.entity] ||
		undefined===filter.name 		||
		undefined===filter.status
	){
		console.log("OOPS. Invalid filter",filter);
		return false;
	}
	me[filter.entity].activeFilters 				= me[filter.entity].activeFilters || {};
	me[filter.entity].activeFilters[filter.name] 	= filter.status;

	me.filterEntity(filter.entity);
}

export const filterEntity = (me,$entity)=>{
	let data 					= me[$entity].data				|| [];
	let filters 				= me[$entity].activeFilters 	|| {};
	me[$entity].filteredData	= filterData(me,data,filters);
	me[$entity].count 			= me[$entity].filteredData ? me[$entity].filteredData.length : 0;

	switch($entity){
		case "groups":			
			me["arrivals"	].draggableSet	= me[$entity].count==0?[]:me[$entity].filteredData[0].items.filter(item=>item.type=="arrival");
			me["departures"	].draggableSet	= me[$entity].count==0?[]:me[$entity].filteredData[0].items.filter(item=>item.type=="departure");
			break;
	}
}

export const filterData = (me,data,filters)=>{
	// AT LEAST ONE FILTER
	if ( Object.keys(filters).length>0 ) {
		Object.keys(filters).forEach(item=>{

			//alert("FILTER["+$entity+"]="+item);
			let selected	= filters[item].selected;
			let options 	= filters[item].options;
			let inverted 	= options.filter(item=>selected.indexOf(item)<0);
			let field		= filters[item].field;

			switch(item){
				case "zones":
					console.log("Filtering zones");
					['arrivals','departures'].forEach(direction=>{
						me[direction].draggableSet = me[direction].draggableSet.filter(group=>{
							selected.reduce((result,filter)=>result || group.zone===filter,false);
						});
					});
			}
		});
	}
	return data;
}