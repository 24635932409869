export const flights = [
	{ 	
		direction	: "arrival",
		code		: "EZY6039",
		company		: "Easyjet",
		date		: "20230305",
		departure	: "15:03",
		arrival 	: "10:31",
		destCode	: "PMI",
	}
];

export const _flights = {
	"EZY422-1579412772-airline-0317:2":{
		"altitude"		:0,
		"coord"			:[-2.7427,51.3818],
		"groundspeed"	:122,
		"heading"		:86,
		"landingTimes"	:{
			"scheduled"			:null,
			"estimated"			:1579600200,
			"actual"			:1579600200
		},
		"takeoffTimes":{
			"scheduled"			:null,
			"estimated"			:null,
			"actual"			:1579597263
		},
		"timestamp":1579600289,
		"destination":{
			"TZ"				:":Europe/London",
			"isValidAirportCode":true,
			"altIdent"			:"BRS",
			"iata"				:"BRS",
			"friendlyName"		:"Int'l de Bristol",
			"friendlyLocation"	:"Bristol, United Kingdom",
			"coord"				:[-2.7191,51.3827],
			"icao"				:"EGGD",
			"gate"				:null,
			"terminal"			:null,
			"delays"			:null
			},
		"displayIdent"	:"EZY422",
		"ga"			:false,
		"historical"	:null,
		"icon"			:"airliner",
		"ident"			:"EZY422",
		"origin"		:{
			"TZ"				:":Europe/London",
			"isValidAirportCode":true,
			"altIdent"			:"EDI",
			"iata"				:"EDI",
			"friendlyName"		:"Edimburgo",
			"friendlyLocation"	:"Edinburgh, United Kingdom",
			"coord"				:[-3.3725,55.9500],
			"icao"				:"EGPH",
			"gate"				:null,
			"terminal"			:null,
			"delays"			:null
			},
		"type"			:"A319",
		"waypoints"		:[]
	}
};