import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {DialogModule} from 'primeng/dialog';


@Component({
    selector   : 'app-list-messages',
    templateUrl: './listMessages.component.html',
    styleUrls:['./listMessages.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})
export class ListMessagesComponent implements OnInit{
    
    @Input()    messages   : any[]             = [];
    @Input()    visible    : boolean           = false;
    @Output()   onClose    : EventEmitter<any> = new EventEmitter();

    pageInfo : any = {
        visible : true
    }
    ngOnInit(): void {
      
    }

    test($event){
        console.log('======>',$event);
        this.onClose.emit(false);
    }
}