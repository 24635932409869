import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector   : 'app-tour-panel',
    templateUrl: './tourPanel.component.html',
    styleUrls:['./tourPanel.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class TourPanelComponent implements OnInit{

    // @Input()    transfers : any[]             = [];
    // @Output()   toggled   : EventEmitter<any> = new EventEmitter();
    
    arrivalActived:boolean    	= true;
    pickupActived:boolean    	= true;

    ngOnInit(){}
}