import { CompanyService } from './../../../demo/service/database/company.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { FirebaseService 		} from 'src/app/demo/service/database/firebase.service';
import { StorageService 		} from './../../../demo/service/storageservice';
import { detailItems, entities 	} from './data/items';
import { DestinationsService } from '../../../demo/service/database/destinations.service';

@Component({
  selector    		: 'app-company-destinations',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyDestinationsComponent {

	@Output()			emitter			= new EventEmitter<any>();	
	
	userInfo			: any =	{};    
	pageInfo  			: any = {
		position		: { lng: 2.731309, lat: 39.547789, zoom: 12 },
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { 
			staff		: {}, 
			countries	: {}, 
			destinations: {}, 
			stations	: {}, 
			profiles	: {} 
		},
		loading			: {
			stations	:	false,
		},
		destination		: { form: { ready: false }, tableStations: { ready: false }, tableMeetingPoints: { ready: false }},
		
		
	}
	  
	constructor( 
		private commons				: CommonsService,
		private entityService		: EntityService,
		private firebaseCtrl		: FirebaseService,
		private destinationCtrl		: DestinationsService,
		private companyCtrl			: CompanyService,
		private storage 			: StorageService
  	) {}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_STATIONS'		),	icon : 'fa fa-plane'	 		, command: ($event)=> { this.setTabItem('main',	$event)} 	},
					// { id:1, label: this.commons.getTranslate('_MAP'				),	icon : 'fa fa-map-marker' 		, command: ($event)=> { this.setTabItem('main',	$event)} 	},
					// { id:2, label: this.commons.getTranslate('_STATS'			),	icon : 'fa fa-user'		 		, command: ($event)=> { this.setTabItem('main',	$event)} 	}
				],
				selected		: 0
			}
		};
		this.pageInfo.buttons		= [
			{ 	name: 'reload',			label: '_RELOAD', 			icon: 'fa fa-refresh' 		, type: 'button'	},
			{ 	name: 'new',			label: '_CREATE',	 		icon: 'fa fa-plus' 			, type: 'button' 	},
			// { 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	}			
		];
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.items			= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});		

		this.pageInfo["destination"].tableStations = {
			ready	: false,
			entities: entities,
			form	: [
				{
					cols		: 12,
					showTitle	: false,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'name', 		label:'_NAME',		type: 'text',	editor: 'text',			editable	: true	},
						{ mandatory: true, 	field	: 'type', 		label:'_TYPE',		type: 'text',	editor: 'autocomplete',	editable	: true, entityList: 'stationTypeList' },
						{ mandatory: true, 	field	: 'longitude',	label:'_LONGITUDE',	type: 'text',	editor: 'number',		editable	: true	},
						{ mandatory: true, 	field	: 'latitude', 	label:'_LATITUDE',	type: 'text',	editor: 'number',		editable	: true	}						
					]
				}						
			]
		};

		this.pageInfo["destination"].tableMeetingPoints = {
			ready	: false,
			entities: entities,			
			form	: [
				{
					cols		: 12,
					showTitle	: false,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'name', 		label:'_NAME',		type: 'text',	editor: 'text',		editable	: true	},
						{ mandatory: true, 	field	: 'longitude',	label:'_LONGITUDE',	type: 'text',	editor: 'number',	editable	: true	},
						{ mandatory: true, 	field	: 'latitude', 	label:'_LATITUDE',	type: 'text',	editor: 'number',	editable	: true	}						
					]
				}						
			]
		};
	}

	async loadEntities()		{ 	this.userInfo	=	( this.storage.getItem('dmcSuite') || {}).userInfo || {}; 

									await this.load("profiles");
									await this.load("countries");
									await this.load("destinations");
									await this.load("stations");

									await this.load("destination");

									await this._load('destinations');
									console.log('LIST ENTITIES', this.pageInfo.entities);

								}

  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();		
	}

    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

	onChangeZoomMap($event)		{ /*setTimeout(() =>{ this.mountCluster();},1000)*/ }

	doAction($type,$item)	{
		switch($type){
			case "toggleCountry"		:	( this.pageInfo.entities["countries"].data || [])
												.forEach(item=>{ item.openned = item.id==$item.id
																				?(undefined==$item.openned?true:!$item.openned)
																				:false 
												});	
											this.pageInfo.countrySelected = $item.openned ? $item.value : null; 										
											break;
			case "toggleStation"		:	$item.openned = undefined==$item.openned?true:!$item.openned;				break;
			case "toggleMeetingPoint"	:	$item.openned = undefined==$item.openned?true:!$item.openned;				break;
			case "selectDestination"	:	break;
			case "table"				:	switch($item.type){
												case "new"			:	this.commons.generateToast("NEW","NEW","info");	break;
												case "reload"		:	this.loadEntities();							break;
											}
											break;
			case "button"				:	switch($item.name){		
												case "new"			: 	//this.emitter.emit({ action: 'new' });
																		this.pageInfo.entities[$item.entity].data.unshift({});
																		this.pageInfo.entities[$item.entity].count = this.pageInfo.entities[$item.entity].data.length;
																		this.pageInfo.paginate($item.entity,null);
																		break;
												case "reload"		: 	this.loadEntities();												break;
												case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
												case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
											}
											break;
		}
	}

	getFilteredEntity($entity,$info=undefined){
		switch($entity){
			case "stations"			: 	return (this.pageInfo.destination.tableStations.data		|| []).filter(item=>{return true;});
			case "meetingPoints"	: 	return (this.pageInfo.destination.tableMeetingPoints.data	|| []).filter(item=>{return true;});
			
			case 'formStations'		:	return 	{ 	
													info	: $info, 
													entities: this.pageInfo["destination"].tableStations.entities,
													form	: this.pageInfo["destination"].tableStations.form 
												};
			case 'formMeetingPoints':	return 	{ 	
													info	: $info, 
													entities: this.pageInfo["destination"].tableMeetingPoints.entities,
													form	: this.pageInfo["destination"].tableMeetingPoints.form 
												};
			case "countries"		:	return this.pageInfo.entities[$entity].data;
			case "destinations"		:	let country = this.pageInfo.entities['countries'].data.find(item=>item.openned);
										return undefined==country? [] : this.pageInfo.entities[$entity].data.filter(item=>item.country==country.name);
		}
	}
	
	async load($entity){
		let response = {};
		switch($entity){						
			case 'profiles'			:
				response								= await Promise.resolve(this.entityService.getRequest('profiles',{ type: 'dmc' }));
				this.pageInfo.entities[$entity].data	= response["data"].map(item=>{ item.text = this.commons.getTranslate(item.text); return item; });
				console.log('PROFILES', this.pageInfo.entities[$entity].data );
				// Refresh dropdown filter
				this.pageInfo.filters[3].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.text, value: item.name }));
				this.paginate($entity,null);
				break;			

			case 'countries'		:					// FROM DB
				response								= await Promise.resolve(this.entityService.getRequest('countries',{ dmc: 1 }));
				this.pageInfo.entities[$entity].data	= response["data"].filter(item=>item.id>1);	// Avoid unassigned				
				// this.pageInfo.entities[$entity].data	= [{ id: 1, active: true, name: 'Spain', openned: true }];
				console.log('DESTINATIONS DMC',this.pageInfo.entities[$entity].data);
				// Refresh dropdown filter
				this.pageInfo.filters[0].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id }));
				this.paginate($entity,null);
				break;
			
			case 'destinations'		:					// FROM DB
				response								= await Promise.resolve(this.entityService.getComplexRequest('entity',{ entity: 'destination' },{}));
				this.pageInfo.entities[$entity].data	= response["data"].map(item=>{ item.selected = item.id==1; return item; });
				console.log('DESTINATIONS DMC',this.pageInfo.entities[$entity].data);
				// Refresh dropdown filter
				this.pageInfo.filters[1].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id, selected: item.id==1 }));
				this.paginate($entity,null);
				break;

			case 'stations'			:	
				this.pageInfo.entities[$entity].data	= [{ id: 1, active: true, destination: 1, name: 'Airport PMI Son Sant Joan' }];
				this.pageInfo.entities[$entity].count	= this.pageInfo.entities[$entity].data ? this.pageInfo.entities[$entity].data.length : 0;
				// Refresh dropdown filter
				this.pageInfo.filters[2].items			= this.pageInfo.entities[$entity].data.map(item=>({ label: item.name, value: item.id }));
				this.paginate($entity, null);
				console.log('STATIONS DMC',this.pageInfo.entities[$entity].data)				
				break;
				
			case "destination"		:
				response 											= 	await Promise.resolve(this.entityService.getComplexRequest("companyInfo",{ id: 1 },{}));
				this.pageInfo.destination.form.info					= 	response["data"];
				this.pageInfo.destination.form.ready				=	true;

				this.pageInfo.destination.tableStations.data		=	[ 	{ 	
																				id			: 1,
																				active		: true,
																				selected	: true,
																				name		: 'Aeropuerto Palma de Mallorca (PMI)',
																				type		: "_AIRPORT",
																				longitude	: '2.728511', 
																				latitude	: '39.546667' 
																			},
																			{ 	
																				id			: 2,
																				active		: true,
																				name		: 'Puerto Palma',
																				type		: "_PORT",
																				longitude	: '2.626603', 
																				latitude	: '39.552812' 
																			},
																			{ 	
																				id			: 3,
																				active		: true,
																				name		: 'Estación intermodal',
																				type		: "_TRAIN",
																				longitude	: '2.654289', 
																				latitude	: '39.576428' 
																			}
																		].map(item=>{ 
																			item["i18nType"]	= this.commons.getTranslate(item.type); 
																			switch(item.type){
																				case "_AIRPORT"	: item["icon"]	= "fa-plane"; 	break;
																				case "_PORT"	: item["icon"]	= "fa-ship"; 	break;
																				case "_TRAIN"	: item["icon"]	= "fa-train"; 	break;
																				case "_BUS"		: item["icon"]	= "fa-bus"; 	break;
																			}
																			item["markerIcon"]	= {
																				url			: 'assets/layout/icons/luggage.png',
																				scaledSize	: { width: 40, height: 30 }
																			};							
																			return item; 
																		});
				this.pageInfo.destination.tableStations.ready		=	true;				
				this.pageInfo.destination.tableMeetingPoints.data	=	[	{ id: 1, active: true, name: 'Caseta Entrada', 		longitude: '2.730926', latitude: '39.548047' },
																			{ id: 2, active: true, name: 'Caseta Terminal C', 	longitude: '2.736269', latitude: '39.549619' }
																		].map(item=>{ 
																			item["icon"] 		= "fa-home";
																			item["markerIcon"]	= {
																				url			: 'assets/layout/icons/meeting_point.png',
																				scaledSize	: { width: 30, height: 30 }
																			};							
																			return item; 
																		});;
				this.pageInfo.destination.tableMeetingPoints.ready	=	true;
				break;
		}
	}

	async _load($entity){
		this.pageInfo.entities[$entity] 		= this.pageInfo.entities[$entity] || {};
		this.pageInfo.entities[$entity].data 	= this.pageInfo.entities[$entity].data || {};
		switch($entity){
			default					:	console.log('Entity not found', $entity); return;
			case	'destinations'	:	await this.loadDestinationsFromDmc($entity);
										this.mountCountries();

					console.log('DESTINATIONS COMPANY CTRL', this.pageInfo.entities[$entity].data);							
			break;
		}  
	}
	paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this.pageInfo.entities[$type].data ? 0 : this.pageInfo.entities[$type].data.length;
        this.pageInfo.entities[$type].paginateInfo 	= $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this.pageInfo.entities[$type].paginate 		= !this.pageInfo.entities[$type].data
            ? []
            : this.pageInfo.entities[$type].data.slice(
                this.pageInfo.entities[$type].paginateInfo.first,
                this.pageInfo.entities[$type].paginateInfo.first + this.pageInfo.entities[$type].paginateInfo.rows
            );
	}
	
	setLoading(entity,value){	this.pageInfo.loading[entity] = value; }

	/**
	 * Save the info from the dmc and add the generic information from the destination. It uses to create correctly the working stations.
	 * @param $entity entity to save the info
	 */
	async loadDestinationsFromDmc($entity){
		this.pageInfo.entities[$entity].data		=	await this.companyCtrl.getDestinationsFromDmc(this.userInfo.idDmc);
		this.pageInfo.entities[$entity].data		=	await Promise.all(this.pageInfo.entities[$entity].data.map( async destination =>{
																												let data	: any =	await this.firebaseCtrl.getDataByRef(destination.refDestination);
																												return {...	destination, 
																															idDestination 	: data.id, 
																															img 			: data.img, 
																															customCreation	: data.customCreation || false,
																															refAreas 		: data.refAreas	}
																											}));
	}
	/**
	 * Get all the countries from destinations
	 */
	mountCountries(){
		this.pageInfo.entities['countries'].data = Array.from(new Set(this.pageInfo.entities['destinations'].data.map(dest => dest.country))).map(item => {return {value : item, name : item}})
	}

	clickCountry(){

	}
	async handleTreeAction($event){
		switch($event.action){
			case 'toggleCountry'		:	this.pageInfo.countrySelected	=	$event.item;
			case 'toggleDestination'	:	await this.clickDestination($event.item);	break;
		}
	}
	/**
	 * click the destination from the tree view
	 */
	async clickDestination(destination){
		console.log(destination);
		this.setLoading('stations', true);
		this.pageInfo.displayStations 		= false;
		this.pageInfo.destinationSelected 	= destination ? destination : null;
		this.pageInfo.destinationSelected 	? await this.loadStations() : null;
		this.setLoading('stations', false);
		this.pageInfo.displayStations 		= true;
	}
			/**
	 * Load the stations and polygons from the destination selected. We can discuss how to improve the loading time
	 */
	async loadStations(){

		// this.setLoading('stations',true);
		console.log('destinationSelected:',this.pageInfo.destinationSelected)
		let areas	=	await this.destinationCtrl.getAreasFromDestination(this.pageInfo.destinationSelected.refAreas);
		console.log(areas);
		
		this.pageInfo.infoAreas	=	{
			id			:	this.pageInfo.destinationSelected.id,
			areas		:	areas,
			stations	:	await this.destinationCtrl.loadStationsFromAreas(this.pageInfo.countrySelected.value,areas) // must filter all areas with stations
		}
		// this.setLoading('stations',false);
	}

	async handleStationsAction($event){
		let callUpdate : any;
		switch($event.type){
			default		:	return;
			case 'save'	:	// $event is the array of stations
							let updatedAreas		=	$event.data.map(station => {return { type : station.type, items : station.items.filter(el => el.selected)}});
							callUpdate				=	await this.firebaseCtrl.updateItemByRef(this.pageInfo.destinationSelected.ref,{workingStations : updatedAreas});
							callUpdate.success		?	this.commons.generateToast('_SUCCESS','_LIST_UPDATED','success')
													: 	this.commons.generateToast('_ERROR','_STATIONS_PERSIST_ERROR','error');
							// await this.restartViewStations();
							return;	
		}
	}

	/**
	 * Restart the data and view from the destination selected and stations
	 */
	async restartViewStations(){
		this.pageInfo.displayStations 		= false;
		const countrySelected 				=	this.pageInfo.countrySelected;
		const destSelected					=	this.pageInfo.destinationSelected.id;
		this.pageInfo.countrySelected 		= 	null;
		this.pageInfo.destinationSelected 	=	null;
		
		await this._load('destinations');
		this.doAction('toggleCountry',this.pageInfo.entities['countries'].data.find(country => country.name == countrySelected))
		await this.clickDestination(this.pageInfo.entities['destinations'].data.find(dest => dest.id == destSelected));

		this.pageInfo.displayStations 		= true;
	}
}