export const entities 			= {						
	categoryList		: [ 
		{ id: 1, name: 'standard', 	label: '_STANDARD'	},
		{ id: 2, name: 'luxury', 	label: '_LUXURY'	}
	]			
};

export const typesForm			= [{
	cols		: 12,
	icon		: "fa fa-user",
	items		: [
		{
			title		: "_INFO",
			offsetCols	: 1,
			cols		: 5,
			showTitle	: true,
			icon		: "fa fa-check",
			_padding		: "1rem 1rem 1rem 1rem",
			_widthButtons	:	'120px',
			items		: [
				{ clean: true, flex: 10, mandatory: false, 	field	: 'active', 			label:'_ACTIVE',			editor	: 'toggle',			editable	: true						},
				// { clean: true, flex: 10, mandatory: false, field	: 'type',				label:'_TYPE',				type	: 'text',			editable	: false,	legend: false	},
				{ 	clean: true, flex: 10, mandatory: true, 	field	: 'type',				label:'_TYPE',				editor	: 'groupButton',	type:'free', validators	:	[{type : 'minOneSelected'}], 
						items : [
						{label : '_PRIVATE', default : false, value:'private', icon : 'fa fa-fw fa-user'},
						{label : '_SHARED',  default: false, value:'shared', icon : 'fa fa-fw fa-users'}
					]
				},
				{ clean: true, flex: 10, mandatory: true, 	field	: 'name', 				label:'_NAME',				type	: 'text',			editable	: true						},
				{ clean: true, flex: 10, mandatory: true, 	field	: 'code', 				label:'_CODE',				type	: 'text',			editable	: true						},
				// { clean: true, flex: 10, mandatory: true, 	field	: 'category',			label:'_CATEGORY',			editor	: 'autocomplete', 	entityList	: 'categoryList' 			},
				{ clean: true, flex: 10, mandatory: true, 	field	: 'seats_down',			label:'_SEATS_DOWN',		editor	: 'text',			editable	: true,		type	: 'number', validators : [{type : 'positiveNumber'}]				},
				{ clean: true, flex: 10, mandatory: true, 	field	: 'seats_up',			label:'_SEATS_UP',			editor	: 'text',			editable	: true,		type	: 'number', validators : [{type : 'positiveNumber'}]				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'cost_km',			label:'_COST_KM',			editor	: 'text',			editable	: true						},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'cost_maintenance',	label:'_COST_MAINTENANCE',	editor	: 'text',			editable	: true						},
				{ 	
					clean		: true, 
					flex		: 10, 
					mandatory	: false, 	
					field		: 'description',	
					label		:'_DESCRIPTION',
					editor		: 'textarea',		
					editable	: true, 	
					height		: '4rem',
					// header		: 'normal'	// full, false
				}
			]
		},	

		{
			title		: "_SERVICES",
			cols		: 5,
			showTitle	: true,
			icon		: "fa fa-check",
			_padding		: "1rem 1rem 1rem 1rem",
			_widthButtons:	"150px",
			items		: [
				{ clean: true, flex: 10, mandatory: false, 	field	: 'adapted',				label:'_ADAPTED',				editor: 'toggle',	editable	: true			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_wifi', 			label:'_SERVICE_WIFI',			editor: "toggle",	editable	: false			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_bath', 			label:'_SERVICE_BATH',			editor: "toggle",	editable	: false			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_coffee', 		label:'_SERVICE_COFFEE',		editor: 'toggle',	editable	: true			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_tinted_glass',	label:'_SERVICE_TINTED_GLASS',	editor: "toggle",	editable	: false			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_dvd', 			label:'_SERVICE_DVD',			editor: "toggle",	editable	: false			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_micro',			label:'_SERVICE_MICRO',			editor: "toggle",	editable	: false			},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'service_platform',		label:'_SERVICE_PLATFORM',		editor: "toggle",	editable	: false			}						
			]
		}
	]
}];

export const vehiclesInfoForm 	= [{
	cols		: 12,
	icon		: "fa fa-user",
	items		: [
	{
		title		: "_INFO",
		showTitle	: false,
		icon		: "fa fa-check",
		cols		: 6,
		padding		: "0rem 1rem 0rem 1rem",
		items		:	[
			{ clean: true, flex: 10, mandatory: true, 	field	: 'plate',		label:'_PLATE',			type	: 'text',		editable	: true	},
			{ clean: true, flex: 10, mandatory: false, 	field	: 'code',	 	label:'_CODE',			editor	: 'text',		editable	: true	},
			{ clean: true, flex: 10, mandatory: false, 	field	: 'active', 	label:'_ACTIVE',		editor	: 'toggle',		editable	: true	},
			// { clean: true, flex: 10, mandatory: false, 	field	: 'firstturn', 	label:'_FIRST_TURN',	editor	: 'toggle',		editable	: true	},
			// { 
			// 	clean		: true, 
			// 	flex		: 10, 
			// 	mandatory	: true, 	
			// 	field		: 'turn',		
			// 	label		: '_TURN',
			// 	editor		: 'groupButton',
			// 	type		:'free',

			// 	validators	: [{ type : 'minOneSelected'}], 
			// 		items 		: [
			// 		{ label : '_TURN_EARLY_MORNING', 	default : false,  	value: 'turn_early_morning', 	_icon : 'fa fa-fw fa-user' },
			// 		{ label : '_TURN_MORNING', 	 		default : true,  	value: 'turn_morning', 			_icon : 'fa fa-fw fa-user' },
			// 		{ label : '_TURN_AFTERNOON', 		default : false, 	value: 'turn_afternoon', 		_icon : 'fa fa-fw fa-users'},
			// 		{ label : '_TURN_NIGHT', 		 	default : false,	value: 'turn_night', 			_icon : 'fa fa-fw fa-user' }
			// 	]
			// },	
			{ 
                clean		: true, 
                flex		: 10, 
                mandatory	: true, 	
                field		: 'turn',		
                label		: '_TURN',
                editor		: 'groupButton',
                type		:'free', 
                validators	: [{ type : 'minOneSelected'}],
                vertical    : true,
                items 		: [
                    { label : '_TURN_EARLY_MORNING', 	default : false,  	value: 'turn_early_morning', 	_icon : 'fa fa-fw fa-user' },
                    { label : '_TURN_MORNING', 	 		default : true,  	value: 'turn_morning', 			_icon : 'fa fa-fw fa-user' },
                    { label : '_TURN_AFTERNOON', 		default : false, 	value: 'turn_afternoon', 		_icon : 'fa fa-fw fa-users'},
                    { label : '_TURN_NIGHT', 		 	default : false,	value: 'turn_night', 			_icon : 'fa fa-fw fa-user' }
                ]
            },						
			{ clean: true, flex: 10, mandatory: false, 	field	: 'init_time', 	label:'_INIT_TIME',		editor	: 'text',		editable	: true	},
			{ clean: true, flex: 10, mandatory: false, 	field	: 'end_time', 	label:'_END_TIME',		editor	: 'text',		editable	: true	}
		]
	},
	{
		title		: "_IMG_VEHICLE",
		cols		: 6,
		showTitle	: false,
		icon		: "fa fa-check",
		padding		: "0rem 1rem 0rem 1rem",
		items		: [
			{ clean: true, flex: 10, mandatory: false, 	field	: 'brand', 		label:'_BRAND',			type	: 'text',		editable	: true				},
			{ clean: true, flex: 10, mandatory: false,  field	: 'model', 		label: '_MODEL',		type	: 'text',		editable 	: true 				},
			{ clean: true, flex: 10, mandatory : false,	field	: 'img',		label : '_IMAGE',		type 	: 'img',		editor 		: 'img'				}
		]
	}]
}
];

export const vehiclesForm		= [{
	cols		: 12,
	icon		: "fa fa-user",
	items		: [
	{
		title			: "_INFO",
		cols			: 4,
		showTitle		: true,
		icon			: "fa fa-check",
		widthButtons	:	'150px',
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'active', 				label:'_ACTIVE',				editor	: 'toggle',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'plate', 					label:'_CODE',					type	: 'text',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'model',					label:'_SEATS',					type	: 'text',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'seats_down',				label:'_SEATS_DOWN',			type	: 'number',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'seats_up',				label:'_SEATS_UP',				type	: 'number',			editable	: true				}			
		]
	},				
	{
		title			: "_MORE_INFO",
		cols			: 4,
		showTitle		: true,
		icon			: "fa fa-check",
		widthButtons	:	"150px",
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'card',					label:'_CARD_NUMBER',			type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'card_type', 				label:'_CARD_TYPE',				type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'card_period', 			label:'_CARD_PERIOD',			type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'work_number',			label:'_WORK_NUMBER',			type	: 'text',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'first_enrollment',		label:'_FIRST_ENROLLMENT',		type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'current_enrollment',		label:'_CURRENT_ENROLLMENT',	type	: 'text',			editable	: true				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'chassis_warranty_end',	label:'_CHASSIS_WARRANTY_END',	type	: 'text',			editable	: true				}
		]
	},
	{
		title			: "_SERVICES",
		cols			: 4,
		showTitle		: true,
		icon			: "fa fa-check",
		widthButtons	:	'150px',
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_wifi', 			label:'_SERVICE_WIFI',			editor: "toggle",	editable	: false			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_bath', 			label:'_SERVICE_BATH',			editor: "toggle",	editable	: false			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_coffee', 		label:'_SERVICE_COFFEE',		editor: 'toggle',	editable	: true			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_tinted_glass',	label:'_SERVICE_TINTED_GLASS',	editor: "toggle",	editable	: false			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_dvd', 			label:'_SERVICE_DVD',			editor: "toggle",	editable	: false			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_micro',			label:'_SERVICE_MICRO',			editor: "toggle",	editable	: false			},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'service_platform',		label:'_SERVICE_PLATFORM',		editor: "toggle",	editable	: false			}
		]
	},
	{
		title			: "_VEHICLE_INSPECTION",
		cols			: 3,
		showTitle		: true,
		icon			: "fa fa-check",
		widthButtons	:	'150px',
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'itv_first_revision', label:'_FIRST_REVISION',	type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'itv_last_revision', 	label:'_LAST_REVISION',		type	: 'text',			editable	: false				},						
		]
	},
	{
		title			: "_TACOGRAPH",
		cols			: 4,
		showTitle		: true,
		icon			: "fa fa-check",
		widthButtons	:	'150px',
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'taco_first_revision', 	label:'_FIRST_REVISION',		type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'taco_last_revision', 	label:'_LAST_REVISION',			type	: 'text',			editable	: false				},
			{ clean: true, flex: 10, mandatory: true, 	field	: 'taco_last_download',		label:'_LAST_DOWNLOAD',			type	: 'text',			editable	: false				}						
		]
	},
	{
		title			: "_NOTES",
		cols			: 4,
		showTitle		: true,
		icon			: "fa fa-check",
		_padding		: "1rem 1rem 1rem 1rem",
		widthButtons	:	'150px',
		items			: [
			{ clean: true, flex: 10, mandatory: true, 	field	: 'notes', 	label:'_NOTES',				type	: 'textarea',		editable	: false				}
		]
	}]
}
];

export const detailsVehicleForm = [
	{
		cols		: 12,
		icon		: "fa fa-user",
		items		: [
		{
			cols	:	6,
			items	:	[
				{ clean: true, flex: 10, mandatory: false, 	field	: 'card',					label:'_CARD_NUMBER',			type	: 'text',			editable	: false				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'card_type', 				label:'_CARD_TYPE',				type	: 'text',			editable	: false				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'card_period', 			label:'_CARD_PERIOD',			type	: 'text',			editable	: false				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'work_number',			label:'_WORK_NUMBER',			type	: 'text',			editable	: true				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'first_enrollment',		label:'_FIRST_ENROLLMENT',		type	: 'text',			editable	: false				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'current_enrollment',		label:'_CURRENT_ENROLLMENT',	type	: 'text',			editable	: true				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'chassis_warranty_end',	label:'_CHASSIS_WARRANTY_END',	type	: 'text',			editable	: true				}						
			
			]
		},
		{
			title		: "_VEHICLE_INSPECTION",
			cols		: 6,
			showTitle	: true,
			icon		: "fa fa-check",
			_padding		: "1rem 1rem 1rem 1rem",
			widthButtons	:	'150px',

			items		: [
				{ clean: true, flex: 10, mandatory: false, 	field	: 'itv_first_revision', label:'_FIRST_REVISION',	type	: 'text',			editable	: false				},
				{ clean: true, flex: 10, mandatory: false, 	field	: 'itv_last_revision', 	label:'_LAST_REVISION',		type	: 'text',			editable	: false				},						
			]
		},
		{
			title		: "_NOTES",
			cols		: 6,
			showTitle	: false,
			icon		: "fa fa-check",
			_padding		: "1rem 1rem 1rem 1rem",
			widthButtons	:	'150px',

			items		: [
				{ clean: true, flex: 10, mandatory: false, 	field	: 'notes', 				label:'_NOTES',				type	: 'textarea', editor : 'textarea',		editable	: false		, height:'6rem'		}
			]
		}]
	}
];

export const sections 			= [
	{
		title 		:	'_FAMILY_TYPES',
		value		:	'familyTransportTypes',
		subtitle	:	'_SUBTITLE_FAMILY_TYPES',
		icon		: 	'list',
		disabled 	:	true
	},
	{
		title		:	'_FLEET',
		value		:	'fleet',
		subtitle	:	'_SUBTITLE_FLEET',
		disabled	:	false,
		icon		: 	'bus'
	},
	{
		title 		:	'_DRIVERS',
		value		:	'drivers',
		subtitle	:	'_SUBTITLE_DRIVERS',
		icon		:	'user',
		disabled	:	false,
	},
	{
		title		:	'_EXTERNAL_TRANSPORTERS',
		value		:	'externalTransporters',
		subtitle	:	'_SUBTITLE_EXTERNAL_TRANSPORTERS',
		icon		:	'bus',
		disabled	:	false
	}
];

export const externalFleetForm	= [{
	cols		: 12,
	icon		: "fa fa-user",
	items		: [
		{
			cols	:	12,
			items	:	[
				{ 	clean: true, flex: 15, mandatory: false, 	field	: 'active',					label:'_ACTIVE',				editor	: 'toggle',			editable	: true				},
				{ 	clean: true, flex: 15, mandatory: true, 	field	: 'name',	 				label:'_NAME',					type	: 'text',			editable	: true,				},
				{ 	clean: true, flex: 15, mandatory: true, 	field	: 'transporter_name',	 	label:'_TRANSPORTER_NAME',		type	: 'text',			editable	: true,				},
				{ 	clean: true, flex: 15, mandatory: true, 	field	: 'qty',	 				label:'_VEHICLE_QTY',			type	: 'number',			editable	: true,		validators : [{type : 'positiveNumber'}]		},
				{ 	clean		: true, 
					flex		: 15, 
					mandatory	: true, 	
					field		: 'mode_work',				
					label		: '_MODE_WORK',				
					editor		: 'groupButton',	
					type		: 'toggleable', 		
					validators 	: [{type : 'minOneSelected'}], 
					items 		: [
						{ label : '_PER_HOUR', 	default: true, 	value:'per_hour', 	icon : 'fa fa-fw fa-clock-o'	},
						{ label : '_PER_DAY', 	default: false, value:'per_day', 	icon : 'fa fa-fw fa-calendar-o'	}
					]
				},
				{ 	clean: true, flex: 15, mandatory: true, 	field	: 'cost_per_day',			label:'_COST_PER_DAY',			type	: 'number',			editable	: true,  	validators : [{type : 'positiveNumber'}]		},
				{ 	clean: true, flex: 15, mandatory: false, 	field	: 'cost_per_hour', 			label:'_COST_TRANSPORT_HOUR',	type	: 'number',			editable	: true,		validators : [{type : 'positiveNumber'}]		},
				{ 	clean: true, flex: 15, mandatory: false, 	field	: 'driver_cost_per_hour', 	label:'_COST_DRIVER_HOUR',		type	: 'number',			editable	: true,		validators : [{type : 'positiveNumber'}]		},
				{ 	clean: true, flex: 15, mandatory: false, 	field	: 'licenses',				label:'_LICENCES',				editor: 'multiselect',		entityList	: 'licenses',	editable	: true				},
				{ 	clean		: true, 
					flex		: 15,
					mandatory	: false, 	
					field		: 'notes', 					
					label		: '_NOTES', 				
					type		: 'textarea',
					editor 		: 'textarea',	
					editable	: true, 
					height		:'10rem'		
				}									
			]
		}
	]
}];