import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {trigger,state,style,transition,animate,AnimationEvent} from '@angular/animations';


@Component({
    selector   : 'app-group-button',
    templateUrl: './groupButton.component.html',
    styleUrls:['./groupButton.component.scss'] ,
    animations: [
        trigger('animation', [
            state('visible', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('void => *', [
                style({transform: 'translateX(50%)', opacity: 0}),
                animate('300ms ease-out')
            ]),
            transition('* => void', [
                animate(('250ms ease-in'), style({
                    height: 0,
                    opacity: 0,
                    transform: 'translateX(50%)'
                }))
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None,
})

export class GroupButtonComponent implements OnInit{

   @Input()     items       : any;
   @Output()    selected    : EventEmitter<any> = new EventEmitter();
   
   selectedItems : any = [];
   test : any = [{value : 'ACTIVITIES', selected: true},{value : 'TEST', selected: false},{value : 'ZONES', selected: false}];

   ngOnInit(){
       console.log(this.items);
    }

    selectAction(item, items){
        
        let temp = !item.selected;
        items.map(el => el.selected = false);
        item.selected = temp;
        this.selected.emit(items);
    }


}