import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { FirebaseService 		} from './../../../demo/service/database/firebase.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { entities 				} from './data/items';

@Component({
  selector    		: 'app-company-resources',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyResourcesComponent {

	@Input("type")      type	:string	= "";
	@Output()			emitter			= new EventEmitter<any>();		
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: {},
		entity			: {},
		resources		: { editable: true 	},
		pictures		: { items	: []	}			
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService,
		private firebase		: FirebaseService
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'new_logo',	label: '_CREATE',	icon: 'fa fa-plus', type: 'button' 	}
		];
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.pictures.items= [		
			{ id: 0, active: true, name: 'grande', 		url: '/assets/layout/images/img/tourinia_logo.png' 	},
			{ id: 1, active: true, name: 'pequeño', 	url: '/assets/layout/images/img/tourinia_logo.png' 	},
			{ id: 2, active: true, name: 'cabecera', 	url: '/assets/layout/images/img/mallorca_long.png' 	}
		];
		this.pageInfo.pictures.current 			= this.pageInfo.pictures.items[2];
		this.pageInfo.pictures.current.selected = true;
	}

	async loadEntities()			{	await this.load("main"); 												}
  	async ngOnInit()				{	await this.init(); await this.loadEntities();							}
    onUpload(event) 				{	for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); 	}
								    	this.commons.generateToast('File Uploaded','','info');
									}	
	getProvisionalId(a):number		{	return a.reduce((acc,item)=>acc>item.id?acc:(item.id+1),1); 			}
	async action($type,$item,$event){
		switch($type){
			case "button"				:	switch($item.name){		
												case "new_logo"		: 	this.commons.generateToast("Button pressed","NEW LOGO","info");		
																		break;												
											}
										break;		
			case "editor"				:	switch($event.action){
												case "close"	:	this.pageInfo.pictures.view 	= "list";	
																	break;
												case "save"		:	if($event.detail!=""){ 
																		await this.saveCropped($event,$item); 
																	} else {
																		this.commons.generateToast("_ERROR","_CROP_IMAGE_FIRST","error");
																		return false;
																	}
																	$item.new 						= undefined;
																	this.pageInfo.pictures.view 	= "list";
																	break;
											}
											break;

			case "new_picture"			:	this.pageInfo.pictures.items.push({ 	
																		new			: true,
																		id 			: this.getProvisionalId(this.pageInfo.pictures),
																		order		: (this.pageInfo.pictures.items.length+1), 
																		name		: this.commons.getTranslate('_NEW_PICTURE'),
																		tags		: [],
																		features	: ["active"],
																		url			: "/assets/layout/images/sights/no_sight.jpg"
																	});
											this.pageInfo.pictures.current 	= this.pageInfo.pictures[this.pageInfo.pictures.items.length-1];
											this.pageInfo.pictures.view 	= "editor";
											break;
									
			case "deletePicture"		:	this.deletePicture($item);
											break;

			case "viewPicture"			:	if(this.pageInfo.resources.editable!=true){ return false; }
											this.pageInfo.pictures.items.forEach(item=>item.selected=false);
											$item.selected					= true;
											this.pageInfo.pictures.current 	= $item; 
											this.pageInfo.pictures.view 	= "editor";
											break;
		}
	}

	// IMAGE HANDLING

	async deletePicture($item)							{

		if(this.pageInfo.resources.editable == true ){
			let response = await Promise.resolve(this.entityService.delete(this.entityService.getUrl("resource"),{ id: $item.id }));
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR","_ERROR_DELETING","error");
				return false;
			}
		}
		this.pageInfo.pictures.items	= this.pageInfo.pictures.items.filter(item=>item.id!=$item.id);
		this.pageInfo.pictures.default	= undefined;		
	}

	removeBase64Header(str: string, type): string 	{	return str.substring(13+parseInt(type.length));		}
	base64toBlob(base64Data: any, contentType: any) {
		contentType 			= contentType || '';
		const sliceSize 		= 1024;
		const byteCharacters 	= atob(base64Data);
		const bytesLength 		= byteCharacters.length;
		const slicesCount 		= Math.ceil(bytesLength / sliceSize);
		const byteArrays 		= new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++ sliceIndex) {
			const begin 			= sliceIndex * sliceSize;
			const end 				= Math.min(begin + sliceSize, bytesLength);
			const bytes 			= new Array(end - begin);
			for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) { bytes[i] = byteCharacters[offset].charCodeAt(0); }
			byteArrays[sliceIndex] 	= new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	async saveCropped($item,$target)									{
		const imageFormat	= "image/png";
		const extension		= "png";
		const ref 			= this.firebase.afStorage.storage.ref(`dmcs/1/sights/pictures`).child((new Date()).getTime().toString()+"."+extension);
		const str			= this.removeBase64Header($item.detail,imageFormat);
		let response		= await Promise.resolve(ref.putString(str, "base64", { contentType: imageFormat }));
		$target.url			= await Promise.resolve(ref.getDownloadURL());		
	}

	getFilteredEntity($entity){
		switch($entity){
			case "main": 	return (this.pageInfo.entity.data||[]).filter(item=>{
									return true;
									// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
									// item.view 		= isSelected;
									// item.color		= "red";
									// return isSelected;
								});
		}
	}
	async load($entity){
		switch($entity){
			case 'main' :
				break;
		}
	}
}
