import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation, 
		NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { colFilters                             } from "./data/filters";
import { ConfirmationService 					} from 'primeng/primeng';
import { FirebaseService 						} from './../../../service/database/firebase.service';
import { StorageService 						} from './../../../service/storageservice';

@Component({
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class TourContainerComponent implements OnInit{
    locale      	: string 	= 'es';    
	pageInfo 		: any 		= { pageView: true };
	userInfo        : any 		= {};
	tourvariants	: any		= {};
	pickupZones		: any		= {};

	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService,
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private confirmCtrl     : ConfirmationService
	)					{ this.init(); 					}
    async ngOnInit() 	{ await this.loadEntities();	}
    async init()		{
		//
		this.pageInfo.dataLoaded      	= false;
        this.pageInfo.indexTabCalendar	= 0;
		this.pageInfo.variant        	= {};
		
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
        console.log('MY USER INFO', this.userInfo);
		
		this.pageInfo.status			= {
			tours	: "tourList",
			sights	: "sightList",
			variants: "variantList",
			commons	: "pickupList"
		};
		this.pageInfo.i18n				= this.commons.getAllTranslates();
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_TOURS'		),	icon : 'fa fa-folder-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ conditional: 'currentTour',
					  id:1, label: this.commons.getTranslate('_VARIANTS'	), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:2, label: this.commons.getTranslate('_SIGHTS'		),	icon : 'fa fa-map-marker' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:3, label: this.commons.getTranslate('_PICKUPS'		),	icon : 'fa fa-share-alt' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:4, label: this.commons.getTranslate('_CALENDAR'	), 	icon : 'fa fa-calendar-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:5, label: this.commons.getTranslate('_MAP'			),	icon : 'fa fa-map-o' 			, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:6, label: this.commons.getTranslate('_STATS'		),	icon : 'fa fa-area-chart' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:7, label: this.commons.getTranslate('_TRANSPORT'	),	icon : 'fa fa-bus' 				, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:8, label: this.commons.getTranslate('_REPS'		),	icon : 'fa fa-user'		 		, command: ($event)=> { this.setTabItem('main',$event)} 	}										
				],
				selected		: 0
			}
		};
		this.pageInfo.pricingOptions	= {
			items 			: [
				{ value: "base_price", 	label: this.commons.getTranslate('_BASE_PRICE'	),	icon : 'fa fa-folder-o' 		},
				{ value: "offers",		label: this.commons.getTranslate('_OFFERS'		), 	icon : 'fa fa-pencil-square-o' 	},
				{ value: "final_price",	label: this.commons.getTranslate('_FINAL_PRICE'	),	icon : 'fa fa-map-o' 			}				
			],
			selected		: "base_price"
		};

		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
        };
        this.pageInfo.colFilters 		= await Promise.all(colFilters.map(async item=>await this.commons.translateRecursively(item)));
	}				

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

    async loadEntities(){	this.pageInfo.dataLoaded = true;	}
    async load($entity)	{
        switch($entity){
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	action($type,$event){
		switch($type){
			case "tourList"		: 	switch($event.action){
										case "tour_unselected"	:	this.pageInfo.currentTour		= undefined;		break;
										case "tour_selected"	:	this.pageInfo.currentTour		= $event["item"];
																	this.pageInfo.status.variants 	= "variantList";
																	break;
										case "view_tour"		: 	if(undefined==this.pageInfo.currentTour){
																		this.commons.generateToast("_ERROR","_TOUR_NOT_SELECTED","error");
																		return false;
																	}
																	this.pageInfo.status.tours 		= "tourDetail"; 	
																	break;
										case "new_tour"			: 	this.pageInfo.status.tours 		= "tourWizard";		break;
									}
									break;

			case "tourWizard"	:	switch($event.action){
										case "save"				: 	this.commons.generateToast("_TOUR_CREATED_TITLE","_TOUR_CREATED_DESC","success"); break;
										case "close"			: 	break;
									}
									this.pageInfo.status.tours 		= "tourList";
									break;

			case "tourDetail"	: 	this.pageInfo.status.tours 		= "tourList"; 		break;

			case "variantList"	: 	switch($event.action){
										case "variant_unselected":	this.pageInfo.currentVariant	= undefined;		break;
										case "variant_selected"	:	this.pageInfo.currentVariant	= $event["item"];	break;
										case "view_variant"		: 	if(undefined==this.pageInfo.currentVariant){
																		this.commons.generateToast("_ERROR","_VARIANT_NOT_SELECTED","error");
																		return false;
																	}
																	this.pageInfo.status.variants	= "variantDetail";	
																	break;
										case "variant_prices"	: 	this.pageInfo.status.variants	= "variantPrice";	break;
									}
									break;

			case "variantWizard":	switch($event.action){
										case "save"				: 	this.commons.generateToast("_VARIANT_CREATED_TITLE","_VARIANT_CREATED_DESC","success"); break;
										case "close"			: 	break;
									}
									this.pageInfo.status.variants 	= "variantList";
									break;

			case "variantDetail": 	this.pageInfo.status.variants	= "variantList"; 	break;

			case "variantPricing":	switch($event.action){
										case "save"				: 	this.saveVariant(); break;											
									}
									this.pageInfo.status.variants	= "variantList"; break;
									break;
									
			case "sightList"	: 	switch($event.action){
										case "view_sight"		: 	this.pageInfo.status.sights 	= "sightDetail";
																	this.pageInfo.info				= $event.item;
																	break;
										case "new_sight"		: 	this.pageInfo.status.sights 	= "sightWizard";	
																	break;
									}
									break;

			case "sightWizard"	:	switch($event.action){
										case "save"				: this.commons.generateToast("_SIGHT_CREATED_TITLE","_SIGHT_CREATED_DESC","success"); break;
										case "close"			: break;
									}
									this.pageInfo.status.sights 	= "sightList"; 	
									break;

			case "sightDetail"	: 	this.pageInfo.status.sights 	= "sightList";		break;
			
		}
	}

	/**
	 * Save current variant
	 */
    saveVariant(){
		const variant = { calendar: this.pageInfo.currentVariant.calendar, id: this.pageInfo.currentVariant.id, tourId: this.pageInfo.currentVariant.tourId };
        this.storage.setItem('variant',variant);
		this.firebaseCtrl.setVariantInfo(this.userInfo.idDmc,this.userInfo.currentDestination.id,variant.tourId,variant.id, variant);
		this.commons.generateToast('_INFO','_VARIANT_SAVED','info');
	}


	changeTabPricing($event){
		console.log($event);
		if($event.value == 'final_price'){
			console.log(this.pageInfo.currentVariant);
            this.pageInfo.currentVariant.calendar.compositor = this.mountCalendar();
		}
	}
	/**
	 * Check all the days from offers, from schedule, merge them and create computed, which is the info that customer will user
	 */
	mountCalendar(){
        // console.log('MOUNTING CALENDAR');
        if(undefined==this.pageInfo.currentVariant.calendar){ return false; }
        const listDays  =   Array.from(new Set(this.pageInfo.currentVariant.calendar.offers.days.map(el => el.day).concat(this.pageInfo.currentVariant.calendar.schedule.days.map(el => el.day))));
        return listDays.map(day => {
            let foundComputed;
            if(Object.keys(this.pageInfo.currentVariant.calendar.compositor).length ==0){
                foundComputed = null;
            }else{
                foundComputed    =   this.pageInfo.currentVariant.calendar.compositor.find(el => el.day == day);
                if(foundComputed){
                    foundComputed = foundComputed.computed;
                }else{
                    foundComputed = null;
                }
            }
            console.log('computed', foundComputed);
            return {    day         : day, 
                        computed    : foundComputed || null,
                        offers      : this.pageInfo.currentVariant.calendar.offers.days.find(el => el.day == day)		|| null,
                        schedule    : this.pageInfo.currentVariant.calendar.schedule.days.find(el => el.day == day) 	|| null
                    }
				});
	}
}