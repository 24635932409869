import { Router 					} from '@angular/router';
import { MapService 				} from './../../demo/service/mapservice';
import { FirebaseService 			} from './../../demo/service/database/firebase.service';
import { AggregatorsService 		} from './../../demo/service/database/aggregator.service';
import { CommonsService 			} from './../../demo/service/commons.service';
import { GoogleService 				} from './../../demo/service/google/google.service';
import { Component, 
		 Input, 
		 OnInit, 
		 ViewChild, 
		 ElementRef, 
		 Output, 
		 EventEmitter, 
		 AfterViewInit, 
		 HostListener 				} from '@angular/core';
import { ConfirmationService 		} from 'primeng/primeng';
import { DestinationsService 		} from 'src/app/demo/service/database/destinations.service';

@Component({
  selector		: 'app-map-zones',
  templateUrl	: './app-map-zones.component.html',  
  styleUrls   	: ['./app-map-zones.component.scss']
})

export class AppMapZonesComponent implements OnInit, AfterViewInit
{  
	@Input() fromWizard			:	any = false;
	@Input() aggregators   		:   any;
	@Input() infoDestination	:	any;
	@Input() areasDestination   :   any;
	@Input() zonesAggregators	:	any;

	@Output()   emitter			:   EventEmitter<any>   = new EventEmitter();

	@ViewChild('inputNameZone') inputNameZone   		: 	ElementRef;
	@HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		switch(event.keyCode){
			case 27	:	if(this.pageInfo.creatingZone || this.pageInfo.editingZone){	this.modeCreateZone();	}	return;
			case 13	:	if(this.pageInfo.creatingZone || this.pageInfo.editingZone){	this.createZone();		}	return;
			default	:	return;
		}
	}

	pageInfo :	any = {};

	constructor(private googleCtrl 		:	GoogleService,
				private firebaseCtrl	:	FirebaseService,
				private route			:	Router,
				private mapService		:	MapService,
				private confirmCtrl		:	ConfirmationService,
				private commons			:	CommonsService,
				private destinationCtrl	:	DestinationsService,
	) {}
	
	async ngOnInit()	{	await this.init();		}
	ngAfterViewInit() 	{	
		console.log(document);	
		this.loadZones();
	}

	async init()		{
		this.pageInfo = {
			listAreas			:	this.areasDestination,
			currentZone			:	[],
			tmpAggregatorsZones	:	this.zonesAggregators || [],
			zones				:	[],
			displayMsgCopied	:	{display : false, class : null},
			tools				:	{
				copiedItem	:	null,
			},
			colors				:	{
						default		:	'#6495ED',
						toggled		:	'#FFB6C1',
						selected	:	'red'	
			}
		}

		await this.googleCtrl.loadApi();

		this.initMapConfig((this.infoDestination || {}).mapConfig);
	}

	doSaveZoneAsDestination(){
		this.commons.generateToast("_INFO","_SAVING_ZONE_AS_DESTINATION","info");
		
		if(undefined==this.pageInfo.zones[0]){
			this.commons.generateToast("_INFO","_SELECT_ZONE_AS_DESTINATION","info");
			return false;	
		}
		
		if(undefined==this.pageInfo.currentZone){
			this.commons.generateToast("_INFO","_SELECT_ZONE_AS_DESTINATION","info");
			return false;	
		}

		this.destinationCtrl.createDestination("spain", this.pageInfo.zones[0]);
	}

	doAction($type,$info){
		switch($type){
			case "zones"			:
				switch($info.action){
					case "create"			:	this.modeCreateZone(); 	break;
					case "delete"			:	this.deleteZones();		break;
				}
				break;

			case "zone"			:
				switch($info.action){
					case "create"			:	this.createZone(); 		break;
					case "delete"			:	this.deleteZone();		break;
					case "fill_rest_zones"	:	this.fillRestZones();	break;
				}
				break;

			case "destination"	:
				switch($info.action){
					case "save":
						this.commons.pageInfo.creationProcess = false;
						break;
					case "save_zone_as_destination": 
						this.doSaveZoneAsDestination(); 
						break;
				}
				break;
		}
	}

	goToAggregators(){
		if(this.fromWizard){
			this.emitter.emit({action : 'goToAggregators'});
		}else{
			this.route.navigate(['/company_providers']);
		}
	}

	async toggleAggregator(aggregator, overlayInfo?)
	{
		if(overlayInfo){
			overlayInfo.panel.hide(overlayInfo.panel.event);
		}
		if(this.pageInfo.currentAggregator && this.pageInfo.currentAggregator.id == aggregator.id){ return; }
		
		this.commons.toggleItem(aggregator,this.aggregators,'selected');
		this.pageInfo.currentAggregator	=	aggregator;
		
		await this.loadZonesAggregator();
		this.updateTmpZonesAggregator();
		this.paintCreatedAreas();

		console.log('CURRENT AGGREGATOR', this.pageInfo.currentAggregator);
	}

	updateTmpZonesAggregator(){
		if(this.pageInfo.currentAggregator){
			let foundAggregator	=	this.pageInfo.tmpAggregatorsZones.findIndex(el => {
				if(el.aggregatorId){
					return el.aggregatorId 				== (this.pageInfo.currentAggregator).id || el.aggregatorId == (this.pageInfo.currentAggregator || {}).providerId
				}else{
					return el.aggregator.aggregatorId == (this.pageInfo.currentAggregator).id || el.aggregatorId == (this.pageInfo.currentAggregator || {}).providerId
				}
				});
			foundAggregator == -1 	? 	this.pageInfo.tmpAggregatorsZones.push({aggregatorId : this.pageInfo.currentAggregator.id, zones : this.pageInfo.zones})
									:	this.pageInfo.tmpAggregatorsZones[foundAggregator].zones = this.pageInfo.zones;	
		}
	}

	/**-------------------------------------------------------
	 * MAP METHODS
	 -------------------------------------------------------*/
	initMapConfig(mapConfig?,map?){
		let centerCoordsBounds	=	this.googleCtrl.getCenterPolygons(this.pageInfo.listAreas.map(el => el.coords).flat());
        this.pageInfo.mapConfig = {			
            lat     :   mapConfig ? mapConfig.lat : this.pageInfo.listAreas[Math.ceil(this.pageInfo.listAreas.length/2)].coords[0].lat,
            lng     :   mapConfig ? mapConfig.lng : this.pageInfo.listAreas[Math.ceil(this.pageInfo.listAreas.length/2)].coords[0].lng,
            zoom    :   mapConfig ? mapConfig.zoom: 9
		}
		console.log('TMP ZONES', this.pageInfo.tmpZones);
		map ? map.fitBounds(centerCoordsBounds.bounds) : null;
	}

	mapReady(map){
		if(this.fromWizard){
			console.log(document);
			document.querySelector("agm-map")["style"].position	=	"";
			document.querySelector("agm-map")["style"].height	=	"75vh";
		}else{
			// document.getElementsByClassName("toolbarFixed")[0]['style'].position	=	"fixed";
		}
		this.initMapConfig((this.infoDestination || {}).mapConfig,map)
	}

	/**
	 * force to load zones from aggregator 1
	 * SOLUTION
	 * 	move zones outside aggregators
	 */
	async loadZones(){
		try {
			const dmcId 		= this.commons.pageInfo.dmcSelected.id;
			const destinationId	= this.commons.pageInfo.destinationSelected.id;

			let forcedAggregator= 1;
			let doc 			= "/dmcs/"+dmcId+"/destinations/"+destinationId+"/aggregators/"+forcedAggregator;
			let response:any	= await this.firebaseCtrl.getDataByRef(doc);
			
			this.pageInfo.zones	= (response.zones||[]).map(async zone => {
				let info = {
					id			: zone.id,
					name		: zone.name,
					refAreas	: zone.refAreas,
					areas		: await this.firebaseCtrl.getInfoAreas(zone.refAreas)
				}
				return info;
			});
			
		}catch(e){
			this.commons.generateToastError(e.message);
		}
	}

	async loadZonesAggregator(){
		let tmpAggregator	=	this.pageInfo.tmpAggregatorsZones.find(el => el.aggregatorId == (this.pageInfo.currentAggregator).id || el.aggregatorId == (this.pageInfo.currentAggregator || {}).providerId);
		if(!this.fromWizard){
			if(tmpAggregator){
				this.pageInfo.zones	=	(tmpAggregator && tmpAggregator.zones) ? tmpAggregator.zones : [];
			}else{
				this.pageInfo.zones = await Promise.all((this.pageInfo.currentAggregator.zones || []).map(async zone => {
					return {
						id		:	zone.id,
						name	:	zone.name,
						refAreas:   zone.refAreas,
						areas	:	await this.firebaseCtrl.getInfoAreas(zone.refAreas)
					}
				}));
			}	
		} else {
			if(tmpAggregator && tmpAggregator.zones){
				this.pageInfo.zones = await Promise.all((tmpAggregator.zones || []).map(async zone => {
					return {
						id		:	zone.id,
						name	:	zone.name,
						refAreas:   zone.refAreas,
						areas	:	await this.firebaseCtrl.getInfoAreas(zone.refAreas)
					}
				}));
			}else{
				this.pageInfo.zones	=	[];
			}
		}
	}

	copyZonesAggregators()	{	
		this.pageInfo.displayMsgCopied	=	{display : true, class : 'animated fadeIn'}
		this.pageInfo.tools.copiedItem	=	{zones : this.copyZones(), legend : JSON.parse(JSON.stringify(this.pageInfo.legendZones))}
		setTimeout(()=>{this.pageInfo.displayMsgCopied	=	{class : 'animated fadeOut'}},2000)
	}

	pasteZonesAggregators()	{
		this.pageInfo.zones				=	this.pasteZones();
		this.pageInfo.legendZones		=	this.pageInfo.tools.copiedItem.legendZones;
		this.updateTmpZonesAggregator();
		this.emitter.emit({
			action 	: 'update', 
			data 	: {
				aggregator 	: this.pageInfo.currentAggregator, 
				zones 		: this.cleanZones()
			}
		});
		this.paintCreatedAreas();
	}

	copyZones()				{
		const zones = this.pageInfo.zones.map(zone => { 
			return {	
				name : zone.name, 
				areas : zone.areas.map(area => area.properties.name)
			}
		});

		return JSON.parse(JSON.stringify(zones));
	}

	pasteZones()			{	
		return this.pageInfo.tools.copiedItem.zones
					.map((zone,i) => { 	
						let areas	=	zone.areas.map(area => this.pageInfo.listAreas.filter(item => item.properties.name == area).flat()).flat();
						return	{	name 		: 	zone.name,
									id			:	this.commons.generateHash(i*+new Date()),
									areas		:	areas,
									refAreas	:	areas.map(el => el.ref)	
								}
					}
				);
	}

	/**
	 * paing zone areas
	 * @param currentZone 
	 */
	paintCreatedAreas(currentZone?)
	{
		this.pageInfo.legendZones = [];
		this.pageInfo.listAreas.forEach(area => area.color = this.pageInfo.colors.default);
		this.pageInfo.zones.forEach((zone, index) =>{
			let colorZone	=	(currentZone && currentZone.id == zone.id)	
									?this.pageInfo.colors.selected 
									:this.mapService.getColor(index);

			(zone.areas||[]).forEach(areaFromZone => { 
				let areasFound	= this.pageInfo.listAreas.filter( area => {
					return area.properties.name == areaFromZone.properties.name;
				});
				if(areasFound.length == 0){	return;	}
				areasFound.forEach(area => area.color = colorZone); }
			);

			this.pageInfo.legendZones.push({name : zone.name, color : colorZone});
			return;
		});

		console.log(this.pageInfo.listAreas);
    }

	async fillRestZones(){

        let areasAssigneds      = [].concat.apply([],this.pageInfo.zones.map(el => el.areas));
        let areasUnassigneds    = this.pageInfo.listAreas.filter(el => !areasAssigneds.find(area => el.properties.name == area.properties.name));
        let refAreas            = areasUnassigneds.map(el => el.ref);
        let zoneAlreadyExists   = this.pageInfo.zones.findIndex(zone => zone.id == this.pageInfo.currentZoneId);
        if(refAreas.length == 0){
            return this.commons.generateToast('_OPTION_NOT_POSSIBLE', '_NO_EMPTY_AREAS', 'info');
        }

        if(zoneAlreadyExists > -1){
            const concatenatedRefAreas = this.pageInfo.zones[zoneAlreadyExists].refAreas.concat(refAreas);
            this.pageInfo.zones[zoneAlreadyExists].refAreas = concatenatedRefAreas;
            
            const concatenatedAreas =   this.pageInfo.zones[zoneAlreadyExists].areas.concat(areasUnassigneds);
            this.pageInfo.zones[zoneAlreadyExists].areas    =   concatenatedAreas;  
        } 
		else {
            
            let newZone = {
                refAreas: areasUnassigneds.map(el => el.ref),
                areas   : areasUnassigneds,
                name    : this.pageInfo.nameZone,
                id      : this.commons.generateHash(+new Date())
            }
            this.pageInfo.zones.push(newZone);
        }

		this.updateTmpZonesAggregator();

		this.emitter.emit({
			action 	: 'update', 
			data 	: {
				aggregator 	: this.pageInfo.currentAggregator, 
				zones 		: this.cleanZones()
			}
		})
		this.commons.generateToast('_SUCCESS','_MAP_COMPLETED','success');
		
		this.pageInfo.tmpZoneSaved	=	null;
        this.restartOptions();
        this.paintCreatedAreas();
    }

	toggleArea(area){
		let findedZone      = this.pageInfo.zones.find(zone => zone.areas.find(el => el.properties.name == area.properties.name));
		
		if(!this.pageInfo.creatingZone && !this.pageInfo.editingZone && findedZone){
			this.pageInfo.zoneSelected = findedZone.name;
			this.displayZone();
			return;
		}
		
		if(!findedZone){
			this.pageInfo.creatingZone    =   true;
			area.color      = area.color == this.pageInfo.colors.toggled ? this.pageInfo.colors.default : this.pageInfo.colors.toggled;
			let findedArea  = ( this.pageInfo.currentZone || [] ).findIndex(el => el.properties.name == area.properties.name);
			if(findedArea > -1)	{   this.pageInfo.currentZone.splice(findedArea,1);		}
			else            	{   this.pageInfo.currentZone = this.pageInfo.currentZone || [];
									this.pageInfo.currentZone.push(area);				}
		}  
		else {
			let colorArea       = findedZone.areas[0].color;
			if(findedZone.name == this.pageInfo.zoneSelected){
				let zoneExists = findedZone.areas.findIndex(el => el.properties.name == area.properties.name);
				if(zoneExists > -1){
					area.color  = this.pageInfo.colors.default; 
					findedZone.areas.splice(zoneExists,1);
				}else{
					area.color  = colorArea;
					findedZone.areas.push(area);
				}
			}else{
				this.commons.generateToast('_ERROR','_AREA_FROM_OTHER_ZONE','error');
			}            
		}
		this.updateTmpZonesAggregator()
	}

	async deleteZone(){
        let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == this.pageInfo.currentZoneId);
        if (zoneFinded == -1){ return this.commons.generateToast('_ERROR', '_NO_ZONE_FINDED', 'error');}
        
        this.pageInfo.zones.splice(zoneFinded,1);
		this.updateTmpZonesAggregator()
		this.emitter.emit({action : 'update', data : {aggregator : this.pageInfo.currentAggregator, zones : this.cleanZones()}})
        this.commons.generateToast('_SUCCESS','_ZONE_REMOVED','success');
		this.restartItems();
		
		this.pageInfo.tmpZoneSaved	=	null;
        this.restartOptions();
        this.paintCreatedAreas();
	}

	async deleteZones(){
        this.confirmCtrl.confirm({
            message     : 	this.commons.getTranslate('_MESSAGE_CONFIRM_REMOVE_ZONES'),
            header      : 	this.commons.getTranslate('_REMOVE_ZONES'),
            key         :   'removeZones',
            acceptLabel :   this.commons.getTranslate('_YES'),
            rejectLabel :   this.commons.getTranslate('_NO'),
            accept: async () => {
                try{
                    // if((!this.fromWizard || this.mode == 'edit') && (!this.destination.isNewDestination)){
                    //     let infoEntities = this.mountEntities();
                    //     await this.firebaseCtrl.updateZoneDestination(infoEntities.dmc,infoEntities.destination, []);                        
                    // }
                    // this.initMap();
					this.pageInfo.zones         = [];
					this.updateTmpZonesAggregator();
					this.emitter.emit({action : 'update', data : {aggregator : this.pageInfo.currentAggregator, zones : this.cleanZones()}})
					
					this.pageInfo.tmpZoneSaved	=	null;
					this.restartOptions();
					this.restartItems();
					this.paintCreatedAreas();
                }catch(e){}
            }
        });
    }

	displayZone(zone?){
		if(zone){this.pageInfo.zoneSelected	= zone.name;}
        this.restartItems();
        let zoneSelected            = this.pageInfo.zones.find(el => el.name == this.pageInfo.zoneSelected);
        this.pageInfo.nameZone      = zoneSelected.name;
        this.pageInfo.editingZone   = true;
        this.pageInfo.currentZone   = zoneSelected.areas || [];
		this.pageInfo.currentZoneId = zoneSelected.id;
		
		let areas					= this.pageInfo.currentZone.map(area => area.properties.name);
		
		let zoneInfo				= {
			name 	: zoneSelected.name, 
			id		: zoneSelected.id, 
			areas 	: Array.from(new Set(areas))	// Remove duplicates
		};

		this.pageInfo.tmpZoneSaved	= JSON.parse(JSON.stringify(zoneInfo));
      
        this.paintCreatedAreas(zoneSelected);
    }
    
    restartItems(){
        this.pageInfo.nameZone      = 	null;
		this.pageInfo.currentZone  	=	[];
        this.pageInfo.legendZones   =	[];
    }

    restartOptions(){
		if(this.pageInfo.tmpZoneSaved){
			let foundIndexZone 	= 	this.pageInfo.zones.findIndex(el => el.id == this.pageInfo.currentZoneId);
			if(foundIndexZone > -1){
				let areas		=	this.pageInfo.tmpZoneSaved.areas.map(area =>this.pageInfo.listAreas.filter(item => item.properties.name == area).flat()).flat();
				this.pageInfo.zones[foundIndexZone]		=	{...this.pageInfo.tmpZoneSaved, areas : areas, refAreas : areas.map(el => el.ref)}
			}
			
		}

        this.pageInfo.creatingZone  =	false;
        this.pageInfo.editingZone   =	false;
        this.pageInfo.nameZone      =	null;
        this.pageInfo.currentZone   =	[];
		this.pageInfo.currentZoneId = 	null;
		this.pageInfo.zoneSelected	=	null;
		this.pageInfo.tmpZoneSaved	=	null;
    }

    checkStatusName(nameZone)		{	
		if(nameZone === null || nameZone === undefined) { return true; }
		let areaFinded =  this.pageInfo.zones.find(el => el.name == nameZone);
		if(areaFinded){
			return areaFinded.id == this.pageInfo.currentZoneId;
		}else{
			return true;
		}
	}

	cleanZones()					{	
		return this.pageInfo.zones.map(el =>{ 
			return { 
				refAreas 	: el.refAreas, 
				name 		: el.name, 
				id 		: el.id
			}
		});
	}

	async checkInputZone($event)	{	
		if($event.keyCode == 13){
			await this.createZone();
		}
	}

	/**
	 * create new DMC zone
	 * 
	 * @returns 
	 */
    async createZone()				{
        try{
			if(!this.checkStatusName(this.pageInfo.nameZone)){ 
				this.commons.generateToastError("_NAME_ZONE_ALREADY_EXISTS");
				return false;
			}

            let newZone = {
                refAreas: this.pageInfo.currentZone.map(el => el.ref),
                areas   : this.pageInfo.currentZone,
                name    : this.pageInfo.nameZone,
                id      : this.pageInfo.currentZoneId ? this.pageInfo.currentZoneId : this.commons.generateHash(+new Date()) 
            };

            let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == newZone.id);
            
			if(zoneFinded > -1){    // UPDATE
                this.pageInfo.zones[zoneFinded].areas       = 	newZone.areas; 
                this.pageInfo.zones[zoneFinded].name        =	newZone.name;
                this.pageInfo.zones[zoneFinded].refAreas    =   newZone.refAreas;
            }else{
                this.pageInfo.zones.push(newZone);
            }

			this.emitter.emit({ 
				action 	: 'update', 
				data 	: {
					aggregator 	: this.pageInfo.currentAggregator, 
					zones 		: this.cleanZones()
				}
			})

			this.updateTmpZonesAggregator();

			console.log(this.pageInfo.zones);
			this.pageInfo.tmpZoneSaved	=	null;
			this.restartOptions();
			this.paintCreatedAreas();
			
        }catch(e){
            console.error(e);
            this.commons.generateToast('_ERROR','_RESTART_PAGE','error');
		}   
		
	}
	
	modeCreateZone()			{
		if(this.pageInfo.zoneSelected){
			this.restartOptions()
		}else{
			this.pageInfo.creatingZone    = this.pageInfo.creatingZone  ? !this.pageInfo.creatingZone     : true;
			this.pageInfo.editingZone      = this.pageInfo.editingZone    ? !this.pageInfo.editingZone       : false;
		}
		this.restartItems();
		this.paintCreatedAreas();    

		if(this.pageInfo.creatingZone || this.pageInfo.editingZone){
			setTimeout(()=>{this.inputNameZone.nativeElement.focus();},1000)
		}
		console.log(this.pageInfo.creatingZone, this.pageInfo.editingZone);
		console.log('Zones Destination',this.pageInfo.listAreas);
	}
	
	getColorZone(zone)			{ 
		return this.pageInfo.legendZones 
				? (this.pageInfo.legendZones.find(item => item.name == zone.name) || {}).color || 'black' 
				: 'black' 
	}
}