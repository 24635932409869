import { CompanyService 				} from '../../service/database/company.service';
import { AggregatorsService 			} from '../../service/database/aggregator.service';
import { CommonsService 				} from '../../service/commons.service';
import { EntityService 					} from '../../service/entity.service';
import { MessageService 				} from 'primeng/components/common/messageservice';
import { OnInit,
		 Component,
		 ViewChild,
		 ViewEncapsulation,
		 ElementRef,
		 platformCore					} from '@angular/core';
import { filters, zonesFilter 			} from './data/filters';
import { toggleFullScreen 				} from './fn/fullscreen';
import { filterEntity 					} from './fn/filters';
import { providerRestrictionFields 		} from './data/filters';
import { StorageService 				} from '../../service/storageservice';
import { help 							} from './data/help';
import { calendarInfo,
		 colorInfo,
		 heightInfo,					
		 servicesGrid,					} from './data/info';
import { servicesCols 					} from './columns/services.columns';
import { SimpleFirebaseService 			} from '../../service/database/simplefirebase.service';
import { ServiceFiltersService 			} from '../../service/serviceFilters/service-filters.service';
import { TransportService 				} from '../../service/transports/transports.service';
import { tabs							} from './data/tabs';
import { expandedArrivalForm,
		 expandedDepartureForm			} from './columns/expandedForm';

interface marker {
	lat				: number;
	lng				: number;
	label?			: string;
	draggable		: boolean;
}

interface Calendar {
	value			: any,
	date			: string,
	last			: string
};

@Component({
    styleUrls		: ['./externals.component.scss'],
	templateUrl		: './externals.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})

export class ExternalsComponent implements OnInit
{
	@ViewChild('calendarVehicles'	)	calendarVehicles: ElementRef;
	@ViewChild('calendarDrivers'	)	calendarDrivers	: ElementRef;
	@ViewChild('servicesGrid'		) 	servicesGrid	: any;
	
	pageInfo			: any 		= { fullScreen: false, export: [] };
	userInfo			: any		= {};
	zoom				: number 	= 10;
	lat					: number	= 39.638464;
	lng					: number	= 3.003831;
	providers			: any 		= {};
	markers				: marker[] 	= [
										{ 
											lat			: 39.575505, 
											lng			: 2.652774, 
											label		: 'Headquarters', 
											draggable	: true 	
										}
									  ];
	calendar			: Calendar 	= <Calendar>{ last: '' };
	calendarFullView	: boolean	= true;
	entities			: any[]		= [ "groups", "fleet", "drivers" ];
	groups      		: any 		= { cols: [], filters: {}};
	services			: any		= { cols: [], filters: {}, items: []};
	fleet				: any 		= { cols: [], filters: {}, draggableSet: [] };
	drivers				: any 		= { cols: [], filters: {}, draggableSet: [] };
	transporters		: any 		= { cols: [], filters: {}, draggableSet: [] };
	transportTypes		: any 		= { cols: [], filters: {}, draggableSet: [] };
	vehicleTypes		: any[]		= [];
	arrivals			: any		= { draggableSet: [] };
	departures			: any		= { draggableSet: [] };
	
	ownFleet			: any[]		= [];
	ownPlates			: any		= { draggableSet: [] };
	
	driversCalendar		: any 		= { rows: [{ empty: true, items: [] }]};
	transportersCalendar: any 		= { rows: [{ empty: true, items: [] }]};
	companies			: any		= { items: [], selected: [] };
	draggedItem			: any;
	chartData			: any;
	linkable			: any 		= {};

	plainServices		: any[]		= [];
	transportsCalendar	: any 		= { rows	: [{ empty: true, items: [] }],
										tmpRows	: [{ emtpy: true, items: []	}]
									};

	rowData				: any 		= { servicesGrid: {}};

    constructor(
		private commons					: CommonsService,
		private entityService			: EntityService,
		private aggregatorCtrl			: AggregatorsService,
		private companyService			: CompanyService,
		private storageCtrl				: StorageService,
		private serviceFiltersService	: ServiceFiltersService,
		private firebaseService			: SimpleFirebaseService,
		private transportService		: TransportService
	){
		this.staticInit();
	}

	async ngOnInit(){ await this.init(); }

	async init()	{
		this.pageInfo.subscriptions			=	[];
		this.pageInfo.tabs					=	tabs;
		this.pageInfo.table 				=	{ height: '65vh', border: '1px solid #f0f0f0', rowExpansionWidth: '85vw' };		
		this.pageInfo.dialogs				= {
			"splitGroup"	: { params: {}, openned	: false }
		};
		this.pageInfo.currencySign			= "€";
		this.pageInfo.filters 				= filters;
		this.pageInfo.elem 				= 	document.documentElement;

		this.generateMenuCols("groups");
		this.generateMenuCols("services");
	
		this.loadEntities();
		this.doSubscribe();
	}

	async staticInit()									{
		this.pageInfo.debug					=	true;
		this.pageInfo.wheel					= 	{ xFactor: 2, yFactor: 1, sliderFactor: 50 };
		this.pageInfo.noData				= 	{
			header	: {
				title	: "_WELCOME_COMPOSITOR_TITLE",
				message	: "_WELCOME_COMPOSITOR_DESC",
			},
			items	: [
				{ icon	: "bus",			message	: "_WELCOME_COMPOSITOR_ITEM_1" },
				{ icon	: "calendar",		message	: "_WELCOME_COMPOSITOR_ITEM_2" },
				{ icon	: "hand-o-right",	message	: "_WELCOME_COMPOSITOR_ITEM_3" },
				{ icon	: "floppy-o",		message	: "_WELCOME_COMPOSITOR_ITEM_4" }
			]
		};

		this.pageInfo.help					= 	help;
		this.pageInfo.height 				= 	heightInfo;
		this.pageInfo.colors				=	colorInfo;
		this.pageInfo.calendar				=	calendarInfo;
		this.pageInfo.servicesGrid			=	servicesGrid;
		this.pageInfo.servicesGrid.cols		=	await Promise.resolve(this.commons.translateRecursively(servicesCols, { label: 'header' }));

		this.pageInfo.rowData				= 	{};
		this.pageInfo.servicesVehicle		= 	{};

		this.pageInfo.export	= {
			items			: [
				{ label: 'CSV', icon: 'pi pi-refresh', command: () => { this.export('csv') }},
				{ label: 'PDF', icon: 'pi pi-refresh', command: () => { this.export('pdf') }},
			]
		}

		this.pageInfo.panels	= {		transporters	: false		};
	}

	export($format,$entity?,$info?)		{	
		if(undefined==$entity){
			this.commons.generateToast("Export","Generating "+$format+" for "+this.pageInfo.calendar.view.selected,"success");
			return;
		}
		let calendar;
		switch($entity){
			case "services"		:	
				let header = [
					"_DIRECTION", 
					"_NAME", 
					"_VEHICLE",
					"_TYPE",
					"_DATE",
					"_TIME",
					"_AREA", 
					"_ZONE",
					"_LODGINGS",										
					"_BOOKINGS"										
				];
				let items = this.getFilteredEntity('services',{ type: 'grid' })
								.map(item=>{
									let service = {};
									service["direction"	]	= item.direction;
									service["name"		]	= item.name;
									service["vehicle"	]	= item.vehicle;
									service["type"		]	= item.type;
									
									switch(item.direction){
										case "arrival":
											service["date"]	= item.date;
											service["time"] = item.pickupTime;
											break;
										case "departure":
											service["date"]	= item.date;
											service["time"] = item.pickupTime;
											break;
									}		
									
									service["area"		]	= item.area;
									service["zone"		]	= item.zone;
									service["bookings"	]	= (item.bookings||[]).join(',');
									service["hotels"	]	= (item.hotels	||[]).join(',');

									return service;														
								});

				items = [ header.map(h=>this.commons.getTranslate(h)), ...items ];
				
				switch($format){
					case "csv"	: this.commons.export("csv",items,"TMT_Services_"+this.commons.getToday('YYYYMMDD')); break;
				}
			break;
		}
	}

	async loadEntities() 				{
		this.calendar.date = this.commons.getToday('YYYY-MM-DD');
	}

	generateTransportFromGroup(type)	{}
	clearContainer(type)				{}

	setInfo($entity, $params){
		switch($entity){
			case "group"		:	switch($params.action){
				case "toggle"	:	$params.item.open = $params.item.open?false:true; break;
			}
		}
	}

	getInfo($entity, $params){
		switch($entity){
			case "tab"			:
				switch($params.type){
					case "is_selected"	: return $params.panel.selected==$params.tab.name?'selected':'';
					case "expander"		:
						switch($params.rowData.direction){
							case "arrival"	: return $params.tab.name!="departure";
							case "departure": return $params.tab.name!="arrival";
						}
						return true;
				}
				break;

			case "expanderForm"	: 	
				switch($params.item.direction){
					case "arrival"	:	
						this.pageInfo.expanderForm 				= expandedArrivalForm; 	
						this.pageInfo.tabs.rowexpander.selected = "arrival";
						break;						
					case "departure":	
						this.pageInfo.expanderForm 				= expandedDepartureForm;	
						this.pageInfo.tabs.rowexpander.selected	= "departure";
						break;
				}
				return this.pageInfo.expanderForm;

			case "grid"			:	switch($params.type){
										case 'cols'	: return $params["items"];
									}
									break;

			case "time"			:	switch($params.type){
										case "min_in_time"			:	
											let time = $params.item;
											let hour = parseInt(time)/60;
											let min  = parseInt(time)%60;
											return hour.toString().padStart(2,'0')+":"+min.toString().padStart(2,'0');
									}
									break;

			case "importer"		:	switch($params.type){
										case "color"				:	return $params.item.forced?"crimson":"forestgreen";
										case "active"				:	return this.pageInfo.importerFilters.filter(filter=>filter.active);										
									}
									break;

			case "calendar"		:	
				switch($params.type){
					case "transportsCalendar"	:	switch($params.query){
														case "hasItems"		:	return this.transportsCalendar.rows.some(row=>(row.items||[]).length>0);
													}
													break;
					case "tabs"					: 	return this.pageInfo.calendar.view.items;
				}
				break;
			
			case "service"		:				
				switch($params.type){
					case "show_action":
						let acceptable = [ "_NONE" ];
						switch($params.button.name){
							case "accept_service"	: acceptable = [ "_PENDING" 	]; break;
							case "reject_service"	: acceptable = [ "_PENDING" 	]; break;
							case "show_service"		: acceptable = [ "_ACCEPTED" 	]; break;
						}
						return acceptable.some(item=>item==$params.rowData.status);

					case "external_service_status_color":
						switch($params.item.status){
							default				:
							case "_NOT_SENT"	:	return "gray";
							case "_PENDING"		:	return "orange";
							case "_ACCEPTED"	:	return "forestgreen";
							case "_REJECTED"	:	return "crimson";
						}
						break;
				}
				break;

			case "langs"		:	switch($params["type"])	{	case "icon"			    :	return "/assets/layout/icons/flags/"+$params["lang"]+".png";	}	break;

			case "groups"		:	switch($params)			{	
										case "noItems"			: 	return ((this.groups||{}).count || 0 )==0;						
									}	
									break;
			case "group"		:	let group = $params["group"];
									if(undefined==group){
										console.log("NO GROUP");
									}
									switch($params["type"]){
										case "bookings"			:	switch(group.direction){
																		case "arrival":
																			return group.bookingsInfo.sort((a,b)=>a.arrival_Time>b.arrival_Time?1:-1);
																		case "departure":
																			return group.bookingsInfo.sort((a,b)=>a.departure_PickupTime>b.departure_PickupTime?1:-1);																		
																	}
																	break;
										case "is_empty"			:	return group.empty || (group.bookings||[]).length==0;
										case "flag"				:	return "/assets/layout/icons/flags/es.png";
										case "pax"				:	return group["bookings"]
																		.filter(item=>{
																			switch($params["assigned"]){
																				default		:	return true;
																				case true	:	return item.assgigned;
																				case false	:	return !item.assigned;
																			}
																		})
																		.reduce((acc,item)=>acc+parseInt(item.pax),0);
										case "firstTime"		:	return group["bookings"].reduce((min,item)=>min<item.time?min:item.time,100000);
										case "lastTime"			:	return group["bookings"].reduce((max,item)=>max>item.time?max:item.time,0);
										case "timeRange"		:	return group["bookings"].reduce((range,item)=>{
																		range['min'] = range.min<item.init_timestamp?range.min:item.init_timestamp;
																		range['max'] = range.max>item.init_timestamp?range.max:item.init_timestamp;									
																		range["diff"]= (range.max-range.min)/60;																			
																		return range;
																	},{min:1000000000000,max:0})['diff'];									
									}
									break;

			case "formatRange"	:	return ($params["value"]<10?'0':'')+$params["value"]+":00";

			case "timeRange"	:	let value = this.pageInfo.timeRangeValues.values[$params];
									return (value<10?'0':'')+value+":00";

			case "driver"		:	switch($params["attribute"])	{	
										case "thumbnail"		:	return $params["driver"]["avatar"] || "/assets/layout/images/drivers/0.png";
										case "thumbnailFromId"	:	const emptyDriver	= "/assets/layout/images/drivers/0.png";
																	if(undefined==$params["item"]){ return emptyDriver; }
																	let driver 			= this.drivers.data.find(driver=>driver.id==$params["item"]["id"]);
																	return driver && driver["avatar"]?driver["avatar"]:emptyDriver;
									}
									break;

			case "provider"		:	switch($params.type){
										case "serviceColor"		:	switch(parseInt($params.provider)){
																		case 1	:	return "blue";
																		case 2	:	return "forestgreen";
																		default	:	return "orange";
																	}
																	break;

										case "color"			:	switch(parseInt($params.provider)){
																		case 1	:	return "blue";
																		case 2	:	return "forestgreen";
																		default	:	return "orange";
																	}
																	break;

										case "restrictions"		:	let providerData = ( this["providers"].data || [] ).find(item=>item.selected) || {};
																	return this.pageInfo.providerRestrictions.map(item=>{
																		item.value = undefined==providerData["restrictions"]?item.default:providerData["restrictions"][item.name];
																		return item;
																	});

										case "selected"			:	return this["providers"].selected || {};

										case "selecteds"		:	return this["providers"].data.filter(item=>item.selected);

										case "_isSelect	ed"		:	if( null == this.providers.selected ){ return false; }
																	return this.providers.selected.id== $params.provider.id;

										case "isSelected"		:	return $params.provider.selected;

									}
			case "transferCol"	:	switch($params){
										case "height"				:	const v = this.pageInfo.calendar; return v.scaleOffset+(v.scaleFactorInitial*v.scaleRange*v.scaleFactor)+'px';
										case "serviceOnOverBorder"	:	return "10px solid red";
									}
			case "exec"			:	switch($params){
										case "params"			:	return this.pageInfo.execParams;
									}
		}
	}

	async initFilters()				{	// Zone filtering
										this.pageInfo.zonesFilter			= zonesFilter;
										// this.pageInfo.zonesFilter.items		= this.pageInfo.destinationInfo.pickupZones;
										this.pageInfo.zonesFilter.items		= ( this["providers"].selected || {}).zones;
										this.pageInfo.zonesFilter.selected	= "";
										// this.pageInfo.zonesFilter.selected	= (this.pageInfo.zonesFilter.items.find((item,index)=>index==0)||{})["name"];

									}

	checkLink(item,$event=null)
	{
		$event.cancelBubble=true;

		switch(item.status)			{	case "linked"	:		// Break link
																item.status 		= "open";
																item.color			= undefined;
																item.pair.status 	= "open";
																item.pair.color		= undefined;
																break;

										case "pending"	:		if(item===this.linkable.source)		{	// Remove linkables
																										this.linkable.source.status = "open";
																										this.linkable.source.color	= undefined;
																										this.linkable.source		= undefined;
																									}
																// If I'm not source I have to be target and clear it anyway
																if(undefined!==this.linkable.target){	this.linkable.target.status = "open";
																										this.linkable.target.color	= undefined;
																										this.linkable.target		= undefined;
																									}
																									break;

										case "open"		:
										default			:		if(undefined===this.linkable.source){	// I am Source element
																										this.linkable.source 		= item;
																										item.status			 		= "pending";
																										item.color					=  this.pageInfo.colors[item.status];
																} else 								{
																										// I am target element
																										// 1. Check if source is my type arrival or departure
																										if(this.linkable.source.type==item.type){	this.commons.generateToast("_ERROR","_SELECT_ARRIVAL_AND_DEPARTURE","error"); return false; }
																										// 2. Check if we are in the same row
																										if(this.linkable.source.row!==item.row)	{	this.commons.generateToast("_ERROR","_ITEMS_MUST_BE_IN_SAME_ROW","error"); return false; }
																										// 3. Check if items are consecutives
																										if(Math.abs(item.index-this.linkable.source.index)>1){	this.commons.generateToast("_ERROR","_ITEMS_CONSECUTIVE","error"); return false; }
																										this.linkable.target 		= item;
																										this.link();
																									}
																break;
									}
	}

	link(){
		this.linkable.source.status = "linked";
		this.linkable.source.color	= this.pageInfo.colors[this.linkable.source.status];
		this.linkable.source.pair	= this.linkable.target;

		this.linkable.target.status	= "linked";
		this.linkable.target.color	= this.pageInfo.colors[this.linkable.target.status];
		this.linkable.target.pair	= this.linkable.source;

		this.linkable.source		= undefined;
		this.linkable.target		= undefined;
	}

	getFilterButtonClass($filter,$item)	{	if ( $filter.multiple )	{	return $filter.selected==$item.name?'selected':'';		}
											else 					{	return $item.value?'selected':'';						}
										}

	/**
	 * after any calendar update checks with plates are still assigned
	 */
	assignPlates($params){
		let fleet 		= this.fleet.draggableSet 		|| [];
		let transports	= this.transportsCalendar.rows 	|| [];

		switch($params["type"]){
			case "nonEmpty":	// First unassign all plates
								fleet.forEach(type => {
									(type.vehicles||[]).forEach(item=>{
										item.status="unassigned";
									})
								});

								// Finally assign only plates with real services
								transports.forEach(row=>{
									fleet.forEach( type => {
										if(null==type.vehicles){ return; }
										let current = type.vehicles.find(item=>item.plate==row.plate);
										if(undefined!==current){
											current.status="assigned";
										}
									});
								})
								break;

			case "plate"	:	fleet.forEach( type => {
									if(null==type.vehicles){ return; }
									let current = type.vehicles.find(item=>item.plate==$params["plate"]);
									if(undefined!==current){
										current.status=$params["status"];
									}
								});
		}
	}

	remove($type,$info={},$event=null){

		if($event){ $event.cancelBubble=true; }
		
		switch($type){
			case "calendarRow"		:	$info["row"]["empty"	] 	= true;
										$info["row"]["assigned"	]	= false;									

										delete $info["row"]["items"];

										this.remove('calendarEmptyRows');
										break;

			case "calendarEmptyRows":	// Remove empty row
										this.transportsCalendar.rows = this.transportsCalendar.rows.filter(item=>item.empty || (item.items||[]).length>0);
										// ASsgin plates with services row
										this.assignPlates({ type: "nonEmpty" });
										break;

			case "calendarVehicle":		this.assignPlates({ type: "plate", plate: $info["row"].plate, status: "unassigned" });
										$info["row"].plate 	= undefined;

										($info["row"].items||[]).forEach(group=>{
											group.plate		= undefined;
											(group.bookingsInfo||[]).forEach(bi=>{
												bi.plate	= undefined;
											});
										})
										
										this.toggle('vehicle_plate_options',$info["row"],false);
										break;

			case "calendarVehicleType":	($info["row"].items||[]).forEach(group=>{
											group.assigned	= false;
											group.row		= undefined;
											group.vehicle 	= undefined;
											group.plate		= undefined;
											(group.bookingsInfo||[]).forEach(bi=>{
												bi.vehicle	= undefined;
												bi.plate	= undefined;
											});
										})

										// Remove frome calendar
										this.transportsCalendar.rows = this.transportsCalendar.rows.filter(row=>row.name!=$info["row"].name);

										// Update ownFleet item
										let vehicle = this.ownFleet.find(i=>i.name==$info["row"].code);
										if(vehicle){
											vehicle.assigned = false;
										}
										break;

			case "calendarTransport":	if(undefined!=$info["row"]){ 										
											$info["row"].items 		= $info["row"].items.filter(group=>group.name!=$info["item"].name);											
											$info["item"].assigned 	= false;
											$info["item"].vehicle	= undefined;
											$info["item"].plate		= undefined;
											($info["item"].bookingsInfo||[]).forEach(bi=>{
												bi.vehicle	= undefined;
												bi.plate	= undefined;
												bi.assigned	= false;
											});
										}
										break;

			default					:	alert("[remove] type "+$type+" not allowed");
		}
	}

	getServicesByTime($slot="hour"):any{
		let plain_services = [], services = {}, factor;
		switch($slot){
			default:
			case "hour":	factor = 60; break;
		}

		this.getFilteredEntity('groups').forEach(group=>{
			// plain_services = [ ...plain_services, ...group.transports ];
			plain_services.push(group);
		});

		let services_by_key = plain_services.reduce((o,item)=>{
			let hour 			= Math.trunc(parseInt(item.init)/factor);
			let formatted_hour 	= (hour>9)?hour+":00":"0"+hour+":00";
			// o[formatted_hour]	= {
			// 	hour	: formatted_hour,
			// 	items	: [...((o[formatted_hour]||{}).items||[]), item]
			// };

			if(item.vehicle){
				item.assigned = true;
			}

			// Plain booking info into service
			switch(item.private){
				case true:	if((item.bookings||[]).length>0){
								let booking			= item.bookings[0];
								item.customer		= booking.customer;
								item.area			= booking.area;
								item.airport		= booking.airport;
								item.flight			= booking.flight;
								item.reference		= booking.reference;
								item.time			= booking.time;
							}
							break;
			}

			if(undefined==o[formatted_hour]){
				o[formatted_hour]	= {
					hour	: formatted_hour,
					items	: []
				}
			}
			o[formatted_hour].items.push(item);
			return o;
		},{});

		services = Object.keys(services_by_key).reduce((a,key)=>{
			a.push(services_by_key[key]);
			return a;
		},[])

		return services;
	}

	// -----------------------------------------------------------------------------------
	// RENDERING METHODS
	// -----------------------------------------------------------------------------------

	areaEditor($me, $type, $col, $items){	alert('AREA EDITOR'); }
	getFieldEditor($col) 				{ 	return $col.editor ? $col.editor : 'input'; }
	
	getRenderer($type, $col, $items) 	{	
		return this.defaultRenderer($type, $col, $items);
	}

	getRendererNew($params)				{
		let $type 	= $params["type"	];
		let $col	= $params["col"		];
		let $items	= $params["items"	];
		let $name	= $params["name"	];
		return this.defaultRenderer($type, $col, $items);
	}

	defaultRenderer($type, $col, $items){	switch ($type) {
												case 'header'	: return this.defaultStyleRenderer($col);
												case 'style'	: return this.defaultStyleRenderer($col);
												case 'content'	: return $items[$col.field];
											}
										}

	defaultStyleRenderer($col) 			{	return {
												'width'		: $col.width ? $col.width : '',
												'text-align': $col.align ? $col.align : ''
											};
										}

	importedRenderer($type, $col, $items){	
		switch ($type) {
			case 'header'	: return this.defaultStyleRenderer($col);
			case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: 
				switch($items[$col.field]){
					case false	:	return "";
					case true	:	return "<i class='fa fa-times'></i>";
				}
		}
	}

	onSort() 							{ //console.log("onsort"); 
										}

	// -----------------------------------------------------------------------------------
	// END RENDERING METHODS
	// -----------------------------------------------------------------------------------

	/**
	 * Retrieve services from group depending on status
	 *
	 * @param item
	 * @param status
	 * @returns
	 */
	getGroupServices(group,status:any='all'){
		let services = group.transports
						.filter(item=>{
							switch(status){
								case "pending"	: return !item.assigned;
								default			: return true;
							}
						})
						.map(service=>{
							service.type		= group.type;
							service.zone 		= group.zone;
							service.firstTime	= group.firstTime;
							service.lastTime	= group.lastTime;
							service.pax			= group.pax>service.seats?service.seats:group.pax;
							// service.pax			= item.pax-service.seats>0?item.pax-service.seats:0;
							return service;
						});

		return services;
	}

	/**
	 * Retrieve ervicses by type and status
	 *
	 * @param status
	 * @returns
	 */
	setServices(){
		let services = [];
		// console.log("SERVICES VEHICLE",this.pageInfo.servicesVehicle);
		this.getFilteredEntity('groups').forEach(group=>{
			services =	[ 	...services, 
							...(group.transports||[])								
								.map(service=>{
									// "id", "vehicle", "time", "direction", "from", "to", "pax"
									// service.vehicle 	= group.name,
									service.type			= group.type;
									service.zone 			= group.zone;
									service.firstTime		= group.firstTime;
									service.lastTime		= group.lastTime;
									service.pax				= group.pax>service.seats?service.seats:group.pax;									
									service.time			= group.firstTime,
									service.direction 		= group.direction;
									service.area			= service.area || group.area;
									service.zone			= service.zone || group.zone;
									service.private			= group.private;

									if((group["bookings"]||[]).length>0){
										let booking				= group["bookings"][0];
										service.location 		= booking.accommodation;
										service.accommodation	= booking.accommodation;
									} 

									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else if(service.id){
										service.vehicle			= this.pageInfo.servicesVehicle[service.id];
									}
									if(service.vehicle){
										service.vehicle_plate	= service.vehicle.plate;
										service.vehicle_code	= service.vehicle.code;
									} else {
										service.vehicle			= {};
										service.vehicle_plate	= null;
										service.vehicle_code	= null;
									}
									let booking 			= service.bookings[0];

									switch(group.private){
										case true		: 	// PRIVATE
															if((group["bookings"].length>0)){
																service.area = group["bookings"][0].area;																
															}
															break;
										case false		:	// SHUTTLES
									}
									
									switch(service.direction){
										case "arrival"	: 	service.from 		= booking.airport;
															service.to			= service.area;
															break;
										case "departure": 	service.from 		= service.area;
															service.to			= booking.airport;
															service.PickupTime	= group.PickupTime;
															break;
									}
									return service;
								})
						];
		});

		this.services.items					= services;
		this.pageInfo.servicesGrid.total 	= (services||[]).length;
		this.pageInfo.servicesGrid.paginate	= services;
	}

	generateTransportsCalendar(){
		// return false;
		return this.transportsCalendar.rows.map((row,index)=>{
			let offset 	= 0;
			let prev;
			row.index	= index;
			row.items 	= (row.items||[])
							.sort((a,b)=>(a.init>b.init)?1:-1)							
							.map((item,index)=>{								
								item.pillow = this.serviceFiltersService.getPillow(prev,item) || 0;
								return item;
							})
							.map((item,index)=>{
								if(!item.preassigned){
									item.remove 		= false;
									if(undefined!=prev){
										if(prev.end>(item.init-item.pillow)){
											item.remove = true;		// Marked to be removed
										}
									}
									prev = item;
								}
								return item;
							})
							.map(item=>{
								let booking = item.bookings[0];
								item.offset 		= item.init - offset;
								item.size			= item.end  - item.init;
								offset				= item.end;								
								return item;
							}) || [];

			// Remove dynamic turns on empty rows
			if((row.items||[]).length==0){
				row.init_timestamp	= undefined;
				row.init 			= undefined;
				row.end_timestamp	= undefined;
				row.end				= undefined;
			}

			// Remove all non gapped elements
			row.items.forEach(item=>{
				if(item.remove){				
					this.remove("calendarTransport",{ item: item, row: row });
					item.remove = false;
				}
			})
			return row;
		})
	 }

	/**
	 * get calendar of services
	 * 
	 * @param $type 
	 * @returns 
	 */
	getTransportsCalendar($type='all',$info={}){
		
		let generatedRows = this.generateTransportsCalendar();		
		let rows;
	
		switch($type){
			case "all"		:	rows = generatedRows;									break;
			case "empty"	:	rows = generatedRows.filter((item,index)=>index==0);	break;
			case "nonEmpty"	:	rows = generatedRows.filter((item,index)=>index>0);
								
								// Retrieve rows and empty rows with at least 15 elements
								// to avoid empty screen
								const minRows 	= 15;
								const emptyRows	= Math.max(0,minRows-rows.length);
												
								let order	= 	this.pageInfo.calendar.buttons.servicesButtons.selectors.order.selected;
										
								switch(order){
									case "service_order_by_first":	
										rows = rows.sort((a,b)=>{
											let a_first = a.items[0];
											let b_first = b.items[0];
											if(undefined == a_first || undefined == a_first.pickupTime ){ return -1; }
											if(undefined == b_first || undefined == b_first.pickupTime ){ return  1; }
											return a_first.pickupTime >= b_first.pickupTime ?1:-1;
										});
										break;
									case "service_order_by_name":
										rows = rows.sort((a,b)=>a.name>=b.name?1:-1);
										break;
								}

								rows = [ ...rows, ...Array.from(Array(emptyRows).keys()).map(item=>({ empty: true, seats: 0 })) ];							
								return rows;
								break;

			default			:	return [];
		}

		return rows.sort((a,b)=>{
			if( a.empty &&  b.empty	){ return  1; 	}
			if( a.empty && !b.empty	){ return -1; 	}
			if(!a.empty &&  b.empty	){ return  1;	}
			return (a.name>b.name?1:-1);
		});
	}	
	
	generateTransportersCalendar()			{
		this.transportersCalendar.rows	= this.transporters.draggableSet
												// .filter(item=>item.name!="_INNER_FLEET")
												.map(transporter=>	({
													transporter	: transporter,
													items		: this.transportsCalendar.rows.filter(item=>item.transporter==transporter.name)
												}));
	}

	getTabInfo($type,$tab,$panel,$params={})	{	switch($type){
														case "select"	:	if( !this.getTabInfo('disabled',$tab,$panel) ){ $panel.selected = $tab.name; }; break;
														case "disabled"	:	if(undefined==$tab["isDisabled"]){ return false; }
																			return this.isDisabled($tab["isDisabled"],$tab);
														case "selected"	:	return $panel["selected"]==$tab["name"]?'selected':'';
													}
												}

	toggle($type,$item=null,$status=undefined){
		switch($type){
			case "booking"				:	$item.expanded				= $item.expanded?false:true; break;
			case "provider"				:	this.providers.data 		= this.providers.data.map(item => { item.selected = item.id == $item.id; return item; }); break;
			case "transporters"			:	this.pageInfo.panels.transporters	= true; break;
			case "itemFleet"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "itemCalendar"			:	$item.open					= $status!=undefined?status:($item.open==true?false:true);
											$item.zindex				= $status!=undefined?status:($item.open?1:0);
											break;
			case "fullscreen"			:	toggleFullScreen(this); break;
			case "service"				:	// If every transport is assigned just close or not open
											// if(($item.transports||[]).every(item=>item.assigned))	{	$item.open = false;			}
											// else 													{	this.toggle("group",$item);	}
											this.toggle("group",$item);
											break;
			case "group"				:	$item.open					= $status!=undefined?status:($item.open==true?false:true);	break;
			case "fleet"				:	this.fleet.open				= $status!=undefined?status:(this.fleet.open==1?0:1);
											this.fleet.draggableSet 	= this.fleet.draggableSet.map(item=>{ item.open = this.fleet.open; return item; });
											break;
			case "drivers"				:	this.drivers.open			= $status!=undefined?status:(this.drivers.open==true?false:true); 				break;
			case "vehicle_type_options"	:	$item.vehicle_type_options	= $status!=undefined?status:($item.vehicle_type_options==true?false:true); 		break;
			case "vehicle_plate_options":	$item.vehicle_plate_options	= $status!=undefined?status:($item.vehicle_plate_options==true?false:true); 	break;
		}
	}

	generateMenuCols($entity) {
		switch($entity){
			case "services"	: this[$entity].cols = servicesCols;	break;
		}
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
	}

	getFilteredEntity($entity,$info:any={}){
		let selected;
		let results;
		let plates;

		switch($entity)	{
			case "services"	:	return this.getFilteredServices($entity,$info);	break;
		}
	}

	private getFilteredServices($entity,$info){
		let services;

		switch($info.type){
			case "mapped"		:	services = this.pageInfo.services;										break;		
			// case "grid"			: 	services = this.getServices({ type: 'grid', show_unassigned: true });	break;
			// default				: 	services = this.getServices({ type: 'list', status: $info.status }); 	break;
		}
		
		this.pageInfo.services_qty = (services||[]).length;

		switch($info.mode){
			default				:	return services;
		}
	}

	isDisabled($type,$item)				{	switch($type){
												case "transferBookings"	:	return !this.pageInfo.selectedRoutes.bookingPanelOpen; break;
											}
										}

	/**
	 * do autocomplete search
	 */
	doSearch($entity,$info){
		switch($entity){
			case "transporter"	: return [{id:1,name:"transporter_1",	value:"tramsporter_1_1"	}];	break;
			case "vehicle"		: return [{id:1,name:"vehicle_1",		value:"vehicle_1_1b"	}];	break;
			case "plate"		: return [{id:1,name:"plate_1",			value:"plate_1_1"		}]; break;
		}
	}

	toggleRow($item) 				{ this.toggleDT($item); 						}
	expandRow($item) 				{ this.toggleDT($item); 						}
	collapseRow($item) 				{ this.toggleDT($item); 						}

	/**
	 * actions to take once we open row with expander
	 * 
	 * @param $item 
	 * @returns 
	 */
	toggleDT($item) 				{
		console.log($item);
		if (undefined === $item) { return false; }
		if (undefined == this[$item.table].expandedRowKeys[$item.id]) {
			this[$item.table].expandedRowKeys 				= [];
			this[$item.table].expandedRowKeys[$item.id] 	= 1;
			this.rowData[$item.table] 						= $item;									
		} else {
			this[$item.table].expandedRowKeys 				= [];
		}
	}

	private async changeExternalServiceStatus($info){
		let $item 		= $info.item;
		let $transporter= $info.transporter;
		let response;

		switch($item.action){
			case "_ACTION_SEND"	: 
				this.commons.generateToast("_INFO","_PENDING","info");
				let transporterId	= "PdhgU0wu5MW1dOXbuKTD";
				let docPath			= "/transporters/"+transporterId+"/requests";
				
				// Save into Transporter
				$item.dmc			= this.commons.userInfo.currentDmc.id;
				$item.destination	= this.commons.userInfo.currentDestination.id;
				let parsedItem		= JSON.parse(JSON.stringify($item));
				response			= await this.firebaseService.addDoc(docPath,parsedItem);
				if(!response.id){
					this.commons.generateToast("_ERROR","_TRANSPORTER_REQUEST_SAVE_FAILED","error");
					return false;
				}
				
				// Save into Dmc destination
				docPath				= "/dmcs/"+$item.dmc+"/destination/"+$item.destination+"/transporters/"+transporterId+"/requests";
				parsedItem.id		= response.id;
				response			= await this.firebaseService.setDoc(docPath,parsedItem);
				
				console.log(response);
				
				if(!response.id){
					this.commons.generateToast("_ERROR","_TRANSPORTER_REQUEST_DMC_SAVE_FAILED","error");
					return false;
				}
				
				$item.status 		= "_PENDING";	
				$item 				= this.updateExternalServiceStatus($item);				
				
				break;
			case "_ACTION_CLEAR": 			
				this.commons.generateToast("_INFO","_NOT_SEND","info");		
				// $item.status = "_NOT_SEND";
				$transporter.items = $transporter.items.filter(item=>item!=$item);
				break;
		}
	}

	private updateExternalServiceStatus($info){
		$info.status = $info.status || "_NOT_SEND";
		switch($info.status){
			case "_NOT_SEND"	: $info.action = "_ACTION_SEND"; 	break;
			case "_PENDING"		: $info.action = "_ACTION_NONE"; 	break;
			case "_ACCEPTED"	: $info.action = "_ACTION_NONE"; 	break;
			case "_REJECTED"	: $info.action = "_ACTION_CLEAR"; 	break;
		}
		return $info;
	}

	private showService($info){
		let item = $info.item;
		this.pageInfo.rowData			= $info.item;
		// this.pageInfo.form				= [
		// 	{ label : "_NAME", field: "name" },
		// 	{ label : "_AREA", field: "area" }
		// ];
		// this.pageInfo.form				= expandedForm;
		this.pageInfo.overlay_service 	= true;
	}

	private async doServiceAction($info){
		let $item		= $info.item || {};
		let response;

		switch($info.action){
			case "show": return this.showService($info);
		}

		try {
			if(undefined==$item.status	){ throw new Error("_STATUS_NOT_FOUND");	} 
			if(undefined==$item.name	){ throw new Error("_ITEM_NOT_FOUND"); 		}

			// Update status
			this.pageInfo.services		= this.pageInfo.services || [];
			let service 				= this.pageInfo.services.find(item=>item.name==$item.name);
			if(!service){
				this.commons.generateToast("_ERROR","_SERVICE_NOT_FOUND","error");
				return false;
			}
			service.status				= $info.status;
			service.transporter_status	= $info.status;
			
			// Transporter DOC
			response = await this.firebaseService.updateDocPromise(
				this.pageInfo.servicesPath,
				{ items	: this.pageInfo.services }
			);

			// DMC transporter doc
			let dmcId						= "1";
			let destinationId				= "QGBHKJzKHcGtoiVY5tVU";
			const transporterId				= "OgJXDV8fA7KoEBpCAQNw";
			const date						= this.calendar.date;
			let dmcTransporterRequestPath 	= "/dmcs/"+dmcId+"/destinations/"+destinationId+"/transporters/"+transporterId+"/requests/"+date;

			response = await this.firebaseService.updateDocPromise(
				dmcTransporterRequestPath,
				{ items	: this.pageInfo.services }
			)

		}catch(e){
			this.commons.generateToast("_INFO",e.message || "_SERVICE_ACTION_ERROR","info");
			return false;
		}
	}

	private copyServicesToClipboard(){
		this.commons.generateToast("_INFO","copy services to clipboard","info");
	}

	private acceptAllServices(){
		// this.commons.generateToast("_INFO","accept all services","info");
		(this.pageInfo.services||[])
			.filter(service	=>service.status=="_PENDING")
			.forEach(service=>{
			this.doServiceAction({ item: service, status: "_ACCEPTED" });
		})
	}

	private rejectAllServices(){
		// this.commons.generateToast("_INFO","reject all services","info");
		(this.pageInfo.services||[])
			.filter(service	=>service.status=="_PENDING")
			.forEach(service=>{
			this.doServiceAction({ item: service, status: "_REJECTED" });
		})
	}

	async doSubscribe(){
		this.pageInfo.transporterRef	= "PlPMNb8Fwgh7GMkAqXdD";
		this.pageInfo.transporterPath	= "/transporters/"+this.pageInfo.transporterRef;
		let servicesPathRel				= this.pageInfo.transporterPath+"/requests/";
		this.calendar.date				= this.calendar.value.toISOString().split('T')[0];		
		this.pageInfo.servicesPath 		= servicesPathRel+this.calendar.date;
		// this.pageInfo.servicesPath 		= servicesPathRel+"2024-02-14";

		let transporterInfo:any	= await this.firebaseService.getDocData(this.pageInfo.transporterPath);
		if(transporterInfo.id){
			this.pageInfo.transporter = transporterInfo;
		}

		// Unsubscribe previous
		if(this.pageInfo.subscriptions["calendar"]){
			this.pageInfo.subscriptions["calendar"] = undefined;
		}

		// Subscribe current
		this.pageInfo.subscriptions["calendar"] = (await this.firebaseService.subscribeEntityDoc(this.pageInfo.servicesPath))
													.subscribe(info=>{
			if(info && info["items"]){														
				this.pageInfo.services = info["items"];
			}
		})
	}

	doCalendarChange(){
		this.calendar.date		= this.calendar.value.toISOString().split('T')[0];		
		this.pageInfo.services	= [];
		this.doSubscribe();
		this.calendar.last = this.calendar.value;		
	}

	/**
	 * execute action
	 * 
	 * @param $type 
	 * @param $action 
	 * @param $info 
	 * @returns 
	 */
	doAction($type,$action,$info){
		let proposed = {};
		switch($type){
			case "calendar"	:
				switch($action)	{
					case "change":	this.doCalendarChange(); break;
				}
				break;
			case "control"	:	
				switch($action)	{
					case "doButton"				:	
						switch($info["item"].name){
							case "accept_all_services"			: this.acceptAllServices();					break;
							case "reject_all_services"			: this.rejectAllServices();					break;
							case "export_services"				: this.export("csv","services");			break;
							case "copy_services_to_clipboard"	: this.copyServicesToClipboard(); 			break;						
						}
						break;
				}
				break;											
			
			case "button"	:
				switch($info.type){
					case "service":
						switch($action){
							case "accept_service"	: this.doServiceAction({ item: $info.rowData, status: "_ACCEPTED" 	}); break;
							case "reject_service"	: this.doServiceAction({ item: $info.rowData, status: "_REJECTED" 	}); break;
							case "show_service"		: this.doServiceAction({ item: $info.rowData, action: "show" 		}); break;
						}
						break;
				}
				break;

			case "row"					:	switch($action){
												case "toggle":	
													if($info.table){ 
														$info.rowData.table = $info.table; 
													}
													this.toggleRow($info.rowData);													
											}					
											break;

			case "calendar"				:	switch($action){
												case "clear_selected": 	this.pageInfo.serviceSelected = undefined; 
																		break;
												case "remove_vehicle": 	this.remove($info.row.plate?'calendarVehicle':'calendarVehicleType',{ row: $info.row },$info.event);
																		break;																			
											}											
											break;

			case "zone"					:	switch($action){
												case "select": this.pageInfo.selectedZone = $info.item; break;	
											}
											break;

			case "table"				:	switch($action){
												case "click": 	switch($info.col.field){
													case "reference": this.commons.copyToClipboard($info); 	break;													
												}
											}
											break;										

			case "transfer"				:	switch($action)	{
												case "assign"			:	$info["item"].assigned	= $info["item"].assigned?false:true;
																			$info["item"].status	= $info["item"].status || "pending";
																			break;
												case "changeStatus"		:	switch($info["item"].status){
																				case "pending"	: $info["item"].status = "accepted"; break;
																				case "accepted"	: $info["item"].status = "rejected"; break;
																				case "rejected"	: $info["item"].status = "pending"; 	break;
																			}
																			break;
											}
											break;

			case "tab"					:	switch($action)	{
												case "select"			: 	if(!$info["tab"].disabled){	this.pageInfo.calendar.view.selected=$info["tab"].name;}	break;
											}
											break;

			case "wheel"				:	switch($info["type"]){
												case "X"				:	if(undefined==$info["event"]){ return false; }
																			$info["event"].currentTarget.scrollLeft += $info["event"].deltaY * this.pageInfo.wheel.xFactor;
																			$info["event"].preventDefault();
																			$info["event"].stopPropagation();
																			break;

												case "Y"				:	if(undefined==$info["event"]){ return false; }
																			$info["event"].stopPropagation(); 
																			break;

												case "slider"			:	this.pageInfo.timeRangeValues.initTime = Math.max(	this.pageInfo.timeRangeValues.min,
																																Math.min(	this.pageInfo.timeRangeValues.max,
																																			this.pageInfo.timeRangeValues.initTime + Math.ceil($info["event"].deltaY/this.pageInfo.wheel.sliderFactor)
																																		)
																															);
																			if(undefined==$info["event"]){ return false; }
																			$info["event"].stopPropagation();
																			break;
											}
											break;

			case "button"				:	
				switch($action){
					case "reload"			:	this.loadEntities();							break;
					case "zones"			:	this.pageInfo.zonesFilter.selected = this.pageInfo.zonesFilter.selected==$info["name"]?'':$info["name"];
												break;
					case "toggle"			:	if( undefined==$info["button"] || undefined==$info["item"] ){ return false; 		}
												if( $info["button"].multiple )	{
													// Check there is at least MIN_SELECTED_ITEMS
													if( 	$info["button"].minSelected
														&&	$info["item"].value
														&&	($info["button"].items.filter(item=>item.value)||[]).length<=$info["button"].minSelected
													){ return false;	}
													$info["item"].value 		= $info["item"].value?false:true;
												}
												else						{	$info["button"].selected 	= $info["item"].name;	}
												break;
				}
				break;

			case "panel"				:	switch($action){
												case "save"				:	// Set operative values
																			this["transporters"].data 			= this["transporters"].draggableSet.map(item=>item); break;
												case "close"			:	// Restore values
																			// this["transporters"].draggableSet	= this["transporters"].data.map(item=>item);
																			this.pageInfo.panels[$info] = false;
																			break;
												case "toggle_shared"	:	$info["shared"]  = !$info["shared"];	break;
												case "toggle_private"	:	$info["private"] = !$info["private"];	break;
											}
											break;

			case "transporter"			:	switch($action){
												case "filter":	switch($info.action){
													case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
																	else					{	$info.item.selected = $info.item.selected?false:true; 	}
																	break;
													}
													break;
											}
											break;

			case "service"				:	
				switch($action){
					case "change_external_service_status":	
						this.changeExternalServiceStatus($info);
						break;

					case "filter"			:	switch($info.action){
													case "toggle":	if($info.item.forced)	{ 	$info.item.selected = true; 							}
																	else					{	$info.item.selected = $info.item.selected?false:true; 	}
																	break;
												}
												break;

					case "remove"			:	this.remove('calendarTransport',{ item: $info.item, row: $info.row },$info.event);
												break;

					case "select"			:	if((this.pageInfo.selectedRoutes.items || []).length==0){
													this.pageInfo.selectedRoutes.zone	= undefined;
													this.pageInfo.selectedRoutes.type	= undefined;
												}
												this.pageInfo.selectedRoutes.zone	= undefined==this.pageInfo.selectedRoutes.zone	? $info.item.zone: this.pageInfo.selectedRoutes.zone;
												this.pageInfo.selectedRoutes.type	= undefined==this.pageInfo.selectedRoutes.type	? $info.item.type: this.pageInfo.selectedRoutes.type;

												if(this.pageInfo.selectedRoutes.zone!==	$info.item.zone	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_ZONE_ERROR",		"error");	return false; 	}
												if(this.pageInfo.selectedRoutes.type!==	$info.item.type	){	this.commons.generateToast("_ERROR","_ROUTE_SELECTED_DIRECTION_ERROR",	"error");	return false;	}

												$info.item.selected = $info.item.selected?false:true;

												// Add items if selected, previous removing if already exists
												this.pageInfo.selectedRoutes.items	= ( this.pageInfo.selectedRoutes.items || [] ).filter(item=>item!=$info.item);
												this.pageInfo.selectedRoutes.items	= $info.item.selected?[ ...this.pageInfo.selectedRoutes.items, $info.item ]:this.pageInfo.selectedRoutes.items;

												this.doAction("service","check",{});
												break;

					case "clear"			:	this.pageInfo.selectedRoutes.items = [];
												this.doAction("service","check",{});
												break;

					case "check"			:	this.pageInfo.selectedRoutes.bookingPanelOpen = this.pageInfo.selectedRoutes.items.length>0;
												if(!this.pageInfo.selectedRoutes.bookingPanelOpen){ this.pageInfo.controlPanels.transfers.selected = "routes"; }
												break;
				}
				break;
		}
	}
}