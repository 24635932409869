export const filters	= [
	{ 	
		showLabel	: true,
		name		: 'country',
		type		: 'dropdown',
		position	: 'left',
		label		: '_COUNTRY',
		items		: [],
		selected	: []
	},
	{ 	
		showLabel	: true,
		name		: 'destination',
		type		: 'dropdown',
		position	: 'left',
		label		: '_DESTINATION',
		items		: [],
		selected	: []
	},
	{ 	
		showLabel	: true,
		name		: 'station',
		type		: 'dropdown',
		position	: 'left',
		label		: '_STATION',
		items		: [],
		selected	: []
	},
	{ 	
		showLabel	: true,
		name		: 'profile',
		type		: 'dropdown',
		position	: 'left',
		label		: '_PROFILE',
		items		: [],
		selected	: []
	}
];