export const pages = {
    admin		:	{
        admin : {
            notLoggedPage			    : "/",

            // loggedWithSetupDone	    : "/create_destination",
            loggedWithSetupDone	    : "/bookings",
            // loggedWithSetupDone		    : "/transport_compositor",
            // loggedWithSetupDone	    : "/checkins",
            // loggedWithSetupDone	    : "/dashboard",
            // loggedWithSetupDone		: "/externals",

            // loggedWithSetupNoDone	: "/create_destination",
            loggedWithSetupNotDone	: "/bookings",
            // loggedWithSetupNotDone	    : "/transport_compositor",
            // loggedWithSetupNotDone	: "/checkins",
            // loggedWithSetupNotDone	: "/dashboard",
            // loggedWithSetupNotDone	: "/externals"
        }
    },
    dmc			:	{
        dmc_admin	:	{
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/dashboard",
            loggedWithSetupNotDone	: "/dashboard"
            // loggedWithSetupNotDone	: "/basic"
        },
        officer :	{
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/dashboard",
            loggedWithSetupNotDone	: "/dashboard"
            // loggedWithSetupNotDone	: "/basic"
        }

    },
    reseller	:	{
        reseller		:	{
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/dashboard_reseller",
            loggedWithSetupNotDone	: "/dashboard_reseller"
        },
        reseller_admin	:	{
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/dashboard_reseller",
            loggedWithSetupNotDone	: "/dashboard_reseller"
        },
        reseller_operator	:	{
            notLoggedPage			: "/",
            loggedWithSetupDone		: "/create_destination",
            loggedWithSetupNotDone	: "/create_destination"
        }

    }
};