import { CarService                             } from '../../service/carservice';
import { HttpClient                             } from '@angular/common/http';
import { CommonsService                         } from '../../service/commons.service';
import { EntityService                          } from '../../service/entity.service';
import { MessageService                         } from 'primeng/components/common/messageservice';
import { OnInit, 
		 Component, 
		 ViewEncapsulation, 
		 Inject 								} from '@angular/core';
import { MouseEvent                             } from '@agm/core';
import { MapService                             } from '../../service/mapservice';
import { DOCUMENT                               } from '@angular/platform-browser';
import { filters, entitiesToDisplay				} from './data/filters';
import { info									} from './data/pathInfo';
import { StorageService 						} from '../../service/storageservice';

interface marker {
	lat			: number;
	lng			: number;
	label?		: string;
	draggable	: boolean;
}

@Component({
    styleUrls		: ['./map.component.scss'],
	templateUrl		: './map.component.html',
	encapsulation	: ViewEncapsulation.None,
	providers		: [ MessageService ],
})
export class MapComponent implements OnInit 
{
	waypoints 	: any [] = [];
	hourTime 	= new Date();
	date 		: Date;
    icon = {
		url:"assets/demo/images/maps/infoMarker.png",
		scaledSize: {
		  width: 50,
		  height: 50
		}
	}
	pageInfo 	: any = {
		//******* left Panel *******
		itemsToDisplay 				: [],
		entitiesToDisplay 			: [],
		subEntitiesToDisplay		: [],
		entitySelected 				: null,
		subEntitySelected			: null,
		currentSubEntitiesSelected 	: [],
		/*****************************/
		
		valHour		: 4,
		fullScreen	: false,
		cls			: {
			map			: '',
			toolbar		: ''
		},
		busTour 			: [],
		displayTransfers	: [],
		busPannelInfo	: [{
			type 	: 'arrival',
			items 	: []
		},
		{
			type 	: 'pickup',
			items 	: []
		}
		],
		displayMessage 			: false,
		currentDriverSelected	: null,
		messagesToDrivers 		: [], 
		listMessages			: false
	}

	//CALENDAR INFO
	zoom	:number =	11;
	lat		:number =  	39.639938;
	lng		:number =  	2.894679;

	origin		: any;
	destination : any;
	//FILTER AND PANEL DATA
	zones 			: any 	= {};
	routes 			: any 	= {};
	areas 			: any	= {};
	hotels 			: any 	= {};
	excursions 		: any 	= {};
    constructor(
		private http			: HttpClient,
        private commons			: CommonsService,
        private entityService	: EntityService,
		private messageService	: MessageService,
		private carService		: CarService,
		private mapService 		: MapService,
		private storageService	: StorageService,
		
		@Inject(DOCUMENT) private document: any
    ) { 
		this.pageInfo.itemsToDisplay 	= [];

		this.init();
		this.loadEntities();
	}
	
	ngOnInit() { }
	
	init() 
	{
		this.pageInfo.elem 				= document.documentElement;
		this.pageInfo.info 				= info;
		this.pageInfo.filters 			= filters;
		this.pageInfo.entitiesToDisplay = entitiesToDisplay;
		//check the 'default' filters selected
		// this.pageInfo.itemsToDisplay	= this.pageInfo.filters.map(item => {return {entity : item.entity, selecteds : item.selected}});
		this.pageInfo.itemsToDisplay	= this.pageInfo.filters;
		this.pageInfo.filters.forEach(el =>{
			if(el.selected.length > 0){
				el.selected.forEach(entity => {
					console.log('THIS ENTITY IS SELECTED:',entity)
					this.checkFilter(el,entity,true);
				})
			}
		})
	}

	assignEntitiesToDisplay(){
		// this.pageInfo.entitiesToDisplay	= this.pageInfo.itemsToDisplay.filter(el=> el.selecteds.length > 0).map(item => {return {name : item.entity}});
		this.pageInfo.entitiesToDisplay.find(el => el.entity == 'mainInfo').items 	 = this.pageInfo.filters.filter(entity => entity.selected.length > 0).map(selected => {return {label: selected.label, value: selected.name}});
		console.log("Entities to display",this.pageInfo.entitiesToDisplay);
		console.log(this.pageInfo.subEntitiesToDisplay);
	}

	async loadEntities() {		
        // await this.load('bookings');
		// await this.load('groups'); 
		await this.load('routes');  
		await this.load('areas');
		await this.load('hotels');   
		await this.load('zones');  
		await this.load('excursions');
	}
	
	getDirection(){
		this.origin 	 = { lat : 	39.638464, lng	:	3.003831};
		this.destination = { lat : 	39.78901,  lng 	:	3.124456 };
		console.log("Way points ",this.waypoints); 
	}

	//Search if the element from the entity is selected
	checkIfDisplay(entity,element){
		let findEntity = this.pageInfo.itemsToDisplay.findIndex(el => el.entity == entity);
		if(findEntity > -1){
			return this.pageInfo.itemsToDisplay[findEntity].selected.indexOf(element) > -1;
		}else{
			return false;
		}
	}
	
	mapClicked($event: MouseEvent) {
		this.pageInfo.markers.push({
			lat: $event.coords.lat,
			lng: $event.coords.lng,
			draggable: true
		});
	}


	//Process to emulate the moves from the transfers
	startTrack()
	{
		let timeFactor = 3000;
		for (let i=0; i<this.pageInfo.displayTransfers.length; i++) {
			for(let x = 0; x<this.pageInfo.displayTransfers[i].route.points.length; x++){
				setTimeout( () => 
				{					
					this.pageInfo.displayTransfers[i].points.lat = this.pageInfo.displayTransfers[i].pointsToVisit[x].lat;
					this.pageInfo.displayTransfers[i].points.lng = this.pageInfo.displayTransfers[i].pointsToVisit[x].lng;

					this.pageInfo.displayTransfers[i].pointsVisited.push({lat:this.pageInfo.displayTransfers[i].points.lat,lng:this.pageInfo.displayTransfers[i].points.lng});
					
					// console.log(this.pageInfo.displayTransfers[i]);
					
					this.markCurrentLodgingArrival(this.pageInfo.displayTransfers[i]);
					this.markCurrentLodgingPickup(this.pageInfo.displayTransfers[i]);

					// console.log(this.pageInfo.displayTransfers[i].pointsVisited, this.pageInfo.displayTransfers[i].route.points.length);

					if(this.pageInfo.displayTransfers[i].pointsVisited.length == this.pageInfo.displayTransfers[i].route.points.length - 1){
						// this.showInfo('info','Bus with route '+this.pageInfo.displayTransfers[i].route.nameRoute+' has finished','In time');
						this.commons.generateToast('Finished','Bus with route '+this.pageInfo.displayTransfers[i].route.nameRoute+' has finished','success',2);

						this.updateStatus(this.pageInfo.displayTransfers[i].id,'finished');
						if(i == this.pageInfo.displayTransfers.length -1){
							// this.showInfo('info','All routes finished','In time');
							this.commons.generateToast('Finished','All routes finished!','success',2);

						}
					}
				}, x * timeFactor );
			}
		}
	}
	/**
	 * Mark the next stop for the transfer if it has some element to visit from the arrival
	 * @param currentBus The transfer with the route planned
	 */
	markCurrentLodgingArrival(currentBus){
		currentBus.lodgingsArrival.forEach(el =>{
			el.icon = this.getImage(el.type);
		})
		let lodgingsToVisitArrival = currentBus.lodgingsArrival
									.filter(el => currentBus.pointsVisited.findIndex( point => 
												( el.coords.lat == point.lat ) && 
												el.coords.lng == point.lng ) == -1);
		if(lodgingsToVisitArrival.length >0){
			currentBus.lodgingsArrival.findIndex(el => (el.coords.lat == lodgingsToVisitArrival[0].coords.lat) && 
														el.coords.lng == lodgingsToVisitArrival[0].coords.lng)
			lodgingsToVisitArrival[0].icon = this.getImage(lodgingsToVisitArrival[0].type,'active')
		}													
	}

	/**
	 * Mark the next stop for the transfer if it has some element to visit from the pickup
	 * @param currentBus The transfer with the route planned
	 */
	markCurrentLodgingPickup(currentBus){
		currentBus.lodgingsPickup.forEach(el =>{
			el.icon = this.getImage(el.type);
		})
		let lodgingsToVisitArrival = currentBus.lodgingsPickup
									.filter(el 		 => currentBus.pointsVisited
									.findIndex(point => (	el.coords.lat == point.lat ) && 
															el.coords.lng == point.lng ) == -1);
		if(lodgingsToVisitArrival.length >0){
			currentBus.lodgingsPickup.findIndex(el => (el.coords.lat == lodgingsToVisitArrival[0].coords.lat) && 
														el.coords.lng == lodgingsToVisitArrival[0].coords.lng)
			lodgingsToVisitArrival[0].icon = this.getImage(lodgingsToVisitArrival[0].type,'active')
		}		
	}
	 			
	async load($entity) {
		switch ($entity) 
		{
			case 'areas':
                this[$entity].data 		= [];
                this[$entity].tree 		= [];
                this[$entity].loading 	= true;

				await this.entityService.loadEntity($entity);
			
				this[$entity].data 		= this.commons.getEntity($entity);
				this[$entity].tree    	= [
					{
						"label"			: 	"Areas",
						"data"			: 	"Areas",
                        "expandedIcon"	: 	"fa fa-folder-open",
						"collapsedIcon"	: 	"fa fa-folder",
						"expanded"		:	true,
						"children"		: 	[{ id: 1, label: 'test', icon: 'fa fa-folder'}]
					}
				];
				
				if(undefined!==this[$entity] && undefined!==this[$entity].data){
					this[$entity]["tree"][0]["children"] = this[$entity].data.map(item => ({
																id    	: item.id,
																label	: item.name,
																icon 	: 'fa fa-folder'
															}));					
				}
			
				this[$entity].loading = false;
                break;

            case 'hotels':
				let hotelsArea1		= [];
				let hotelsArea2 	= [];
				this[$entity].data 	= [];
				
				await this.entityService.loadEntity('hotels_');
				hotelsArea1 		= this.entityService.get('hotels_');

				await this.entityService.loadEntity('hotels2');

				hotelsArea2 		= this.entityService.get('hotels2');
				this[$entity].data 	= [].concat(hotelsArea1,hotelsArea2).map(lodging => {return {...lodging,icon:this.getIconCategoryLodging(lodging.category)}});
				
				console.log('DATA LODGINGS')
				console.log(this[$entity].data);

				// this[$entity].data = [].concat(hotelsArea1,hotelsArea2);

				break;	
			
			case 'zones':
				this[$entity].data 	= [];
				let ourZones 		= this.storageService.getItem('zones');
				if(ourZones){
					this[$entity].data = this.mountPolygons(ourZones);
					this[$entity].data.unshift({areas:[],name:'Airport',id:'airport'})
					this.pageInfo.filters.find(el => el.entity == 'zones').items = []
					this.pageInfo.filters.find(el => el.entity == 'zones').items = this.pageInfo.filters.find(el => el.entity == 'zones').items.concat(this.storageService.getItem('filtersZones'));
					this.pageInfo.filters.find(el => el.entity == 'zones').items.unshift({ label : 'Airport',		value: 'airport'})
				}else{
					await this.entityService.loadEntity($entity);
					this[$entity].data = this.entityService.get($entity).map((el,index) => ({...el, color : this.mapService.decideColorPolygon(index),status :'Avaliable'}));;
				}
				console.log('ZONES');
				console.log(this[$entity].data)			
				break;
			
			case 'routes':
				this[$entity].data = [];
				await this.entityService.loadEntity($entity);
				this[$entity].data = this.entityService.get($entity);
				
				this.initTransports();
				
				break;

			case 'excursions':
				this[$entity].data = [];
				await this.entityService.loadEntity($entity);
				this[$entity].data = this.entityService.get($entity).map(excursion => {return{
					...excursion,
					pointInfo 	: {
						coords 	: {
							lng 	: excursion.points[25].lng,
							lat 	: excursion.points[25].lat
						},
						icon	: this.pageInfo.info.pathInfoMarker
					}
				}});
				
				console.log("Excursions",this[$entity].data);		
				break;
		}	
		
		this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;        
		this.filterData($entity);
	}



	initTransports(){
		// console.log('init transports');
		// console.log(this.routes.data);
		this.routes.data.forEach((el,index) =>{
			
			let infoArrival 		=  el.schedule.find(item => item.type == "arrival");
			let infoPickUp 			=  el.schedule.find(item => item.type == "pickup");
			let firstLodgingPickup 	=  el.schedule.filter(el => el.type	  == "pickup")[0].stops[0];

			this.pageInfo.busTour.push({
				id            : index+1,
				icon          : el.type == "bus" ? this.pageInfo.info.pathBus : this.pageInfo.info.pathShuttle,
				points        : {lat:el.points[0].lat, lng:el.points[0].lng},
				route         : el,
				pointsArrival : [],
				pointsPickup  :  el.points.slice(el.points.findIndex(item => (item.lat == firstLodgingPickup.coords.lat) && (item.lng == firstLodgingPickup.coords.lng))),
				pointsVisited : [],
				pointsToVisit : el.points,
				resummeArrival 	: [
					{type:"adults"    ,qty:infoArrival.adults                                            , icon : "topbar-icon fa fa-fw fa-user"     },
					{type:"children"  ,qty:infoArrival.children                                          , icon : "topbar-icon fa fa-fw fa-child"    },
					{type:"hotel"     ,qty:infoArrival.stops    .filter(el => el.type =="hotel").length  , icon : "topbar-icon fa fa-fw fa-h-square" },
					{type:"apartment" ,qty:infoArrival.stops    .filter(el => el.type=="apartment").length, icon : "topbar-icon fa fa-fw fa-home"     }
				],
				resummePickUp 		: [
					{type:"adults"    ,qty:infoPickUp.adults                                            ,icon : "topbar-icon fa fa-fw fa-user" },
					{type:"children"  ,qty:infoPickUp.children                                          ,icon : "topbar-icon fa fa-fw fa-child" },
					{type:"hotel"     ,qty:infoPickUp.stops    .filter(el => el.type =="hotel").length  ,icon : "topbar-icon fa fa-fw fa-h-square" },
					{type:"apartment" ,qty:infoPickUp.stops    .filter(el => el.type=="apartment").length,icon : "topbar-icon fa fa-fw fa-home" }
				],
				infoArrival 	: infoArrival,
				infoPickUp		: infoPickUp,
				paxPickUp		: infoArrival.pax,
				lodgingsArrival : el.schedule.filter(el => el.type =="arrival").map(parades => parades.stops.map(lodging => {return {...lodging,icon:this.getImage(lodging.type)}}))[0],
				lodgingsPickup 	: el.schedule.filter(el => el.type =="pickup").map(parades =>  parades.stops.map(lodging => {return {...lodging,icon:this.getImage(lodging.type)}}))[0]
			})
		});
		//Save the full info on busTour and the info to filter will be saved on displayTransfers
		this.pageInfo.displayTransfers = this.pageInfo.busTour;
		this.assignTrajectsPannelInfo();
		console.log('BUSES')
		console.log(this.pageInfo.displayTransfers);
	}
	
	/**
	 * Mark on the panel the trajects. The first element of 'busPannelInfo' will contain the arrivals, and the second, the pickups
	 */
	assignTrajectsPannelInfo(){
		this.pageInfo.displayTransfers.forEach(bus => {
			this.pageInfo.busPannelInfo[0].items.push({...bus.infoArrival,	id	:	bus.id, status	: 'on track', visible : true});
			this.pageInfo.busPannelInfo[1].items.push({...bus.infoPickUp,	id	:	bus.id, status 	: 'on track', visible : true});
		});
	}

	/******* START FILTERS FROM ENTITIES *******/

	/**
	 * 1. Filter By Zones Selecteds: It will display all the elements which contains some property / activity on the zone selected
	 */
	filterDataByZone()
	{
		let zones = (this.pageInfo.itemsToDisplay.find(el => el.entity == 'zones') || {}).selected;
		this.filterTransfersByZone(zones);
		this.filterToursByZone(zones);
	}
	
	//DISPLAY TRANSFERS BY ZONE SELECTED
	/**
	 * Will hide the transfers where are not on the selected zones
	 * @param zones The currently zones selected
	 */
	filterTransfersByZone(zones)
	{
		this.pageInfo.displayTransfers 	= this.pageInfo.busTour;

		if(zones.length == 0){
			this.pageInfo.displayTransfers.map(el => el.display = true);	
		}else{
			this.pageInfo.displayTransfers.filter(transfer => this.belongsZone(zones,transfer)).map(el => el.display = true);
		}
		;
		this.updateTrajectsPannelInfoByZones(zones);
	}

	/**
	 * 
	 * @param zones The currently zones selected
	 * @param transfer All the transfers
	 */
	belongsZone(zones, transfer)
	{
		let arrival = zones.findIndex(zone => zone.toLowerCase() == (transfer.infoArrival.to).toLowerCase());
		let pickup  = zones.findIndex(zone => zone.toLowerCase() == (transfer.infoPickUp.to).toLowerCase());
		return (arrival >-1 || pickup >-1)
	}

	/**
	 * Will display the transports which have some activity on the selecteds zones
	 * @param zones The zones currently selecteds
	 */
	updateTrajectsPannelInfoByZones(zones)
	{
		if(zones.length == 0){
			this.pageInfo.busPannelInfo.forEach(bus => {
				bus.items.map(item => item.visible = true);
			})
		}else{
			this.pageInfo.busPannelInfo.forEach(bus => {
				bus.items.forEach(item => item.visible = (zones.findIndex(zone => zone.toLowerCase() == item.to.toLowerCase()) > -1));
			});
		}
		// this.pageInfo.displayTransfers.filter(transfer => transfer.items.)
	}

	//DISPLAY TOURS BY ZONE SELECTED 
	/**
	 * @param zones The zones currently selecteds 
	 */
	filterToursByZone(zones)
	{
		if(this.excursions['data']){
			if(zones.length == 0){
				this.excursions.data.map(el => el.display = true)
			}else{
				this.excursions.data.forEach(route => {
					if(zones.findIndex(zone => route.start == zone) > -1){
						route.display = true;
					}else{
						route.display = false;
					}
				})
			}
		}
		
	}
	/********* END FILTERS FROM ENTITIES ***********/

	getSubEntities($event)
	{
		this.pageInfo.subEntitySelected 	= null;
		this.pageInfo.entitySelected 		=	$event["value"] ? $event["value"] : $event; 
		// this.pageInfo.subEntitiesToDisplay 	= 	(this.pageInfo.itemsToDisplay
		// 										.find(el => el.entity == this.pageInfo.entitySelected) || {})
		// 										.selected.map(el =>{return {name : el}});
	}

	displayInfoToPannel($event){
		this.pageInfo.subEntitySelected = $event.value.name;
	}
	selectMainEntity($event){
		console.log('EVENT IS', $event);
		let valueSelected	= $event.find(el => el.selected);
		if(valueSelected){
			let findEntityOnFilter = this.pageInfo.filters.find(el => el.entity == valueSelected.value) || {};
			if(findEntityOnFilter){
				this.pageInfo.subEntitiesToDisplay = findEntityOnFilter.selected.map(el => {
					return {
						value : el, 
						selected: el === (this.pageInfo.currentSubEntitiesSelected.find(el => el.entity == valueSelected.value) || {}).value}});
			}
			this.pageInfo.subEntitySelected		= (this.pageInfo.subEntitiesToDisplay.find(el => el.selected) || {}).value || null;
		}else{
			this.pageInfo.subEntitiesToDisplay 	= [];
			this.pageInfo.subEntitySelected 	= null;
		}
	}

	selectSubMainEntity($event){
		console.log('EVENT IS', $event);
		let findActiveEntity = this.pageInfo.entitiesToDisplay.find(el => el.selected);
		if(findActiveEntity){
			let valueSelected = $event.find(el => el.selected);

			if(valueSelected){
				//DO ACTION TO DISPLAY PANEL
				this.pageInfo.currentSubEntitiesSelected.find(el=> el.entity == findActiveEntity.value).value = valueSelected.value;
				this.pageInfo.subEntitySelected = valueSelected.value;
			}else{
				(this.pageInfo.currentSubEntitiesSelected.find(el => el.entity == findActiveEntity.value) || {}).value = null;
				this.pageInfo.subEntitySelected = null;
			}
		}
		
		
	}
	checkFilter(filter, $event?, initalSetup?){

		console.log('%c Current Sub Entities', 'color:orange;border:2px solid red;', this.pageInfo.currentSubEntitiesSelected)
		//search the entity on the main filters and set to null the subEntity to display
		let findEntityOnFilter = this.pageInfo.filters.find(el => el.entity == filter.entity) || {};
		let elementSelected	   = $event['option'] ? $event.option.value : $event;
		// this.pageInfo.subEntitySelected = null;

		//due the logic of the p-selectButton Component from PrimeNG, the selected values have to be assigned previously manually
		if(!initalSetup){
			if(findEntityOnFilter.selected.indexOf(elementSelected)>-1){
				findEntityOnFilter.selected.splice(findEntityOnFilter.selected.indexOf(elementSelected),1);
			}else{
				findEntityOnFilter.selected.push(elementSelected);
			}
		}
		
		//if there are some main entity selected will proceed to display the subEntities and set as active the selected one
		if(findEntityOnFilter.selected.length>0){

			let findedEntity = this.pageInfo.entitiesToDisplay.findIndex(el => el.value == filter.entity)
			if(findedEntity>-1){
				this.pageInfo.entitiesToDisplay.map(el => el.selected = false);
				this.pageInfo.entitiesToDisplay[findedEntity].selected = true;

				let currentSubEntitySelected = this.pageInfo.currentSubEntitiesSelected.find(el => el.entity == this.pageInfo.entitiesToDisplay[findedEntity].value)
				if(currentSubEntitySelected){
					let findedSubEntity = findEntityOnFilter.selected.indexOf(elementSelected); 
					currentSubEntitySelected.value =  findedSubEntity > -1 ? findEntityOnFilter.selected[findedSubEntity] : currentSubEntitySelected.value;
					this.pageInfo.subEntitiesToDisplay 	= findEntityOnFilter.selected.map(item => { return {value : item, selected:  item === currentSubEntitySelected.value}}); 
					// this.pageInfo.subEntitySelected 	= elementSelected == this.pageInfo.subEntitySelected ? null : elementSelected;
					this.pageInfo.subEntitySelected 	= (this.pageInfo.subEntitiesToDisplay.find(el => el.selected === true) || {}).value || null;

				}else{
					console.log('ERROR, CURRENT SUB ENTITY NOT FOUND');
				}
				
				
			}else{
				this.pageInfo.entitiesToDisplay.map(el => el.selected = false);
				this.pageInfo.entitiesToDisplay.push({value : filter.entity, selected:true});
				this.pageInfo.currentSubEntitiesSelected.push({entity : filter.entity, value : elementSelected });
				this.pageInfo.subEntitiesToDisplay 	= findEntityOnFilter.selected.map(item => { return {value : item, selected: elementSelected == item }});
				this.pageInfo.subEntitySelected 	= elementSelected == this.pageInfo.subEntitySelected ? null : elementSelected;
			}
		}else{
			let findedEntity = this.pageInfo.entitiesToDisplay.findIndex(el => el.value == filter.entity)
			if(findedEntity > -1){
				this.pageInfo.entitiesToDisplay.splice(findedEntity,1);
				this.pageInfo.entitiesToDisplay.map(el => el.selected = false);
				this.pageInfo.subEntitiesToDisplay = [];
				this.pageInfo.currentSubEntitiesSelected.splice(this.pageInfo.currentSubEntitiesSelected.findIndex(el => el.entity == filter.entity),1);
				this.pageInfo.subEntitySelected = null;
			}else{
				console.log('ERROR. NO ENTITY FINDED')
			}
		}
		console.log('%c Current Sub Entities', 'color:orange; border:2px solid red;', this.pageInfo.currentSubEntitiesSelected)
		
		switch(filter.type){
			case "multiple":
				this.checkMultipleFilter(filter);
				break;
			default:
				this.checkSimpleFilter(filter);
				break;
		}
	}

	
    checkMultipleFilter(filter){
		console.log(filter);
		console.log(this[filter.entity])
		if(this[filter.entity] === undefined){
			this[filter.entity] = {
				entity  : filter.entity,
				name 	: filter.name
 			}
		}
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}
		
		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= {
															field	: filter.field,
															options	: filter.items.map(item=>item.value),
															selected: filter.selected
														};
		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		console.log('FILTER',filter);
		console.log(this[filter.entity])
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		
			// undefined===filter.status
		){
			console.log("OOPS. Invalid filter",filter);
			return false;
		}
		this[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	filterData($entity){
		let data 	= this[$entity].data			|| [];
		let filters = this[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		console.log(Object.keys(filters))
		if ( (Object.keys(filters)||[]).length>0 ) {
			Object.keys(filters).forEach(item=>{
				//alert("FILTER["+$entity+"]="+item);
				console.log(filters, item);
				console.log(filters[item]);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;
				
				switch(item){
					case "status":
						data 	= data.filter(item=>!inverted.some(value=>value==item[field]));
						break;
					case "zones":
						console.log('zones selected',this.pageInfo.entitiesToDisplay);
						// this.pageInfo.entitiesToDisplay.selected = 'Zones';
						this.displayInfo(item,selected);
						break;
					case "activities":
						 this.displayInfo(item,selected);	
					break;

					case "error":
						data 	= data.filter(item=>!inverted.some(value=>value==item[field]));
						break;
				}
			});
		}		
	}

	/**
	 * 
	 * @param $event The form message to send to the driver
	 */
	createMessageDriver($event){
		console.log('Event driver', $event);
		this.pageInfo.messagesToDrivers.push($event);
		this.commons.generateToast('Message sent!','Message sent to driver'+$event.recipient,'success',5);
	}

	displayMessages(){
		this.pageInfo.listMessages = !this.pageInfo.listMessages;
		console.log(this.pageInfo.messagesToDrivers);
	}

	hideMessages($event){
		this.pageInfo.listMessages = $event;
	}

	isZoneMainEntity(){
		if(this.pageInfo.entitiesToDisplay.length == 0) return false;
		let findedEntity = this.pageInfo.entitiesToDisplay.find(el => el.value == 'zones');
		if(findedEntity){
			if(findedEntity.selected){
				return this.pageInfo.subEntitiesToDisplay.filter(el => el.selected).length >0
			}else{
				return false
			}
		}else{
			return false;
		}
	}

	//NEED TO PASS TO COMMONS
	getImage(type,active?){	
		switch(type){
			case 'hotel':
				return active ? this.pageInfo.info.pathHotelCurrent : this.pageInfo.info.pathHotel;
			case 'apartment':
				return active ? this.pageInfo.info.pathApartmentCurrent: this.pageInfo.info.pathApartment;
		}
	}
	
	/**
	 * Display or hide the infoWindow of the marker transport from the component transfer panel
	 * @param $event Id of transport
	 */
	toggleTransport($event){
		let busFinded = this.pageInfo.displayTransfers.find(el => el.id == $event.id);
		if(busFinded){
			busFinded.isOpen = busFinded['isOpen'] ? !busFinded['isOpen'] : true;
		}
	}

	updateStatus(id, status){
		this.pageInfo.busPannelInfo.forEach(element =>{
			element.items.find(item => item.id == id).status = status;
		});
	}

	getIconCategoryLodging(category){return this.pageInfo.info.hotels["marker"+category];}
	getHourFromSlider()	{return this.pageInfo.valHour ? this.pageInfo.valHour <= 9 || this.pageInfo.valHour >= 24 ? '0'+this.pageInfo.valHour % 24 + ':00' : this.pageInfo.valHour % 24 + ':00' : '-';	}
	mountPolygons(items){
		return items.map((el,index) =>{return {...el, areas : el.areas.map(area => {return {...area, color : this.mapService.decideColorPolygon(index)}})}});
	}
	generatePolygon(areas){
		let polygonCreated =  [].concat.apply([],areas.map(el => el.coords));
		polygonCreated.push(polygonCreated[0]);
		console.log('areas és',polygonCreated)
		return polygonCreated			
	}
	clickPoly(polygon,$event)
	{
		var myLatLng 		= $event.latLng;
		var lat 			= myLatLng.lat();
		var lng 			= myLatLng.lng();

		polygon.markerInfo 	= null;
		polygon.markerInfo 	= {
			coords 	: { lat : lat, lng : lng	},
			icon	: this.pageInfo.info.pathInfoMarker
		};
	}
	//Update the elements to display given an entity and item
	displayInfo(entity,items)
	{
		// let findIndex = this.pageInfo.itemsToDisplay.findIndex(el => el.entity == entity);
		// if(findIndex > -1){
		// 	this.pageInfo.itemsToDisplay[findIndex].selecteds = items;
		// }else{
		// 	this.pageInfo.itemsToDisplay.push({
		// 		entity 		: entity, 
		// 		selected 	: items
		// 	})
		// }
		console.log('items to display:',this.pageInfo.itemsToDisplay);
		this.displayElements(false);
		this.filterDataByZone();
		// this.assignEntitiesToDisplay();
		//this.getSubEntities(entity);
		console.log(this.pageInfo.itemsToDisplay)
	}

	displayElements(display){
		this.pageInfo.displayTransfers.map(el => el.display = display);
	}

	// FULLSCREEN SUPPORT
	// Migrate to service

	toggleFullScreen()
	{
		this.pageInfo.fullScreen = this.pageInfo.fullScreen?false:true;
	
		if(this.pageInfo.fullScreen){
			this.pageInfo.cls.map 		= "map-fullscreen";
			this.pageInfo.cls.toolbar	= "toolbar-fullscreen";
			this.openFullscreen();
		} else {
			this.pageInfo.cls.map 		= "";
			this.pageInfo.cls.toolbar	= "";
			this.closeFullscreen();
		}
	
		console.log("Classes",this.pageInfo.fullScreen,this.pageInfo.cls);
	}
	
	openFullscreen() {
		if 		( this.pageInfo.elem.requestFullscreen		) { this.pageInfo.elem.requestFullscreen();											} 
		else if ( this.pageInfo.elem.mozRequestFullScreen	) {	this.pageInfo.elem.mozRequestFullScreen();		/* Firefox */					} 
		else if ( this.pageInfo.elem.webkitRequestFullscreen) {	this.pageInfo.elem.webkitRequestFullscreen();	/* Chrome, Safari and Opera */  }
		else if ( this.pageInfo.elem.msRequestFullscreen	) {	this.pageInfo.elem.msRequestFullscreen(); 		/* IE/Edge */ 					}
	}
	
	closeFullscreen() {
		if 		( this.document.exitFullscreen		) {	this.document.exitFullscreen();											}
		else if ( this.document.mozCancelFullScreen	) {	this.document.mozCancelFullScreen();	/* Firefox */ 					}
		else if ( this.document.webkitExitFullscreen) {	this.document.webkitExitFullscreen();	/* Chrome, Safari and Opera */ 	} 
		else if ( this.document.msExitFullscreen	) {	this.document.msExitFullscreen();		/* IE/Edge */					}
	}

	/** METHODS TO REMOVE
	 
	activateMarkerTransport(type,item){

		item.isOpen 			= item['isOpen'] 	? !item['isOpen'] 	: true;
		let otherTrajectType 	= type == 'arrival' ? 'pickup' 			: 'arrival';
		let findedOtherWay 		= this.pageInfo.busPannelInfo.find(el => el.type == otherTrajectType ).items.find(transport => transport.id == item.id); 
		
		if(findedOtherWay){
			findedOtherWay.isOpen = item.isOpen;
		}

		let busFinded = this.pageInfo.displayTransfers.find(el => el.id == item.id);
		if(busFinded){
			busFinded.isOpen = busFinded['isOpen'] ? !busFinded['isOpen'] : true;
		}
	}

		checkField( entity, item ){
		if(undefined!==this[entity].checks[item.name]){
			this[entity].checks[item.name].panel = true;
		}
	}
	*/
	changeRoute(event){
		console.log('CHANGE ROUTE');
		console.log(event);
	}
	onResponse(event){
		console.log('Transport')
		console.log(event);
	}

	infoRoute($event){
		this.pageInfo.currentDriverSelected = $event.display ? $event : null;
		console.log('INFO MARKER CHANGE');
		console.log($event);
	}
}
