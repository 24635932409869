import { FirebaseService 						} from '.././../../service/database/firebase.service';
import { CommonsService 						} from '.././../../service/commons.service';
import { StorageService                         } from '.././../../service/storageservice';
import { Component, OnInit, ViewEncapsulation   } from '@angular/core';
import { filters 								} from '.././data/filters';
import { EntityService                          } from '../../../service/entity.service';
import { MapService                             } from '../../../service/mapservice';
import { ToastrService                          } from 'ngx-toastr';
import { ConfirmationService                    } from 'primeng/api';


@Component({
    styleUrls		: ['./pickupszones.component.scss'],
	templateUrl		: './pickupszones.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class PickupsZonesComponent implements OnInit{
    pageInfo : any   = {
        cls			: {
            map			: '',
            toolbar		: ''
        },
        zones           : [],
        currentZones    : [],
        legendZones     : [],
        createZone      : false,
        nameZone        : null,
	};
	userInfo	:	any	=	{}
    areasDestination    :   any     = {};
    zoom                :   number  =	10;
    lat                 :   number  = 39.638464;
    lng                 :   number  = 3.003831 ;
    
    constructor
    (
        private entityService	    : EntityService,
        private mapService 		    : MapService,
        private storageService      : StorageService,
        private commons             : CommonsService,
		private confirmationService : ConfirmationService,
		private firebaseCtrl		: FirebaseService
    ){}
    
    async ngOnInit(){
		this.pageInfo.filters = filters;
		this.userInfo	=	( this.storageService.getItem('dmcSuite') || {} ).userInfo || {}; 
        console.log('MY USER INFO', this.userInfo);
        await this.loadEntities();
        let testPickUp    =   await this.firebaseCtrl.getPickupsZones('1','1');
        console.log('PICKUP ZONES', testPickUp);
        this.initMap();        
    }
    
    initMap(){
		this.storageService.getItem('user');
		this.firebaseCtrl.subscribeMainInfoDestinationByDmc(this.userInfo.idDmc,this.userInfo.currentDestination.id).valueChanges().subscribe(async (data : any) =>{
			console.log('DATA PICKUS', data);
			this.pageInfo.zones = data.pickupZones || [];
			this.pageInfo.filters.find(el => el.entity == 'zones').items = [];
			this.pageInfo.zones = await Promise.all(data.pickupZones.map(async zone => {
				return {
					id		:	zone.id,
					name	:	zone.name,
					areas	:	await this.firebaseCtrl.getInfoAreas(zone.areas)
				}
			}));
			this.pageInfo.filters.find(el => el.entity == 'zones').items = this.pageInfo.zones.map(zone => {return { label : zone.name, value: zone.name, id: zone.id}}) 
			this.paintCreatedsAreas();
		})
        // this.pageInfo.filters.find(el => el.entity == 'zones').items    = this.storageService.getItem('filtersZones') || [];
       
        console.log(this.pageInfo.zones);
    }
    async loadEntities() {
		await this.load('areasDestination');  
    }
    
    async load($entity){
        switch ($entity)
        {
			case 'areasDestination':
			const areas = await this.firebaseCtrl.getAreasFromDestination('1');
			this[$entity].data = [];
			areas.forEach(data =>{
				if(!data.exists) return;
				this[$entity].data.push({ref: data.ref,...data.data()});
			});
			this[$entity].data.map(el => el.color = '#6495ED' );
			console.log('AREAS DESTINATION',this[$entity].data);
        }
    }

 


    modeCreateZone(){
        if(this.pageInfo.filters.some(el => el.selected.length >0)){
            this.restartOptions()
        }else{
            this.pageInfo.createZone    = this.pageInfo.createZone  ? !this.pageInfo.createZone     : true;
            this.pageInfo.editZone      = this.pageInfo.editZone    ? !this.pageInfo.editZone       : false;
        }
        this.restartItems();
        this.paintCreatedsAreas();    
        this.pageInfo.filters.map(el => el.selected = []);

        console.log(this.pageInfo.createZone, this.pageInfo.editZone);
        console.log('Zones Area Mallorca',this.areasDestination.data);
    }
    paintCreatedsAreas(currentZone?){
        this.pageInfo.legendZones = [];
        this.pageInfo.filters.forEach(zone =>{
            zone.items.forEach((area,index) =>{
                let findedArea = this.pageInfo.zones.find(el => el.name == area.value);
                if(findedArea){

                    let colorArea = (currentZone || {}).id == findedArea.id ? 'red' : this.mapService.getColor(index);
                    this.pageInfo.legendZones.push({name : findedArea.name, color : colorArea});
                    
                    findedArea.areas.forEach(el =>
                        {
                            this.areasDestination.data.filter(subArea => subArea.name == el.name)
                                                    .map(areaFiltered => areaFiltered.color = colorArea);
                        })
                    }
                })
            })
    }

    modeEditZone(){
        this.pageInfo.createZone    = false;
        this.pageInfo.editZone      = true;
    }

    async fillRestZones(){
        let areasAssigneds      = [].concat.apply([],this.pageInfo.zones.map(el => el.areas))
        let areasUnassigneds    = this.areasDestination.data.filter(el => !areasAssigneds.find(area => el.name == area.name));
        let refAreas            = areasUnassigneds.map(el => el.ref);
        let zoneAlreadyExists   = this.pageInfo.zones.findIndex(zone => zone.id == this.pageInfo.currentZoneId);
        if(refAreas.length == 0){
            return this.commons.generateToast('Option not possible', 'There are no more empty areas', 'info');
        }

        if(zoneAlreadyExists > -1){
            const concatedAreas = this.pageInfo.zones[zoneAlreadyExists].areas.concat(refAreas);
            this.pageInfo.zones[zoneAlreadyExists].areas = concatedAreas;
            
        }else{
            let newZone = {
                areas   : refAreas,
                name    : this.pageInfo.nameZone,
                id      : Math.random().toString(36).substring(7)
            }
            this.pageInfo.zones.push(newZone);
        }

        await this.firebaseCtrl.updatePickupsZonesDestination(this.userInfo.idDmc,this.userInfo.currentDestination.id,this.pageInfo.zones);
       
        this.commons.generateToast('Success!','Map Completed!','success');
        this.restartItems();
        this.restartOptions();
        this.paintCreatedsAreas();
    }

    //IF edit on zone, must check if is the current zone. If it is, then splice 
    toggleArea(area){
        
        if(!this.pageInfo.createZone && !this.pageInfo.editZone){
            this.commons.generateToast('_ERROR','_CREATE_OR_EDIT_AREAS','info');
        }
        else
        {
            let findedArea      = this.pageInfo.zones.find(zone => zone.areas.find(el => el.name == area.name));
            let areaSelected    = this.pageInfo.filters.find(el => el.entity == 'zones').selected;
            
            if(!findedArea){
                area.color      = area.color == '#6495ED' ? '#FFB6C1' : '#6495ED';
                let findedArea  = this.pageInfo.currentZones.findIndex(el => el.name == area.name);
                if(findedArea > -1){
                    this.pageInfo.currentZones.splice(findedArea,1);
                }else{
                    this.pageInfo.currentZones.push(area);
                }
            }else{
                let colorArea       = findedArea.areas[0].color;
                if(findedArea.name == areaSelected){
                    let zoneExists = findedArea.areas.findIndex(el => el.name == area.name);
                    if(zoneExists > -1){
                        area.color  = '#6495ED'; 
                        findedArea.areas.splice(zoneExists,1);
                    }else{
                        area.color  = colorArea;
                        findedArea.areas.push(area);
                    }
                }else{
                    this.commons.generateToast('Error!','This area belongs to other zone!','error');
                  }
                }
            }
        }
    

    async createZone(){
        try{
            if(false === this.checkStatusName(this.pageInfo.nameZone)){
                this.commons.generateToast('Error!','Check the name. Maybe is empty or already exist!','error');
            }else{
                let refAreas = this.pageInfo.currentZones.map(el => el.ref);
                let newZone = {
                    areas   : refAreas,
                    name    : this.pageInfo.nameZone,
                    id      : this.pageInfo.currentZoneId ? this.pageInfo.currentZoneId : Math.random().toString(36).substring(7) 
                };
        
                let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == newZone.id);
                if(zoneFinded > -1){
                    this.pageInfo.zones[zoneFinded].areas = newZone.areas; 
					this.pageInfo.zones[zoneFinded].name  = newZone.name;
					console.log('FOUND ZONE', this.pageInfo.zones);
                    await this.firebaseCtrl.updatePickupsZonesDestination(this.userInfo.idDmc,this.userInfo.currentDestination.id,this.pageInfo.zones);
                    this.commons.generateToast('Success!','Zone edited!','success');
                }else{
                    this.pageInfo.zones.push(newZone);
                    await this.firebaseCtrl.updatePickupsZonesDestination(this.userInfo.idDmc,this.userInfo.currentDestination.id,this.pageInfo.zones);
                    this.commons.generateToast('Success!','Area created!','success');
                }
                this.restartItems();
				this.restartOptions();
				this.paintCreatedsAreas();

        }

        }catch(e){
            this.commons.generateToast('Error!','An error ocurred. Please, restart the page.','error');
        }
       
            // this.paintCreatedsAreas();
            
        
    }

    async deleteZone(){
        let zoneFinded = this.pageInfo.zones.findIndex(el => el.id == this.pageInfo.currentZoneId);
        if(zoneFinded > -1){
			this.pageInfo.zones.splice(zoneFinded,1);
			await this.firebaseCtrl.updatePickupsZonesDestination(this.userInfo.idDmc,this.userInfo.currentDestination.id,this.pageInfo.zones);
            this.commons.generateToast('Success!','Area removed!','success');
            this.restartItems();
            this.restartOptions();
            this.paintCreatedsAreas();
        }else{
            this.commons.generateToast('Error', 'No zone finded', 'error');
        }
    }

    displayZone(zone){
        this.restartItems();
        let zoneSelected            = this.pageInfo.zones.find(el => el.name == zone.selected);
        this.pageInfo.nameZone      = zoneSelected.name;
        this.pageInfo.editZone      = true;
        this.pageInfo.currentZones  = zoneSelected.areas;
        this.pageInfo.currentZoneId = zoneSelected.id;
      
        this.paintCreatedsAreas(zoneSelected);
    }

    
    restartItems(){
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = [];
        this.pageInfo.legendZones   = [];
        this.areasDestination.data.map(el => el.color = "#6495ED");

    }

    restartOptions(){
        this.pageInfo.createZone    = false;
        this.pageInfo.editZone      = false;
        this.pageInfo.filters.map(el => el.selected = []);
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = null;
        this.pageInfo.currentZoneId = null;
    }

    checkStatusName(nameZone){
        if(nameZone === null) return false;
        let areaFinded =  this.pageInfo.zones.find(el => el.name == nameZone);
        if(areaFinded){
            return areaFinded.id == this.pageInfo.currentZoneId;
        }else{
            return true;
        }
    }
    async deleteLocaleZones(){
        this.confirmationService.confirm({
            message: 	await this.commons.getTranslate('_MESSGE_CONFIRM_REMOVE_ZONES'),
            header: 	await this.commons.getTranslate('_REMOVE_ZONES'),
            accept: async () => {
                await this.firebaseCtrl.updatePickupsZonesDestination(this.userInfo.idDmc,this.userInfo.currentDestination.id,[]);
                this.storageService.deleteItem('zones');
                this.storageService.deleteItem('filtersZones');
                this.initMap();
                this.restartOptions();
                this.restartItems();
            }
        });
 
    }
    sortList(list){
        if(!list) return [];
        return list.sort((a,b) =>{ return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1});
    }
}