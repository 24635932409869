export const CS_GC = [
    {
        "Id": 101,
        "Name": "Aeropuerto de Gran Canaria (LPA)",
        "Island": "GC",
        "ZoneId": 101,
        "ZoneName": "Aeropuerto ",
        "Address": "Aeropuerto Sur, 35230, Las Palmas, España",
        "Coordinates": "27.936969976554398,-15.390254699939282"
    },
    {
        "Id": 1186,
        "Name": "Bluebay Beach Club",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Paseo Pablo Picasso, 6, 35107 Bahía Feliz, Las Palmas, Spain",
        "Coordinates": "27.782984,-15.5141466"
    },
    {
        "Id": 1334,
        "Name": "Marinasol Aparthotel",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle los Pinos, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7747189,-15.5326286"
    },
    {
        "Id": 1347,
        "Name": "Monte Feliz",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Paseo Pablo Picasso, S/N, 35100 Bahía Feliz, Las Palmas, Spain",
        "Coordinates": "27.7839096,-15.5151341"
    },
    {
        "Id": 1361,
        "Name": "Orquidea Club Spa",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Urb. Bahía Feliz Gran Canaria, Calle Viejos Tendereteros, s/n, 35100 Tarajalillo, Las Palmas, Spain",
        "Coordinates": "27.7827813,-15.5183821"
    },
    {
        "Id": 1382,
        "Name": "Playa Feliz",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Av. de Wind Surfing, 4, 35100 Bahía Feliz, Las Palmas, Spain",
        "Coordinates": "27.7817194,-15.519325"
    },
    {
        "Id": 1426,
        "Name": "Side Shore Bungalows",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Plaza de los Hibiscus, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7764218,-15.5289036"
    },
    {
        "Id": 1435,
        "Name": "Sun Club Aguila Playa",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle los Pinos, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.775596,-15.530039"
    },
    {
        "Id": 1494,
        "Name": "Altamar Aparthotel Bahia Feliz",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Av. de Wind Surfing, 35107 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7783333,-15.5247222"
    },
    {
        "Id": 1545,
        "Name": "Norcopia",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "35100 las, C. las Margaritas, 31, 35100 San Agustin, Las Palmas, Spain",
        "Coordinates": "27.7687227,-15.5522482"
    },
    {
        "Id": 1554,
        "Name": "Tres Vidas",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Carretera Gral. Del Sur, Km 44 s/n (Tres Vidas, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7835065,-15.5162364"
    },
    {
        "Id": 1557,
        "Name": "Las Pitas Aptos",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Carretera Gral. Del Sur, Km 44 s/n (Las Pitas, 35100 Bahía Feliz, Las Palmas, Spain",
        "Coordinates": "27.7836865,-15.5168395"
    },
    {
        "Id": 1625,
        "Name": "Bamira",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle los Pinos, 11, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7745929,-15.5322565"
    },
    {
        "Id": 1657,
        "Name": "La Perla Bungalow",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle las Azucenas, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7746007,-15.5299547"
    },
    {
        "Id": 1659,
        "Name": "Aguycan Beach",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle las Azucenas, 1, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7751228,-15.5302834"
    },
    {
        "Id": 1669,
        "Name": "Club Montemar Bahia Feliz",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Paseo Pablo Picasso, 11, 35107 Tarajalillo, Las Palmas, Spain",
        "Coordinates": "27.7832028,-15.5158833"
    },
    {
        "Id": 1713,
        "Name": "Solysur",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle los Geranios, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.774248240465564,-15.532870002703273"
    },
    {
        "Id": 2324,
        "Name": "Acapulco Ocean View",
        "Island": "GC",
        "ZoneId": 102,
        "ZoneName": "Bahía Feliz",
        "Address": "Calle los Pinos, 13, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.774066,-15.53215"
    },
    {
        "Id": 1184,
        "Name": "Relaxia Beverly Park",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle Hamburgo, 10, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.764727,-15.5581251"
    },
    {
        "Id": 1191,
        "Name": "Buganvilla",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 17, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7706695,-15.5374157"
    },
    {
        "Id": 1220,
        "Name": "Corinto II",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle Hamburgo, 2, 35100 LAS PALMAS, Las Palmas, Spain",
        "Coordinates": "27.7658911,-15.5542667"
    },
    {
        "Id": 1225,
        "Name": "Bull Costa Canaria",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Retamas, 1, 35100 Playa San Agustín-Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7712742,-15.5416636"
    },
    {
        "Id": 1236,
        "Name": "Dunas Don Gregory",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Dalias, 11, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7678817,-15.5500362"
    },
    {
        "Id": 1251,
        "Name": "Europalace",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle Hamburgo, 12, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639822,-15.5594262"
    },
    {
        "Id": 1257,
        "Name": "New Folias",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Retamas, 17, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7691803,-15.5457754"
    },
    {
        "Id": 1261,
        "Name": "Gloria Palace San Agustin Thalasso & Hotel",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Margaritas, S/N, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.772196,-15.5471437"
    },
    {
        "Id": 1267,
        "Name": "Green Ocean",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Av. de la Unión Europea, 119, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7664832,-15.5549284"
    },
    {
        "Id": 1274,
        "Name": "Corallium Beach",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7717914,-15.5405306"
    },
    {
        "Id": 1280,
        "Name": "Abora Interclub Atlantic",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7709954,-15.5358149"
    },
    {
        "Id": 1341,
        "Name": "Paradisus Gran Canaria",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Retamas, 3, 35100 Playa de San Agustin, Las Palmas, Spain",
        "Coordinates": "27.7704767,-15.5426301"
    },
    {
        "Id": 1379,
        "Name": "Pasion Tropical ",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 6, 35100 San Agustín, Palmas, Spain",
        "Coordinates": "27.7687084,-15.5436777"
    },
    {
        "Id": 1407,
        "Name": "Rocas Rojas",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Pitas, 8, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7723763,-15.5427358"
    },
    {
        "Id": 1413,
        "Name": "San Agustin Beach Club",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle de las Ahulagas, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7674032,-15.5443388"
    },
    {
        "Id": 1437,
        "Name": "Sunsuites Carolina",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Cardones, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7676584,-15.5479177"
    },
    {
        "Id": 1450,
        "Name": "Thalaso Gloria Palace San Agustin",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Margaritas, S/N, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.772196,-15.5471437"
    },
    {
        "Id": 1457,
        "Name": "Veril Playa",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle Hannover, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7651559,-15.5570826"
    },
    {
        "Id": 1483,
        "Name": "Hapimag Aptos",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Carrer Músic Torrandell, 07160, Illes Balears, Spain",
        "Coordinates": "39.5327913,2.4599724"
    },
    {
        "Id": 1486,
        "Name": "Miami Playa Aptos. (S. Agustin)",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Retamas, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7692819,-15.5450568"
    },
    {
        "Id": 1521,
        "Name": "Flamboyan",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Margaritas, 10, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.769414,-15.5502805"
    },
    {
        "Id": 1541,
        "Name": "Miami Beach (S. Agustin)",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7686847,-15.5423804"
    },
    {
        "Id": 1543,
        "Name": "Monte Rojo",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Margaritas, 9, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7708044,-15.5474855"
    },
    {
        "Id": 1558,
        "Name": "Tarahal Aptos.",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 23, 35105 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7689702,-15.5452232"
    },
    {
        "Id": 1574,
        "Name": "Acebuches San Agustin",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 23, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7690048,-15.5448899"
    },
    {
        "Id": 1593,
        "Name": "La Galea",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Margaritas, 33, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.769,-15.5522231"
    },
    {
        "Id": 1594,
        "Name": "La Siesta Aptos.",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7684163,-15.5432765"
    },
    {
        "Id": 1595,
        "Name": "Las Gavias",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Dalias, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7678906,-15.5469522"
    },
    {
        "Id": 1596,
        "Name": "Los Patios",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Geranios, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7734384,-15.5331294"
    },
    {
        "Id": 1599,
        "Name": "Monte del Moro Seaside Suites",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Dragos, 15, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7701356,-15.5352171"
    },
    {
        "Id": 1628,
        "Name": "Las Barcas",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Tabaibas, 11, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7671008,-15.5518292"
    },
    {
        "Id": 1630,
        "Name": "Bellimar Aptos.",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Adelfas, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7691793,-15.5422302"
    },
    {
        "Id": 1633,
        "Name": "El Yate",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Dalias, 4, 35290 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7679183,-15.5460863"
    },
    {
        "Id": 1654,
        "Name": "Nueva Suecia",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 19, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.772328,-15.538865"
    },
    {
        "Id": 1672,
        "Name": "Aquarius Las burras",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle las Tabaibas, 17, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7665273,-15.5526563"
    },
    {
        "Id": 1680,
        "Name": "Los Balandros Apartamentos",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle Hamburgo, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7655808,-15.5554906"
    },
    {
        "Id": 1686,
        "Name": "Olympia Apartamentos Club en S. Agustin",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "35100 las, Calle Hamburgo, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639427,-15.5584411"
    },
    {
        "Id": 1704,
        "Name": "Los Manueles Apartamentos",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle de las Ahulagas, 13, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7683993,-15.5467601"
    },
    {
        "Id": 1726,
        "Name": "Balcon de San Agustin",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 15, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7704551,-15.5366996"
    },
    {
        "Id": 2372,
        "Name": "Hapimag Aptos.",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Jazmines, 23, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.772248,-15.540359"
    },
    {
        "Id": 2441,
        "Name": "Apartamentos Flipper",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "Calle los Dragos, 3, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.770731,-15.5336304"
    },
    {
        "Id": 2451,
        "Name": "Patricia Apartamentos",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Mimosas, 1, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.769253234855437,-15.542652898135096"
    },
    {
        "Id": 2529,
        "Name": "B´SLOW San Agustín",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. de las Ahulagas, 7, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7681192,-15.5456986"
    },
    {
        "Id": 2531,
        "Name": "Mangata Gran Canaria",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. las Mimosas, 1, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7693441,-15.5426059"
    },
    {
        "Id": 2551,
        "Name": "Los Caracoles",
        "Island": "GC",
        "ZoneId": 103,
        "ZoneName": "San Agustín",
        "Address": "C. los Jazmines, 21, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7727059,-15.539329"
    },
    {
        "Id": 1116,
        "Name": "Taboga Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Milán, 19, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7513332,-15.5724468"
    },
    {
        "Id": 1125,
        "Name": "Tabaibas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 9, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.754235,-15.5699876"
    },
    {
        "Id": 1131,
        "Name": "Augustino Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Canadá, 12, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7574361,-15.5825711"
    },
    {
        "Id": 1152,
        "Name": "Adonis Bungalows ",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Inglaterra, 9, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7547567,-15.5743991"
    },
    {
        "Id": 1155,
        "Name": "Aloe Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7514294,-15.5772836"
    },
    {
        "Id": 1160,
        "Name": "Anamar Suites",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Avenida de los Estados Unidos, 38, 35290 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.757267,-15.572125"
    },
    {
        "Id": 1163,
        "Name": "Don Palomon Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Milán, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7526118,-15.5712298"
    },
    {
        "Id": 1164,
        "Name": "Patricio Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Milán, 17, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7516401,-15.5721611"
    },
    {
        "Id": 1173,
        "Name": "Atis Tirma",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirma, 10, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.758861432708844,-15.570192486047745"
    },
    {
        "Id": 1177,
        "Name": "Barbacan Sol Aparthotel",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 27, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7604172,-15.5793155"
    },
    {
        "Id": 1178,
        "Name": "Barcelo Margaritas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 40, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7631713,-15.5803147"
    },
    {
        "Id": 1180,
        "Name": "Begoña Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Finlandia, 14, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.750167087185492,-15.574591478129664"
    },
    {
        "Id": 1181,
        "Name": "Belmonte",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 12, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.755012,-15.5714132"
    },
    {
        "Id": 1187,
        "Name": "Bohemia Suites & Spa",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 28, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7562897,-15.5701995"
    },
    {
        "Id": 1190,
        "Name": "Buenos Aires Aphotel",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 27, 35100 Playa Ingles, Las Palmas, Spain",
        "Coordinates": "27.7592455,-15.5754303"
    },
    {
        "Id": 1193,
        "Name": "Todoque Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 19, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.752411,-15.574496"
    },
    {
        "Id": 1204,
        "Name": "Casas Pepe",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 1, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7537216,-15.5714743"
    },
    {
        "Id": 1215,
        "Name": "Cordial Biarritz Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 18, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7557106,-15.5749145"
    },
    {
        "Id": 1228,
        "Name": "Dolores Apartments",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7541994,-15.5711242"
    },
    {
        "Id": 1230,
        "Name": "Don Gregorio",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 34, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7567867,-15.5716143"
    },
    {
        "Id": 1231,
        "Name": "Dona Rosa",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 23, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7510419,-15.5736884"
    },
    {
        "Id": 1240,
        "Name": "Dunasol",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Lisboa, 1, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7534041,-15.5734656"
    },
    {
        "Id": 1241,
        "Name": "El Palmital",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 36, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.74998882113536,-15.573688745498657"
    },
    {
        "Id": 1246,
        "Name": "El Palmar",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 26, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7559201,-15.5698325"
    },
    {
        "Id": 1250,
        "Name": "Europa",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 21, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7579422,-15.5731079"
    },
    {
        "Id": 1255,
        "Name": "Fayna Aptos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 32, 35100 Gran Canarias, Las Palmas, Spain",
        "Coordinates": "27.7566829,-15.5710779"
    },
    {
        "Id": 1276,
        "Name": "Abora Catarina",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 1, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7490845,-15.5770359"
    },
    {
        "Id": 1284,
        "Name": "Cordial Judoca Beach",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 20, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7526092,-15.5717339"
    },
    {
        "Id": 1298,
        "Name": "EÓ Las Gacelas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 18, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7595833,-15.5747835"
    },
    {
        "Id": 1310,
        "Name": "Los Aguacates",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Finlandia, 1, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7479538,-15.5763023"
    },
    {
        "Id": 1311,
        "Name": "Los Arcos Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 60, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7574938,-15.5805714"
    },
    {
        "Id": 1320,
        "Name": "Los Porches Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Inglaterra, 18, 35110 Maspalomas, PALMAS, LAS, Spain",
        "Coordinates": "27.754042,-15.576849"
    },
    {
        "Id": 1321,
        "Name": "Los Tilos ",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de España, 11, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7582798,-15.5745707"
    },
    {
        "Id": 1322,
        "Name": "Sunny Village",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 29, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7572634,-15.5745307"
    },
    {
        "Id": 1327,
        "Name": "Maba Playa",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 12, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7550745,-15.5722539"
    },
    {
        "Id": 1329,
        "Name": "Marbella Golf",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 46, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639762,-15.5854878"
    },
    {
        "Id": 1332,
        "Name": "Marina Gold - Gold by Marina",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 15, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7567752,-15.5723132"
    },
    {
        "Id": 1343,
        "Name": "Melusina ",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Bremen, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7526003,-15.5725349"
    },
    {
        "Id": 1353,
        "Name": "Nara",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Bremen, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7525214,-15.573"
    },
    {
        "Id": 1354,
        "Name": "Nayra Adult Only",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Irlanda, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757206,-15.577196"
    },
    {
        "Id": 1355,
        "Name": "Mur Neptuno",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Alférez Provisional, 29, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7593688,-15.5763421"
    },
    {
        "Id": 1368,
        "Name": "Parque Cristina Aptos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 13, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7537911,-15.5740889"
    },
    {
        "Id": 1369,
        "Name": "HD Parque Cristobal",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Holanda, s/n, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7613889,-15.5816667"
    },
    {
        "Id": 1375,
        "Name": "Parque Romantico Bung",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Dinamarca, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7496222,-15.5755765"
    },
    {
        "Id": 1376,
        "Name": "Parquesol",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 43, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.75750851297815,-15.577889084815979"
    },
    {
        "Id": 1380,
        "Name": "Gay Bunsgalows Paso Chico",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 39, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757351,-15.5769"
    },
    {
        "Id": 1383,
        "Name": "Playamar Playa del Ingles",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7549222,-15.5703782"
    },
    {
        "Id": 1386,
        "Name": "Principado",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 14, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7555382,-15.572856"
    },
    {
        "Id": 1392,
        "Name": "Rey Carlos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 14, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7516667,-15.5766667"
    },
    {
        "Id": 1395,
        "Name": "Hotel Servatur Don Miguel",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 30, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7603389,-15.5786652"
    },
    {
        "Id": 1399,
        "Name": "Riu Palace Maspalomas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.74717305831165,-15.576637036023294"
    },
    {
        "Id": 1401,
        "Name": "Riu Palmeras",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7560388,-15.5714132"
    },
    {
        "Id": 1409,
        "Name": "Roque Nublo",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 28, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.759495163010644,-15.578825175762177"
    },
    {
        "Id": 1411,
        "Name": "Sahara Beach Club",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 53, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7474596,-15.5752721"
    },
    {
        "Id": 1414,
        "Name": "Santa Barbara",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Barcelona, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.75631,-15.5805266"
    },
    {
        "Id": 1420,
        "Name": "Servatur Barbados Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 17, 19, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7563659,-15.5788903"
    },
    {
        "Id": 1428,
        "Name": "Sol Barbacan",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 27, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7604172,-15.5793155"
    },
    {
        "Id": 1434,
        "Name": "Sun Club Playa del Ingles",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Francia, 13, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7508172,-15.5782438"
    },
    {
        "Id": 1436,
        "Name": "Sunprime Atlantic View Suites & Spa",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 48, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7623737,-15.5836736"
    },
    {
        "Id": 1442,
        "Name": "Tamaran",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 16, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.753069,-15.5772531"
    },
    {
        "Id": 1451,
        "Name": "Tinache",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 22, 35100, 35100 Playa del Ingles, Las Palmas, Spain",
        "Coordinates": "27.7553371,-15.577848"
    },
    {
        "Id": 1467,
        "Name": "Bull Vital Suites",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 80, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.754910161130898,-15.580757680262819"
    },
    {
        "Id": 1468,
        "Name": "HOTEL RITUAL MASPALOMAS",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Alférez Provisional, 33, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.759584,-15.5780994"
    },
    {
        "Id": 1469,
        "Name": "Bull Vital Suites",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 80, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.75492703243911,-15.580766838738818"
    },
    {
        "Id": 1479,
        "Name": "Apto. Datasol",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7555293,-15.569694"
    },
    {
        "Id": 1481,
        "Name": "Apto. Guatiza",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 11, 35007 Maspalomas, Las Palmas",
        "Coordinates": "27.752782656164193,-15.572092831134796"
    },
    {
        "Id": 1484,
        "Name": "Lenamar Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 18, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7540989,-15.5776958"
    },
    {
        "Id": 1488,
        "Name": "Paraiso Maspalomas Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 44, 46, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639668,-15.5838891"
    },
    {
        "Id": 1490,
        "Name": "Teneguia Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 13, 35100 San Fernando, Las Palmas, Spain",
        "Coordinates": "27.755,-15.5786111"
    },
    {
        "Id": 1491,
        "Name": "Tenesor Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 9, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7537785,-15.5781106"
    },
    {
        "Id": 1501,
        "Name": "Basement Studios",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 37, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7574119,-15.5767479"
    },
    {
        "Id": 1512,
        "Name": "Club Excelsior II",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7495799,-15.5764581"
    },
    {
        "Id": 1514,
        "Name": "Club Primavera",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 45, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7476474,-15.5746491"
    },
    {
        "Id": 1528,
        "Name": "Holycan",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 354, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7597189,-15.5826125"
    },
    {
        "Id": 1532,
        "Name": "Iguazu",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Apartamentos Iguazu, Av. de Tirajana, 24, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7565314,-15.5784634"
    },
    {
        "Id": 1538,
        "Name": "Los Valles I",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Irlanda, 4, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.756706,-15.5774317"
    },
    {
        "Id": 1564,
        "Name": "Dragon Club II",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de España, 22, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7565069,-15.575574"
    },
    {
        "Id": 1566,
        "Name": "Las Afortunadas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Bonn, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7544095,-15.5714128"
    },
    {
        "Id": 1567,
        "Name": "La Residence Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Inglaterra, 16, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.754821612012595,-15.574032068252563"
    },
    {
        "Id": 1581,
        "Name": "Dunatlantico",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, nº6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7488663,-15.5764663"
    },
    {
        "Id": 1604,
        "Name": "Tajaraste",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Finlandia, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7487653,-15.574944"
    },
    {
        "Id": 1609,
        "Name": "Casas de Bettina",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 30, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7506559,-15.5738651"
    },
    {
        "Id": 1610,
        "Name": "Parque Rey Carlos Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 83, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7505834,-15.5793192"
    },
    {
        "Id": 1618,
        "Name": "Apto. Los Volcanes",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7529809,-15.5778257"
    },
    {
        "Id": 1622,
        "Name": "Las Dalias Aptos. - Alhambra Boutique",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.75209935200576,-15.572540293260788"
    },
    {
        "Id": 1631,
        "Name": "Sahara Mar Aptos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 53, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7474596,-15.5752721"
    },
    {
        "Id": 1641,
        "Name": "Tunte Mar",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de España, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7612388,-15.5740037"
    },
    {
        "Id": 1642,
        "Name": "Don Pedro Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7557258,-15.5703483"
    },
    {
        "Id": 1643,
        "Name": "Paraguay Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 14, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.751926,-15.5728702"
    },
    {
        "Id": 1645,
        "Name": "Tanife Apartamentos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7594768,-15.579358"
    },
    {
        "Id": 1650,
        "Name": "Remy Bungalow",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 43, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7481829,-15.5744158"
    },
    {
        "Id": 1651,
        "Name": "Club Canario",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 5, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.75231267023965,-15.573074519634247"
    },
    {
        "Id": 1662,
        "Name": "Andalucia Park",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7543169,-15.5803701"
    },
    {
        "Id": 1663,
        "Name": "El Paseo Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7550121,-15.5694851"
    },
    {
        "Id": 1675,
        "Name": "Villa Gran Canaria",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Swing Los Lagos Nr 9, Urbanización Salobre Golf, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.777308,-15.641807"
    },
    {
        "Id": 1678,
        "Name": "Laila Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 25, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.75,-15.5733333"
    },
    {
        "Id": 1679,
        "Name": "Duna Star",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7474101,-15.5774917"
    },
    {
        "Id": 1696,
        "Name": "Taidia Apartamentos",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 9, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.75448,-15.5783421"
    },
    {
        "Id": 1698,
        "Name": "Islas Paraiso Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Dinamarca, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7506217,-15.5754049"
    },
    {
        "Id": 1703,
        "Name": "Escape Club Gran Canaria",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Inglaterra, 16, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7547634,-15.5740398"
    },
    {
        "Id": 1706,
        "Name": "Riu Palmitos Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7560388,-15.5714132"
    },
    {
        "Id": 1711,
        "Name": "Aqua Beach Bungalows",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 43, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7575079,-15.577887"
    },
    {
        "Id": 1720,
        "Name": "Atlantic Sun Beach",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 13, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.753124,-15.572027"
    },
    {
        "Id": 1755,
        "Name": "Masparadise Apartment",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 64, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757167,-15.582044"
    },
    {
        "Id": 2297,
        "Name": "Cisne",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 20",
        "Coordinates": "27.754838227328342,-15.570019483566284"
    },
    {
        "Id": 2298,
        "Name": "Aptos. Datosol",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 2, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7554843,-15.56973"
    },
    {
        "Id": 2299,
        "Name": "El Paseo",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 5, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7550097,-15.5694895"
    },
    {
        "Id": 2300,
        "Name": "Tabaibas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 9, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.754235,-15.5699876"
    },
    {
        "Id": 2304,
        "Name": "Santa Monica",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "P.º Costa Canaria, 116, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7509384,-15.5724896"
    },
    {
        "Id": 2343,
        "Name": "Arco Iris",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Milán, 731, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7530641,-15.5713278"
    },
    {
        "Id": 2345,
        "Name": "Australia",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 23, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7581197,-15.5740515"
    },
    {
        "Id": 2346,
        "Name": "Bogotá Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 18, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7522249,-15.5719924"
    },
    {
        "Id": 2348,
        "Name": "The Vintage Club",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Alemania, 16, 35100 Playa del Inglés",
        "Coordinates": "27.754342242602604,-15.573907381077717"
    },
    {
        "Id": 2349,
        "Name": "Ecuador",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 19, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7535832,-15.5729705"
    },
    {
        "Id": 2351,
        "Name": "Horizonte",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 3, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7552507,-15.5693143"
    },
    {
        "Id": 2352,
        "Name": "Las Camelias",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 15, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7559024,-15.5791545"
    },
    {
        "Id": 2353,
        "Name": "Las Rosas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 8, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7553407,-15.5720058"
    },
    {
        "Id": 2355,
        "Name": "Los Almendros",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Francia, 3, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7511941,-15.5752388"
    },
    {
        "Id": 2357,
        "Name": "Los Mariachis",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 14, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7548387,-15.5709793"
    },
    {
        "Id": 2360,
        "Name": "Nogal Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Milán, 16, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7516236,-15.5728231"
    },
    {
        "Id": 2361,
        "Name": "Nogalera",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle la Luna, 7, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7547771,-15.569821"
    },
    {
        "Id": 2362,
        "Name": "Playa del Sol",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 26, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7586398,-15.5788469"
    },
    {
        "Id": 2366,
        "Name": "Sandra",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 15, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7532874,-15.5722032"
    },
    {
        "Id": 2368,
        "Name": "Walhalla",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Alfereces Provisionales, 19, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7578797,-15.5726006"
    },
    {
        "Id": 2417,
        "Name": "Peru Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Roma, 22, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7527502,-15.5717903"
    },
    {
        "Id": 2439,
        "Name": "Solymar Aptos.",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Tirajana, 4, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.748374,-15.5761538"
    },
    {
        "Id": 2472,
        "Name": "Atlantis Playa del Inglés",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Avda Alfereces Provisionales, 27, 35100 Las Palmas",
        "Coordinates": "27.758251688336266,-15.574951539269268"
    },
    {
        "Id": 2484,
        "Name": "Copacabana",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "C. Roma, 22, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.752823204079547,-15.571877356666826"
    },
    {
        "Id": 2493,
        "Name": "Valparaiso",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. Estados Unidos, 36, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.75689913956814,-15.571963244645337"
    },
    {
        "Id": 2505,
        "Name": "Granada Beach",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Calle Granada, 8, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.751750072570005,-15.5738439970887"
    },
    {
        "Id": 2526,
        "Name": "Royal Level Barc. Margaritas",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 40, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.76259514351389,-15.581394445929934"
    },
    {
        "Id": 2565,
        "Name": "Bungalow Beatriz",
        "Island": "GC",
        "ZoneId": 104,
        "ZoneName": "Playa del Inglés 1",
        "Address": "Av. de Gran Canaria, 90, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.748762233098038,-15.578488520418214"
    },
    {
        "Id": 1118,
        "Name": "Club Vista Serena",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Capricornio, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7646356,-15.5891213"
    },
    {
        "Id": 1119,
        "Name": "Colorado Golf - Antes MRC Maspalomas",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 24, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.758976,-15.5934439"
    },
    {
        "Id": 1127,
        "Name": "La Suerte del Golf Aptos.",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "ES, Avenida Touroperador Matkat, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.752213,-15.5886662"
    },
    {
        "Id": 1148,
        "Name": "Eo Maspalomas Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Neckermann, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7567213,-15.5875307"
    },
    {
        "Id": 1179,
        "Name": "Starboys Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. de Alemania, 15, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7532783,-15.5743706"
    },
    {
        "Id": 1198,
        "Name": "Canary Garden Club",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Ols Wings, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.756458,-15.595977"
    },
    {
        "Id": 1199,
        "Name": "Capri Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Tauro, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7634274,-15.5895634"
    },
    {
        "Id": 1208,
        "Name": "Cay Beach Princess",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Thomson Hol., 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7565389,-15.5994583"
    },
    {
        "Id": 1209,
        "Name": "Bungalows Los Claveles",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 49, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7578293,-15.5944895"
    },
    {
        "Id": 1210,
        "Name": "Allsun Esplendido",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av Touroperador Sunair, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7472222,-15.5988889"
    },
    {
        "Id": 1211,
        "Name": "Club Maspalmos Suites and Spa",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Ols Wings, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7553386,-15.5974149"
    },
    {
        "Id": 1216,
        "Name": "Cordial Green Golf",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7522083,-15.5907616"
    },
    {
        "Id": 1219,
        "Name": "Cordial Sandy Golf Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, s/n, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.752570213282873,-15.59019573032856"
    },
    {
        "Id": 1234,
        "Name": "Kumara Serenoa",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Fritidsresor, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7517763,-15.5833584"
    },
    {
        "Id": 1235,
        "Name": "Livvo Dunagolf Suites",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avenida Fritis Resort, 2, 35290 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7515316,-15.5855484"
    },
    {
        "Id": 1237,
        "Name": "Dunas Maspalomas Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Air Marín, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7503726,-15.5977184"
    },
    {
        "Id": 1239,
        "Name": "Dunas Suites and Villas Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avenida Sunair, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7470627,-15.5971679"
    },
    {
        "Id": 1247,
        "Name": "El Palmeral",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Touroperador Alpitour, 2, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.755025742858674,-15.59158444404602"
    },
    {
        "Id": 1266,
        "Name": "Green Oasis Club",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Air Marín, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7528874,-15.6004371"
    },
    {
        "Id": 1287,
        "Name": "Kiwi",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Finnmatkat, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757899,-15.5898916"
    },
    {
        "Id": 1288,
        "Name": "Livvo Koala Garden",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Geminis, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7627161,-15.5922966"
    },
    {
        "Id": 1290,
        "Name": "La Mirage Swingers",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.752802,-15.5946967"
    },
    {
        "Id": 1293,
        "Name": "Villas Las Almenas ",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, 47, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7573042,-15.5852394"
    },
    {
        "Id": 1303,
        "Name": "Las Vegas Golf",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, 7, Bungalow 416, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7549412,-15.5875633"
    },
    {
        "Id": 1337,
        "Name": "Maspalomas Golf (Campo Golf)",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Neckermann, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.752025455620487,-15.593644380569458"
    },
    {
        "Id": 1338,
        "Name": "Maspalomas Lago Canary Suites ",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Kuoni, 25, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7601345,-15.5895857"
    },
    {
        "Id": 1339,
        "Name": "Maspalomas Oasis Club",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "CAMPO INTERNACIONAL DE MASPALOMAS, Av. Touroperador Air Marín, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7503383,-15.6003983"
    },
    {
        "Id": 1340,
        "Name": "Maspalomas Princess",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tui, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7522066,-15.5976317"
    },
    {
        "Id": 1367,
        "Name": "Parque Bali Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Thomson Hol., 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.755167,-15.6001568"
    },
    {
        "Id": 1370,
        "Name": "Parque Golf",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avenida Fritidsresor, S/N, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7528807,-15.5833965"
    },
    {
        "Id": 1371,
        "Name": "Parque Nogal",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Leo, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7642364,-15.5901169"
    },
    {
        "Id": 1422,
        "Name": "Servatur Suns Gardens",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Virgo, nº2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7656326,-15.5900068"
    },
    {
        "Id": 1427,
        "Name": "Siesta Suites",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Touroperador Matkatala, 21, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7526998,-15.5876587"
    },
    {
        "Id": 1432,
        "Name": "EÓ Suite Hotel Jardín Dorado",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Urbanización Campo de Golf de Maspalomas, Avenida Tour Operador Tjaereborg, 6, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.754987765055816,-15.585013031959534"
    },
    {
        "Id": 1439,
        "Name": "Tabaiba Princess",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tui, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7521777,-15.5966934"
    },
    {
        "Id": 1443,
        "Name": "Tara Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Ols Wings, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7572777,-15.5954558"
    },
    {
        "Id": 1452,
        "Name": "Tisalaya Park",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Tauro, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7635998,-15.5884678"
    },
    {
        "Id": 1454,
        "Name": "Turbo Club",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Libra, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7651472,-15.5908938"
    },
    {
        "Id": 1459,
        "Name": "Villas Blancas Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Tourop Tjaerebrg, 46, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.756409550014546,-15.585916936397552"
    },
    {
        "Id": 1464,
        "Name": "VistaFlor Bungalow",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Neckermann, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7587427,-15.5849907"
    },
    {
        "Id": 1503,
        "Name": "Campo Golf Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Neckermann, 42, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7515798,-15.5950163"
    },
    {
        "Id": 1505,
        "Name": "Los Tunos Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 22, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7574777,-15.5940896"
    },
    {
        "Id": 1508,
        "Name": "Sonora Golf Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Kuoni, 27, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.7601253,-15.5909231"
    },
    {
        "Id": 1509,
        "Name": "Suns Gardens Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Virgo, nº2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7656326,-15.5900068"
    },
    {
        "Id": 1510,
        "Name": "Verdemar Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Aurinkomatka, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.755413,-15.5905914"
    },
    {
        "Id": 1516,
        "Name": "Club Vista Verde",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.756608,-15.5851932"
    },
    {
        "Id": 1520,
        "Name": "El Trigal Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avenida TTOO Vingresor, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7526779,-15.5935057"
    },
    {
        "Id": 1548,
        "Name": "Allsun Esplendido",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av Touroperador Sunair, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7470728446864,-15.59888800836189"
    },
    {
        "Id": 1549,
        "Name": "Parque Luz Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avenida Fritidsresor, S/N, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7528807,-15.5833965"
    },
    {
        "Id": 1560,
        "Name": "Allsun Esplendido",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av Touroperador Sunair, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7472222,-15.5988889"
    },
    {
        "Id": 1584,
        "Name": "Magnolias Natura",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Magnolias Natura, Av. Touroperador Tjaereborg, 32, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7576719,-15.5846747"
    },
    {
        "Id": 1588,
        "Name": "Playmar Bungalows Maspalomas",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Campo Internacional, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7589602,-15.5865825"
    },
    {
        "Id": 1589,
        "Name": "Club Tisaya Golf",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tjaereborg, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7561405,-15.5856439"
    },
    {
        "Id": 1600,
        "Name": "Palm Garden Bungalows",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7532125,-15.5936746"
    },
    {
        "Id": 1624,
        "Name": "Rainbow Golf Bung",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Finnmatkat, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757931,-15.588515"
    },
    {
        "Id": 1655,
        "Name": "Villa Paraiso",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. de Gran Canaria, 44, 46, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639668,-15.5838891"
    },
    {
        "Id": 1671,
        "Name": "Venus Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Tui, s/n, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.761078,-15.5902063"
    },
    {
        "Id": 1691,
        "Name": "Club Torso Maspalomas",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Kuoni, 8, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7569806,-15.5880306"
    },
    {
        "Id": 1700,
        "Name": "Vista Verde Beach Mate",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Avda. Touroperador Tjaereborg B90-92, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.756843,-15.5848161"
    },
    {
        "Id": 1701,
        "Name": "Bungalows Beach Flor",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Tourop Thomson Hol, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7572807,-15.5995"
    },
    {
        "Id": 1702,
        "Name": "Dunas Palmeral Oasis",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Air Marín, 2, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7503644,-15.5977849"
    },
    {
        "Id": 1708,
        "Name": "Dunaflor Maspalomas",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Neckermann, 14, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7574427,-15.586637"
    },
    {
        "Id": 1710,
        "Name": "FBC Fortuny Resort",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Air Marín, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7516651,-15.6005052"
    },
    {
        "Id": 1724,
        "Name": "Mercado San Fernando",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Pg C-6B Maspalomas, 1C, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.76719,-15.59284"
    },
    {
        "Id": 1741,
        "Name": "Bungalows Las almenas",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.756197,-15.5897969"
    },
    {
        "Id": 2336,
        "Name": "Aptos. Lacy",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Vingresor, 23, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7534861,-15.5949184"
    },
    {
        "Id": 2337,
        "Name": "LIVVO Los Calderones",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Air Marín, 2, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7517316,-15.6005322"
    },
    {
        "Id": 2338,
        "Name": "Grimanesa",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Capricornio, 4, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7650387,-15.5895486"
    },
    {
        "Id": 2339,
        "Name": "Los Melocotones",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Kuoni, 15, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7593345,-15.588128"
    },
    {
        "Id": 2340,
        "Name": "Seven Hotel & Wellness",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Calle Touroperador Matkatala, 2, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7521461,-15.5886734"
    },
    {
        "Id": 2464,
        "Name": "Los Leones",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Cosmos, 6, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7548925,-15.5966998"
    },
    {
        "Id": 2559,
        "Name": "Bungalows Playa Flor",
        "Island": "GC",
        "ZoneId": 105,
        "ZoneName": "Maspalomas",
        "Address": "Av. Touroperador Intamu, 2, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.755450462150375,-15.598900005022475"
    },
    {
        "Id": 1207,
        "Name": "Cay Beach Meloneras",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Báltico, 35100 LAS PALMAS, Las Palmas, Spain",
        "Coordinates": "27.745065,-15.607216"
    },
    {
        "Id": 1243,
        "Name": "El Capricho",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 21, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.736780630776252,-15.594760179519653"
    },
    {
        "Id": 1270,
        "Name": "H10 Playa Meloneras Palace",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Caspio, 5, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7468237,-15.6107903"
    },
    {
        "Id": 1279,
        "Name": "Lopesan Faro Collection",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. Cristóbal Colón, 1, 35100 Meloneras, Las Palmas, Spain",
        "Coordinates": "27.7356294,-15.5980951"
    },
    {
        "Id": 1307,
        "Name": "Lopesan Baobab",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Adriático, 1, 35100 Meloneras, Las Palmas, Spain",
        "Coordinates": "27.7408403,-15.6002276"
    },
    {
        "Id": 1308,
        "Name": "Lopesan Costa Meloneras",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Mediterráneo, 1, 35100 Meloneras, Las Palmas, Spain",
        "Coordinates": "27.738314,-15.5999021"
    },
    {
        "Id": 1309,
        "Name": "Lopesan Villa Del Conde",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Mediterráneo, 7, 35100 Meloneras, Las Palmas, Spain",
        "Coordinates": "27.7422778,-15.6065009"
    },
    {
        "Id": 1342,
        "Name": "Meloneras Golf (Campo Golf)",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Carretera, GC-500, S/N, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.750838,-15.6172136"
    },
    {
        "Id": 1358,
        "Name": "Oasis Maspalomas Aptos.",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 14, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.737260179720483,-15.594784319400787"
    },
    {
        "Id": 1359,
        "Name": "Oasis Tindaya",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "AV. DEL OASIS, 6",
        "Coordinates": "27.73814510776602,-15.595051897621847"
    },
    {
        "Id": 1397,
        "Name": "Riu Gran Canaria",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Urbanización Las Meloneras, Avenida del Mediterraneo, s/n, 35100 Maspalomas - Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7409529,-15.6044992"
    },
    {
        "Id": 1398,
        "Name": "Riu Palace Oasis",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Plaza de las Palmeras, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.736704,-15.5973484"
    },
    {
        "Id": 1400,
        "Name": "Riu Palace Meloneras",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Mediterráneo, s/n, 35100 Maspalomas - Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7397403,-15.6026083"
    },
    {
        "Id": 1417,
        "Name": "Seaside Grand Hotel Residencia",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 32, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7396343,-15.5970415"
    },
    {
        "Id": 1418,
        "Name": "Seaside Palm Beach",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7372879,-15.5952746"
    },
    {
        "Id": 1455,
        "Name": "Villa Eden",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.738794868278063,-15.59711581904907"
    },
    {
        "Id": 1476,
        "Name": "Cocoteros Aptos.",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 9, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.737457221496676,-15.594820529222488"
    },
    {
        "Id": 1500,
        "Name": "Bahia Meloneras Bungalows",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Caspio, 58, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.74507510561912,-15.607216358184814"
    },
    {
        "Id": 1546,
        "Name": "Palm Beach",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7372879,-15.5952746"
    },
    {
        "Id": 1582,
        "Name": "Tensuites",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 27, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.73647568942274,-15.595330049107751"
    },
    {
        "Id": 1620,
        "Name": "DunaOasis Maspalomas Aptos.",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Av. del Oasis, 10, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7374379,-15.5947566"
    },
    {
        "Id": 1656,
        "Name": "Las Velas",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar de Escocia, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7538392,-15.6067111"
    },
    {
        "Id": 1682,
        "Name": "Oasis Playa Maspalomas",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Playa de Maspalomas, Spain",
        "Coordinates": "27.7360141,-15.5939723"
    },
    {
        "Id": 1684,
        "Name": "Villas Bahia Meloneras",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Caspio, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7446034,-15.6083525"
    },
    {
        "Id": 1699,
        "Name": "Villas Meloneras",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "Calle Mar Mediterráneo, 14, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.743761,-15.6056384"
    },
    {
        "Id": 1705,
        "Name": "Oasis Foresta",
        "Island": "GC",
        "ZoneId": 106,
        "ZoneName": "Meloneras",
        "Address": "35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7374568,-15.5948198"
    },
    {
        "Id": 1128,
        "Name": "Las Fresas Bungalows",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 3, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7904235,-15.7067624"
    },
    {
        "Id": 1157,
        "Name": "Altair",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 12, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7899417,-15.7064952"
    },
    {
        "Id": 1158,
        "Name": "Altamadores",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle San Borondón, 6, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7943831,-15.7189577"
    },
    {
        "Id": 1159,
        "Name": "Altamar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 21, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7888021,-15.7201022"
    },
    {
        "Id": 1168,
        "Name": "Apartamentos Turísticos Aguasol",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Roque del Este, 24, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7865492,-15.7064897"
    },
    {
        "Id": 1169,
        "Name": "Arimar Sun",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 3, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7913249,-15.7109192"
    },
    {
        "Id": 1170,
        "Name": "Arizona",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Avenida de Canarias, s/n, 35139 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7889299,-15.708119"
    },
    {
        "Id": 1175,
        "Name": "Babalu",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 15, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7897222,-15.7166667"
    },
    {
        "Id": 1176,
        "Name": "Balcon De Mar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 25, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7932124,-15.7123611"
    },
    {
        "Id": 1188,
        "Name": "Boston",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 34, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7910881,-15.7072438"
    },
    {
        "Id": 1195,
        "Name": "Cala Nova",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Cala Nova, 07849, Illes Balears, Spain",
        "Coordinates": "39.0035636,1.5781016"
    },
    {
        "Id": 1197,
        "Name": "Canaima",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de los Cardones, 1-2, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7930354,-15.7099401"
    },
    {
        "Id": 1200,
        "Name": "Carlota",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 16, 35130 Puerto Rico de Gran Canaria, Las Palmas",
        "Coordinates": "27.79210948873866,-15.706726312637329"
    },
    {
        "Id": 1202,
        "Name": "Casablanca Puerto Rico Servatur",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 24, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7919838,-15.7058666"
    },
    {
        "Id": 1206,
        "Name": "Castillo de Sol Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 16, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7911111,-15.7061111"
    },
    {
        "Id": 1213,
        "Name": "Colina Mar Aparthotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 4, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7912381,-15.7139361"
    },
    {
        "Id": 1227,
        "Name": "Cumana Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 14, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7922222,-15.7097222"
    },
    {
        "Id": 1242,
        "Name": "Eden ",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Roque de los Muchachos, 1, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7916037,-15.7160393"
    },
    {
        "Id": 1245,
        "Name": "El Greco Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Doreste y Molina, 38, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7849072,-15.7148051"
    },
    {
        "Id": 1258,
        "Name": "Gelimar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7868187,-15.7157031"
    },
    {
        "Id": 1262,
        "Name": "Gran Amadores",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Islas Afortunadas, 3, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7911111,-15.7208333"
    },
    {
        "Id": 1268,
        "Name": "Guanabara Park",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle los Pechos, 2, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7858528,-15.7035744"
    },
    {
        "Id": 1271,
        "Name": "Heliomar Palmeras",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.79253,-15.709162"
    },
    {
        "Id": 1295,
        "Name": "Las Buganvillas Apt. Puerto Rico",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 17, 35139 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7908755,-15.7156398"
    },
    {
        "Id": 1305,
        "Name": "Leticia del Mar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Urbanización de Puerto Rico, Av. de Mogán, 5, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7884388,-15.7115435"
    },
    {
        "Id": 1323,
        "Name": "Los Veleros",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Guayadeque, 16, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.784696,-15.7089245"
    },
    {
        "Id": 1325,
        "Name": "Marianela Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gavilanes nº, 6, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7853446,-15.7075027"
    },
    {
        "Id": 1326,
        "Name": "Mocanes",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tasartico, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7894608,-15.7124456"
    },
    {
        "Id": 1328,
        "Name": "Maracaibo",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 13, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7869427,-15.7144426"
    },
    {
        "Id": 1330,
        "Name": "Marina Bayview",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Guayadeque, 3, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7841256,-15.7097987"
    },
    {
        "Id": 1331,
        "Name": "Marina Elite - Balito Beach",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Diseminado Barranco de Balito, 1, 35129 Balito, Las Palmas, Spain",
        "Coordinates": "27.7752268,-15.7015011"
    },
    {
        "Id": 1333,
        "Name": "Marina Suites",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Juan Díaz Rodríguez, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7813691,-15.7094279"
    },
    {
        "Id": 1344,
        "Name": "Miriam",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. la Graciosa, 13, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7854328,-15.7094593"
    },
    {
        "Id": 1349,
        "Name": "Monteparaiso",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Isla de Lobos, 2, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.789086336282416,-15.71543740629168"
    },
    {
        "Id": 1350,
        "Name": "Morasol Suites",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Juan Díaz Rodríguez, 12, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7822222,-15.7088889"
    },
    {
        "Id": 1356,
        "Name": "Nido del Aguila",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Roque de los Muchachos, 3, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7913056,-15.716825"
    },
    {
        "Id": 1357,
        "Name": "Niza",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 2, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.787642,-15.7099915"
    },
    {
        "Id": 1360,
        "Name": "Omar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 4, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7885771,-15.7076695"
    },
    {
        "Id": 1363,
        "Name": "Palmera Mar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Montaña Clara, 5, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7909133,-15.7192045"
    },
    {
        "Id": 1374,
        "Name": "Parque Raquel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 13, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7918301,-15.709183"
    },
    {
        "Id": 1384,
        "Name": "Portonovo Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle de Juan Díaz Rodríguez, 29, 31130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7838121,-15.7117909"
    },
    {
        "Id": 1385,
        "Name": "Portosol",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Fuerteventura, 15, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7849383,-15.7103429"
    },
    {
        "Id": 1387,
        "Name": "Puerto Azul Servatur",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 13, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7871843,-15.7170867"
    },
    {
        "Id": 1391,
        "Name": "Revoli",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 11, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7883011,-15.7127177"
    },
    {
        "Id": 1393,
        "Name": "Rio Piedras",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle el Ancla, 2, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.786795,-15.713022"
    },
    {
        "Id": 1394,
        "Name": "Riosol",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Isla de Lobos, 1, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.788779691401047,-15.715588179230375"
    },
    {
        "Id": 1403,
        "Name": "Riu Vistamar hotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Urbanización Puerto Rico, Av. de la Cornisa, 23, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7882014,-15.7215884"
    },
    {
        "Id": 1406,
        "Name": "Rocamar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 18, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7916608,-15.711435"
    },
    {
        "Id": 1410,
        "Name": "Roslara",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tenerife, 7, 35139 Urb. Puerto Rico, Mogan, Las Palmas, Las Palmas, Spain",
        "Coordinates": "27.7939982,-15.713499"
    },
    {
        "Id": 1421,
        "Name": "Servatur Montebello",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7906952,-15.7115609"
    },
    {
        "Id": 1423,
        "Name": "Servatur Sunsuite Royal",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de los Cardones, 7A, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7950358,-15.7093392"
    },
    {
        "Id": 1424,
        "Name": "Servatur Terrazamar Sunsuite",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.794354,-15.7081044"
    },
    {
        "Id": 1429,
        "Name": "Solana",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de los Cardones, 6, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7931127,-15.7096289"
    },
    {
        "Id": 1440,
        "Name": "Dona Elvira",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 30, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7926514,-15.7055297"
    },
    {
        "Id": 1448,
        "Name": "Terraza Amadores",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Playa de Amadores, Calle Islas Afortunadas, 1, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7916667,-15.720108"
    },
    {
        "Id": 1460,
        "Name": "Villas Mirador del Mar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 31, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7945511,-15.7117343"
    },
    {
        "Id": 1471,
        "Name": "Bahia Blanca Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle la Palma, 1, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7874009,-15.719663"
    },
    {
        "Id": 1472,
        "Name": "Balcon de Amadores Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Montaña Clara, 1, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7892279,-15.7199189"
    },
    {
        "Id": 1474,
        "Name": "Barlovento Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Fuerteventura, 8, 35130 Mogán, Las Palmas",
        "Coordinates": "27.785525353361678,-15.710480064153671"
    },
    {
        "Id": 1477,
        "Name": "Corona Cedral Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 4, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7892286,-15.711793"
    },
    {
        "Id": 1482,
        "Name": "Guayarmina Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera s/n",
        "Coordinates": "27.786684706604692,-15.715605459120233"
    },
    {
        "Id": 1485,
        "Name": "Malibu Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Roque del Este, 26, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7873899,-15.7055693"
    },
    {
        "Id": 1487,
        "Name": "IG Nachosol Premium ",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 3, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7865314,-15.7156167"
    },
    {
        "Id": 1489,
        "Name": "Sol y Paz Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 19, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7909737,-15.7151251"
    },
    {
        "Id": 1493,
        "Name": "Tobago Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 21, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7861963,-15.7151548"
    },
    {
        "Id": 1495,
        "Name": "Mirador del Atlantico Aparthotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Islas Afortunadas, 5, 35130 Playa Amadores, Las Palmas, Spain",
        "Coordinates": "27.7902149,-15.7213674"
    },
    {
        "Id": 1497,
        "Name": "Puerto Feliz Aparthotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7892487,-15.7070452"
    },
    {
        "Id": 1498,
        "Name": "Terrazamar Aparthotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.794354,-15.7081044"
    },
    {
        "Id": 1499,
        "Name": "Aquacanis",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle el Gavilán, 2, 35130 Mogán, Las Palmas",
        "Coordinates": "27.785223395434468,-15.705921649932861"
    },
    {
        "Id": 1502,
        "Name": "Buenavista",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 14, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7864411,-15.7161618"
    },
    {
        "Id": 1504,
        "Name": "Caribe Bungalows",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Doreste y Molina, 4, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7875238,-15.7119609"
    },
    {
        "Id": 1506,
        "Name": "Monaco Bungalows",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 6, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7909377,-15.7107489"
    },
    {
        "Id": 1523,
        "Name": "Holiday Club Puerto Calma",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Joaquín Blanco Torrent, 2, 35130 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7830355,-15.7077077"
    },
    {
        "Id": 1530,
        "Name": "Dunas Amadores Hotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle la Palma, 2, 35139 Mogán, Las Palmas, Spain",
        "Coordinates": "27.786072,-15.7188626"
    },
    {
        "Id": 1531,
        "Name": "Sun Suite Royal Hotel",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de los Cardones, 7A, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7950358,-15.7093392"
    },
    {
        "Id": 1533,
        "Name": "Inagua",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle los Pechos, 1, 35130 Puerto Rico de Gran Canaria, Las Palmas",
        "Coordinates": "27.786303083965258,-15.70333868265152"
    },
    {
        "Id": 1534,
        "Name": "Isla Bonita",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tasartico, 8, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7884752,-15.7137758"
    },
    {
        "Id": 1535,
        "Name": "Jacaranda Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Roque del Este, 20, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7861624,-15.7082194"
    },
    {
        "Id": 1542,
        "Name": "Monte Paraiso Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Isla de Lobos, 2, 35130 Mogán, Las Palmas",
        "Coordinates": "27.789660728134823,-15.714778304100037"
    },
    {
        "Id": 1544,
        "Name": "Monterrey",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tasartico, 3, 35130 Mogán, Las Palmas",
        "Coordinates": "27.789411577888888,-15.712096095085144"
    },
    {
        "Id": 1550,
        "Name": "Portlamar Apartments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle el Gavilán, 12, 35139 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7843256,-15.7077384"
    },
    {
        "Id": 1552,
        "Name": "Terraza Mar Sunsuites",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.794354,-15.7081044"
    },
    {
        "Id": 1561,
        "Name": "Guayana",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 8D, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7918081,-15.7065362"
    },
    {
        "Id": 1562,
        "Name": "Puerto Bello",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tasartico, 6, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7888819,-15.7136862"
    },
    {
        "Id": 1573,
        "Name": "Richmond ",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Bentayga, 4, 35130 Mogán, Las Palmas",
        "Coordinates": "27.785299329923355,-15.704398155212402"
    },
    {
        "Id": 1591,
        "Name": "Servatur Hartaguna",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 16, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7912372,-15.7119168"
    },
    {
        "Id": 1603,
        "Name": "Sunsuites Lufesa",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Tomás Roca Bosch, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7917699,-15.7072699"
    },
    {
        "Id": 1606,
        "Name": "Mirador de Valle",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Ctra. Circunvalación, s/n, 45004 Toledo, Spain",
        "Coordinates": "39.8503616,-4.0218798"
    },
    {
        "Id": 1615,
        "Name": "Palmera Mar 2",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Montaña Clara, 5, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7909133,-15.7192045"
    },
    {
        "Id": 1616,
        "Name": "Bellavista",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "7,, Av. Veneguera, 3, 35130 Mogán, Las Palmas",
        "Coordinates": "27.78649291820313,-15.716075152158737"
    },
    {
        "Id": 1627,
        "Name": "XQ Vistamar Apartamentos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Olímpicos Doreste y Molina, 54, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.784852,-15.715168"
    },
    {
        "Id": 1629,
        "Name": "Vallemar Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "35130 Puerto Rico de Gran Canaria, Las Palmas",
        "Coordinates": "27.786775296018828,-15.70868968963623"
    },
    {
        "Id": 1632,
        "Name": "Eo Corona Cedral",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 4, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7892286,-15.711793"
    },
    {
        "Id": 1637,
        "Name": "Lairaga Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tenerife, 5, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7940359,-15.7131836"
    },
    {
        "Id": 1639,
        "Name": "Miami Sun (Pto. Rico)",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 7, 35139 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7857602,-15.7108498"
    },
    {
        "Id": 1652,
        "Name": "Tindaya Executive Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tasartico, 17, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7881315,-15.7140379"
    },
    {
        "Id": 1653,
        "Name": "Las Tuneras",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 39, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7912177,-15.7145781"
    },
    {
        "Id": 1658,
        "Name": "Servatur Sunsuites",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.794354,-15.7081044"
    },
    {
        "Id": 1670,
        "Name": "Timanfaya Apartamentos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 6, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7870504,-15.7152447"
    },
    {
        "Id": 1676,
        "Name": "Los Crotos Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Fuerteventura, 9, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7858391,-15.7097702"
    },
    {
        "Id": 1677,
        "Name": "Madelefi Aptos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Roque del Este, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas",
        "Coordinates": "27.787154962514315,-15.708678960800171"
    },
    {
        "Id": 1685,
        "Name": "Yaizasol Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Fuerteventura, 13, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7856322,-15.710133"
    },
    {
        "Id": 1689,
        "Name": "Amadores Apartamentos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Playa de Amadores, Calle Islas Afortunadas, 1, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7916667,-15.720108"
    },
    {
        "Id": 1727,
        "Name": "Orinoco Bungalows",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 37, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7893149,-15.710823"
    },
    {
        "Id": 1734,
        "Name": "Apartamentos Santa Claudia",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 16, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7949423,-15.7114336"
    },
    {
        "Id": 1742,
        "Name": "ROQUE NUBLO Pto. Rico",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 35130 Puerto Rico de Gran Canaria, Las Palmas",
        "Coordinates": "27.786046154921046,-15.716649851108226"
    },
    {
        "Id": 1746,
        "Name": "IG Nachosol atlantic",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Fuerteventura, nº 15, 35140 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7850229,-15.7102254"
    },
    {
        "Id": 2373,
        "Name": "Blue Star Apts. - Ocean Hill",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 19, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7890006,-15.7186568"
    },
    {
        "Id": 2374,
        "Name": "Cala D Or",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 14, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7904431,-15.7062264"
    },
    {
        "Id": 2375,
        "Name": "Cala Nova",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Gran Canaria, 2, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7878763,-15.7081401"
    },
    {
        "Id": 2376,
        "Name": "Corona Rosa 303",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Roque del Este, 22, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7865509,-15.7070858"
    },
    {
        "Id": 2377,
        "Name": "Florida",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Tomás Roca Bosch, 6, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7907516,-15.7078162"
    },
    {
        "Id": 2378,
        "Name": "Haití Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 23, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7855303,-15.7156573"
    },
    {
        "Id": 2379,
        "Name": "Halley",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Bentayga, 3, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7855394,-15.7042993"
    },
    {
        "Id": 2380,
        "Name": "La Cascada",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 16, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7849568,-15.7111737"
    },
    {
        "Id": 2382,
        "Name": "Aptos. Laguna",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "avda de las Palmeras 3. puerto rico. mogán, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7932446,-15.7085229"
    },
    {
        "Id": 2383,
        "Name": "Lara",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 15, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.790607,-15.7166117"
    },
    {
        "Id": 2384,
        "Name": "Mayaguez Aptos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 14, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.78522,-15.71118"
    },
    {
        "Id": 2385,
        "Name": "Miami Aptos.(Pto. Rico)",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 10, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7858407,-15.7108672"
    },
    {
        "Id": 2386,
        "Name": "Miami Beach (Pto. Rico)",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Joaquín Blanco Torrent, 8, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7845133,-15.7055163"
    },
    {
        "Id": 2387,
        "Name": "Montecarlo",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Tomás Roca Bosch, 20",
        "Coordinates": "27.792281864932473,-15.707404389016855"
    },
    {
        "Id": 2388,
        "Name": "Montesol Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 12, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7920515,-15.7110714"
    },
    {
        "Id": 2389,
        "Name": "Monteverde Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 4, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7891928,-15.7119673"
    },
    {
        "Id": 2390,
        "Name": "Mozart Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 28, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7900223,-15.7176057"
    },
    {
        "Id": 2391,
        "Name": "Natural Park",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle los Pechos, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7854268,-15.7023186"
    },
    {
        "Id": 2392,
        "Name": "Calypso Oasis Aptos. - Oasis de Paz",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 10, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.788421,-15.7132615"
    },
    {
        "Id": 2393,
        "Name": "Peñamar Aptos. - Sea Breaze",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Bentayga, 2, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7857089,-15.7045475"
    },
    {
        "Id": 2394,
        "Name": "Porlamar",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Joaquín Blanco Torrent, 31, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7842392,-15.7078434"
    },
    {
        "Id": 2395,
        "Name": "Puerto Plata",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 7, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7902943,-15.7125559"
    },
    {
        "Id": 2396,
        "Name": "Punta del Rey",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 36, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7847353,-15.7160324"
    },
    {
        "Id": 2397,
        "Name": "Sanfe Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 6, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7870504,-15.7152447"
    },
    {
        "Id": 2398,
        "Name": "Scorpio",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Timanfaya, 10, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7927271,-15.7121255"
    },
    {
        "Id": 2399,
        "Name": "Sirena Aptos.",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle el Ancla, 1, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7862142,-15.7137622"
    },
    {
        "Id": 2400,
        "Name": "Sunshine Villas",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle el Gavilán, 3, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7849831,-15.707395"
    },
    {
        "Id": 2401,
        "Name": "Villa Magna",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 6, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.7870504,-15.7152447"
    },
    {
        "Id": 2461,
        "Name": "Puerto Principe",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Veneguera, 7, 35130 Puerto Rico de Gran Canaria, Las Palmas, España",
        "Coordinates": "27.7860816,-15.7161074"
    },
    {
        "Id": 2475,
        "Name": "Calypso Apartmentos ",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Guayadeque, 8, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.78563097072582,-15.707631821623481"
    },
    {
        "Id": 2486,
        "Name": "Apartamentos Jamaica",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Tomás Roca Bosch, 9, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.788245840164393,-15.709514186381668"
    },
    {
        "Id": 2488,
        "Name": "Porto Alegre",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle El Gavilán, 4",
        "Coordinates": "27.785294095576933,-15.70680992687846"
    },
    {
        "Id": 2494,
        "Name": "JB Puerto Rico",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de la Cornisa, 9, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.78801964263884,-15.71499969601148"
    },
    {
        "Id": 2519,
        "Name": "Trinidad",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Avenida Alegranza, 1, Puerto Rico",
        "Coordinates": "27.789464776955512,-15.708271009897771"
    },
    {
        "Id": 2521,
        "Name": "Carolina Apartamentos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de los Cardones, 7A, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.79476171449667,-15.70942628965633"
    },
    {
        "Id": 2534,
        "Name": "Bungalow Yucatán",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. las Palmeras, 16, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.791212986374138,-15.70967890180233"
    },
    {
        "Id": 2538,
        "Name": "Apartamento Los Dragos",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. Lanzarote, 12, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.785377753831018,-15.710925467943401"
    },
    {
        "Id": 2543,
        "Name": "Apartamentos Bonanza",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Av. de Mogán, 8, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.78854842376694,-15.712852999525827"
    },
    {
        "Id": 2560,
        "Name": "Puerto Mar Apartaments",
        "Island": "GC",
        "ZoneId": 108,
        "ZoneName": "Puerto Rico",
        "Address": "Calle Tazartico, 1, 35130 Mogán, Las Palmas, España",
        "Coordinates": "27.78969783670651,-15.712014693644095"
    },
    {
        "Id": 1151,
        "Name": "Amadores Beach",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle San Borondón, 1, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7904711,-15.724316"
    },
    {
        "Id": 1259,
        "Name": "Gloria Palace Amadores Thalasso & Hotel",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle la Palma, 2, 35139 Mogán, Las Palmas, Spain",
        "Coordinates": "27.786072,-15.7188626"
    },
    {
        "Id": 1260,
        "Name": "Gloria Palace Royal Hotel & Spa",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle Tamara, 1, 35130 Playa Amadores, Las Palmas, Spain",
        "Coordinates": "27.792303,-15.722207"
    },
    {
        "Id": 1304,
        "Name": "Las Villas de Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Playa de Amadores, Calle Tenerife, 32, 35129 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.79481,-15.7168"
    },
    {
        "Id": 1513,
        "Name": "Club Playa Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "1 Avenida Minstra Anna Lindh Amadores, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7912501,-15.7226192"
    },
    {
        "Id": 1524,
        "Name": "Holiday Club Hotel Sol Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle Tenerife, 11, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7939586,-15.7147626"
    },
    {
        "Id": 1525,
        "Name": "Holiday Club Jardin de Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle Montaña Clara, 4, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7902332,-15.7194236"
    },
    {
        "Id": 1526,
        "Name": "Holiday Club Playa de Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "1 Avenida Minstra Anna Lindh Amadores, 35130 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7912501,-15.7226192"
    },
    {
        "Id": 1527,
        "Name": "Holiday Club Vista Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle Montaña Clara, 2, 35130 Mogán, Las Palmas, Spain",
        "Coordinates": "27.790649,-15.7188663"
    },
    {
        "Id": 1539,
        "Name": "Marina Beach Hotel",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Av. Playa del Cura, 4, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.79905,-15.7328833"
    },
    {
        "Id": 2369,
        "Name": "Serenity Amadores",
        "Island": "GC",
        "ZoneId": 109,
        "ZoneName": "Playa De Amadores",
        "Address": "Calle San Borondón, 9, 35138 Mogán, Las Palmas, España",
        "Coordinates": "27.7933506,-15.7220749"
    },
    {
        "Id": 1134,
        "Name": "Labranda Costa Mogan",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Av. Playa del Cura, 4, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.79905,-15.7328833"
    },
    {
        "Id": 1161,
        "Name": "Anfi Emerald",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Av. Canadá, 43, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8038264,-15.7161151"
    },
    {
        "Id": 1162,
        "Name": "Anfi Tauro Golf Club (Campo Golf)",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Valle de Tauro, s/n, 35138, Las Palmas, Spain",
        "Coordinates": "27.8010967,-15.7197141"
    },
    {
        "Id": 1317,
        "Name": "Los Jardines del Cura",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Vaticano, 21, 35140 Playa del Cura, Las Palmas, Spain",
        "Coordinates": "27.8079108,-15.7304649"
    },
    {
        "Id": 1496,
        "Name": "Paraiso Club Aparthotel",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Pardela",
        "Coordinates": "27.80205270218182,-15.731827592217874"
    },
    {
        "Id": 1519,
        "Name": "El Cardenal",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Petrel, 3, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8001318,-15.7334951"
    },
    {
        "Id": 1556,
        "Name": "Villas de Tauro",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Av. Canadá, 45-61, 35130 Taurito, Las Palmas, Spain",
        "Coordinates": "27.8051976,-15.7129699"
    },
    {
        "Id": 1575,
        "Name": "Suite Monte Golf",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Petrel, 1, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.800114,-15.733638"
    },
    {
        "Id": 1613,
        "Name": "Su Eminencia",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Petrel, 5, 35138 Mogán, Las Palmas",
        "Coordinates": "27.79914225025619,-15.734063386917114"
    },
    {
        "Id": 1614,
        "Name": "Curasol II",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Playa del Cura, Calle Paíño, 1, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8003781,-15.7302111"
    },
    {
        "Id": 1666,
        "Name": "Curasol",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Playa del Cura, Calle Paíño, 1, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8003781,-15.7302111"
    },
    {
        "Id": 1683,
        "Name": "Demelza Beach",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle el Beato, 56, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8039333,-15.7342667"
    },
    {
        "Id": 1687,
        "Name": "Cura Marina II",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Avda. de la Playa, 2, 35138 Playa del cura, Las Palmas, Spain",
        "Coordinates": "27.7985474,-15.7328209"
    },
    {
        "Id": 1697,
        "Name": "Anfi Opal Hotel",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Escocia, 5, 35138 Puerto Rico de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7993077,-15.7202729"
    },
    {
        "Id": 1715,
        "Name": "Idyll Suites",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Petrel, 3, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8005422,-15.7329633"
    },
    {
        "Id": 1721,
        "Name": "Anfi Topaz",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Escocia nr. 1, Las Palmas,, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8002457,-15.7167365"
    },
    {
        "Id": 2309,
        "Name": "Monseñor",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Petrel, 4, 35138 Mogán, Las Palmas, España",
        "Coordinates": "27.8000238,-15.7340808"
    },
    {
        "Id": 2370,
        "Name": "Riviera Vista",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Calle Paíño, 5, 35138 Mogán, Las Palmas, España",
        "Coordinates": "27.7989332,-15.7298909"
    },
    {
        "Id": 2445,
        "Name": " OCEAN BEACH CLUB",
        "Island": "GC",
        "ZoneId": 110,
        "ZoneName": "Playa Del Cura",
        "Address": "Playa del Cura, s/n, 35140 Mogán, Las Palmas",
        "Coordinates": "27.80019570165841,-15.731499195098877"
    },
    {
        "Id": 1194,
        "Name": "Cala Blanca",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Cala Blanca, Illes Balears, Spain",
        "Coordinates": "39.9717848,3.8334817"
    },
    {
        "Id": 1345,
        "Name": "Mogan Princess & Beach Club",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Urb. de Taurito, Calle Albaicín, 17, 35138 Taurito, Las Palmas, Spain",
        "Coordinates": "27.814534738483644,-15.750519034224384"
    },
    {
        "Id": 1364,
        "Name": "LIVVO Costa Taurito",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Calle Alcazaba, 4, 35138 Taurito, Las Palmas, Spain",
        "Coordinates": "27.817461562503727,-15.750857103711787"
    },
    {
        "Id": 1365,
        "Name": "LIVVO Lago Taurito",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Taurito, s/n, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8157349,-15.7520437"
    },
    {
        "Id": 1366,
        "Name": "LIVVO Valle Taurito",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Urbanización Taurito, Calle Alhambra, s/n, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8169444,-15.7530556"
    },
    {
        "Id": 1433,
        "Name": "Suite Princess Htl",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Urb. de, Calle Alcazaba, 4, 35138 Taurito, Las Palmas, Spain",
        "Coordinates": "27.8143389,-15.7519276"
    },
    {
        "Id": 1444,
        "Name": "Taurito Princess",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Urb. de Taurito, Calle Alhambra, 8, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.81546539102417,-15.75371479127977"
    },
    {
        "Id": 1445,
        "Name": "Tauro Resort",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Av. del Guincho, s/n, 35138, Las Palmas, Spain",
        "Coordinates": "27.8073857,-15.7255739"
    },
    {
        "Id": 1466,
        "Name": "Vista Taurito Apartments HV",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Calle Albaicín, 35138 Taurito, Las Palmas, Spain",
        "Coordinates": "27.8149825,-15.7500297"
    },
    {
        "Id": 1473,
        "Name": "Balcon de Taurito Aptos.",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Calle Albaicín, s/n, 35138 La Playa de Mogán, Las Palmas, Spain",
        "Coordinates": "27.8185763,-15.7493394"
    },
    {
        "Id": 2371,
        "Name": "Cala Blanca",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "Calle Albaicín, 12, 35138 Taurito, Las Palmas, España",
        "Coordinates": "27.8150189,-15.7505201"
    },
    {
        "Id": 2481,
        "Name": "Apartamentos Cordial Magec Taurito",
        "Island": "GC",
        "ZoneId": 111,
        "ZoneName": "Taurito",
        "Address": "C. Albaicín, 6, 35138 Taurito, Las Palmas",
        "Coordinates": "27.81782583789029,-15.75018293195123"
    },
    {
        "Id": 1165,
        "Name": "Cordial Mogan Valle Aparthotel",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 2, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8224634,-15.7599905"
    },
    {
        "Id": 1217,
        "Name": "Cordial Mogan Playa",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 2, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8224634,-15.7599905"
    },
    {
        "Id": 1218,
        "Name": "Cordial Mogan Valle",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 4, 35138 Lomo Quiebre, Las Palmas, España",
        "Coordinates": "27.824572975643026,-15.758127884471769"
    },
    {
        "Id": 1291,
        "Name": "La Venecia de Canarias",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Callejón Explanada del Castillete, local 328, 35138 Puerto de Mogán, Las Palmas, Spain",
        "Coordinates": "27.8179233,-15.7647669"
    },
    {
        "Id": 1438,
        "Name": "The Puerto de Mogan Hotel & Apart.",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Urbanización Puerto de Mogán, s/n, 35138 La Playa de Mogán, Las Palmas, Spain",
        "Coordinates": "27.81676,-15.763916"
    },
    {
        "Id": 1568,
        "Name": "Los Mangueros Aptos.",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 6, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8256645,-15.7565588"
    },
    {
        "Id": 1583,
        "Name": "Las Brisas Mogan",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Calle Subida a los Riscos, 4, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8188282,-15.7648833"
    },
    {
        "Id": 1585,
        "Name": "El Sirocco Apts",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Urbanisation puerto Mogan nr 76, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8175501,-15.7659395"
    },
    {
        "Id": 1636,
        "Name": "Parada Taxis Mogan",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Calle Explanada del Castillete",
        "Coordinates": "27.81820495982171,-15.764948679708265"
    },
    {
        "Id": 1638,
        "Name": "Marina Puerto Mogan Aptos.",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Puerto de Mogán, 35138 Mogán, Las Palmas, Spain",
        "Coordinates": "27.823351,-15.7593141"
    },
    {
        "Id": 1644,
        "Name": "Edificio Filin",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. el Marinero, 15, 35139 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8221676,-15.7630195"
    },
    {
        "Id": 1649,
        "Name": "Edificio Playa",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Paseo de los Pescadores, 14, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.818914,-15.7636152"
    },
    {
        "Id": 1668,
        "Name": "Pension Eva",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. el Marinero, 65, 35139 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.824777,-15.7613661"
    },
    {
        "Id": 1695,
        "Name": "Spar Mogan",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av de la Constitución, 32, 35140 Mogán, Las Palmas, Spain",
        "Coordinates": "27.8827167,-15.7249702"
    },
    {
        "Id": 1714,
        "Name": "Radisson Blu Mogan",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 35, 35140 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.8257879,-15.7575772"
    },
    {
        "Id": 1718,
        "Name": "Sol Mogan Suite",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Calle Antoñito el Molinero, 12, 35149 El Cercado, Las Palmas, Spain",
        "Coordinates": "27.8420968,-15.7453023"
    },
    {
        "Id": 1722,
        "Name": "HD Mogan Beach Aptos",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Pasaje La Traíña, 2, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.818807,-15.76434"
    },
    {
        "Id": 1723,
        "Name": "Bella Vista Sun Club",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Calle Subida a los Riscos, 30, 35130 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8194149,-15.7652295"
    },
    {
        "Id": 1736,
        "Name": "Cordial Muelle Viejo",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Calle la Puntilla, 2, 4, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8178976,-15.7617897"
    },
    {
        "Id": 1753,
        "Name": "Blue Explorers Resort",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Av. Los Marrero, 18, 35138 Lomo Quiebre, Las Palmas, Spain",
        "Coordinates": "27.8273973,-15.7554601"
    },
    {
        "Id": 2460,
        "Name": "Golden Beach",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "15 Calle la Puntilla, 35138 Puerto de Mogán",
        "Coordinates": "27.818755288078677,-15.76112566308773"
    },
    {
        "Id": 2470,
        "Name": "Cordial Mogán Solaz",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "C. la Puntilla, 26, 35138 Lomo Quiebre, Las Palmas, España",
        "Coordinates": "27.820158030567256,-15.760808265879236"
    },
    {
        "Id": 2537,
        "Name": "Ernesto Apartamentos",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "Urb. Puerto Mogán F1, 9, 35139 Lomo Quiebre, Las Palmas, España",
        "Coordinates": "27.81822197124264,-15.764766329031607"
    },
    {
        "Id": 2552,
        "Name": "HD Mogan Coral Apartments",
        "Island": "GC",
        "ZoneId": 112,
        "ZoneName": "Mogan",
        "Address": "C. Subida a los Riscos, 7, 35139 Lomo Quiebre, Las Palmas, España",
        "Coordinates": "27.818873,-15.7649411"
    },
    {
        "Id": 1129,
        "Name": "Alisios Canteras Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Colombia, 12, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.13529,-15.439194"
    },
    {
        "Id": 1130,
        "Name": "Hotel Alisios Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Colombia, 12, 35010 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.13529,-15.439194"
    },
    {
        "Id": 1135,
        "Name": "Brisamar Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Paseo las Canteras, 49, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1382155,-15.4367932"
    },
    {
        "Id": 1136,
        "Name": "Crisol Faycan",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Nicolás Estévanez, 61, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.139215,-15.4359919"
    },
    {
        "Id": 1137,
        "Name": "Tejeda Aptos.",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Santa Catalina, Calle Dr. Grau Bassas, 44, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1395148,-15.4356806"
    },
    {
        "Id": 1138,
        "Name": "Maype Canteras Aptos.",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Kant, 2, 35010 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1375813,-15.4369849"
    },
    {
        "Id": 1139,
        "Name": "Hitide Surf House",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Paseo las Canteras, 57, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.137596,-15.437121"
    },
    {
        "Id": 1140,
        "Name": "Idafe Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Nicolás Estévanez, 49, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1392636,-15.435144"
    },
    {
        "Id": 1141,
        "Name": "Sea of clouds - pension ibiz",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Nicolás Estévanez, 31, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1393418,-15.4339046"
    },
    {
        "Id": 1142,
        "Name": "Aguas Verde",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "55,, Paseo las Canteras, 47, 35010 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.138424001088218,-15.43677270412445"
    },
    {
        "Id": 1143,
        "Name": "Volpe Rossa",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Isla de Cuba, 32, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1377217,-15.4332089"
    },
    {
        "Id": 1144,
        "Name": "Lua Lua Hostel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Fernando Guanarteme, 27, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1374,-15.4348"
    },
    {
        "Id": 1145,
        "Name": "Marmoral",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Dr. Grau Bassas, 38, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1391294,-15.4356223"
    },
    {
        "Id": 1147,
        "Name": "Valentina Beach",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Pascal, 1, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1378827,-15.435263"
    },
    {
        "Id": 1149,
        "Name": "AC Gran Canaria",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Eduardo Benot, 3-5, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1420488,-15.4303314"
    },
    {
        "Id": 1150,
        "Name": "AC Hotel Iberia Las Palmas by Marriott",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Avenida Alcalde J Ramirez Bethencourt 8, 35003 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.112177701311563,-15.41681170463562"
    },
    {
        "Id": 1156,
        "Name": "Aloe Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle de los Gofiones, 2, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1477355,-15.4299373"
    },
    {
        "Id": 1171,
        "Name": "Bull Astoria",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle de, Calle Fernando Guanarteme, 54, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1350842,-15.4378653"
    },
    {
        "Id": 1182,
        "Name": "EXE LAS PALMAS",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sagasta, 28, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1437697,-15.4309322"
    },
    {
        "Id": 1185,
        "Name": "Blanca Paloma",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Princesa Guayarmina, 2, 35008 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.148945080422035,-15.42829155921936"
    },
    {
        "Id": 1214,
        "Name": "Colon Playa Aptos.",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Alfredo L Jones, 45, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1418759,-15.4338604"
    },
    {
        "Id": 1226,
        "Name": "Hotel Cristina by Tigotan",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Gomera, 6, 35008 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.14476490132583,-15.430596121665197"
    },
    {
        "Id": 1252,
        "Name": "Sercotel Playa Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "calle portugal, 68",
        "Coordinates": "28.136064,-15.4382595"
    },
    {
        "Id": 1254,
        "Name": "Livvo Fataga",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Néstor de la Torre, 21, 35006 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1336548,-15.4332654"
    },
    {
        "Id": 1273,
        "Name": "Parque Hotel(Sercortel Parque Las Palmas)",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Muelle Las Palmas, 2, 35003 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.109527,-15.416969"
    },
    {
        "Id": 1281,
        "Name": "Crisol Alisios Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Colombia, 12, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.13529,-15.439194"
    },
    {
        "Id": 1324,
        "Name": "Luz Playa",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sagasta, 66, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1463,-15.4303"
    },
    {
        "Id": 1351,
        "Name": "NH Imperial Playa",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Ferreras, 1, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.148424,-15.430761"
    },
    {
        "Id": 1352,
        "Name": "NH Playa Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Prudencio Morales, 41, 35009 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.149008,-15.430695"
    },
    {
        "Id": 1388,
        "Name": "Pujol",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Salvador Cuyas, 5, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1438791,-15.4306388"
    },
    {
        "Id": 1390,
        "Name": "Reina Isabel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Alfredo L Jones, 40, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1421756,-15.433316"
    },
    {
        "Id": 1415,
        "Name": "Santa Catalina Royal Hideaway",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle León y Castillo, 227, 35005 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1212319,-15.4279414"
    },
    {
        "Id": 1458,
        "Name": "Verol",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sagasta, 25, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1433773,-15.4314585"
    },
    {
        "Id": 1492,
        "Name": "Tinoca Aptos",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle la Naval, 10, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1487273,-15.4304394"
    },
    {
        "Id": 1518,
        "Name": "Dunas Canteras Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sagasta, 28, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1437697,-15.4309322"
    },
    {
        "Id": 1536,
        "Name": "Occidental Las Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Bernardo de la Torre, 79, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.140614,-15.43477"
    },
    {
        "Id": 1553,
        "Name": "Tinoca",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle la Naval, 10, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1487273,-15.4304394"
    },
    {
        "Id": 1572,
        "Name": "Eurostars Las Canteras Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sagasta, 28, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1437697,-15.4309322"
    },
    {
        "Id": 1577,
        "Name": "Hostal Alcavaneras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "22 Calle de Luis Antúnez, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.13066,-15.430524"
    },
    {
        "Id": 1580,
        "Name": "LPA Urban Center Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Valencia, 4, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1296576,-15.4321568"
    },
    {
        "Id": 1586,
        "Name": "Puerto Canteras Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Benecharo, 13, 15, 35009 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1503044,-15.4240509"
    },
    {
        "Id": 1592,
        "Name": "Hostal 7 Soles",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle la Naval, 71, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1493651,-15.4281446"
    },
    {
        "Id": 1598,
        "Name": "Hotel Madrid",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Plaza de Cairasco, 4, 35002 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.102991615191083,-15.41619747877121"
    },
    {
        "Id": 1602,
        "Name": "Sansofe Palace",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "35012 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.10319,-15.4410525"
    },
    {
        "Id": 1608,
        "Name": "Guesthouse Katanka",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Maestro Valle, 26, 35005 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1264918,-15.4328848"
    },
    {
        "Id": 1611,
        "Name": "Utopia Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Simancas, 54, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1303033,-15.4435017"
    },
    {
        "Id": 1623,
        "Name": "Bajamar Hotel Aptos.",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Venezuela, 34, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1349509,-15.4384543"
    },
    {
        "Id": 1626,
        "Name": "Hostal Kasa",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle del General Vives, 75, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1403765,-15.4315676"
    },
    {
        "Id": 1634,
        "Name": "Big Fish Hostel Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Sgto. Llagas, 34, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1405497,-15.435416"
    },
    {
        "Id": 1647,
        "Name": "Boji Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Luis de la Cruz, 10, 35003 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1088169,-15.4220583"
    },
    {
        "Id": 1665,
        "Name": "Pensión Falow",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Alfredo Calderón, 25, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1304643,-15.4312314"
    },
    {
        "Id": 1674,
        "Name": "Canary Beach Flats",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Pedro Castillo Westerling, 4, 35008 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.143447166216955,-15.430863700577143"
    },
    {
        "Id": 1681,
        "Name": "Alcaravaneras Hostal",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "22 Calle de Luis Antúnez, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.13066,-15.430524"
    },
    {
        "Id": 1688,
        "Name": "Castillo Playa Aptos. Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Pedro Castillo Westerling, 18",
        "Coordinates": "28.1435354798518,-15.431474963771604"
    },
    {
        "Id": 1690,
        "Name": "Muelle Nelson Mandela",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Nelson Mandela (Armas), España",
        "Coordinates": "28.1635385,-15.3999634"
    },
    {
        "Id": 1692,
        "Name": "Hostal Blue Wave",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Américo Vespucio, 44, 35009 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1493248,-15.4309359"
    },
    {
        "Id": 1709,
        "Name": "Muelle Puerto Santa Catalina",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Muelle de Santa Catalina, Las Palmas de Gran Canaria",
        "Coordinates": "28.141150265055494,-15.425895912022561"
    },
    {
        "Id": 1712,
        "Name": "Ecohostel Canarias Bettmar",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Pedro Díaz, 20, 35001 Vegueta, Las Palmas, Spain",
        "Coordinates": "28.098862,-15.417173"
    },
    {
        "Id": 1716,
        "Name": "Wavia Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Pajonales, 19, 35009 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1492319,-15.4319769"
    },
    {
        "Id": 1717,
        "Name": "Playa Dorada Las Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Luis Morote, 61, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1410946,-15.4350239"
    },
    {
        "Id": 1719,
        "Name": "Aptos Salvador Cuyas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Salvador Cuyas, 18, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1441766,-15.4313845"
    },
    {
        "Id": 1729,
        "Name": "Mannix Urban",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Veintinueve de Abril, 68, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1408544,-15.4324994"
    },
    {
        "Id": 1730,
        "Name": "La Fabrica Urban Hostel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Luis Antunez, 51, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1326202,-15.4306867"
    },
    {
        "Id": 1732,
        "Name": "Labranda Bex",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle León y Castillo, 330, 35007 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1389484,-15.4305138"
    },
    {
        "Id": 1733,
        "Name": "CC El Muelle",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Muelle de Sta. Catalina, s / n, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1417997,-15.4274003"
    },
    {
        "Id": 1735,
        "Name": "RK Canteras Suites Apt",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Prudencio Morales, 29, 35009 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1489398,-15.4311793"
    },
    {
        "Id": 1740,
        "Name": "Tusity Yeah Apartments",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Pedro del Castillo Westerling nº21, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1429966,-15.431515"
    },
    {
        "Id": 1743,
        "Name": "Pensión Plaza",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Fataga, 2, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1403,-15.43021"
    },
    {
        "Id": 1744,
        "Name": "Maresí­a Canteras Urban The Senses",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle León Tolstoi, 1, 35010 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1363447,-15.4368226"
    },
    {
        "Id": 1748,
        "Name": "Silken Saaj",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle León y Castillo, 329, 35006 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.131212,-15.4301493"
    },
    {
        "Id": 1750,
        "Name": "VEINTIUNO Emblematic Hotels",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Espíritu Santo, 21, 35001 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1004589,-15.4138253"
    },
    {
        "Id": 1752,
        "Name": "Sercotel Puerto de la Luz",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Juan Rejón, 42, 35008 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.14887,-15.426886"
    },
    {
        "Id": 1754,
        "Name": "Occidental Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle León y Castillo, 244, 35005 Las Palmas de Gran Canaria, Las Palmas, Spain",
        "Coordinates": "28.1211135,-15.4260508"
    },
    {
        "Id": 1757,
        "Name": "P. Bus Plaza Farray Fdo Guanarteme",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Plazoleta de Farray, 7, 35007 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.136904, -15.435550"
    },
    {
        "Id": 1787,
        "Name": "Plaza Las Ranas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Plaza Hurtado de Mendoza, 2, 35002 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.102238,-15.415424"
    },
    {
        "Id": 2319,
        "Name": "Muelle deportivo LPA",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Cl Darsena Deportiva, 1001, 35005, Las Palmas, España",
        "Coordinates": "28.12679,-15.42512"
    },
    {
        "Id": 2320,
        "Name": "BEX Holiday Home ",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle José Franchy Roca, 1, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1387922,-15.4308561"
    },
    {
        "Id": 2321,
        "Name": "RK Atlantis Canarias Aptos. ",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Lanzarote 5-7",
        "Coordinates": "28.145435124510797,-15.430274892596172"
    },
    {
        "Id": 2322,
        "Name": "27 Degrees Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle de Pi y Margall, 45, 35006 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1324287,-15.4328818"
    },
    {
        "Id": 2325,
        "Name": "Hotel Acuario Boutique",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle del General Vives, 82, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1407056,-15.431353"
    },
    {
        "Id": 2326,
        "Name": "Aptos. Miami (LPA)",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle del General Vives, 92, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.141225,-15.4314053"
    },
    {
        "Id": 2327,
        "Name": "Bed&Chic Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle del General Vives, 76, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.140186,-15.431387"
    },
    {
        "Id": 2328,
        "Name": "Bora Bora The Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Anzofé, 31, 35009 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.150049,-15.425783"
    },
    {
        "Id": 2329,
        "Name": "Hotel Concorde",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Tomás Miller, 85, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1414448,-15.4338659"
    },
    {
        "Id": 2330,
        "Name": "Catalina Park Aptos.",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Tomás Miller, 67, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1403671,-15.4338309"
    },
    {
        "Id": 2331,
        "Name": "LEMON&SOUL- Atlanta Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Alfredo L Jones, 37, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1419972,-15.4333227"
    },
    {
        "Id": 2332,
        "Name": "Olympia Hotel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Dr. Grau Bassas, 1, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1373977,-15.435611"
    },
    {
        "Id": 2333,
        "Name": "Valencia Hotel ",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Valencia, 64, 35006 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1335203,-15.4321417"
    },
    {
        "Id": 2408,
        "Name": "Columbus Hostels",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Reyes Católicos, 8, 35001 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.100117,-15.4141506"
    },
    {
        "Id": 2416,
        "Name": "RK City Center",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Valencia, 24, 35006 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1311998,-15.4321767"
    },
    {
        "Id": 2418,
        "Name": "Doña Luisa",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Alfredo L Jones, 29, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1420312,-15.4327735"
    },
    {
        "Id": 2432,
        "Name": "Don Carlos - Las Palmas GC",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Tenerife, 22, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1478058,-15.4304364"
    },
    {
        "Id": 2452,
        "Name": "Hotel Matilde",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Juan Rejón, 73, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1493121,-15.4255376"
    },
    {
        "Id": 2459,
        "Name": "Boutique Hotel Cordial Malteses",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "CALLE DR. RAFAEL GONZÁLEZ, 3 LAS PALMAS",
        "Coordinates": "28.103681764523092,-15.416456491295657"
    },
    {
        "Id": 2468,
        "Name": "Hotel THe Lumm",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Simón Bolívar, 105, 35007 Las Palmas de Gran Canaria, Las Palmas",
        "Coordinates": "28.139464659681668,-15.429948502282075"
    },
    {
        "Id": 2477,
        "Name": "Boutique Hotel Cordial La Peregrina",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Peregrina, 2, 35002 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.102797556684344,-15.415465807491646"
    },
    {
        "Id": 2497,
        "Name": "Cactus Host",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Dr. Miguel Rosas, 19, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.140681713803087,-15.43308837850418"
    },
    {
        "Id": 2511,
        "Name": "ART Las Palmas",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. el Salvador, 79, 35010 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.129412356069988,-15.441887735088956"
    },
    {
        "Id": 2514,
        "Name": "Muelle Sanapú",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Av. de Los Consignatarios, 10, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.143908633003775,-15.428721131005162"
    },
    {
        "Id": 2523,
        "Name": "Catalina Plaza Hotel Sostenible",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Luis Morote, 16, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1414098777246,-15.431522127400967"
    },
    {
        "Id": 2528,
        "Name": "Hotel Ciudad del Mar",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. la Naval, 38, 35008 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.14905771321966,-15.42906340998762"
    },
    {
        "Id": 2533,
        "Name": "La Ventana Azul Surf Hostel",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle El Salvador, 7, C. Numancia, 19, 35010 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.131303438455028,-15.443898181540673"
    },
    {
        "Id": 2540,
        "Name": "RK Farallon Canteras",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "C. Nicolás Estévanez, 38, 35007 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.13939968798229,-15.43402258183819"
    },
    {
        "Id": 2575,
        "Name": "Suites 1478",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Calle Dr. Chil, 22, 35001 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.099942581132588,-15.415098658352495"
    },
    {
        "Id": 2633,
        "Name": "Boutique Hotel Cordial Plaza Mayor de Santa Ana",
        "Island": "GC",
        "ZoneId": 113,
        "ZoneName": "Las Palmas",
        "Address": "Plaza Sta. Ana, 5, 35001 Las Palmas de Gran Canaria, Las Palmas, España",
        "Coordinates": "28.1001994,-15.4156709"
    },
    {
        "Id": 1133,
        "Name": "Anfi Del Mar",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Barranco de la Verga, s/n, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7725051,-15.6983441"
    },
    {
        "Id": 1232,
        "Name": "Bull Dorado Beach",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 1, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7624,-15.6843"
    },
    {
        "Id": 1264,
        "Name": "Servatur Green Beach",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 23, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.76805,-15.68826"
    },
    {
        "Id": 1335,
        "Name": "Mariposa del Sol",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 9, 35129 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7714357,-15.6863684"
    },
    {
        "Id": 1346,
        "Name": "Monte Anfi Club",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "35129 Patalavaca, Las Palmas, Spain",
        "Coordinates": "27.7732438,-15.6974033"
    },
    {
        "Id": 1389,
        "Name": "Radisson BLU Resort Arguineguin",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Diseminado Bco.Arguineguín, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.772089,-15.6910453"
    },
    {
        "Id": 1430,
        "Name": "Special Lodges Villa Grancanaria",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle Swing Los Lagos Nr 9, Urbanización Salobre Golf, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.777308,-15.641807"
    },
    {
        "Id": 1515,
        "Name": "Club Puerto Atlantico",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 3, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7630556,-15.6847222"
    },
    {
        "Id": 1540,
        "Name": "Marinero 1",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle Alonso Quesada, 1, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7580926,-15.6811208"
    },
    {
        "Id": 1547,
        "Name": "Palmera",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 14, Patalavaca",
        "Coordinates": "27.770251400666993,-15.686574876308441"
    },
    {
        "Id": 1551,
        "Name": "Sunwing Resort",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 21, 35129 Patalavaca, Las Palmas, Spain",
        "Coordinates": "27.7640972,-15.6864767"
    },
    {
        "Id": 1555,
        "Name": "Villa del Mar",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 3, 35129 CP, Las Palmas, Spain",
        "Coordinates": "27.7724311,-15.6858643"
    },
    {
        "Id": 1569,
        "Name": "Montemarina y Aquamarina Aptos.",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Avenida de la Verga, 14, 35129 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7727288,-15.6917884"
    },
    {
        "Id": 1571,
        "Name": "Granada I",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 12, 35129 Mogán, Las Palmas, Spain",
        "Coordinates": "27.770638,-15.686685"
    },
    {
        "Id": 1576,
        "Name": "Mayfair",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 27, 35129 Los Canarios, Las Palmas, Spain",
        "Coordinates": "27.7716264,-15.6868132"
    },
    {
        "Id": 1635,
        "Name": "Spar Arguineguin",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Ctra. General, 46, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7596698,-15.6805873"
    },
    {
        "Id": 1640,
        "Name": "Club Puerto Anfi",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "35129 Barranco de la Verga, Las Palmas, Spain",
        "Coordinates": "27.7725955,-15.6977205"
    },
    {
        "Id": 1648,
        "Name": "Anfi Beach",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Barranco de la Verga, 35129 Las Palmas",
        "Coordinates": "27.7746964637754,-15.694420337677002"
    },
    {
        "Id": 1661,
        "Name": "Valle Marina Apartamentos",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle Bjorn Lyng, 35140 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7772884,-15.692319"
    },
    {
        "Id": 1664,
        "Name": "Gran Anfi",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle Anfi del Mar, 33A, 35129 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7720577,-15.7000419"
    },
    {
        "Id": 1673,
        "Name": "Vista Canaria Arguineguin",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle la Lajilla, nº19, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7605615,-15.6837034"
    },
    {
        "Id": 1694,
        "Name": "Rio Canario Aptos.",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 2, 35129 Mogán, Las Palmas, Spain",
        "Coordinates": "27.7706673,-15.6871191"
    },
    {
        "Id": 1725,
        "Name": "Monte Carrera",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle Tahona, 2, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7666982,-15.6836664"
    },
    {
        "Id": 1728,
        "Name": "Apartamentos Litotoleo",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "TF-28, 67, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7579273,-15.6788629"
    },
    {
        "Id": 1731,
        "Name": "Apartamentos Panorama",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Guaires, 49, 35129 Mogán, Las Palmas, España",
        "Coordinates": "27.769140010201376,-15.686280978766437"
    },
    {
        "Id": 1745,
        "Name": "Vista Puerto Apartaments",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Calle la Lajilla, nº19, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7605615,-15.6837034"
    },
    {
        "Id": 1751,
        "Name": "Arguineguin Park by Servatur",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. Aly, 55, 35120 Arguineguin, Las Palmas, Spain",
        "Coordinates": "27.7681351,-15.6848866"
    },
    {
        "Id": 2334,
        "Name": "Doñana",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 25, 35129 Patalavaca, Las Palmas, España",
        "Coordinates": "27.7699454,-15.6880642"
    },
    {
        "Id": 2335,
        "Name": "Don Paco",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Av. los Canarios, 27, 35129 Patalavaca, Las Palmas, España",
        "Coordinates": "27.7711709,-15.6890995"
    },
    {
        "Id": 2433,
        "Name": "Cordial Santa Agueda",
        "Island": "GC",
        "ZoneId": 114,
        "ZoneName": "Arguineguin",
        "Address": "Q83F+WV Santa Águeda, España",
        "Coordinates": "27.754997575817303,-15.674869684798482"
    },
    {
        "Id": 1272,
        "Name": "Elba Vecindario Aeropuerto",
        "Island": "GC",
        "ZoneId": 116,
        "ZoneName": "Aguimes-Vecindario",
        "Address": "Av. del Atlántico, 353, 35110 Vecindario, Las Palmas, Spain",
        "Coordinates": "27.8407476,-15.4409739"
    },
    {
        "Id": 1238,
        "Name": "Dunas Mirador Maspalomas",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Einstein, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.76459984833741,-15.600636794558405"
    },
    {
        "Id": 1244,
        "Name": "Venus Resort",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Einstein, nr. 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7564481,-15.6011648"
    },
    {
        "Id": 1362,
        "Name": "Palm Oasis Maspalomas",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Urb. Sonnenland C/Albert Einstein s/n Parc 34, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7652387,-15.599575"
    },
    {
        "Id": 1462,
        "Name": "Vista Bonita Gay Resort",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Carmen Laforet, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7604494,-15.6011471"
    },
    {
        "Id": 1463,
        "Name": "Vista Dorada ",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Einstein, 7-9, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7611111,-15.6005556"
    },
    {
        "Id": 1465,
        "Name": "Vista Oasis",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Tindaya Incoming S.L., Calle Einstein, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7586521,-15.6008447"
    },
    {
        "Id": 1470,
        "Name": "Ataitana Faro Aptos.",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Einstein, 5, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7578098,-15.601648"
    },
    {
        "Id": 1507,
        "Name": "Sonemar Bungalows",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Agustín Millares Carlo, 170, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7591996,-15.6037445"
    },
    {
        "Id": 1511,
        "Name": "Vicksol Bungalows",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Juan Bosch Millares, 25, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7598229,-15.6016409"
    },
    {
        "Id": 1597,
        "Name": "Los Robles Verdes",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Bungalows Los Robles Verdes, Calle Tomás Morales, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7569294,-15.6046333"
    },
    {
        "Id": 1660,
        "Name": "Surycan",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Surycan, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7625333,-15.6006297"
    },
    {
        "Id": 1667,
        "Name": "Sonneland Club II",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Agustín Millares Carlo, 170, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7608303,-15.6031146"
    },
    {
        "Id": 1707,
        "Name": "Sonneland Club I",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Juan Bosch Millares, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7598055,-15.6029027"
    },
    {
        "Id": 1738,
        "Name": "Venesol",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Venesol, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7548769,-15.6029395"
    },
    {
        "Id": 1749,
        "Name": "Villa Lovisi Sonneland",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Severo Ochoa, 7, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7555916,-15.6050483"
    },
    {
        "Id": 2510,
        "Name": "Vista Faro Bung.",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "Calle Dr. Agustín Millares Carlo, 5, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.759017354636615,-15.603530742068973"
    },
    {
        "Id": 2561,
        "Name": "Vista Mar",
        "Island": "GC",
        "ZoneId": 117,
        "ZoneName": "Sonneland",
        "Address": "C. Einstein, 3, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7575282,-15.6016053"
    },
    {
        "Id": 1120,
        "Name": "Salobre Hotel Resort & Serenity",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Calle Swing, s/n, 35128, Las Palmas, Spain",
        "Coordinates": "27.7821872,-15.650231"
    },
    {
        "Id": 1412,
        "Name": "Salobre Golf & Resort",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Urbanización Salobre Golf, GC 1 km 53, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7812921,-15.6466811"
    },
    {
        "Id": 1425,
        "Name": "Salobre Hotel Resort & Serenity",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Calle Swing, s/n, 35128, Las Palmas, Spain",
        "Coordinates": "27.7821872,-15.650231"
    },
    {
        "Id": 1461,
        "Name": "Villas Salobre Golf & Resort",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Calle Swing Hills, 4A, 35290 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7787119,-15.6436889"
    },
    {
        "Id": 1693,
        "Name": "Rotonda Pasito Blanco",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Rotonda Barranco Maspalomas, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7658645,-15.596016"
    },
    {
        "Id": 2627,
        "Name": "Salobre Golf (Campo Golf)",
        "Island": "GC",
        "ZoneId": 120,
        "ZoneName": "Salobre",
        "Address": "Q9J4+M4 El Salobre, España",
        "Coordinates": "27.7817066,-15.6446644"
    },
    {
        "Id": 1121,
        "Name": "Las Brisas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7602058,-15.5668276"
    },
    {
        "Id": 1122,
        "Name": "Las Gondolas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7565142,-15.5696675"
    },
    {
        "Id": 1123,
        "Name": "Monterrey Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 11, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7569071,-15.5709244"
    },
    {
        "Id": 1124,
        "Name": "Sahara Playa I",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 1, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7557764,-15.5688791"
    },
    {
        "Id": 1126,
        "Name": "Tagoror",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 9, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.75700393583413,-15.570231950005066"
    },
    {
        "Id": 1132,
        "Name": "Las Isas apartamentos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 13, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7572352,-15.5712026"
    },
    {
        "Id": 1153,
        "Name": "Agaete Parque",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7639103,-15.5665021"
    },
    {
        "Id": 1154,
        "Name": "Alegranza II",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 7, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.763128,-15.562724"
    },
    {
        "Id": 1166,
        "Name": "HL Suitehotel Playa Del Ingles",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 3-5, 35100 Las Palmas, Spain",
        "Coordinates": "27.7592988,-15.5672414"
    },
    {
        "Id": 1167,
        "Name": "Montemar Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle San Cristóbal de la Laguna, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7602778,-15.5652778"
    },
    {
        "Id": 1172,
        "Name": "Atindana",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 13, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7609551,-15.5717555"
    },
    {
        "Id": 1174,
        "Name": "Axelbeach Maspalomas Apartments & Lounge Club",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avda. Tirajana, 32 (Entrance by, Calle Timple, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7610919,-15.5786368"
    },
    {
        "Id": 1183,
        "Name": "Betancuria",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Menceys, 7, 35290 Maspalomas, Las Palmas",
        "Coordinates": "27.759013338435718,-15.572650730609894"
    },
    {
        "Id": 1189,
        "Name": "Bronze Playa Hotel",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle San Cristóbal de la Laguna, 7, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7616821,-15.5657191"
    },
    {
        "Id": 1192,
        "Name": "HL Miraflor Suite",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Timple, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7618053,-15.578059"
    },
    {
        "Id": 1196,
        "Name": "Calma Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7632232,-15.562503"
    },
    {
        "Id": 1201,
        "Name": "Caroni",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7581571,-15.5698358"
    },
    {
        "Id": 1203,
        "Name": "Casas Carmen",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 31, 35100 info@casascarmen, Las Palmas, Spain",
        "Coordinates": "27.7597135,-15.5654726"
    },
    {
        "Id": 1205,
        "Name": "Caserio Hotel",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 8, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7633514,-15.5635357"
    },
    {
        "Id": 1221,
        "Name": "Corona Blanca",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 11, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.761265,-15.568439"
    },
    {
        "Id": 1222,
        "Name": "Corona Playa",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avenida De Madrid, 17",
        "Coordinates": "27.76074557786297,-15.564509949252429"
    },
    {
        "Id": 1223,
        "Name": "Corona Roja Aptos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle el Pez, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7650356,-15.5600765"
    },
    {
        "Id": 1229,
        "Name": "Don Diego Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Menceyes, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7582184,-15.5705581"
    },
    {
        "Id": 1233,
        "Name": "Dorotea",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7586079,-15.5707064"
    },
    {
        "Id": 1248,
        "Name": "Bull Escorial",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avenida Italia, 6, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7639658,-15.56299"
    },
    {
        "Id": 1249,
        "Name": "Bull Eugenia Victoria",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 26, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7644504,-15.573987"
    },
    {
        "Id": 1253,
        "Name": "Faisan Aptos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.757815,-15.5696168"
    },
    {
        "Id": 1263,
        "Name": "Gran Canaria Princess",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 18, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7630943,-15.5706316"
    },
    {
        "Id": 1265,
        "Name": "Green Field",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.763568,-15.565784"
    },
    {
        "Id": 1269,
        "Name": "Guinea",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Helsinki, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7580932,-15.5682207"
    },
    {
        "Id": 1275,
        "Name": "Abora Buenaventura",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Gánigo, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7638969,-15.5683021"
    },
    {
        "Id": 1277,
        "Name": "Abora Continental",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 2, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7645196,-15.5610332"
    },
    {
        "Id": 1278,
        "Name": "Corallium Dunamar",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Helsinki, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7569444,-15.5683333"
    },
    {
        "Id": 1282,
        "Name": "Jardin Del Atlantico",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle San Cristóbal de la Laguna, 9, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7621777,-15.5659322"
    },
    {
        "Id": 1283,
        "Name": "Jardin del Sol",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 24, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7633304,-15.5733709"
    },
    {
        "Id": 1286,
        "Name": "Karina",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7543169,-15.5803701"
    },
    {
        "Id": 1289,
        "Name": "Koka",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 17, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7597222,-15.5711111"
    },
    {
        "Id": 1292,
        "Name": "Las Algas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 9, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7625337,-15.5631366"
    },
    {
        "Id": 1294,
        "Name": "Las Arenas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 23, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7587005,-15.5681947"
    },
    {
        "Id": 1296,
        "Name": "Las Dunas Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Marruecos, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7576292,-15.5686422"
    },
    {
        "Id": 1297,
        "Name": "Las Faluas Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 9, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7620343,-15.5677949"
    },
    {
        "Id": 1299,
        "Name": "Jacaranda Beach",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 8, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7584581,-15.569984"
    },
    {
        "Id": 1300,
        "Name": "Las Nasas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7622437,-15.5624671"
    },
    {
        "Id": 1301,
        "Name": "Alsol Las Orquideas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 28, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.759796598975502,-15.566017627716064"
    },
    {
        "Id": 1302,
        "Name": "Las Tartanas III",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 21, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7600145,-15.5729338"
    },
    {
        "Id": 1306,
        "Name": "Liberty - Axelbeach",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avda. Tirajana, 32 (Entrance by, Calle Timple, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7610919,-15.5786368"
    },
    {
        "Id": 1312,
        "Name": "Los Arpones",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7624633,-15.5620443"
    },
    {
        "Id": 1313,
        "Name": "Los Cactus",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 19, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7606197,-15.5658956"
    },
    {
        "Id": 1314,
        "Name": "Alsol Los Caribes II",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7591249,-15.5663758"
    },
    {
        "Id": 1315,
        "Name": "Los Ficus",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 17, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7608745,-15.5654878"
    },
    {
        "Id": 1316,
        "Name": "Los Girasoles Bungalows",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Sargentos Provisionales, 25, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7609537,-15.5760303"
    },
    {
        "Id": 1318,
        "Name": "Los Juncos 1",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Marruecos, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7582738,-15.5694437"
    },
    {
        "Id": 1319,
        "Name": "Los Nardos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Plaza de Miramar, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7588151,-15.5670621"
    },
    {
        "Id": 1336,
        "Name": "Maritim Playa Aptos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 10, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7606781,-15.5689433"
    },
    {
        "Id": 1348,
        "Name": "Montemayor",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 15, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.76142481345646,-15.563469529151917"
    },
    {
        "Id": 1372,
        "Name": "Parque Paraiso I",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avenida de Tirma, 16, Plaza Ansite, 7, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7609646,-15.571493"
    },
    {
        "Id": 1373,
        "Name": "Parque Paraiso II",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Pl. Ansite, 7, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.763066,-15.568679"
    },
    {
        "Id": 1377,
        "Name": "Parque Tropical",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Avda Italia 1, San Bartolomé de Tirajana",
        "Coordinates": "27.764073576811047,-15.561221837997437"
    },
    {
        "Id": 1378,
        "Name": "Parquemar",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 28, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7633333,-15.5752778"
    },
    {
        "Id": 1381,
        "Name": "Labranda Playa Bonita",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 2, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7637891,-15.5641765"
    },
    {
        "Id": 1396,
        "Name": "Riu Papayas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 22, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7644174,-15.572196"
    },
    {
        "Id": 1402,
        "Name": "Riu Papayas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 22, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7644174,-15.572196"
    },
    {
        "Id": 1404,
        "Name": "Servatur Waikiki",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 20, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7632666,-15.5714357"
    },
    {
        "Id": 1405,
        "Name": "Roca Verde Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 24, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7610198,-15.5648925"
    },
    {
        "Id": 1408,
        "Name": "Rondo Aparthotel ",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirajana, 34, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7617896,-15.57869"
    },
    {
        "Id": 1416,
        "Name": "Santa Clara",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Sargentos Provisionales, 30, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7619444,-15.5747222"
    },
    {
        "Id": 1419,
        "Name": "Seaside Sandy Beach",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Menceyes, s/n, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7573601,-15.5703942"
    },
    {
        "Id": 1431,
        "Name": "Strelitzias",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 25, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.758466,-15.56894"
    },
    {
        "Id": 1441,
        "Name": "Tamaragua Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Menceyes, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7583002,-15.5716017"
    },
    {
        "Id": 1446,
        "Name": "Tenesoya",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 18, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7614809,-15.5690066"
    },
    {
        "Id": 1447,
        "Name": "Teror",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 22, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.759963337802244,-15.576208680868149"
    },
    {
        "Id": 1449,
        "Name": "Terrazamar Aptos. Playa del Ingles",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Helsinki, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7573163,-15.5678642"
    },
    {
        "Id": 1453,
        "Name": "Tivoli Aptos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 6, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7625923,-15.5625199"
    },
    {
        "Id": 1456,
        "Name": "Labranda Marieta",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 11, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.76220403397959,-15.564312585319513"
    },
    {
        "Id": 1475,
        "Name": "Carina Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 32, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7593432,-15.5661432"
    },
    {
        "Id": 1478,
        "Name": "Danubio Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle el Escorial, 3, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7643473,-15.5623884"
    },
    {
        "Id": 1480,
        "Name": "Doncel Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "11,, Av. de Menceyes, 9, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7593507,-15.5735905"
    },
    {
        "Id": 1517,
        "Name": "Corona Verde",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 12, 35100 Gran Canaria, Las Palmas, Spain",
        "Coordinates": "27.7590002,-15.5704622"
    },
    {
        "Id": 1522,
        "Name": "Green Garden Apartments",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 4, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7637035,-15.5651155"
    },
    {
        "Id": 1529,
        "Name": "Marieta Hotel",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 11, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.76220403397959,-15.564312585319513"
    },
    {
        "Id": 1537,
        "Name": "Los Corales",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 45, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7583707,-15.5670909"
    },
    {
        "Id": 1559,
        "Name": "Broncemar Aptos - Bronce Playa",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "C. San Cristóbal de la Laguna, 7, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.761537156448913,-15.565377453809479"
    },
    {
        "Id": 1563,
        "Name": "Vistalmar Bungalows",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Hamburgo, 7, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7654223,-15.5546367"
    },
    {
        "Id": 1565,
        "Name": "Miraflor Suites",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Timple, 4, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7618053,-15.578059"
    },
    {
        "Id": 1570,
        "Name": "Birdcage Resort",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Egipto, 10, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.761282,-15.57445"
    },
    {
        "Id": 1578,
        "Name": "Caserio Bungalows",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 8, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7633514,-15.5635357"
    },
    {
        "Id": 1579,
        "Name": "Hostal Casa de Huespedes S. Fdo",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle la Palma, 16, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7652778,-15.5783333"
    },
    {
        "Id": 1587,
        "Name": "Isabel Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Helsinki, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757497,-15.5677257"
    },
    {
        "Id": 1590,
        "Name": "Artemisa",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Timple, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7604292,-15.5777361"
    },
    {
        "Id": 1601,
        "Name": "Reinasol",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 3, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7627307,-15.5616891"
    },
    {
        "Id": 1605,
        "Name": "Tropical La Zona",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Sargentos Provisionales, 18, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.761646,-15.5708182"
    },
    {
        "Id": 1607,
        "Name": "Los Elefantes",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 2, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.757351,-15.5698041"
    },
    {
        "Id": 1612,
        "Name": "Casa Jumilla",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 27, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.759987543857626,-15.564948565854847"
    },
    {
        "Id": 1617,
        "Name": "Los Juncos 2 Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Marruecos, 9",
        "Coordinates": "27.758731186662732,-15.569499734114967"
    },
    {
        "Id": 1619,
        "Name": "Caserio Azul Aptos - Apartamentos Calma",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, 5, 35100 San Bartolomé de Tirajana, Las Palmas, Spain",
        "Coordinates": "27.7632232,-15.562503"
    },
    {
        "Id": 1646,
        "Name": "Felicidad Bungalow",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 16, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7615581,-15.5687001"
    },
    {
        "Id": 1739,
        "Name": "Silvi Villas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Arquitecto Manuel de la Peña Suarez, 14, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.76132204309564,-15.570326411745976"
    },
    {
        "Id": 1747,
        "Name": "FARILAGA",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tirma, 7, 35100 Maspalomas, Las Palmas, Spain",
        "Coordinates": "27.7589259,-15.5710733"
    },
    {
        "Id": 2341,
        "Name": "Africana",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 20, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.76105710628804,-15.564208248939856"
    },
    {
        "Id": 2342,
        "Name": "Amazonas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Marruecos, 4, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7580761,-15.568679"
    },
    {
        "Id": 2344,
        "Name": "Atlantic Beach Club",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "P.º Costa Canaria, s/n, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7577199,-15.5669466"
    },
    {
        "Id": 2347,
        "Name": "Chaparral Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "35100 las, Av. de Madrid, 22, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7610611,-15.5646"
    },
    {
        "Id": 2350,
        "Name": "Green Park",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Tenerife, 4, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7637035,-15.5651155"
    },
    {
        "Id": 2354,
        "Name": "Las Tejas",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 39, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7587765,-15.5661684"
    },
    {
        "Id": 2356,
        "Name": "Los Caribes",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 29, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7598801,-15.56527"
    },
    {
        "Id": 2358,
        "Name": "Allsun Lucana",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle el Pez, 4, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.764246,-15.5601384"
    },
    {
        "Id": 2359,
        "Name": "Miramar Apartamentos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 11, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7618743,-15.5628533"
    },
    {
        "Id": 2363,
        "Name": "Rebecca Park",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Calle Noruega, 116, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7619279,-15.5758761"
    },
    {
        "Id": 2364,
        "Name": "San Nicolas Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 35, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7592106,-15.5658935"
    },
    {
        "Id": 2365,
        "Name": "San Valentin & Terraflor Park",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Sargentos Provisionales, 26, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.761171948966656,-15.57611791962015"
    },
    {
        "Id": 2367,
        "Name": "Santa Fe",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 33, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7628169,-15.5714926"
    },
    {
        "Id": 2434,
        "Name": "Apartamentos Los Angeles",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 13, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7616623,-15.5632478"
    },
    {
        "Id": 2444,
        "Name": "Los Molinos",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 36, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7627719,-15.5783468"
    },
    {
        "Id": 2448,
        "Name": "Apartamentos Tirajana",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Alfereces Provisionales, 20, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.7598643,-15.575481"
    },
    {
        "Id": 2458,
        "Name": "LAS PERLAS",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": " Av. de Madrid, 30, 35100 San Bartolomé de Tirajana, Las Palmas",
        "Coordinates": "27.759498015797767,-15.566023617056427"
    },
    {
        "Id": 2499,
        "Name": "Las Olas ",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Italia, n 20, 35100 Maspalomas, Las Palmas",
        "Coordinates": "27.760530671334497,-15.566484792593766"
    },
    {
        "Id": 2503,
        "Name": "Edificio Habitat",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Gran Canaria, 32, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7631154,-15.5776196"
    },
    {
        "Id": 2517,
        "Name": "Apartamento La Concha",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 40, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7587835925696,-15.566553902565971"
    },
    {
        "Id": 2525,
        "Name": "Apartamentos Casablanca (Pl. Inglés)",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 37, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.7589911,-15.5659994"
    },
    {
        "Id": 2539,
        "Name": "Marivista Aptos.",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. de Madrid, 12, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.761859272706356,-15.563243906232668"
    },
    {
        "Id": 2567,
        "Name": "Apartamentos Aida",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "C. Helsinki, 1, 35100 San Bartolomé de Tirajana, Las Palmas, España",
        "Coordinates": "27.75836280390452,-15.56772805527687"
    },
    {
        "Id": 2570,
        "Name": "Sanom Beach",
        "Island": "GC",
        "ZoneId": 121,
        "ZoneName": "Playa del Inglés 2",
        "Address": "Av. Arquitecto Manuel de la Peña Suarez, 38, 35100 Maspalomas, Las Palmas, España",
        "Coordinates": "27.760527617662166,-15.576462199403172"
    },
    {
        "Id": 2628,
        "Name": "Real Club de Golf de Las Palmas (Campo Golf)",
        "Island": "GC",
        "ZoneId": 127,
        "ZoneName": "Santa Brigida",
        "Address": "Vial Camino, 12, 35319 El Raso, Las Palmas, España",
        "Coordinates": "28.0315888,-15.4598743"
    }
];