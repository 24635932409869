import { AuthFirebaseService	} from './demo/service/database/authfirebase.service';
import { CommonsService 		} from './demo/service/commons.service';
import { EntityService 			} from './demo/service/entity.service';
import { OnInit 				} from '@angular/core';
import { Component 				} from '@angular/core';
import { TranslateService   	} from '@ngx-translate/core';
import { StorageService     	} from './demo/service/storageservice';
import { Router             	} from '@angular/router';
import { FirebaseService 		} from './demo/service/database/firebase.service';
import { firebaseForm 			} from './config/app.form';
import { CompanyService 		} from './demo/service/database/company.service';
import { GoogleService 			} from './demo/service/google/google.service';
import { MapService 			} from './demo/service/mapservice';
import { pipe 					} from 'rxjs';
import { map 					} from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { TransportService } from './demo/service/transports/transports.service';

@Component({
    selector	: 'app-root',
    templateUrl	: './app.component.html'
})
export class AppComponent implements OnInit {

    public menuMode 		= 'horizontal';
    public menuActive 		= false;
    public topbarMenuActive = false;
    activeTopbarItem		: Element;
    menuClick				: boolean;
    menuButtonClick			: boolean;
    topbarMenuButtonClick	: boolean;
    resetMenu				: boolean;
    menuHoverActive			: boolean;
    pageInfo				: any = {
		dataLoaded	:	false,
		userLogged	:	false,
        header: {
            mainTitle   : 'Tourinia',
			mainSubtitle: 'DMC',
			mainLogo	: 'assets/layout/images/tourinia_logo.png'
        },
        i18n    : {
            loaded     	: false,
            items    	: []
        },
		entities		:	{ 'firebase' : {} }
    };

	public userInfo: any = {}

    constructor(
		private mapService 				: MapService,
        private commons					: CommonsService,
        private entityService			: EntityService,
        private translate       		: TranslateService,
        private storage         		: StorageService,
        private router          		: Router,
		private authFirebaseService    	: AuthFirebaseService,
		private firebaseService			: FirebaseService,
		private companyService			: CompanyService,
		private transportService		: TransportService
		// private googleCtrl 			: GoogleService
	){
		this.authFirebaseService.subscribeAuthState().subscribe(async authState => { this.loginWatchdog(authState); });
		this.pageInfo.version = this.commons.pageInfo.version;
	}

	/***
	 * Login watchdog
	 * Raised each time login action is performe
	 * @param $state
	 */
	 async loginWatchdog($state){
		console.group();
		console.log("AUTHSTATE",$state);

		// if($state==undefined || $state==null){
		// 	this.authFirebaseService.logOut();
		// 	this.router.navigate(['/']);
		// 	return false;
		// }

		if($state==null){
			this.authFirebaseService.logOut();
			if(this.router.url!=this.commons.pageInfo.defaultNotLoggedPage){
				this.router.navigate([ this.commons.pageInfo.defaultNotLoggedPage ]);
			}
		}

		if($state && $state!=null && $state.emailVerified){		// Logged and email verified

			await this.commons.generateTranslates();
			this.loadGlobalEntities();

			await this.mountUser($state);

			this.pageInfo.entityType		= this.commons.getEntityProfile(this.commons.userInfo.profile);
			this.pageInfo.dataLoaded 		= true;

			// this.commons.pageInfo.storage	= this.storage.getItem('dmcSuite');

			this.loadUserEntities();

			if(!this.commons.userInfo.currentDestination){
				this.router.navigate([this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].loggedWithSetupNotDone]);
			} else {
				this.router.navigate([this.commons.userInfo.currentDestination
					?this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].loggedWithSetupDone
					:this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].loggedWithSetupNotDone
				]);
			}

			let type = this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type];
			console.log("Type",type);
			console.groupEnd();

			// this.storage.setItem('dmcSuite', this.commons.pageInfo.storage );

			this.router.navigate([this.commons.userInfo.currentDestination
									?type.loggedWithSetupDone
									:type.loggedWithSetupNotDone]);

		} else {												// Not logged or email not verified

			this.commons.userInfo		=	{}
			this.pageInfo.dataLoaded 	= 	true;

			console.groupEnd();

			if(!this.pageInfo.entityType)	{
				this.router.navigate([this.commons.pageInfo.defaultNotLoggedPage]);
			}
			else							{
				let page = this.commons.pageInfo.pages[this.pageInfo.entityType.profile][this.pageInfo.entityType.type].notLoggedPage;
				if(this.router.url!=page){ this.router.navigate([ page ]); }
			}
		}
	}

    async ngOnInit() {
		await this.initApp();
		this.initForms();
	}

	async ngOnDestroy()	{
		// unsubscribe all
	}

	initForms()	{
		const entities = {
			firebaseOperationsList	: [ "_FIREBASE_OPERATION_ADD",
										"_FIREBASE_OPERATION_SET",
										"_FIREBASE_OPERATION_UPDATE",
										"_FIREBASE_OPERATION_DELETE"
									]
		};

		Object.keys(entities).forEach(async item=>{
			let entry = entities[item].map(i=>{
				return { name: i, label: this.commons.getTranslate(i), value: i };
			});
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entry);
			console.log("Entity",item,"values",this.pageInfo.entities[item]);
		})

		console.log("INITFORM ENTITIES",this.pageInfo.entities);

		this.pageInfo.entities["firebaseOperationsList"] = [
			{ name: '_FIREBASE_OPERATION_ADD', 		label: '_FIREBASE_OPERATION_ADD', 		value: '_FIREBASE_OPERATION_ADD'	},
			{ name: '_FIREBASE_OPERATION_SET', 		label: '_FIREBASE_OPERATION_SET', 		value: '_FIREBASE_OPERATION_SET'	},
			{ name: '_FIREBASE_OPERATION_UPDATE', 	label: '_FIREBASE_OPERATION_UPDATE', 	value: '_FIREBASE_OPERATION_UPDATE'	},
			{ name: '_FIREBASE_OPERATION_DELETE', 	label: '_FIREBASE_OPERATION_DELETE', 	value: '_FIREBASE_OPERATION_DELETE'	}
		];

		this.pageInfo.entities["firebase"].form		= {
			ready		: true,
			entities	: this.pageInfo.entities,
			commons		: [],
			options		: { height: '70vh', showButtons: false	},
			buttons		: [
				{ name: 'execute', 		label: '_EXECUTE'	},
				{ name: 'cancel', 		label: '_CANCEL'	}
			],
			form		: firebaseForm
		};
	}

	async mountUser($info):Promise<void> {

		let dmcSuite	= this.storage.getItem('dmcSuite') || { userInfo: {} };
		let response 	= await Promise.resolve(this.firebaseService.getuserInfo($info.uid));

		if(response.success){
			this.commons.userInfo						= response;
			this.commons.userInfo.isLogged 				= true;
			this.commons.userInfo.complete_name			= response.first_name+" "+response.last_name;
			this.commons.userInfo.avatar				= this.commons.userInfo.avatar || $info.photoUrl;
			this.commons.userInfo.roles    		 		= await this.firebaseService.getRolesFromProfile(this.commons.userInfo.profile);

			if(!this.commons.userInfo.currentDmc){
				this.commons.userInfo.currentDmc 			= response.currentDmc 			|| dmcSuite.userInfo.currentDmc;
			}
			if(!this.commons.userInfo.currentDestination){
				this.commons.userInfo.currentDestination 	= response.currentDestination 	|| dmcSuite.userInfo.currentDestination;
			}
			if(!this.commons.userInfo.currentStation){
				this.commons.userInfo.currentStation 		= response.currentStation 		|| dmcSuite.userInfo.currentStation;
			}
			if(!this.commons.userInfo.currentCountry){
				this.commons.userInfo.currentCountry 		= response.currentCountry 		|| dmcSuite.userInfo.currentCountry;
			}

		} else {
			this.commons.userInfo.isLogged			= false;
		}

		this.storage.setItem('dmcSuite',dmcSuite);
		console.log("USERINFO",this.commons.userInfo);

		return this.loadDestination();
	}

	async load($entity)					{
		switch($entity){
			case 'destination'		:	await this.loadDestination(); break;
		}
	}

	async loadDestination(){
		// Dmcs
		this.commons.pageInfo.entities['dmcs'			]	= 	await this.firebaseService.getDmcs();
		if(undefined==this.commons.userInfo.currentDmc){
			this.commons.userInfo.currentDmc				= 	this.commons.pageInfo.entities["dmcs"][0];
		}
		// Countries
		this.commons.pageInfo.entities['countries'	]		= 	await Promise.resolve(this.entityService.getRequest('countries',{ dmc: this.commons.userInfo.currentDmc }));
		if(undefined==this.commons.userInfo.currentCountry){
			this.commons.userInfo.currentCountry			= 	this.commons.pageInfo.entities["countries"][0];
		}
		// Destinations
		this.commons.pageInfo.entities['destinations']		= 	await this.companyService.getDestinationsFromDmc(this.commons.userInfo.currentDmc.id);
		if(undefined==this.commons.userInfo.currentDestination){
			this.commons.userInfo.currentDestination		= 	this.commons.pageInfo.entities["destinations"][0];
		}
		// Stations
		this.commons.pageInfo.entities['stations']			= 	(this.commons.userInfo.currentDestination.workingStations||[])
																	.map(item => item.items)
																	.flat()
																	.map(item => { return {...item,label : item.type[0].toUpperCase() +item.type.substr(1) + ' ' + item.name}});
		if(undefined==this.commons.userInfo.currentStation){
			this.commons.userInfo.currentStation			= this.commons.pageInfo.entities["stations"][0];
		}
	}

    async loadCompanyModules()						{
														let dmcInfo				=	(this.storage.getItem('dmcSuite') || {}).userInfo;
														this.pageInfo.modules	=	(dmcInfo && dmcInfo.idDmc) 	? await this.companyService.getModulesFromCompany(dmcInfo.idDmc)
																												: [];

													}

	async initApp() {
		this.setTranslator()
		this.firebaseService.init({ userInfo : { id : 1 } });
		this.pageInfo.subscriptions = {};
	}

	setTranslator()            						{	this.translate.setDefaultLang('es');     }

    // async _loadGlobalEntities()    					{	const entities	= [ "provider_areas_to_tourinia" ];
	// 	 										        entities.map(async $entity=>{
	// 										            await this.entityService.loadEntity($entity);
	// 											            this.commons.setEntity($entity, 		await this.entityService.get($entity));
	// 											            this.commons.setEntity($entity+'List', 	await this.entityService.getList($entity));
	// 											        });
	// 											        console.log("End load global entities");
	// 	    										}

	async loadGlobalEntities()    					{
		const entities	= [ "providers" ];

		entities.map(async $entity=>{
			await this.entityService.loadEntity($entity);
			this.commons.setEntity($entity, 			await this.entityService.get($entity));
			this.commons.setEntity($entity+'List', 	await this.entityService.getList($entity));
		});

		// Main entitiies
		entities.forEach(entity=>{
			switch(entity){
				default	:	this.firebaseService.subscribeEntity(entity).subscribe(data=>{
								this.processEntity(entity,data)
							}); break;
			}
		});
	}

	unsubscribeUserEntities(){
		this.pageInfo.subscriptions.forEach(subscription=>subscription());
	}

	async loadUserEntities()
	{
		let colPath, docPath, info;
		const entities = [ 
			"tourinia_areas_and_zones", 
			// "zones", 
			// "areas", 
			// "provider_areas", 
			"dmcs",
			"config_modules" 
		];

		const dmcId 		= this.commons.userInfo.currentDmc.id;
		const destinationId	= this.commons.userInfo.currentDestination.id;

		entities.forEach(async entity=>{
			switch(entity){
				// case "areas"			:
				// 	colPath = "destinations/"+destinationId+"/areas";
				// 	this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(colPath).subscribe( data => { 
				// 		this.processEntity(entity, data) 
				// 	});
				// 	break;

				// case "provider_areas"	:
				// 	colPath = "destinations/"+destinationId+"/providers";
				// 	this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(colPath).subscribe( data => { 
				// 		this.processEntity(entity, data) 
				// 	});
				// 	break;

				case "tourinia_areas_and_zones"	:
					colPath	= "dmcs/"+dmcId+"/destinations/"+destinationId+"/aggregators";
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(colPath).subscribe( data => { 
						this.processEntity(entity, data);
					});
					break;


				case "dmcs"				:
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(entity).subscribe(data => { this.processEntity(entity,data) });
					break;

				case "config_modules"	:
					docPath	= "config/modules";
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntityDoc(docPath).subscribe( data => { this.processEntity(entity,data) });
					break;

				default					:
					this.pageInfo.subscriptions[entity] = this.firebaseService.subscribeEntity(entity).subscribe( data => { this.processEntity(entity,data); });
					break;
			}
		});
	}

	getCenterPolygon(polygon){
		console.log(polygon)
		if(!this.pageInfo.originSelected){
			this.pageInfo.route.origin.coords		=	this.mapService.get_polygon_centroid(polygon.coords);
			this.pageInfo.originSelected			=	true;
			this.pageInfo.originArea				=	polygon.name;
			this.pageInfo.displayDirection 			=	true;

		}else{
			this.pageInfo.route.destination.coords	=	this.mapService.get_polygon_centroid(polygon.coords);
			this.pageInfo.destinationArea			=	polygon.name;
			this.pageInfo.displayDirection 			=	true;
		}
		polygon.color 	=	'tomato';
	}

	/**
	 * Force tourinia areas and zones
	 */
	async setTouriniaAreasAndZones($params)
	{
		try {
			let $entity				= $params["entity"];
			let $info				= $params["info"];

			// Each DMC PROVIDER has its zones
			let response 			= await this.getProviderZones($info);

			let areas2Zone 			= response.areas2Zone;
			let providerZones		= response.providerZones;
			let providerZonesObject = response.providerZonesObject;

			// FYI !
			// INIT CLONING ZONES FROM HTX TO OTHER PROVIDERS	-----------------
			// REMOVE !!!

			const providerForced	= 1;

			// HTX to Hoppa,Hotelbeds and WTR
			[2,4,5].forEach(provider=>{
				providerZones	[provider] 	= providerZones	[providerForced];
				areas2Zone		[provider]	= areas2Zone	[providerForced];
			});

			// END CLONING	-----------------------------------------------------

			this.commons.setEntity("provider_zones",		providerZones		);
			this.commons.setEntity("provider_zones_object",	providerZonesObject	);
			this.commons.setEntity("provider_areas_2_zones",areas2Zone			);

			// Tourinia Areas and areas 2 zone mappings
			this.commons.userInfo.tourinia_areas 			= Object.keys(areas2Zone[providerForced]);
			this.commons.userInfo.tourinia_areas_2_zones	= areas2Zone[providerForced];

			console.log("PROVIDER_ZONES",			providerZones	);
			console.log("PROVIDER AREAS 2 ZONES",	areas2Zone		);

			this.setDestination();

		}catch(e){
			this.commons.generateToastError("_SET_TOURINIA_ZONES_ERROR");
		}
	}

	async setDestination($info:any={})				
	{
		try {
			// Get DESTINATION info and make it PUBLIC
			let destinationData 	= await this.firebaseService.getDataByRef(this.commons.userInfo.currentDestination.refDestination);
			if(!destinationData){
				this.commons.generateToast("_ERROR","_DESTINATION_DATA_NOT_FOUND","error");
				return false;
			}

			this.commons.userInfo.provider_mappings			= destinationData["provider_mappings"	] 	|| {};
			this.commons.userInfo.tourinia_resorts 			= destinationData["tourinia_resorts"	]	|| [];

			// Get DMC DESTINATION info and make it PUBLIC
			const dmcId				= this.commons.userInfo.currentDmc.id;
			const destinationId		= this.commons.userInfo.currentDestination.id;

			const dmcDestinationPath= "dmcs/"+dmcId+"/destinations/"+destinationId;
			let dmcDestinationData	= await this.firebaseService.getDataByRef(dmcDestinationPath);
			if(!dmcDestinationData){
				this.commons.generateToast("_ERROR","_DMC_DESTINATION_DATA_NOT_FOUND","error");
				return false;
			}

			// Info from destination DMC
			// 	pickupZones
			//	rules
			//	turns
			//	waitingTimes

			this.commons.userInfo.tourinia_resorts_2_areas	= dmcDestinationData["tourinia_resorts_2_areas"] || {};
			this.commons.userInfo.currentDestinationDmc		= dmcDestinationData;
			
			// Flights aliases
			this.commons.pageInfo.flights_aliases			= dmcDestinationData["flights_aliases"];
			
			// Also add to currentDestination
			[
				"pickupZones",
				"rules",
				"turns",
				"waitingTimes",
				"turn_duration_min",
				"init_service_time",
				"baseArea",
				"canary_shuttle_mappings"
			]
			.forEach(token=>{
				this.commons.userInfo.currentDestination[token] = dmcDestinationData[token];
			});

		}catch(e){
			this.commons.generateToastError("_SET_DESTINATION_ERROR");
		}
	}

	async getProviderZones($info=[])
	{
		let response = { 
			areas2Zone			: {}, 
			providerZones		: {}, 
			providerZonesObject	: {}
		};

		try {
			for(let provider of $info){
				try {
					if(!provider.id){
						this.commons.generateToastError("_PROVIDER_WITH_NO_ID");
						throw new Error("_PROVIDER_WITH_NO_ID");						
					}
					console.log("Tourinia zones from provider",provider.id);
					response.areas2Zone[provider.id] 			= {};
					response.providerZones[provider.id]			= [];
					response.providerZonesObject[provider.id]	= [];

					let zones = provider.zones || [];
					if(zones.length==0){
						this.commons.generateToastError("_NO_ZONES_FOR_PROVIDER_AT_DESTINATION");
						return response;
					}
					for(let zone of provider.zones){
						let areas = await this.getProviderZoneAreas(zone);

						(areas||[]).forEach(area=>{
							response.areas2Zone[provider.id][area["properties"]["name"]]=zone.name;
						});

						response.providerZones		[provider.id].push(zone.name);
						response.providerZonesObject[provider.id].push(zone);
					}
				}
				catch(e){
					this.commons.generateToastError("_NO_ZONES_FOR_PROVIDER");
				}
			}
		}catch(e){
			this.commons.generateToastError("_GET_PROVIDER_ZONES_ERROR");
		}
		return response;
	}

	async getProviderZoneAreas($zone){
		let response = await this.firebaseService.getInfoAreas($zone.refAreas);
		return response;
	}

	setProviderDestinationAreas($info){
		// Provider_Areas_2_zones are generated at SetTouriniaAreasAndZones()
		// Call tourinia_zones before
		let provider_areas_2_zones 		= this.commons.getEntity("provider_areas_2_zones");
		let provider_areas_mapped  		= { 1: {}};
		let provider_areas_not_found	= { 1: {}};
		let provider_areas_to_tourinia	= { 1: {}};

		console.log("Provider_areas_2_zones",provider_areas_2_zones);

		$info.forEach(provider=>{
			provider_areas_mapped		[provider.id]	= {};
			provider_areas_not_found	[provider.id]	= [];
			provider_areas_to_tourinia	[provider.id]	= {};

			provider.areas.forEach(area=>{
				provider_areas_to_tourinia[provider.id][area.from] = area.to;
				let zoneFound = provider_areas_2_zones[provider.id][area.to];
				if(zoneFound)	{	provider_areas_mapped[provider.id][area.from] = zoneFound;	}
				else			{	provider_areas_not_found[provider.id].push(area.from);		}
			})
		});

		// WTS hack !
		[2,15].forEach(provider=>{
			provider_areas_mapped		[provider] 	= provider_areas_mapped		[1];
			provider_areas_to_tourinia	[provider]	= provider_areas_to_tourinia[1];
		});

		let moreZones = this.commons.pageInfo.moreZones;

		// Add forced mapping for HOPPA and WTR
		// REMOVE ! and move to
		// dmcs/<id>/destinations/<id>/aggregators";
		Object.keys(moreZones).forEach(zone=>{
			provider_areas_mapped[2 ][zone] = moreZones[zone];
			provider_areas_mapped[15][zone] = moreZones[zone];
		});

		// provider areas mapped contains map between Provider Resort to Tourinia Zone
		console.log("PROVIDER_DESTINATION_AREAS ( MORE_ZONES )",provider_areas_mapped		);
		this.commons.setEntity("provider_areas_mapped",			provider_areas_mapped		);
		this.commons.setEntity("provider_areas_to_tourinia", 	provider_areas_to_tourinia	);
	}

	loadProviders($info){
		this.commons.setEntity("providers",$info||[]);
	}

	async processEntity($entity,$info)	{

		let response, defaults;

		switch($entity){
			// Tournia AREAS & ZONES and providers zones
			case "tourinia_areas_and_zones"		:
				this.setTouriniaAreasAndZones({ entity: $entity, info: $info });
				break;

			// REMOVE !!!!!
			// Now should be
			// /countries/<id>/destinations/<id>/refAreas
			//
			// Check wnere AREAS are loaded !!!
			case "areas"				:

					const colPath = "destinations/"+this.commons.userInfo.currentDestination.id+"/areas";
					$info.forEach(area => {
						area.center = this.mapService.get_polygon_centroid(area.coords);
					});
					this.commons.setEntity($entity,$info||[]);
					console.log("AREAS",$info);
					break;

			case "provider_areas"		:

				// destinations/<id>/providers";
				this.commons.setEntity($entity,$info || []);
				console.log("PROVIDER AREAS",$info);
				break;

			case "provider_destination_areas":	this.setProviderDestinationAreas($info); 	break;

			// END AREAS & ZONES

			case "config_modules"		:	this.commons.setEntity($entity,$info || []); 	break;

			case 'dmcs'					:	this.commons.setEntity($entity,$info || []);	break;

			case "providers"			:	this.loadProviders($info); 						break;

			case "users"				:	this.commons.setEntity(	"users",
																	[ 	{ status: "new", name: this.commons.getTranslate('_NEW') },
																		...$info
																			// .filter(item=>item.group=="staff")
																			.map(item=>{
																			if( item.status!="new"){
																				item.complete_name  = item.first_name;
																				item.complete_name += item.last_name?" "+item.last_name:"";
																				item.profile_entry	= item.profile_entty;
																				item.profile		= item.profile_entry.value;
																			}
																			return item;
																		})
																	]
											);
											break;

			case "customers"			:	this.commons.setEntity("customers",	[ 	{ status: "new", name: this.commons.getTranslate('_NEW'), is_company: true },
																					...$info
																				]);
											break;

			case "profiles"				:	this.commons.setEntity("profiles",	$info);
											break;

			case "views"				:	let views = $info["views"] || $info[0]["views"];
											this.commons.setEntity($entity,views);
											break;

			default						: 	this.commons.setEntity($entity,			$info);
											this.commons.setEntity($entity+"List", 	$info);
											break;
		}
	//    console.log("Entity",$entity,this.commons.getEntity($entity));
	//    console.log("[LOAD ENTITY] ",$entity,$info);
	}

	getInfo($entity,$type)							{	switch($entity){
															case "user"		: 	if( !this.commons.userInfo ){ return false; }
																				switch($type){
																					case "isLogged"		:	return ( this.commons.userInfo.roles && this.commons.userInfo.roles.length  > 0 &&  this.commons.userInfo.isLogged 	);
																					// case "isNotLogged"	:	return ( this.commons.userInfo.roles.length == 0 || !this.commons.userInfo.roles 		);
																				}
														}
													}

    onMenuButtonClick(event: Event) 				{	this.menuButtonClick 		= true;
												        this.menuActive 			= !this.menuActive;
												        event.preventDefault();
				    								}

    onTopbarMenuButtonClick(event: Event) 			{	this.topbarMenuButtonClick 	= true;
												        this.topbarMenuActive 		= !this.topbarMenuActive;
												        event.preventDefault();
												    }

    onTopbarItemClick(event: Event, item: Element) {	this.topbarMenuButtonClick 	= true;
												        if (this.activeTopbarItem === item) {	this.activeTopbarItem 	= null;	}
												        else 								{	this.activeTopbarItem 	= item;	}
												        event.preventDefault();
    												}

    onTopbarSubItemClick(event) 					{	event.preventDefault();				}

    onLayoutClick() 								{
												        if (!this.menuButtonClick && !this.menuClick) {
												            if (this.menuMode === 'horizontal') 	{	this.resetMenu = true;		}
															if (this.isMobile() 			||
																this.menuMode === 'overlay' ||  
																this.menuMode === 'popup') 			{	this.menuActive = false;	}
												            this.menuHoverActive 	= false;
												        }

												        if (!this.topbarMenuButtonClick) 			{	this.activeTopbarItem = null; this.topbarMenuActive = false;	}

												        this.menuButtonClick 		= false;
												        this.menuClick 				= false;
												        this.topbarMenuButtonClick 	= false;
    												}

    onMenuClick() 									{	this.menuClick = true; this.resetMenu = false;							}
    isMobile() 										{ 	return window.innerWidth < 1025;										}
    isHorizontal() 									{ 	return this.menuMode === 'horizontal'; 									}
    isTablet()     									{ 	const width = window.innerWidth; return width <= 1024 && width > 640; 	}
    // isLogged()   									{	console.log('User logged',this.commons.userInfo.isLogged);
	// 											        this.router.navigate([this.commons.userInfo.isLogged?'/checkins':'/landing']);
	// 											    }

    async generateTranslates(force=false)			{
		if( this.pageInfo.i18n.loaded==true && force==false ){ return false; }
		this.pageInfo.i18n.items = await Promise.resolve(this.translate.getTranslation(this.translate.getBrowserLang()).toPromise());
		this.pageInfo.i18n.loaded = true;
	}

	getTranslate($item)								{	return this.pageInfo.i18n.items[$item]	|| $item; 	}
	getAllTranslates()								{	return this.pageInfo.i18n.items;					}

	loadContent()									{
														if(this.pageInfo.userLogged == false) { return true }; // go to landing

														// if(this.pageInfo.dataLoaded){ return true;}else{return false}
														return this.pageInfo.dataLoaded;
														// else{
														// 	if(!this.commons.userInfo.isLogged || !this.commons.userInfo.roles){ return false};
														// 	return !(this.commons.userInfo.isLogged || this.commons.userInfo.roles.length >0)
														// }
													}

	doAction($type,$info?)							{
		switch($type){
			case "firebase"		: 	switch($info.type){
				case "open"		: this.pageInfo.firebaseOverlay = true; 	break;
				case "close"	: this.pageInfo.firebaseOverlay = false; 	break;
				case "action"	: switch($info.action){

					case "execute"	: 	let values 		= this.pageInfo.entities["firebase"].form.item;
										let jsonValues 	= {};
										console.log("VALUES",values);
										let validated = true;
										["path","operation","values"].forEach(field=>{
											validated = validated && values[field]!=undefined && values[field]!="";
										})
										if(!validated){
											this.commons.generateToast("_ERROR","_INVALID_FORM_VALUES","error");
											return false;
										}
										try {
											jsonValues = JSON.parse(values["values"]);
										} catch(e){
											this.commons.generateToast("_ERROR","_JSON_NOT_VALID", "error");
											return false;
										}

										jsonValues["timestamp"] = new Date();

										try {
											switch(values["operation"]){
												case "_FIREBASE_OPERATION_ADD"		: 	//this.commons.generateToast("_INFO","_ADDING_ITEM","info");
																						this.firebaseService.addDoc(values["path"],jsonValues);
																						break;
												case "_FIREBASE_OPERATION_SET"		: 	//this.commons.generateToast("_INFO","_SETTING_ITEM","info");
																						jsonValues["id"] = values["id"];
																						this.firebaseService.setDoc(values["path"],jsonValues);
																						break;
												case "_FIREBASE_OPERATION_UPDATE"	: 	//this.commons.generateToast("_INFO","_UPDATING_ITEM","info");
																						jsonValues["id"] = values["id"];
																						this.firebaseService.updateDoc(values["path"],jsonValues);
																						break;
												case "_FIREBASE_OPERATION_DELETE"	:	//this.commons.generateToast("_INFO","_DELETING_ITEM","info");
																						this.firebaseService.deleteDoc(jsonValues);
																						break;
											}
											this.commons.generateToast("_INFO","_FIREBASE_OPERATION_SUCCEED", "info");

										} catch(e){
											this.commons.generateToast("_FIREBASE_OPERATION_FAILED",e.message, "error");
											return false;
										}
										this.pageInfo.firebaseOverlay = false;
										break;

					case "cancel"	: 	this.pageInfo.firebaseOverlay = false;	break;
				}
			}
			break;
		}
	}
}
