import { FirebaseService 						} from './../../demo/service/database/firebase.service';
import { CommonsService 						} from '../../demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild,
		 ViewEncapsulation						} from '@angular/core';
import * as moment 						          from 'moment';
import { extendMoment 							} from 'moment-range';
import { isArray                                } from 'util';
import { ConfirmationService 					} from 'primeng/api';
import { StorageService 						} from 'src/app/demo/service/storageservice';

@Component({
  selector        	: 'app-tours-schedule',
  templateUrl     	: './tours-schedule.component.html',
  encapsulation		: ViewEncapsulation.None,
  styleUrls       	: ['./tours-schedule.component.scss'],
})
export class ToursScheduleComponent implements OnInit {

    @Input() 	schedule        : any 				= null;
	@Output() 	emiterOption	: EventEmitter<any> = new EventEmitter();
    @ViewChild ('monthViewCalendar') monthViewCalendar: any;

	@ViewChild('dayExec')		dayExec			:	any;
	@ViewChild('dayCalendar')	dayCalendar		:	any;	

	locale		: string = 'es';
	pageInfo 	: any = {};
	userInfo	: any = {};
	
	constructor(
		private commons             : CommonsService,
		private storageCtrl			: StorageService,
		private confirmationService : ConfirmationService
    ){}
    async ngOnInit(){ await this.init(); }
    async init()    {
		this.pageInfo = { 
			showPriceSidebar				:	false,
			currency						:	'€',
			modeViewDays    				: 	'template',
	        viewDate						:	new Date(),
			displayDialog   				:   false,
			contextExecDisplayDialog		:	false,
			contextDayDisplayDialog			:	false,
	        dirtyDays       				:   [],
	        contextMenu     				:   {dayCopied	:	{}},
	        execsSelecteds  				:   {day    :   null,   execs   :   []},
			contextMenuExecs				:   {day    :   null,   execs   :   []},
			contextMenuDirtyDays			:	[],
	        colorPeriods    				:   ['#ffe595','#e8a5a5','#e6d398','tomato','#79b5e4'],
	        calSingleDaySelected			:	null,
			displayContextMenuOptions		:	null,
			displayCheckBoxDialogPeriod		:	true,
			displayCheckBoxDialogCalendar	:	true,
			templateColors					:	{
				_isPast			:	'#e8e8e8',
				isPast			:	'white',
				isToday 		:	'#b4eab4',
				isSelected		:	'tomato',
				isCopied		:	'purple',
				isDaySelected	:	'#ff9b7b',
				isWeekend		:	'#d1ecf5',
				isCustom		:	'#009688',
				isRangeMode		:	'#8a66ca',
				hasPeriod		:	'#346399',
			},
			useMode					:	[
				"_TOUR_PRICE_USE_MODE_LIST_1",
				"_TOUR_PRICE_USE_MODE_LIST_2",
				"_TOUR_PRICE_USE_MODE_LIST_3",
				"_TOUR_PRICE_USE_MODE_LIST_4",
				"_TOUR_PRICE_USE_MODE_LIST_5"
			]
		};

		let answeredDialogPeriod					=	this.storageCtrl.getItem('answeredDialogPeriod');
		let answeredDialogCalendar					=	this.storageCtrl.getItem('answeredDialogCalendar');
		
		this.pageInfo.displayCheckBoxDialogPeriod	=	( answeredDialogPeriod 	== null || answeredDialogPeriod   == false) ? true : false;
		this.pageInfo.displayCheckBoxDialogCalendar	=	( answeredDialogCalendar== null || answeredDialogCalendar == false) ? true : false;
		
		this.pageInfo.modeDisplayCalendar = [
            { label : this.commons.getTranslate('_TEMPLATE'), value : 'template'},
            { label : this.commons.getTranslate('_CALENDAR'), value : 'calendar'}
        ];
		
		this.pageInfo.monthStepper  =   [
            { label : this.commons.getTranslate('_MONTH'), value : 'previous', 	iconPos:'left', 	icon:'fa fa-arrow-left'},
            { label : this.commons.getTranslate('_MONTH'), value : 'next', 		iconPos:'left',		icon:'fa fa-arrow-right'}
        ];

        this.pageInfo.contextMenuOptions = [
            {
				label		: 	this.commons.getTranslate('_COPY'),
				id			:	'copy',
                icon		: 	'pi pi-fw pi-chevron-right',
                command		: 	($event) => { 
                    this.pageInfo.contextMenu.dayCopied =   this.pageInfo.contextMenu.rightClick;
                    console.log('DAY COPIED!',this.pageInfo.contextMenu.dayCopied);
                    this.restartExecsSelecteds();
                    this.pageInfo.execsSelecteds.day    =   this.pageInfo.contextMenu.dayCopied.day;
                    this.pageInfo.execsSelecteds.execs  =   this.pageInfo.contextMenu.dayCopied.scheduler;
                }
			},
			{
				label		:	this.commons.getTranslate('_DELETE_MULTIPLE'),
				id			:	'delete_multiple',
				icon		:	'pi pi-fw pi-chevron-right',
				command		:	() => {
					const getDaysRangeMode 	=   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
						if(getDaysRangeMode.length > 1){
							getDaysRangeMode.forEach(element => {
								let foundDay	=	this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
								if(foundDay > -1){
									this.schedule.calendar.schedule.days.splice(foundDay,1);
								}
							});
							this.commons.generateToast('_SUCCESS','_DELETED_DAYS','success');
						}
						else{
							this.commons.generateToast('_ERROR','_NO_MULTIPLE_DAYS_SELECTEDS','error');
						}
						this.markRankDaysSchedule(false);
						this.updateViewCalendar();
				}
			},
			{
				label		:	this.commons.getTranslate('_DELETE'),
				id			:	'delete',
				icon		:	'pi pi-fw pi-chevron-right',
				command		:	() => {
					console.log(this.pageInfo.contextMenuExecs);
					if(this.pageInfo.contextMenu.rightClick){
						let foundDay	=	this.schedule.calendar.schedule.days.find(el => el.day == this.pageInfo.contextMenu.rightClick.day);
						if(foundDay){
							this.confirmationService.confirm({
								message : 	foundDay.scheduler.some(el => el.dirty) ? this.commons.getTranslate('_CONFIRMATION_DELETE_CUSTOMIZED_EXEC') : this.commons.getTranslate('_CONFIRMATION_DELETE_DAY'),
								header	:	this.commons.getTranslate('_DELETE_OFFER'),
								icon	:	'pi pi-exclamation-triangle',
								accept	:	()=>{
									foundDay.scheduler = [];
									this.commons.generateToast('_SUCCESS','_DAY_DELETED','success');
									this.markRankDaysSchedule(false);
									this.updateViewCalendar();
								}
							})
						}
					}
				}
			},
			{
				label		: this.commons.getTranslate('_PASTE_OVERWRITING'),
				icon		: 'pi pi-fw pi-chvron-left',
				id			: 'paste_overwrite',
				command		:  ($event) =>{
					this.pageInfo.pasteMode	=	'overwrite';
					this.computePasteDayLogic();
				}
			},
            {
                label		: this.commons.getTranslate('_PASTE_MERGING'),
				icon		: 'pi pi-fw pi-chevron-left',
				id			: 'paste_merge',
                command		: ($event) => { 
					this.pageInfo.pasteMode = 'merge';
					this.computePasteDayLogic();
                    // this.restartExecsSelecteds();
				}
            }
		];
		
        this.pageInfo.contextMenuOptionsExec    =   [
            {
                label   :   this.commons.getTranslate('_COPY'),
                id      :   'copy',
                icon    :   'pi pi-fw pi-chevron-right',
                command :   ($event) => {
                    this.pageInfo.contextMenuExecs  =   JSON.parse(JSON.stringify(this.pageInfo.execsSelecteds));
                    this.commons.generateToast('_INFO','_EXECS_COPIED','info');
                }
            },{
                label   :   this.commons.getTranslate('_DELETE'),
                id      :   'delete',
                icon    :   'pi pi-fw pi-chevron-right',
                command :   ($event) => {
					console.log('Deleting', this.pageInfo.execsSelecteds);
					console.log(this.schedule.calendar.schedule);
					this.confirmationService.confirm({
						message : 	this.commons.getTranslate('_CONFIRMATION_DELETE_CUSTOMIZED_EXEC'),
						header	:	this.commons.getTranslate('_DELETE_OFFER'),
						icon	:	'pi pi-exclamation-triangle',
						accept	:	()=>{
							// First find the day from the list and remove the selected execs
							let foundDay		=	this.schedule.calendar.schedule.days.find(el => el.day == this.pageInfo.execsSelecteds.day);
							if(!foundDay)	return this.commons.generateToast('_ERROR','_EXEC_NOT_FOUND','error');
							
							foundDay.scheduler	=	foundDay.scheduler.filter(exec => !this.pageInfo.execsSelecteds.execs.some(el => exec.id == el.id));
							this.commons.generateToast('_SUCCESS','_EXECS_DELETED','success');
							this.updateViewCalendar();
						}
					});
				}
            }
        ]
    }
	
	/**
	 * ?????
	 */
	computePasteDayLogic()
	{
		if(this.pageInfo.contextMenuExecs.day != null){
			const getDaysRangeMode 	=   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
			if(getDaysRangeMode.length > 1){
				this.pageInfo.contextMenuDirtyDays	=	[];
				this.pageInfo.duplicatedExecs		=	[];
				if(this.pageInfo.pasteMode == 'merge'){
					getDaysRangeMode.forEach(element =>{
						let foundDay	=	this.schedule.calendar.schedule.days.find(el => moment(el.day).isSame(moment(element.date)));
						if(foundDay){
							if(foundDay.scheduler && foundDay.scheduler.some(exec => this.pageInfo.contextMenuExecs.execs.some(contextExec => contextExec.start_hour ==exec.start_hour))){
								this.pageInfo.duplicatedExecs.push(foundDay);
							}
						}
					});
					if(this.pageInfo.duplicatedExecs.length > 0){
						let datesDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => moment(el.day).format('DD-MM-YYYY'));
						let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_HOUR') +' :'+JSON.stringify(datesDuplicateds);
						return this.commons.generateToast('_DUPLICATED_HOURS',strMessage,'error');
					}
				}
				
				getDaysRangeMode.forEach(element =>{
					let foundDay	=	this.schedule.calendar.schedule.days.find(el => moment(el.day).isSame(moment(element.date)));
					if(foundDay && foundDay.scheduler.some(el => el.dirty)){
						this.pageInfo.contextMenuDirtyDays.push(foundDay);
					}
				});
				if(this.pageInfo.contextMenuDirtyDays.length > 0)	{ this.pageInfo.contextExecDisplayDialog = true;	}
															else	{ this.pasteAllContextExecs();						}
			}else{
				let foundDay			=	this.schedule.calendar.schedule.days.find(el => el.day == this.pageInfo.contextMenu.rightClick.day);
				console.log('found day', foundDay);

				if(foundDay){
					if(this.pageInfo.pasteMode == 'merge'){
						this.pageInfo.duplicatedExecs	=	foundDay.scheduler.filter(exec => this.pageInfo.contextMenuExecs.execs.some(copiedExec => copiedExec.start_hour == exec.start_hour));
						if(this.pageInfo.duplicatedExecs.length > 0){
							let hoursDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => el.start_hour);
							let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_HOUR') +' :'+JSON.stringify(hoursDuplicateds);
							return this.commons.generateToast('_DUPLICATED_HOURS',strMessage,'error');
						}
					}
					switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	foundDay.scheduler	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs)); break;
						case 'merge'		:	this.pageInfo.contextMenuExecs.execs.forEach(el => {
													if(!foundDay.scheduler.find(exec => exec.start_hour == el.start_hour)){
														foundDay.scheduler.push(JSON.parse(JSON.stringify(el)));
													}
												}); break;
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');
					}
				}else{
					this.pageInfo.contextMenu.rightClick.scheduler	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs));
					this.schedule.calendar.schedule.days.push(JSON.parse(JSON.stringify(this.pageInfo.contextMenu.rightClick)));
					
				}
			}
			// ALL DAY COPIED
		}else{
			const getDaysRangeMode                          =   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
			if(getDaysRangeMode.length > 0){
				if(this.pageInfo.pasteMode == 'merge'){
					this.pageInfo.duplicatedExecs		=	[];
					getDaysRangeMode.forEach(element =>{
						let foundDay	=	this.schedule.calendar.schedule.days.find(el => moment(el.day).isSame(moment(element.date)));
						if(foundDay){
							if(foundDay.scheduler && foundDay.scheduler.some(exec => this.pageInfo.contextMenu.dayCopied.scheduler.some(contextExec => contextExec.start_hour ==exec.start_hour))){
								this.pageInfo.duplicatedExecs.push(foundDay);
							}
						}
					});
					if(this.pageInfo.duplicatedExecs.length > 0){
						let datesDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => moment(el.day).format('DD-MM-YYYY'));
						let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_HOUR') +' :'+JSON.stringify(datesDuplicateds);
						return this.commons.generateToast('_DUPLICATED_HOURS',strMessage,'error');
					}
				}	
				this.pageInfo.contextMenuDirtyDays	=	[];
				getDaysRangeMode.forEach(element =>{
					let foundDay	=	this.schedule.calendar.schedule.days.find(el => moment(el.day).isSame(moment(element.date)));
					if(foundDay && foundDay.scheduler.some(el => el.dirty)){
						this.pageInfo.contextMenuDirtyDays.push(foundDay);
					}
				});
				if(this.pageInfo.contextMenuDirtyDays.length > 0){
					this.pageInfo.contextDayDisplayDialog = true;
				}else{
					this.pasteAllContextDay();
				}
		
				// this.markRankDaysSchedule(false);
				
			}else{
				const foundDay	=	this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(this.pageInfo.contextMenu.rightClick.day)));
				if(foundDay > -1){
					if(this.pageInfo.pasteMode == 'merge'){
						this.pageInfo.duplicatedExecs	=	this.schedule.calendar.schedule.days[foundDay].scheduler.filter(exec => this.pageInfo.contextMenu.dayCopied.scheduler.some(copiedExec => copiedExec.start_hour == exec.start_hour));
						if(this.pageInfo.duplicatedExecs.length > 0){
							let hoursDuplicateds	=	this.pageInfo.duplicatedExecs.map(el => el.start_hour);
							let strMessage			=	this.commons.getTranslate('_FOLLOWING_DATES_DUPLICATED_HOUR') +' :'+JSON.stringify(hoursDuplicateds);
							return this.commons.generateToast('_DUPLICATED_HOURS',strMessage,'error');
						}
					}
					switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	this.schedule.calendar.schedule.days[foundDay].scheduler	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.scheduler)); break;
						case 'merge'		:	this.pageInfo.contextMenu.dayCopied.scheduler.forEach(el => {
													if(!this.schedule.calendar.schedule.days[foundDay].scheduler.find(exec => exec.start_hour == el.start_hour)){
														this.schedule.calendar.schedule.days[foundDay].scheduler.push(JSON.parse(JSON.stringify(el)));
													}
												}); break;
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');						 	
					}
				}else{
					this.pageInfo.contextMenu.rightClick.scheduler	=	JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.scheduler));
					this.schedule.calendar.schedule.days.push(JSON.parse(JSON.stringify(this.pageInfo.contextMenu.rightClick)));
				}
				this.pageInfo.contextMenu.rightClick 					=	null;
			}
		}
		this.updateViewCalendar();
	}

	/**
	 * ????
	 */
    updateViewCalendar(){
        console.log('updating calendar');
        setTimeout(()=>{
            this.monthViewCalendar.view.days =  this.monthViewCalendar.view.days.map(el =>{
                const foundDay  =   this.schedule.calendar.schedule.days.find(item => moment(el.date).format() == moment(item.day).format());
                return { ...el, infoDay : foundDay }
            });
        },150);
	}
	
    onChangeModeView($event)	{	if($event.value=='calendar'){ this.updateViewCalendar(); }	}
	
	/**
	 * ???
	 */
	pasteAllContextExecs(){
		const getDaysRangeMode 	=   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element =>{
			let foundDay	=	this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				switch(this.pageInfo.pasteMode){
					case 'overwrite'	:	this.schedule.calendar.schedule.days[foundDay].scheduler = JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs));break;	
					case 'merge'		:	this.pageInfo.contextMenuExecs.execs.forEach(exec =>{
												if(!this.schedule.calendar.schedule.days[foundDay].scheduler.find(el => el.start_hour == exec.start_hour)){
													this.schedule.calendar.schedule.days[foundDay].scheduler.push(JSON.parse(JSON.stringify(exec)));
												}
											});break;
					default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');						 	
				}
			}else{
				this.schedule.calendar.schedule.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					scheduler		: this.pageInfo.contextMenuExecs.execs.map(exec => {return { ...exec,id:this.commons.generateHash()}})
				});
			}
		});
		this.restartDaysConfig();
	}

	/**
	 * ????
	 */
	pasteNonCustomizedContextExecs(){
		const getDaysRangeMode	=	this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element =>{
			let foundDay	=	this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(element.tdate)));
			if(foundDay>-1){
				if(!this.pageInfo.contextMenuDirtyDays.find(el => el.day == foundDay.day)){
					switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	this.schedule.calendar.schedule.days[foundDay].scheduler = JSON.parse(JSON.stringify(this.pageInfo.contextMenuExecs.execs));break;	
						case 'merge'		:	this.pageInfo.contextMenuExecs.execs.forEach(exec =>{
													if(!this.schedule.calendar.schedule.days[foundDay].scheduler.find(el => el.start_hour == exec.start_hour)){
														this.schedule.calendar.schedule.days[foundDay].scheduler.push(JSON.parse(JSON.stringify(exec)));
													}
												});break;
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');						 	
					}
				}
			}else{
				this.schedule.calendar.schedule.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					scheduler		: this.pageInfo.contextMenuExecs.execs.map(exec => {return { ...exec,id:this.commons.generateHash()}})
				});
			}
		});
		this.restartDaysConfig();	
	}

	/**
	 * ???
	 */
	pasteAllContextDay(){
		const getDaysRangeMode	=	this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element=>{
			const foundDay = this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				switch(this.pageInfo.pasteMode){
					case 'overwrite'	:	this.schedule.calendar.schedule.days[foundDay].scheduler = this.pageInfo.contextMenu.dayCopied.scheduler;	break;	
					case 'merge'		:	this.pageInfo.contextMenu.dayCopied.scheduler.forEach(exec => {
												if(!this.schedule.calendar.schedule.days[foundDay].scheduler.find(el => el.start_hour == exec.start_hour)){
													this.schedule.calendar.schedule.days[foundDay].scheduler.push(JSON.parse(JSON.stringify(exec)));
												} 
											}); break;
					default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');											 	
				}
			}else{
				this.schedule.calendar.schedule.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					scheduler		: JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.scheduler))
				});
			}
		});
		this.restartDaysConfig();
	}

	/**
	 * ???
	 */
	pasteNonCustomizedContextDay(){
		const getDaysRangeMode	=	this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		getDaysRangeMode.forEach(element=>{
			const foundDay = this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).isSame(moment(element.date)));
			if(foundDay > -1){
				if(!this.pageInfo.contextMenuDirtyDays.find(el => moment(el.day).isSame(element.date))){
					switch(this.pageInfo.pasteMode){
						case 'overwrite'	:	this.schedule.calendar.schedule.days[foundDay].scheduler = this.pageInfo.contextMenu.dayCopied.scheduler;	break;	
						case 'merge'		:	this.pageInfo.contextMenu.dayCopied.scheduler.forEach(exec => {
													if(!this.schedule.calendar.schedule.days[foundDay].scheduler.find(el => el.start_hour == exec.start_hour)){
														this.schedule.calendar.schedule.days[foundDay].scheduler.push(JSON.parse(JSON.stringify(exec)));
													} 
												}); break; 	
						default				:	return this.commons.generateToast('_ERROR','_NONE_PASTE_MODE_SELECTED','error');
					}
				}
				
			}else{
				this.schedule.calendar.schedule.days.push({
					day 			: moment(element.date).format(), 
					period_owner 	: null,
					scheduler		: JSON.parse(JSON.stringify(this.pageInfo.contextMenu.dayCopied.scheduler))
				});
			}
		});
		this.restartDaysConfig();

	}
	
	/**
	 * ???
	 */
	restartDaysConfig(){
		this.pageInfo.contextExecDisplayDialog	=	false;
		this.pageInfo.contextDayDisplayDialog	=	false;
		// this.commons.generateToast('_SUCCESS','_EXECS_COPIED','success');
		this.pageInfo.contextMenu.rightClick 	= 	null;
		this.pageInfo.contextMenuExecs			=	{day    :   null,   execs   :   []};
		this.pageInfo.pasteMode					=	null;
        this.restartDayCopied();
        this.restartExecsSelecteds();
		this.markRankDaysSchedule(false);
		this.updateViewCalendar();
    }

	/**
	 * ???
	 */
    restartDayCopied(){
        this.pageInfo.contextMenu.dayCopied     =   {
            day             :   null,
            period_owner    :   null,
            scheduler       :   null
        }
	}
	
	/**
	 * ???
	 * @param $event 
	 */
    async handleSchedulePeriodEvent($event){
        switch($event.type){
            case 'add'      :   this.addNewPeriodSchedule($event.data); break;
            case 'update'   :   this.updatePeriodSchedule($event.data); break;
            case 'delete'   :   this.deletePeriodSchedule($event.data); break;
            case 'lastRow'  :   this.lastRowPeriodSchedule($event.data); break;
        }
    }

	/**
	 * 
	 * @param data New period created from component tours-period-table
	 */
    lastRowPeriodSchedule(data){
        this.schedule.calendar.schedule.periods.push({
            start       :   data.start,
            end         :   data.end,
            title       :   data.title,
            template    :   [],
            id          :   this.commons.generateHash()
        });
        console.log(this.schedule.calendar.schedule.periods);
    }

    addNewPeriodSchedule(data){
        this.schedule.calendar.schedule.periods.push({start:new Date(), end: new Date(), template : [], id : this.commons.generateHash(Math.random().toString())})
    }

    async updatePeriodSchedule(data){
        let findPeriod  =   this.schedule.calendar.schedule.periods.find(el => el.id == data.id);

        if(!this.checkMinLengthPeriod(data)){
            findPeriod.editMode = true;
            return await this.commons.generateToast('_ERROR','_MIN_PERIOD_LENGTH','error');
        } 
        
        if(this.checkCompatibilityPeriodsSchedule(data)){
            findPeriod          =   data;
            findPeriod.selected =   true;
            this.updateDaysFromPeriodSchedule(findPeriod);
        } else{
            await this.commons.generateToast('_ERROR','_BAD_PERIOD','error');
			findPeriod.editMode = true;
        }
    }

    updateDaysFromPeriodSchedule(period){
        const daysPeriod    =   this.getDaysFromPeriodSchedule(period.id);
        daysPeriod.forEach(item =>{
            const isInPeriod    =  (moment(item.day).isBetween(period.start, period.end));
            if(!isInPeriod){
                const findDay = this.schedule.calendar.schedule.days.findIndex(el => moment(el.day).format() == moment(item.day).format());
                findDay > -1    ?   this.schedule.calendar.schedule.days.splice(findDay,1)   :   null;
            }   
        });
        
        period.template.forEach(item =>{
            if(!item.scheduler) return;
            item.scheduler.forEach(exec =>{
                this.fillCalendarDaysSchedule(item.day,exec);
            })
        })
    }
    getDaysFromPeriodSchedule(idPeriod){
        return this.schedule.calendar.schedule.days.filter(el => (el.period_owner != null) && (el.period_owner.id == idPeriod));
	}
	/**
	 * Find the period and delete it. After search all the days in schedule  which has its period id assigned to remove it.
	 * @param data period from tours-period-table component 
	 */
    deletePeriodSchedule(data){
        let findPeriod  =   this.schedule.calendar.schedule.periods.findIndex(el => el.id == data.id);
        if(findPeriod > -1){
            this.schedule.calendar.schedule.days = this.schedule.calendar.schedule.days
                                                    .filter(el =>{ 
                                                                if(!el.period_owner){ return true;}
                                                                return el.period_owner.id != data.id
                                                            });
            this.schedule.calendar.schedule.periods.splice(findPeriod,1)
        }else{
            this.commons.generateToast('_ERROR','_ERROR','error');        
        }
    
    }

    checkMinLengthPeriod(data){
        const extendedMoment 	= 	extendMoment(moment);
		return Array.from(extendedMoment().range(data.start, data.end).by('days')).length > 7
    }
    checkCompatibilityPeriodsSchedule(data){
        const filteredNull  =   this.schedule.calendar.schedule.periods.filter(data => data.start != null || data.end != null);
        const wrongPeriod   =   filteredNull.find(el =>{
                                const dateStart =   moment(data.start).format();    const dateEnd   =   moment(data.end).format();
                                const elStart   =   moment(el.start).format();      const elEnd     =   moment(el.end).format();
                                return (moment(dateStart).isBetween(elStart, elEnd) || moment(dateEnd).isBetween(elStart,elEnd)
                                        || 
                                        (moment(dateStart).isBefore(moment(elStart)) && moment(dateEnd).isAfter(moment(elEnd)))
                                        ||
                                        (moment(dateStart).isAfter(moment(elStart)) && moment(dateEnd).isBefore(elEnd)))});
        if(wrongPeriod) return false;
        return true;
	}
	
	getPeriodSelectedSchedule(){
        if(!this){ return null; }
		return this.schedule.calendar.schedule.periods.find(el => el.selected) || null;
    }
    
    handleTablePriceEvent($event){
        switch($event.type){
            case 'add'      :           this.addNewExecTour($event.data); break;
            case 'update'   :           this.updateExecTour($event.data); break;
            case 'delete'   :           this.deleteExecTour($event.data); break;
            case 'deleteMultiple'   :   this.deleteMultipleExecTour($event.data); break;
            case 'restart'  :           this.restartExecTourSchedule($event.data);break;
            case 'lastRow'  :           this.addItemLastRow($event.data); break;
            case 'merge'    :           this.mergeExecTour($event.data);  break;

        }
    }

    mergeExecTour(data){
        this.getInfoFillCalendarDays(data.daySelected.day);
        this.pageInfo.fillDaysData.data                     =   data.tmp;
        this.pageInfo.fillDaysData.multiple                 =   true;
        this.pageInfo.fillDaysData.daySelected				=	data.daySelected;

        if(this.pageInfo.fillDaysData.dirtyDays.length > 0){
            this.pageInfo.displayDialog =   true;
        }else{
            data.daySelected.scheduler = data.tmp.scheduler;
            data.daySelected.scheduler.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,exec));
        }
    }

    dialogMergePeriods(){
        this.pageInfo.fillDaysData.daySelected.scheduler    =   this.pageInfo.fillDaysData.data.scheduler;
        this.pageInfo.fillDaysData.daySelected.scheduler.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,exec));
        this.pageInfo.displayDialog = false;
    }
    dialogMergePeriodsNonCustomized(){
        let filtered    =   this.pageInfo.fillDaysData.filteredDays.filter(el => !this.pageInfo.fillDaysData.dirtyDays.some(dirty => moment(el).format() == moment(dirty.day).format()));
        
        this.pageInfo.fillDaysData.daySelected.scheduler    =   this.pageInfo.fillDaysData.data.scheduler;
        this.pageInfo.fillDaysData.daySelected.scheduler.forEach(exec => this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,filtered,exec));
        this.pageInfo.displayDialog = false;
    }
    addNewExecTour(data){
        console.log('ON ADD', data);
        data.daySelected.scheduler = data.daySelected.scheduler || [];
        data.daySelected.scheduler.map(el => el.editMode = false);
        data.daySelected.scheduler.push({
            id          : this.commons.generateHash(),
            editMode    : true,
            start_hour  : null,
            prices      : [{type:'adult',price : null},{type:'children',price : null},{type:'infant',price : null},]  
        });
    }

    addItemLastRow(data){
        data.daySelected.scheduler = data.daySelected.scheduler || [];
        data.daySelected.scheduler.map(el => el.editMode = false);
        
        data.exec.id          =   this.commons.generateHash();
        data.exec.editMode    =   false;
        data.daySelected.scheduler.push(data.exec);
        
        this.updateExecTour(data);
    }
    deleteMultipleExecTour(data){
        this.getInfoFillCalendarDays(data.daySelected.day);
        if(this.pageInfo.fillDaysData == null) return;
        if(this.pageInfo.fillDaysData.dirtyDays.length > 0 && !this.storageCtrl.getItem('answeredDialogPeriod')){
            this.pageInfo.displayDialog                 = true;
            this.pageInfo.fillDaysData.deleteMultiple   = true;
            this.pageInfo.fillDaysData.data             = data;
        }else{
            data.execsToDelete.forEach(execToDelete => {
                data.daySelected.scheduler.splice(data.daySelected.scheduler.findIndex(el => el.start_hour == execToDelete.start_hour),1);
                this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, this.pageInfo.fillDaysData.filteredDays,data.daySelected);
            });
        }
    }
    deleteMultipleExecsPeriod(){
        this.pageInfo.fillDaysData.data.execsToDelete.forEach(execToDelete => {
            this.pageInfo.fillDaysData.data.daySelected.scheduler.splice(this.pageInfo.fillDaysData.data.daySelected.scheduler.findIndex(el => el.start_hour == execToDelete.start_hour),1);
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, this.pageInfo.fillDaysData.filteredDays,this.pageInfo.fillDaysData.data.daySelected);
        });
        this.pageInfo.displayDialog = false;
    }
    deleteMultipleExecsPeriodNonCustomized(){
        let filtered    =   this.pageInfo.fillDaysData.filteredDays.filter(el => !this.pageInfo.fillDaysData.dirtyDays.some(dirty => moment(el).format() == moment(dirty.day).format()));
        this.pageInfo.fillDaysData.data.execsToDelete.forEach(execToDelete => {
            this.pageInfo.fillDaysData.data.daySelected.scheduler.splice(this.pageInfo.fillDaysData.data.daySelected.scheduler.findIndex(el => el.start_hour == execToDelete.start_hour),1);
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod, filtered,this.pageInfo.fillDaysData.data.daySelected);
        });
        this.pageInfo.displayDialog = false;
    }
    updateExecTour(data){
        console.log('ON UPDATE', data);
		
		const checkNewInfo  =   data.exec.prices.some(el => el.price < 0);
        if(checkNewInfo)	{   return this.commons.generateToast('_ERROR','_BAD_PRICE','error');}
		
		let findedExec      =   data.daySelected.scheduler.findIndex(el => el.id == data.exec.id);
        if(findedExec < 0)	{   return this.commons.generateToast('_ERROR','_NO_EXEC_FOUND','error');};
	   
		// data.exec.editMode  					=   false;
		// data.daySelected.scheduler[findedExec] 	= 	data.exec;
		
        this.getInfoFillCalendarDays(data.daySelected.day);
        
        if(this.pageInfo.fillDaysData == null) return;
        if(this.pageInfo.fillDaysData.filteredDays.length == 0){
            this.pageInfo.fillDaysData.filteredDays = [moment(data.daySelected.day)];
        }
        this.pageInfo.fillDaysData.data                     =   data.exec;
        this.pageInfo.fillDaysData.execTemplateSelected     =   data.daySelected.scheduler[findedExec];
        this.pageInfo.fillDaysData.daySelected				=	data.daySelected.scheduler;
        if(this.pageInfo.fillDaysData.dirtyDays.length > 0 && !this.storageCtrl.getItem('answeredDialogPeriod')){
            this.pageInfo.displayDialog = true;
        }else{
            data.exec.editMode  					=   false;
            data.daySelected.scheduler[findedExec] 	= 	data.exec;
            this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,data.daySelected.scheduler[findedExec]);
            // switch(data.action){
            //     case 'paste'    :   this.commons.generateToast('_SUCCESS','_EXECS_PASTED','success'); break;
            //     case 'delete'   :   this.commons.generateToast('_SUCCESS','_EXECS_DELETED','success'); break;   
            // }
            
        }
    }
    getPeriodSelectedOffers(){
		if (!this.schedule) return null;
		return this.schedule.calendar.schedule.periods.find(el => el.selected) || null;
    }
    
    getInfoFillCalendarDays(dayId){
        const currentPeriod = this.getPeriodSelectedOffers();
		if(!currentPeriod) return null;

		const extendedMoment 	= 	extendMoment(moment);
		const rangeDays			=	Array.from(extendedMoment().range(currentPeriod.start, currentPeriod.end).by('days'));
      
        const filteredDays		=	rangeDays.filter(day => day.day() == (dayId == 6 ? 0 : dayId+1));

        this.pageInfo.dirtyDays  =   [];
        filteredDays.forEach(day =>{
            let foundDay = this.schedule.calendar.schedule.days.find(el =>(el.day &&  (moment(day).format() == moment(el.day).format())) );
            if(foundDay && (foundDay.scheduler.some(el => el.dirty  === true) || (foundDay.period_owner == null))){this.pageInfo.dirtyDays.push(foundDay);}
        });
        this.pageInfo.fillDaysData = {
			dayId			:	dayId,
            currentPeriod   :   currentPeriod,
            filteredDays    :   filteredDays,
            dirtyDays       :   this.pageInfo.dirtyDays
        } 
    }
    deleteExecTour(data){
        console.log('ON DELETE', data);
        data.daySelected.scheduler.splice(data.daySelected.scheduler.findIndex(el => el.id == data.exec.id),1);
        this.fillCalendarDaysSchedule(data.daySelected.day, data.daySelected,'delete');

    }
    restartExecTourSchedule(data){
        // data.daySelected.scheduler = [];
        let tmpDaySelected          =   JSON.parse(JSON.stringify(data.daySelected));
        tmpDaySelected.scheduler    =   [];
        this.fillCalendarDaysSchedule(data.daySelected.day, tmpDaySelected);
    }
    fillCalendarDaysSchedule(dayId, data,action?){
        const currentPeriod = this.getPeriodSelectedSchedule();
		if(!currentPeriod) return null;

		const extendedMoment 	    = 	extendMoment(moment);
		const rangeDays			    =	Array.from(extendedMoment().range(currentPeriod.start, currentPeriod.end).by('days'));
		
		const filteredDays		    =	rangeDays.filter(day => day.day() == (dayId == 6 ? 0 : dayId+1));
        this.pageInfo.dirtyDays     =   [];
        this.pageInfo.fillDaysData  =   {
			dayId			:	dayId,
            currentPeriod   :   currentPeriod,
            filteredDays    :   filteredDays,
            data            :   data,
            dirtyDays       :   this.pageInfo.dirtyDays,
            action          :   action
        }
        // execTemplateSelected    :   data.daySelected.scheduler[findedExec];

        filteredDays.forEach(day =>{
            let foundDay = this.schedule.calendar.schedule.days.find(el =>(el.day &&  (moment(day).format() == moment(el.day).format())) );
			if(foundDay){
				if(foundDay.scheduler.some(el => el.dirty === true) || (foundDay.period_owner == null)){this.pageInfo.dirtyDays.push(foundDay);}
			}
        });
        if(this.pageInfo.dirtyDays.length > 0 && !this.storageCtrl.getItem('answeredDialogPeriod')){
            this.pageInfo.displayDialog = true;

        }else{
            this.fillSelectedDays(currentPeriod,filteredDays,data);
            this.updateScheduleFromTemplate();
        }
		console.log(' FIRST UPDATE DAYS!!', this.schedule)
    }
    fillSelectedDays(currentPeriod,filteredDays,data){
        filteredDays.forEach(day =>{
			const foundDay	=	this.schedule.calendar.schedule.days.find(el => el.day &&  moment(day).format() == moment(el.day).format());
			if (foundDay){
                if(data.scheduler && data.scheduler.length == 0){
                    if(this.pageInfo.fillDaysData.data && this.pageInfo.fillDaysData.data.execsToDelete){
                        foundDay.scheduler = foundDay.scheduler.filter(el => !this.pageInfo.fillDaysData.data.execsToDelete.some(exec => exec.start_hour == el.start_hour));
                    }else{
                        foundDay.scheduler = [];
                    }
                }else{
                    const foundExec	=	foundDay.scheduler.findIndex(el => ((el.id == data.id) || (el.start_hour == data.start_hour)));
                    if(foundExec > -1 ){
                        let tmpExec                         =   JSON.parse(JSON.stringify(foundDay.scheduler[foundExec]));   
                        foundDay.scheduler[foundExec]       =   JSON.parse(JSON.stringify(data));
                        foundDay.scheduler[foundExec].dirty =   tmpExec['dirty'];
					}
					if(data.scheduler){
						if (foundDay.scheduler.length > data.scheduler.length){
							if(foundDay.scheduler.some(el => el['dirty'])){
								foundDay.scheduler = data.scheduler;
								foundDay.scheduler = foundDay.scheduler.map(el =>{ return {...el,dirty : true}})
							}else{
								foundDay.scheduler = data.scheduler;
							}
						}
					}
                    else{
						foundDay.scheduler = [];
                        foundDay.scheduler.push(data);
                    }
                }
			}else{
				this.schedule.calendar.schedule.days.push(
					{	day 			: day.format(), 
						period_owner 	: {id: currentPeriod.id, start : currentPeriod.start, end : currentPeriod.end},
						scheduler		: [data]
					});
			}
        });
        this.schedule.calendar.schedule.days =   this.schedule.calendar.schedule.days.filter(el => el.scheduler.length > 0);

	}
	cancelUpdatePeriodDialog(){

        if(this.pageInfo.fillDaysData.daySelected){
            this.pageInfo.fillDaysData.daySelected.splice(this.pageInfo.fillDaysData.daySelected.findIndex(el => el.id == this.pageInfo.fillDaysData.data.id),1);
        }		                     

		this.pageInfo.displayDialog=false;
	}
    fillAllDaysPeriod(){
        
		if(!this.pageInfo.fillDaysData.action){
            this.updateScheduleFromTemplate();
        }
        this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,this.pageInfo.fillDaysData.filteredDays,this.pageInfo.fillDaysData.data);
		
		this.pageInfo.displayDialog = false;
        this.commons.generateToast('_INFO','_ITEMS_UPDATED','info');
        this.pageInfo.fillDaysData 	= null;
    }
    fillNoCustomizedDays(){
        
		if(!this.pageInfo.fillDaysData.action){
            this.updateScheduleFromTemplate();
        }

		let filteredDays	=	this.pageInfo.fillDaysData.filteredDays.filter(day => !this.pageInfo.dirtyDays.some(el => el.day == moment(day).format()))
		this.fillSelectedDays(this.pageInfo.fillDaysData.currentPeriod,filteredDays,this.pageInfo.fillDaysData.data);
		
		this.pageInfo.displayDialog = false;
        this.commons.generateToast('_INFO','_ITEMS_UPDATED','info');
        this.pageInfo.fillDaysData 	= null;
	}
	updateScheduleFromTemplate(){
        this.pageInfo.fillDaysData.data.editMode = false;
        let foundPeriod = 	this.schedule.calendar.schedule.periods.findIndex(el => el.id == this.pageInfo.fillDaysData.currentPeriod.id);
		let foundDay	=	this.schedule.calendar.schedule.periods[foundPeriod].template.findIndex(template => template.day == this.pageInfo.fillDaysData.dayId);
        if(!this.pageInfo.fillDaysData.data || (this.pageInfo.fillDaysData.data.scheduler && this.pageInfo.fillDaysData.data.scheduler.length == 0)){ // previously execTemplateSelected 
            this.schedule.calendar.schedule.periods[foundPeriod].template[foundDay].scheduler = [];
        }else{
            let foundExec	=	this.schedule.calendar.schedule.periods[foundPeriod].template[foundDay].scheduler.findIndex(offer => offer.id == this.pageInfo.fillDaysData.data.id);
            this.schedule.calendar.schedule.periods[foundPeriod].template[foundDay].scheduler[foundExec] = this.pageInfo.fillDaysData.data;
        }

	}

	/** START CALENDAR METHODS */
	clickedDateSchedule($event, value : any, fromContextMenu?)
	{
		if($event && ($event.shiftKey || $event.ctrlKey))
		{
			switch(true){
				case $event.shiftKey===true :
							if(	!this.pageInfo.calSingleDaySelected || 
								!moment(this.pageInfo.calSingleDaySelected.day).isSameOrBefore(moment(value.date))
							){ return; }
							const extendedMoment 	= 	extendMoment(moment);
							const rangeDays			=	Array.from(extendedMoment().range(moment(this.pageInfo.calSingleDaySelected.day), moment(value.date)).by('days')).map(el => el.toDate());
							this.markRankDaysSchedule(false);
							this.markRankDaysSchedule(true,rangeDays);
							break;

				case $event.ctrlKey===true :
							const foundDay 		    = this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(value.date));
							if(!foundDay){ return; }
							// foundDay.rangeMode 	    = foundDay.rangeMode ? !foundDay.rangeMode : false;
							foundDay.rangeMode 	    = !foundDay.rangeMode;
							break;

				default: 	return;
			}
		} 
		else {
            // this.restartExecsSelecteds();
            if(fromContextMenu)	{

                this.monthViewCalendar.view.days.map(el => el.selected = false);
                const foundDay 		= this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(value.date));
                if(!foundDay){ return; }
				foundDay.selected 	= true;
				
            } else 				{

				this.markRankDaysSchedule(false);
				const tmp    		= value.selected ? !value.selected : true;
				this.monthViewCalendar.view.days.map(el => el.selected = false);
				value.selected 		= tmp;
				if(value.selected){
					const dayInfo   = this.getInfoDayCalendarSchedule(value.date);				
					this.pageInfo.calSingleDaySelected 	= isArray(dayInfo) 
															? {day:moment(value.date).format(), scheduler:[]}
															: JSON.parse(JSON.stringify(dayInfo));
					this.pageInfo.showPriceSidebar = this.pageInfo.calSingleDaySelected!=null;		
				} else 				{
					this.pageInfo.calSingleDaySelected	= null;
				}    
        	}    
		}
    }
    
    markRankDaysSchedule(mode,days?){
        if(days){
            days.forEach(day => {
                const foundDay 		= this.monthViewCalendar.view.days.find(el => moment(el.date).isSame(day));
                if(!foundDay){ return; }
                foundDay.rangeMode  =   mode;
            });
        }else{
            this.monthViewCalendar.view.days.map(el => el.rangeMode = mode);
        }
    }

	async handleCalSelectedDaySchedule($event){
        switch($event.type){
            case 'update' : await this.updateCalExecTourSchedule($event.data); 	break;
            case 'delete' :	this.deleteCalExecTourSchedule($event.data); 		break;
            case 'add'    : await this.addCalExecTourSchedule($event.data);    	break;
            case 'lastRow': this.addItemLastRow($event.data);					break;
        }
        this.updateViewCalendar();
	}
	
    async updateCalExecTourSchedule(data){
        data.dirty         =   true;
        const dayCalendar   =   this.getInfoDayCalendarSchedule(this.pageInfo.calSingleDaySelected.day);
        if(isArray(dayCalendar)){
            this.schedule.calendar.schedule.days.push({	
													day         : this.pageInfo.calSingleDaySelected.day, 
													scheduler   : [data.exec],
													period_owner: null
												});
            this.pageInfo.calSingleDaySelected = JSON.parse(JSON.stringify(this.schedule.calendar.schedule.days[this.schedule.calendar.schedule.days.length - 1]));
        } else {
            const findExec      =   dayCalendar.scheduler.findIndex(el => el.id == data.exec.id);
			if(findExec > -1)	{ dayCalendar.scheduler[findExec] = data.exec; 	}
			else				{ dayCalendar.scheduler.push(data.exec); 		}
            this.pageInfo.calSingleDaySelected  = JSON.parse(JSON.stringify(dayCalendar));
        }
        data.exec.editMode = false;

        
    }
     deleteCalExecTourSchedule(data){
        if(data.exec.dirty){
            this.confirmationService.confirm({
                message : 	'_CONFIRM_DAY_WITH_CUSTOM_EXEC',
                header	:	'_DELETE_OFFER',
                icon	:	'pi pi-exclamation-triangle',
                accept	:	()=>{
                    const dayCalendar   =   this.getInfoDayCalendarSchedule(this.pageInfo.calSingleDaySelected.day);
                    if(isArray(dayCalendar)) return this.commons.generateToast('_ERROR', '_BAD_DELETE_EXEC', 'error'); 
            
                    const findExec  =   dayCalendar.scheduler.findIndex(el => el.id == data.exec.id);
                    if (findExec > -1){
                        dayCalendar.scheduler.splice(findExec,1);
                        this.pageInfo.calSingleDaySelected  =   JSON.parse(JSON.stringify(dayCalendar));
                    }   
                }
            })
        }else{
            const dayCalendar   =   this.getInfoDayCalendarSchedule(this.pageInfo.calSingleDaySelected.day);
            if(isArray(dayCalendar)) return this.commons.generateToast('_ERROR', '_BAD_DELETE_EXEC', 'error'); 
    
            const findExec  =   dayCalendar.scheduler.findIndex(el => el.id == data.exec.id);
            if (findExec > -1){
                dayCalendar.scheduler.splice(findExec,1);
                this.pageInfo.calSingleDaySelected  =   JSON.parse(JSON.stringify(dayCalendar));
            }
        }
        
        
    }

 
    addCalExecTourSchedule(data){
        this.pageInfo.calSingleDaySelected.scheduler.push(
            {	id          : this.commons.generateHash(),
                start_hour	: '00:00', 
                prices		: [{
                    type	: 'adult',
                    price	:	null
                },{
                    type	: 'children',
                    price	:	null
                },{
                    type	: 'infant',
                    price	:	null
                }
                ]
            }    
        );
    }
	getTitleCalendar(){
		return moment(this.pageInfo.viewDate).locale(this.commons.getActiveLang()).format('MMMM, YYYY');
	}
	changeMonth(type){
		console.log(type);
		switch(type){
			case 'previous' : this.previousMonth(); break;
			case 'next'		: this.nextMonth();		break;
        }
        this.updateViewCalendar();
	}
    previousMonth(){
        this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() - 30));
        console.log(this.pageInfo.viewDate);

    }
    nextMonth(){
        this.pageInfo.viewDate = new Date(this.pageInfo.viewDate.setDate(this.pageInfo.viewDate.getDate() + 30));
        console.log(this.pageInfo.viewDate);

    }
    getFormatDateHour(date){
        return this.commons.getFormatDateHour(date);
    }
    dayHasExecsSchedule(day){
		const dayFound = this.schedule.calendar.schedule.days.find(el => moment(el.day).format() == moment(day.date).format());
		if(!dayFound) return false;
		return dayFound.scheduler && dayFound.scheduler.length > 0;
	}
    getInfoDayCalendarSchedule(day){
		const foundDay = this.schedule.calendar.schedule.days.find(el => 
            moment((moment(el.day).format())).isSame(moment((moment(day).format()))));
        if ( !foundDay || !foundDay.scheduler ) return [];
        // console.log('this day has execs', day, foundDay);
        return foundDay;
    }

    onCalRightClickDaySchedule($event, contextMenu, day){
        //MIRAR SI TENIM EXECS SELECTEDS I ELIMINARLES, JA QUE SE SOBRESCRIURAN 
		console.log('DAY CLICKED', day);
		this.pageInfo.displayContextMenuOptions = [];
		const getDaysRangeMode                  =   this.monthViewCalendar.view.days.filter(el => el.rangeMode);
		if(getDaysRangeMode.length > 1){
			let someExec	=	getDaysRangeMode.some(element => {
				let foundDay	=	this.schedule.calendar.schedule.days.find(el => moment(el.day).isSame(moment(element.date)));
				if(!foundDay) return false;
				return ((foundDay.scheduler) && (foundDay.scheduler.length)) > 0;
			});
			
			if(someExec && (this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.execsSelecteds.execs.length > 0)){
				this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => (el.id == 'delete_multiple') || (el.id == 'paste_overwrite') || (el.id == 'paste_merge'));
				this.setContextDayLabel('paste_overwrite','_OVERWRITE');
				this.setContextDayLabel('paste_merge','_MERGE')
			}else if(!someExec && (this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.execsSelecteds.execs.length > 0)){
				this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id == 'paste_overwrite');
				this.setContextDayLabel('paste_overwrite','_PASTE');
			}else if(someExec){
				this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => el.id == 'delete_multiple');
				this.setContextDayLabel('delete_multiple','_DELETE_DAYS')
			}
		}else{
			if(day.infoDay){
				if(this.pageInfo.contextMenu.dayCopied && this.pageInfo.contextMenu.dayCopied.day && this.pageInfo.contextMenu.dayCopied.day != null){
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions;
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple');
					this.setContextDayLabel('paste_overwrite','_OVERWRITE');
					this.setContextDayLabel('paste_merge','_MERGE');
					// this.pageInfo.displayContextMenuOptions.find(el => el.id == 'paste_overwrite').label 	= this.commons.getTranslate('_OVERWRITE_DAY');
					// this.pageInfo.displayContextMenuOptions.find(el => el.id == 'paste_merge').label 		= this.commons.getTranslate('_OVERWRITE_DAY');
				}else if(this.pageInfo.contextMenuExecs.execs.length > 0){
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions;
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.displayContextMenuOptions.filter(el => el.id != 'delete_multiple');
					this.setContextDayLabel('paste_overwrite','_OVERWRITE_HOURS');
					this.setContextDayLabel('paste_merge','_MERGE_HOURS');
					// this.pageInfo.displayContextMenuOptions.find(el => el.id == 'paste').label = this.commons.getTranslate('_PASTE_HOUR');
				}else{
				   this.pageInfo.displayContextMenuOptions = this.pageInfo.contextMenuOptions.filter(el => el.id == 'copy' || el.id == 'delete'); 
				}
			}else{
				if(this.pageInfo.contextMenu.dayCopied.day || this.pageInfo.contextMenuExecs.execs.length > 0){
					this.pageInfo.displayContextMenuOptions	= this.pageInfo.contextMenuOptions.filter(el => el.id == 'paste_overwrite');
					this.setContextDayLabel('paste_overwrite','_PASTE');
				}
			}
			
			const dayFound  						=   this.getInfoDayCalendarSchedule(day.date);
			const emptyDay 							= 
				{
					day 			: 	moment(day.date).format(),
					period_owner    :   null,
					scheduler		:	[]
				};
			this.pageInfo.contextMenu.rightClick	=	isArray(dayFound) ? emptyDay : dayFound;
		}
        
        contextMenu.show($event);$event.stopPropagation()
        // return false;
    }

    selectExecDay($event,exec,daySelected,execsDay){
        this.restartDayCopied();
        if(this.pageInfo.execsSelecteds.day && this.pageInfo.execsSelecteds.day != daySelected.day){
            this.restartExecsSelecteds();
		};
		exec.prices	=	exec.prices ? exec.prices : this.displayMainPriceDaySchedule(execsDay);
        this.pageInfo.execsSelecteds.day    =   daySelected.day;
        if($event.ctrlKey){
            let foundExec   =   this.pageInfo.execsSelecteds.execs.findIndex(item => item.start_hour == exec.start_hour);
            foundExec       >   -1 ? this.pageInfo.execsSelecteds.execs.splice(foundExec,1) : this.pageInfo.execsSelecteds.execs.push(exec);    
        }else{
			if((this.pageInfo.execsSelecteds.execs.length == 1) && (this.pageInfo.execsSelecteds.execs[0].start_hour == exec.start_hour)){
				this.pageInfo.execsSelecteds.execs    =   [];
				this.restartDaysConfig();
			}else{
				this.pageInfo.execsSelecteds.execs    =   [];
				this.pageInfo.execsSelecteds.execs.push(exec);
			}
            
        }
        console.log(exec,daySelected);
        $event.stopPropagation();
    }
    
    restartExecsSelecteds(){
        this.pageInfo.execsSelecteds = {
            day     :   null,
            execs   :   []
        }
    }
    onRightClickExec($event, contextMenuExec){
        let execsSelecteds  =   this.pageInfo.execsSelecteds.execs; 
        this.pageInfo.displayContextMenuOptionsExecs    =   [];

        if(execsSelecteds.length > 0){
            this.pageInfo.displayContextMenuOptionsExecs   =   this.pageInfo.contextMenuOptionsExec;
            if(execsSelecteds.length > 1){
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'copy').label      =   this.commons.getTranslate('_COPY_HOURS');
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'delete').label    =   this.commons.getTranslate('_DELETE_HOURS');
            }else{
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'copy').label 		= 	this.commons.getTranslate('_COPY_HOUR');
                this.pageInfo.displayContextMenuOptionsExecs.find(el => el.id == 'delete').label    =   this.commons.getTranslate('_DELETE_HOUR');
            }
        }else{
            this.pageInfo.displayContextMenuOptionsExecs    =   [];
            this.commons.generateToast('_INFO','_SELECT_EXEC_TO_COPY','info');
        }
        contextMenuExec.show($event); $event.stopPropagation();
    }
    getDistributedHoursSchedule(info, turn){
        const dayInfo   =   info;
        if(dayInfo.scheduler.length == 0) return [];
        const mostAppear    =   this.getMostUsedPrice(dayInfo.scheduler);
        const mountObject   =   dayInfo.scheduler.map(el =>{
            return {
				start_hour  :   el.start_hour,
				id			:	el.id,
                prices      :   mostAppear == null ? el.prices : JSON.stringify(el.prices) == mostAppear.val  ? null : el.prices
            }
        });
        switch(turn){
            case 0 :    return mountObject.filter(el=> el.start_hour <= '12:00').sort((a,b)=> {return a.start_hour > b.start_hour ? 1 : -1});
			case 1 :    return mountObject.filter(el=> el.start_hour >  '12:00').sort((a,b)=> {return a.start_hour > b.start_hour ? 1 : -1});
        }
    }

    displayMainPriceDaySchedule(info){
        const dayInfo   =  info;
        if(Array.isArray(dayInfo) || dayInfo.scheduler.length == 0) return [];
        const mostAppear=  this.getMostUsedPrice(dayInfo.scheduler);
        return (mostAppear!==null?JSON.parse(mostAppear.val):[]).map(item=>{ 
			switch(item.type){
				case "adult"	: item.icon="fa fa-male"; 	break;
				case "children"	: item.icon="fa fa-child"; 	break;
				case "infant"	: item.icon="fa fa-child";	break;
			}
			return item;
		});
    }

    getMostUsedPrice(scheduler){
        const prices        =   scheduler.map(el => JSON.stringify(el.prices));
        const appears       =   prices.map(el =>({ val: el, count: prices.filter(eachItem => eachItem == el).length }));
        const mostPriceUser     =  Math.max.apply(Math, appears.map(item =>  item.count));
        const thereIsUnique     =  appears.filter(el => el.count == mostPriceUser).length == mostPriceUser;
        return thereIsUnique    ?  appears.find(el => el.count == mostPriceUser) : null;
	}
    
    getDayBackgroundColorSchedule(day){
        if(day.rangeMode)   return this.pageInfo.templateColors.isRangeMode;
		if(day.selected)    return this.pageInfo.templateColors.isDaySelected;
        const indexPeriod   =   this.schedule.calendar.schedule.periods.findIndex(el => {
            return (moment(day.date).isBetween(moment(el.start), moment(el.end),null,'[]'));
        });
        if(indexPeriod > -1){ return this.pageInfo.colorPeriods[indexPeriod]; 	}
        if(day.isPast)    	{ return this.pageInfo.templateColors.isPast;		}
        if(day.isToday)     { return this.pageInfo.templateColors.isToday;		}
        if(day.isWeekend)   { return this.pageInfo.templateColors.isWeekend;	}
    }
    getRibbonBackgroundColorSchedule(day,exec){
        if(!day.infoDay) return this.pageInfo.templateColors.hasPeriod;
        if(this.pageInfo.execsSelecteds.day == day.infoDay.day){
            if(this.pageInfo.execsSelecteds.execs.find(el => el.start_hour == exec.start_hour)){
                return (this.pageInfo.contextMenuExecs.execs.length >0 || this.pageInfo.contextMenu.dayCopied.day ) ? this.pageInfo.templateColors.isCopied : this.pageInfo.templateColors.isSelected;
            }
        }
        return day.infoDay.period_owner == null ? this.pageInfo.templateColors.isCustom : this.pageInfo.templateColors.hasPeriod;
	}
	
	setContextDayLabel(id,label){
		this.pageInfo.displayContextMenuOptions.find(el => el.id == id).label 	= this.commons.getTranslate(label);
	}
}
