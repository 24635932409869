import { AggregatorsService } from './../../../service/database/aggregator.service';
import { EntityService } from 'src/app/demo/service/entity.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { Component, OnInit, ViewEncapsulation, NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { ToastrService                          } from 'ngx-toastr';
import { DOCUMENT } from '@angular/platform-browser';
import { HttpClient } from 'selenium-webdriver/http';
import { CommonsService } from 'src/app/demo/service/commons.service';
import { FirebaseService } from 'src/app/demo/service/database/firebase.service';
import { AuthFirebaseService } from 'src/app/demo/service/database/authfirebase.service';
import { ConfirmationService } from 'primeng/api';

@Component({
    styleUrls		: ['./providers.component.scss'],
	templateUrl		: './providers.component.html',
	encapsulation	: ViewEncapsulation.None,
})


export class ProvidersComponent implements OnInit{
	userInfo    : any =	{};
    form		: any = { cols: [], filters: {} };
	providers 	: any = {};
	pageInfo	: any = {
		buttonEditRow       : false,
        buttonRemoveRow     : true,
        buttonCancelRow     : true,
		validations : {
			staff : [
					{
						field       : 'email',
						validation  : this.isEmail,
						msgError    : 'The email is badly formatter'
					}
			]
		}
	};
	@ViewChild('mainTable') mainTable : any;
	@ViewChild('confirmationRemove') confirmationRemove	:	any;
    constructor( 
        private storage 			:	StorageService,
        private commons				: 	CommonsService,
        private entityService		:	EntityService,
        private firebaseCtrl		:	FirebaseService,
		private authCtrl            :   AuthFirebaseService,
		private aggregatorCtrl		:	AggregatorsService,
        private confirmationService	: 	ConfirmationService   ){

            this.loadEntities();
        }

        ngOnInit(){
			this.init();
		}
		
		init(){
			this.providers.cols = [
				{ field: 'name',     	header: 'Name', 		width: '70px', 		align: "center",    editor : 'input', 		editable:true, 	options : {type : 'text'}, 		mandatory : true 	},
				{ field: 'account',    	header: 'Account',  	width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'text'},		mandatory : true		},
				{ field: 'password',    header: 'Password',  	width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'password'},	mandatory : true		},
				// { field: 'destination',	header: 'Destination',  width: '100px',		align: "center",    editor : 'multiselect', editable:true,  options : {suggestions : 'destinations'},mandatory : true 	},
				// { field: 'address',	    header: 'Address',  	width: '200px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false 	},
				{ field: 'contact_phone',	    header: 'Contact Phone',  		width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'text'},mandatory : false		},
				{ field: 'contact_email',	    header: 'Contact email',  	    width: '70px', 		align: "center",    editor : 'input',  		editable:false,	options : {type : 'text'},mandatory : true 	}            
			];
			this.providers.form	= [
				{ field: 'name',     	label: 'Name', 		width: '70px', 		align: "center",    editor : 'input', 		editable:true, 	options : {type : 'text'}, 		mandatory : true 	},
				{ field: 'account',    	label: 'Account',  	width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'text'},		mandatory : true		},
				{ field: 'password',    label: 'Password',  width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'password'},	mandatory : true		},
				{ field: 'api_key',    	label: 'Api Key',  	width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'text'},		mandatory : false		},				
				// { field: 'destination',	header: 'Destination',  width: '100px',		align: "center",    editor : 'multiselect', editable:true,  options : {suggestions : 'destinations'},mandatory : true 	},
				// { field: 'address',	    header: 'Address',  	width: '200px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false 	},
				{ field: 'contact_phone',label: 'Contact Phone',  	width: '100px', 	align: "center",    editor : 'input',		editable:true,  options : {type : 'text'},mandatory : false		},
				{ field: 'contact_email',label: 'Contact Email',  	width: '70px', 		align: "center",    editor : 'input',  		editable:true,	options : {type : 'text'},mandatory : true 	}            
			];	
			this.generateMenuCols('providers');

		}
    
        async loadEntities() {
            // await this.load('destinations');    
            this.userInfo	=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
            await this.load('providers');
        }

        generateMenuCols($entity) {
			this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
			console.log(this[$entity].selectedColumns);
        }
    
        paginate($type, $event) {
            // First, rows, page, pageCount
            const length = !this[$type].data ? 0 : this[$type].data.length;
            this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
            this[$type].paginate = !this[$type].data
                ? []
                : this[$type].data.slice(
                    this[$type].paginateInfo.first,
                    this[$type].paginateInfo.first + this[$type].paginateInfo.rows
                );
        }

        async load($entity) {
            switch ($entity) {
                case 'providers':
                    this[$entity].data  = [];				
                    // this[$entity].data	= this.entityService.get($entity);
                    // this.firebaseCtrl.getStaffFromDmc(this.userInfo.dmcId || '1').subscribe(users => {
                    //     this[$entity].data  = [];				
                    //     users.forEach(user =>{
                    //         this[$entity].data.push(user);
                    //     });
                    //     this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
                    //     this.paginate($entity, null);
                    //     console.log('PROVIDERS',this[$entity].data);
                    //     // this[$entity].data  =   
					// })
					this.aggregatorCtrl.getProvidersFromDmc(this.userInfo.dmcId || '1').subscribe(providers =>{
						this[$entity].data	=	[];
						providers.forEach(provider => { this[$entity].data.push(provider); });
						this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
						console.log('PROVIDERS', this[$entity].data);
						this.paginate($entity, null);
					});
					// this[$entity].data.push({
					// 	name 			: 'Hoppa',
					// 	contactEmail	: 'hoppa.com',
					// 	account			: 'touriniaHoppa',
					// 	password		: 'password123',
					// 	phone			: '123',
					// 	isNew			: true
					// });
                break;
                
                case 'destinations':	
                break;
                case 'profiles':
                break;
            }
            // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });
    
		}
		getRendererType($type) {
			switch ($type) {
				case 'adapted':
					return (type, col, items) => this.adaptedRenderer(this, type, col, items);
			}
		}
	
		getRenderer($type, $col, $items) {
			return $col.renderer
						// ? $col.renderer($type, $col, $items)
						? this.getRendererType($col.renderer)($type, $col, $items)
						: this.defaultRenderer($type, $col, $items);
		}
	
		defaultRenderer($type, $col, $items) {
			switch ($type) {
				case 'header'	: return this.defaultStyleRenderer($col);
				case 'style'	: return this.defaultStyleRenderer($col);
				case 'content'	: switch($col.field) {
													case 'profile'          : return $items[$col.field];
													case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
													default 		 		: return $items[$col.field]}
			}
		}
	
		defaultStyleRenderer($col) {
			return {
				'width'			: $col.width ? $col.width : '',
				'text-align'	: $col.align ? $col.align : ''
			};
		}
	
		adaptedRenderer($me, $type, $col, $items) {
			switch ($type) {
				case 'header'	: return this.defaultStyleRenderer($col);
				case 'style'	: return {
					'width'			: $col.width         ? $col.width   : '',
					'text-align'	: $col.align         ? $col.align   : '',
					'color'			: $items[$col.field] ? 'green'      : 'red',
					'fontWeight'	: '900',
					'fontSize'		: '1rem'
				};
				case 'expanded'	: return {
					'width'			: $col.width         ? $col.width   : '',
					'color'			: $items[$col.field] ? 'green'      : 'red',
					'fontWeight'	: '900',
					'fontSize'		: '1rem'
				};
				case 'content'	: return $items[$col.field]?'V':'X';
			}
		}

		async addNewProvider($event){
			let newId = this.commons.generateHash(Math.random().toString(36).replace(/[^a-z]+/g, '') );
			this[$event].data.unshift({id: newId, isNew : true});
			this.paginate($event,null);
			this.mainTable["expandedRowKeys"][newId] = 1;
			this.makeColEditable($event,true,this[$event].data.find(el=> el.id == newId));
	
			await this.commons.generateToast('_ELEMENT_CREATED','_FILL_INFO','info');
		}
	
		makeColEditable($entity,value,rowData){
			try{
				this[$entity].cols.map(el => el.editable = value);
				this.pageInfo.tempRow 		= JSON.stringify(rowData);
				this.pageInfo.buttonEditRow = value ? true : false;
			}catch(e){
				this.pageInfo.tempRow = null;
			}
			
		}

		async saveRow($entity,rowData){
			try{
				// let validation = this.validateInfo($entity,rowData);
				let validation = this.validateInfo($entity,rowData);
				if(validation.success && rowData.isNew){
					//****** UPDATE/CREATE ROW *********** API PUT / POST rowData
					if(rowData.isNew){ delete rowData.isNew}
					let success	=	await this.aggregatorCtrl.addProviderToDmc(rowData,this.userInfo.dmcId || '1' );
					// let success = await this.firebaseCtrl.addUserToStaffDmc(rowData,createdUser,this.userInfo.dmcId || '1');        
					this.makeColEditable($entity,false,rowData);
					await this.commons.generateToast('_SUCCESS','_ELEMENT_CREATED','success',2);
					this.mainTable.expandedRowKeys = {};
				}else{
					let success =	await this.aggregatorCtrl.updateProviderToDmc(rowData, this.userInfo.dmcId || '1');
					this.commons.generateToast('_SUCCESS', '_ELEMENT_UPDATED','success');
				}
			}
			catch(e){
				console.error(e);
				await this.commons.generateToast('ERROR','_ERROR','error',2);    
			}
		}
	
		async removeRow($entity,rowData){
			console.log('removing');
			this.confirmationService.confirm({
				message: 	await this.commons.getTranslate('_MESSAGE_CONFIRM_REMOVE_PROVIDER'),
				header: 	await this.commons.getTranslate('_REMOVE_PROVIDER'),
				accept: async () => { 
					let findedRow = this[$entity].data.findIndex(el => el.id == rowData.id);
	
					if(findedRow > -1){
						//****** DELETE ROW *********** API REMOVE
						await this.aggregatorCtrl.removeProviderFromDmc(rowData,this.userInfo.dmcId || '1');
						await this.commons.generateToast('_SUCCESS','_ELEMENT_REMOVED','success',2);
						
					}else{
						this.commons.generateToast('Error','Could not remove the item','error',2);
					}
			
					this.pageInfo.buttonEditRow = false;
					
				},
				reject: () =>{ console.log('rejected')
								console.log(this.confirmationRemove)}
			});
				}

		validateInfo($entity,rowData){
			let specificValidations =  this.pageInfo.validations[$entity]   ? this.pageInfo.validations[$entity].find(el => !el['validation'](this[$entity].data,rowData))
																			: null;
	
			if( specificValidations ){
				return { success    	: false,
							msg        	: specificValidations.msgError
				}
			}else{
				let mandatoryFields = this[$entity].cols.filter(el => el.mandatory)
														.filter(mandatoryElement =>     (rowData[mandatoryElement.field] === undefined)
																					||  (rowData[mandatoryElement.field] === null) 
																					||  (rowData[mandatoryElement.field] === '') );
				if( mandatoryFields.length > 0){
	
					return {  
							success : false,
							msg     : mandatoryFields.length == 1   ? 'Field '+ mandatoryFields[0].field +' is mandatory'
																	: 'Fields '+ mandatoryFields.map(el => el.field) +' are mandatory'
							}
				}else{
					return {
							success :true
					}
				}
			}
		}
		isEmail(items, dataRow : any){ return (dataRow.email.includes("@")) && (dataRow.email.includes("."))     }


}