import { SuperformService 		} from './../../../demo/service/super-form/superform.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation,		
		 ViewChild				} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { entities 				} from './data/items';

@Component({
  selector    		: 'app-company-info',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyInfoComponent {
	@Input("current")   		currentProvider	:	any	= {};
	@Input("form")				form			:	any;  
	@Output()					emitter			= new EventEmitter<any>();	
	@ViewChild('companyForm')	companyForm		: any;

	pageInfo  : any = {
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: {},
		entity			: { form: { ready: false }, table: { ready: false }}
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService,
		private superFormCtrl	: SuperformService
  	){}

	async init()				{
		String.prototype["capitalize"]	= function(){ return this.charAt(0).toUpperCase()+this.slice(1); }
		
		this.pageInfo.today 			= new Date();
		this.pageInfo.uploadedFiles		= [];
		this.pageInfo.buttons			= buttons;
		this.pageInfo.filters			= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		

		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});
		
		await this.loadEntities();
		console.log(this.form);

		// INFO
		this.pageInfo.entity.form			=	this.form ? this.form :{ ready: false, entities: this.pageInfo.entities };
		this.pageInfo.entity.form.entities	=	this.pageInfo.entities;
		this.superFormCtrl.prepareToFrontend(this.pageInfo.entity.form);
		this.pageInfo.entity.form.ready		=	true;
	}

	async loadEntities()		{	
									// await this.load("main");
									await this.load("countryList");
									await this.load("activityList");
									await this.load("companyTypeList");
									await this.load("langList");
								}
  	async ngOnInit()			{	//await this.loadEntities();
									await this.init();
									// await this.loadEntities();		
								}
	onUpload(event) 	{
		for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
		this.commons.generateToast('File Uploaded','','info');
	}
	action($type,$item)	{
		switch($type){
			case "button"			:	switch($item.name){		
											case "reload"		: 	this.loadEntities(); 												break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;
											case "import"		: 	this.commons.generateToast("Button pressed","Import","info");		break;
										}
										break;
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "main": 	return (this.pageInfo.entity.data||[]).filter(item=>{
									return true;
									// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
									// item.view 		= isSelected;
									// item.color		= "red";
									// return isSelected;
								});
		}
	}
	async load($entity){
		let response;
		if(this.pageInfo.entities[$entity] && this.pageInfo.entities[$entity].data){ return; }
		switch($entity){
			case 'main' 			:	response 								= 	await Promise.resolve(this.entityService.getComplexRequest("companyInfo",{ id: 1 },{}));
										this.pageInfo.entity.form.info			= 	response["data"] || [];
										this.pageInfo.entity.form.ready			=	true;		
										break;

			case "countryList"		:	
										response								=	await Promise.resolve(this.entityService.getRequest("countries",{}));
										this.pageInfo.entities[$entity]			=   ( response["data"] || [] ).map(item=>{
																						// item.name 	= item.name.toLowerCase();
																						item.label		= item.name.capitalize();
																						return item;
																					});	
										break;

			case "activityList"		:	this.pageInfo.entities[$entity]			=   [	
																						{	value : 'transporter', 
																							label : await this.commons.getTranslate('_TRANSPORTER')
																						},
																						{	value : 'dmc',
																							label : await this.commons.getTranslate('_DMC') 	
																						}
																					];
			break;

			case "companyTypeList"	:	this.pageInfo.entities[$entity]			=   [	
																						{	value : 'transporter', 
																							label : await this.commons.getTranslate('_TRANSPORTER')
																						},
																						{	value : 'dmc',
																							label : await this.commons.getTranslate('_DMC') 	
																						}
																					];
			break;

			case "langList"			:	response								=   await Promise.resolve(this.entityService.getRequest("countries",{}));
										this.pageInfo.entities[$entity]			=	[{ id:0, name: 'es', 	label: '_SPANISH' 	},{ id:1, name: 'en', 	label: '_ENGLISH' 	}];
										break;
		}
	}

	checkFields(){
		console.log('VALIDATING FIELDS ON COMPANY INFO');
		if(!this.companyForm){ return false};
		return this.companyForm.validate();
	}
	doAction($event){
		this.emitter.emit($event);
	}
}
