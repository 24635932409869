export const mappedPlates = [
    ["8135-KRM","COCHE 01"],
    ["8139-KRM","COCHE 02"],
    ["8139-KRM","COCHE 03"],
    ["8143-KRM","COCHE 04"],
    ["8144-KRM","COCHE 05"],
    ["8144-KRM","COCHE 06"],
    ["8117-KRM","COCHE 07"],
    ["6761-MDW","COCHE 08"],
    ["6761-MDW","COCHE 09"],
    ["6765-MDW","COCHE 10"],
    ["6768-MDW","COCHE 11"],
    ["6765-MDW","COCHE 12"],
    ["7263-SLK","COCHE 13"],
    ["6797-MDW","COCHE 14"],
    ["6778-MDW","COCHE 15"],
    ["6980-MDW","COCHE 16"],
    ["6789-MDW","COCHE 17"],
    ["6789-MDW","COCHE 18"],
    ["6769-MDW","COCHE 19"],
    ["6770-MDW","COCHE 20"],
    ["6770-MDW","COCHE 21"],
    ["6772-MDW","COCHE 22"],
    ["6775-MDW","COCHE 23"],
    ["6775-MDW","COCHE 24"],
    ["8152-KRM","COCHE 25"],
    ["6779-MDW","COCHE 26"],
    ["6776-MDW","COCHE 27"],
    ["6786-MDW","COCHE 28"],
    ["6793-MDW","COCHE 29"],
    ["6786-MDW","COCHE 30"],
];