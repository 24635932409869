import { CommonsService } from './../../demo/service/commons.service';
import { FirebaseService} from './../../demo/service/database/firebase.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
		 OnChanges		} from '@angular/core';
import { filters 		} from './data/filters';
import { formData		} from './data/formData';
@Component({
  selector    : 'app-notifications',
  templateUrl : './notifications.component.html',
  styleUrls   : ['./notifications.component.scss'] ,

})

export class NotificationsComponent implements OnInit, OnChanges {
	@Input()  notifications	:	any;	// object with notifications and assistantChats
	@Output() emiterOption	:   EventEmitter<any> = new EventEmitter(); // emiter to mark the current chat expanded
	pageInfo				:	any	=	{};

	constructor(private firebaseService : FirebaseService, private commons : CommonsService){}

    ngOnChanges(): void {
		console.log('on changes', this.notifications);
	}
	async ngOnInit(){
		await this.initFilters();
		this.initMenu();
		this.initformsNotificationss();
		
		this.pageInfo.blockedIssues;
		console.log('MY NOTIFICATIONS', this.notifications);
	}


	// --------------------------------------------------------------------------------------
	// MENU
	// --------------------------------------------------------------------------------------

	initMenu(){
		let me					= this;
		this.pageInfo.menu		= {};
		Object.keys(this.pageInfo.filters).forEach(async entity=>{
			// Each entity could have many filters
			let entityFilters	= this.pageInfo.filters[entity];
			this.pageInfo.menu[entity]	= [];
			entityFilters.forEach(filter => {
				let item 		= { 
					label		: this.commons.getTranslate(filter.label),
					icon		: filter.icon?('fa fa-'+filter.icon):'',
					style		: { padding: '5px 10px' },
					items		: []
				};
				filter.items.forEach( current=> {
					let element	= {
						label		: 	 this.commons.getTranslate(current.label),
						icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
						command		: 	($event) => { 
											console.log("MENU ITEM SELECTED",$event);
											me.doMenuAction(current.value,filter);
										}
					}
					item["items"].push(element);
				});
				this.pageInfo.menu[entity].push(item);
			});
		});
	}
	/**
	 * Execute Menu item action
	 * @param $item 
	 * @param $filter 
	 */
	doMenuAction($item,$filter){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		// $filter.selected = $filter.selected.some(item=>item===$item)
		// 					?$filter.selected.filter(item=>item!==$item)
		// 					:[...$filter.selected,$item];
		if($filter.selected.some(item=>item===$item)){
			$filter.selected = $filter.selected.filter(item=>item!==$item);
		} else {
			$filter.selected = [...$filter.selected,$item];
		}
		console.log($item,$filter);
		//fer un filtre de tots els selected
		this.initMenu();				// Regenerate menu to show selection
	}
	async initFilters()			{ this.pageInfo.filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		}
	initformsNotificationss()	{
		console.log('NOTIFICATIONS FORMS	',this.pageInfo.formsNotifications);
		this.pageInfo.formResult				=	{}
		this.pageInfo.displayDialogConsult		=	false;
		this.pageInfo.displayDialogIssue		=	false;
		this.pageInfo.formLoading				=	false;
		this.pageInfo.dialogStatus 	= 	[
			{	
				entity	:	'consult',
				options	:	[
					{ 	type 	: 	'_BLOCKED',
						active 	:	false	
					},
					{
						type	:	'_REJECTED',
						active	:	false,
					}
				]
			},
			{
				entity	:	'issue',
				options	:	[
					{	type	:	'_BLOCKED',
						active	:	false,
					},
					{
						type	:	'_REJECTED',
						active	:	false
					}
				]
			}
			
		];
	}

	getItems(){
		return this.notifications;
	}
	getFilteredItems(type){
		if((Object.keys(this.notifications).length == 0) || (!this.notifications && !this.notifications[type]) || !this.pageInfo.filters ){return [];}
		if(this.pageInfo.filters[type][0].selected.some(el => el == '_ALL')){
			return type == 'consult' ? 	this.notifications[type].items.sort((chat1,chat2) =>  this.commons.formatTimestamp(chat2.lastMessage.time) - this.commons.formatTimestamp(chat1.lastMessage.time))
									 :	this.notifications[type].items;	
		}
		let filtereds = (this.notifications[type].items || []).filter(el => this.pageInfo.filters[type][0].selected.some(status => status == el.status))
		return type == 'consult' ? 	filtereds.sort((chat1,chat2) =>  this.commons.formatTimestamp(chat2.lastMessage.time) - this.commons.formatTimestamp(chat1.lastMessage.time))
								 :	filtereds;

	}

	async openIssueChat(item){
		item.loading = true;
		this.addLogStatus(item,'_CHAT_ENABLED',null);
		await this.firebaseService.updateAssistantChat(item.id, {chatEnabled : true, log_status : item.log_status})
	}
	async toggleItem(type,item){
		switch(type){
			case "notification":
				item.expanded		= undefined==item.expanded?false:item.expanded;
				item.expanded		= item.expanded==false?true:false;
				
				console.log("EXPANDED NOTIFICATION->"+item.expanded);
				this.emiterOption.emit({type : 'expand', item : item, expanded : item.expanded}); // emit the value to get items expanded when update
				if(item.expanded){	
					if(item.unreadMsgs.operator > 0){
						await this.readMessages(item);
					}
					this.scrollToItem(item);	
				}
				break;
		}
	}

	async processStatus(status, item){
		item.loading = true;
		this.addLogStatus(item, status, this.pageInfo.formResult);
		await this.firebaseService.updateAssistantChat(item.id,{status : status, log_status	:	item.log_status });
		this.commons.generateToast('_SUCCESS', '_STATUS_CHANGED','info');
		item.loading = false;
	}
	async readMessages(item){
		this.firebaseService.updateAssistantChat(item.id, {unreadMsgs : {operator : 0, customer : item.unreadMsgs.customer}})
	}

	scrollToItem(item){
		console.log(item);
		setTimeout(() =>{
			const element = document.getElementById(item.id);
			// {
			// 	behavior: "auto"  | "instant" | "smooth",
			// 	block:    "start" | "end",
			//   }
			element.scrollIntoView({ block: 'end',	behavior: item.messages.length > 20 ? "auto" : 'smooth',});
		},200)
	}
	async sendNotification($event){
		if(undefined==$event.content || $event.content==""){ return false; }
		this.notifications[$event.type].items.find(item => item.id == $event.item.id).loading = true;
		let body	=	{
			content		:	$event.content,
			id			:	'operator',
			receivers	:	[],
			sender		:	'operator',
			timestamp	:	new Date(),
			type		:	'text',
			viewers		:	[]
		}
		await this.firebaseService.addMessageToRoom($event.item.refRoom,body);

		/**
		 * create the object to update the document from the collection assistantChats from the dmc. Doing this is the way to 
		 * update the messages from the subscription
		 *  */ 
		let updateInfo	=	{ 
			joined	:	true,
			status 	:	'_PROCESSING',
			lastMessage	:	{
				sender 	: 	'operator',
				type	:	'operator',
				time	:	new Date(),
				value	:	$event.content
			}
		};
		await this.firebaseService.updateAssistantChat($event.item.id, updateInfo);
		this.notifications[$event.type].items.find(item => item.id == $event.item.id).loading = false;

	}

	/**
	 * 
	 * @param items array of messages
	 * @param index current message
	 * @param type operator || id customer
	 */
	checkSameSender(items, index, type){
		if(index == 0){ return true;};
		return !(items[index-1].sender == type);  
	}

	/**
	 * Format time message like WhatsApp
	 * @param time 
	 */
	formatTime(time)		{	return this.commons.formatTimePassed(time);	}
	formatTimestamp(time)	{	return this.commons.formatTimestamp(time);	}
	checkTimePenalization(time){
		let formatedTime	=	this.commons.formatTimestamp(time);
		let computedDate	=	this.commons.computeDate(formatedTime, 'add',7,'days');
		if(computedDate){
			return this.commons.formatCustomDate(computedDate, 'DD/MM/YYYY HH:mm');
		} 
	}
	//---------------------------
	// Dialog methods
	//---------------------------
	/**
	 * Check if some notification is tempting to change status
	 */
	someActionActive(){
		if(!this.pageInfo || !this.pageInfo.statusActiveDialog){ return false;}
		return this.pageInfo.statusActiveDialog.some(el => el.active == true);
	}
	/**
	 * 
	 * @param entity type of dialog (assistant | issue)
	 * @param type status type 
	 */
	changeStatusDialog(entity, type){

		let foundEntity	=	this.pageInfo.dialogStatus.find(el => el.entity == entity);
		foundEntity.options.forEach(el => el.active = false);
		foundEntity.options.find(el=> el.type == type).active = true;
		switch(entity){
			case 'consult'	:	return this.pageInfo.displayDialogConsult	= 	true;
			case 'issue'	:	return this.pageInfo.displayDialogIssue		=	true;
		}
	}
	restartDialogs(){
		this.pageInfo.displayDialogConsult	 = false;
		this.pageInfo.displayDialogIssue	 = false;
	}

	/**
	 * Create a push into records_status to log each modification 
	 * @param entity entity to change status
	 * @param item notification object, (assistantChat || issue)
	 */
	async confirmDialogActionStatus(entity, item){
		this.pageInfo.formLoading	=	true;
		let type	=	this.pageInfo.dialogStatus.find(el => el.entity == entity).options.find(el => el.active).type
		let data	=	this.pageInfo.formResult;

		this.addLogStatus(item, type, data);
		
		await this.firebaseService.updateAssistantChat(item.id,{status : type, log_status	:	item.log_status });
		
		this.commons.generateToast('_SUCCESS', '_STATUS_CHANGED','info');
		this.pageInfo.formLoading	=	false;
		this.restartDialogs();
	}

	/**
	 * 
	 * @param item item to update
	 * @param type status / action to change
	 * @param data content (message info about action)
	 */
	addLogStatus(item, type, data){
		let updatedStatus = {
			type	:	type,
			time	:	new Date(),
			data	:	data
		};
		item.log_status	=	item.log_status ? [...item.log_status,updatedStatus]  : [updatedStatus]; // create the first log or push it
	}

	getDialogCurrentStatus(entity){
		return this.pageInfo.dialogStatus.find(el => el.entity == entity).options.find(el => el.active).type;
	}

	getListReasons(entity){
		if(!this.pageInfo.formsNotifications){ this.translateListReasons();}
		let currentType	=	this.getDialogCurrentStatus(entity);
		return this.pageInfo.formsNotifications.find(el => el.entity == entity).options.find(el => el.type == currentType).reasons;	
	}
	translateListReasons(){
		this.pageInfo.formsNotifications	=	formData.map(data => {
			return {...data, options : data.options.map(el => {
				return {...el, reasons : el.reasons.map(reason => {
					return {...reason, label : this.commons.getTranslate(reason.label)}}
				)}})};
			});
	}
	getBadge(entity){
		if(!this.notifications || Object.keys(this.notifications).length == 0){ return null;}
		let total	=	this.notifications[entity].badge;
		if(total > 0){
			return '( '+total+' )'; 
		}else{
			return null;
		}
		
	}
}
