import { FirebaseService 						} from './../../../service/database/firebase.service';
import { MapService 							} from './../../../service/mapservice';
import { CommonsService 						} from '.././../../service/commons.service';
import { StorageService                         } from '.././../../service/storageservice';
import { Component, OnInit, ViewEncapsulation, 
		 Input, Output, EventEmitter } from '@angular/core';
import { filters 								} from '.././data/filters';
import { EntityService                          } from '../../../service/entity.service';
import { ToastrService                          } from 'ngx-toastr';
import { ConfirmationService                    } from 'primeng/api';
import { DestinationsService 					} from 'src/app/demo/service/database/destinations.service';
import { isArray 								} from 'util';


@Component({
	selector		: 'app-generator-destinations',
    styleUrls		: ['./mapGeneratorDestinations.component.scss'],
	templateUrl		: './mapGeneratorDestinations.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class MapGeneratorDestinationsComponent implements OnInit{
	
	@Input()	fromWizard	:	any = null;	 
	@Input()	alreadyData	:	any;
	@Input()	mode		:	any;
	@Output()	emitter				= new EventEmitter<any>();	

    pageInfo    :   any = {};

    constructor(
				private destinationServices	:	DestinationsService,
				private mapCtrl				:	MapService,
				private firebaseCtrl		:	FirebaseService,
				private confirmCtrl			: 	ConfirmationService,
				private commons				:	CommonsService){}

    async ngOnInit(){
		this.init();
		await this.loadEntites();
		if(this.alreadyData){
			if(this.mode == 'edit'){
				await this.loadTmpEdit();
			}else{
				await this.loadTmpCreation()
			}
		}else{
			this.generateForms();
		}
	}
	async loadTmpEdit(){
		this.generateForms();
		let infoDestination : any				=	await this.firebaseCtrl.getDataByRef(this.alreadyData.refDestination);
		infoDestination.listAreas				=	await Promise.all(infoDestination.refAreas.map(async refArea => { return await this.firebaseCtrl.getDataByRef(refArea)}))
		
		this.pageInfo.polyDestinations			=	infoDestination.listAreas.map(area => { return {...area , background : this.pageInfo.colors.selected}});
		this.pageInfo.mapConfig.lat 			= 	infoDestination.listAreas[Math.ceil(infoDestination.listAreas.length/2)].coords[0].lat;
		this.pageInfo.mapConfig.lng 			= 	infoDestination.listAreas[Math.ceil(infoDestination.listAreas.length/2)].coords[0].lng;
		this.pageInfo.displayMap				=	true;
		this.pageInfo.newDestinationForm.info	=	infoDestination || {};
		this.pageInfo.originalPolyDestinations	=	this.pageInfo.polyDestinations;	
		console.log('infoDestination is', infoDestination);
	}

	async loadTmpCreation(){
		this.generateForms();
		this.pageInfo.countryForm.info			=	this.pageInfo.countryForm.info || {};
		this.pageInfo.countryForm.info.country	=	this.alreadyData.templateFromCountry;
		this.pageInfo.countrySelected			=	this.alreadyData.templateFromCountry.value.value; // get the value to call firebase destinations from country
		await this.getAreasFromDB();

		this.pageInfo.destinationsSelecteds						=	this.alreadyData.destinationsSelecteds;
		this.pageInfo.destinationForm.form[0].items[0].editor	=	'multiselect';
		this.pageInfo.destinationForm.info						=	this.pageInfo.destinationForm.info || {};
		this.pageInfo.destinationForm.info.destinations			=	this.alreadyData.templateFromDestinations;
		await this.displayAreasFromDestinations();
		
		let markedAreas	=	this.alreadyData.areas.filter(el => el.background == 'red');
		this.pageInfo.polyDestinations.filter(area 			=> markedAreas.findIndex(savedArea => savedArea.properties.name == area.properties.name) > -1 )
										.forEach(filteredArea => filteredArea.background = 'red');

		this.pageInfo.newDestinationForm.info	=	this.pageInfo.newDestinationForm.info || {};							  
		Object.keys(this.alreadyData.formNewDestination).forEach(data =>{
			this.pageInfo.newDestinationForm.info[data]	=	this.alreadyData.formNewDestination[data];
		});			  
		console.log('Form destination ',this.pageInfo.formNewDestination);	
		this.pageInfo.acceptedInfoCreation	=	true;
	}

	init(){
		this.pageInfo	=	{
			entities				:	{},
			displayHoverAreas		:	false,
			displayDestinationForm	:	false,
			displayDestinations		:	false,
			creatingArea			:	false,
			displayToolbar			:	true,
			countrySelected			:	null,
			templateFromProvinces	:	[],
			colors					:	{
				selected	:	'red'
			},
			gifs            : {
				tutorial    :   'assets/demo/gifs/maps/gifCreateZones.gif'
			},
			mapConfig				:	{
				lat		:	null,
				lng 	:	null,
				zoom	:	8
			},
			tutorialInfo		:	{ steps : new Array(4), route : '_MAP_CREATE_DESTINATION_STEP'}
		}
	}
	
	generateForms(){
		this.pageInfo.countryForm	=	{
			ready		: true,
			entities	: this.pageInfo.entities,
			form		: [
				{
					cols		: 12,
					showTitle	: false,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'country', 		label:'_COUNTRY',		type: 'text',	editor: 'autocomplete',	editable	: true, entityList: 'countries'	},
					]
				}						
			]
		}
		this.pageInfo.destinationForm	=	{
			ready		: true,
			entities	: this.pageInfo.entities,
			form		: [
				{
					cols		: 12,
					showTitle	: false,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'destinations',	label:'_REGIONS',		type: 'text',	editor: 'autocomplete',	editable: true, entityList: 'destinations'	},
					]
				}						
			]
		};
		this.pageInfo.provincesForm	=	{
			ready		: true,
			entities	: this.pageInfo.entities,
			form		: [
				{
					cols		: 12,
					showTitle	: false,
					icon		: "fa fa-file-text-o",
					padding		: "1rem 1rem 1rem 1rem",
					items		: [
						{ mandatory: true, 	field	: 'provinces',	label:'_PROVINCES',	type: 'text',	editor: 'multiselect', entityList: 'provinces'	},
					]
				}						
			]
		}
		this.pageInfo.newDestinationForm	=	{
			ready		:	true,
			entities	:	this.pageInfo.entities,
			form		:	[
				{
					title		: "_INFO_NEW_DESTINATION",
					cols		:	12,
					showTitle	:	true,
					icon		:	'fa fa-file-text-o',
					padding		:	'1rem',
					items		:	[
						{ mandatory : true,		field	: 'name',		label : '_NAME', 		type : 'text', 	editor : 'text'},
						{ mandatory : false,	field	: 'description',label : '_DESCRIPTION', type : 'text', 	editor : 'textarea'},					
						{ mandatory : false,	field	: 'img',		label : '_IMAGE',		type : 'img',	editor : 'img'}
					],
					
				}
			],
			buttons	: {
				items:	[
					{ name: 'save', label: '_SAVE_NEW_DESTINATION',	action: 'save',  icon: 'fa fa-fw fa-save'}					
				]
			}
		}

	}
    async loadEntites(){
		await this.load('countries');
		console.log('Data loaded',this.pageInfo.entities)
	}

    async load(entity){
		this.pageInfo.entities[entity]	=	this.pageInfo.entities[entity] || {}; // init entity	
        switch(entity){
			default				:	return;
			case	'countries'	:	const response	=	await this.destinationServices.getCountries();
									response.forEach((item : any) => item.name = this.commons.getTranslate(item.label));
									this.pageInfo.entities[entity]	=	response;
			return;
        }
	}
	
	async handleForm($event){
		console.log($event);
		switch($event.type){
			case 'selectedFromList'	:	
				switch($event.extra.field){
					case 'country'		:	console.log('ON SWITCH CASE',$event.item[$event.extra.field].value.code);
											this.pageInfo.countrySelected		=	$event.item[$event.extra.field].value.value;
											this.pageInfo.templateFromCountry	=	$event.item[$event.extra.field];
											if(this.fromWizard){
												await this.getAreasFromDB();
											}else{
												this.selectModeWork(); 
											}
					break;
					case 'destinations'	:	if(isArray($event.item[$event.extra.field])){
												this.pageInfo.destinationSelected	=	$event.item[$event.extra.field].map(el => el.value);
											}else{
												this.pageInfo.destinationSelected	=	$event.item[$event.extra.field].value.value;
											}
											console.log(this.pageInfo.destinationSelected);
											
											await this.displayDestinationMap();
					return;
				}
			case 'multiSelect'	:	
				switch($event.extra.field){
					case 'destinations'	:	this.pageInfo.destinationsSelecteds		=	$event.item[$event.extra.field];
											this.pageInfo.templateFromDestinations	=	$event.item[$event.extra.field];
											this.cleanDestinationsUnselecteds();
											await this.displayDestinationMap();	
					break;
					case 'provinces'	:	this.pageInfo.provincesSelecteds		=	$event.item[$event.extra.field];
											this.pageInfo.templateFromProvinces		=	$event.item[$event.extra.field];
											this.markAreasFromProvinces();
					break;
					default				:	return;
				}
			console.log($event);


		}
	}

	/**
	 * Remove the polygons selecteds if some destination is removed from the selector
	 */
	cleanDestinationsUnselecteds(){
		if(!this.pageInfo.polyDestinations){ return; }
		const destinationsSelecteds	=	this.pageInfo.destinationsSelecteds.map(dest => dest.value);
		const destinationsPolygons	=	Array.from(new Set(this.pageInfo.polyDestinations.map(item => item.properties.NAME_1)));
		destinationsPolygons.forEach(destination => {
			const foundDest	=	destinationsSelecteds.find(dest => dest == destination);
			if(foundDest){ return; }
			this.pageInfo.polyDestinations.filter(item => item.properties.NAME_1 == destination).forEach(area => { area['background'] ? delete area['background'] : null});
		})
	}
	selectModeWork(){
		this.pageInfo.displaySelectMode	=	true;
	}
	async getLocalData(){
		
		this.pageInfo.displaySelectMode	=	false;
		this.pageInfo.modeWork 			= 'uploadData';
		
		this.pageInfo.dataCountry 	= 	await this.destinationServices.getJSONCountry(this.pageInfo.countrySelected);
		let communities				=	this.destinationServices.getComunitiesFromCountry(this.pageInfo.dataCountry.features);

		//get the areas from our Database to be able to delete or add the clicked one
		this.pageInfo.savedCountryAreas	=	await this.destinationServices.getAreasFromCountry(this.pageInfo.countrySelected);
		this.pageInfo.savedCommunities	=	this.destinationServices.getComunitiesFromCountry(this.pageInfo.savedCountryAreas);

		console.log('Saved country areas => ',this.pageInfo.savedCountryAreas, this.pageInfo.savedCommunities);

		this.pageInfo.entities['destinations']					=	communities.map(el => {return {value : el, name : el}});
		this.pageInfo.destinationForm.form[0].items[0].editor	=	'autocomplete';	// 
	}

	/**
	 * find if the current destination is already added to our DB and enable to delete it
	 */
	findAlreadyAddedDestination(){
		if(!this.pageInfo.savedCommunities || !this.pageInfo.destinationSelected){ return false;}
		return this.pageInfo.savedCommunities.find(community => community == this.pageInfo.destinationSelected);
	}
	async getAreasFromDB(){

		this.pageInfo.displaySelectMode	=	false;
		this.pageInfo.modeWork 			= 	'createDestinations';
		
		this.pageInfo.countryAreas		=	await this.destinationServices.getAreasFromCountry(this.pageInfo.countrySelected);
		this.createStructRegions();
		let communities					=	this.destinationServices.getComunitiesFromCountry(this.pageInfo.countryAreas);

		this.pageInfo.entities['destinations']					=	communities.map(el => {return {value : el, name : el}});
		this.pageInfo.destinationForm.form[0].items[0].editor	=	'multiselect';	// Set to true to be able to pickup more than one destination
		
		console.log('DATA TEST', this.pageInfo.countryAreas);
		console.log('COMMUNITIES ARE', communities);
	
	}

	async getProvinces(){
		console.log('List provinces',this.pageInfo.polyDestinations);
		let provinces	=	this.destinationServices.getProvincesFromDestinations(this.pageInfo.polyDestinations);
		this.pageInfo.entities['provinces']	=	provinces.map(el => { return {value : el, name : el}});
		this.pageInfo.displayProvinces		=	true;
		console.log(this.pageInfo.entities['provinces']);
	}

	displayDestinationMap(){
		switch(this.pageInfo.modeWork){
			case 'createDestinations'	:	this.displayAreasFromDestinations();	return;
			case 'uploadData'			:	this.mapDisplayLocalData();				return;
			default						:	return;
		}
	}

	/** Display the areas from the JSON of the country */
	mapDisplayLocalData(){
		let data	=	this.pageInfo.dataCountry.features.filter(el => el.properties.NAME_1 == this.pageInfo.destinationSelected);
		this.pageInfo.polyDestinations	=	this.destinationServices.getInfoCommunity(data);
		this.pageInfo.mapConfig.lat 	= 	this.pageInfo.polyDestinations[Math.ceil(this.pageInfo.polyDestinations.length/2)].coords[0].lat;
		this.pageInfo.mapConfig.lng 	= 	this.pageInfo.polyDestinations[Math.ceil(this.pageInfo.polyDestinations.length/2)].coords[0].lng;
		this.pageInfo.displayMap		=	true;
	}

	async displayAreasFromDestinations(){
		console.log('filtered', this.pageInfo.destinationsSelecteds);
		if(!this.pageInfo.countryAreas || !isArray(this.pageInfo.destinationsSelecteds)){ return; }
		if(this.mode == 'edit'){
			console.log('Destinations selecteds',this.pageInfo.destinationsSelecteds);
			if(this.pageInfo.destinationsSelecteds.length == 0)	// if no destination / community selected
			{ 
				this.pageInfo.polyDestinations	=	this.pageInfo.polyDestinations.filter(el => el.background == this.pageInfo.colors.selected); // just get and paint the areas from our destination creation
			}
			else	// get all the areas from destinations selecteds
			{ 
				let areasDestination			=	this.pageInfo.countryAreas.filter(area => this.pageInfo.destinationsSelecteds.find(dest => dest.value == area.properties.NAME_1));
				if(undefined != this.pageInfo.polyDestinations && this.pageInfo.polyDestinations.length >0){ // just get and paint the areas from our destination creation
					this.pageInfo.polyDestinations	=  this.pageInfo.polyDestinations.filter(el => el.background == this.pageInfo.colors.selected);
				}else{
					this.pageInfo.polyDestinations	=	areasDestination;
				}

				const tmpAreas					=	JSON.parse(JSON.stringify(this.pageInfo.polyDestinations.map(data => { return { properties : data.properties}})));				
				areasDestination.forEach(areaDest =>{
					let findSelectedArea	=	tmpAreas.find(selectedArea => selectedArea.properties.name == areaDest.properties.name);
					!findSelectedArea		?	this.pageInfo.polyDestinations.push(areaDest) : null; 
				});
			}
			
		}else{
			this.pageInfo.polyDestinations	=	this.pageInfo.countryAreas.filter(area => this.pageInfo.destinationsSelecteds.find(dest => dest.value == area.properties.NAME_1));
		}

		this.pageInfo.mapConfig.lat 	= 	this.pageInfo.polyDestinations[Math.ceil(this.pageInfo.polyDestinations.length/2)].coords[0].lat;
		this.pageInfo.mapConfig.lng 	= 	this.pageInfo.polyDestinations[Math.ceil(this.pageInfo.polyDestinations.length/2)].coords[0].lng;
		if(this.pageInfo.destinationsSelecteds.length == 0){
			this.pageInfo.provincesSelecteds	=	[];
			this.pageInfo.templateFromProvinces	=	[];
			this.pageInfo.provincesForm.item	=	{}
		}

		await this.getProvinces();
		this.pageInfo.displayMap		=	true;

	}

	markAreasFromProvinces(){
		if(!this.pageInfo.polyDestinations || !this.pageInfo.provincesSelecteds){ return; }
		this.pageInfo.polyDestinations.forEach(el => el.background = 'transparent');
		this.pageInfo.provincesSelecteds.forEach(province => {
			this.pageInfo.polyDestinations.filter(area => area.properties.NAME_2 == province.value).forEach(area => area.background = 'red');
		});
	}

	clickCustomArea($event,type, item?){
		if(this.pageInfo.creatingArea){
			let coords	: any = {};
			switch(type){
				case 'polygon'	:	coords = {lat : $event.latLng.lat(), lng : $event.latLng.lng()}; 	break;
				default			:	coords = $event.coords; 											break;
			}
			this.pageInfo.customLines.push(coords);
		}else{
			switch(type){
				case 'polygon'	:	if(item){	item.background = item.background == 'red' ? 'transparent' : 'red';	}
									else	{	
										const latLngPolygon	=	{ lat	:	$event.latLng.lat(), lng	:	$event.latLng.lng()	};
										let item			=	this.pageInfo.customPolygon.find(poly => this.mapCtrl.isPointInsidePolygon(poly.coords,latLngPolygon.lat,latLngPolygon.lng));
										if(item){	item.background = item.background == 'red' ? 'transparent' : 'red';	}
									}
				
				break;
				default			:	return;
			}
		}
		
	}
	
	checkMarkNamePolygon($event){	this.pageInfo.displayHoverAreas	=	$event;}
	markCurrentPolygon($event,index,coordsPolygon){
		this.pageInfo.currentHoverPolygon =	this.pageInfo.currentHoverPolygon || null; 
		if(this.pageInfo.currentHoverPolygon == index){ return};

		this.pageInfo.currentHoverPolygon		=	index;
		this.pageInfo.centroidCurrentPolygon	=	this.getCentroid(coordsPolygon);
		console.log('Current Polygon index', index);
	}
	getCentroid(coords){
		return this.mapCtrl.get_polygon_centroid(coords);
	}
	toggleCreateArea(){
		this.pageInfo.creatingArea	=	!this.pageInfo.creatingArea;
		this.pageInfo.customLines	=	[];
		this.pageInfo.polyDestinations.forEach(el => delete el.background);
		this.pageInfo.customPolygon	=	null;
	}
	finishCreateArea(){
		this.pageInfo.creatingArea	=	false;
		this.pageInfo.customLines.push(this.pageInfo.customLines[0]);
		this.pageInfo.customPolygon	=	this.pageInfo.customLines;

		this.pageInfo.customPolygon		=	this.pageInfo.polyDestinations.filter(el => this.mapCtrl.somePointInsidePolygon(el.coords,this.pageInfo.customPolygon));
		this.pageInfo.customPolygon.forEach(el => el.background = 'red');
		
		this.pageInfo.customLines				=	[];
		this.pageInfo.currentHoverPolygon		=	null;
		this.pageInfo.displayDestinationForm	=	true;
	}

	async handleDestination($event){
		console.log($event);
		const areas			=	{ refAreas : this.pageInfo.polyDestinations.filter(el => el.background == this.pageInfo.colors.selected).map(el => el.ref)}
		let newDestination	=	{ ...$event.item, ...areas};
		
		if(areas.refAreas.length > 0){
			if(this.fromWizard){
				if(this.mode == 'edit'){
					this.emitter.emit(	{ 	success : true, 
											mode	: this.mode,
											data	: newDestination})
				}else{
					this.emitter.emit(	{	success: true, 
											mode	: this.mode,
											data 	: {
												...newDestination,
												destinationsSelecteds		:	this.pageInfo.destinationsSelecteds, 
												areas 						:	this.pageInfo.polyDestinations, 
												templateFromCountry			:	this.pageInfo.templateFromCountry 		|| this.alreadyData.templateFromCountry,
												templateFromDestinations	:	this.pageInfo.templateFromDestinations	|| this.alreadyData.templateFromDestinations, 
												templateFromProvinces		:	this.pageInfo.templateFromProvinces		|| this.alreadyData.templateFromProvinces
										}
									});
				}
				
			}else{
				this.pageInfo.uploadingInformation	=	true;
				this.pageInfo.infoMessage			=	'_CREATING';
				await this.destinationServices.createDestination(this.pageInfo.countrySelected,newDestination);
				this.pageInfo.uploadingInformation	=	false;
				this.commons.generateToast('_SUCCESS', '_DATA_ADDED', 'success');	
				await this.ngOnInit();
			}
			
		}else{
			this.commons.generateToast('_ERROR','_MISSING_INFO','error');
		}
		this.pageInfo.newDestinationForm.item	=	{};
	}
	async uploadDestination(){
		this.pageInfo.uploadingInformation	=	true;
		this.pageInfo.infoMessage			=	'_UPLOADING';
		await this.destinationServices.addAreasToDestination(this.pageInfo.countrySelected,this.pageInfo.polyDestinations);
		this.pageInfo.uploadingInformation	=	false;
		this.commons.generateToast('_SUCCESS', '_DATA_ADDED', 'success');
	}

	async removeDestination(){
		this.confirmCtrl.confirm({	
			message 	:	this.commons.getTranslate('_REMOVE_DESTINATION_CONFIRM'),
			header		:	this.commons.getTranslate('_CONFIRMATION'),
			icon		:	'pi pi-exclamation-triangle',
			key			:	'removeProvince',
			acceptLabel	:	this.commons.getTranslate('_REMOVE'),
			rejectLabel	:	this.commons.getTranslate('_CANCEL'),
			accept: async () => {
				let areasToRemove = this.pageInfo.savedCountryAreas.filter(el => el.properties.NAME_1 == this.pageInfo.destinationSelected);
				console.log('list to remove', areasToRemove);
				this.pageInfo.uploadingInformation	=	true;
				this.pageInfo.infoMessage	=	'_REMOVING';
				await this.destinationServices.removeAreas(areasToRemove);
				this.pageInfo.uploadingInformation	=	false;
				this.ngOnInit();
			}
		});	
		
	}

	/* ADMIN METHODS. JUST ADMINS CAN ACCESS TO THE FOLLOWING METHODS*/
	async createStructRegions(){
		console.log(this.pageInfo.countryAreas);
		let regions		=	this.destinationServices.getComunitiesFromCountry(this.pageInfo.countryAreas);
		regions.map(region =>{
			return {
				region		:	region, 
				provinces	:	this.destinationServices.getProvincesFromRegion(this.pageInfo.countryAreas,region)	
			}
		})
	}
}