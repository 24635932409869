export let detailItems = [	
	{
		id			: 1,
		title		: "_WIZARD_VARIANT_STEP_1_TITLE",
		description	: "_WIZARD_VARIANT_STEP_1_DESC"
	},
	{
		id			: 2,
		title		: "_WIZARD_VARIANT_STEP_2_TITLE",
		description	: "_WIZARD_VARIANT_STEP_2_DESC"
	},
	{
		id			: 3,
		title		: "_WIZARD_VARIANT_STEP_3_TITLE",
		description	: "_WIZARD_VARIANT_STEP_3_DESC"
	},
	{
		id			: 4,
		title		: "_WIZARD_VARIANT_STEP_4_TITLE",
		description	: "_WIZARD_VARIANT_STEP_4_DESC"
	},
	{
		id			: 5,
		title		: "_WIZARD_VARIANT_STEP_5_TITLE",
		description	: "_WIZARD_VARIANT_STEP_5_DESC"
	},
	{
		id			: 6,
		title		: "_WIZARD_VARIANT_STEP_6_TITLE",
		description	: "_WIZARD_VARIANT_STEP_6_DESC"
	},
	{
		id			: 7,
		title		: "_WIZARD_VARIANT_STEP_7_TITLE",
		description	: "_WIZARD_VARIANT_STEP_7_DESC"
	},
];

export const entities = {						
	statusList	: [
		{ id: 1,	label: '_PENDING',			name: 'pending' 			},
		{ id: 2,	label: '_EDIT_PENDING',		name: 'edit_pending'		},
		{ id: 3,	label: '_REVIEWING',		name: 'reviewing' 			},
		{ id: 4,	label: '_EDITED',			name: 'edited'				},
		{ id: 5,	label: '_ACCEPTED',			name: 'accepted'			},
		{ id: 6,	label: '_PAYMENT_PENDING',	name: 'payment_pending'		},
		{ id: 7,	label: '_PAYED',			name: 'payed'				}		
	],
	paymentMethodList	: [
		{ id: 1,	label: '_CASH',				name: 'cash' 				},
		{ id: 2,	label: '_BANK_ACCOUNT',		name: 'bank_account'		}
	],
	typeList	: [
		{ id: 1,	label: '_CLEANING',			name: 'cash' 				},
		{ id: 2,	label: '_EXPENSES',			name: 'expenses'			},
		{ id: 3,	label: '_FUEL',				name: 'fuel'				},
		{ id: 4,	label: '_REPAIRS',			name: 'repairs'				}
	],
	subtypeList	: [
		{ id: 1,	label: '_CASH',				name: 'cash' 				},
		{ id: 2,	label: '_BANK_ACCOUNT',		name: 'bank_account'		}
	]
};

export const expendituresFakeData = [	
	{
		id							: 1,
		status						: '_REVISION',
		driver						: 'Pepe Rubianes',
		vehicle_type				: 'BUS55',
		vehicle						: 'IB-3324',
		provider					: 'HTX',
		type						: '_CLEANING',
		subtype						: '_FULL',
		date						: '2019-09-12',
		cost						: '25',
		payment_method				: '_BANKACCOUNT',
		payment_date				: '2019-10-01',
		ticket						: 'https://pbs.twimg.com/media/Dkwc4hbU0AAIpY3.jpg',
		note						: 'Elefante azul de amanecer en Palma'		
	},
	{
		id							: 2,
		status						: '_REVISION',
		driver						: 'Pepe Rubianes',
		vehicle_type				: 'BUS55',
		vehicle						: 'IB-3324',
		provider					: 'HTX',
		type						: '_CLEANING',
		subtype						: '_FULL',
		date						: '2019-09-12',
		cost						: '25',
		payment_method				: '_BANKACCOUNT',
		payment_date				: '2019-10-01',
		ticket						: 'https://pbs.twimg.com/media/Dkwc4hbU0AAIpY3.jpg',
		note						: 'Elefante azul de amanecer en Palma'		
	},
	{
		id							: 3,
		status						: '_REVISION',
		driver						: 'Pepe Rubianes',
		vehicle_type				: 'BUS55',
		vehicle						: 'IB-3324',
		provider					: 'HTX',
		type						: '_CLEANING',
		subtype						: '_FULL',
		date						: '2019-09-12',
		cost						: '25',
		payment_method				: '_BANKACCOUNT',
		payment_date				: '2019-10-01',
		ticket						: 'https://pbs.twimg.com/media/Dkwc4hbU0AAIpY3.jpg',
		note						: 'Elefante azul de amanecer en Palma'		
	},
	{
		id							: 4,
		status						: '_REVISION',
		driver						: 'Pepe Rubianes',
		vehicle_type				: 'BUS55',
		vehicle						: 'IB-3324',
		provider					: 'HTX',
		type						: '_CLEANING',
		subtype						: '_FULL',
		date						: '2019-09-12',
		cost						: '25',
		payment_method				: '_BANKACCOUNT',
		payment_date				: '2019-10-01',
		ticket						: 'https://pbs.twimg.com/media/Dkwc4hbU0AAIpY3.jpg',
		note						: 'Elefante azul de amanecer en Palma'		
	},
	{
		id							: 5,
		status						: '_REVISION',
		driver						: 'Pepe Rubianes',
		vehicle_type				: 'BUS55',
		vehicle						: 'IB-3324',
		provider					: 'HTX',
		type						: '_CLEANING',
		subtype						: '_FULL',
		date						: '2019-09-12',
		cost						: '25',
		payment_method				: '_BANKACCOUNT',
		payment_date				: '2019-10-01',
		ticket						: 'https://pbs.twimg.com/media/Dkwc4hbU0AAIpY3.jpg',
		note						: 'Elefante azul de amanecer en Palma'		
	}
];