import { CommonsService } from './../../demo/service/commons.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector    : 'tours-items-list',
  templateUrl : './tours-items-list.component.html',
  styleUrls   : ['./tours-items-list.component.scss'] ,

})

export class ToursItemsListComponent implements OnInit{

@Input() listItems 		: 	any[] = [];  
@Input() selectedItems	:	any[] = [];


pageInfo : any = {
	currentItemSelected : null,
	listLangs			: ['es','uk','en','it']	
}
constructor(private commons : CommonsService,private dragulaService: DragulaService){
}

ngOnInit(){
	this.dragulaService.destroy("SPILL");
	this.dragulaService.createGroup("SPILL", {
		removeOnSpill: true
	});
	this.pageInfo.lang = this.commons.getActiveLang();
}

getTitleByLang(itemLangs){
	const foundLang = itemLangs.find(el => el.code == this.pageInfo.lang);
	return foundLang || '_NOT_FOUND_IN '+ this.pageInfo.lang;
}

getListSelectedItems(){
	this.selectedItems = this.selectedItems.filter(el => el != null);
	return this.selectedItems;
}

dragStart($event,item){
	this.pageInfo.currentItemSelected = item;
}
dragEnd($event){
	this.pageInfo.currentItemSelected = null;
}
async drop($event){
	const foundItem	=	this.selectedItems.find(el => el.id == this.pageInfo.currentItemSelected.id);
	if(foundItem){
		await this.commons.generateToast('_ERROR','_DUPLICATED_ENTRY','error');
		return;
	}else{
		this.selectedItems = [...this.selectedItems,this.pageInfo.currentItemSelected];
	}
	this.pageInfo.currentItemSelected = null;
}

}