import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild} from '@angular/core';
import { CommonsService } from 'src/app/demo/service/commons.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector    : 'offers-template-period',
  templateUrl : './offers-template-period.component.html',
  styleUrls   : ['./offers-template-period.component.scss'] ,

})

export class OffersTemplatePeriodComponent implements OnInit {

    @Input() 						tableOffers     	:   any               	= {};
    @Output() 						emiterOption		:   EventEmitter<any>   = new EventEmitter();
	@ViewChild('calendarPicker') 	calendarPicker		:	any;
	@ViewChild('startHour')			startHour			:	any;
	@ViewChild('endHour')			endHour				:	any;
	@ViewChild('promotionDropDown')	promotionDropDown	:	any;
	
	@ViewChild('execDay')				execDay				:	any;
	@ViewChild('execTemplate')	execTemplate	:	any;

	pageInfo: any = {};
	
	constructor(private commons 	: CommonsService, 
				private confirmCtrl	: ConfirmationService
	){ }
    ngOnInit() {
		console.log('INIT COMPONENT');
		this.tableOffers.template.map(el => el.offers.map(exec => exec.editMode = false));
		this.init();
	}
	init(){

		this.pageInfo			= {
			daySelected     		:   null,
			currentExecSelected		:	null,
			offersSelected			:	{idDay:null, execs:[]},
			colorPeriods    		:   ['#ffe595','#e8a5a5','#e6d398','tomato','#79b5e4'],
			contextMenuExec			:	{},
			contextMenuDay			:	{},
			displayContextExecOpt	:	null,
			displayContextDayOpt	:	null,
			lastRowOffers		:	{
				id          : null,
				editMode    : false,
				startTime   : null,
				endTime     : null,
				type        : {},
				value       : null,
				profiles    : ['adult','children','infant'],
				turns       : []
			},
			templateWeek    		:   [
				{ name : "_MONDAY", 	turns: 	[ { label:'_MORNING', id:0	},{ label:'_AFTERNOON',id:1 }]},
				{ name : "_TUESDAY",	turns:	[ { label:'_MORNING', id:0 	},{ label:'_AFTERNOON',id:1	}]},
				{ name : "_WEDNESDAY",	turns: 	[ { label:'_MORNING', id:0 	},{ label:'_AFTERNOON',id:1 }]},
				{ name : "_THURSDAY",	turns:	[ { label:'_MORNING', id:0	},{ label:'_AFTERNOON',id:1	}]},
				{ name : "_FRIDAY",		turns: 	[ { label:'_MORNING', id:0 	},{ label:'_AFTERNOON',id:1 }]},
				{ name : "_SATURDAY",	turns:	[ { label:'_MORNING', id:0	},{ label:'_AFTERNOON',id:1	}]},
				{ name : "_SUNDAY",		turns: 	[ { label:'_MORNING', id:0 	},{ label:'_AFTERNOON',id:1 }]}
			],
			controllerGButton	:	{
				day	:	{
					displayCopy		:	false,
					displayDelete	:	false,
					displayOverWrite:	false,
					displayMergeDay	:	false
				},
				exec:	{
					displayCopy		:	false,
					displayDelete	:	false,
					displayPaste	:	false
				}
			}
		};

		this.pageInfo.listTypeOffers = [
			{ 	code	: 'promotion', 		name: this.commons.getTranslate('_PROMOTION') 		},
			{ 	code	: 'discount', 		name: this.commons.getTranslate('_DISCOUNT') 		},
			{ 	code	: 'absolute_price', name: this.commons.getTranslate('_ABSOLUTE_PRICE') 	},
		];

		this.pageInfo.profiles 	=	[
			{	value	: 'adult', 		label: this.commons.getTranslate('_ADULT') 			},
			{	value	: 'children',	label: this.commons.getTranslate('_CHILDREN')		},
			{	value	: 'infant',		label: this.commons.getTranslate('_INFANTS')		}
		];

		this.pageInfo.listTurns = [
			{	value : 'M', label: this.commons.getTranslate('_MORNING') 	},
			{	value : 'A', label: this.commons.getTranslate('_AFTERNOON') }
		];

		this.pageInfo.listPromotions	=	[
			{ name:	'2x1', code	: '2x1' },
			{ name:	'3x2', code	: '3x2' },
			{ name:	'4x3', code	: '4x3' },
			{ name:	'7x5', code	: '7x5' }
		];

		this.pageInfo.contextMenuExecOptions = [
			{
				label: this.commons.getTranslate('_COPY'),
				icon: 'pi pi-fw pi-chevron-right',
				command		: 	($event) => { 
					this.pageInfo.contextMenuExec.offersCopied =   this.pageInfo.offersSelected;
				}
			},
			{
				label 	: 	this.commons.getTranslate('_DELETE'),
				id		:	'copy',
				icon 	:	'pi pi-fw pi-chevron-right',
				command	:	() =>{
					this.deleteExecs();
				}
			}
		];

		this.pageInfo.contextMenuDayOptions = [
			{
				label: this.commons.getTranslate('_COPY_DAY'),
				id: 'copy',
				icon: 'pi pi-fw pi-chevron-right',
				command		: 	($event) => { 

					this.pageInfo.contextMenuDay.rightClick		=	this.pageInfo.contextMenuDay.tmpRightClick;
					this.pageInfo.offersSelected				=	this.pageInfo.contextMenuDay.tmpRightClick;
				}	
			},
			{
				label: this.commons.getTranslate('_PASTE_DAY'),
				id: 'paste',
				icon: 'pi pi-fw pi-chevron-left',
				command		: ($event) => {
					this.mergeDay(true);
				}
			},
			{
				label: this.commons.getTranslate('_DELETE_EXECS_DAY'),
				id: 'delete',
				icon: 'pi pi-fw pi-chevron-left',
				command		: 	($event) => {
					this.confirmCtrl.confirm({
						message : 	this.commons.getTranslate('_CONFIRM_REMOVE_DAY_PERIOD'),
						header	:	this.commons.getTranslate('_DELETE_DAY'),
						icon	:	'pi pi-exclamation-triangle',
						accept	:	()=>{			
							const foundDay = this.tableOffers.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
							if(foundDay == -1) return;
							this.pageInfo.daySelected = this.tableOffers.template[foundDay];
							this.restartRow();   
						}
					});
				}
			}
		];

		this.restartLastRow();
	}
    toggleEditableRow(row){
      	const tmp    = row.editMode ? !row.editMode : true;
      	this.tableOffers.template.map(el => el.editMode = false);
		row.editMode = tmp; 
		if(row.editMode){
			this.pageInfo.currentExecSelected 			= 	JSON.parse(JSON.stringify(row));
			this.pageInfo.currentExecSelected.startTime	=	this.convertStringToTime(this.pageInfo.currentExecSelected.startTime);
			this.pageInfo.currentExecSelected.endTime	=	this.convertStringToTime(this.pageInfo.currentExecSelected.endTime);
		}
    }
    handleOption($event)		{ this.emiterOption.emit($event);	}
	deleteRow(row)				{
		console.log(this.pageInfo.daySelected);
		this.emitOption({type:'delete',data : {daySelected : this.pageInfo.daySelected, exec :row}});
		this.toggleLastRow(false);
	}
	addRow()					{
		this.emitOption({type:'add', data : {daySelected : this.pageInfo.daySelected}});
		this.pageInfo.currentExecSelected = JSON.parse(JSON.stringify(this.pageInfo.daySelected.offers.find(el => el.editMode)));	
		console.log('current exec',this.pageInfo.currentExecSelected);
	}
	restartRow()				{
		this.emiterOption.emit({type:'restart', data : {daySelected : this.pageInfo.daySelected}});
	}
	saveRow(row,copiedValue?)	{	
		const validatedRow 	= this.validateRow(copiedValue || this.pageInfo.currentExecSelected);
		if(validatedRow){
			if(!copiedValue){
				this.pageInfo.currentExecSelected.value 	= this.pageInfo.currentExecSelected.value['code'] ? this.pageInfo.currentExecSelected.value['code'] : this.pageInfo.currentExecSelected.value; 
				this.pageInfo.currentExecSelected.startTime = this.commons.getFormatDateHour(this.pageInfo.currentExecSelected.startTime)
				this.pageInfo.currentExecSelected.endTime	= this.commons.getFormatDateHour(this.pageInfo.currentExecSelected.endTime);	
				const sameValue								= this.pageInfo.daySelected.offers.some(el => this.compareExecs(el,this.pageInfo.currentExecSelected));
				if(sameValue){ this.commons.generateToast('_ERROR','_OFFER_ALREADY_EXISTS','error'); return;}
			}
			this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec : copiedValue || this.pageInfo.currentExecSelected}});			
		}
		this.toggleLastRow(false);
	}
	selectTurnDay(day,indexDay, turn){
		console.log(day, indexDay, turn);	
		this.pageInfo.templateWeek[indexDay].turns[turn.id].selected = !this.pageInfo.templateWeek[indexDay].turns[turn.id].selected;
		 
	}
    selectDay(day, indexDay, turn){
	  const tmp	=	day.selected ? !day.selected : true;
	  this.pageInfo.templateWeek.map(el => el.selected = false);
	  day.selected = tmp;
	  const index = indexDay == 6 ? 0 : (indexDay +1)
	  if(this.pageInfo.offersSelected.idDay == indexDay){
		this.pageInfo.offersSelected.idDay	=	null;
		this.pageInfo.offersSelected.execs	=	[];
	  } 
	  if(day.selected){
		  	const foundDay	=	this.tableOffers.template.find(el => el.day == indexDay);
			if( foundDay ){
				foundDay.selected 			= true;
				this.pageInfo.daySelected 	= foundDay; 
			}else{
				this.tableOffers.template.push({	day			:	indexDay, 
													selected	:	true,
													turns		:	['M','A'],
													offers		: 	[]
												});
				this.pageInfo.daySelected 	= this.tableOffers.template[this.tableOffers.template.length -1];
			};
			this.pageInfo.contextMenuDay.idDay	=	this.pageInfo.daySelected.day;
			
			if(this.pageInfo.daySelected.offers.length >0){
				this.changeStatusGButton('day','displayCopy',true);
				this.changeStatusGButton('day','displayDelete',true);
			}
			if(this.pageInfo.offersSelected.execs.length > 0){
				this.changeStatusGButton('exec','displayDelete',true);
				if(this.pageInfo.daySelected.offers.length >0){
					this.changeStatusGButton('day','displayMerge',true);
					this.changeStatusGButton('day','displayOverwrite',true);
					this.changeStatusGButton('day','displayCopy',true);
				}else{
					this.changeStatusGButton('day','displayOverwrite',true);
				}
				if( this.pageInfo.contextMenuExec.execsCopied && this.pageInfo.contextMenuExec.execsCopied.execs.length > 0){
					this.changeStatusGButton('exec','displayPaste',true);
				}
			}
	  }else{
		this.pageInfo.daySelected = null;	
		this.restartGButton();	  
	  }
	}
	
	emitOption(data)		{ 	this.emiterOption.emit(data); 					}
	getFormatDateHour(date)	{	if(date == null){return '-'};
								return this.commons.getFormatDateHour(date);
							}
	getSelectDay()			{
		const findSelectedDay = this.pageInfo.templateWeek.findIndex(el => el.selected);
		if(findSelectedDay < 0) return [];
		const findItem	=	this.tableOffers.template.find(el => el.day == (findSelectedDay));
		if(findItem && findItem.offers) return findItem.offers;
		return [];
	}
	isAnyEditingRow()		{ return this.getSelectDay().some(item=>item.editMode); }
	toggleLastRow(status)			{ 
		this.pageInfo.lastRowStatus = undefined!==status
										?status
										:( undefined==this.pageInfo.lastRowStatus?true:!this.pageInfo.lastRowStatus );
		if(this.pageInfo.lastRowStatus==false){ this.restartLastRow(); }
	}
	selectTimePicker($event){
		console.log('TIME PICKER')
		console.log($event);
	}
	saveRowKeyUp($event,row){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		if(this.pageInfo.currentTimeSelected.start_hour == null) return this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		this.pageInfo.currentTimeSelected.start_hour =  this.commons.getFormatDateHour(this.pageInfo.currentTimeSelected.start_hour);

		if(this.pageInfo.daySelected.offers.find(el => 
			(el.start_hour == this.pageInfo.currentTimeSelected.start_hour) 
			&& (el.id != this.pageInfo.currentTimeSelected.id)
		)){ 
			return  this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');
		}
		if(!this.checkPricingRow()){ return this.commons.generateToast('_ERROR','_BAD_FORMED_PRICE','error'); }
		this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec :this.pageInfo.currentTimeSelected}});
	}

	checkPricingRow()		{	return !this.pageInfo.currentTimeSelected.prices.some(element => element.price === null || element.price < 0);}
	getOffers(i,day,turn)	{
		if(this.tableOffers.template.length == 0) return [];
		const foundDay	=	this.tableOffers.template.find(el => el.day == (i));
		if(!foundDay || !foundDay.offers)	return [];
		return foundDay.offers;
	}
	convertStringToTime(timeString){
		var today 		= 	new Date();
		const hours		=	timeString.split(':')[0];
		const minutes	=	timeString.split(':')[1];

		return new Date(today.getFullYear(), today.getMonth(), today.getDate(), +hours, +minutes, 0);
	}
	selectExec($event,execs,item,indexExec, indexDay){
		$event.stopPropagation();
		if($event && ($event.shiftKey || $event.ctrlKey)){
			switch(true){
					case $event.shiftKey===true :
						console.log('CLICKED SHIFT');
						this.pageInfo.offersSelected.idDay	=	+(this.pageInfo.offersSelected.idDay.toString() || indexDay.toString());
						if(indexDay == this.pageInfo.offersSelected.idDay){
							console.log(execs);
							const fromValue	=	this.pageInfo.offersSelected.execs[0];
							const fromIndex	=	execs.findIndex(el => el.id == fromValue.id);
							console.log('OFFERS TO FILL',fromIndex,indexExec);
							const deducedIndex	=	(fromIndex > indexExec)  ? (indexExec) : (indexExec+1);
							execs.slice(Math.min(fromIndex,deducedIndex), Math.max(fromIndex, deducedIndex)).forEach(el =>{
								const findExec = this.pageInfo.offersSelected.execs.find(item => item.id == el.id);
								if(findExec) return;
								this.pageInfo.offersSelected.execs.push(el);
							})	
							// console.log(this.pageInfo.offersSelected);
							// console.log(indexExec);
						}else{
							this.commons.generateToast('_ERROR', '_DIFFERENT_DAY_SELECTED', 'error');
							return;
						}
					break;
					case $event.ctrlKey===true :
						console.log('CLICKED CTRL');
						this.pageInfo.offersSelected.idDay	=	+(this.pageInfo.offersSelected.idDay.toString() || indexDay.toString());
						if(indexDay == this.pageInfo.offersSelected.idDay){
							const findOffer	= this.pageInfo.offersSelected.execs.findIndex(el => el.id == execs[indexExec].id);
							if(findOffer > -1 ){
								this.pageInfo.offersSelected.execs.splice(findOffer,1);
							}else{
								this.pageInfo.offersSelected.execs.push(execs[indexExec])
							}
						}else{
							this.pageInfo.offersSelected.idDay = indexDay;
							this.pageInfo.offersSelected.execs = [];
							this.pageInfo.offersSelected.execs.push(execs[indexExec]);
						}
							
					break;
					default: return;
			}
		}else{
			const findOffer	= this.pageInfo.offersSelected.execs.findIndex(el => el.id == execs[indexExec].id);
			if(findOffer > -1 ){
				this.pageInfo.offersSelected.idDay	=	null;
				this.pageInfo.offersSelected.execs	=	[];
				// this.pageInfo.offersSelected.splice(findOffer,1);
			}else{
				this.pageInfo.offersSelected.execs	=	[];
				this.pageInfo.offersSelected.idDay	=	indexDay
				this.pageInfo.offersSelected.execs.push(execs[indexExec])
			}	
		}
		if(this.pageInfo.offersSelected.execs.length > 0){
			this.changeStatusGButton('exec','displayCopy',true);
			this.changeStatusGButton('exec','displayDelete',true);
		}else{
			this.changeStatusGButton('exec','displayCopy',false);
			this.changeStatusGButton('exec','displayDelete',false);
		}
	}

	onRightClickDay($event, contextMenu,idDay, day){

		this.pageInfo.displayContextDayOpt	=	this.pageInfo.contextMenuDayOptions;
		const findExecs						=	this.tableOffers.template.find(el => el.day == idDay);
		if(findExecs && findExecs.offers.length > 0){
			if(this.pageInfo.offersSelected.execs.length == 0){
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions.filter( el => el.id != 'paste');
			}else{
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions;
				let pasteLabel							=	this.pageInfo.displayContextDayOpt.find(el => el.id == 'paste');
				pasteLabel.label 						= 	this.pageInfo.offersSelected.execs.length > 1 ?  (this.commons.getTranslate('_PASTE_MULTIPLES')) : (this.commons.getTranslate('_PASTE_OFFER')+' '+this.pageInfo.offersSelected.execs[0].value);
			}
			this.pageInfo.contextMenuDay.tmpRightClick 	= 	{day : day, idDay : idDay, execs :findExecs.offers}; 
		}else{
			if(this.pageInfo.offersSelected.execs.length > 0){
				this.pageInfo.displayContextDayOpt		=	this.pageInfo.contextMenuDayOptions.filter(el => el.id == 'paste');
				let pasteLabel							=	this.pageInfo.displayContextDayOpt.find(el => el.id == 'paste');
				pasteLabel.label 						= 	this.pageInfo.offersSelected.execs.length > 1 ?  (this.commons.getTranslate('_PASTE_MULTIPLES')) : (this.commons.getTranslate('_PASTE_OFFER')+' '+this.pageInfo.offersSelected.execs[0].value);  

			}else{
				this.pageInfo.displayContextDayOpt		=	[];
			}
			// this.pageInfo.contextMenuDay.tmpRightClick	=	{day : day,idDay : idDay}
		}
		this.pageInfo.contextMenuDay.day			=	day;
		this.pageInfo.contextMenuDay.idDay			=	idDay;
		contextMenu.show($event); 
		$event.stopPropagation();
	}
	onRightClickExec($event, contextMenu){
		this.pageInfo.displayContextExecOpt	=	this.pageInfo.contextMenuExecOptions;
		contextMenu.show($event);$event.stopPropagation();
	}
	getBackgroundRibbon(execs,i){
		if(!this.pageInfo.offersSelected) return '#3e9ac3';
		return this.pageInfo.offersSelected.execs.find(el => el.id == execs[i].id) ? 'tomato' : '#3e9ac3';
	}
	compareExecs(el, offerCopied){
		console.log('COMPARE');
		const offerDay 		= 	JSON.parse(JSON.stringify(el));
		const offerToCopy	=	JSON.parse(JSON.stringify(offerCopied));
		delete offerDay.id;
		delete offerDay.editMode;
		delete offerToCopy.id;
		delete offerToCopy.editMode;
		return JSON.stringify(offerDay) == JSON.stringify(offerToCopy);
	}
	validateRow(data){
		if(Object.keys(data.type).length == 0)													{ this.commons.generateToast('_ERROR','_NO_TYPE_ASSIGNED','error'); return false; }
		if(data.type.code == 'promotion' && data.value == null)									{ this.commons.generateToast('_ERROR','_NO_VALUE_ASSIGNED','error'); return false; }
		if(data.type.code == 'discount' && (data.value >100 || data.value <= 0))				{ this.commons.generateToast('_ERROR','_BAD_DISCOUNT', 'error'); return false; }
		if((data.startTime == null || data.endTime == null) || (data.startTime > data.endTime))	{ this.commons.generateToast('_ERROR','_BAD_FORMED_TIME','error'); return false; }
		return true;
	}
	getLangProfile(type){
		const foundProfile = this.pageInfo.profiles.find(el => el.value == type);
		return foundProfile ? foundProfile.label : null;	
	}
	restartLastRow(){
		this.pageInfo.lastRowOffers	=	{
			id          : null,
			editMode    : false,
			startTime   : "00:00",
			endTime     : "23:59",
			type        : {},
			value       : null,
			profiles    : [ 'adult', 'children', 'infant' ],
			turns       : []
		}
	}
	saveLastRow(){
		const validatedRow 	= this.validateRow(this.pageInfo.lastRowOffers);
		if(validatedRow){
			this.pageInfo.lastRowOffers.value 		= this.pageInfo.lastRowOffers.value['code'] ? this.pageInfo.lastRowOffers.value['code'] : this.pageInfo.lastRowOffers.value; 
			this.pageInfo.lastRowOffers.startTime 	= this.commons.getFormatDateHour(this.pageInfo.lastRowOffers.startTime)
			this.pageInfo.lastRowOffers.endTime		= this.commons.getFormatDateHour(this.pageInfo.lastRowOffers.endTime);	
			const sameValue							= this.pageInfo.daySelected.offers.some(el => this.compareExecs(el,this.pageInfo.lastRowOffers));
			if(sameValue){ this.commons.generateToast('_ERROR','_OFFER_ALREADY_EXISTS','error'); return;}
			this.emiterOption.emit({type:'lastRow', data : {daySelected : this.pageInfo.daySelected, exec : JSON.parse(JSON.stringify(this.pageInfo.lastRowOffers))}});
			this.toggleLastRow(false);
		}
	}
	saveLastRowKeyUp($event){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		this.saveLastRow();
	}
	mergeDay(fromContextMenu){
		const foundDay = this.tableOffers.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
		if(foundDay > -1){
			let tmpDaySelected			= JSON.parse(JSON.stringify(this.tableOffers.template[foundDay]));
			this.pageInfo.daySelected 	= this.tableOffers.template[foundDay];

			this.pageInfo.offersSelected.execs.forEach(el=>{
				if(this.tableOffers.template[foundDay].offers.some(offerCopied => this.compareExecs(el, offerCopied))) return;
				const newExec 	= 	JSON.parse(JSON.stringify(el));
				newExec.id		=	this.commons.generateHash();
				tmpDaySelected.offers.push(newExec);
			}) 
			this.mergeHoursPeriod(tmpDaySelected);
		}else{
			this.addHoursToEmptyDay();
		}
		if(fromContextMenu){
			this.selectDay(this.pageInfo.contextMenuDay.day,this.pageInfo.contextMenuDay.idDay,''); 
		}
	}
	mergeHoursPeriod(tmpDaySelected){
		this.emiterOption.emit({type:'merge', data : {daySelected : this.pageInfo.daySelected, tmp	:	tmpDaySelected}});
	}
	addHoursToEmptyDay(){
		this.tableOffers.template.push(
			{	day			:	this.pageInfo.contextMenuDay.idDay, 
				selected	:	true,
				turns		:	['M','A'],
				offers		: 	[]
			});
		let tmpDaySelected			=	JSON.parse(JSON.stringify(this.tableOffers.template[this.tableOffers.template.length -1]));
		tmpDaySelected.offers		=	this.pageInfo.offersSelected.execs
											.map( el =>{
												const newExec = JSON.parse(JSON.stringify(el));
												newExec.id = this.commons.generateHash(); 
												return newExec; 
											})
		this.pageInfo.daySelected = this.tableOffers.template[this.tableOffers.template.length -1];
		this.mergeHoursPeriod(tmpDaySelected);
	}
	overwriteDay(fromContextMenu){
		const foundDay	=	this.tableOffers.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
		if(foundDay > -1){
			let tmpDaySelected			=	JSON.parse(JSON.stringify(this.tableOffers.template[foundDay]));
			this.pageInfo.daySelected 	= this.tableOffers.template[foundDay];

			tmpDaySelected.offers	=	[];
			tmpDaySelected.offers	=	this.pageInfo.offersSelected.execs
												.map( el =>{
													const newExec = JSON.parse(JSON.stringify(el));
													newExec.id = this.commons.generateHash(); 
													return newExec; 
												});
			this.mergeHoursPeriod(tmpDaySelected);
		}else{
			this.addHoursToEmptyDay();
		}
		if(fromContextMenu){
			this.selectDay(this.pageInfo.contextMenuDay.day, this.pageInfo.contextMenuDay.idDay,'');
		}
	}
	deleteExecs(){
		let foundDay				=	this.tableOffers.template.find(item => item.day == this.pageInfo.offersSelected.idDay);
		if(!foundDay)	return this.commons.generateToast('_ERROR','_COULD_NOT_FIND_DAY', 'error');
		this.pageInfo.daySelected	=	foundDay;
		let offersToDelete			=	foundDay.offers.filter(el => this.pageInfo.offersSelected.execs.some(item => item.id == el.id));
		console.log('OFFERS TO DELETE',offersToDelete);
		this.deleteMultipleExecs(offersToDelete);
	}
	deleteMultipleExecs(offersToDelete){
		this.emiterOption.emit({type:'deleteMultiple', data : {daySelected : this.pageInfo.daySelected, offersToDelete : offersToDelete}});
	}
	checkIfStartGButton(){
		return (Object.values(this.pageInfo.controllerGButton.day).every(el => el==false) && Object.values(this.pageInfo.controllerGButton.exec).every(el => el==false));
	}
	changeStatusGButton(type,property,value){
		this.pageInfo.controllerGButton[type][property] = value;
		console.log(this.pageInfo.controllerGButton);
	}
	getStatusGButton(type,property){
		return !this.pageInfo.controllerGButton[type][property];
	}
	restartGButton()	{
		let entriesDay	=	this.pageInfo.controllerGButton.day;
		let entriesExec	=	this.pageInfo.controllerGButton.exec;
		Object.keys(entriesDay ).forEach(entry =>{ this.pageInfo.controllerGButton.day [entry] = false; });
		Object.keys(entriesExec).forEach(entry =>{ this.pageInfo.controllerGButton.exec[entry] = false; });
	}
	copyDayGB()			{
		// this.pageInfo.contextMenuDay.idDay				=	this.pageInfo.daySelected.day;
		this.pageInfo.contextMenuDay.tmpRightClick		=	{day:null, idDay:this.pageInfo.daySelected.day, execs : this.pageInfo.daySelected.offers};
		this.pageInfo.offersSelected					=	{idDay	:	this.pageInfo.daySelected.day, execs	:	this.pageInfo.daySelected.offers};
	}
	deleteDayGB()		{
		this.confirmCtrl.confirm({
			message : 	this.commons.getTranslate('_CONFIRM_REMOVE_DAY_PERIOD'),
			header	:	this.commons.getTranslate('_DELETE_PERIOD'),
			icon	:	'pi pi-exclamation-triangle',
			accept	:	()=>{
				// const foundDay = this.tablePrice.template.findIndex(el => el.day == this.pageInfo.contextMenuDay.idDay);
				// if(foundDay == -1) return;
				// this.pageInfo.daySelected = this.tablePrice.template[foundDay];
				this.restartRow();   
			}
		});	
	}
	copyExecsGB()		{ this.pageInfo.contextMenuExec.execsCopied =   this.pageInfo.offersSelected; }
	onSelectStartHour()	{
		if(this.startHour.currentMinute == 59){
			this.endHour.currentHour = this.startHour.currentHour == 23 ? 0 : this.startHour.currentHour +1;
			this.endHour.currentMinute = 0
		}else{
			this.endHour.currentHour = this.startHour.currentHour;
			this.endHour.currentMinute = this.startHour.currentMinute + 1;
		}
	}
}