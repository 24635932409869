import { FirebaseService 				} from '../database/firebase.service';
import { CommonsService 				} from '../commons.service';
import { Injectable               		} from '@angular/core';
import { AngularFirestore				} from '@angular/fire/firestore';
import { TransportDriverService 		} from './driver.service';
import { TransportFleetService 			} from './fleet.service';
import { TransportTransporterService 	} from './transporter.service';
import { TransportTypeService 			} from './type';

@Injectable()
export class TransportService {

	// plainServices		: any[]		= [];
	// transportsCalendar	: any 		= { rows	: [{ empty: true, items: [] }],
	// 									tmpRows	: [{ emtpy: true, items: []	}]
	// 								};
  	constructor(
		private afs     			: AngularFirestore,
		private commons 			: CommonsService,
		private driverService		: TransportDriverService,
		private fleetService		: TransportFleetService,
		private transporterService	: TransportTransporterService,
		private typeService			: TransportTypeService
	){}

	/**
	 * find a group where to fit booking
	 *
	 * @param $params
	 * @returns
	 */
	findSuitableGroup($params){
		let bookings		= $params["bookings"];
		let mappedGroups	= $params["groups"	];
		let booking			= $params["booking"	];
		let gap				= $params["gap"		];

		let groups			= Object.keys(mappedGroups).reduce((a,item)=>{
								mappedGroups[item]["name"] = item;
								a.push(mappedGroups[item]);
								return a;
							},[]);

		// Find existing groups fitable
		let availables	= 	groups
								.filter(item=>	item.zone		== booking.zone			)
								.filter(item=>	item.direction 	== booking.direction	)
								.filter(item=>	this.checkGroupGap({
													group 		: item,
													booking		: booking,
													bookings	: bookings
												})
								);

		if((availables||[]).length>0){
			return availables?availables[0].name:undefined;
		}
	}

	public checkGroupGap($params){
		let booking 	= $params["booking"		];
		let group		= $params["group"		];
		let bookings	= $params["bookings"	];
		let waitTime	= this.commons.pageInfo.shuttleWaitTime * 60;

		let gap = group.bookings.reduce((f,bg)=>{
			let groupBooking = bookings.find(b=>b.reference==bg);
			if(!booking){
				return false;
			}
			f = f && (Math.abs(groupBooking.init_timestamp-booking.init_timestamp)<waitTime);
			return f;
		},true);
		return gap;
	}

	/**
	 * return minuts from midnight for a date
	 * @param $date
	 * @returns
	 */
	private getDateMinutes($a,$b){
		return ($a-$b)/60;
	}

	/**
	 * set group init time and other helper times as timepstamps
	 *
	 */
	 public setGroupFirstTime($params){
		let group 	= $params["group"];
		let date	= $params["date"];

		let startOfDay, endOfDay;

		if(!date){
			this.commons.generateToast("_ERROR","_NO_DATE","error");
			return false;
		}

		startOfDay	= this.commons.getDate(date+" 00:00:00").unix();
		endOfDay	= this.commons.getDate(date+" 23:59:59").unix();

		// Set BI flight DateTime
		group.bookingsInfo = (group.bookingsInfo||[]).map(bi=>{
			switch(bi.direction){
				case "arrival"	:	if(undefined==bi.arrival_Date){
										console.log("[setGroupFirstTime] No arrival date for reference",bi["reference"]);
										return bi;
									}
									if(undefined==bi.arrival_Time){
										console.log("[setGroupFirstTime] No arrival flight time for reference",bi["reference"]);
										bi.arrival_DateTime = undefined;
										return bi;
									}
									bi.arrival_DateTime		= this.commons.getDate(bi["arrival_Date"]+" "+bi["arrival_Time"]);
									break;

				case "departure":	if(undefined==bi.departure_Date){
										console.log("[setGroupFirstTime] No departure pickup date for reference",bi["reference"]);
										return bi;
									}
									if(undefined==bi.departure_Time){
										console.log("[setGroupFirstTime] No departure flight time for reference",bi["reference"]);
										bi.departure_DateTime = undefined;
										return bi;
									}
									bi.departure_DateTime	= this.commons.getDate(bi["departure_Date"]+" "+bi["departure_Time"]);
									break;
			};
			return bi;
		})
		// Order by firstTime
		.sort((a,b)=>{
			switch(group.direction){
				case "arrival"	:	return a.arrival_DateTime	>= b.arrival_DateTime	?1:-1;
				case "departure":	return a.departure_DateTime	>= b.departure_DateTime	?1:-1;
			}
		});

		// Generate first service time
		switch(group.direction){
			case "arrival"	:	this.generateGroupArrivalInit		({ 	group		: group,
																		startOfDay	: startOfDay,
																		first		: group.bookingsInfo[0].arrival_DateTime
																	});
								break;

			case "departure":	// BI has to been ordered by flightTime in line 137
								this.generateGroupDeparturePickup	({ 	
									group		: group,
									startOfDay	: startOfDay
								});

								// Save group pickuptime into every booking for persistence
								if(group.departure_PickupDateTime){
									group.bookingsInfo.forEach(bi=>{
										bi.group_Departure_PickupTime = group.departure_PickupDateTime.format("HH:mm");
										if(!bi.departure_PickupTime){
											bi.departure_PickupTime = bi.group_Departure_PickupTime;
											bi.generated_PickupTime	= true;
										}
										if(!bi.departure_PickupLocation){
											bi.departure_PickupLocation = group.departure_PickupLocation;
										}
									});
								} else {
									console.log("No GDTDT");
								}
								break;
		}

		// Check for first group flight
		group.bookingsInfo = (group.bookingsInfo||[]).map(bi=>{
			switch(bi.direction){
				case "departure"	:	// Check anomalies
										// Treat on PROVIDER controller
										switch(parseInt(bi.provider)){
											case 15:	if(bi.departure_DateTime==bi.departure_PickupDateTime){
															// Non TRIPULATION
															bi.departure_DateTime = undefined;
														} else {
															// TRIPuLATION
														}
														break;
										}

										// Get group first Time
										// a) undefined
										if(undefined==group.departure_DateTime){
											group.departure_DateTime = bi.departure_DateTime;
										}

										// b) earlier
										if ( bi.departure_DateTime.unix()<group.departure_DateTime){
											group.departure_DateTime = bi.departure_DateTime;
										}
										break;
			}
			return bi;
		});

		// // FLIGHT TIME
		// if(undefined==bi.departure_DateTime){
		// 	if(undefined==bi.departure_Date){
		// 		return bi; }
		// 	if(undefined==bi.departure_Time){
		// 		return bi; }

		// 	bi.departure_DateTime = this.commons.getDate(bi["departure_Date" ]+" "+bi["departure_Time"	]);
		// 	if(!bi.departure_DateTime.isValid()){
		// 		return bi; }
		// }

		// set group first time to all bookings
		group.bookingsInfo.forEach(bi=>{ bi.group_firstTime = group.firstTime;});

		// Check if group generated pickuptime
		if(group.bookingsInfo.some(bi=>bi.departure_PickupTime)){
			console.log("[generateGroupDeparturePickupTime] bookings group have already departure pickuptime defined");
			group.generated_PickupTime			= false;

			group.bookingsInfo.forEach(bi=>{
				bi.group_firstTime 				= group.firstTime;
				bi.generated_PickupTime			= false;
			});
		}

		return group;
	};

	/**
	 * generate Group Arrival service init time
	 */
	generateGroupArrivalInit($params){

		let group 						= $params["group"];
		let startOfDay					= $params["startOfDay"];
		let firstBookingFlightDateTime  = $params["first"];

		let addedWaitTime;
		let addedRouteTime;
		let waitingTime;

		// 1. CHECK FLIGHT
		if(undefined==firstBookingFlightDateTime){
			console.log("[generateGroupArrivalInit] No arrival first time");
			return false;
		}
		if(!firstBookingFlightDateTime.isValid()){
			group.bookingsInfo[0].arrival_DateTime = undefined;
			console.log("[generateGroupArrivalInit] Mo valid arrival first time");
			return false;
		}
		group["firstTimestamp"	] 	= firstBookingFlightDateTime.unix();
		group["firstDateTime"	] 	= firstBookingFlightDateTime.format("YYYY-MM-DD HH:mm");
		group["firstTime"		] 	= firstBookingFlightDateTime.format("HH:mm");

		// 2. ADD WAITING TIME ( noarmilly 1 hour ) TO FIRST ARRIVAL
		waitingTime		= this.commons.pageInfo.waitingTimes.shuttleArrival || this.commons.pageInfo.waitingTimes.shuttleArrival;
		addedWaitTime	= this.commons.addTimeToDate({
			"date" 	: group["firstDateTime"],
			"time"	: waitingTime,
			"unit"	: "m"
		});

		group["pickupDateTime"	]	= addedWaitTime.format("YYYY-MM-DD HH:mm");
		group["pickupTime"		]	= addedWaitTime.format("HH:mm");

		group["serviceDateTime"	]	= group["pickupDateTime"	];
		group["serviceTime"		]	= group["pickupTime"		];

		(group.bookingsInfo||[]).forEach(bi=>{
			bi["arrival_PickupTime"	] = group["pickupTime"];
		});

		// SERVICE INIT
		group["init_timestamp"	]	= addedWaitTime.unix();
		group["init"			] 	= this.getDateMinutes(group["init_timestamp"],startOfDay);

		// SERVCIE END. INIT + ROUTE + DELIVERY LAST CURSTOM
		addedRouteTime	= this.commons.addTimeToDate({
			"date" 	: group["pickupDateTime"],
			"time"	: group["routeTime"],
			"unit"	: "m"
		});

		// Substract time per lodging
		let lodgings							= group.bookingsInfo.reduce((a,bi)=>{
			if(undefined!=bi.departure_Location){
				if(!a.some(l=>l==bi.departure_Location)){
					a.push(bi.departure_Location);
				}
			}
			return a;
		},[]);

		let lodgingsQty							= (lodgings||[]).length;
		let pickupTimesByLodgings				= (lodgingsQty>0?lodgingsQty-1:0)*this.commons.pageInfo.pickupTimes.perLodging;

		// ADD TIMES
		group.end_timestamp						= addedRouteTime.unix() + pickupTimesByLodgings;
		group.end								= this.getDateMinutes(group["end_timestamp"],startOfDay);
	}

	/**
	 * generate Group departure first pickup
	 * Calculate as
	 * 		1st_departure_flight - airport_waiting_time - route_time - pickups
	 */
	generateGroupDeparturePickup($params){

		let group 						= $params["group"];
		let startOfDay					= $params["startOfDay"];

		// Substract time per lodging
		let lodgings					= group.bookingsInfo.reduce((a,bi)=>{
			if(undefined!=bi.departure_Location){
				if(!a.some(l=>l==bi.departure_Location)){
					a.push(bi.departure_Location);
				}
			}
			return a;
		},[]);

		let lodgingsQty					= (lodgings||[]).length;
		let pickupTimesByLodgings		= (lodgingsQty>0?lodgingsQty-1:0)*this.commons.pageInfo.pickupTimes.perLodging;

		let airportWaitingTime			= this.commons.pageInfo.waitingTimes.airport;
		if(this.commons.userInfo.destination && this.commons.userInfo.destination.waitingTime){
			airportWaitingTime = this.commons.userInfo.destination.waitingTimes.airport;
		}

		group.routeTime 				= this.commons.pageInfo.distances[this.commons.pageInfo.base][group.zone] || 0;

		// NOT CALCULATE
		group.bookingsInfo = (group.bookingsInfo||[]).map(bi=>{
			if(bi.departure_PickupDateTime	){ return bi; 	}
			if(!bi.departure_Date			){ return bi;	}
			if(bi.departure_PickupTime){
				bi.departure_PickupDateTime = this.commons.getDate(bi["departure_Date"]+" "+bi["departure_PickupTime"]);
			}
			// Set PickupLocation
			if(bi.departure_PickupLocation){
				group.departure_PickupLocation = bi.departure_PickupLocation;
			}
			return bi;
		});

		if(group.bookingsInfo.every(bi=>bi.departure_PickupDateTime)){
			group.departure_PickupDateTime = group.bookingsInfo.reduce((v,bi)=>{
				v = v<=bi.departure_PickupDateTime?v:bi.departure_PickupDateTime;
				return v;
			},group.bookingsInfo[0].departure_PickupDateTime);
			group.departure_PickupTime		= group.departure_PickupDateTime.format("HH:mm");
		}

		// If group pickup_date_time has been set just return
		let alreadyPickupTime = group.departure_PickupDateTime && group.departure_PickupDateTime.isValid();

		switch(alreadyPickupTime){
			case true	:	
				console.log("[generateGroupDeparturePickup] Group depature_DateTime already defined");
				group.generated_PickupTime		= false;
				break;

			default		:	
				// CALCULATE PICKUPTIME
			// If pickupTime no defined calculate it !
			console.log("[generateGroupDeparturePickup] Generating pickups");

			let firstBookingFlightDateTime  = group.bookingsInfo[0].departure_DateTime;

			if(undefined==firstBookingFlightDateTime){
				console.log("[generateGroupDeparturePickup] No first time");
				return group;
			}

			if(!firstBookingFlightDateTime.isValid()){
				group.bookingsInfo[0].departure_DateTime = undefined;
				console.log("[generateGroupDeparturePickup] No valid departure first time");
				return group;
			}

			// 1. Set group flight time from bookings
			group["firstTimestamp"	] 		= firstBookingFlightDateTime.unix();
			group["firstDateTime"	] 		= firstBookingFlightDateTime.format("YYYY-MM-DD HH:mm");
			group["firstTime"		] 		= firstBookingFlightDateTime.format("HH:mm");

			group.generated_PickupTime		= true;
			break;
		}

		// ROUTE
		let routeMinDuration		= parseInt(this.commons.pageInfo.route.min_duration);
		let routeDuration			= pickupTimesByLodgings + group.routeTime;
		routeDuration				= routeDuration < routeMinDuration ? routeMinDuration : routeDuration;

		// SERVICE INIT
		if(group.departure_PickupDateTime){
			// DEFINED PICKUP TIME
			group.init_timestamp					= group.departure_PickupDateTime.unix();
			group.init 								= this.getDateMinutes(group.init_timestamp,	startOfDay);
			group.end_timestamp						= group.init_timestamp + routeDuration*60;
			group.departure_PickupTime 				= this.commons.getTimestampFormat(group.init_timestamp,"HH:mm");
		} else {
			// GENERATED PICKUP TIME
			group.end_timestamp						= group.firstTimestamp - airportWaitingTime*60;
			group.departure_LastPickupTime_timestamp= group.end_timestamp - group.routeTime*60;
			group.departure_PickupTime_timestamp	= group.departure_LastPickupTime_timestamp - pickupTimesByLodgings;
			group.init_timestamp					= group.departure_PickupTime_timestamp;
			group.init 								= this.getDateMinutes(group.init_timestamp,	startOfDay);
			group.departure_PickupDateTime			= this.commons.getTimestampMoment(group.init_timestamp);
			// group.departure_PickupTime 				= this.commons.getTimestampFormat(group.departure_PickupDateTime,"HH:mm");
			group.departure_PickupTime				= this.commons.getTimestampFormat(group.init_timestamp, "HH:mm");
		}

		group["serviceDateTime"	]	= group["departure_pickupDateTime"	];
		group["serviceTime"		]	= group["departure_pickupTime"		];

		// SERVICE END
		if(group.end_timestamp<group.init_timestamp){
			group.end_timestamp = group.init_timestamp;
		}
		group.end	= this.getDateMinutes(group.end_timestamp, startOfDay);

		return group;
	}

	/**
	 * generate groups name
	 *
	 * @param $params
	 */
	generateGroupName($params){
		let group 		= $params["group"];
		let groupId		= this.commons.pageInfo.groupId || 1;
		// let date		= this.commons.getNow("YYYYMMDDHHMM");
		// let date		= this.commons.getNow("YYYYMMDD");
		let groupPadId	= groupId.toString().padStart(5,'0');
		let date		= $params["date"];
		let groupName;
		let direction;

		switch($params["direction"]){
			case "arrival"	: direction="ARR"; break;
			case "departure": direction="DEP"; break;
			case "both"		: direction="BOTH"; break;
			default			:
				this.commons.generateToast("_ERROR","_GROUP_DIRECTION_NOT_FOUND","error");
				return false;
		}

		switch(group.private){
			case true	: groupName = "GR_PR_"+direction+"_"+date+"_"+groupPadId; break;
			case false	: groupName = "GR_SH_"+direction+"_"+date+"_"+groupPadId; break;
		}

		this.commons.pageInfo.groupId = groupId+1;
		return groupName;
	}

	/**
	 * generate only private groups
	 *
	 * @param $params
	 * @returns
	 */
	 generatePrivateGroups($params){
		let mappedGroups 	= {};
		// let groupId 		= 0;
		let groupName;
		// let waitTime 		= 70;
		let info			= {};
		let date			= this.commons.getNow("YYYYMMDDHHMM");

		// Generate private groups
		$params["bookings" ]
			.filter	(booking=>booking.shared=="private")
			.forEach(booking=>{

				switch($params["Mode"]){
					case "load"	:	if(undefined==booking.group){ return; }
									break;
					default		:	groupName 			= this.generateGroupName({ group: { private: true }, direction: booking.direction, date: $params.date });
									booking.group 		= booking.group || groupName;
									break;
				}
				booking.group_pax 	= booking.pax;
				
				switch(booking.direction){
					case "arrival":
						if(!booking.arrival_Time){
							console.log("[generatePrivateGroups] Error en booking",booking);
							break;
						}
						booking.area	= booking["arrival_To"] || booking["arrival_Area"] || booking["area"];						
						info 			= {
							provider				: booking.provider,
							environment				: booking.environment,
							name					: booking.group,
							transporter				: booking.transporter,
							transporter_status		: booking.transporter_status,
							transporter_location_id	: booking.transporter_location_id,
							status					: booking.transporter_status,
							direction				: "arrival",
							type					: "arrival",
							private					: booking.shared=="private"?true:false,
							solution				: 1,
							area					: booking.area,
							zone					: booking.zone,
							pax						: booking.pax,
							date					: booking.arrival_Date,
							arrival_Date			: booking.arrival_Date,
							waitTime				: this.commons.pageInfo.privateWaitTime,
							bookings				: [ booking.reference ],
							bookingsInfo			: [ booking ],
							reference				: booking.reference
						};

						if(booking.arrival_Fomento_Id		){ info["fomento_id"	] = booking.arrival_Fomento_Id; 		}
						if(booking.arrival_Fomento_Status	){ info["fomento_status"] = booking.arrival_Fomento_Status; 	}

						if(booking.transporter	){	info["transporter"	] = booking.transporter; 	}
						if(booking.vehicle		){	info["vehicle"		] = booking.vehicle; 		}
						if(booking.driver		){	info["driver"		] = booking.driver; 		}

						if(booking.zone)	{
							info["routeTime"] = this.commons.pageInfo.distances[this.commons.pageInfo.base][booking.zone] || 0;
						} else 				{
							booking.errors = [ ...(booking.errors||[]),"_ZONE_NOT_FOUND" ];
							booking.group  = undefined;
							console.log("[generatePrivateGroups]",booking.error);
							return false;
						}

						mappedGroups[booking.group] = info;
						break;

				case "departure":
						booking.area	= booking["area"] || booking["departure_Area"] || booking["departure_From"];
						info = {
							provider				: booking.provider,
							environment				: booking.environment,
							name					: booking.group,
							transporter				: booking.transporter,
							transporter_status		: booking.transporter_status,
							transporter_location_id	: booking.transporter_location_id,							
							status					: booking.transporter_status,
							direction				: "departure",
							type					: "departure",
							private					: booking.shared=="private"?true:false,
							solution				: 1,
							area					: booking.area,
							zone					: booking.zone,
							pax						: booking.pax,
							date					: booking.departure_Date,
							arrival_Date			: booking.departure_Date,
							waitTime				: this.commons.pageInfo.privateWaitTime,
							bookings				: [ booking.reference ],
							bookingsInfo			: [ booking ],
							reference				: booking.reference
						};

						if(booking.departure_Fomento_Id		){ info["fomento_id"	] = booking.departure_Fomento_Id; 		}
						if(booking.departure_Fomento_Status	){ info["fomento_status"] = booking.departure_Fomento_Status; 	}


						if(booking.transporter	){	info["transporter"	] = booking.transporter; 	}
						if(booking.vehicle		){	info["vehicle"		] = booking.vehicle; 		}
						if(booking.driver		){	info["driver"		] = booking.driver; 		}

						if(booking.zone)	{
							info["routeTime"] = this.commons.pageInfo.distances[this.commons.pageInfo.base][booking.zone] || 0;
						} else 				{
							booking.errors = [ ...(booking.errors||[]),"_ZONE_NOT_FOUND" ];
							booking.group  = undefined;
							console.log("[generatePrivateGroups]",booking.errors);
							return false;
						}

						mappedGroups[booking.group] = info;
						break;
				}
		})

		// Set init time for each group depending on bookings inside
		Object.keys(mappedGroups).forEach(groupName=>{
			let group = mappedGroups[groupName];
			mappedGroups[groupName] 	= this.setGroupFirstTime({
											group	: group,
											date	: $params["date"]
										});
		});

		let response = {
			count		:	Object.keys(mappedGroups).length,
			solution	: 	1,
			items		:	Object.keys(mappedGroups).map(group=>mappedGroups[group])
		};

		return response;
	}

	/**
	 * generates only shuttles and speedy shuttles
	 *
	 * @param $params
	 * @returns
	 */
	 generateShuttleGroups($params){
		let mappedGroups 	= {};
		let groupName;
		let info			= {};
		let groups			= $params["groups"] || [];
		let date			= this.commons.getNow("YYYYMMDDHHMM");

		$params["bookings" ]
			.filter	(booking=>booking.shared!="private")
			.forEach(booking=>{
				
				if(!booking.group){
					switch($params["mode"]){
						case "load"	:	return;
						default		:	
							switch($params.maxItems){
								case 1	: 	break;
								default	:	booking.group = this.findSuitableGroup({
												bookings: $params["bookings"],
												// groups	: $params["groups"],
												groups	: mappedGroups,
												booking	: booking,
												gap		: 45
											});
											break;
							}

							if(!booking.group){
								booking.group	= this.generateGroupName({ 
									group		: { private: false },  
									direction	: booking.direction, 
									date		: $params.date 
								});
							}
							break;
					}
				}

				switch(booking.direction){

					case "arrival":
						if(!booking.arrival_Time){
							console.log("[(generateShut)tleGroups] Error en arrival booking",booking);
							break;
						}

						if(undefined==mappedGroups[booking.group]){
							info = {
								provider				: booking.provider,
								environment				: booking.environment,
								name					: $params["groupName"] || booking.group,
								direction				: "arrival",
								// type					: "arrival",
								transporter				: booking.transporter,
								transporter_status		: booking.transporter_status,
								transporter_location_id	: booking.transporter_location_id,						
								private					: booking.shared=="private"?true:false,
								solution				: 1,
								area					: booking.area,
								zone					: booking.zone,
								pax						: booking.pax,
								date					: booking.arrival_Date,
								arrival_Date			: booking.arrival_Date,
								waitTime				: this.commons.pageInfo.shuttleWaitTime,
								bookings				: [ booking.reference ],
								bookingsInfo			: [ booking ],
								assigned				: booking.vehlcle?true:false
							};

							if(booking.arrival_Fomento_Id		){ info["fomento_id"	] = booking.arrival_Fomento_Id; 		}
							if(booking.arrival_Fomento_Status	){ info["fomento_status"] = booking.arrival_Fomento_Status; 	}

							if(booking.transporter	){	info["transporter"	] = booking.transporter; 	}
							if(booking.vehicle		){	info["vehicle"		] = booking.vehicle; 		}
							if(booking.driver		){	info["driver"		] = booking.driver; 		}

							if(booking.zone)	{ info["routeTime"] = this.commons.pageInfo.distances[this.commons.pageInfo.base][booking.zone] || 0;	}
							else 				{
								booking.errors = [ ...(booking.errors||[]),"_ZONE_NOT_FOUND" ];
								booking.group  = undefined;
								console.log("[generateShuttleGroups]",booking.error);
								return false;
							}

							mappedGroups[booking.group] = info;
							booking.group_pax = mappedGroups[booking.group].pax;

						} else {
							// Check first and last Time
							let currentDateTime 	= booking["arrival_Date"]+" "+booking["arrival_Time"];
							let currentTimestamp 	= this.commons.getDate(info["firstDateTime"]).unix();

							// Ada paxes to group
							mappedGroups[booking.group].pax = parseInt(mappedGroups[booking.group].pax)+ parseInt(booking.pax);
							mappedGroups[booking.group].bookings.push(booking.reference);
							mappedGroups[booking.group].bookingsInfo.push(booking);

							if(mappedGroups[booking.group]){
								booking.group_pax = mappedGroups[booking.group].pax;
							} else {
								console.log("no pax");
							}
						}

						break;

				case "departure":
						if(!booking.departure_Time){
							console.log("[generateShuttleGroups] Error en booking",booking);
							break;
						}

						if(undefined==mappedGroups[booking.group]){
							info = {
								provider				: booking.provider,
								environment				: booking.environment,
								name					: $params["groupNAme"] || booking.group,
								direction				: "departure",
								// type					: "departure",
								transporter				: booking.transporter,
								transporter_status		: booking.transporter_status,								
								transporter_location_id	: booking.transporter_location_id,							
								private					: booking.shared=="private"?true:false,
								solution				: 1,
								area					: booking.area,
								zone					: booking.zone,
								pax						: booking.pax,
								date					: booking.departure_Date,
								departure_Date			: booking.departure_Date,							
								waitTime				: this.commons.pageInfo.shuttleWaitTime,
								bookings				: [ booking.reference ],
								bookingsInfo			: [ booking ],
								assigned				: booking.vehlcle?true:false
							};

							if(booking.departure_Fomento_Id		){ info["fomento_id"	] = booking.departure_Fomento_Id; 		}
							if(booking.departure_Fomento_Status	){ info["fomento_status"] = booking.departure_Fomento_Status; 	}


							if(booking.transporter	){	info["transporter"	] = booking.transporter; 	}
							if(booking.vehicle		){	info["vehicle"		] = booking.vehicle; 		}
							if(booking.driver		){	info["driver"		] = booking.driver; 		}

							if(booking.zone)	{ info["routeTime"] = this.commons.pageInfo.distances[this.commons.pageInfo.base][booking.zone] || 0;	}
							else 				{
								booking.errors = [ ...(booking.errors||[]),"_ZONE_NOT_FOUND" ];
								booking.group  = undefined;
								console.log("[generateShuttleGroups]",booking.error);
								return false;
							}

							// Set pickup time and pickup location
							["departure_PickupTime","departure_PickupLocation"].forEach(token=>{
								if(booking[token]){
									info[token] = booking[token];
								}
							});

							mappedGroups[booking.group] = info;
							booking.group_pax = mappedGroups[booking.group].pax;

						} else {
							// Add booking pax to group pax
							mappedGroups[booking.group].pax = parseInt(mappedGroups[booking.group].pax)+ parseInt(booking.pax);
							mappedGroups[booking.group].bookings.push(booking.reference);
							mappedGroups[booking.group].bookingsInfo.push(booking);
							if(mappedGroups[booking.group]){
								booking.group_pax = mappedGroups[booking.group].pax;
							} else {
								console.log("no pax");
							}
						}
						break;
				}
		})

		// Set init time for each group depending on bookings inside
		Object.keys(mappedGroups).forEach(groupName=>{
			let group = mappedGroups[groupName];
			mappedGroups[groupName] 	= this.setGroupFirstTime({
											group	: group,
											date	: $params["date"]
										});
		});

		return {
			count		:	Object.keys(mappedGroups).length,
			solution	: 	1,
			items		:	Object.keys(mappedGroups).map(group=>mappedGroups[group])
		};
	}

	/**
	 * generates shuttle group from bookings
	 *
	 * @param $params
	 * @returns
	 */
	generateShuttleGroup($params){
		let mappedGroups 	= {};
		let group			= $params["group"];
		let currentDateTime;
		let currentTimestamp;

		if(undefined==group){
			this.commons.generateToast("_ERROR","_WRONG_PARAMS","error");
			return false;
		}

		group["bookingsInfo" ]
			.forEach((booking,index)=>{
				switch(booking.direction){
					case "arrival":
						if(booking.arrival_Time){
							console.log("[(generateShut)tleGroups] Error en arrival booking",booking);
							break;
						}
						currentDateTime 	= booking["arrival_Date"]+" "+booking["arrival_Time"];
						currentTimestamp 	= this.commons.getDate(group["firstDateTime"]).unix();

						switch(index){
							case 0	:	if(booking.zone)	{
											group["routeTime"] = this.commons.pageInfo.distances[booking.zone][this.commons.pageInfo.base] || 0;
										}
										break;
						}
						break;

				case "departure":
						if(!booking.departure_Time){
							console.log("[generateShuttleGroups] Error en booking",booking);
							break;
						}
						currentDateTime 	= booking["departure_Date"]+" "+booking["arrival_Time"];
						currentTimestamp 	= this.commons.getDate(group["firstDateTime"]).unix();
						switch(index){
							case 0	:
										if(booking.zone)	{
											group["routeTime"] = this.commons.pageInfo.distances[this.commons.pageInfo.base][booking.zone] || 0;
										}
										break;
						}
				}
		})

		// Set init time for each group depending on bookings inside
		Object.keys(mappedGroups).forEach(groupName=>{
			let group = mappedGroups[groupName];
			mappedGroups[groupName] 	= this.setGroupFirstTime({
											group	: group,
											date	: $params["date"]
										});
		});

		return group;
	}

	/**
	 * save transport type aliases to mapped from provider type name to ours
	 *
	 * @param dmcId
	 * @param destinationId
	 * @param id
	 * @param alias
	 */
	 async saveAlias(dmcId, destinationId, id, alias){
		let fleetPath = this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('fleet');
		let fleetInfo = await fleetPath.doc(String(id).toString()).ref.get();
		if(fleetInfo.exists){
			let aliases = (fleetInfo["aliases"]||[]).filter(item=>item!=alias);
			aliases.push(alias)
			fleetPath.doc(String(id).toString()).update({ aliases: aliases });
		}
	}

	// FLEET
	async getFleet						( dmcId, destinationId					){ return this.fleetService.getFleet(dmcId,destinationId); 													}

	// DRIVERS
	async generateDriverCalendar		( $params								){ return this.driverService.generateDriverCalendar($params);												}
	async getDrivers					( dmcId, destinationId					){ return this.driverService.getDrivers(dmcId,destinationId);												}
	async saveDriver					( userInfo, dmc, destination			){ return this.driverService.saveDriver(userInfo,dmc,destination);											}
	async deleteDriver					( refDriver								){ return this.driverService.deleteDriver(refDriver);														}

	// TRANSPORTS TYPE
	async saveTypeTransport				( dmcId, destinationId, typeTransport	){ return this.typeService.saveTypeTransport(dmcId, destinationId, typeTransport);							}
	async deleteTypeTransport			( dmcId, destinationId, typeTransport	){ return this.typeService.deleteTypeTransport(dmcId, destinationId, typeTransport);						}

	// TRANSPORTERS
	async generateTransporterCalendar	( $params								){ return this.transporterService.generateTransporterCalendar($params);										}
	async getTransporters				( dmcId, destinationId					){ return this.transporterService.getTransporters(dmcId, destinationId);									}
	async saveTransporter				( $info									){ return this.transporterService.saveTransporter($info);													}
	async deleteTransporter				( transporter, dmcId,destinationId		){ return this.transporterService.deleteTransporter(transporter, dmcId, destinationId);						}
	async getTypeFleetTransporter		( infoTransporter						){ return this.transporterService.getTypeFleetTransporter(infoTransporter);									}
	async saveFleetExternalTransporter	( infoTransporter, dmcId, destinationId	){ return this.transporterService.saveFleetExternalTransporter(infoTransporter, dmcId, destinationId);		}
	async deleteFleetExternalTransporter( infoTransporter, dmcId, destinationId	){ return this.transporterService.deleteFleetExternalTransporter(infoTransporter, dmcId, destinationId);	}
}