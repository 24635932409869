export const filters = {
    consult : [{
		entity	: 'consult',
		name	: 'consult',
		label	: '_STATUS',
		type	: 'multiple',
		field	: 'type',
		items	: [
			{ label: '_ALL', 		value: '_ALL'			},
			{ label: '_PROCESSING', value: '_PROCESSING'	},
			{ label: '_PENDING',    value: '_PENDING'		},
			{ label: '_REJECTED',	value: '_REJECTED'		},
			{ label: '_RESOLVED',	value: '_RESOLVED'		},
		],
		selected: [ '_PROCESSING']
    }],
    issue : [{
		entity	: 'issue',
		name	: 'issue',
		label	: '_TYPE',
		type	: 'multiple',
		field	: 'type',
		items	: [
			{ label: '_ALL', 			value: '_ALL'			},
			{ label: '_PROCESSING', 	value: '_PROCESSING'	},
			{ label: '_PENDING',    	value: '_PENDING'		},
			{ label: '_REJECTED',		value: '_REJECTED'		},
			{ label: '_RESOLVED',		value: '_RESOLVED'		},

		],
		selected: [ '_ALL']
	}]
}
