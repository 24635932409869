export const buttons	= [
	{ 	name: 'reload',			label: '_RELOAD', 			icon: 'fa fa-refresh' 		, type: 'button'	},
	{ 	name: 'new_variant',	label: '_CREATE', 			icon: 'fa fa-plus' 			, type: 'button' 	},
	{ 	name: 'edit',			label: '_EDIT',				icon: 'fa fa-pencil'		, type: 'button',	conditional: 'itemSelected' 	},
	// { 	name: 'schedule',		label: '_SCHEDULER',		icon: 'fa fa-list'			, type: 'button',	conditional: 'itemSelected'  	},
	// { 	name: 'offers',			label: '_OFFERS',			icon: 'fa fa-money'			, type: 'button',	conditional: 'itemSelected'  	},
	{ 	name: 'pricing',		label: '_PRICING',			icon: 'fa fa-calendar'		, type: 'button',	conditional: 'itemSelected'  	},
	{ 	name: 'clone_variant',	label: '_CLONE',			icon: 'fa fa-clone'			, type: 'button',	conditional: 'itemSelected'  	},
	{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	},
	{ 	name: 'import',			label: '_IMPORT', 			icon: 'fa fa-upload' 		, type: 'upload'	}
];