import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { items 				} from './data/items';

@Component({
  selector    	: 'app-tour-wizard-complements',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class TourWizardComponentsComponent {

	@Input("type")    	type		: string	= "";
	@Input("info")		info		: any		= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {}
	draggedItem			: any = {};
	
	  
	constructor( private commons: CommonsService    	
  	) {}

  	ngOnInit()					{		
		this.info.complements	= this.info.complements || { included: [], notIncluded: [] };
	}

    action($type,$item){		
		switch($type){			
		}
	}

	dragStart($event,item) 			{ this.draggedItem 	= { item: item }; 	}
	dragEnd($event) 				{ this.draggedItem = null; 				}
    drop(group,$event) 				{
		
		switch(group){
			case "included"		: 
				if(this.info.complements.included.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_SERVICE_ALREADY_INCLUDED","info");
					return false;
				}
				if(this.info.complements.notIncluded.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_NOT_INCLUDED","error");
					return false;
				}				
				this.info.complements.included.push(this.draggedItem.item);
				break;
			case "notIncluded"	: 
				if(this.info.complements.notIncluded.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_NOT_INCLUDED","info");
					return false;
				}
				if(this.info.complements.included.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_INCLUDED","error");
					return false;
				}
				this.info.complements.notIncluded.push(this.draggedItem.item); 	
				break;
		}
	}

	remove($type,$item){
		switch($type){
			case "included"		: this.info.complements.included 	= this.info.complements.included.filter(item=>item.id!=$item.id); 	break;
			case "notIncluded"	: this.info.complements.notIncluded	= this.info.complements.notIncluded.filter(item=>item.id!=$item.id); 	break;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			case "complements"	: 	return this.info.entities[$entity].filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
		}
	}
}
