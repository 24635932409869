import { TourWizardI18nComponent } from './../../wizard/steps/i18n/component';
import { EntityService 			} from './../../../../demo/service/entity.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter     		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { wizardItems, entities 	} from './data/items';
import { TranslateService 		} from '@ngx-translate/core';

@Component({
  selector    	: 'app-tour-wizard',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class TourWizardComponent {

  	@Input("type")      type:string;	
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService,
		private translate      	: TranslateService        
	){
		this.init();
	}

	init()							{}
	loadEntities()					{ 	this.load("langs"); 
										this.load("sights"); 
										this.load("services");
										this.load("complements");
									}
	async ngOnInit()				{
		this.pageInfo.step					= 0;
		this.pageInfo.items					= wizardItems;		
		this.pageInfo.wizardInfo			= { ready : false, entities: {}};
		
		await this.loadEntities();

		this.pageInfo.wizardInfo.forms		= {};
		Promise.all(Object.keys(entities).map(async item=>{
			this.pageInfo.wizardInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		},{}));

		// INFO
		this.pageInfo.wizardInfo.forms.info = [
			{
				cols		: 5,
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'name', 						label:'_TOUR_INNER_NAME',			text: 'text',	editor: 'text', 	info: true		},
					{ mandatory: true, 	field	: 'active', 					label:'_ACTIVE', 					type: 'text', 	editor: 'toggle',	info: true		}					
				]
			}
			,{
				cols		: 7,
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ label:'_STEP_INFO_TOUR_LEGEND',	editor: 'info' }					
				]
			}
			,{
				cols		: 4,
				clear		: true,
				title		: "_WIZARD_INFO_MAIN",
				showTitle	: true,
				icon		: "fa fa-check",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'mode', 						label:'_TOUR_MODE',					type: 'text', 	editor: 'text' 															},
					{ mandatory: false, field	: 'coupon', 					label:'_COUPONS', 					type: 'text', 	editor: 'toggle' 														},
					{ mandatory: true, 	field	: 'price_garantied',			label:'_PRICE_GUARANTEED', 			type: 'text', 	editor: 'toggle' 														},
					{ mandatory: true, 	field	: 'instant_confirmation',		label:'_INSTANT_CONFIRMATION', 		type: 'text', 	editor: 'toggle' 														},
					{ mandatory: true, 	field	: 'coupon_type', 				label:'_COUPON_TYPE',				type: 'text', 	editor: 'autocomplete', multiple: true, entityList: 'couponTypeList' 	},
					{ mandatory: true, 	field	: 'groups', 					label:'_FOR_GROUPS',				type: 'text', 	editor: 'toggle' 														}
				]
			},			
			{
				cols		: 4,
				title		: "_WIZARD_INFO_OPTIONS",
				showTitle	: true,
				icon		: "fa fa-ban",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'items', 						label:'_OPTIONS_ITEMS', 			type: 'text', 	editor: 'toggle' 												},
					{ mandatory: true, 	field	: 'expect', 					label:'_OPTIONS_EXPECT', 			type: 'text', 	editor: 'toggle' 												},					
					{ mandatory: true, 	field	: 'itinerary', 					label:'_OPTIONS_ITINERARY', 		type: 'text', 	editor: 'toggle' 												},
					{ mandatory: true, 	field	: 'info_meeting',				label:'_OPTIONS_INFO_MEETING',		type: 'text', 	editor: 'toggle' 												}					
				]
			},
			{
				cols		: 4,
				title		: "_WIZARD_INFO_CANCELATION_POLICY",
				showTitle	: true,
				icon		: "fa fa-ban",
				padding		: "1rem 1rem 1rem 1rem",
				items		: [
					{ mandatory: true, 	field	: 'cancel_available', 			label:'_CANCEL_AVAILABLE', 			type: 'text', 	editor: 'toggle' 																},
					{ mandatory: true, 	field	: 'cancel_reason', 				label:'_CANCEL_REASON', 			type: 'text', 	editor: 'autocomplete', multiple: true, entityList: 'cancelReasonList' 			},
					{ mandatory: true, 	field	: 'cancel_devolution_period',	label:'_CANCEL_DEVOLUTION_PERIOD', 	type: 'number',	editor: 'number'	 															},
					{ mandatory: true, 	field	: 'cancel_devolution_method',	label:'_CANCEL_DEVOLUTION_METHOD', 	type: 'text',	editor: 'autocomplete', multiple: true, entityList: 'cancelDevolutionMethodList'},
					{ mandatory: true, 	field	: 'cancel_conditions', 			label:'_CANCEL_CONDITIONS', 		type: 'text', 	editor: 'autocomplete', multiple: true, entityList: 'cancelConditionList'		}
				]
			}
			,{
				cols		: 4,
				clear		: true,
				icon		: "fa fa-check",
				padding		: "0.5rem 1rem 0.5rem 0rem",
				items		: [
					{ mandatory: true, 	field	: 'confirmation',				label:'_OPTIONS_CONFIRMATION',		type: 'text', 	editor: 'list', 	conditional: true, entityList: 'infoConfirmationList'	}
				]
			}
			,{
				cols		: 4,
				icon		: "fa fa-check",
				padding		: "0.5rem 1rem 0.5rem 0rem",
				items		: [
					{ mandatory: true, 	field	: 'info_additional',			label:'_OPTIONS_INFO_ADDITIONAL',	type: 'text', 	editor: 'list', 	conditional: true, entityList: 'infoAdditionalList' 	},
					{ mandatory: true, 	field	: 'info_steps', 				label:'_OPTIONS_INFO_STEPS', 		type: 'text', 	editor: 'list', 	conditional: true, entityList: 'infoStepList' 			}
				]
			}
			,{
				cols		: 4,
				icon		: "fa fa-check",
				padding		: "0.5rem 1rem 0.5rem 0rem",
				items		: [
					{ mandatory: true, 	field	: 'journey_times',				label:'_OPTIONS_JOURNEY_TIMES',		type: 'text', 	editor: 'list', 	conditional: true, entityList: 'infoEventList' 			}
				]
			}
		];

		this.pageInfo.wizardInfo.info	= {			
			name						: "",
			active						: true,

			mode						: "",
			coupon						: true,
			price_guaranteed			: false,
			instant_confirmation		: false,
			coupon_type					: [],
			groups						: false,
			
			items						: true,
			expect						: false,
			itinerary					: true,
			info_meeting				: true,
			
			cancel_available			: false,
			cancel_reason				: [],
			cancel_devolution_period	: 0,
			cancel_devolution_method	: "",
			cancel_conditions			: [],
			
			info_additional				: [],
			info_steps					: [],
			journey_times				: [],
			confirmation				: []
		};

		// I18N
		this.pageInfo.wizardInfo.i18n		= {};
		this.pageInfo.wizardInfo.forms.i18n = [
			{
				title		: "_I18N_INFO",
				showTitle	: true,
				i18n		: true,
				icon		: "fa fa-language",
				padding		: "0rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'title',			label:'_TITLE',						type: 'text', 	editor: 'text',	info: true 						},
					{ mandatory: true, 	field	: 'tags', 			label:'_TAGS',						text: 'text',	editor: 'chip', info: true 						},
					{ mandatory: true, 	field	: 'desc_short',		label:'_DESC_SHORT',				type: 'text', 	editor: 'text' 									},
					{ mandatory: true, 	field	: 'desc_long', 		label:'_DESC_LONG',					type: 'text', 	editor: 'area' 									}					
				]
			}
			,{
				title		: "_WIZARD_I18N_ITEMS",
				showTitle	: true,
				icon		: "fa fa-check",
				i18n		: true,
				padding		: "0.5rem 1rem 0.5rem 3rem",
				items		: [
					{ 	
						mandatory	: true, 	
						field		: 'expect', 		
						label		: '_OPTIONS_EXPECT', 			
						type		: 'text', 	
						editor		: 'toggle', 	
						conditional	: true			
					},
					{ 	
						mandatory	: false, 	
						margin		: '1rem 0rem 0rem 0rem', 	
						field		: 'items',
						label		: '_OPTIONS_ITEMS',
						type		: 'text',
						editor		: 'listAdd',
						conditional	: true,
						legend		: '_OPTIONS_ITEMS_LEGEND'						
					},
					{ 	
						mandatory	: false,
						margin		: '1rem 0rem 0rem 0rem', 	
						field		: 'itinerary',
						label		: '_OPTIONS_ITINERARY',
						type		: 'text',
						editor		: 'listAdd',
						conditional	: true,
						legend		: '_OPTIONS_ITINERARY_LEGEND'						
					}					
				]
			}
			,{
				title		: "_WIZARD_I18N_MEETING",
				conditional	: 'info_meeting',
				showTitle	: true,
				i18n		: true,
				icon		: "fa fa-check",
				padding		: "0.5rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'i18n_hour', 		label:'_MEETING_HOUR', 				type: 'text', 	editor: 'text'	},
					{ mandatory: true, 	field	: 'i18n_place', 	label:'_MEETING_PLACE', 			type: 'text', 	editor: 'text'	},
					{ mandatory: true, 	field	: 'i18n_arrival',	label:'_MEETING_ARRIVAL',			type: 'text', 	editor: 'text'	}
				]
			}
		];

		// RESOURCES
		this.pageInfo.wizardInfo.resources 	= { 
			cols			: 4,
			editable		: false,
			showSightName	: true,
			features		: [
				{	value: 'default', 		icon: 'fa fa-heart',		disabled: false	},
				{	value: 'position', 		icon: 'fa fa-map-marker',	disabled: true	},
				{	value: 'big', 			icon: 'fa fa-desktop',		disabled: true	},
				{	value: 'small', 		icon: 'fa fa-mobile',		disabled: true	}			
			]
		};
		this.pageInfo.wizardInfo.pictures	= [];
		this.pageInfo.wizardInfo.videos		= [];
		this.pageInfo.wizardInfo.audios		= [];

		this.pageInfo.wizardInfo.sights		= [];
		this.pageInfo.wizardInfo.services	= { included: [], notIncluded: []};
		this.pageInfo.wizardInfo.complements= { included: [], notIncluded: []};
		
		this.loadSightsResources();

		this.pageInfo.wizardInfo.ready		= true;
	}  	

    onUpload(event) {
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}
	
	async action($type,$item){		
		switch($type){
			case "sight"	:	this.loadSightResources($item);						break;
			case "close"	: 	this.emitter.emit({ 'action': 'close'});			break;
			case "set"		: 	this.pageInfo.step = $item.id;						break;
			case "next"		: 	this.pageInfo.step++;		 						break;
			case "back"		: 	this.pageInfo.step-= this.pageInfo.step>1?1:0; 		break;
			case "init"		: 	this.pageInfo.step=0; 								break;
			case "finish"	: 	let success = await this.persistTour(this.pageInfo.wizardInfo);
								if(success==true){ this.emitter.emit({ 'action': 'finish'}); }
								break;
		}
		this.checkStep();
	}

	checkStep(){
		switch(this.pageInfo.step){
			case 6			:	//this.loadSightsResources(); break;
		}
	}

	async loadSightResources($info){
		const sight = $info.item;
		switch($info["action"]){
			case "add"		: 	console.log("Loading resources for sight ",sight.id,sight.title);
								let response = await Promise.resolve(this.entityService.getComplexRequest("sightInfo",{ id: sight.id },{}));
								if(response["success"]!=true){
									this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
									return false;
								}
								response["data"]["pictures"].forEach(picture=>{
									picture.sightId		= sight.id;
									picture.sightName	= sight.title;
								});
								console.log("Removing pictures from sight",sight.id,sight.title);
								this.pageInfo.wizardInfo.pictures = [ ...this.pageInfo.wizardInfo.pictures, ...response["data"]["pictures"] ];
								break;

			case "remove"	: 	this.pageInfo.wizardInfo.pictures = this.pageInfo.wizardInfo.pictures.filter(item=>item.sightId!=sight.id);
								break;
		}
	}

	async loadSightsResources(){
		let order = 1;
		this.pageInfo.wizardInfo.pictures = [];
		// await this.pageInfo.wizardInfo.sights.forEach(async sight=>{
		for(const sight of this.pageInfo.wizardInfo.sights){
			console.log("Loading resources for sight ",sight.title);
			let response = await Promise.resolve(this.entityService.getComplexRequest("sightInfo",{ id: sight.id },{}));
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
				return false;
			}
			response["data"]["pictures"].forEach(picture=>{
				picture.sightName	= sight.title;
			});
			console.log("Adding pictures from sight",sight.title);
			this.pageInfo.wizardInfo.pictures = [ ...this.pageInfo.wizardInfo.pictures, ...response["data"]["pictures"] ];
		}		
		// });
	}

	/**
	 * Persist Sight
	 * @param $item
	 */
	async persistTour($item){
		console.log("Persist Tour",$item);
		let item = { info: {}, i18n: {}, sights: {}, services: {}, complements: {}, pictures: {}, videos: {}, audios: {}};

		// Check mandatory fields
		if(($item.info.name||"") == ""	){ this.commons.generateToast("_ERROR","_WIZARD_ERROR_NAME",	"error"); return false; }
		if(!Object.keys($item.i18n).some(lang=>$item.i18n[lang].title!=undefined && $item.i18n[lang].title!="")){
			this.commons.generateToast("_ERROR","_WIZARD_ERROR_LANGS",	"error"); return false;
		};
		if($item.sights.length	 == 0	){ this.commons.generateToast("_ERROR","_WIZARD_ERROR_SIGHTS",	"error"); return false; }
		
	// GENERAL
		// Info general
		item.info			= $item.info;		

		// Info list
		[ "info_additional", "info_steps", "journey_times", "confirmation" ].forEach(key=>{
			item.info[key]	= ($item.info[key]||[]).map(item=>item.id);	// Get only ID
		});

		// Info dropdowns 			
		[  "coupon_type", "cancel_reason", "cancel_devolution_method", "cancel_conditions" ].forEach(key=>{
			item.info[key]	= ($item.info[key]||[]).id;					// Get only ID
		});
		
	// I18N
		item.i18n 			= $item.i18n;
		item.sights			= {
			items		: $item.sights.sort((a,b)=>a.position>=b.position?1:-1).map(item=>item.id)
		};

	// SERVICES
		item.services			= { 
			included	: ($item.services.included		||[]).map(item=>item.id),
			notIncluded	: ($item.services.notIncluded	||[]).map(item=>item.id)	
		};

	// COMPLEMENTS
		item.complements		= { 
			included	: ($item.complements.included	||[]).map(item=>item.id),
			notIncluded	: ($item.complements.notIncluded||[]).map(item=>item.id)	
		};

	// PICTURES
		item.pictures			= {
			default		: ($item.pictures.find(pic=>pic.features.some(feature=>feature=="default"))||{}).id,
			items		: $item.pictures.map(pic=>pic.id)
		};

	// VIDEOS
		item.videos			= {
			default		: ($item.videos.find(vid=>vid.features.some(feature=>feature=="default"))||{}).id,
			items		: $item.videos.map(vid=>vid.id)
		};

	// AUDIOS
		item.audios			= {
			default		: ($item.audios.find(aud=>aud.features.some(feature=>feature=="default"))||{}).id,
			items		: $item.audios.map(aud=>aud.id)
		};

		const vars 		= [ "info","i18n","sights","services","complements","pictures","videos","audios" ];
		const records	= [ vars.reduce((o,i)=>{ o[i]=item[i]; return o; },{ }) ];

		console.log("RECORDS",records);
		
		this.pageInfo.spinner 	= true;
		return await this.entityService.postJSON(
			this.entityService.getUrl('tour_persist'),
			{
				type	:	"multiple",
				records	:	records
			}
		).then(response=>{
			this.pageInfo.spinner = false;
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
				return false;
			}
			this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");			
			return true;
		}).catch(response=>{
			this.pageInfo.spinner = false;
			this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
			console.log("RESPONSE",response);				
		});
	}

	async load($entity){
		let response;
		switch($entity){
			case 'langs'		:	response 									= 	await Promise.resolve(this.entityService.getRequest("langs"));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.wizardInfo.langs				= 	response["data"];
									break;

			case 'sights' 		:	response 									= 	await Promise.resolve(this.entityService.getRequest("sights"));
									this.pageInfo.wizardInfo.entities[$entity]	= 	response["data"].map((item,index)=>({ 
																						id			: item["id"], 
																						type		: item["type"], 
																						title		: item["name"], 
																						latitude	: item["latitude"], 
																						longitude	: item["longitude"],
																						color		: "red"
																					}))
																					.map(item=>{								// Capitalize
																							item.title = item.title.charAt(0).toUpperCase()+item.title.slice(1);
																							return item;
																					})	
																					.sort((a,b)=>(a.title>b.title?1:-1))		// Sort by title
																					.map(item=>{								// Assign icon
																						switch(item.type){
																							case "_EVENT"	: item["icon"] = "play"; 	break;
																							case "_TICKET"	: item["icon"] = "ticket"; 	break;
																							case "_STAFF"	: item["icon"] = "user"; 	break;
																							default			: item["icon"] = "check";	break;
																						}
																						return item;
																					});
									console.log("ENTITY "+$entity,response);
									break;

			case 'services' 	:	response 									= 	await Promise.resolve(this.entityService.getRequest("tour_services",{lang:this.translate.getBrowserLang()}));
									this.pageInfo.wizardInfo.entities[$entity]	= 	response["data"].map((item,index)=>({																
																					id			: item["id"],
																					name		: item["name"],
																					type		: item["type"],
																					title		: item["title"]														
																				}))
																				.map(item=>{	// Capitalize 1st letter
																					item.title = item.title.charAt(0).toUpperCase()+item.title.slice(1);
																					return item;
																				})
																				.sort((a,b)=>(a.title>b.title?1:-1));					
									break;
			case 'complements' :	response 									= 	await Promise.resolve(this.entityService.getRequest("tour_complements",{lang:this.translate.getBrowserLang()}));
									this.pageInfo.wizardInfo.entities[$entity]	= 	response["data"].map((item,index)=>({																
																					id			: item["id"],
																					name		: item["name"],
																					type		: item["type"],
																					title		: item["title"]														
																				}))
																				.map(item=>{	// Capitalize 1st letter
																					item.title = item.title.charAt(0).toUpperCase()+item.title.slice(1);
																					return item;
																				})
																				.sort((a,b)=>(a.title>b.title?1:-1));					
									break;
		}
	}
}
