export const rejectInfo = {
	panel	: false,
	cols	: [ 
		{ field: 'code',			label: "_CODE",		align: "left",	 width: "50px"	 		},
		{ field: 'message',			label: "_MESSAGE",	align: "center", width: "500px" 		}
	],
	items	: [
		{ 	
			code			: "96", 	
			commentStatus	: "_MANDATORY", 
			commentTitle	: "Provider alternate drop off information in comment field",
			message			: "Accommodation - Alternative drop off - No access to accommodation"
		},
		{ 
			code			: "97",	
			commentStatus	: "_MANDATORY",
			commentTitle	: "Provider alternate drop off information in comment field",
			message			: "Accommodation - Alternative drop off - Shuttle or large vehicle cannot access accommodation"			
		},
		{ 
			code			: "51", 	
			commentStatus	: "_MANDATORY",
			commentTitle	: "Provider alternate drop off information in comment field",
			message			: "Accommodation - No shuttles going to private address"
		},
		{ code: "53", 	message: "Accommodation - Provide full accommodation Information" },
		{ code: "99", 	message: "Extras - Can not provide extra items" },
		{ code: "72", 	message: "Extras - Give us more info about your extra - Bike box" },
		{ code: "73", 	message: "Extras - Give us more info about your extra - Child or Booster seat" },
		{ code: "74", 	message: "Extras - Give us more info about your extra - Child stroller" },
		{ code: "75", 	message: "Extras - Give us more info about your extra - Extra stop - Keys" },
		{ code: "77", 	message: "Extras - Give us more info about your extra - Golf bag" },
		{ code: "78", 	message: "Extras - Give us more info about your extra - Pet carrier" },
		{ code: "79", 	message: "Extras - Give us more info about your extra - Skis" },
		{ code: "80", 	message: "Extras - Give us more info about your extra - Surf board" },
		{ code: "81", 	message: "Extras - Give us more info about your extra - Wheelchair" },
		{ code: "82", 	message: "Extras - Give us more info about your extra - Extra luggage" },
		{ code: "84", 	message: "Extras - Vehicle too small for extra - Child stroller" },
		{ code: "85", 	message: "Extras - Vehicle too small for extra - Child or Booster seat" },
		{ code: "86", 	message: "Extras - Vehicle too small for extra - Pet carrier" },
		{ code: "87", 	message: "Extras - Vehicle too small for extra - Wheelchair / Scooter" },
		{ code: "88", 	message: "Extras - Vehicle too small for extra – Bikebox" },
		{ code: "89", 	message: "Extras - Vehicle too small for extra - Golf bag" },
		{ code: "90", 	message: "Extras - Vehicle too small for extra - Extra luggage" },
		{ code: "91", 	message: "Extras - Vehicle too small for extra - Skis" },
		{ code: "92", 	message: "Extras - Vehicle too small for extra - Surfboard" },
		{ code: "93", 	message: "Extras - Vehicle too small for extra - Number of passengers" },
		{ code: "57", 	message: "Flights - Provide Full flight information" },
		{ 
			code			: "101", 	
			commentStatus	: "_MANDATORY",
			commentTitle	: "Provide necessary information in comment field",
			message			: "Flights - Wrong airport has been booked" 
		},
		{ 
			code			: "100", 	
			commentStatus	: "_MANDATORY", 
			commentTitle	: "Provide necessary information in comment field",
			message			: "Other - Amendment from original comment not done correctly" 
		},
		{ code: "58", 	message: "Other - Child or Childrens ages" },
		{ code: "59", 	message: "Other - Clients full name" },
		{ code: "60", 	message: "Other - Clients number" },
		{ code: "61", 	message: "Other - Ferry ticket not included" },
		{ code: "64", 	message: "Port - Provide full ship information" },
		{ code: "65", 	message: "Rates - Contract - Route Rate as per agreement" },
		{ code: "66", 	message: "Rates - Contractual - Late night or early morning supplement fee" },
		{ code: "67", 	message: "Rates - Contractual - Next years rates" },
		{ code: "68",	message: "Rates - Contractual - Promotion no longer running" },
		{ code: "98", 	message: "Rates - Rate for extra items not showing" },
		{ code: "69", 	message: "Rates - Wrong Resort - resort not loaded" },
		{ code: "70", 	message: "Rates - Wrong Resort - should be booked to" },
		{ code: "95", 	message: "Train station - Provide full train information" },
		{ code: "83",	message: "Vehicle - This shuttle service is not available at this hour" },
		{ code: "52", 	message: "Vehicle - Preferred pickup time" },
		{ 
			code			: "54", 	
			commentStatus	: "_MANDATORY",
			commentTitle	: "Provide pick up time in HH:MM format",			
			message			: "Vehicle - Recommended pickup time" 
		}
	]
};