import { Injectable               	} from '@angular/core';
import { CommonsService             } from 'src/app/demo/service/commons.service';
import { IProviderService 			} from './iproviderservice';

@Injectable()
export class ProviderHoppaService implements IProviderService {		
	pageInfo 	= { 
		providerId	: 2 
	};

  	constructor(
		private commons 	: CommonsService
	){}
    
	init($controller){
		$controller.registerProviderService({ 
			id		: this.pageInfo.providerId, 
			name	: "hoppa",
			service	: this 
		});
	}

    // Process bookings into HOPPA format
    // convert to Tourinia format
    // and generate groups for tripulation

	filterBooking($params):boolean{
		const $booking 			= $params["booking"			];
		const $show_cancelled	= $params["show_cancelled"	];

		if(!$params["show_cancelled"]){
			switch($booking.status){
				// case "ACAN":
				// case "PCAN":	return false;
			}
		}
		return true;		
	}

	mapBooking($booking):any{
		switch($booking.status){
			// case undefined	:
			// case null		:
			// 	$booking.status = "PCON";
			// case "PCON":
			// case "PAMM":
			// case "PCAN":
			// 	$booking.verified = false;
			// 	break;
			// case "ACON":
			// case "AAMM":
			// case "ACAN":
			// 	$booking.verified = true;
			// 	break;
		}
		return $booking;
	}

    processBookings($info){
		let groups		= [];
		let bookingsMap	= [];
		let response;

		($info.bookings||[]).forEach((booking,index) => {
			let data = {};
			
			// Generate booking map from header and data
			($info.header||[]).forEach((field,index)=>{
				data[field]=booking[index];
			});

			if(response["success"]){
				data = response.data;
			}
			bookingsMap.push(data);
		});

        return bookingsMap;
	}
	
	processArrival($info):any{}

	processDeparture($info):any{}

	check4Validation($params):any{
		return true;
	}
}