import { Http			 						} from "@angular/http";
import { CommonsService 						} from '../../../service/commons.service';
import { EntityService 							} from '../../../service/entity.service';
import { Component, OnInit, ViewEncapsulation, 
		NgZone, ElementRef, ViewChild, Inject   } from '@angular/core';
import { DragulaService 						} from "ng2-dragula";
import { colFilters                             } from "./data/filters";

import { ConfirmationService 					} from 'primeng/primeng';
import { FirebaseService 						} from './../../../service/database/firebase.service';
import { StorageService 						} from './../../../service/storageservice';

@Component({
    styleUrls		: ['./component.scss'],
	templateUrl		: './component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class InvoicingCompanyComponent implements OnInit
{
    locale      	: string 	= 'es';    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private http 			: Http,
		private dragulaCtrl 	: DragulaService,
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private confirmCtrl     : ConfirmationService
	)					{ this.init(); 					}
    async ngOnInit() 	{ await this.loadEntities();	}
    async init()		{
		//
		this.pageInfo.dataLoaded      	= false;
        this.pageInfo.indexTabCalendar	= 0;
		this.pageInfo.variant        	= {};
		
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
		
		console.log('UserInfo', this.userInfo);
		
		this.pageInfo.status			= {
			providers	: "providerList",			
		};
		this.pageInfo.i18n				= this.commons.getAllTranslates();
		this.pageInfo.tabs				= {
			main			: {
				items 			: [
					{ id:0, label: this.commons.getTranslate('_SERVICES'		), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:1, label: this.commons.getTranslate('_INVOICES'		),	icon : 'fa fa-file-text-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:2, label: this.commons.getTranslate('_CONCILIATION'	), 	icon : 'fa fa-hanshake-o' 		, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:3, label: this.commons.getTranslate('_INCOMES'			),	icon : 'fa fa-euro'	 			, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:4, label: this.commons.getTranslate('_EXPENDITURES'	),	icon : 'fa fa-euro' 			, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:5, label: this.commons.getTranslate('_GARAGE'			),	icon : 'fa fa-cogs'				, command: ($event)=> { this.setTabItem('main',$event)} 	},
					{ id:6, label: this.commons.getTranslate('_STATS'			),	icon : 'fa fa-area-chart' 		, command: ($event)=> { this.setTabItem('main',$event)} 	}
				],
				selected		: 0
			}
		};		
		this.pageInfo.views				= {
			items			: [
				{ value: "grid", icon: 'fa fa-th' 		},
				{ value: "list", icon: 'fa fa-list' 	}				
			],
			selected		: [ "grid" ]
        };
        this.pageInfo.colFilters 		= await Promise.all(colFilters.map(async item=>await this.commons.translateRecursively(item)));
	}				

	setTabItem($entity,$event){	return this.pageInfo.tabs[$entity].selected = $event.item.id; }
	getTabItems($entity,$type){
		switch($type){
			case "list"		: return this.pageInfo.tabs[$entity].items.map(item=>{ item.disabled=(item.conditional && !this.pageInfo[item.conditional]); return item; });
			case "active"	: return this.pageInfo.tabs[$entity].items[this.pageInfo.tabs[$entity].selected];
		}
	}

    async loadEntities(){	this.pageInfo.dataLoaded = true;	}
    async load($entity)	{
        switch($entity){
		};        
	}

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
	}

	action($type,$event){
		switch($type){
			case "providerList"		: 	switch($event.action){
										case "provider_unselected"	:	this.pageInfo.currentProvider	= undefined;		break;
										case "provider_selected"	:	this.pageInfo.currentProvider	= $event["item"];	break;
										case "view_provider"		: 	if(undefined==this.pageInfo.currentProvider){
																			this.commons.generateToast("_ERROR","_PROVIDER_NOT_SELECTED","error");
																			return false;
																		}
																		this.pageInfo.status.providers 		= "providerDetail"; 	
																		break;
										case "new_provider"			: 	this.pageInfo.status.providers 		= "providerWizard";		break;
									}
									break;			

			case "providerDetail"	: 	this.pageInfo.status.providers 		= "providerList"; 		break;			
			
		}
	}
}