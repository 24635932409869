import { EntityService 		} from './../../../../demo/service/entity.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { detailItems 		} from './data/items';

@Component({
  selector    	: 'app-sight-detail',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component_horizontal.html'
//   templateUrl 	: 'component_vertical.html'
})
export class SightDetailComponent {

	@Input("type")      type:string;
	@Input("info")      info:any		= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService
	){
		this.init();
	}

	init()							{}
	async loadEntities()			{ 	await this.load("sightInfo"); 
										await this.load("langs");
										await this.load("sightTypes"); 
									}
	async ngOnInit()				{

		this.pageInfo.step					= 1;		
		this.pageInfo.items					= detailItems;		
		this.pageInfo.detailInfo			= { ready : false };
		
		await this.loadEntities();

		this.pageInfo.detailInfo.ready		= 	true;									
		this.pageInfo.detailInfo.forms		= {};

		this.pageInfo.detailInfo.entities	= {						
			langList			: this.pageInfo.detailInfo.langs,
			sightTypes			: this.pageInfo.detailInfo.sightTypes,
			infoAdditionalList	: [ 
				{ id: 1, label: '_CHILDREN_TICKET' 		},
				{ id: 2, label: '_DRINK_AND_FOOD'		},
				{ id: 3, label: '_DIFICULTIES'			},
				{ id: 4, label: '_CLAUSURE'				}
			]			
		};

		// I18N
		this.pageInfo.detailInfo.forms.i18n = [
			{
				title		: "_GENERIC_INFO",
				showTitle	: true,
				icon		: "fa fa-bars",
				padding		: "0.25rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'name',			label:'_NAME',						type: 'text', 	editor: 'text',			info: true 						},
					{ mandatory: true, 	field	: 'type',			label:'_TYPE',						type: 'text', 	editor: 'autocomplete',	entityList: 'sightTypes'		}
				]
			},
			{
				title		: "_I18N_INFO",
				showTitle	: true,
				i18n		: true,
				icon		: "fa fa-language",
				padding		: "1rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'title',			label:'_TITLE',						type: 'text', 	editor: 'text',	info: true 						},
					{ mandatory: true, 	field	: 'tags', 			label:'_TAGS',						text: 'text',	editor: 'chip', info: true 						},
					{ mandatory: true, 	field	: 'short_desc',		label:'_DESC_SHORT',				type: 'text', 	editor: 'text' 									},
					{ mandatory: true, 	field	: 'long_desc', 		label:'_DESC_LONG',					type: 'text', 	editor: 'area', height: '200px'					}					
				]
			}			
		];
		
		// SIGHT
		this.pageInfo.detailInfo.sight		= { 
			map			: {
				zoom		: 10, 
				lat			: 39.639938, 
				lng			: 2.894679
			},
			marker		: {
				latitude	: this.info.latitude,
				longitude	: this.info.longitude,
			}
		};

		this.pageInfo.detailInfo.resources	= {
			cols		: 5
		};

		// console.log("INFO",this.pageInfo.detailInfo);
	}  	

	onUpload(event) {
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}
	
	async action($type,$item){		
		switch($type){
			case "close"	:	await this.persistSight(this.pageInfo.detailInfo);
								this.emitter.emit({ 'action': 'close' });			
								break;
			case "set"		: 	this.pageInfo.step = $item.id; break;			
		}
	}

	/**
	 * Persist Sight
	 * @param $item
	 */
	async persistSight($item){
		// console.log("Persist Booking",$item);
		this.pageInfo.spinner = true;
		const vars = [ 	"id", 
						"active", 
						"name", 
						"idtype",
						"type", 
						"area",
						"idarea", 
						"address", 
						"i18n", 
						"pictures", 
						"latitude", 
						"longitude" 
					];
		await this.entityService.postJSON(
			this.entityService.getUrl('sight_persist'),
			{
				type	:	"multiple",
				records	:	[ vars.reduce((o,item)=>{ o[item]=$item[item]; return o; },{ }) ]
			}
		).then(response=>{
			this.pageInfo.spinner = false;
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
				return false;
			}
			this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");			
			return true;
		}).catch(response=>{
			this.pageInfo.spinner = false;
			this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
			console.log("RESPONSE",response);				
		});
	}

	async load($entity){
		let response;
		switch($entity){
			case 'langs'		:	response 							= 	await Promise.resolve(this.entityService.getRequest("langs",));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.detailInfo.langs		= 	response["data"];
									break;
			case 'sightInfo' 	:	response 							= 	await Promise.resolve(this.entityService.getComplexRequest("sightInfo",{ id: this.info.id },{}));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.detailInfo			= 	response["data"];
									break;
			case 'sightTypes' 	:	response 							= 	await Promise.resolve(this.entityService.getRequest("sightTypes",{}));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.detailInfo.sightTypes = response["data"];
									break;
		}
	}
}
