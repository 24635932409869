import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector   : 'app-transfer-panel',
    templateUrl: './transferPanel.component.html',
    styleUrls:['./transferPanel.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class TransferPanelComponent implements OnInit{

    @Input()    transfers : any[]             = [];
    @Output()   toggled   : EventEmitter<any> = new EventEmitter();

    arrivalActived:boolean    	= true;
    pickupActived:boolean    	= true;

    ngOnInit(){}

    
	activateMarkerTransport(type,item){

		item.isOpen             = item['isOpen'] ? !item['isOpen'] : true;
		let otherTrajectType    = type == 'arrival' ? 'pickup' : 'arrival';
        let findedOtherWay      = this.transfers.find(el        => el.type      == otherTrajectType ).items
                                                .find(transport => transport.id == item.id); 
        
        if(findedOtherWay){ findedOtherWay.isOpen = item.isOpen; }

        this.toggleTransport(item);
    }
    
    toggleTransport(item){
        this.toggled.emit(item);
    }

    checkItemsByTraject(type){
        return this.transfers.find(el => el.type == type).items.filter(transferType => transferType.visible).length;
    }
}