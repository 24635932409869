import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { detailItems, entities, invoiceFakeData 	} from './data/items';

@Component({
  selector    		: 'app-company-garage',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyGarageComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		views			: { items: [] },
		entities		: { invoices: {} }		
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService	
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'list',		label: '_LIST', 	icon: 'fa fa-list'	, type: 'button'	},
			{ 	name: 'service',	label: '_SERVICE',	icon: 'fa fa-cogs'	, type: 'button'	}
		];
		this.pageInfo.status		= "list";
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };
				
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.entities["invoices"].table	= {
			cols	: [
				{	field: 'id',						header		: '_ID',						width		:  '50px',	align: 'center', disabled	: true			},
				{	field: 'invoice_number',			header		: '_INVOICE_NUMBER',			width		: '100px',	align: 'center', disabled	: false			},   
				{	field: 'status',					header		: '_STATUS',					width		: '100px',	align: 'center', disabled	: false			},   
				{	field: 'emision_date',				header		: '_EMISION_DATE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'expiry_date',				header		: '_EXPIRY_DATE',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'receptor_name',				header		: '_RECEPTOR_NAME',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'receptor_id',				header		: '_RECEPTOR_ID',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'receptor_phone',			header		: '_RECEPTOR_PHONE',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'receptor_address',			header		: '_RECEPTOR_ADDRESS',			width		: '150px',	align: 'center', disabled	: true			},
				{	field: 'receptor_zipcode',			header		: '_RECEPTOR_ZIPCODE',			width		: '150px',	align: 'center', disabled	: true			},
				{	field: 'receptor_municipality',		header		: '_RECEPTOR_MUNICIPALITY',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'receptor_region',			header		: '_RECEPTOR_REGION',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'receptor_country',			header		: '_RECEPTOR_COUNTRY',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_name',				header		: '_SERVICE_NAME',				width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_description',		header		: '_SERVICE_DESCRIPTION',		width		: '150px',	align: 'center', disabled	: true			},
				{	field: 'service_price_base',		header		: '_SERVICE_PRICE_BASE',		width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_price_discount',	header		: '_SERVICE_PRICE_DISCOUNT',	width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_price_vat',			header		: '_SERVICE_PRICE_VAT',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_price_taxes',		header		: '_SERVICE_PRICE_TAXES',		width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'service_payment_type',		header		: '_SERVICE_PAYMENT_TYPE',		width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'payment_date',				header		: '_PAYMENT_DATE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'payment_total',				header		: '_PAYMENT_TOTAL',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'note',						header		: '_NOTE',						width		: '100px',	align: 'center', disabled	: true			}
			],			
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh' },
			buttons	: [
				{ label: '_RELOAD', icon: 'refresh'	}
			],
			filters	: [
				{
					entity	: 'invoices',
					name	: 'status',
					label	: '_STATUS',
					type	: 'multiple',
					field	: 'status',
					icon	: 'share-alt',
					items	: [
						{ label: '_PENDING',	value: 'pending' 	},
						{ label: '_PAYED',		value: 'payed' 		},
						{ label: '_CANCELED',	value: 'canceled'	}
					],
					selected: [ 'pending', 'payed', 'canceled' ]
				}				
			],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{
						title	: "_INFO",
						items	: [
							{	field: 'invoice_number',			label		: '_INVOICE_NUMBER',			editable    : true,     type: 'text'			},   
							{	field: 'status',					label		: '_STATUS',					editable    : true,     type: 'text'			},
							{	field: 'receptor_name',				label		: '_RECEPTOR_NAME',				editable    : true,     type: 'text'			},
							{	field: 'receptor_id',				label		: '_RECEPTOR_ID',				editable    : true,     type: 'text'			},
							{	field: 'receptor_phone',			label		: '_RECEPTOR_PHONE',			editable    : true,     type: 'text'			},
							{	field: 'receptor_address',			label		: '_RECEPTOR_ADDRESS',			editable    : true,     type: 'text'			},
							{	field: 'receptor_zipcode',			label		: '_RECEPTOR_ZIPCODE',			editable    : true,     type: 'text'			},
							{	field: 'receptor_municipality',		label		: '_RECEPTOR_MUNICIPALITY',		editable    : true,     type: 'text'			},
							{	field: 'receptor_region',			label		: '_RECEPTOR_REGION',			editable    : true,     type: 'text'			},
							{	field: 'receptor_country',			label		: '_RECEPTOR_COUNTRY',			editable    : true,     type: 'text'			}
						]
					}
					,{
						title	: "_SERVICE",
						items	: [
							{	field: 'service_name',				label		: '_SERVICE_NAME',				editable    : true,     type: 'text'			},
							{	field: 'service_description',		label		: '_SERVICE_DESCRIPTION',		editable    : true,     type: 'text'			},
							{	field: 'service_price_base',		label		: '_SERVICE_PRICE_BASE',		editable    : true,     type: 'text'			},
							{	field: 'service_price_discount',	label		: '_SERVICE_PRICE_DISCOUNT',	editable    : true,     type: 'text'			},
							{	field: 'service_price_vat',			label		: '_SERVICE_PRICE_VAT',			editable    : true,     type: 'text'			},
							{	field: 'service_price_taxes',		label		: '_SERVICE_PRICE_TAXES',		editable    : true,     type: 'text'			}							
						]
					}
					,{
						title	: "_PAYMENT",
						items	: [
							{	field: 'service_payment_type',		label		: '_SERVICE_PAYMENT_TYPE',		editable    : true,     type: 'text'			},
							{	field: 'emision_date',				label		: '_EMISION_DATE',				editable    : true,     type: 'text'			},
							{	field: 'expiry_date',				label		: '_EXPIRY_DATE',				editable    : true,     type: 'text'			},
							{	field: 'payment_date',				label		: '_PAYMENT_DATE',				editable    : true,     type: 'text'			},
							{	field: 'payment_total',				label		: '_PAYMENT_TOTAL',				editable    : true,     type: 'text'			},
							{	field: 'note',						label		: '_NOTE',						editable    : true,     type: 'text'			}
						]
					}
				]
			}
		};

		await this.initFilters("invoices");
		await this.initMenu("invoices");
	}

	async initFilters($entity)	{
		this.pageInfo.entities[$entity].filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		
	}

	checkFilter(filter)			{	switch(filter.type){
										case "multiple":	this.checkMultipleFilter(filter); 	break;
										default:			this.checkSimpleFilter(filter);		break;
									}
	}

    checkMultipleFilter(filter)	{
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= {
																				field	: filter.field,
																				options	: filter.items.map(item=>item.value),
																				selected: filter.selected
																			};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this.pageInfo.entities[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this.pageInfo.entities[$entity].data			|| [];
		let filters = this.pageInfo.entities[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "shared":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		this.pageInfo.entities[$entity].filteredData	= data;
		this.pageInfo.entities[$entity].count 		= this.pageInfo.entities[$entity].filteredData ? this.pageInfo.entities[$entity].filteredData.length : 0;
		// this.paginateLocal($entity, null);
	}

	filterPending(filter){}

	async initMenu($entity){
		let me									= this;
		this.pageInfo.entities[$entity].menu	= [];
		this.pageInfo.entities[$entity].filters.forEach(async filter=>{
			let item 		= {
				label		: await this.commons.getTranslate(filter.label),
				icon		: filter.icon?('fa fa-'+filter.icon):'',
				style		: { padding: '5px 10px' },
				items		: []
			};
			filter.items.forEach(async current=> {
				let element	= {
					label		: 	await this.commons.getTranslate(current.label),
					icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
					command		: 	($event) => { me.doMenuAction(current.value,filter,$entity); }
				}
				item["items"].push(element);
			});
			this.pageInfo.entities[$entity].menu.push(item);
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter,$entity){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		$filter.selected = $filter.selected.some(item=>item===$item)
							?$filter.selected.filter(item=>item!==$item)
							:[...$filter.selected,$item];
		this.checkFilter($filter);		// Execute filtering
		this.initMenu($entity);		// Regenerate menu to show selection
	}

	async loadEntities()		{ await this.load("invoices");							}
  	async ngOnInit()			{ await this.init(); await this.loadEntities();			}
    onUpload(event) 			{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)			{
		switch($type){
			case "button"			:	switch($item.name){		
											case "reload"		: 	this.loadEntities();												break;
											case "list"			:	this.pageInfo.status = $item.name;									break;
											case "bookings"		:	this.pageInfo.status = $item.name;									break;
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
										}
										break;
		}
	}
	getFilteredEntity($entity)	{
		switch($entity){
			case "invoices"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});			
		}
	}
	async load($entity)			{
		let response = {};
		switch($entity){
			case 'invoices' :
				response 								= 	await Promise.resolve(this.entityService.getRequest($entity));
				response["data"]						=	invoiceFakeData;						
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;			
		}
	}
}
