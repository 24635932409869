export const audios = [
	{ 
		id			: 1, 	
		lang		: 'es', 
		duration	: "", 
		lat			: '31.2934', 
		lng			: '2.978', 
		title		: 'Llegada a las cuevas', 
		mp3			: '/assets/audios/gori.mp3'
	},
	{ 
		id			: 2,
		lang		: 'uk', 
		duration	: "", 
		lat			: '31.2934', 
		lng			: '2.978', 
		title		: 'El paseo por dentro de las cuevas', 
		mp3			: '/assets/audios/crowd-cheering.mp3'
	},
	{ 
		id			: 3, 	
		lang		: 'uk', 
		duration	: "", 
		lat			: '31.2934', 
		lng			: '2.978', 
		title		: 'El lago Martel', 
		mp3			: 'https://sample-videos.com/audio/mp3/crowd-cheering.mp3'
	},
	{ 
		id			: 4, 	
		lang		: 'es', 
		duration	: "", 
		lat			: '31.2934', 
		lng			: '2.978', 
		title		: 'Explicación de las maravillas', 
		mp3			: 'https://sample-videos.com/audio/mp3/crowd-cheering.mp3'
	}				
];