export const mainFilters = [
	{			
		entity		: 'booking_groups',
		name		: 'bookingInfo',
		// label		: '_BOOKING_INFO',
		label		: '_INFO',
		showTitle	: true,
		type		: 'simple',				
		items		: [
			{ label: '_INFO_SIMPLE',value: 'simple', 	_icon: 'list'	},
			{ label: '_INFO_FULL',	value: 'full',		_icon: 'list'	}
		],
		selected	: 'simple'				
	},
	{
		entity		: 'booking_groups',
		name		: 'type',
		label		: '_TYPE',
		showTitle	: false,
		icon		: 'bus',
		type		: 'simple',
		field		: 'shared',
		items		: [
			{ label: '_PRIVATE', 	value: 'private' 	},
			{ label: '_SHUTTLE',	value: 'shuttle' 	}
		],
		selected	: "shuttle"
	},
	{
		entity		: 'booking_groups',
		name		: 'direction',
		label		: '_DIRECTION',
		icon		: 'plane',
		showTitle	: false,
		type		: 'simple',
		field		: 'direction',
		items		: [
			{ label: '_ARRIVAL', 	value: 'arrival' 	},
			{ label: '_DEPARTURE', 	value: 'departure' 	}
		],
		selected	: "arrival"
	}
	// ,{
	// 	entity		: 'booking_groups',
	// 	name		: 'zones',
	// 	label		: '_ZONES',
	// 	type		: 'multiple',
	// 	field		: 'zones',
	// 	items		: [],
	// 	selected	: []		
	// }
	// ,{
	// 	entity	: 'booking_groups',
	// 	name	: 'transfer',
	// 	label	: 'Transfer',
	// 	type	: 'multiple',
	// 	field	: 'transfer',
	// 	items	: [
	// 		{ label: 'Shuttle', 		value: 'shuttle'	},
	// 		{ label: 'Speedy shuttle', 	value: 'speedy'		},
	// 		{ label: 'Private', 		value: 'private' 	}
	// 	],
	// 	selected: [ "shuttle", "speedy" ]
	// }
];

export const zonesFilter = {
		entity		: 'booking_groups',
		name		: 'zones',
		label		: '_ZONES',
		type		: 'simple',
		field		: 'zones'		
};

export const providerRestrictionFields = [
	{ name: "_MAX_WAIT_TIME", 		type: 'number',		default: 60			},
	{ name: "_USE_SPEEDY",			type: 'boolean',	default: false		},
	{ name: "_MAX_SPEEDY_STOPS",	type: 'number',		default: 0			}	
];