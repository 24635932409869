import { AggregatorsService 			} from './../../../demo/service/database/aggregator.service';
import { DestinationsService 			} from './../../../demo/service/database/destinations.service';
import { ConfirmationService			} from 'primeng/primeng';
import { StorageService 				} from 'src/app/demo/service/storageservice';
import { EntityService 					} from 'src/app/demo/service/entity.service';
import { CommonsService 				} from 'src/app/demo/service/commons.service';
import { SuperformService 				} from '../../../demo/service/super-form/superform.service';
import { Component, 
    	 Input, 
    	 Output, 
    	 EventEmitter,      	
   		 ViewEncapsulation,		
   		 OnInit,
   		 AfterViewInit					} from '@angular/core';

@Component({
	selector    		: 'main-providers',
	styleUrls			: ['./main-providers.component.scss'],
	encapsulation		: ViewEncapsulation.None,
	templateUrl 		: 'main-providers.component.html'  
})
export class MainProvidersComponent implements OnInit, AfterViewInit {
	
	@Input() 	providers		:	any	=	[];
	@Input()	dmcDestinations	:	any	=	[];
	@Input()	dmcProviders	:	any	=	[];
	@Input()	dmc				:	any;
	@Input()	destination		:	any;
	@Input()	mode			:	any =	"edit";
	@Output()	emitter			=	new EventEmitter<any>();	
	
	pageInfo    :   any =   {};

	constructor(private commons 		: CommonsService,
				private entityService 	: EntityService,
				private aggregatorCtrl	: AggregatorsService,
				private confirmCtrl		: ConfirmationService,
				private destinationCtrl	: DestinationsService,
				private superformCtrl	: SuperformService,
				private storageService 	: StorageService){}


	async ngOnInit() {
		this.pageInfo	=	{
			entities:	{
				providers			:	{},
				vehiclesTypeList	:	{}
			},
			imgs	:	{
				noImgDest	:	'https://icon-library.net/images/polygon-icon/polygon-icon-28.jpg',
			},
			loading	:	{
				providers	:	true,
			},
			formScheduler	:	[
				{
					id		:	0,
					value	:	[0,1,2,3,4],
					type	:	'template',
					label	:	'_FROM_MONDAY_TO_FRIDAY'
				},
				{
					id		:	1,
					value	:	[0,1,2,3,4,5,6],
					type	:	'template',
					label	:	'_FROM_MONDAY_TO_SUNDAY'
				},
				{
					id		:	2,
					value	:	[],
					type	:	'custom',
					label	:	'_CUSTOM'
				}
			],
			listDays				:	[{value : 0, label : '_MONDAY'},{value : 1, label : '_TUESDAY'},{value : 2, label : '_WEDNESDAY'},{value : 3, label : '_THURSDAY'},{value : 4, label : '_FRIDAY'},{value : 5, label : '_SATURDAY'},{value : 6, label : '_SUNDAY'},],
			listSchedules			:	[],
			editingScheduler		:	false,
			currentSchedule			:	{},
			providerSelected		:	null,
			destinationSelected		:	null,
			displayInfoDestination	:	false,
			activeIndexView			:	0,
		};

		this.pageInfo.listDays		= [
			{ value : 0, 	label : '_MONDAY_FL' 		},
			{ value : 1, 	label : '_TUESDAY_FL'		},
			{ value : 2, 	label : '_WEDNESDAY_FL'		},
			{ value : 3, 	label : '_THURSDAY_FL'		},
			{ value : 4, 	label : '_FRIDAY_FL'		},
			{ value : 5, 	label : '_SATURDAY_FL'		},
			{ value : 6, 	label : '_SUNDAY_FL'		}
		];

		this.pageInfo.listMonths 	= [
			{ value : 0, 	label : '_JANUARY' 			},
			{ value : 1, 	label : '_FEBRUARY'			},
			{ value : 2, 	label : '_MARCH'			},
			{ value : 3, 	label : '_APRIL'			},
			{ value : 4, 	label : '_MAY'				},
			{ value : 5, 	label : '_JUNE'				},
			{ value : 6, 	label : '_JULY'				},
			{ value : 7, 	label : '_AUGUST'			},
			{ value : 8, 	label : '_SEPTEMBER'		},
			{ value : 9, 	label : '_OCTOBER'			},
			{ value : 10, 	label : '_NOVEMBER'			},
			{ value : 11, 	label : '_DECEMBER'			}
		];
		this.pageInfo.listMonths 	= [
			{ value : 0, 	label : '_JANUARY' 			},
			{ value : 1, 	label : '_FEBRUARY'			},
			{ value : 2, 	label : '_MARCH'			},
			{ value : 3, 	label : '_APRIL'			},
			{ value : 4, 	label : '_MAY'				},
			{ value : 5, 	label : '_JUNE'				},
			{ value : 6, 	label : '_JULY'				},
			{ value : 7, 	label : '_AUGUST'			},
			{ value : 8, 	label : '_SEPTEMBER'		},
			{ value : 9, 	label : '_OCTOBER'			},
			{ value : 10, 	label : '_NOVEMBER'			},
			{ value : 11, 	label : '_DECEMBER'			}
		];

		this.pageInfo.listMonths = this.pageInfo.listMonths.map(month => {return {...month, label : this.commons.getTranslate(month.label)}});
		this.initForms();
		if(!Array.isArray(this.dmcDestinations)){ // if it is new creation
			this.dmcDestinations	=	[this.dmcDestinations];
		}
		await this.loadEntities();
	}

	ngAfterViewInit(){
		this.toggleProvider(this.providers[0]);
	}
	
	async loadEntities(){
		await this.load('vehiclesTypeList');
	}
	setLoading(property,value){ this.pageInfo.loading[property] = value}

	initForms(){
		this.pageInfo.providerForm	=	[
			{
				ready		: false,
				entities	: this.pageInfo.entities,			
				form		: [
					{
						cols		: 12,
						icon		: "fa fa-user",
						items		: [
							{
								cols		:	12,
								showTitle 	: 	true,
								title		:	'_CREDENTIALS',
								icon		: 	"fa fa-user",
								widthButtons:	"90px",
								items		:	[
									{ clean: true, flex: 10, mandatory	:	true,	field: 'account',			label		: '_ACCOUNT',	editable	: true, 	type: 'text', 		},
									{ clean: true, flex: 10, mandatory	:	true,	field: 'password',			label		: '_PASSWORD',	editable	: true, 	type: 'password' 		}								
								]
							},
							{
								cols		:	12,
								showTitle 	: 	true,
								title		:	'_CONTRACT',
								icon		: 	"fa fa-file-text-o",
								widthButtons:	"90px",
								items		:	[
									{ clean: true, flex: 10, mandatory	:	true,	field: 'hire_init',		label		: '_HIRE_INIT',		editable	: true, 	editor: 'datetime' ,options : {yearNavigator: true}	},
									{ clean: true, flex: 10, mandatory	:	true,	field: 'hire_end',		label		: '_HIRE_END',		editable	: true, 	editor: 'datetime', options : {	yearNavigator: true, minDate : {field : 'hire_init' }}	}							
								]
							}
						]
					}
				],
				buttons	: {
					items:	[
						{ name: 'save', 	label: '_SAVE',		action: 'save',  	icon: 'fa fa-fw fa-save'},
						{ name: 'remove', 	label: '_DELETE',	action: 'delete',  	icon: 'fa fa-fw fa-trash'},
						{ name: 'cancel',	label: '_CANCEL',	action: 'cancel',	icon: 'fa fa-fw fa-remove'}
					]
				}
			}	
		];

		this.pageInfo.timerFormProvider	=	[
			{
				ready		:	true,
				entities	:	null,
				form		:	[
					{
						cols		: 12,
						icon		: "fa fa-user",
						items		: [
							{
								cols		:	12,
								showTitle	:	false,
								title		:	'_HOUR',
								icon		:	"fa fa fa-timer",
								widthButtons:	'90px',
								items		:	[
									{ clean: true, flex: 10, field	:	'start_hour',	label	:	'_START_HOUR',	editable	:	true,	editor	:	'datetime',	options :	{timeOnly : true}},
									{ clean: true, flex: 10, field	:	'interval'	,	label	:	'_INTERVAL',	editable	:	true,	type	:	'number',	placeholder	:	'_IN_MINUTES', }

								]	
							}
						]
					}
				],
				info	:	{
					start_hour	:	new Date()
				}
			}
		]
	}

	getActiveIndex(){ return this.pageInfo.activeIndexView; }
	async load($entity)	{
		let response;
		this.pageInfo.entities[$entity]	=	this.pageInfo.entities[$entity] || {};
        switch($entity){
			case "vehiclesTypeList"	:	response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
										this.pageInfo.entities[$entity].data		= 	response["data"];
										console.log('MY TYPES LIST',this.pageInfo.entities[$entity].data );
										this.pageInfo.entities[$entity].count 		= 	response["data"] ? response["data"].length : 0;
			break;
		}
	}


	getInfo($entity, $params){
		switch($entity){
			case "provider"		:	
				switch($params.type){
					case "isSelected"		:	return $params.provider.selected;
				}
				break;
		}
	}

	doAction($type,$action,$info){
		switch($type){
			case "provider":	
				switch($action){
					case "toggle":	$info.provider.selected = $info.provider.selected?false:true; break;
				}
				break;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			default						:	return [];
			case 'providers'			:	return this.providers;
			case 'vehiclesTypeList'		:	return  (this.pageInfo.entities[$entity].data||[]).map(item=>{ item.url="/assets/layout/images/fleet/"+item.type+".png"; return item; });	
			case 'stationsDestination'	:	return 	((this.pageInfo.destinationsEntities || {})[(this.pageInfo.destinationSelected || {}).id] || {}).stations;
			case 'areasDestination'		:	return	((this.pageInfo.destinationsEntities || {})[(this.pageInfo.destinationSelected || {}).id] || {}).areas;
			case 'listPrices'			:	return 	((this.pageInfo.destinationsEntities || {})[(this.pageInfo.destinationSelected || {}).id] || {}).listPrices;
		}
	}
	toggleProvider(provider){
		if(this.pageInfo.providerSelected && this.pageInfo.providerSelected.id == provider.id){ return false; }

		this.commons.toggleItem(provider, this.providers,'selected',false);
		this.pageInfo.providerSelected	=	provider.selected ? provider : null;


		console.log('Provider Selected',	this.pageInfo.providerSelected);
		if(!this.pageInfo.providerSelected){ this.pageInfo.providerForm[0].info = {}; return; }
		let findedProvider	=	this.dmcProviders.find(item => item.provider.providerId == this.pageInfo.providerSelected.id);
		if(findedProvider){
			this.pageInfo.displayTableAccounts	=	true;
			this.pageInfo.infoAccountsProvider	=	findedProvider;
			this.restartProvidersAccounts();
			this.resetProviderForm();
			return;
		}else{
			this.pageInfo.displayTableAccounts	=	false;
			this.pageInfo.infoAccountsProvider	=	null;
		}

		// let alreadyAdded							=	this.findProviderData(this.pageInfo.providerSelected);
		// if(alreadyAdded && alreadyAdded.data && alreadyAdded.data['schedulers']){
		// 	this.pageInfo.providerSelected.schedulers	=	alreadyAdded.data['schedulers'].map(data => { return {...data, start_hour : this.commons.formatTimestamp(data.start_hour)}})
		// }else{
		// 	this.pageInfo.providerSelected.schedulers	=	[];
		// }
		this.pageInfo.providerForm[0].info	=	{};	
		this.pageInfo.providerForm[0].item	=	{};
		console.log('Provider Selected',	this.pageInfo.providerSelected);
	}

	getCurrentAccount(property?){
		const foundAccount	=	((this.pageInfo.infoAccountsProvider || {}).accounts || []).find(account => account.selected);
		return foundAccount ? (property ? foundAccount[property] : foundAccount ) : null;
	}
	getCurrentDestination(){ return this.dmcDestinations.find(item => item.selected);}

	restartProvidersAccounts(){
		if(!this.pageInfo.infoAccountsProvider || !this.pageInfo.infoAccountsProvider.accounts){ return; }
		this.pageInfo.infoAccountsProvider.accounts.forEach(account => account.selected = false);
		if(!this.pageInfo.providersFromDestination){ return; }
		(this.dmcProviders || []).forEach(provider => provider.accounts.forEach(account => account.selected = false));
		(this.pageInfo.providersFromDestination || []).forEach(item => item.selected = false);
	}

	resetProviderForm(){
		this.pageInfo.providerForm[0].info	=	 {};	
		this.pageInfo.providerForm[0].item	=	 {};
	}

	findProviderData(provider){
		return (this.providers || []).find(el => el.id == provider.id);
	}

	addAccountToProvider(){
		if(!this.pageInfo.infoAccountsProvider){
			this.pageInfo.infoAccountsProvider	=	{provider 	:	{id : this.pageInfo.providerSelected.id, providerId : this.pageInfo.providerSelected.id},
													accounts	:	[]}
		};
		let checkIfSomeTmp	=	this.pageInfo.infoAccountsProvider.accounts.find(el => el.tmpCreation);
		if(checkIfSomeTmp){	return this.commons.generateToast('_ERROR','_ALREADY_CREATING_ACCOUNT', 'error')}
		this.pageInfo.displayTableAccounts = false;
		this.restartProvidersAccounts();
		this.resetProviderForm();
		let newAccount	:any =	{destinations : [],tmpCreation : true};
		this.pageInfo.infoAccountsProvider.accounts.push(newAccount);
		this.onSelectAccount(this.pageInfo.infoAccountsProvider.accounts[this.pageInfo.infoAccountsProvider.accounts.length-1]);
	}

	onSelectAccount(account){
		this.commons.toggleItem(account,this.pageInfo.infoAccountsProvider.accounts,'selected');
		this.pageInfo.providerAccountSelected	=	account.selected ? account : null;
		if(!account.selected){ this.resetProviderForm(); return; }
		this.pageInfo.providerForm[0].info			=	account || {};	
		this.pageInfo.providerForm[0].item			=	account || {};

		this.superformCtrl.changeFooterProperties(this.pageInfo.providerForm[0],'delete',{visible : this.pageInfo.providerForm[0].item.tmpCreation != undefined ? false : true});
		this.superformCtrl.changeFooterProperties(this.pageInfo.providerForm[0],'cancel',{visible : this.pageInfo.providerForm[0].item.tmpCreation != undefined ? true 	: false});	
	}

	getSchedulersFromAccount(){
		if(!this.pageInfo.infoAccountsProvider || !this.pageInfo.infoAccountsProvider.accounts){ return []};
		return (this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected) || {}).schedulers || []
	}

	async toggleDestinationToProviderAccount($event,destination){
		$event.stopPropagation();
		
		if(!this.pageInfo.infoAccountsProvider){	return this.commons.generateToast('_ERROR','_SELECT_OR_CREATE_ACCOUNT','error');}
		
		let accountSelected	=	this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected);
		if(!accountSelected){ return this.commons.generateToast('_ERROR','_NO_ACCOUNT_SELECTED','error')};
		
		let findDestination	=	accountSelected.destinations.findIndex(item => item.id == destination.id);
		if(findDestination == -1){
			accountSelected.destinations.push({id : destination.id, name : destination.name});
			if(!this.dmc.isNew && (this.mode == 'edit' || !destination.isNewDestination)){
				await this.aggregatorCtrl.toggleAccountToDestination(	this.dmc.id,
																	destination.id,
																	this.pageInfo.providerSelected.id,
																	{providerId : this.pageInfo.infoAccountsProvider.provider.providerId, accountId : accountSelected.id});
				this.emitter.emit({action : 'updateAggregatorsDest'});
				}else{
					this.emitter.emit({action:'assignDestination', data : {aggregator : this.pageInfo.providerSelected.id, accountSelected: accountSelected, destination : destination.id}});
				}
		}else{
			if(!this.dmc.isNew && (this.mode == 'edit' || !destination.isNewDestination)){
				await this.aggregatorCtrl.toggleAccountToDestination(	this.dmc.id, 
																		destination.id,
																		this.pageInfo.providerSelected.id, 
																		{providerId : this.pageInfo.infoAccountsProvider.provider.providerId, accountId : accountSelected.id});
				this.emitter.emit({action : 'updateAggregatorsDest'});
			}else{
				this.emitter.emit({action:'unassignDestination', data : {aggregator : this.pageInfo.providerSelected.id, destination : destination.id}});
			}
			accountSelected.destinations.splice(findDestination,1);
		}
		if(!this.dmc.isNew && (this.mode == 'edit' || !destination.isNewDestination)){
			await this.aggregatorCtrl.updateAccountToProviderDmc(this.dmc.id, this.pageInfo.infoAccountsProvider.provider.id, accountSelected)
		}
	}

	async handleProviderForm($event){
		console.log($event);
		switch($event.type){
			// providersFromDestination afegir-lo aquí i canviar-ho per pageInfo.form
			case 'save'	:	
				let emptyItems	=	Object.keys($event.item).filter(property => property == undefined || property == null);
				if(emptyItems.length > 0 || Object.keys($event.item).length == 0){	return this.commons.generateToast('_ERROR', '_EMPTY_PARAMS','error');}
				
				let foundProvider	=	this.dmcProviders.findIndex(item => item.provider.providerId == this.pageInfo.providerSelected.id);
				if(foundProvider> -1){
					let foundAccount = this.dmcProviders[foundProvider].accounts.findIndex(item => item.account == $event.item.account);
					if(foundAccount == -1){
						delete $event.item.tmpCreation;
						let newAccount	=	{...$event.item,id : this.commons.generateHash(), isNew : true, destinations : []};
						if(!this.dmc.isNew){
							let newData = await this.aggregatorCtrl.addAccountToProviderDmc(this.dmc.id,this.dmcProviders[foundProvider].provider.id,newAccount);
							newAccount.id = newData.id 
						}
						this.dmcProviders[foundProvider].accounts.push(newAccount);
					}else{
						delete $event.item.tmpCreation;
						let updatedAccount	=	this.dmcProviders[foundProvider].accounts[foundAccount];
						Object.keys($event.item).forEach(key => updatedAccount[key]	= $event.item[key])	
						if(!this.dmc.isNew){
							await this.aggregatorCtrl.updateAccountToProviderDmc(this.dmc.id, this.dmcProviders[foundProvider].provider.id, updatedAccount)
						}
					}
					this.pageInfo.infoAccountsProvider	=	this.dmcProviders[foundProvider];
					// let accountSelected 				=	this.pageInfo.infoAccountsProvider.accounts[foundAccount > -1 ? foundAccount : this.pageInfo.infoAccountsProvider.accounts.length -1]
					// this.onSelectAccount(accountSelected);
					// this.pageInfo.providerForm[0].info			=	accountSelected || {};	
					// this.pageInfo.providerForm[0].item			=	accountSelected	|| {};
				}else{
					if(!this.dmc.isNew){
						let newProvider 	= { id : this.pageInfo.providerSelected.id, providerId : this.pageInfo.providerSelected.id};
						await this.aggregatorCtrl._addProviderToDmc(this.dmc.id,newProvider);
						delete $event.item.tmpCreation;
						let newAccount		=	{...$event.item,id : null, isNew : true, destinations : []};	
						let accountAdded	=	await this.aggregatorCtrl.addAccountToProviderDmc(this.dmc.id, newProvider.id,newAccount);
						newAccount.id		=	accountAdded.id;
						this.dmcProviders.push({
							provider 	: 	newProvider,
							accounts	:	[newAccount],
							isNew		:	true
						});
					}else{
						let newProvider	=	{ id : this.pageInfo.providerSelected.id, providerId : this.pageInfo.providerSelected.id}
						const tmpId	=	this.commons.generateHash();
						delete $event.item.tmpCreation;

						this.dmcProviders.push({
							provider 	: 	newProvider,
							accounts	:	[{...$event.item,id : tmpId, isNew : true, destinations : []}],
							isNew		:	true
						});
					}
					
					// this.addAccountToProvider();
					
					this.pageInfo.infoAccountsProvider	=	this.dmcProviders[this.dmcProviders.length -1];
					let accountSelected 				=	this.pageInfo.infoAccountsProvider.accounts[this.pageInfo.infoAccountsProvider.accounts.length -1];
					
					accountSelected.selected 			=	true;
					// this.onSelectAccount(accountSelected);
					this.pageInfo.providerForm[0].info			=	accountSelected || {};	
					this.pageInfo.providerForm[0].item			=	accountSelected	|| {};
				};
				this.addSchedule();
				console.log('UPDATED PROVIDERS FROM COMPANY',this.dmcProviders);
				break;

			case 'delete'	:	
				return this.confirmCtrl.confirm({
					message 	: 	this.commons.getTranslate('_DELETE_ACCOUNT'),
					header		: 	this.commons.getTranslate('_CONFIRMATION'),
					icon		: 	'pi pi-exclamation-triangle',
					key			:	'removeAggregatorAccount',
					acceptLabel	:	this.commons.getTranslate('_DELETE'),
					rejectLabel	:	this.commons.getTranslate('_CANCEL'),
					accept: async () => {
						let accountToDelete	=	this.pageInfo.infoAccountsProvider.accounts.findIndex(item => item.id == $event.item.id);
						if(accountToDelete == -1) { return this.commons.generateToast('_ERROR', '_COULD_NOT_DELETE_ACCOUNT','error')}
						
						if(!this.dmc.isNew){
							let accountInfo	=	this.pageInfo.infoAccountsProvider.accounts[accountToDelete];
							await Promise.all(accountInfo.destinations.map(async destination =>{
								await this.aggregatorCtrl.removeAccountToDestination(this.dmc.id, destination.id,this.pageInfo.providerSelected.id, accountInfo.id);
							}));
							let callUpdate = await this.aggregatorCtrl.removeAccountProviderDmc(this.dmc.id,this.pageInfo.infoAccountsProvider.provider.id,accountInfo.id);
							callUpdate.success ? this.commons.generateToast('_SUCCESS','_ACCOUNT_DELETED','success') : this.commons.generateToast('_ERROR','_COULD_NOT_DELETE','error');
							this.pageInfo.infoAccountsProvider.accounts.splice(accountToDelete,1); // remove it from the list accounts table
						}else{
							this.pageInfo.infoAccountsProvider.accounts.splice(accountToDelete,1); // remove it from the list accounts table
						}
						this.resetProviderForm();	
					},
					reject: () => {}
				})
				break;

			case 'cancel'	:	
				this.pageInfo.providerForm[0].item	=	{}; 
				this.pageInfo.providerForm[0].info	=	{};
				this.pageInfo.displayTableAccounts	=	this.pageInfo.infoAccountsProvider.accounts.length > 0 ? true : this.pageInfo.displayTableAccounts;
				break;

			default			:	return;
		}
	}

	async selectDestination(destination){
		this.pageInfo.displayInfoDestination	=	false;
		let isSelected	=	this.checkIfDestinationAssigned(destination);
		if(!isSelected){ return this.commons.generateToast('_INFO','_ENABLE_DESTINATION','info')}
		this.commons.toggleItem(destination,this.dmcDestinations,'selected');
		this.pageInfo.destinationSelected = destination.selected ? destination : null;
		if(this.pageInfo.destinationSelected){
			await this.mountDestinationsEntities();
			this.pageInfo.displayInfoDestination	=	true;
		}
	}

	getFormatDateHour(date){
		return this.commons.getFormatDateHour(this.formatTimeStamp(date));
	}
	checkCurrentSchedule(){
		return this.pageInfo.currentSchedule.start_hour != null && this.pageInfo.currentSchedule.days.length > 0;
	}
	checkIfDisplayScheduler(){
		if(!this.pageInfo.infoAccountsProvider || !this.pageInfo.infoAccountsProvider.accounts){ return false; }
		return this.pageInfo.infoAccountsProvider.accounts.some(account => account.selected);
	}
	addSchedule(){
		this.pageInfo.editingScheduler 					= 	!this.pageInfo.editingScheduler;
		this.pageInfo.timerFormProvider[0].info.start_hour	=	new Date();
		this.pageInfo.currentSchedule	=	{
			id			:	this.commons.generateHash(+new Date()),
			period		:	{
				start	:	0,
				end		:	null
			},
			isNew		:	true,
			start_hour	:	this.pageInfo.timerFormProvider[0].info.start_hour,
			days		:	[]
		};	
	}
	onClickOptionsScheduler($event, option){
		this.pageInfo.currentSchedule.days	=	option.value;
		this.pageInfo.currentSchedule.type	=	option.type;
		this.pageInfo.currentSchedule.start_hour	=	this.pageInfo.timerFormProvider[0].info.start_hour;
	}
	onSelectSchedule(schedule){
		this.pageInfo.currentSchedule				=	JSON.parse(JSON.stringify(schedule));
		this.pageInfo.listDays.forEach(day => day.selected = false);
		this.pageInfo.currentSchedule.days.forEach(day => this.pageInfo.listDays.find(el => el.value == day).selected = true);
		this.pageInfo.currentSchedule.start_hour	=	new Date(this.commons.formatTimestamp(this.pageInfo.currentSchedule.start_hour));
		this.pageInfo.timerFormProvider[0].info		=	this.pageInfo.currentSchedule	
		this.pageInfo.editingScheduler	=	true;
	}
	onClickDaySchedule($event,day){
		console.log($event, day);
		day.selected = $event;
		let findIndex = this.pageInfo.currentSchedule.days.findIndex(el => el == day.value);
		return findIndex > -1 	?	this.pageInfo.currentSchedule.days.splice(findIndex,1)
								:	this.pageInfo.currentSchedule.days.push(day.value);
	}
	handleScheduleTimePicker($event){
		console.log($event);
		this.pageInfo.currentSchedule.start_hour		=	$event.item.start_hour;
	}
	cancelScheduler(){
		this.pageInfo.currentSchedule	=	{
			period		:	{
				start	:	0,
				end		:	null
			},
			start_hour	:	this.pageInfo.timerFormProvider[0].info.start_hour,
			interval:	null,
			days	:	[]
		};
		this.pageInfo.editingScheduler = false;
		// if(this.pageInfo.providerSelected.schedulers.length > 0){	this.pageInfo.editingScheduler	=	false;	}

	}
	async deleteScheduler(){
		let currentAccount 		=	this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected);
		currentAccount.schedulers 	=	currentAccount.schedulers || [];
		let findedSchedule = currentAccount.schedulers.findIndex(item => item.id == this.pageInfo.currentSchedule.id);
		findedSchedule > -1 ?	currentAccount.schedulers.splice(findedSchedule,1) : this.commons.generateToast('_ERROR','_COULD_NOT_DELETE_SCHEDULE','error');
		let callInfo	=	await this.aggregatorCtrl.updateAccountToProviderDmc(this.dmc.id,this.pageInfo.infoAccountsProvider.provider.id,currentAccount);
		callInfo.success ? this.commons.generateToast('_SUCCESS','_SCHEDULER_DELETED','success') : this.commons.generateToast('_ERROR','_COULT_NOT_DELETE','error');
		this.cancelScheduler();
	}
	async saveSchedulerToProvider(){
		this.pageInfo.currentSchedule.interval		=	this.pageInfo.timerFormProvider[0].item.interval 	||  null;

		this.pageInfo.currentSchedule.id			=	this.pageInfo.currentSchedule.id || this.commons.generateHash();
		let valueDays								=	this.pageInfo.formScheduler.find(el => el.id == this.pageInfo.currentSchedule.typeSchedule);
		if(valueDays.type == 'custom'){
			this.pageInfo.currentSchedule.days			=	this.pageInfo.listDays.filter(el => el.selected).map(el => el.value);
		}else{
			this.pageInfo.currentSchedule.days			=	valueDays.value;
		}
		delete this.pageInfo.currentSchedule.isNew;
		let currentAccount 		=	this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected);
		currentAccount.schedulers 	=	currentAccount.schedulers || [];
		let findSchedule		=	currentAccount.schedulers.findIndex(item => item.id == this.pageInfo.currentSchedule.id);
		findSchedule	> -1	?	currentAccount.schedulers[findSchedule]	=	JSON.parse(JSON.stringify(this.pageInfo.currentSchedule))	
								:	currentAccount.schedulers.push(this.pageInfo.currentSchedule)

		
		if(!this.dmc.isNew){
			await this.aggregatorCtrl.updateAccountToProviderDmc(this.dmc.id,this.pageInfo.infoAccountsProvider.provider.id,currentAccount);
		}

		this.pageInfo.currentSchedule	=	{};
		this.pageInfo.editingScheduler	=	false;
		this.pageInfo.enableAddProviderAccount	=	true;
	}

	providerAdded(provider){
		if(!this.pageInfo.form.providers || !provider){ return false;}
		return this.pageInfo.form.providers.some(el => el.provider.id == provider.id);
	}
	getAccountSelected(){
		if(!this.pageInfo.infoAccountsProvider){ return false}
		let accountSelected	=	this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected);
		if(!accountSelected){ return false;}
		return accountSelected && !accountSelected.tmpCreation;
	}
	checkIfDestinationAssigned(destination){
		if(!this.pageInfo.infoAccountsProvider){ return false}
		let accountSelected	=	this.pageInfo.infoAccountsProvider.accounts.find(item => item.selected);
		if(!accountSelected){ return false;}
		return (accountSelected.destinations || []).findIndex(item => item.id == destination.id) > -1; 
	}
	formatTimeStamp(time){	return this.commons.formatTimestamp(time);	}

	/* ------------------------------------------------------------------
	/ DESTINATION ENTITIES
	/--------------------------------------------------------------------*/
	/**
	 * Check if data from destination is loaded previously or save it to global variable
	 */
	async mountDestinationsEntities(){
		this.pageInfo.destinationsEntities	=	this.pageInfo.destinationsEntities || {};
		if(this.pageInfo.destinationsEntities[this.pageInfo.destinationSelected.id]){ return; }
		let destinationInfo	=	{
			id			:	this.pageInfo.destinationSelected.id,
			areas		:	await this.destinationCtrl.getAreasFromDestination(this.pageInfo.destinationSelected.refAreas),
			stations	:	this.pageInfo.destinationSelected.workingStations,	
			listPrices	:	this.pageInfo.destinationSelected.ref 	?	await this.aggregatorCtrl.getListPricesFromProvider(this.pageInfo.destinationSelected.ref,this.pageInfo.providerSelected.id)
																	:	[]
		};
		this.pageInfo.destinationsEntities[this.pageInfo.destinationSelected.id]	=	destinationInfo;
	}

	/* ------------------------------------------------------------------
	/ LIST PRICING METHODS
	/--------------------------------------------------------------------*/
	async handleActionPrices($event){
		if(this.pageInfo.destinationSelected.ref){
			let update = await this.aggregatorCtrl.updateProviderPricing(this.pageInfo.destinationSelected.ref, this.pageInfo.providerSelected, $event.data);
			update.success	?	this.commons.generateToast('_SUCCESS','_DATA_SAVED','success') : this.commons.generateToast('_ERROR','_ERROR','error');
		}
	}
}