import { Component, 
         OnInit, 
         ViewEncapsulation, 
         Input, 
         Output, 
         EventEmitter           } from '@angular/core';
import { FirebaseService 		} from '../../../service/database/firebase.service';
import { StorageService 		} from '../../../service/storageservice';
import { DestinationsService    } from '../../../service/database/destinations.service';
import { AggregatorsService     } from '../../../service/database/aggregator.service';
import { CommonsService         } from 'src/app/demo/service/commons.service';

@Component({
	selector		: 'app-manage-zones',
    styleUrls		: ['./manage-zones.component.scss'],
	templateUrl		: './manage-zones.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class ManageZonesComponent implements OnInit
{
    @Input() fromWizard         : Boolean = false;
    @Input() mode               : any;
    @Input() aggregatorsDest    : any;
    @Input() areasDestination   : any;
    @Input() destination        : any;
    @Input() dmc                : any;
    @Input() zonesAggregators   : any;

    @Output() emitter           = new EventEmitter<any>();
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private aggregatorCtrl	: AggregatorsService,
		private destinationCtrl : DestinationsService,
        private commons         : CommonsService

	)					{ 			 					}
	async ngOnInit() 	{	await this.init();
							await this.loadEntities();	}
    async init()		{
        //
        this.pageInfo   =   {
            entities    :   {},
            dataLoaded  :   false
        }
		
		this.userInfo					=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
    }

    async loadEntities(){
        const entities  =   ['zonesAggregators','aggregators','aggregatorsDest','dmcDestination','infoDestination','areasDestination'];
        for(let entity of entities){
            await this.load(entity);
        }
        this.pageInfo.dataLoaded = true;
    }

    async load($entity){
		this.pageInfo.entities[$entity] 		=   this.pageInfo.entities[$entity] || {};
		this.pageInfo.entities[$entity].loading =	true;
        let response;
        switch($entity){
            case 'zonesAggregators'     :   response                                    =   {success : true, data : this.zonesAggregators || []};
                                            this.pageInfo.entities[$entity].data		= 	response["data"];
            break;
            case 'aggregators'          :   response                                =   await this.aggregatorCtrl.getProviders();
                                            this.pageInfo.entities[$entity].data    =   response;
                                            break;
            case 'aggregatorsDest'      :	response	                            =	this.aggregatorsDest    ?   { success : true, data : this.aggregatorsDest || []}
                                                                                                                :   { success : true, data : await this.aggregatorCtrl.getAggregatorsFromDestination(this.userInfo.idDmc, this.userInfo.currentDestination.id)};
                                            this.pageInfo.entities[$entity].data	=	this.mergeInfoAggregators(response.data);
                                            break;
            case 'dmcDestination'       :   response	                        	=	this.fromWizard ? { success : true, data : []}
                                                                                                        : { success : true, data : await this.destinationCtrl.getInfoDestinationFromDmc(this.userInfo.idDmc,this.userInfo.currentDestination.id)};
                                            this.pageInfo.entities[$entity].data    =   response.data;
                                            break;   
            case 'infoDestination'      :   response                                =   this.fromWizard         ?   { success : true, data : null}
                                                                                                                :   { success : true, data : await this.firebaseCtrl.getDataByRef(this.getFilteredEntity('dmcDestination').refDestination)};
                                            this.pageInfo.entities[$entity].data    =   response.data;
                                            break;
            case 'areasDestination'	    :   response				     	        =   this.areasDestination   ?   { success : true, data : this.areasDestination || []}
                                                                                                                :   { success : true, data : await this.destinationCtrl.getAreasFromDestination(this.getFilteredEntity('infoDestination').refAreas)};
                                            this.pageInfo.entities[$entity].data    =	response.data; 	
										    break;
        
        }
        this.pageInfo.entities[$entity].loading =	false;
    }

    getFilteredEntity($entity){
        switch($entity){
            default                 :   return this.pageInfo.entities[$entity].data;
        }
    }

    mergeInfoAggregators(listAggregators){
        return listAggregators.map(aggregator => {
            let foundAggregator =   this.getFilteredEntity('aggregators').find(item => item.id == aggregator.id || item.id == aggregator.providerId);
            
            return foundAggregator ?    {...aggregator, name : foundAggregator.name, logo :  "/assets/layout/icons/providers/"+foundAggregator.thumbnail+".png"}
                                    :   aggregator;
        });
    }

    async handleMapAction($event){

        // Hard code HTX as provider
        // Move zones info to destination, not to provider inside destination
        // const aggregatorRef = $event.data.aggregator.ref;
        const selectedDmc			= this.commons.pageInfo.dmcSelected;
        const selectedDestination	= this.commons.pageInfo.destinationSelected;
        const selectedProvider      = "1";

		let error = false;

		if(undefined==selectedDmc			){ error = true; }
		if(undefined==selectedDestination	){ error = true; }
		if(undefined==selectedProvider		){ error = true; }

		if(error){
			this.commons.generateToast("_ERROR","_NO_PROVIDER_INFO","error");
			return false;
		}

		// return false; 
        const aggregatorRef         = "/dmcs/"+selectedDmc.id+"/destinations/"+selectedDestination.id+"/aggregators/"+selectedProvider;

        switch($event.action){
            default                 :   return;
            case 'goToAggregators'  :   return this.emitter.emit($event);
            case 'update'           :   
            switch(this.fromWizard){
                case true   :
                    switch(this.mode){
                        case "edit" :
                            let response = await this.firebaseCtrl.updateItemByRef(aggregatorRef,{ zones : $event.data.zones });
                            break;
                        default     :
                            this.emitter.emit({ action : 'saveZones', data : $event});
                            break;                        
                    }
                    break;
                default     :
                    await this.firebaseCtrl.updateItemByRef($event.data.aggregator.ref,{
                        zones : $event.data.zones
                    });
                }
                // this.commons.pageInfo.creationProcess = false;
                await this.load('aggregatorsDest');
            }
        }
}