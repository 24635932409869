import { EntityService 						} from '../../demo/service/entity.service';
import { CommonsService 					} from '../../demo/service/commons.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild,
		 OnChanges							} from '@angular/core';
import { TabView							} from 'primeng/primeng';

@Component({
	selector	: 'app-flight-checker',
	templateUrl	: './flight-checker.component.html',
	styleUrls	: ['./flight-checker.component.scss']
})

export class FlightCheckerComponent implements OnInit,OnChanges {
	
	@ViewChild(TabView) tabView		: TabView;
	@ViewChild("dt")	dataTable	: any;
	
	@Input()	rowData			: any	= {};
    @Input() 	providers       : any[] = [];
    @Input() 	flights      	: any[] = [];
    @Input()	airlines		: any[]	= [];
	@Input()	airports		: any[]	= [];
	@Output() 	emiterOption	: EventEmitter<any> = new EventEmitter();

    pageInfo	: any	= {
		height					: { flightTable: '50vh' },
        selectedFilter          : 'area',
		tabSelectedIndex		: 0,
		checks					: { 
									provider 	: { items: [], cols: [] },
									flight		: { items: [], cols: [] }	
								},
		providers				: {
			database			: ((me)=>(x,y)=>me.generateFlightsByDatabase	(me,x,y))(this)
			// ,skyscanner			: ((me)=>(x,y)=>me.generateFlightsBySkyScanner	(me,x,y))(this)
		}
	};
    
	constructor(
		private commons 		: CommonsService, 
		private entityService 	: EntityService
	){}
	
	async init()
	{
		this.pageInfo.filters       = [
			{ label : this.commons.getTranslate('_AREA'),     value : 'area'		},
			{ label : this.commons.getTranslate('_DISTANCE'), value : 'distance'	}
		];
	}

	async ngOnInit(){
		this.pageInfo.checks 		= {
			provider 		: {
				panel	: false,
				cols	: [ 
					{ field: 'source',			label: "Source",		align: "left",	 width: "150px"	 		},
					// { field: 'company',			label: "Company", 		align: "center", width: "200px"  		},
					{ field: 'flight',			label: "Flight",		align: "center", width: "100px" 		},
					{ field: 'departure',		label: "Departure",		align: "center", width: "100px" 		},
					{ field: 'origin',			label: "Origin",		align: "center", width: "400px" 		},
					{ field: 'destination',		label: "Destination",	align: "center", width: "400px"			}					
				],
				items	: []
			},
			flight 			: {
				panel		: false,
				placeholder	: "_SET_FILTER_SEARCH",
 				search		: "",
				cols		: [
					{ field: 'id',						label: "Id", 			align: "center", width: "50px",  filter: false, disabled: true  	},
					{ field: 'company',					label: "Company", 		align: "center", width: "150px", filter: false  	},
					{ field: 'code',					label: "Flight",		align: "center", width: "100px", filter: true 		},
					// { field: 'departure',			label: "Departure",		align: "left",	 width: "100px", filter: false		},
					{ field: 'origin',					label: "Origin",		align: "left",	 width: "200px", filter: false		},
					// { field: 'originCode',			label: "Origin Code",	align: "center", width: "50px", filter: false 	},
					{ field: 'destination',				label: "Destination",	align: "center", width: "200px", filter: false 		},
					// { field: 'destCode',				label: "Dest Code",		align: "center", width: "50px", filter: false 		},
					
					{ field: 'date',						label: "Date",			align: "center", width: "100px", filter: false 		},
					{ field: 'time',						label: "Time",			align: "center", width: "100px", filter: false 		},
					
					// { field: 'departureDate',			label: "Departure",		align: "center", width: "100px", filter: false 		},
					// { field: 'departureTime',			label: "Time",			align: "center", width: "100px", filter: false 		},
					// { field: 'departureTimeDelay',		label: "Delay",			align: "center", width: "200px", filter: false 		},
					// { field: 'arrivalDate',				label: "Arrival",		align: "center", width: "100px", filter: false 		},
					// { field: 'arrivalTime',				label: "Time",			align: "center", width: "100px", filter: false 		},
					
					// { field: 'arrivalTimeDelay',		label: "Delay",			align: "center", width: "200px", filter: false 		}
				],
				items		: []
			},
			flightProvider 	: {
				panel	: false,
				cols	: [ 
					{ field: 'provider',		label: "Provider",		align: "left",	 width: "150px"	 		},
					{ field: 'departure',		label: "Departure",		align: "center", width: "100px" 		},
					{ field: 'origin',			label: "Origin",		align: "center", width: "380px"			},
					{ field: 'destination',		label: "Destination",	align: "center", width: "380px"			},
					{ field: 'time',			label: "Last Check",	align: "center", width: "100px" 		}
				],
				items	: []
			},
			airportFrom	: { name 	: "" 	},
			airportTo	: { name	: ""	}
		};
	}

	// Get data updates
	ngOnChanges(data) 			{ 	
		if(undefined!==data.rowData){	
			this.reloadData({ check: 'row'}, data.rowData.currentValue);	
		}
		if(undefined!==data.flights){
			// alert("VUELOS");
		}			
	}

	// Reload data on row change or field check
	private reloadData($item,current=this.rowData)
	{
		let flightCodeInfo:any = { success: false };
		
		switch(current["checkFieldDirection"])
		{
			case "arrival"		:	this.pageInfo.checks.airportFrom	= { name: current['arrival_GatewayTo'	]	}
									this.pageInfo.checks.airportTo		= { name: current['arrival_From'		]	}				
									current.arrival_AirportFromCode		= this.getAirport("from", current);
									current.arrival_AirportToCode		= this.getAirport("to"	, current);
									flightCodeInfo						= this.getFlightCodeInfo(current);
									if(flightCodeInfo["success"]){
										current.arrival_Airline			= flightCodeInfo["airline"];
										current.arrival_FlightNumber	= flightCodeInfo["number"];
									}
									break;

			case "departure"	:	this.pageInfo.checks.airportFrom	= {	name: current['departure_To'		]	}
									this.pageInfo.checks.airportTo		= { name: current['departure_GatewayTo'	]	}												
									current.departure_AirportFromCode	= this.getAirport("from", current);
									current.departure_AirportToCode		= this.getAirport("to"	, current);
									flightCodeInfo						= this.getFlightCodeInfo(current);
									if(flightCodeInfo["success"]){
										current.departure_Airline		= flightCodeInfo["airline"];
										current.departure_FlightNumber	= flightCodeInfo["number"];
									}
									break;
		}
		this.generateProviders();
		this.generateFlights();

		this.checkField('copy','flight',this.rowData,["partial"]);
	}

	private generateProviders(){
		this.pageInfo.checks.provider.items = [];
		(this.providers||[]).forEach(provider=>{
			this.pageInfo.checks.provider.items.push({
				source		: provider.source,
				icon		: '/assets/layout/icons/flightcheckers/'+provider.field+'.png',
				company		: '',
				flight		: '',
				departure	: '',
				origin		: '',
				destination	: '',
			});
		});
	}

	private generateFlights(type="database"){
		this.pageInfo.checks.flight.items = [];
		(this.flights || []).forEach( flight => { 
			let flightInfo = this.pageInfo.providers[type](this.rowData,flight);
			if(undefined!=flightInfo){	this.pageInfo.checks.flight.items.push(flightInfo); }
		});		
		// this.generateFilteredEntity('flights');	// Generate initial filtered items
	}
	
	/**
	 * generate flight throught database format
	 * @param flight 
	 */
	private generateFlightsByDatabase(me,rowData,flight)
	{
		rowData.base = rowData.base || "";

		// Check if one of the stations is our base		
		switch(rowData.direction){
			case "arrival"	: 	if(!flight.destCode || !flight.destCode.includes	(rowData.base.toUpperCase())){ return; }; 
								break;
			case "departure": 	if(!flight.originCode || !flight.originCode.includes(rowData.base.toUpperCase())){ return; };
								break;
		}
										
		/**
		 * 	active		: true
			code		: "HV6758"
			company		: "Transavia"
			departure	: "09:46"
			destCode	: "GRQ"
			destination	: "Groningen (GRQ)"
			direction	: "departure"
			id			: 428
			origin		: "Palma De Mallorca Airport (PMI)"
			originCode	: "PMI"
			*/		
		return {
			id			: flight.id,
			direction	: flight.direction,
			company		: flight.company,
			code		: flight.code,
			departure	: flight.departure,
			originCode	: flight.originCode,
			origin		: flight.origin,
			destCode	: flight.destCode,
			destination	: flight.destination,
			providers	: flight.providers,			
			expanded	: true
		};
	}

	/**
	 * get Airline code
	 * @param data 
	 */
	private getAirport($type,data){
		if(undefined === data || undefined === data.customer ){ return false; }
		
		let airportStr;
		switch($type){
			case "from"	: airportStr = data[data["checkFieldDirection"]=='arrival'? data["checkFieldDirection"]+'_GatewayTo': data["checkFieldDirection"]+'_From'		]; break;
			case "to"	: airportStr = data[data["checkFieldDirection"]=='arrival'? data["checkFieldDirection"]+'_From'		: data["checkFieldDirection"]+'_GatewayTo'	]; break;
		}
		
		if(undefined==airportStr){ return false; }

		const airport	= this.airports.find(item=>airportStr.includes("("+item.code+")"));
		return airport!==undefined?airport.code:"";
	}

	/**
	 * get Airline code
	 * @param data 
	 */
	private getFlightCodeInfo(data){
		if(undefined === data || undefined === data.customer ){ return false; }
		const flight 		= data[data["checkFieldDirection"]+"_GatewayInfo"];
		const parsedInfo	= flight.toUpperCase().replace(/([A-Z]{2,3})\/?([A-Z]{0,3})/g,'$1,$2,').split(',');	// [ iso, icao, number ]
		const airline 		= this.airlines.find(item=>{
			return item.iso_code==parsedInfo[0] || ( item.icao_code != null && item.icao_code == parsedInfo[1] )
		});
		return (undefined!==airline && null!==airline)?{ success: true, airline: airline.name, number: parsedInfo[2] }:{ success : false };
	}

	// onChangeTabView($event){
	// 	this.pageInfo.tabSelectedIndex = $event.index; 
	// }
	public checkField( $type, $item, $data={}, $extras=["full"] ){	
		// if($data["value"]==undefined){ return false; }	
		switch($type){
			case "copy":			let value, field;
									switch($item){
										case "origin"		: 	field	= "origin";			
																value 	= $data["checkFieldDirection"]=="arrival"?$data["checkFieldDirection"]+"_GatewayTo":$data["checkFieldDirection"]+"_From";
																break;
										case "destination"	: 	field	= "destination";	
																value 	= $data["checkFieldDirection"]=="arrival"?$data["checkFieldDirection"]+"_From":$data["checkFieldDirection"]+"_GatewayTo";
																break;
										case "flightPartial":
										case "flight"		: 	field	= "code";
																value	= $data["checkFieldDirection"]+"_GatewayInfo";
																break;					
										default				: 	return false;
									}
									// Filter by this field
									this.pageInfo.checks.flight.cols = this.pageInfo.checks.flight.cols.map(item=>{
										if(item.field==field)	{ item.filter = true; 	} 
										else 					{ item.filter = false; 	}
										return item;
									});
					
									($extras||[]).forEach(extra=>{	
										switch(extra){
											case "full"		:	this.pageInfo.checks.flight.search = $data[value];					break;
											case "partial"	:	this.pageInfo.checks.flight.search = ($data[value]||'').slice(0,-2);break;
										}
									});
									
									this.search('flights','checkField');				// Search
									break;

			case 'clearSearch': 	this.pageInfo.checks.flight.search = "";			// Clear search combo
									break;
		}
	}

	public doAction($event,$action,$item=undefined){
		if(undefined !== $event.originalEvent) { $event.originalEvent.stopPropagation(); }
		switch($action){
			case "assignFlightNumber"	: 	const type 				= this.rowData["checkFieldDirection"]+"_GatewayInfo";
											this.rowData[type] 	= $item.code; 		
											// this.clearError(type);
											// this.emiterOption.emit({ action: 'accept' });
											break;
			case "assignFlightTime"		:	let date = new Date($item["date"]);
											this.rowData["date"] = this.commons.formatCustomDate(date,"YYYY-MM-DD");
											this.rowData["time"] = this.commons.formatCustomDate(date,"HH:mm");
											break;
			// case "assignAirportFrom"	: 	this.rowData[this.rowData["checkFieldDirection"]+"_AirportFromCode"	]	= $event.code; 		break;
			// case "assignAirportTo"		: 	this.rowData[this.rowData["checkFieldDirection"]+"_AirportToCode"		]	= $event.code; 		break;
			case "cancel"				: 	this.emiterOption.emit({ action: 'cancel'			}); break;
			case "accept"				: 	
											this.clearError(this.rowData["checkFieldDirection"]+"_GatewayInfo");
											this.emiterOption.emit({ action: 'accept' 			}); 
											break;

			case "reject"				: 	this.emiterOption.emit({ action: 'reject'	 		}); break;
		}
	}

	clearError(type)					{	this.rowData["errors"] = this.rowData["errors"].filter(item=>item["field"]!=type);	}

	search($entity,$info=undefined){
		switch($entity){
			case "flights"		:		this.generateFilteredEntity($entity,$info); break;
			case "airportFrom"	:
			case "airportTo"	:		return this.generateFilteredEntity($entity);
		}
	}

	generateFilteredEntity($entity,$info="none"){
		if(undefined==this.dataTable){ return false; }
		switch($entity){
			case "flights"		:		this.dataTable.filterGlobal(this.pageInfo.checks.flight.search,'contains');				
										break;
			case "airportFrom"	:
			case "airportTo"	:
				// When manipulate input systems convert it to string, so move it back to object
				if ( typeof this.pageInfo.checks[$entity] == "string"	){ this.pageInfo.checks[$entity] = { name: this.pageInfo.checks[$entity] }; }
				if ( undefined 	=== this.pageInfo.checks[$entity].name	){ this.pageInfo.checks[$entity].name = ""; 	}
				if ( null 		=== this.pageInfo.checks[$entity].name	){ this.pageInfo.checks[$entity].name = ""; 	}
				if ( this.pageInfo.checks[$entity].name == ""			){ return []; 									}
				if ( this.pageInfo.checks[$entity].name.length < 3	){ return [];									}
				return this.airports.filter(item=>item.name.toLowerCase().includes(this.pageInfo.checks[$entity].name.toLowerCase()))
									// .map(item=>item.name)
									;

			case "airlines":		return this.airlines;
		}
	}

	getFilteredEntity($entity,$direction){
		switch($entity){
			case "_flights":	if(this.pageInfo.checks.flight.search==""){ this.generateFilteredEntity('flights'); }
								return this.pageInfo.checks.flight.filtered;
			// case "flights":		return this.pageInfo.checks.flight.items.filter(item=>item.direction==$direction);
			// case "flights":		return this.pageInfo.checks.flight.items;
			case "flights"	:	return this.flights;
		}
	}

	getCols($entity){
		return this.pageInfo.checks[$entity].cols.map(item=>{
			switch(this.rowData["checkFieldDirection"]){
				case "arrival":		switch(item.field){	
										case "id"					:
										// case "departureDate"		:
										// case "departureTime"		:
										// case "departureTimeDelay"	:										
										case "destination"			:	item.disabled = true; 	break;
										default						:	item.disabled = false;
									}
									break;
				case "departure":	switch(item.field){
										case "id"					:
										// case "arrivalDate"			:
										// case "arrivalTime"			:
										// case "arrivalTimeDelay"		:	
										case "origin"				:	item.disabled = true; 	break	
										default						:	item.disabled = false;	break;
									}
									break;
			}
			return item;
		});
	}

	setFilter($entity,$field)	{	switch($entity){
										case "flights":
											this.pageInfo.checks.flight.cols = this.pageInfo.checks.flight.cols.map(item=>{
												if(item.field==$field){ item.filter = item.filter==true?false:true; }
												return item;
											});
											break;
									}
								}

	expandRow($item)			{ 	this.toggleDT($item); }
	collapseRow($item)			{ 	this.toggleDT($item);	}
	toggleRow($item)			{ 	this.toggleDT($item);	}
	toggleDT($item)				{	
		if(undefined===$item){ return false; }
		if(undefined==this.dataTable.expandedRowKeys[$item.id]){
			this.dataTable.expandedRowKeys 			 	= []; 
			this.dataTable.expandedRowKeys[$item.id] 	= 1;
			$item.expanded								= true;
		} else {
			$item.expanded								= false;
			this.dataTable.expandedRowKeys 				= [];		
		}
	}
}
