import { CommonsService } from './../../demo/service/commons.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild} from '@angular/core';

@Component({
  selector    : 'app-calendar-tours-price-table',
  templateUrl : './calendar-tours-price-table.component.html',
  styleUrls   : ['./calendar-tours-price-table.component.scss'] ,

})
/**
 * tablePrice Row : 
 * {
 *  [{
 *      type    :   'adult',
 *      img     :   'path'
 *      qty     :   25
 *  }]
 * }
 */
export class CalendarToursPriceTableComponent implements OnInit {
    
    pageInfo                    : any = {
		lastRowPriceTable		:	{
			id          : null,
			start_hour	: null, 
			prices		: [{
				type	: 'adult',
				price	:	null
			},{
				type	: 'children',
				price	:	null
			},{
				type	: 'infant',
				price	:	null
			}]
		},
	}
    @Input()    period          : any = {};
    @Input()    day             : any = {};
    @Output()   emiterOption	:   EventEmitter<any>   = new EventEmitter();

    constructor( private commons : CommonsService){

    }

    ngOnInit(){}

    toggleEditableRow(row){
        const tmp    = row.editMode ? !row.editMode : true;
        this.day.scheduler.map(el => el.editMode = false);
		row.editMode = tmp; 
	
		if(row.editMode){
			this.pageInfo.currentTimeSelected = JSON.parse(JSON.stringify(row));
			
			var today 		= 	new Date();
			const hours		=	this.pageInfo.currentTimeSelected.start_hour.split(':')[0];
			const minutes	=	this.pageInfo.currentTimeSelected.start_hour.split(':')[1];
			var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), +hours, +minutes, 0);

			this.pageInfo.currentTimeSelected.start_hour = myToday;

			console.log('CALENDAR DAY SELECTED:',this.pageInfo.currentTimeSelected);
		}else{
			// row.editMode = false;
			// this.emitOption({type: 'update', data : this.pageInfo.currentTimeSelected})
			// this.pageInfo.currentTimeSelected = row;	
		}
    }
    handleOption($event){
        this.emiterOption.emit($event);
    }

	deleteRow(row){
		console.log(this.pageInfo.daySelected);
		this.emitOption({type:'delete',data : {daySelected : this.pageInfo.daySelected, exec :row}});
	}
	addRow(){

		this.emitOption({type:'add', data : {daySelected : this.pageInfo.daySelected}});
		// this.pageInfo.currentTimeSelected = JSON.parse(JSON.stringify(this.pageInfo.daySelected.scheduler.find(el => el.editMode)));
		
	}
	async saveRow(row){

        if(this.pageInfo.currentTimeSelected.start_hour == null) return await this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		this.pageInfo.currentTimeSelected.start_hour =  this.commons.getFormatDateHour(this.pageInfo.currentTimeSelected.start_hour);

		if(this.day.scheduler.find(el => (el.start_hour == this.pageInfo.currentTimeSelected.start_hour) && (el.id != this.pageInfo.currentTimeSelected.id))) return await this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');

		if(!this.checkPricingRow()) return await this.commons.generateToast('_ERROR','_BAD_FORMED_PRICE','error');
		row.editMode								=	false;
		this.pageInfo.currentTimeSelected.editMode	=	false;
		this.emiterOption.emit({type:'update', data : {exec :this.pageInfo.currentTimeSelected}});
		this.pageInfo.currentTimeSelected 			= null;

		// console.log('TABLE PRICE AFTER UPDATE',this.day);		
	}
	async saveRowKeyUp($event, row){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		if(this.pageInfo.currentTimeSelected.start_hour == null) return await this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		this.pageInfo.currentTimeSelected.start_hour =  this.commons.getFormatDateHour(this.pageInfo.currentTimeSelected.start_hour);

		if(this.day.scheduler.find(el => (el.start_hour == this.pageInfo.currentTimeSelected.start_hour) && (el.id != this.pageInfo.currentTimeSelected.id))) return await this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');

		if(!this.checkPricingRow()) return await this.commons.generateToast('_ERROR','_BAD_FORMED_PRICE','error');
		
		row.editMode								=	false;
		this.pageInfo.currentTimeSelected.editMode	=	false;
		this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec :this.pageInfo.currentTimeSelected}});

	}

	checkPricingRow()	{	return !this.pageInfo.currentTimeSelected.prices.some(element => element.price === null || element.price < 0);}

    
	emitOption(data){
		this.emiterOption.emit(data);
	}

	restartLastRow(){
		this.pageInfo.lastRowPriceTable	=	{
			id          : null,
			start_hour	: null, 
			prices		: [{
				type	: 'adult',
				price	:	null
			},{
				type	: 'children',
				price	:	null
			},{
				type	: 'infant',
				price	:	null
			}]
		}
	}
	saveLastRow(){
		if(this.pageInfo.lastRowPriceTable.start_hour == null) return this.commons.generateToast('_ERROR','_BAD_FORMED_DATE','error');
		this.pageInfo.lastRowPriceTable.start_hour =  this.commons.getFormatDateHour(this.pageInfo.lastRowPriceTable.start_hour);
		
		if(this.day.scheduler.find(el => (el.start_hour == this.pageInfo.lastRowPriceTable.start_hour))) return this.commons.generateToast('_ERROR','_DUPLICATED_START_HOUR','error');
		if(this.pageInfo.lastRowPriceTable.prices.some(el => el.price <0 || el.price == null)) return this.commons.generateToast('_ERROR','_BAD_FORMAT_PRICE','error');

		this.emiterOption.emit({type:'lastRow', data : {daySelected : this.day, exec : JSON.parse(JSON.stringify(this.pageInfo.lastRowPriceTable))}});
		this.restartLastRow();
	}

	saveLastRowKeyUp($event){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		this.saveLastRow();
	}

	selectTimePicker($event){}
}