import { Component, OnInit, ViewEncapsulation   } from '@angular/core';
import { ToastrService                          } from 'ngx-toastr';
import { MapService } from 'src/app/demo/service/mapservice';
import { EntityService } from 'src/app/demo/service/entity.service';
import { StorageService } from 'src/app/demo/service/storageservice';
import { CommonsService } from 'src/app/demo/service/commons.service';
import { data 								} from './data/info';

@Component({
    styleUrls		: ['./mapcheckdistances.component.scss'],
	templateUrl		: './mapcheckdistances.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class MapCheckDistancesComponent implements OnInit{
    pageInfo : any   = {
        cls			: {
            map			: '',
            toolbar		: ''
        },
        zones           : [],
        currentZones    : [],
        legendZones     : [],
        createZone      : false,
        nameZone        : null,

        //----- VARIABLES DISTANCE
        tracking        :   false,
        indexCurrentArea     :   0,
		indexCurrentDestination	:	0,
        origin          :   null,
        destination     :   null
	};
	zonesRealized		:	any 	= [];
    areasmallorca       :   any     = {};
    zoom                :   number  =	10;
    lat                 :   number  = 39.638464;
    lng                 :   number  = 3.003831 ;
    
    constructor
    (
        private entityService	: EntityService,
        private mapService 		: MapService,
        private storageService  : StorageService,
        private commons         : CommonsService,
    )
    {
    }
    
    async ngOnInit(){
		await this.loadEntities();
        this.initMap();        
    }
    

  

    initMap(){
        this.pageInfo.zones                                             = this.storageService.getItem('zones') || [];
        // this.pageInfo.filters.find(el => el.entity == 'zones').items    = this.storageService.getItem('filtersZones') || [];
        console.log(this.pageInfo.zones);
    }
    async loadEntities() {
		await this.load('areasmallorca');  
    }
    
    async load($entity){
        switch ($entity)
        {
            case 'areasmallorca':
			await this.entityService.loadEntity($entity);
            this[$entity].data = this.entityService.get($entity);
            console.log('puting blue')
            this[$entity].data.map(el => el.color = '#6495ED' );
        }
    }

 
    generateCenters(){
		console.log(this.areasmallorca);
        this.pageInfo.areas =   this.areasmallorca.data.map((area,index) => {return {center  : this.mapService.get_polygon_centroid(area.coords), id: index,...area }});
		this.pageInfo.areas.push({
			center	: 	{lat: 39.5521152815252, lng: 2.736645344962426},
			color	:	'#6495ED',
			coords	:	[],
			id		:	this.pageInfo.areas.length,
			name	:	'PMI Airport'
		})
		console.log(this.pageInfo.areas);
		this.pageInfo.currentAreasCheckeds 	= 	this.storageService.getItem('calculedAreas') || JSON.parse(JSON.stringify(data));
		let fullCombinations 				= 	this.mountFullCombinationAreas();
		this.pageInfo.pendingCombinations 	=	this.getPendingCombinations(fullCombinations);
		
		console.log('AREASSS');
		console.log(this.pageInfo.currentAreasCheckeds);
    }
    
    removeCombinationDuplicate(){
		this.pageInfo.currentAreasCheckeds 	= 	this.storageService.getItem('calculedAreas');
		console.log(this.pageInfo.currentAreasCheckeds);
		var result = this.pageInfo.currentAreasCheckeds.reduce((unique, o) => {
			if(!unique.some(obj => obj.origin.id === o.origin.id && obj.destination.id === o.destination.id)) {
			  unique.push(o);
			}
			return unique;
		},[]);
		console.log(result);
		this.storageService.setItem('calculedAreas', result);
    }
	
	mountFullCombinationAreas(){
		let fullCombinations = [];
		for(let x = 0; x<this.pageInfo.areas.length; x++){
			for(let y = x+1; y<this.pageInfo.areas.length; y++){
				let newCombination = {
					data : null,
					destination	:	{name : this.pageInfo.areas[y].name, id	:	this.pageInfo.areas[y].id, center : this.pageInfo.areas[y].center},
					origin		:	{name : this.pageInfo.areas[x].name, id :	this.pageInfo.areas[x].id, center : this.pageInfo.areas[x].center}
				};
				fullCombinations.push(newCombination);
			}
		};
		console.log(fullCombinations);
		return fullCombinations;
	}

	getPendingCombinations(fullCombinations){
		let combinationCompleteds = fullCombinations.filter(data => undefined === this.pageInfo.currentAreasCheckeds.find(area => data.origin.id == area.origin.id && data.destination.id == area.destination.id));
		console.log('Combinations Pendings', combinationCompleteds);
		return combinationCompleteds;
	}
	
	checkAllDistances(){
		console.log('CHECKING');
		this.pageInfo.tracking = true;
		let currentIndex = 0;
		this.pageInfo.interval	=	setInterval(()=>{
			if(currentIndex < this.pageInfo.pendingCombinations.length){
				this.pageInfo.origin    				=   this.pageInfo.pendingCombinations[currentIndex].origin;
				this.pageInfo.destination   			=   this.pageInfo.pendingCombinations[currentIndex].destination;
				console.log(this.pageInfo.origin.center, this.pageInfo.destination.center, currentIndex);
				currentIndex ++;
			}else{
				this.storageService.setItem('calculedAreas', this.pageInfo.currentAreasCheckeds);
				alert('FINISHED');
				clearInterval(this.pageInfo.interval);
			}
			
		},1200)
	}

    infoRouteObtained($event){
		if($event === null){
			setTimeout(()=>{console.log('CRASHED', this.pageInfo.origin, this.pageInfo.destination), 4000});
			alert('Crashed');
			this.storageService.setItem('calculedAreas', this.pageInfo.currentAreasCheckeds);
			clearInterval(this.pageInfo.interval)
		}else{
			console.log($event);
			this.pageInfo.currentAreasCheckeds.push({
				origin		:	{name : this.pageInfo.origin.name, id : this.pageInfo.origin.id}, 
				destination	:	{name : this.pageInfo.destination.name, id : this.pageInfo.destination.id},
				data		:	{distance	:	$event.routes[0].legs[0].distance,
								 duration	:	$event.routes[0].legs[0].duration}
			});
					}
        console.log(this.zonesRealized);
    }

    modeCreateZone(){
        if(this.pageInfo.filters.some(el => el.selected.length >0)){
            this.restartOptions()
        }else{
            this.pageInfo.createZone    = this.pageInfo.createZone  ? !this.pageInfo.createZone     : true;
            this.pageInfo.editZone      = this.pageInfo.editZone    ? !this.pageInfo.editZone       : false;
        }
        this.restartItems();
        this.paintCreatedsAreas();    
        this.pageInfo.filters.map(el => el.selected = []);

        console.log(this.pageInfo.createZone, this.pageInfo.editZone);
        console.log('Zones Area Mallorca',this.areasmallorca.data);
    }
    paintCreatedsAreas(currentZone?){
        this.pageInfo.filters.forEach(zone =>{
            zone.items.forEach((area,index) =>{
                let findedArea = this.pageInfo.zones.find(el => el.name == area.value);
                if(findedArea){

                    let colorArea = (currentZone || {}).id == findedArea.id ? 'red' : this.mapService.getColor(index);
                    this.pageInfo.legendZones.push({name : findedArea.name, color : colorArea});
                    
                    findedArea.areas.forEach(el =>
                        {
                            this.areasmallorca.data.filter(subArea => subArea.name == el.name)
                                                    .map(areaFiltered => areaFiltered.color = colorArea);
                        })
                    }
                })
            })
    }

   
  
    
    restartItems(){
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = [];
        this.pageInfo.legendZones   = [];
        this.areasmallorca.data.map(el => el.color = "#6495ED");

    }

    restartOptions(){
        this.pageInfo.createZone    = false;
        this.pageInfo.editZone      = false;
        this.pageInfo.filters.map(el => el.selected = []);
        this.pageInfo.nameZone      = null;
        this.pageInfo.currentZones  = null;
        this.pageInfo.currentZoneId = null;
    }

    checkStatusName(nameZone){
        if(nameZone === null) return false;
        let areaFinded =  this.pageInfo.zones.find(el => el.name == nameZone);
        if(areaFinded){
            return areaFinded.id == this.pageInfo.currentZoneId;
        }else{
            return true;
        }
    }
    deleteLocaleZones(){
        this.storageService.deleteItem('zones');
        this.storageService.deleteItem('filtersZones');
        this.initMap();
        this.restartOptions();
        this.restartItems();
    }
    sortList(list){
        if(!list) return [];
        return list.sort((a,b) =>{ return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1});
    }
}