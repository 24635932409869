import { MapService 				} from './../mapservice';
import { CommonsService 			} from './../commons.service';
import { HttpClient               	} from '@angular/common/http';
import { Injectable               	} from '@angular/core';
import { AngularFirestore			} from '@angular/fire/firestore';
import { AngularFireAuth          	} from '@angular/fire/auth';
import { AngularFireStorage 		} from '@angular/fire/storage';

@Injectable()
export class DestinationsService {

	pageInfo	:	any = {
		mapsPath		:	'assets/maps/',
		airportsPath	:	'assets/airports/',
		portsPath		:	'assets/ports/',
		maxDistance		:	5000
	}
		
  	constructor(  
		private afs     	: AngularFirestore,
		public 	afStorage	: AngularFireStorage,
		private http 		: HttpClient,
		private mapService	: MapService,
    ){}

	//Refactor and unify to one
	async getJSONCountry(country)		{	return await this.http.get(this.pageInfo.mapsPath		+ country+'.json').toPromise() }
	
	async getJSONAirport(country)		{	return await this.http.get(this.pageInfo.airportsPath	+ country+'.json').toPromise()}
	
	async getJSONPort	(country)		{	return await this.http.get(this.pageInfo.portsPath		+ country+'.json').toPromise()}
    
	async getCountries()				{	
		let countries   =    await Promise.resolve(this.afs.collection('countries').ref.get());
		console.log(countries);
		return countries.docs.map(doc => {return {ref : doc.ref, ...doc.data()}})
	
	}
	async getAreasFromCountry(country)	{	
		let data	=	await Promise.resolve(this.afs.collection('countries').doc(country).collection('areas').ref.get());
		let areas	=	data.docs.map(doc => {return {ref : doc.ref, ...doc.data()}});
		areas		=	areas.filter((el : any) => el.properties);
		console.log(areas);
		return areas;
	
	}
	getComunitiesFromCountry(data)		{	return data?Array.from(new Set(data.map(info => info.properties.NAME_1))):[];	}								
	
	getProvincesFromDestinations(data)	{	return data?Array.from(new Set(data.map(info => info.properties.NAME_2))):[];	}
	
	getProvincesFromRegion(areas,region){	//1 get list provinces
											//2 associate areas to province
											//3 return them 
										}
	getInfoCommunity(data)				{	
		if(!data){ return []}
		const indirect			=	[]; // process to get all areas
		data.filter(data => data.geometry.coordinates == undefined).forEach(item => {
			item.geometry.geometries.forEach(geometry =>{
				indirect.push({properties : item.properties, coords : this.formatPolygons(geometry.coordinates[0])})
			})
		})
		let directMunicipies	=	data.filter(data => data.geometry.coordinates != undefined).map(el => { return { properties : el.properties, coords : this.formatPolygons(el.geometry.coordinates[0])}});
		
		let mergedMunicipies	=	[...indirect,...directMunicipies]; //merge the two ways where obtained areas
		mergedMunicipies.forEach(el => 
			{
				delete el.properties.stroke;
				delete el.properties['fill-opacity'];
				delete el.properties['stroke-opacity'];
				el.properties.name = el.properties.NAME_4
		});
		return mergedMunicipies;
	}

	formatPolygons(data)				{	
		if(!data){ return []};
		return data.map(el =>{ return {lat : el[1], lng : el[0]	}});
	}

	async addAreasToDestination(country,areas){
		if(!country || !areas){ return };
		await Promise.all(areas.map(async area => await Promise.resolve(this.afs.collection('countries').doc(country).collection('areas').add(area))));
	}

	async removeAreas(areas)			{	
		await Promise.all(areas.map(async area => await Promise.resolve(this.afs.doc(area.ref).delete())))	
	}

	async createDestination(country,data){
		if(!country || !data){ return;	}
		let destinationAdded;

		if(data.name){		
			destinationAdded = this.afs.collection('countries').doc(country).collection('destinations').doc(data.name);
			destinationAdded.set(data);
		} else {
			destinationAdded = this.afs.collection('countries').doc(country).collection('destinations').add(data);			
		}

		await Promise.resolve(destinationAdded.update({ 
			id : data.id, 
			ref: "/countries/"+country+"/destinations/"+data.id 
		}));
		
		// return {ref : destination.ref, ...destination.data()};
	}

	async getDestinationsFromCountry(country){
		if(!country){	return;	}
		let destinations	=	await Promise.resolve(this.afs.collection('countries').doc(country).collection('destinations').ref.get());
		return destinations.docs.map(doc => {return {ref : doc.ref, country : country, ...doc.data()}})
	}

	async getDestination(ref)			{	
		try {
			let dest = await this.afs.doc(ref).ref.get()
			return { success : true, data : dest.data()};
		} catch(e){	
			return { success : false }; 					
		}	
	}

	async getInfoDestinationFromDmc(dmcId, destinationId){
		let info = await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).ref.get());
		return info.data();
	}

	/**
	 * return areas from a destination from areas references
	 * @param listRef 
	 * @returns 
	 */
	async getAreasFromDestination(listRef){
		if(!listRef){ return; };
		let areas	=	await Promise.all(listRef.map(async ref => await this.afs.doc(ref).ref.get()))	
		return areas.map((el:any) => {return {...el.data(), ref : el.ref}});
	}

	async loadStationsFromAreas(country,areas){
		let airportData	:	any =	await this.getJSONAirport(country);
		let portData	:	any = 	await this.getJSONPort(country);
		
		let filteredAirports	=	airportData.filter(airport => areas.some(area => this.mapService.isPointInsidePolygon(area.coords, airport.location.lat, airport.location.lng)));
		let filteredPorts		=	Object.values(portData.ports).filter((port : any) => 
										areas.some(	area => this.mapService.isPointInsidePolygon(area.coords, port.lat, port.lon) 
															|| area.coords.some(coord => this.mapService.checkDistance(coord,{lat : port.lat, lng : port.lon}) < this.pageInfo.maxDistance )
													));
		return [{	type	:	'airport',	items	:	filteredAirports},
				{	type	:	'port',		items	:	filteredPorts.map((data : any ) => { return {...data, terminals : [],	type:'port', location : {lat : data.lat, lng : data.lon}}})}];
	}

	async getAssignedsDestinations(userInfo){
		let resellerId	=	userInfo['resellerId'];
		let userId		=	userInfo['userId'];
		let data		=	await Promise.resolve(this.afs.collection('resellers').doc(resellerId).collection('staff').doc(userId).ref.get());
		return data.data().destinationsAssigneds || []; 
	}
}