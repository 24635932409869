import { FirebaseService 			} from 'src/app/demo/service/database/firebase.service';
import { CommonsService             } from './../commons.service';
import { Injectable               	} from '@angular/core';
import { AngularFirestore,          } from '@angular/fire/firestore';
import { AngularFireStorage 		} from '@angular/fire/storage';

@Injectable()
export class AggregatorsService {

	pageInfo	:	any = {}
		
  	constructor(  
		private afs     	: AngularFirestore,
        private commons 	: CommonsService,
        private firebaseCtrl: FirebaseService,
		public 	afStorage	: AngularFireStorage,
    ){}

	async getProviders()									{	
		let providersCol 	= await Promise.resolve(this.afs.collection('providers').ref.get());
		let providers 		= providersCol.docs
								.map(provider => { 
									return { ref : provider.ref, ...provider.data() }}
								)
								.filter(item=>item["active"])
								.sort((a,b)=>a["id"]>b["id"]?1:-1)
								;
		return providers;
	} 

	getProvidersFromDmc(dmcId:string)						{	return this.afs.collection('dmcs').doc(dmcId).collection('aggregators').valueChanges();		}
	async getProviderAccountsFromDmc(dmcId : string)		{	let providers	=	await this.afs.collection('dmcs').doc(dmcId).collection('aggregators').ref.get();
																if(providers.docs.length == 0){ return []; }
																return await Promise.all(providers.docs.map(async provider => {
																	let accountsProvider	=	await this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(provider.id).collection('accounts').ref.get();
																	return {	provider	:	provider.data(),
																				accounts	:	accountsProvider.docs.map(account => account.data())
																			}
																}));
															}
	async getAggregatorsFromDestination(dmcId,destinationId){	let request = await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('aggregators').ref.get();
																return request.docs.map(item => { return { ref : item.ref, ...item.data()}}) || [];
															}	
	async addProviderToDmc(rowData,dmcId:string)			{	const newProvider = await Promise.resolve(this.afs.collection('dmcs').doc(dmcId.toString()).collection('providers').add(rowData));
																return await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('providers').doc(newProvider.id).update({id : newProvider.id}))
															}

	async updateProviderToDmc(rowData,dmcId:string)			{	return await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(rowData.id).update(rowData));}

	async removeProviderFromDmc(rowData,dmcId:string)		{	return await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(rowData.id).delete());}
	async deleteProviderFromDmc(dmcId, providerId,accountId){	return await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(providerId).collection('accounts').doc(accountId).delete())}
	
	async _addProviderToDmc(dmcId,providerInfo)				{
																try{
																	let dmcPath			=	this.afs.collection('dmcs').doc(dmcId).collection('aggregators');
																	await dmcPath.doc(String(providerInfo.id).toString()).set(providerInfo);
																	return { success : true, id : providerInfo.id};
																}catch(e){
																	return { success : false, id : null};
																}
		 
															}

	async addAccountToProviderDmc(dmcId,providerId, newAccount){
		try{
			let newAccountAdded	=	await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(String(providerId).toString()).collection('accounts').add(newAccount));
			await newAccountAdded.update({id : newAccountAdded.id});
			return { success : true, id : newAccountAdded.id};
		}catch(e){
			return { success : false, id : null}
		}
	}

	async getAccountToProviderDmc(dmcId,providerId,accountId){
		try{
			let response	=	await this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(String(providerId).toString()).collection('accounts').doc(accountId).ref.get();
			return {success : true, data : response.data()}
		}catch(e){
			return {success : false}
		}
	}

	async updateAccountToProviderDmc(dmcId, providerId, account){
		try{
			if(account['isNew']){delete account['isNew'];}
			await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('aggregators').doc(String(providerId).toString()).collection('accounts').doc(account.id).update(account));
			return { success : true }
		}catch(e){
			return { success : false}
		}
	}

	async removeAccountProviderDmc(dmcId,providerId,accountId){
		try{
			let dmcPath	=	this.afs.collection('dmcs').doc(dmcId).collection('aggregators');
			await dmcPath.doc(String(providerId).toString()).collection('accounts').doc(accountId).delete();	
			return {success : true};
		}catch(e){
			return {success : false}
		}
	}

	async toggleAccountToDestination(dmcId,destinationId,providerId,infoAccount){
		let docPath		=	this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('aggregators').doc(String(providerId).toString());
		let infoPath	=	await docPath.ref.get();
		console.log('ON TOGGLE DESTINATION TO AGGREGATOR',providerId,infoAccount);	
		if(infoPath.exists){
			let providersAccounts	=	infoPath.data().providersAccounts ||  [];
			let foundProvider		=	providersAccounts.findIndex(el => el.accountId == infoAccount.accountId);
			foundProvider == -1		?	providersAccounts.push(infoAccount)
									:	providersAccounts.splice(foundProvider,1);
			await docPath.update({providersAccounts : providersAccounts});	
		}else{
			await docPath.set({id : providerId, providersAccounts : [infoAccount]});
		}	
	}

	async removeAccountToDestination(dmcId,destinationId,providerId, accountId){
		let schedulersFromDestination	=	await this.getAggregatorsFromDestination(dmcId,destinationId);
		if(!Array.isArray(schedulersFromDestination)){ return; }
		schedulersFromDestination = schedulersFromDestination.filter((scheduler:any) => scheduler.accountId != accountId);
		// mirar el aggregatorId
		await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('aggregators').doc(String(providerId).toString()).update({providersAccounts : schedulersFromDestination});
	}

	async persistProviders(dmcId,providers){
		let destinationsFromDmc	=	await this.firebaseCtrl.getDestinationsFromDmc(dmcId);
		await Promise.all(providers.map(async provider => {
			let providerCreated	=	await this._addProviderToDmc(dmcId,provider.provider);
			await Promise.all(provider.accounts.map(async account =>{
				(account.destinations || []).forEach(destAccount =>{
					let findDest	=	destinationsFromDmc.find(destDmc => destDmc.name == destAccount.name);
					if(!findDest){ return; }
					destAccount.id = findDest.id;
				});
				let accountCreated = await this.addAccountToProviderDmc(dmcId,providerCreated.id,account);
				await Promise.all(account.destinations.map(async destination =>{
					await this.toggleAccountToDestination(dmcId,destination.id,provider.provider.providerId, {providerId : providerCreated.id, accountId : accountCreated.id});
				}));
			}));
		}));
	}
	// async updateProvidersToDmc(dmcId,refDest,infoProviders){
	// 	try{
	// 		let providers		=	infoProviders.providersDmc;
	// 		let accountsDest	=	infoProviders.accountsDestination;
	// 		let dmcPath			=	this.afs.collection('dmcs').doc(dmcId).collection('providers');
			
	// 		let newProviders	=	providers.filter(provider => provider.isNew); // add first the info from the news providers
	// 		await Promise.all(newProviders.map(async provider => {
	// 			let newProvider	=	await dmcPath.add(provider.provider);
	// 			await newProvider.update({ id : newProvider.id});
	// 			await Promise.all(provider.accounts.map(async account =>{ // by default all accounts will be new because the provider is new
	// 				let listProfilesFromAccount	=	accountsDest.filter(accountDest => accountDest.accountId == account.id);
	// 				delete account.isNew;
	// 				let newAccount 				=	await newProvider.collection('accounts').add(account);
	// 				account.id					=	newAccount.id;
	// 				await newAccount.update({id : newAccount.id}); // set and get the hash id
	// 				listProfilesFromAccount.forEach(account => account.accountId = newAccount.id);
	// 			}))
	// 		}));
	// 		let existProviders	=	providers.filter(provider => !provider.isNew);
	// 		await Promise.all(existProviders.map(async item =>{

	// 			let newAccounts	=	item.accounts.filter(account => account.isNew);
	// 			await Promise.all(newAccounts.map(async account =>{
	// 				let listProfilesFromAccount	=	accountsDest.filter(accountDest => accountDest.accountId == account.id);
	// 				delete account.isNew;
	// 				let newAccount 				=	await dmcPath.doc(item.provider.id).collection('accounts').add(account);
	// 				account.id					= 	newAccount.id;
	// 				await newAccount.update({id : newAccount.id}); // set and get the hash id
	// 				listProfilesFromAccount.forEach(account => account.accountId = newAccount.id);
	// 			}));

	// 			let existingAccounts	=	item.accounts.filter(account => !account.isNew);
	// 			await Promise.all(existingAccounts.map(async account =>{
	// 				console.log('account with updates',account);
	// 				await dmcPath.doc(item.provider.id).collection('accounts').doc(account.id).update(account);
	// 			}));
	// 		}))
	// 		await this.afs.doc(refDest).update({providersAccounts : accountsDest});
	// 		return { success : true}	

	// 	}catch(e){
	// 		console.error(e);
	// 		return { success : false}
	// 	}
	// }
	async addListPricesToAggregator(dmcId,destinationId,aggregatorId,listPrices){
		try{
			await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('aggregators').doc(String(aggregatorId).toString())
														.update({listPrices : listPrices});
		}catch(e){
			console.log('error en listprices');
			console.error(e);
		}														
	}

	async addConfigZonesToAggregator(dmcId,destinationId,aggregatorId,zones){
		try{
			await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).collection('aggregators').doc(String(aggregatorId).toString())
														.update({zones : zones});
		}catch(e){
			console.log('error en mapzonesaggregator');
			console.error(e);
		}														
	}

	async updateProviderPricing(refDest, infoProvider, listPrices){
		try{
			let docProvider		=	await this.afs.doc(refDest).collection('aggregators').doc(String(infoProvider.id).toString()).ref.get();
			docProvider.exists	?	await docProvider.ref.update({listPrices : listPrices})
								:	await docProvider.ref.set({id : infoProvider.id, listPrices : listPrices});
			return { success : true }
		}catch(e){
			console.log('updateProviderError',e);
			return { success : false, error : e}
		}
	}
	async getListPricesFromProvider(refDest,providerId){
		try{
			let docProvider				=	await this.afs.doc(refDest).collection('aggregators').doc(String(providerId).toString()).ref.get();
			return docProvider.exists	?	(docProvider.data().listPrices || [])	: [];	 
		}catch(e){
			return [];
		}
	}

}