export const filters = [
	{
		entity	: 'groups',
		name	: 'transfer',
		label	: 'Transfer',
		type	: 'multiple',
		field	: 'transfer',
		items	: [
			{ label: 'Shuttle', 		value: 'shuttle'	},
			{ label: 'Private', 		value: 'private' 	}
		],
		selected: [ "shuttle", "private" ]
	}
];