import { AuthFirebaseService } from './../../../service/database/authfirebase.service';
import { FirebaseService } from './../../../service/database/firebase.service';
import { StorageService } from './../../../service/storageservice';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import { EntityService } from '../../../service/entity.service';
import { CommonsService } from '../../../service/commons.service';
import { MessageService } from 'primeng/components/common/messageservice';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';


@Component({
    templateUrl: './staff.component.html',
    styles: [`
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }

        .upload + label {
            width: 50%;
            font-size: 1em;
            font-weight: 500;
            color: white;
            line-height: 2em;
            // text-transform: uppercase;
            text-align: center;
            background-color: #546E7A;
            display: inline-block;
            border-radius: 10px;
            box-shadow: 0px 3px 0px #3a4b53;
            transition: 150ms;
            margin-top:0.5rem;
        }
        .upload {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
        }
        .upload:focus + label,
        .upload + label:hover {
            background-color: #3a4b53;
            box-shadow: 0px 0px 0px;
            // box-shadow: 0px 5px 0px #3a4b53;
            cursor:pointer;
        }
        .upload:focus + label {
            outline: 1px dotted #000;
            outline: -webkit-focus-ring-color auto 5px;
        }
        .errorCol {
            background: rgba(255,0,0,0.1)!important;
            // color     : tomato !important;
        }
        .myTable {
            // height: 300px;
        }
        .myTableBody {
            // height: 500px;
        }
        .ui-toolbar {
            padding: 0em 1em !important;
        }
        .ui-dataview .filter-container {
            text-align: center;
        }
        .ui-button {
            margin: 0px 5px 0px 0px;
        }
        .ui-toolbar-group {
            padding: 0px 10px 0px 0px !important;
        }
        
        .ui-sidebar-right {
            width: 50rem;
        }
        
        .ui-autocomplete {
            display: inline-flex;
        }
        
        @keyframes ui-progress-spinner-color {
            // 100%,0% {	stroke: #d62d20;	}
            // 40% 	{	stroke: #0057e7;	}
            // 66% 	{	stroke: #008744;	}
            // 80%,90% {	stroke: #ffa700;	}
            0%,100% { 	stroke: #506D7A; 	}
        }
        
        .ui-table-scrollable-body {
            min-height	: 70vh;
            max-height	: 70vh;
            border		: 0px solid #D5D5D5;
            background	: whitesmoke;
        }
   
        .ui-tabview.ui-tabview-top, 
        body .ui-tabview.ui-tabview-bottom, 
        body .ui-tabview.ui-tabview-left, 
        body .ui-tabview.ui-tabview-right {
            width		: 100%;
            border		: 0px solid red;
        }
        // .ui-tree {
        //     width: initial;
        //     height: 470px;
        // }
    `],
    encapsulation: ViewEncapsulation.None,
    providers: [ MessageService,ConfirmationService ]
})

export class StaffComponent implements OnInit {
    
    @ViewChild('mainTable') mainTable : any;
	userInfo			: any =	{};
    staff				: any = { cols: [], filters: {} };
	destinations     	: any = {};
	profiles			: any = {};
    results				: any[];
    rowGroupMetadata	: any;
    pageInfo : any = {
        tempRpw             : null,
        tempTdSelected      : null,
        buttonEditRow       : false,
        buttonRemoveRow     : true,
        buttonCancelRow     : true,
           //***** VALIDATIONS ******/
           validations : {
            staff : [
                    {
                        field       : 'email',
                        validation  : this.isEmail,
                        msgError    : 'The email is badly formatter'
                    }
            ]
        }
    }
    menu: any = {
        options: [
            { label: 'reload',  icon: 'fa fa-plus' },
            { label: 'download', icon: 'fa fa-download' },
        ]
    };
    
     

    constructor(
        private http				: 	HttpClient,
        private storage 			:	StorageService,
        private commons				: 	CommonsService,
        private entityService		:	EntityService,
		private messageService		:	MessageService,
        private firebaseCtrl		:	FirebaseService,
        private authCtrl            :   AuthFirebaseService,
        private confirmationService	: 	ConfirmationService    ) {

        this.loadEntities();
    }

    async loadEntities() {
		// await this.load('destinations');    
		this.userInfo	=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
		await this.load('destinations');
		await this.load('profiles');
		await this.load('staff');
    }

    ngOnInit() {
        this.init();
    }

    async init() {

        this.staff.cols = [
			{ field: 'name',     	header: 'Name', 		width: '70px', 	align: "center",    editor : 'input', editable:true, options : {type : 'text'}, mandatory : true 	},
			{ field: 'surname',    	header: 'Surname', 		width: '100px', 	align: "center",    editor : 'input', editable:true, options : {type : 'text'}, mandatory : true 	},
			{ field: 'profile',		header: 'Profile',		  	width: '100px',		align: "center",    editor : 'multiselect', editable:true, options : {suggestions : 'profiles'},		mandatory : true 	},
			{ field: 'destination',	header: 'Destination',  	width: '100px',		align: "center",    editor : 'multiselect', editable:true,  options : {suggestions : 'destinations'},mandatory : true 	},
			// { field: 'address',	    header: 'Address',  	width: '200px', 	align: "center",    editor : 'input',  options : {type : 'text'},mandatory : false 	},
			{ field: 'phone',	    header: 'Phone',  		width: '100px', 	align: "center",    editor : 'input',editable:true,  options : {type : 'text'},mandatory : false		},
			{ field: 'email',	    header: 'Email',  	    width: '70px', 	align: "center",    editor : 'input',  editable:false,options : {type : 'text'},mandatory : true 	}            
        ];
		this.staff.form = [		
			{ field: 'name',     	label: 'Name', 		    width: '200px', 	align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},
            { field: 'surname',		label: 'Surname',  	    width: '100px',		align: "center",    editor : 'input',       editable:true,options : {type : 'text'},        mandatory : true 	},						
            { field: 'phone',	    label: 'Phone',  		width: '150px', 	align: "center",    editor : 'input',       editable : true,  options : {type : 'text'},    mandatory : false		},
            { field: 'email',	    label: 'Email',  	    width: '250px', 	align: "center",    editor : 'input',       editable : false,  options : {type : 'text'},   mandatory : true 	},
            { field: 'profile',		label: 'Profile',		align: "center",    editor : 'dropdown',    options : {suggestions : 'profiles'},		    mandatory : true 	},
            { field: 'destination',	label: 'Destination',  	align: "center",    editor : 'multiselect', options : {suggestions : 'destinations'},    mandatory : true 	}
        ];
        this.generateMenuCols('staff');
        
        this.staff.filters = [
			{ name: 'private', 	labelOn: 'Showing private', 	labelOff: 'No private', status: true },
			{ name: 'shared', 	labelOn: 'Showing shared', 	    labelOff: 'No shared', 	status: true }
        ];

        // this.loadEntities();

        // this.updateRowGroupMetaData();
    }

    generateMenuCols($entity) {
        this[$entity].selectedColumns = this[$entity].cols.filter(item => !item.disabled);
    }

    paginate($type, $event) {
        // First, rows, page, pageCount
        const length = !this[$type].data ? 0 : this[$type].data.length;
        this[$type].paginateInfo = $event || { first: 0, rows: 100, page: 1, pageCount: Math.ceil(length / 100) };
        this[$type].paginate = !this[$type].data
            ? []
            : this[$type].data.slice(
                this[$type].paginateInfo.first,
                this[$type].paginateInfo.first + this[$type].paginateInfo.rows
            );
    }

	doAction($type,$item)	{
		switch($type){
			case "toggleCountry"		:	( this.pageInfo.entities["countries"].data || [])
												.forEach(item=>{ item.openned = item.id==$item.id
																				?(undefined==$item.openned?true:!$item.openned)
																				:false 
												});											
											break;
			case "toggleStation"		:	$item.openned = undefined==$item.openned?true:!$item.openned;				break;
			case "toggleMeetingPoint"	:	$item.openned = undefined==$item.openned?true:!$item.openned;				break;						
		}
	}

    getFilteredEntity($entity,$info=undefined){
		switch($entity){
			case "stations"			: 	return (this.pageInfo.destination.tableStations.data		|| []).filter(item=>{return true;});
			case "meetingPoints"	: 	return (this.pageInfo.destination.tableMeetingPoints.data	|| []).filter(item=>{return true;});
			
			case 'formStations'		:	return 	{ 	
													info	: $info, 
													entities: this.pageInfo["destination"].tableStations.entities,
													form	: this.pageInfo["destination"].tableStations.form 
												};
			case 'formMeetingPoints':	return 	{ 	
													info	: $info, 
													entities: this.pageInfo["destination"].tableMeetingPoints.entities,
													form	: this.pageInfo["destination"].tableMeetingPoints.form 
												};
			case "countries"		:	return this.pageInfo.entities[$entity].data;
			case "destinations"		:	let country = this.pageInfo.entities['countries'].data.find(item=>item.openned);
										return undefined==country? [] : this.pageInfo.entities[$entity].data.filter(item=>item.country==country.name);
		}
    }
    
    async load($entity) {
        switch ($entity) {
            
            case 'staff':
				this[$entity].data  = [];				
                // this[$entity].data	= this.entityService.get($entity);
                this.firebaseCtrl.getStaffFromDmc(this.userInfo.dmcId || '1').subscribe(users => {
                    this[$entity].data  = [];				
                    users.forEach(user =>{
                        this[$entity].data.push(user);
                    });
                    this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
                    this.paginate($entity, null);
                    console.log('MY USERS',this[$entity].data);
                    // this[$entity].data  =   
                })
				// console.log(this[$entity].data);
				// let user	=	[{
				// 	type 	:	'staff',
				// 	profile	:	['admin'],
				// 	email	:	'gloria@tmt.com',
				// 	id		:	1,
				// 	destination :	[{id:'1',name:'Mallorca'}],
				// 	phone	:	646385598,
				// 	name	:	'Gloria',
				// 	surname :	'Barceló'	
				// }];
				// this[$entity].data	= user;
            break;
            
			case 'destinations':	
    			this[$entity].data	= await this.firebaseCtrl.getDestinationsFromDmc(this.userInfo.dmcId || '1');
    			this[$entity].data	= this[$entity].data.map(el => {return {label : el.name, value : {id :el.id, name : el.name}}});
                this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
                this.paginate($entity, null);
    			console.log('DESTINATIONS DMC',this[$entity].data)
    			break;
			case 'profiles':
				this[$entity].data	= await this.firebaseCtrl.getProfiles({types : ['admin']});
	            this[$entity].data	= this[$entity].data.map(el => {return {label : el.type, value : el.type}});
	            this[$entity].count = this[$entity].data ? this[$entity].data.length : 0;
	            this.paginate($entity, null);
				console.log('PROFILES', this[$entity].data );
				break;
        }
        // this[$entity].data	= this[$entity].data.map((item,index)=>{ item.id = index; return item });

    }

    getEditor($col) {
        return $col.editor ? $col.editor : 'input';
    }

    search(field,event) {
        switch(field){
            case 'location' : this.results  = this.destinations.data.filter(item => item.includes(event.query)); break;            
            case 'brand'    : this.results  = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;            
            default         : this.results  = this.commons.getEntity('areasList').filter(item => item.includes(event.query)); break;
         }
	}
	
	getSuggestions($entity){
		return this[$entity].data;
	}

    checkEntity($entity, $value) {
        // alert('CHECKENTITY[' + $entity + ']->' + JSON.stringify(this.commons.getEntity($entity)));
        // return this.commons.getEntity($entity).some(item => item === $value);
        return true;
    }

    areaEditor($me, $type, $col, $items) {
        alert('AREA EDITOR');
	}


    getRendererType($type) {
        switch ($type) {
			case 'adapted':
				return (type, col, items) => this.adaptedRenderer(this, type, col, items);
        }
    }

    getRenderer($type, $col, $items) {
        return $col.renderer
                    // ? $col.renderer($type, $col, $items)
                    ? this.getRendererType($col.renderer)($type, $col, $items)
                    : this.defaultRenderer($type, $col, $items);
    }

    defaultRenderer($type, $col, $items) {
        switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return this.defaultStyleRenderer($col);
			case 'content'	: switch($col.field) {
                                                case 'profile'          : return $items[$col.field];
                                                case 'destination'	    : return Array.isArray($items[$col.field]) ? $items[$col.field].map(el => el.name) : $items[$col.field];
												default 		 		: return $items[$col.field]}
        }
    }

    defaultStyleRenderer($col) {
        return {
            'width'			: $col.width ? $col.width : '',
            'text-align'	: $col.align ? $col.align : ''
        };
    }

    adaptedRenderer($me, $type, $col, $items) {
		switch ($type) {
            case 'header'	: return this.defaultStyleRenderer($col);
            case 'style'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'text-align'	: $col.align         ? $col.align   : '',
				'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'expanded'	: return {
				'width'			: $col.width         ? $col.width   : '',
            	'color'			: $items[$col.field] ? 'green'      : 'red',
				'fontWeight'	: '900',
				'fontSize'		: '1rem'
            };
            case 'content'	: return $items[$col.field]?'V':'X';
        }
    }

    toCamelCase($value) {
        return $value.substr(0, 1).toUpperCase() + $value.substr(1, $value.length).toLowerCase();
    }

    floatRenderer($value, $precision = 2) {
        return parseFloat($value).toFixed($precision);
    }

    onSort() {
        // this.updateRowGroupMetaData();
    }

    makeColEditable($entity,value,rowData){
        try{
            this[$entity].cols.map(el => el.editable = value);
            this.pageInfo.tempRow = JSON.stringify(rowData);
            this.pageInfo.buttonEditRow = value ? true : false;
        }catch(e){
            this.pageInfo.tempRow = null;
        }
        
    }
    
	async addNewUser($event){
		let newId = this.commons.generateHash(Math.random().toString(36).replace(/[^a-z]+/g, '') );
		this[$event].data.unshift({id: newId, isNew : true});
		this.paginate($event,null);
		this.mainTable["expandedRowKeys"][newId] = 1;
		this.makeColEditable($event,true,this[$event].data.find(el=> el.id == newId));

		await this.commons.generateToast('_ELEMENT_CREATED','_FILL_INFO','info');
	}

    cancelEdit($entity){
        try{
            this[$entity].data[this[$entity].data
                                            .findIndex(el => el.id == JSON.parse(this.pageInfo.tempRow).id)] = JSON.parse(this.pageInfo.tempRow);
            
            this.paginate       ($entity,null);
            this.makeColEditable($entity,false,null)
            
            this.commons.generateToast('Canceled','Edit canceled','info',2);
        }catch(e){
            this.commons.generateToast('ERROR',e,'error',1);
            console.error('ERROR. Row not found', e);
        }
    }

    async saveRow($entity,rowData){
        // try{
        //     // let validation = this.validateInfo($entity,rowData);
        //     let validation = this.validateInfo($entity,rowData);
        //     if(validation.success){
        //         //****** UPDATE/CREATE ROW *********** API PUT / POST rowData
        //         let newUser   =   await this.firebaseCtrl.checkExistUserDmc(rowData,this.userInfo.dmcId);
        //         if(newUser){ 
        //             let createdUser =   await this.authCtrl.signIn(rowData.email,'newuser123');
        //             if(createdUser){
        //                 if(rowData.isNew){ delete rowData.isNew}
        //                 let success = await this.firebaseCtrl.addUserToStaffDmc(rowData,createdUser,this.userInfo.dmcId);        
        //                 this.makeColEditable($entity,false,rowData);
        //                 await this.commons.generateToast('_SUCCESS','_ELEMENT_CREATED','success',2);
        //                 this.mainTable.expandedRowKeys = {};
        //                 await this.authCtrl.logOut();
        //             }else{
        //                 this.commons.generateToast('Error', 'Could not create. Contact the administrator.','error');
        //             }
        //         }else{
        //             const fileImg   = rowData.fileImg;
        //             delete rowData.fileImg;
        //             let updatedUser = await this.firebaseCtrl.updateUserStaffDmc(rowData, this.userInfo.dmcId);
        //             if(updatedUser){
        //                 await this.commons.generateToast('_SUCCESS','_ELEMENT_UPDATED','success',2);
        //             }else{
        //                 await this.commons.generateToast('_ERROR', '_USER_ALREADY_EXISTS','error',2);
        //             }
        //         }
        //         // this.paginate($entity,null);
		// 	}
		// 	else	{	await this.commons.generateToast('ERROR','_ERROR','error',2);                }  
        // }
        // catch(e)	{	console.error(e); await this.commons.generateToast('ERROR','_ERROR '+e,'error',2);    }
    }

    async removeRow($entity,rowData){
        this.confirmationService.confirm({
            message: 	await this.commons.getTranslate('_MESSGE_CONFIRM_REMOVE_USER'),
            header: 	await this.commons.getTranslate('_REMOVE_USER'),
            accept: async () => { 
                let findedRow = this[$entity].data.findIndex(el => el.id == rowData.id);
                if(findedRow > -1)	{	await this.firebaseCtrl.removeUserToStaffDmc(rowData,this.userInfo.dmcId || '1');
										await this.commons.generateToast('_SUCCESS','_ELEMENT_REMOVED','success',2);
									}
				else				{	this.commons.generateToast('Error','Could not remove the item','error',2); }
                this.pageInfo.buttonEditRow = false;                
            }
        });
    }

    async ressetPassword(rowData){
        if(!rowData.email) return await this.commons.generateToast('_ERROR', '_NO_EMAIL_FOUND', 'error');
        const resset = await this.authCtrl.recoverPassword(rowData.email);
        if(resset.success){ await this.commons.generateToast('_SUCCESS','_MESSAGE_RESSET_PASSWORD','success');	}
    }
    checkIfDisableInput(rowData, field){

        if(field == 'email' && rowData.isNew) return true; 

        // const staff =   [].concat.apply([],this.staff.form.map(el => el.items.map(data => data)));

        const staff =   [].concat.apply([],this.staff.form.map(data => data));
        return staff.find(el => el.field == field).editable;

    }
    validateInfo($entity,rowData){
        let specificValidations =  this.pageInfo.validations[$entity]   ? this.pageInfo.validations[$entity].find(el => !el['validation'](this[$entity].data,rowData))
                                                                        : null;

        if( specificValidations ){
            return { success: false, msg: specificValidations.msgError	}
        }else{
            let mandatoryFields = this[$entity].cols.filter(el => el.mandatory)
                                                    .filter(mandatoryElement =>     (rowData[mandatoryElement.field] === undefined)
                                                                                ||  (rowData[mandatoryElement.field] === null) 
                                                                                ||  (rowData[mandatoryElement.field] === '') );
            if( mandatoryFields.length > 0){
                return 	{	success : false,
	                        msg     : mandatoryFields.length == 1   ? 'Field '+ mandatoryFields[0].field +' is mandatory'
	                                                                : 'Fields '+ mandatoryFields.map(el => el.field) +' are mandatory'
	                        }
            } else {
                return {	success :true	}
            }
        }
    }
    
    confirmRemoveRow($entity,rowData)     	{   this.dialogRemoveRow($entity,rowData);        			}
    dialogRemoveRow($entity,rowData)		{	this.confirmationService.confirm({
										            message: 'Are you sure that you want to proceed?',
										            header: 'Confirmation',
										            icon: 'pi pi-exclamation-triangle',
										            accept: () => {
										                this.removeRow($entity,rowData);
										            },
										            reject: () => {
										            }
										        });
										    }
    saveCurrentValueOnTd(element)			{	if(!element){ return; }
										        this.pageInfo.tempTdSelected = JSON.parse(JSON.stringify(element));
    										}

    async updateRow($entity,event,rowData, element,isBlur?){
        // || (event.code == 'Enter' || event.code == 'Tab')
        if((event.keyCode == 9 || event.keyCode == 13  || isBlur)  ){
            if(!(rowData[element] == this.pageInfo.tempTdSelected)){
                let validation = this.validateInfo($entity,rowData);
                if(validation.success)	{	await this.saveRow($entity,rowData);
						                    this.pageInfo.tempTdSelected = null;
										}
				else					{	this.commons.generateToast('ERROR',validation.msg,'error',2);  
						                    rowData[element] = this.pageInfo.tempTdSelected;              
						                }
			}
			else						{	rowData[element] = this.pageInfo.tempTdSelected;
							                this.pageInfo.tempTdSelected = null;							
            }
		}
		else if(event.keyCode == 27)	{	rowData[element] = this.pageInfo.tempTdSelected;	}
    }

    checkField(field)						{	console.log(field);															}
    uniqueCode(data,rowData)				{	if(!rowData['code']) return false;
										        console.log(this)
										        return data.some(el => el.code == rowData.code);
										    }
    isEmail(items, dataRow : any)			{	return (dataRow.email.includes("@")) && (dataRow.email.includes("."))     	}

    getImgUser(rowData)						{	return rowData.avatar || 'http://vyshnevyi-partners.com/wp-content/uploads/2016/12/no-avatar-300x300.png';	}

    checkFile(event,rowData) 				{
		if (event.target.files && event.target.files[0]) {
            const file 		= event.target.files[0];
            // rowData.fileImg = file;
			const reader 	= new FileReader();
			reader.onload 	= e => rowData.avatar = reader.result;
            reader.readAsDataURL(file);
		}
    }
    
    async handleOption($event)				{	console.log('On handle option staff');
										        console.log($event);
										        switch($event.type){
										            case 'save'             :   await this.saveRow($event.entity, $event.rowData);  break;
										            case 'delete'           :   await this.removeRow($event.entity,$event.rowData); break;
										            case 'ressetPassword'   :   await this.ressetPassword($event.rowData);          break;
										            default                 :   await this.commons.generateToast('_ERROR', '_COMMAND_UNDEFINED','error');
										        }
										    }
}
