import { TourWizardI18nComponent } from './../../wizard/steps/i18n/component';
import { EntityService } from './../../../../demo/service/entity.service';
import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { wizardItems 		} from './data/items';

@Component({
  selector    	: 'app-sight-wizard',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class SightWizardComponent {

	@Input("type")      type:string;
	@Input("info")		info:any		= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService
	){
		this.init();
	}

	init()							{}
	async loadEntities()			{ 	await this.load("langs");
										await this.load("sightTypes"); 
									}
	async ngOnInit()				{

		this.pageInfo.step					= 1;		
		this.pageInfo.items					= wizardItems;		
		this.pageInfo.wizardInfo			= { ready : false };
		
		await this.loadEntities();

		this.pageInfo.wizardInfo.ready		= true;
		
		this.pageInfo.wizardInfo.active		= true;			// Default

		// TO FIX !! 
		// Type to fullfill and area after select position
		this.pageInfo.wizardInfo.idtype		= 21;			// Default
		// this.pageInfo.wizardInfo.area	= "Palma";		// Default
		this.pageInfo.wizardInfo.idarea		= 1;			// Default

		this.pageInfo.wizardInfo.address	= "";			// Default
									
		this.pageInfo.wizardInfo.forms		= {};

		this.pageInfo.wizardInfo.entities	= {						
			langList			: this.pageInfo.wizardInfo.langs,
			sightTypes			: this.pageInfo.wizardInfo.sightTypes,
			infoAdditionalList	: [ 
				{ id: 1, label: '_CHILDREN_TICKET' 		},
				{ id: 2, label: '_DRINK_AND_FOOD'		},
				{ id: 3, label: '_DIFICULTIES'			},
				{ id: 4, label: '_CLAUSURE'				}
			]			
		};

		// I18N
		this.pageInfo.wizardInfo.i18n 		= {};
		this.pageInfo.wizardInfo.forms.i18n = [
			{
				title		: "_GENERIC_INFO",
				showTitle	: true,
				icon		: "fa fa-bars",
				padding		: "0.25rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'name',			label:'_NAME',						type: 'text', 	editor: 'text',			info: true 						},
					{ mandatory: true, 	field	: 'type',			label:'_TYPE',						type: 'text', 	editor: 'autocomplete',	entityList: 'sightTypes'		}
				]
			},
			{
				title		: "_I18N_INFO",
				showTitle	: true,
				i18n		: true,
				icon		: "fa fa-language",
				padding		: "1rem 1rem 0.5rem 3rem",
				items		: [
					{ mandatory: true, 	field	: 'title',			label:'_TITLE',						type: 'text', 	editor: 'text',	info: true 						},
					{ mandatory: true, 	field	: 'tags', 			label:'_TAGS',						text: 'text',	editor: 'chip', info: true 						},
					{ mandatory: true, 	field	: 'short_desc',		label:'_DESC_SHORT',				type: 'text', 	editor: 'text' 									},
					{ mandatory: true, 	field	: 'long_desc', 		label:'_DESC_LONG',					type: 'text', 	editor: 'area', height: '200px'					}					
				]
			}			
		];
		
		// SIGHT
		this.pageInfo.wizardInfo.sight		= { 
			map			: {
				zoom		: 10, 
				lat			: 39.639938, 
				lng			: 2.894679				
			},marker	: {}
		};

		this.pageInfo.wizardInfo.resources	= {
			cols		: 5
		}

		// console.log("INFO",this.pageInfo.wizardInfo);
	}  	

	onUpload(event) {
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}
	
	async action($type,$item){		
		switch($type){
			case "close"	: 	this.emitter.emit({ 'action': 'close' });		break;
			case "set"		: 	this.pageInfo.step = $item.id;					break;
			case "next"		: 	this.pageInfo.step++;		 					break;
			case "back"		: 	this.pageInfo.step-= this.pageInfo.step>1?1:0; 	break;
			case "init"		: 	this.pageInfo.step=0; 							break;
			case "finish"	: 	await this.persistSight(this.pageInfo.wizardInfo);
								this.emitter.emit({ 'action': 'finish'}); 		
								break;
		}
	}

	/**
	 * Persist Sight
	 * @param $item
	 */
	async persistSight($item){
		// console.log("Persist Booking",$item);
		this.pageInfo.spinner 	= true;
		const vars = [ 	"id", 
						"active", 
						"name", 
						"idtype",
						"type", 
						"area",
						"idarea", 
						"address", 
						"i18n", 
						"pictures", 
						"latitude", 
						"longitude" 
					];
		await this.entityService.postJSON(
			this.entityService.getUrl('sight_persist'),
			{
				type	:	"multiple",
				records	:	[ vars.reduce((o,item)=>{ o[item]=$item[item]; return o; },{ }) ]
			}
		).then(response=>{
			this.pageInfo.spinner = false;
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
				return false;
			}
			this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");			
			return true;
		}).catch(response=>{
			this.pageInfo.spinner = false;
			this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
			console.log("RESPONSE",response);				
		});
	}

	async load($entity){
		let response;
		switch($entity){
			case 'langs'		:	response 							= 	await Promise.resolve(this.entityService.getRequest("langs",));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.wizardInfo.langs		= 	response["data"];
									break;
			case 'sightTypes' 	:	response 							= 	await Promise.resolve(this.entityService.getRequest("sightTypes",{}));
									if(response["success"]!=true){
										this.commons.generateToast("_ERROR","_REQUEST_ERROR","error");
										return false;
									}
									this.pageInfo.wizardInfo.sightTypes = response["data"];
									break;
		}
	}
}
