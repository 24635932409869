export let driversCols = [
    {
        field		: 'id',
        header		: 'Id',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'active',
        header		: 'Active',
        width		: '50px',
        disabled	: false,
        editable	: false,
        align		: 'center'
	},
	{
        field		: 'name',
        header		: 'Name',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
    {
        field		: 'location',
        header		: 'License',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    },
	{
        field		: 'address',
        header		: 'Address',
        width		: '60px',
        disabled	: false,
        editable	: false,
        align		: 'center'
	},
	{
        field		: 'idcard',
        header		: 'Idcard',
        width		: '100px',
        disabled	: false,
        editable	: false,
        align		: 'center'
	},
	{
        field		: 'phone',
        header		: 'Phone',
        width		: '100px',
        disabled	: false,
        editable	: false,
        align		: 'center'
	},
	{
        field		: 'email',
        header		: 'Email',
        width		: '120px',
        disabled	: false,
        editable	: false,
        align		: 'center'
    }
];
