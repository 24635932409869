import { Component,
         Input,
         Output,
         EventEmitter,
		 ViewEncapsulation	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { EntityService 		} from 'src/app/demo/service/entity.service';
import { sightsData1 		} from './data/sights';
import { buttons 			} from './data/buttons';
import { filters 			} from './data/filters';

@Component({
  selector    		: 'app-sight',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'
})
export class SightComponent {

  	@Input("type")      type:string;
	@Output()			emitter			= new EventEmitter<any>();

	pageInfo  	: any = {};

	constructor(
		private commons			: CommonsService,
		private entityService	: EntityService
	){
		this.init();
	}

	init()			{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.entities		= { "sights" : [], "areas": [] };
		this.pageInfo.areas			= [];
		this.pageInfo.searchArea	= "";
		this.pageInfo.tabs			= {
			items 			: [
				{ id:0, label: this.commons.getTranslate('_LIST'),	icon : 'fa fa-folder-o'		, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:1, label: this.commons.getTranslate('_MAP'	), 	icon : 'fa fa-map-marker'	, command: ($event)=> { this.setTabItem($event)} 	}				
			],
			selected		: 0
		};
		this.loadEntities();
	}
	loadEntities()	{ this.load("sights"); 					}
	ngOnInit()		{ 
		this.pageInfo.map		= { zoom: 10, lat: 39.639938, lng: 2.894679 };		
		this.pageInfo.sights	= this.pageInfo.sights || [];
	}

	setTabItem($event){	this.pageInfo.tabs.selected = $event.item.id;	}

    onUpload(event) {
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}

	getMarkers($type){
		switch($type){
			case "sights"		: 	return this.pageInfo.entities.sights.filter(item=>item.view);
			case "selected"		:	return this.pageInfo.sights;
		}
	}

	getLocationParsed($item){
		return ( undefined==$item["latitude"] || undefined==$item["longitude"] )
				? ""
				: parseFloat($item["latitude"]).toFixed(4) + " , " + parseFloat($item["longitude"]).toFixed(4);	
	}

	action($type,$item?){
		switch($type){
			case "button"		:	switch($item.name){
										case "new_sight"	: this.emitter.emit({ action: 'new_sight' 	}); 				break;
										case "reload"		: this.load("sights");											break;
										case "export"		: this.commons.generateToast("Button pressed","Export","info");	break;
										case "import"		: this.commons.generateToast("Button pressed","Import","info"); break;
									}
									break;
			case "view_sight"	:	this.emitter.emit({ action: 'view_sight', item: $item 	});	break;
			case "toggleArea"	: 	let isSelected 					= this.pageInfo.areas.some(item=>item.name==$item.name);
									if(isSelected) {
										$item.selected 				= false;
										$item.color					= "red";
										this.pageInfo.areas			= this.pageInfo.areas.filter(item=>item.name!=$item.name);
									} else {
										$item.selected 				= true;
										$item.color					= "green";
										this.pageInfo.areas			= [ ...this.pageInfo.areas, $item ];
									}
									break;
			case "toggleViewAll":	this.pageInfo.sightViewAll 		= undefined==this.pageInfo.sightViewAll?true:!this.pageInfo.sightViewAll;
									this.pageInfo.entities.areas.forEach(item=>{ item.selected = this.pageInfo.sightViewAll; });
									this.pageInfo.areas				= this.pageInfo.entities.areas.filter(item=>item.selected);
									break;
			case "deleteSight"	:	this.deleteSight($item); break;
		}
	}

	async deleteSight($item){
		let response = await Promise.resolve(this.entityService.deleteById("sightId",{ id: $item.id },{}));
		if(response["success"]!=true){
			this.commons.generateToast("_ERROR","_ERROR_DELETING","error");
			return false;
		}
		this.pageInfo.entities["sights"] = this.pageInfo.entities["sights"].filter(item=>item.id!=$item.id);
	}

	getFilteredEntity($entity){
		switch($entity){
			case "sights"		: return this.pageInfo.entities[$entity].filter(item=>{
																		let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
																		item.view 		= isSelected;
																		item.color		= "red";
																		return isSelected;
																	});
			case "areas"		: return this.pageInfo.entities[$entity].filter(item=>{
																		return (undefined==this.pageInfo.searchArea || this.pageInfo.searchArea=="")
																		 		?true
																		 		:item.name.toLowerCase().includes(this.pageInfo.searchArea.toLowerCase());
																	});
		}
	}

	async load($entity){
		switch($entity){
			case 'sights' :
					let response 					= await Promise.resolve(this.entityService.getRequest("sights"));
					this.pageInfo.entities[$entity]	= response["data"].map((item,index)=>({ 
																							id			: item["id"],
																							code		: item["code"],
																							type		: item["type"],
																							name		: item["name"],
																							title		: item["name"],
																							langs		: item["langs"],
																							area		: item["area"],
																							latitude	: item["latitude"],
																							longitude	: item["longitude"],
																							pictures	: item["pictures"] 	|| 0,
																							videos		: item["videos"]	|| 0,
																							audios		: item["audios"]	|| 0,
																							thumbnail	: item["thumbnail"]	|| "/assets/layout/images/sights/no_sight.jpg"
																						}))
																		.map(item=>{								// Capitalize 1st letter
																				item.title = item.title.charAt(0).toUpperCase()+item.title.slice(1);
																				return item;
																		})
																		.sort((a,b)=>(a.title>b.title?1:-1))		// Sort by title
																		.map(item=>{								// Assign icon
																			switch(item.type){
																				case "_EVENT"	: item["icon"] = "play"; 	break;
																				case "_TICKET"	: item["icon"] = "ticket"; 	break;
																				case "_STAFF"	: item["icon"] = "user"; 	break;
																				default			: item["icon"] = "check";	break;
																			}
																			return item;
																		});
					this.pageInfo.entities["areas"]	= this.pageInfo.entities[$entity].reduce((a,item)=>a.some(aItem=>item.area==aItem.name)?a:[...a,{ name: item.area, selected: true }],[]);
					this.action('toggleViewAll');
					// console.log("ENTITY "+$entity,this.pageInfo.entities[$entity]);
					// console.log("AREAS 	",this.pageInfo.entities["areas"]);
					break;
		}
	}
}
