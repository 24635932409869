export const tabs 	= {
	bookings	: 	{
		items	: [ 
			{ name: 'bookings_grid', 		label: '_BOOKINGS_GRID', 		icon: 'file'	 				},											
			// { name: 'lodgings', 			label: '_LODGINGS', 			icon: 'home'	 				}
		], 
		cols		: 2,
		selected	: 'bookings_grid'
	},

	rowexpander	: 	{
		items	: [ 
			{ name: 'arrival', 				label: '_BOOKING_ARRIVAL', 		icon: 'file'	 				},											
			{ name: 'departure', 			label: '_BOOKING_DEPARTURE', 	icon: 'file'	 				},											
			// { name: 'flight', 				label: '_FLIGHT_INFO', 			icon: 'plane'	 				},														
			// { name: 'lodging', 				label: '_LODGING_INFO', 		icon: 'home'	 				},
		], 
		cols		: 2
	},

    check_bookings_left	: 	{
		items	: [ 
			{ name: 'bookings_grid', 		label: '_BOOKINGS_GRID', 		icon: 'bus'	 					},											
		], 
		cols		: 2,
		selected	: 'bookings_grid'
	},

    check_bookings_right	: 	{
		items	: [ 
			{ name: 'bookings_grid', 		label: '_BOOKINGS_GRID', 		icon: 'bus'	 					},											
			{ name: 'services_groups', 		label: '_SERVICES_GROUPS', 		icon: 'bus'	 					},
		], 
		cols		: 2,
		selected	: 'bookings_grid'
	},

	check_bookings	: 	{
		items	: [ 
			{ name: 'bookings_imported', 		label: '_BOOKINGS_IMPORTED', 	icon: 'bus'	 				},		
			{ name: 'bookings_current', 		label: '_BOOKINGS_CURRENT', 	icon: 'bus'	 				}
		], 
		cols		: 2,
		selected	: 'bookings_imported'
	}
}