import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { detailItems, entities, expendituresFakeData 	} from './data/items';

@Component({
  selector    		: 'app-company-expenditures',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class CompanyExpendituresComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		views			: { items: [] },
		entities		: { expenditures: {} }		
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService	
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= [
			{ 	name: 'list',		label: '_LIST', 	icon: 'fa fa-list', 		type: 'button'	},
			{ 	name: 'invoice',	label: '_INVOICE',	icon: 'fa fa-file-text-o', 	type: 'button', disabled: true	},
			{ 	name: 'stats',		label: '_STATS',	icon: 'fa fa-line-chart',	type: 'button', disabled: true	}
		];
		this.pageInfo.status		= "list";		
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };
				
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.entities["expenditures"].table	= {
			cols	: [				
				{	field: 'id',						header		: '_ID',				width		:  '50px',	align: 'center', disabled	: true			},
				{	field: 'status',					header		: '_STATUS',			width		: '100px',	align: 'center', disabled	: false			},   
				{	field: 'driver',					header		: '_DRIVER',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'vehicle_type',				header		: '_VEHICLE_TYPE',		width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'vehicle',					header		: '_VEHICLE',			width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'provider',					header		: '_PROVIDER',			width		: '100px',	align: 'center', disabled	: true			},
				{	field: 'type',						header		: '_TYPE',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'subtype',					header		: '_SUBTYPE',			width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'date',						header		: '_DATE',				width		: '150px',	align: 'center', disabled	: false			},
				{	field: 'cost',						header		: '_COST',				width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'payment_method',			header		: '_PAYMENT_METHOD',	width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'payment_date',				header		: '_PAYMENT_DATE',		width		: '100px',	align: 'center', disabled	: false			},
				{	field: 'note',						header		: '_NOTE',				width		: '150px',	align: 'center', disabled	: true			},				
			],			
			options	: { showCalendar: true, rowExpander: true, scrollHeight: '55vh' },
			buttons	: [
				{ label: '_RELOAD', icon: 'refresh'	}
			],
			filters	: [
				{
					entity	: 'expenditures',
					name	: 'status',
					label	: '_STATUS',
					type	: 'multiple',
					field	: 'status',
					icon	: 'share-alt',
					items	: [
						{ label: '_PENDING',			value: 'pending' 			},
						{ label: '_EDIT_PENDING',		value: 'edit_pending'		},
						{ label: '_REVIEWING',			value: 'reviewing' 			},
						{ label: '_EDITED',				value: 'edited'				},
						{ label: '_ACCEPTED',			value: 'accepted'			},
						{ label: '_PAYMENT_PENDING',	value: 'payment_pending'	},
						{ label: '_PAYED',				value: 'payed'				}
					],
					selected: [ 'pending', 'edit_pending', 'reviewing', 'edited', 'accepted', 'payment_pending', 'payed' ]
				}				
			],
			form	: {
				showEmptyTitle	: false,
				items			: [
					{						
						_title	: "_INFO",
						items	: [
							{	field: 'id',						label		: '_ID',						editable    : false,    type: 'text'																},
							{	field: 'status',					label		: '_STATUS',					editable    : true,     type: 'text',	editor: 'autocomplete', entityList: 'statusList'			},
							{	field: 'driver',					label		: '_DRIVER',					editable    : true,     type: 'text'																},
							{	field: 'vehicle_type',				label		: '_VEHICLE_TYPE',				editable    : true,     type: 'text'																},
							{	field: 'vehicle',					label		: '_VEHICLE',					editable    : true,     type: 'text'																},
							{	field: 'provider',					label		: '_PROVIDER',					editable    : true,     type: 'text'																},
							{	field: 'type',						label		: '_TYPE',						editable    : true,     type: 'text',	editor: 'autocomplete', entityList: 'typeList'				},
							{	field: 'subtype',					label		: '_SUBTYPE',					editable    : true,     type: 'text',	editor: 'autocomplete', entityList: 'subtypeList'			},
							{	field: 'date',						label		: '_DATE',						editable    : true,     type: 'text'																},
							{	field: 'cost',						label		: '_COST',						editable    : true,     type: 'text'																},
							{	field: 'payment_method',			label		: '_PAYMENT_METHOD',			editable    : true,     type: 'text',	editor: 'autocomplete', entityList: 'paymentMethodList'		},
							{	field: 'payment_date',				label		: '_PAYMENT_DATE',				editable    : true,     type: 'text'																}
						]							
					}
					,{
						_title	: "_NOTES",
						items	: [
							{	field: 'note',						label		: '_NOTE',						editable    : true,     type: 'textarea', height:'325px' 	}

						]
					}
					,{
						_title	: "_TICKET",
						items	: [
							{	field: 'ticket',					label		: '_TICKET',					editable    : true,     type: 'picture', height: '395px'	}							
						]
					}
				],
				buttons	: {
					style	: { "margin-top": "2rem" },
					items	: [
						{ name: 'save', 		label: '_SAVE', 		action: 'saveRow',       icon: 'floppy-o'		},
						{ name: 'invoice', 		label: '_INVOICE', 		action: 'showInvoice',   icon: 'file-text-o'	}
					]
				},
				results	: []
			}
		};

		await this.initFilters("expenditures");
		await this.initMenu("expenditures");
	}

	async initFilters($entity)	{
		this.pageInfo.entities[$entity].filters = await this.commons.translateRecursively(filters,{ label: "label", children: "items" });		
	}

	checkFilter(filter)			{	switch(filter.type){
										case "multiple":	this.checkMultipleFilter(filter); 	break;
										default:			this.checkSimpleFilter(filter);		break;
									}
	}

    checkMultipleFilter(filter)	{
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= {
																				field	: filter.field,
																				options	: filter.items.map(item=>item.value),
																				selected: filter.selected
																			};

		this.filterData(filter.entity);
    }

	checkSimpleFilter(filter){
		if(	undefined===filter.entity 		||
			undefined===this[filter.entity] ||
			undefined===filter.name 		||
			undefined===filter.status
		){ return false; }

		this.pageInfo.entities[filter.entity].activeFilters 				= this.pageInfo.entities[filter.entity].activeFilters || {};
		this.pageInfo.entities[filter.entity].activeFilters[filter.name] 	= filter.status;

		this.filterData(filter.entity);
	}

	/**
	 * Filter entity
	 * @param $entity
	 */
	filterData($entity){
		let data 	= this.pageInfo.entities[$entity].data			|| [];
		let filters = this.pageInfo.entities[$entity].activeFilters 	|| {};

		// AT LEAST ONE FILTER
		if ( Object.keys(filters).length>0 ) {
			Object.keys(filters).forEach(item=>{

				//alert("FILTER["+$entity+"]="+item);
				let selected	= filters[item].selected;
				let options 	= filters[item].options;
				let inverted 	= options.filter(item=>selected.indexOf(item)<0);
				let field		= filters[item].field;

				switch(item){
					case "verified":	data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "status":		data 	= data.filter(item=>!inverted.some(value=>value==item[field])); break;
					case "direction":	data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "error":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
					case "shared":		data 	= data.filter(item=>!inverted.some(value=>value==item[field]));	break;
				}
			});
		}

		this.pageInfo.entities[$entity].filteredData	= data;
		this.pageInfo.entities[$entity].count 		= this.pageInfo.entities[$entity].filteredData ? this.pageInfo.entities[$entity].filteredData.length : 0;
		// this.paginateLocal($entity, null);
	}

	filterPending(filter){}

	async initMenu($entity){
		let me									= this;
		this.pageInfo.entities[$entity].menu	= [];
		this.pageInfo.entities[$entity].filters.forEach(async filter=>{
			let item 		= {
				label		: await this.commons.getTranslate(filter.label),
				icon		: filter.icon?('fa fa-'+filter.icon):'',
				style		: { padding: '5px 10px' },
				items		: []
			};
			filter.items.forEach(async current=> {
				let element	= {
					label		: 	await this.commons.getTranslate(current.label),
					icon		: 	filter.selected.some(selected=>selected==current.value)?'fa fa-check-square-o':'fa fa-square-o',
					command		: 	($event) => { me.doMenuAction(current.value,filter,$entity); }
				}
				item["items"].push(element);
			});
			this.pageInfo.entities[$entity].menu.push(item);
		});
	}

	/**
	 * Execute Menu item action
	 * @param $item
	 * @param $filter
	 */
	doMenuAction($item,$filter,$entity){
		if(undefined===$filter.selected){ return false; }
		if(undefined===$item)			{ return false; }
		$filter.selected = $filter.selected.some(item=>item===$item)
							?$filter.selected.filter(item=>item!==$item)
							:[...$filter.selected,$item];
		this.checkFilter($filter);		// Execute filtering
		this.initMenu($entity);		// Regenerate menu to show selection
	}

	async loadEntities()		{ await this.load("expenditures");							}
  	async ngOnInit()			{ await this.init(); await this.loadEntities();			}
    onUpload(event) 			{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)			{
		switch($type){
			case "button"			:	switch($item.name){		
											case "reload"		: 	this.loadEntities();												break;
											case "list"			:	this.pageInfo.status = $item.name;									break;
											case "bookings"		:	this.pageInfo.status = $item.name;									break;
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;											
										}
										break;
			case "tableExpenditures":	switch($item.name){								
											case "new"			:	this.pageInfo.entities["expenditures"].data.push({});				break;
											case "reload"		: 	this.loadEntities();												break;
											case "selectedRow"	:	//this.commons.generateToast("_INFO","Selected Item","info");
																	this.pageInfo.entities["expenditures"].selected = $item.item;
																	break;
											case "saveRow"		:	this.commons.generateToast("_INFO","Save row","info");				break;
											case "createInvoice":	this.commons.generateToast("_INFO","Create invoice","info");		break;
											case "search"		:	this.commons.generateToast("_INFO","Search entity "+$item.entity,"info");
																	this.pageInfo.entities["expenditures"].table.results = [
																		{ id: 1, name: 'foo11', label: 'foo12', value: 'foo13' },
																		{ id: 2, name: 'foo21', label: 'foo22', value: 'foo23' }
																	];
																	break;
										}
										break;
		}
	}
	getFilteredEntity($entity)	{
		switch($entity){
			case "expenditures"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});			
		}
	}
	async load($entity)			{
		let response = {};
		switch($entity){
			case 'expenditures' :
				// response 								= 	await Promise.resolve(this.entityService.getRequest($entity));
				response["data"]						=	expendituresFakeData;						
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;			
		}
	}
}
