import { AggregatorsService } from './../../demo/service/database/aggregator.service';
import { CommonsService } from './../../demo/service/commons.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-summary-onboarding',
  templateUrl: './app-summary-onboarding.component.html',  
  styleUrls   : ['./app-summary-onboarding.component.scss']
})
export class AppSummaryOnboardingComponent implements OnInit{
    @Input()	mode			:	any;
	@Input() 	dmc				:   any;
	@Input() 	destination		:   any;
	@Input()	stations		:	any;
	@Input() 	transportData	:   any;
	@Input()	aggregators		:	any;
	@Input() 	aggregatorsDest	:   any;
	@Input() 	pricingList		:   any;
	@Input() 	zonesList		:   any;

	@Output()   emitter			:   EventEmitter<any>   = new EventEmitter();
	
	pageInfo					:	any = {};

	constructor(private commonsService 		:	CommonsService,
				private aggregatorService	:	AggregatorsService){}

	async ngOnInit() {

		this.pageInfo =	{
			aggregators	:	[],
			imgs		:	{
				airport 		: 	'https://icons.iconarchive.com/icons/sonya/swarm/256/Airport-icon.png',
				port			: 	'https://i.ya-webdesign.com/images/anchor-logos-png-1.png',
				train			: 	'https://image.flaticon.com/icons/png/512/2062/premium/2062153.png',
				fleet			:	'https://pregem.com/wp-content/uploads/2017/03/fleet-icon-1.png',
				drivers			:	'https://cdn.iconscout.com/icon/free/png-256/driver-1785014-1518720.png',
				transporters	:	'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRgjjmBWIVnI-BPESXUiQoJa8vaUzgZyHBQOlm4eQLX2LWbhe8h'
			},
			loading		:	true
		}
		await this.mergeAggregators();
		this.pageInfo.loading = false;
	}

	async mergeAggregators(){
		if(this.dmc.isNew){
			
		}else{
			this.pageInfo.aggregators	=	await Promise.all(this.aggregatorsDest.map(async aggregator =>{
				let infoAggregator	=	this.aggregators.find(el => aggregator.id == el.id);
				let accounts		=	await Promise.all(aggregator.providersAccounts.map(async account =>{
					let infoAccount	=	await this.aggregatorService.getAccountToProviderDmc(this.dmc.id,account.providerId,account.accountId);
					return infoAccount.success ? infoAccount.data : {};
				}));
				console.log(infoAggregator,accounts);
				return {	aggregator	: infoAggregator,
							accounts	: accounts
						}
		}))
		}
		console.log('Aggregators Mount', this.pageInfo.aggregators);
	}

	getInfoAggregator(aggregatorId){
		if(!this.aggregators){return {}}
		return this.aggregators.find(item => item.id == aggregatorId) || {};
	}

	cancel(){
		this.emitter.emit({action : 'cancel'});
	}

	save(){
		this.emitter.emit({action : 'save'});
	}
}