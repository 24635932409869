import { Component, 
         OnInit, 
         ViewEncapsulation, 
         Input, 
         EventEmitter, 
         Output                 } from '@angular/core';
import { CommonsService 		} from '../../../service/commons.service';
import { EntityService 			} from '../../../service/entity.service';
import { ConfirmationService 	} from 'primeng/primeng';
import { FirebaseService 		} from '../../../service/database/firebase.service';
import { StorageService 		} from '../../../service/storageservice';
import { DestinationsService 	} from '../../../service/database/destinations.service';
import { AggregatorsService 	} from '../../../service/database/aggregator.service';
import { CompanyService 		} from '../../../service/database/company.service';
import { TransportService       } from 'src/app/demo/service/transports/transports.service';

@Component({
	selector		: 'app-manage-list-prices',
    styleUrls		: ['./manage-list-prices.component.scss'],
	templateUrl		: './manage-list-prices.component.html',
	encapsulation	: ViewEncapsulation.None,
})

export class ManageListPricesComponent implements OnInit
{

    @Input() fromWizard         :   Boolean = false;
    @Input() dmc                :   any;
    @Input() destination        :   any;
    @Input() mode               :   any;
    @Input() aggregators        :   any;
    @Input() areasDestination   :   any;
    @Input() dmcFleet           :   any;
    @Input() stations           :   any;
    @Input() aggregatorsDest    :   any;
    @Input() listPrices         :   any;

    @Output() emitter       =   new EventEmitter<any>();
    
	pageInfo 		: any 		= {};
	userInfo        : any 		= {};
	
	constructor(
		private entityService 	: EntityService, 
		private commons 		: CommonsService, 
		private storage 		: StorageService,
		private firebaseCtrl    : FirebaseService,
		private aggregatorCtrl	: AggregatorsService,
        private destinationCtrl : DestinationsService,
        private transportCtrl   : TransportService
	){}

	async ngOnInit() 	{	await this.init();
							await this.loadEntities();	
						}
    
	async init()		{	this.pageInfo   =   {
													entities    :   {},
													dataLoaded  :   false
												}
							
							this.userInfo	=	(this.storage.getItem('dmcSuite') || {}).userInfo || {}; 
						}

    async loadEntities(){
        const entities  =   [	'dmcFleet',
								'vehiclesTypeList',
								'listPrices',
								'aggregators',
								'aggregatorsDest',
								'dmcDestination',
								'infoDestination',
								'areasDestination',
								'stations',
								'areasDestination'
							];
        for( let entity of entities ){ 
			await this.load(entity);
        }
        console.log(this.pageInfo.entities);

		// Check if some entities has not been loadded
        let someUnhandled	=   Object.keys(this.pageInfo.entities).some(item => this.pageInfo.entities[item].data == undefined);
        if(someUnhandled){   
			return this.commons.generateToast('_ERROR','_COULD_NOT_LOAD_ALL_DATA', 'error');
		}
        this.pageInfo.dataLoaded = true;
    }

    async load($entity){
		this.pageInfo.entities[$entity] 		=   this.pageInfo.entities[$entity] || {};
		this.pageInfo.entities[$entity].loading =	true;
        let response;
        switch($entity){

            case 'areasDestination'	    :   if(this.areasDestination)	{ 	response 		= { success : true, 
																								data 	: this.areasDestination 
																							}
																		}
											else 						{ 	let refAreas 	= this.getFilteredEntity('infoDestination').refAreas;
																			response 		= { success : true,
																								data 	: await this.destinationCtrl.getAreasFromDestination(refAreas)	
																							};
																		}
											this.pageInfo.entities[$entity].data    =	response.data;										    
											break;

            case 'listPrices'           :   response  	=   this.listPrices ?   {success : true, data : this.listPrices || []}
                                                                                                            :   {success : true, data : []}
                                            this.pageInfo.entities[$entity].data		= 	response["data"];
                                            break;

            case "dmcFleet"             :   response                                    =   this.dmcFleet   ?   {success : true, data : this.dmcFleet}
                                                                                                            :   {success : true, data : await Promise.resolve(this.transportCtrl.getFleet(this.userInfo.idDmc, this.userInfo.currentDestination.id)) };
                                            this.pageInfo.entities[$entity].data		= 	response["data"];

                                            break;

            case "vehiclesTypeList"	    :   response 									= 	await Promise.resolve(this.entityService.getRequest("fleet",{}));
                                            this.pageInfo.entities[$entity].data		= 	response["data"];
                                            
                                            let fleetTypes                            	=   this.getFilteredEntity('dmcFleet');                                            
                                            this.pageInfo.entities[$entity].data        =   this.pageInfo.entities[$entity].data.filter(el => {
                                                                                                return (fleetTypes||[]).find(fleet => fleet.typeId == el.id)?true:false;
                                                                                            });
											console.log('MATCH TYPES'	);
											console.log(fleetTypes		);
											console.log(response.data	);												
                                            break;

            case 'aggregators'          :   response                                    =   await this.aggregatorCtrl.getProviders();
                                            this.pageInfo.entities[$entity].data        =   response;
                                            break;
											
            case 'aggregatorsDest'      :	response    	                            =	this.aggregatorsDest    ?   { success : true, data : this.aggregatorsDest || []}
                                                                                                                    :   { success : true, data : await this.aggregatorCtrl.getAggregatorsFromDestination(this.userInfo.idDmc, this.userInfo.currentDestination.id)};
                                            this.pageInfo.entities[$entity].data	    =	this.mergeInfoAggregators(response.data);
                                            break;

            case 'dmcDestination'       :   response	                        	    =	this.fromWizard         ? { success : true, data : []}
                                                                                                            		: { success : true, data : await this.destinationCtrl.getInfoDestinationFromDmc(this.userInfo.idDmc,this.userInfo.currentDestination.id)};
                                            this.pageInfo.entities[$entity].data        =   response.data;
                                            break;

            case 'infoDestination'      :   response                                    =   this.fromWizard         ?   { success : true, data : []}
                                                                                                                    :   { success : true, data : await this.firebaseCtrl.getDataByRef(this.getFilteredEntity('dmcDestination').refDestination)};
                                            this.pageInfo.entities[$entity].data        =   response.data;
                                            break;
											
            case 'stations'             :  	response                                     =   this.stations           ? {success : true, data : this.stations}
                                                                                                                    : {success : true, data : this.getFilteredEntity('dmcDestination').workingStations || []}  
                                            this.pageInfo.entities[$entity].data		= 	response["data"];
                                            break;
        
        }
        this.pageInfo.entities[$entity].loading =	false;
    }

    getFilteredEntity($entity){
        switch($entity){
            default                 :   return ( this.pageInfo.entities[$entity] || {}).data;
			case 'dmcFleet'			:	return ((this.pageInfo.entities[$entity] || {}).data||{}).data;
            case 'vehiclesTypeList' :	return ((this.pageInfo.entities[$entity] || {}).data||[]).map(item=>{ item.url="/assets/layout/images/fleet/"+item.type+".png"; return item; });
        }
    }

    mergeInfoAggregators(listAggregators){
        return listAggregators.map(aggregator => {
            let foundAggregator =   this.getFilteredEntity('aggregators').find(item => item.id == aggregator.id || item.id == aggregator.providerId);
            
            return foundAggregator ?    {...aggregator, name : foundAggregator.name, logo :  "/assets/layout/icons/providers/"+foundAggregator.thumbnail+".png"}
                                    :   aggregator;
        });
    }

    async handleMapAction($event){
        switch($event.action){
            default             :   return;
            case 'update'       :   await this.firebaseCtrl.updateItemByRef($event.data.aggregator.ref,{zones : $event.data.zones});
                                    await this.load('aggregatorsDest');
        }
    }

    async handleActionPrices($event){
        console.log('ACTION PRICES', $event);
        switch($event.action){
            case 'savePrices'   :   if(this.fromWizard){
                                        if(this.mode == 'edit'){
                                            console.log('UPDATE TO DATABASE');
                                            await this.firebaseCtrl.updateItemByRef($event.data.aggregator.ref,{listPrices : $event.data.listPrices});
                                            this.commons.generateToast('_SUCCESS','_PRICES_UPDATED','success');
                                        }else{
                                            this.emitter.emit($event);
                                        }
                                    }else{
                                        console.log('UPDATE TO DATABASE');
                                        await this.firebaseCtrl.updateItemByRef($event.data.aggregator.ref,{listPrices : $event.data.listPrices});
                                        this.commons.generateToast('_SUCCESS','_PRICES_UPDATED','success');
                                    }
            break;
            case 'goToAggregators'  :   this.emitter.emit($event);
            case 'goToFleet'        :   this.emitter.emit($event);
            break;
        }
        
    }
}