export const filters	= [
	{ 	
		name	: 'availability',
		type	: 'dropdown_calendar', 
		position: 'left',
		label	: '_AVAILABILITY',
		items	: [
			{ label: "calendar1", 			value: "calendar2" }
		],
		showWeek: true,
		selected: undefined
	},
	{ 	
		name	: 'sort',
		type	: 'dropdown',
		position: 'left', 
		label	: '_SORTBY',
		items	: [
			{ label: "_NAME", 		value: "name" 	},
			{ label: "_AREA", 		value: "area" 	},
			{ label: "_TYPE", 		value: "type" 	},			
		],
		selected: "most_relevant"
	}
];