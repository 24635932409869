import { Component, 
         Input, 
         Output, 
         EventEmitter,     	
		 ViewEncapsulation			} from '@angular/core';
import { CommonsService 			} from 'src/app/demo/service/commons.service';
import { DragulaService 			} from 'ng2-dragula';

@Component({
  selector    		: 'app-tour-wizard-sights',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html',  
})
export class TourWizardSightsComponent {

	@Input("type")      type	:string	= "";
	@Input("info")		info	:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	draggedItem			: any = {};	
	
	constructor(
		private commons		: CommonsService, 
		private dragulaCtrl : DragulaService
	){ 
		this.init(); 
	}

	init(){
		console.log("Sights component constructor")
		this.pageInfo.sights	= [];
		this.pageInfo.tabs		= {
			items 			: [
				{ id:0, label: this.commons.getTranslate('_SIGHT_LIST'		),	icon : 'fa fa-folder-o' 		, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:1, label: this.commons.getTranslate('_SIGHT_SELECTION'	), 	icon : 'fa fa-pencil-square-o' 	, command: ($event)=> { this.setTabItem($event)} 	}				
			],
			selected		: 0
		};
	}

	setTabItem($event){	this.pageInfo.tabs.selected = $event.item.id;	}

  	ngOnInit()					{
		console.log("Init component sights");
		this.pageInfo.map		= { zoom: 10, lat: 39.639938, lng: 2.894679 };		
		this.info.sights		= this.info.sights || [];
	}

    action($type,$item){		
		switch($type){
			case "toggleSight"	: 	let isSelected 			= this.info.sights.some(item=>item.id==$item.id);
									if(isSelected) {
										$item.selected 		= false;
										$item.color			= "red";
										// $item.icon		= { url : "http://maps.google.com/mapfiles/ms/icons/red-dot.png" };
										this.info.sights 	= this.info.sights.filter(item=>item.id!=$item.id);
										this.emitter.emit({ action : 'remove', item: $item });
									} else {
										$item.selected 		= true;
										$item.color			= "green";
										$item.view			= true;
										// $item.icon		= { url : "http://maps.google.com/mapfiles/ms/icons/green-dot.png" };
										$item.position		= this.info.sights.length + 1;
										this.info.sights	= [ ...this.info.sights, $item ];
										this.emitter.emit({ action : 'add', item: $item });
									}
									break;
			case "toggleViewAll":	this.pageInfo.sightViewAll = undefined==this.pageInfo.sightViewAll?true:!this.pageInfo.sightViewAll;
									this.info.entities.sights.forEach(item=>{ item.view = this.pageInfo.sightViewAll; });	
									break;
			case "toggleView"	:	$item.view = undefined==$item.view?true:!$item.view;					
									break;
			case "showRoutes"	:	this.showRoutes(); 	break;
			case "showSight"	:	this.showSight();	break;
			case "showSights"	:	this.showSights();	break;
			case "searchSights"	:	console.log("SEARCH",$item); break;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			case "sights"		: return this.info.entities.sights.filter(item=>{ 
																			return (undefined==this.pageInfo.searchSight || this.pageInfo.searchSight=="")
																					?true
																					:item.title.toLowerCase().includes(this.pageInfo.searchSight.toLowerCase());
																		});
		}
	}

	getMarkers($type){
		switch($type){
			case "sights"		: 	return this.info.entities.sights.filter(item=>item.view);
			case "selected"		:	return this.info.sights;
		}
	}

	showSight()				{ console.log("showSight"); 	}
	showSights()			{ console.log("showSights"); 	}
	showRoutes()			{ console.log("showRoutes"); 	}
	
	remove($type,$item){
		switch($type){			
		}
	}

	dragStart(event,item) 		{ this.draggedItem = item;	}
	dragEnd(event) 				{ this.draggedItem = null; 	}
	
	drop(group,event) {
		switch(group){
			case "reorder"		: 
				if(this.draggedItem.position==event.position){ return false; }
				let tmp						= this.draggedItem.position;
				this.draggedItem.position 	= event.position;
				event.position				= tmp;
				this.info.sights			= this.info.sights.sort((a,b)=>a.position>b.position?1:-1);
				break;
		}
	}    
}
