export const filters = {
	calendar : [
		{
			name	: 'display',
			label	: '_VISUALIZE',
			type	: 'multiple',
			icon	: 'file',		
			items	: [
								{ label: '_SCHEDULE', value: 'schedule' 	},
                { label: '_OFFERS', 	value: 'offers'		},
                { label: '_PERIODS', 	value: 'periods'	},
			],
			selected: [
				'schedule','offers' 
			]
		},
		{
			name	: 'price',
			label	: '_PRICING',
			type	: 'multiple',
			field	: 'types',
			icon	: 'user',		
			items	: [
				{ label: '_ADULTS', 		value: 'adult' },
				{ label: '_CHILDREN', 	value: 'children'	},
				{ label: '_INFANTS', 		value: 'infant' }	
			],
			selected: []
		},
		{
			name	: 'modifications',
			label	: '_MODIFICATIONS',
			type	: 'multiple',
			field	: 'modifications',
			icon	: 'file',		
			items	: [
				{ label: '_OFFERS', 			value: 'offers' 		},
				{ label: '_PROMOTIONS', 	value: 'promotions'	},
			],
			selected: [ 'SH', 'SP', 'PR' ]
		}
    ]
}