import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';


@Component({
    selector   : 'app-marker-transfer',
    templateUrl: './markerTransfer.component.html',
    styleUrls:['./markerTransfer.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class MarkerTransferComponent implements OnInit{

    @Input() item   : any;
    @Input() resumme: any;
    
    ngOnInit(){
    }
}