import { ViewChild, ElementRef, NgZone 		} from '@angular/core';
import { SuperformService 					} from '../../demo/service/super-form/superform.service';
import { Component, 
         Input, 
         Output, 
		 EventEmitter						} from '@angular/core';
import { CommonsService 					} from 'src/app/demo/service/commons.service';
import { MapsAPILoader,
		 AgmMap 							} from '@agm/core';
import { FirebaseService 					} from 'src/app/demo/service/database/firebase.service';
import { Observable 						} from 'rxjs';
		 
declare const google: any;

@Component({
  selector    	: 'app-super-form',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'
})
export class SuperFormComponent {

	@Input('readonly'	)	readonly: boolean = false;
	@Input("info"		)	info	: any;
	@Input("entities"	)	entities: any;
	@Input("options"	)	options	: any;
	@Input("rowData"	)	rowData	: any; // item selected if comes from table expansion
	@Input("phases"		)	phases	: any;
	@Input("states"		)	states	: any;
	@Output()				emitter	= new EventEmitter<any>();	

	@ViewChild('place') place 	: ElementRef;

	// @ViewChild("searchAddress")  							searchOrigin		: ElementRef;
    // @ViewChild('circle', 	{ read	: AgmCircle }) 			circle				: AgmCircle;
    // @ViewChild('agmCluster',	{ read 	: AgmMarkerCluster})	agmCluster			: any;
    @ViewChild('map',			{ read 	: AgmMap})				map					: AgmMap;
    
	// @ViewChild('streetviewMap')     streetviewMap   	: any;
    // @ViewChild('streetviewPano')    streetviewPano  	: any;
		
	pageInfo  			: any 	= { dataLoaded		: false,
									maxFileSize		: 10000000,
									results			: [], 
									form			: [], 
									entities		: [], 
									uploadedFiles	: [],
									defaultImg 		: 'assets/layout/images/img/no_image.png' 
								};
	draggedItem			: any 	= {};	
	
	constructor( 	public 	commons				: CommonsService, 
					private superFormService 	: SuperformService,		
					private mapsAPILoader		: MapsAPILoader,
					private ngZone				: NgZone,
					private firebaseService		: FirebaseService
	)								{ this.init(); }
	init()							{}

	ngAfterViewInit()				{
		// this.listenGoogleMapDirections(); // to call the autocompletes
            
		// this.map.mapReady.subscribe(map => {
        //     this.listenGoogleMapDirections(); // to call the autocompletes            
        // });
	}

	listenGoogleMapDirections()		{
		this.mapsAPILoader.load().then(() => {
			this.listenAddressPlaces(this.place);  	 //  address
		});
	}

	listenGooglePlaces($item)		{	if( this.pageInfo.placeActive 							){ return false; }
										if(undefined==$item || undefined==$item.nativeElement	){ console.log("[listenGooglePlace] Empty item"); return false; }								
										this.pageInfo.placeActive 	= true;
										this.pageInfo.placer 		= new google.maps.places.Autocomplete($item.nativeElement);								
									}

	listenAddressPlaces($item)		{	new google.maps.places
														.Autocomplete($item.nativeElement)
														.addListener("place_changed", () => {
															alert("LAL PLACE_CHANGED");
												            //   this.ngZone.run(() => {
															// 	const place 					= autocompleteOrigin.getPlace();
															// 	this.pageInfo.searchItem.step 	= "hotel";
												            //     if (place.geometry === undefined || place.geometry === null) { return; }
												            //     setTimeout(()=>{
												            //         this.mountAddressFromGoogle(place);						
												            //     },1500)
												            //   })
												        });
    								}

	// Get data updates
	ngOnChanges(data) 				{ 
		console.log("[superForm] FORM CHANGE WITH DATA",data);
		[ "info" ].forEach(item=>{
			if ( undefined===data[item] || undefined==data[item].currentValue || null==data[item].currentValue) { return false; }
			const value = data[item].currentValue;
			switch(item){
				case "info"		:	this.info.item				= this.rowData ? (this.rowData) : (this.info.item ? this.info.item : (value.info	|| value.item || {}));
									this.pageInfo.form 			= value.form	|| [];
									this.pageInfo.buttons		= value.buttons ? (Array.isArray(value.buttons) ? value.buttons 
																												: value.buttons['items'] ? value.buttons['items'] 
																														 				: value.buttons)	
																				|| {}
																				: {};
									if(Array.isArray(this.pageInfo.buttons)){
										(this.pageInfo.buttons || []).forEach(button => {if(button.hasOwnProperty('visible')){return}else{button.visible = true;}})
									}
									this.pageInfo.displayButtons= value.displayButtons!=undefined ? value.displayButtons : true;
									this.entities				= { //...( this.entities	|| {} ),
																	...( value.entities || {} ), 
																	...( value.commons || []  )
																	.reduce((o,item)=>{ 
																		o[item]=this.commons.entities[item]; 
																		return o;
																	},{}) };
									this.pageInfo.entities		= this.entities;
									this.pageInfo.options		= value.options	|| {};
									this.pageInfo.dataLoaded 	= true; 
									//this.superFormService.prepareToFrontend(this.info);

									this.listenGooglePlaces(this.place);
									break;
			}
		});		
	}	

	ngOnDestroy() 				{}

	checkConditional($item)		{	if(!$item				){ return false; 	}
									if(!$item.conditional	){ return true; 	}
									let value;
									switch($item.conditional.multiple){
										case true	:	if($item.conditional.andFields){
															return $item.conditional.andFields.reduce((v,item)=>{
																v = v && this.info.item[item.field] == item.value;
																return v;
															},true);
														}
														if($item.conditional.orFields){
															return $item.conditional.orFields.reduce((v,item)=>{
																v = v || this.info.item[item.field] == item.value;
																return v;
															},false);
														}
														return true;
											
										default		:	switch($item.conditional.type){
															case "list"			:	value = ( this.info.item[$item.conditional.field] || {})["value"];	break;
															default				:	value = this.info.item[$item.conditional.field];					break;
														}
														switch($item.conditional.check){
															case "notAssigned"	:	return value != undefined;
															case "positive"		:	return value != undefined && value > 0;
															default				:	return value == $item.conditional.value;
														}										
									}
								}

	getStatusIcon(item)			{	try {
										if((item.mandatory || item.validators) && !item.disabled && this.info.item){
											if(item.validators){
												return item.validators.some(validator => this.superFormService.invalidInfo(this.info.item[item.field], validator, null)) ? "fa fa-ban" : "fa fa-check";
											}else{
												if(undefined==this.info.item[item.field] || this.info.item[item.field]==""){ return "fa fa-ban"; }
													return "fa fa-check";
											}
										}
										return "fa fa-fw fa-align-justify";
									} catch(e) {
										return "fa fa-fw fa-align-justify";
									}
								}

	getStatusColor(item)		{	let value;
									if(this.readonly || item.readonly){ return "cadetblue"; }
									if(item.mandatory){ this.info.mandatories = [ ...(this.info.mandatories||[]).filter(current=>current!=item.field), item.field ]; }
									try {
										if((item.mandatory || item.validators) && !item.disabled && this.info.item){
											if(item.validators){
												value = item.validators.some(validator => this.superFormService.invalidInfo(this.info.item[item.field], validator, null));
												return value?"crimson":"forestgreen";											
											}else{
												value = (undefined==this.info.item[item.field] || this.info.item[item.field]=="");
												return value?"crimson":"forestgreen";												
											}
										}
										return "cadetblue";
									} catch(e) {
										return "cadetblue";
									}
								}

	search(item,$event) 		{	console.log("ITEM",item,"EVENT",$event);
									if(undefined==item.entityList){ return false; }
									this.pageInfo.results =  (this.pageInfo.entities[item.entityList] || [])
																.filter	( item 	=> String(item.name).toLowerCase().includes(String($event.query).toLowerCase()))
																.map	( el 	=> { return { 	label 	: this.commons.getTranslate( el.label ),
																								value	: el[ item.key || 'name']
																							};
																					}
																		)
																// .map	( el 	=> ({ 	value : el,
																// 						label : el.complete_name || el.name
																// 					})
																// )
																// .map	( el 	=> ({ 	value : el,
																// 						label : el.complete_name || el.name
																// 					})
																// )
																;
									console.log("[Search] Results",this.pageInfo.results);
									// if(item.nullable!=false){
									// 	this.pageInfo.results	= [ { label: this.commons.getTranslate("_NOT_SELECTED"), value: "not_selected" }, ...this.pageInfo.results ];
									// }
								}

	getListValues(entity)		{	return this.pageInfo.entities[entity] || [];
								}
	getImg(img)					{	return img ? img : this.pageInfo.defaultImg;
								}

	// checkFile($event,$info) 	{	if ($event.target.files && $event.target.files[0]) {
	// 									let info		= $info.info;
	// 									let item		= $info.item;
	// 									const file 		= $event.target.files[0];
	// 									// rowData.fileImg = file;
	// 									const reader 	= new FileReader();
	// 									reader.onload 	= e =>  info[item.field] = reader.result; console.log(reader.result);
	// 									reader.readAsDataURL(file);
	// 									console.log('image is', info[item.field]);
	// 								}
	// 							}

	removeImg($item,$extra)		{	$item[$extra.field]	=	null;	}

	isSelected($type,$info)		{	switch($type){
										case "radio"	:	if(!$info.option.value){ return false; }
															let selected = 	$info.multiple
																			? ( this.info.item[$info.field] || [] ).some(item=>item==$info.option.value) 
																			: this.info.item[$info.field]==$info.option.value;
															return selected;
									}
								}
								
    async doAction($type,$item,$extra){
		switch($type){
			case "text"				:	switch($item.type){
											case "place":	this.emitter.emit({ type: "listening_place", item: $item  });
															this.pageInfo.placer.addListener("place_changed", () => {
																this.emitter.emit({ type: "place", item: this.pageInfo.placer.getPlace() });																
															});
										}
										break;

			case "place"			:	switch($item.action){
											case "set"	:	this.listenGooglePlaces(this.place);
															console.log(document.getElementsByClassName('pac-item-query'));	
															break;										
										}
										break;

			case 'phase'			:	this.emitter.emit({ type: $type, item: $item  });	break;
		
			case "selectRadio"		:	if(!$item.multiple)	{	this.info.item[$item.field]	= 	$item.option.value;		}
										else 				{	this.info.item[$item.field]	=	this.info.item[$item.field] || [];
																let selected	= this.info.item[$item.field].some(item=>item==$item.option.value);
																if(selected){ 	if($item.item.min && ( this.info.item[$item.field] || [] ).length<= parseInt($item.item.min)){ 
																					this.commons.generateToast("_WARNING","_RADIO_MIN_ITEMS_"+$item.item.min,"warning");
																					return false; 
																				}
																				this.info.item[$item.field] = this.info.item[$item.field].filter(item=>item!=$item.option.value); 	}
																else 		{ this.info.item[$item.field].push($item.option.value);													}
															}
										break;

			case "removeUpload"		:	$item[$extra.field]		=	null;					break;
			case "onUpload"			:	this.emitter.emit({ type: $type, item: $item  });	break;
			case "save"				:	// NO VALIDATION. REMOVE !!
										// this.emitter.emit({ type: "mandatories", 	item: this.pageInfo.mandatories });										
										this.emitter.emit({ type: $type, item: $item, extra: $extra });	
										break;

										// WITH VALIDATION
										if(this.validate()){
											let simplified	=	this.superFormService.prepareToBackend(this.info);
											console.log("DATA",simplified);
											this.emitter.emit({ type: $type, item: $item, extra: $extra, simplified : simplified });}
										else{	
											this.commons.generateToast("_ERROR","_VALIDATE_FIELDS","error");
										} 	
										break;
            case "delete"			:	this.emitter.emit({ type : $type, item : $item, extra : $extra});					break;
			case "cancel"			:	this.emitter.emit({ type : $type, item : $item || null, extra : $extra || null}); 	break;
			
			case "substractOne"		:	$extra.minValue 	= parseInt($extra.minValue) 	|| 0;
										$item[$extra.field] = parseInt($item[$extra.field]) || 0;										
										$item[$extra.field] = $item[$extra.field]>$extra.minValue?$item[$extra.field]-1:$item[$extra.field];
										break;
			case "addOne"			:	$extra.maxValue 	= parseInt($extra.maxValue) 	|| 100000;
										$item[$extra.field] = parseInt($item[$extra.field]) || 0;
										$item[$extra.field] = $item[$extra.field]<$extra.maxValue?$item[$extra.field]+1:$item[$extra.field]; 
										break;
			case "toggleItem"		: 	let isSelected = this.pageInfo.selected.some(item=>item.id==$item.id);
										if(isSelected) {
											$item.selected 			= false;
											this.pageInfo.selected 	= this.pageInfo.selected.filter(item=>item.id!=$item.id);
										} else {
											$item.selected 			= true;
											$item.position			= this.pageInfo.selected.length + 1;
											this.pageInfo.selected	= [ ...this.pageInfo.selected, $item ];
										}
										break;
			case "toggleItemField"	:	switch($extra){
											case "type":	$item.editing 	= $item.editing==true?false:true; break;
										}
										break;
			case "setItemField"		:	switch($extra){
											case "type":	$item.type 		= $item.optionSelected;
															$item.editing	= false;
															break;
										}
										break;
			case "showInfo"			: 	if($item.info){ this.commons.generateToast("_INFO",$item.label+"_INFO","info"); } break;

			case "getSuggestions"	:	return ( this.pageInfo.entities[$item.entityList] || []).map(item=>{ item.label = this.commons.getTranslate(item.label); return item });

			case "groupButton"		:	if(this.readonly || $item.readonly){ return false; }
										switch($extra.type){
											default					:	return;
											case	'toggleable'	:	let selected = $extra.items.find(item => item.selected);
																		if(selected && selected.value == $item.value){ return; }
																		$extra.items.forEach(item => item.selected = false);
																		$item.selected = true;
																		break;
											case	'free'			:	$item.selected = $item.selected === undefined ? true : !$item.selected; break;
											case	'only-view'		:	return;
										}
										this.info.item					=	this.info.item || {};
										this.info.item[$extra.field]	=	$extra.items.filter(el => el.selected).map(el => el.value);

										console.log($item,$extra);this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;

			case "selectedFromList"	:	this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;
			
			case "multiSelect"		:	this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;
			
			case "datetime"			:	this.emitter.emit({ type: $type, item: $item, extra: $extra });	break;
			
			case "toggle"			:	this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;
			
			case "selectButton"		:	this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;
			
			case "map"				:	if(!(this.readonly || ( $extra.item && $extra.item.readonly ))){ 
											this.emitter.emit({ type: $type, item: $item, extra: $extra }); 
										}
										break; 

			case "textarea"			:	$item[$extra.item.field] = $extra.event.textValue;
										this.emitter.emit({ type: $type, item: $item, extra: $extra }); 
										break;

			default					:	this.emitter.emit({ type: $type, item: $item, extra: $extra }); break;
		}
	}

	googleSitesPlaces($type,$item)	{
		switch($type){
			case "autocomplete":	new google.maps.places.Autocomplete($item.nativeElement).addListener("place_changed", () => {
										alert("PLACE CHANGED");
									});

									// let autocompletePlace = new google.maps.places.Autocomplete(this.searchPlace.nativeElement);		
									// autocompletePlace.addListener("place_changed", () => {
									// 	  this.ngZone.run(() => 
									// 		// let place: google.maps.places.PlaceResult;
									// 		let place;
									// 		switch(this.pageInfo.searchItem.step){
									// 			case "address"	: 
									// 				place = autocompletePlace.getPlaces();
									// 				if (place.geometry === undefined || place.geometry === null) { return; }
									// 				break;
									// 		  }
									// 	  })
									// });
									break;
		}
	}

	// checkFile($event,$info) 			{	if ($event.target.files && $event.target.files[0]) {
	// 											let info		= $info.info;
	// 											let item		= $info.item;
	// 											const file 		= $event.target.files[0];
	// 											// rowData.fileImg = file;
	// 											const reader 	= new FileReader();
	// 											reader.onload 	= e =>  info[item.field] = reader.result; console.log(reader.result);
	// 											reader.readAsDataURL(file);
	// 											console.log('image is', info[item.field]);
	// 										}
	// 									}


	checkFile($type,event,$info) 		{	if (event.target.files && event.target.files[0]) {
												const file 		= 	event.target.files[0];
												const reader 	= 	new FileReader();
												reader.onload 	=	((e) => {	this.upload({
																					fileName	: file.name,
																					fileContent	: reader.result,
																					type		: $type,
																					info		: $info
																				});
																	});
												reader.readAsDataURL(file);
											}
										}

	upload($info)						{	
		const fileRef 	= this.firebaseService.afStorage.ref($info.fileName);										
		// const task	= this.firebaseService.afStorage.upload($info.fileName,$info.file);
		const task 		= fileRef.putString($info.fileContent,'data_url');
		let percent:Observable<number> = task.percentageChanges();
		
		task.then(async response =>{
			const url 	= 	await Promise.resolve(fileRef.getDownloadURL().toPromise());
			this.updateRefs(	$info.info,
								{	"url"			: url, 
									"size" 			: response.metadata.size,
									"contentType"	: response.metadata.contentType,
									"mime-type"		: response.metadata.contentType,
									"name"			: response.metadata.name																																						
								}
							);
		}).catch(error=>{
			console.error("TASK Error",error);
		});					
	}

	async updateRefs($info,$item)		{	
		if(!$info.info.item){ return false; }
		$info.info.item[$info.item.field] = $item;
		await this.firebaseService.updateDoc( $info.item.entity+"/"+$info.info.item.id, $info.info.item );
	}

	getInfo($type,$info)				{	
		switch($type){																													   
			case "entity"		:	return this.pageInfo.entities[$info];
			case "avatar"		:	return $info.info.item[$info.item.field]
											?$info.info.item[$info.item.field]["url"]
											:this.commons.pageInfo.noAvatar;
			case "zoom"			:	return parseInt($info);
			case "sorted"		: 	return ($info.items || []).sort((a,b)=>a>b?1:-1);
			case "buttons"		:	let item = this.info.item;
									return (this.phases)
										?	this.phases.filter( button => {
												return	( 	( !this.readonly &&   button.readonly !="only" 	) || 
															(  this.readonly && ( button.readonly == "only" || button.readonly == "always" ))
														) 	&&
														this.states.phase 		== button.phase 	&&
														this.states.section 	== button.section 	&&
														this.states.step		== button.step		&&
														( !button.check || ( button.check(item) ))	
														;
											})
										: 	this.pageInfo.buttons; 
		}
	}

	multipleSelect($event)				{	console.log($event);					}

	remove($type,$item)					{	switch($type){}							}
	dragStart(event,item) 				{ 	this.draggedItem = item;				}
	dragEnd(event) 						{ 	this.draggedItem = null; 				}
	drop(group,event) 					{	switch(group){
												case "reorder"		: 
													if(this.draggedItem.position==event.position){ return false; }
													let tmp						= this.draggedItem.position;
													this.draggedItem.position 	= event.position;
													event.position				= tmp;
													this.pageInfo.selected		= this.pageInfo.selected.sort((a,b)=>a.position>b.position?1:-1);
													break;
											}
										}

	/**
	 * GROUPBUTTON
	 */
	checkIfOptionSelected(info, option)	{	if(!info){ return option.default || false;}
											return info.indexOf(option.value) > -1;
										}
	/**
	 * DATES
	 */
	getMinDate(item)					{	
		if(item.options.minDate['field'])	{	return this.info.item[item.options.minDate.field];			}
		else								{	return item.options.minDate.date;							}
	}

	/**
	 * VALIDATORS
	 */
	public validate()					{	
		const itemsNoValidated	=	[];
		this.pageInfo.form.forEach(col =>{
			if(col.disabled){ return; }
			col.items.filter(item => (item.mandatory || item.validators) && (!item.disabled)).filter(item =>{
				let param	=	(this.info['info'] && Object.keys(this.info['info']).length > 0) ? this.info.info[item.field] : this.info.item[item.field];
				if(item.validators){
					return [{type : 'default'},...item.validators].some(validator => this.invalidInfo(param,validator,col));
				}else{
					return true ===	this.invalidInfo(param,{type : 'default'},col);
				}
			}).forEach(item =>{ item.invalidInfo = true; itemsNoValidated.push(item)});
		});
		return itemsNoValidated.length == 0;
	}

	invalidInfo(item,validator,colGroup){
		// console.log('validate', item, validator, colGroup);
		if(!item){ return true; }
		switch(validator.type){
			default			:	
			case 'default'			:	return (item == undefined || item == '');
			case 'onlyIf'			:	return (colGroup.items.find(item => item.field == validator['field']) || {}).selected;	// check if depending property is selected
			case 'minmax'			:	return (item < validator['min'] || item > validator['max']);
			case 'minOneSelected'	:	return !Array.isArray(item) || item.length ==0;
		}
	}

	/**
	 * 
	 */
	formalizeItems(){
		if(undefined==this.info.item		){ 
			alert("FORMALIZEITEMS WITH NO VALUES");
			return false; 
		}
		if(undefined==this.pageInfo.form	){ 
			alert("FORMALIZEITEMS WITH NO VALUES");
			return false; 
		}

		this.pageInfo.form.forEach(section =>{
			if(!section.items){ return; }
			section.items.forEach(item =>{
				if(!this.info.item[item.field]){ return }
				switch(true){
					case (item.editor == 'multiselect' || item.editor == 'autocomplete')	:	
						this.info.item[item.field]	=	Array.isArray(this.info.item[item.field])	
													?	this.info.item[item.field].map(el => { return {	label 	: this.commons.getTranslate(el.label) || this.commons.getTranslate(el.name) || this.commons.getTranslate(el.value) || el, 
																										value 	: el}})
													:	this.info.item[item.field].value	?	this.info.item[item.field] 
																							:	{	label	: this.info.item[item.field].label || this.info.item[item.field].name || this.info.item[item.field], 
																									value 	: this.info.item[item.field]};
					break; 
					case (item.editor == 'datetime'	)	:	
						this.info.item[item.field]	=	new Date(this.commons.formatTimestamp(this.info.item[item.field]));
					break; 
					case (item.editor == 'groupButton' ):
						this.info.item[item.field].forEach(el => {let found = item.items.find(option => option.value == el); if(found){ found.selected = true}else{found.selected = false;}});
						console.log('groupButton prepared', this.info.item[item.field]);
						return;
					default	:	return;
				}
			})
			// section.items	.filter(item 	=> (item.editor == 'multiselect' || item.editor == 'autocomplete') && this.info.item[item.field])
			// 				.forEach(item	=>  this.info.item[item.field]  	=	Array.isArray(this.info.item[item.field])	
			// 																	?	this.info.item[item.field].map(el => { return el.value ? el : {name : this.commons.getTranslate(el.label) || this.commons.getTranslate(el.name) || el, value : el}})
			// 																	: 	this.info.item[item.field].value ? this.info.item[item.field] : {name : this.info.item[item.field].label || this.info.item[item.field].name || this.info.item[item.field], value : this.info.item[item.field]})
		});
	}
}