import { TranslateService } from '@ngx-translate/core';
import { Component, 
         Input, 
         Output, 
         EventEmitter,      
         OnInit,
         ViewChild} from '@angular/core';
import { CommonsService } from 'src/app/demo/service/commons.service';

@Component({
  selector    : 'calendar-tours-offers',
  templateUrl : './calendar-tours-offers.component.html',
  styleUrls   : ['./calendar-tours-offers.component.scss'] ,

})
/**
 * tablePrice Row : 
 * {
 *  [{
 *      type    :   'adult',
 *      img     :   'path'
 *      qty     :   25
 *  }]
 * }
 */
export class CalendarToursOffersComponent implements OnInit {
    
    pageInfo                    : any = {
		lastRowOffers		:	{
			id          : null,
			editMode    : false,
			startTime   : null,
			endTime     : null,
			type        : {},
			value       : null,
			profiles    : ['adult','children','infant'],
			turns       : []
		},
	}
    @Input()    period          : any = {};
	@Input()    day             : any = {};
	@ViewChild('startHour')			startHour			:	any;
	@ViewChild('endHour')			endHour				:	any;
    @Output()   emiterOption	:   EventEmitter<any>   = new EventEmitter();

    constructor( private commons : CommonsService){

    }

    ngOnInit() {
			console.log('DAY IN COMPONENT DAY OFFER!!!!',this.day);
		 this.init();
	}

	init(){
		this.pageInfo.listTypeOffers = [
		{
			name 	: this.commons.getTranslate('_PROMOTION'),
			code	:	'promotion'
		},
		{
			name 	: this.commons.getTranslate('_DISCOUNT'),
			code	:	'discount'
		},
		{
			name 	:  this.commons.getTranslate('_ABSOLUTE_PRICE'),
			code	:	'absolute_price'
		}];

		this.pageInfo.listTurns = [
			{
				label	:	this.commons.getTranslate('_MORNING'),
				value	:	'M'
			},
			{
				label	:	this.commons.getTranslate('_AFTERNOON'),
				value	:	'A'
			}
		];
		this.pageInfo.listPromotions	=	[
			{
				name	:	'2x1',
				code	:	'2x1'
			},
			{
				name	:	'3x2',
				code	:	'3x2'
			},
			{
				name	:	'3x1',
				code	:	'3x1'
			}
		];

		this.pageInfo.profiles 	=	[
			{
				label	:	this.commons.getTranslate('_ADULT'),
				value	: 'adult'
			},
			{
				label	:	this.commons.getTranslate('_CHILDREN'),
				value	: 'children'
			},
			{
				label	:	this.commons.getTranslate('_INFANT'),
				value	: 'infant'
			}
		]
	}

    toggleEditableRow(row){
        const tmp    = row.editMode ? !row.editMode : true;
        this.day.offers.map(el => el.editMode = false);
		row.editMode = tmp; 
		if(row.editMode){
			this.pageInfo.currentExecSelected 			= 	JSON.parse(JSON.stringify(row));
			this.pageInfo.currentExecSelected.startTime	=	this.convertStringToTime(this.pageInfo.currentExecSelected.startTime);
			this.pageInfo.currentExecSelected.endTime	=	this.convertStringToTime(this.pageInfo.currentExecSelected.endTime);
			console.log('CALENDAR DAY SELECTED:',this.pageInfo.currentExecSelected);
		}else{
			// row.editMode = false;
			// this.emitOption({type: 'update', data : this.pageInfo.currentExecSelected})
			// this.pageInfo.currentExecSelected = row;	
		}
    }
    handleOption($event){
        this.emiterOption.emit($event);
    }

	deleteRow(row){
		console.log(row);
		this.emitOption({type:'delete',data : {daySelected : this.pageInfo.daySelected, exec :row}});
	}
	addRow(){
		console.log(this.pageInfo.daySelected, this.day);

		if(this.day.offers.find(row => !this.validateRow(row,false))){
			return this.commons.generateToast('_INFO','_EXISTING_ROW_MISSING_INFO','info');
		}
		this.emitOption({type:'add', data : {daySelected : this.pageInfo.daySelected}});
		// this.pageInfo.currentExecSelected = JSON.parse(JSON.stringify(this.pageInfo.daySelected.offers.find(el => el.editMode)));
		
	}
	async saveRow(row){

	   
		const validatedRow = await this.validateRow(this.pageInfo.currentExecSelected);
		if(validatedRow){
			
			this.pageInfo.currentExecSelected.value 	= this.pageInfo.currentExecSelected.value['code'] ? this.pageInfo.currentExecSelected.value['code'] : this.pageInfo.currentExecSelected.value; 
			this.pageInfo.currentExecSelected.startTime = this.commons.getFormatDateHour(this.pageInfo.currentExecSelected.startTime)
			this.pageInfo.currentExecSelected.endTime	= this.commons.getFormatDateHour(this.pageInfo.currentExecSelected.endTime);	
			const sameValue								= this.day.offers.filter(el => !el['wasValid']).some(el => this.compareOffers(el,this.pageInfo.currentExecSelected));
			if(sameValue){await this.commons.generateToast('_ERROR','_OFFER_ALREADY_EXISTS','error'); return;}
			row.editMode								=	false;
			if(row.wasValid){
				delete row.wasValid;
			}
			this.pageInfo.currentExecSelected.editMode	=	false;
			this.emiterOption.emit({type:'update', data : {exec :this.pageInfo.currentExecSelected}});
			// this.pageInfo.currentExecSelected 			= null;
		}
		

		// console.log('TABLE PRICE AFTER UPDATE',this.day);		
	}
	async saveRowKeyUp($event, row){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		
		row.editMode								=	false;
		this.pageInfo.currentExecSelected.editMode	=	false;
		this.emiterOption.emit({type:'update', data : {daySelected : this.pageInfo.daySelected, exec :this.pageInfo.currentExecSelected}});

	}

	checkPricingRow()	{	return !this.pageInfo.currentExecSelected.prices.some(element => element.price === null || element.price < 0);}

    
	emitOption(data){
		this.emiterOption.emit(data);
	}


	compareOffers(el, offerCopied){
		console.log('COMPARE');
		const offerDay 		= 	JSON.parse(JSON.stringify(el));
		const offerToCopy	=	JSON.parse(JSON.stringify(offerCopied));
		delete offerDay.id;
		delete offerDay.editMode;
		delete offerToCopy.id;
		delete offerToCopy.editMode;
		return JSON.stringify(offerDay) == JSON.stringify(offerToCopy);

	}

	validateRow(data,displayMessages?){
		let display = true;
		if(displayMessages == false){display = false;};

		if(Object.keys(data.type).length == 0){
			if(display){this.commons.generateToast('_ERROR','_NO_TYPE_ASSIGNED','error');};
			return false;
		}
		if(data.type.code == 'promotion' && data.value == null){
			if(display){this.commons.generateToast('_ERROR','_NO_VALUE_ASSIGNED','error');};
			return false;
		}
		if(data.type.code == 'discount' && (data.value >100 || data.value <= 0)){
			if(display){this.commons.generateToast('_ERROR','_BAD_DISCOUNT', 'error');};
			return false;
		}
		if(data.startTime == null || data.endTime == null){
			if(display){this.commons.generateToast('_ERROR','_BAD_FORMED_TIME','error');};
			return false;
		}
		// if(this.pageInfo.currentExecSelected.turns.length == 0){await this.commons.generateToast('_ERROR', '_NO_TURNS_ASSIGNED','error'); return false}

		return true;
	}
	convertStringToTime(timeString){
		var today 		= 	new Date();
		console.log(this.day);
		console.log(timeString);
		const hours		=	timeString.split(':')[0];
		const minutes	=	timeString.split(':')[1];

		return new Date(today.getFullYear(), today.getMonth(), today.getDate(), +hours, +minutes, 0);
	}
	getLangProfile(type){
		if(!this.pageInfo.profiles) return null;
		const foundProfile = this.pageInfo.profiles.find(el => el.value == type);
		return foundProfile ? foundProfile.label : null;	
	}
	restartLastRow(){
		this.pageInfo.lastRowOffers	=	{
			id          : null,
			editMode    : false,
			startTime   : null,
			endTime     : null,
			type        : {},
			value       : null,
			profiles    : ['adult','children','infant'],
			turns       : []
		}
	}
	saveLastRow(){
		const validatedRow 	= this.validateRow(this.pageInfo.lastRowOffers);
		if(validatedRow){
			this.pageInfo.lastRowOffers.value 		= this.pageInfo.lastRowOffers.value['code'] ? this.pageInfo.lastRowOffers.value['code'] : this.pageInfo.lastRowOffers.value; 
			this.pageInfo.lastRowOffers.startTime 	= this.commons.getFormatDateHour(this.pageInfo.lastRowOffers.startTime)
			this.pageInfo.lastRowOffers.endTime		= this.commons.getFormatDateHour(this.pageInfo.lastRowOffers.endTime);	
			const sameValue							= this.day.offers.some(el => this.compareExecs(el,this.pageInfo.lastRowOffers));
			if(sameValue){ this.commons.generateToast('_ERROR','_OFFER_ALREADY_EXISTS','error'); return;}
			this.emiterOption.emit({type:'lastRow', data : {daySelected : this.day, exec : JSON.parse(JSON.stringify(this.pageInfo.lastRowOffers))}});
			this.restartLastRow();
		}
	}

	saveLastRowKeyUp($event){
		if($event.keyCode != 13 && $event.code != 'Enter') return;
		this.saveLastRow();
	}
	compareExecs(el, offerCopied){
		console.log('COMPARE');
		const offerDay 		= 	JSON.parse(JSON.stringify(el));
		const offerToCopy	=	JSON.parse(JSON.stringify(offerCopied));
		delete offerDay.id;
		delete offerDay.editMode;
		delete offerToCopy.id;
		delete offerToCopy.editMode;
		return JSON.stringify(offerDay) == JSON.stringify(offerToCopy);
	}
	onSelectStartHour($event){

		if(this.startHour.currentMinute == 59){
			this.endHour.currentHour = this.startHour.currentHour == 23 ? 0 : this.startHour.currentHour +1;
			this.endHour.currentMinute = 0
		}else{
			this.endHour.currentHour = this.startHour.currentHour;
			this.endHour.currentMinute = this.startHour.currentMinute + 1;
		}  
	}
}