export const filters = [
	{
		entity	: 'bookings',
		name	: 'shared',
		label	: '_VEHICLE_SERVICE',
		type	: 'multiple',
		field	: 'shared',
		icon	: 'share-alt',
		items	: [
			{ label: '_SHARED',		value: 'shuttle'	},
			{ label: '_PRIVATE',	value: 'private' 	}
		],
		selected: [ "shuttle", "private" ]
	},
	{
		entity	: 'bookings',
		name	: 'verified',
		label	: '_VERIFIED',
		type	: 'multiple',
		field	: 'verified',
		icon	: 'check',
		items	: [
			{ label: '_YES',	value: 'yes' 	},
			{ label: '_NO',		value: 'no' 	}
		],
		selected: [ "yes", "no" ]
	},
	{
		entity	: 'bookings',
		name	: 'status',
		label	: '_STATUS',
		type	: 'multiple',
		field	: 'status',
		icon	: 'list',
		items	: [
			{ label: '_ORIGINAL', 	value: 'OR' },
			{ label: '_CX', 		value: 'CX' },
			{ label: '_AMMENDED', 	value: 'AM' }
		],
		selected: [ "OR", "CX", "AM" ]
	},
	{
		entity	: 'bookings',
		name	: 'direction',
		label	: '_DIRECTION',
		type	: 'multiple',
		field	: 'direction',
		icon	: 'arrows',
		items	: [
			{ label: '_BOTH', 		value: 'both' 		},
			{ label: '_ARRIVAL', 	value: 'arrival' 	},
			{ label: '_DEPARTURE', 	value: 'departure' 	}
		],
		selected: [ "both", "arrival", "departure" ]
	},
	{
		entity	: 'bookings',
		name	: 'error',
		label	: '_ERRORS',
		type	: 'multiple',
		field	: 'error',
		icon	: 'hand-stop-o',
		items	: [
			{ label: '_YES', 	value: 'yes' 		},
			{ label: '_NO',		value: 'no'		 	}
		],
		selected: [ 'yes', 'no' ]
	}
];