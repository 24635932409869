import { CommonsService 			} from '../commons.service';
import { Injectable               	} from '@angular/core';
import { FlightService 				} from '../flight.service';
import { GoogleService 				} from '../google/google.service';
import { LodgingService 			} from '../lodging/lodging.service';
import { BookingsService 			} from '../bookings/bookings.service';
import { FirebaseService 			} from './firebase.service';

@Injectable()
export class CheckService {

	public flightChecker	: any;
	public checks			: any;
	public rowData			: any;
	public base				: any;
	public item				: any 		= {};
	public pageInfo			: any		= {};
	public aliases			: any		= { lodgings: [], flights: [], vehicles: [] };
	
  	constructor(
		private commons				: CommonsService,
		public 	flightService		: FlightService,
		private googleService		: GoogleService,
		private lodgingService		: LodgingService,
		private bookingsService		: BookingsService,
		private firebaseCtrl		: FirebaseService
	){
		this.pageInfo = {
			lodging_alias		: [],
			flight_alias		: []
		};
	}

	init($params){
		this.flightChecker 	= $params["flightChecker"];
		// this.checks			= $params["checks"];
		this.base			= $params["base"];
	}

	setRowData($rowData){
		this.rowData = $rowData;
	}

	// -----------------------------------------------------------------------------------
	// CHECKING FIELD METHODS
	// -----------------------------------------------------------------------------------

	/**
	 * Open panel to check field
	 * 
	 * @param $type 
	 * @param $item 
	 * @param $data 
	 * @returns 
	 */
	 public checkField($params) {
		let $verify	= $params["verify"] || false;
		let $checks = $params["checks"	];
		let $type	= $params["type"	]; 
		let $item	= $params["item"	];
		let $data	= $params["data"	];

		switch ($type) {
			case "check"		:	if ($verify && $data.verified == "yes") {	
										this.commons.generateToast("_ERROR", "_BOOKING_ALREADY_VERIFIED", "error");	
										return false;	
									}
									if (undefined !== $checks[$item.check]) {
										this.item 			= $item;
										this.rowData 		= $data;
										this.rowData.base 	= this.base;
					
										// Checker direction ( arrival or departure ), not confuse with booking direction ( arrival, dep or both )
										if ($item.field.includes("arrival_")	) { this.rowData.checkFieldDirection = "arrival"; 	}
										if ($item.field.includes("departure_")	) { this.rowData.checkFieldDirection = "departure"; }

										if(undefined!==this.flightChecker){
											this.flightChecker.reloadData($item);
										}										
									}
									break;

			case "reload"		:	$data[$item.field] = $data["original_" + $item.field]; break;

			case "autoFixIcon"	: 	if ($data.fixes[$item.field] && $data.fixes[$item.field].action) {	
										return 'fa fa-' + $data.fixes[$item.field].icon;		
									} else {
										let hasError = this.checkFieldError($item, $data) && undefined !== $item.autoFixIcon;
										return hasError ?'fa fa-' + $item.autoFixIcon:'';	
									}
			
			default				:	return;
		}
	}

	/**
	 * Fix field
	 * 
	 * @param $item 
	 * @param $data 
	 * @param singleCorrection 
	 * @returns 
	 */
	public async fixField($item, $data, singleCorrection?) {
		
		if ($data.fixes[$item.field] && $data.fixes[$item.field].action) {
			let item = $data.fixes[$item.field];
			switch (item.action) {
				case 'displayOptionAlias': item.action = 'setAlias'; return;
				default: return;
			}
		}
		// if no, proceed fixing and verifying
		if (this.checkFieldError($item, $data)) { return; }
		await this.fixItem($item, $data, singleCorrection);
	}

	/**
	 * cancel fix field
	 * 
	 * @param $item 
	 * @param $data 
	 * @returns 
	 */
	public cancelFixField($item, $data) {
		if (!$item || !$data) { return; }
		let item = $data.fixes[$item.field]
		switch (item.action) {
			case 'setAlias': item.action = 'displayOptionAlias'; return;
			default: return;
		}
	}

	/**
	 * handle fix field
	 * 
	 * @param $item 
	 * @param $data 
	 * @param $inputValue 
	 * @returns 
	 */
	public async handleFixAction($params) {
		let $item 		= $params["item"];
		let $data 		= $params["rowData"];
		let $inputValue = $params["action"];

		if (!$item || !$data) { return; }

		let item = $data.fixes[$item.field];
		
		switch (item.action) {
			case 'setAlias'	:	let aliasValue = document.getElementById($inputValue)['value'];
								this.aliases.lodging[aliasValue] = $data[$item.field];
								// Update on firebase aliaes
								await this.firebaseCtrl.updateItemByRef(this.bookingsService.serviceInfo.destinationRef, { lodging_alias: this.aliases.lodging })
								delete $data['fixes'][$item.field];
								break;
		}
	}

	/**
	 * display fix action 
	 * 
	 * @param $item 
	 * @param $data 
	 * @returns 
	 */
	public displayFixAction($item, $data) {
		switch (true) {
			case ['arrival_Location', 'departure_Location'].some(field => field == $item['field']):
				if (!$data['fixes'] || !$data['fixes'][$item.field]) { return false; }
				return $data['fixes'][$item.field].action != 'displayOptionAlias';
			default: return false;
		}
	}

	private checkFieldError($item, $data) {
		switch ($item.field) {
			case "arrival_GatewayInfo"	: return !this.flightService.checkFlight($data[$item.field]).error;
			case "departure_GatewayInfo": return !this.flightService.checkFlight($data[$item.field]).error;
			case "location"				: return false;
			case "arrival_Location"		: return false;
			case "departure_Location"	: return false;
			case "arrival_AddressInfo"	: return false;
			case "departure_AddressInfo": return false;
			case "addressInfo"			: return false;
			default: return true;
		}
	}

	private async fixItem($item, $data, singleCorrection) {
		const addressFixes 	= [	'location', 
								'arrival_Location', 
								'departure_Location', 
								'arrival_AddressInfo', 
								'departure_AddressInfo'
							];
		const flightFixes 	= [	'arrival_GatewayInfo', 
								'departure_GatewayInfo'
							];
		if (!$item || !$data) { return this.commons.generateToast('_ERROR', '_NO_DATA', 'error'); }
		
		switch (true) {
			default: return singleCorrection ? this.commons.generateToast("_FIX", "_COULD_NOT_FIX_ITEM", "error") : null;
			case flightFixes.some(item => item == $item.field): this.flightService.fixFlight($data[$item.field]); break;
			case addressFixes.some(item => item == $item.field): await this.bookingsService.fixLocation($item, $data, singleCorrection); break;
		}
	}

	/**
	 * Try to solve address info
	 * 
	 * @param item 
	 * @param booking 
	 * @param singleCorrection 
	 * @returns 
	 */
	// async firstAttempAddressInfo(item, booking, singleCorrection) {
	// 	if (!booking.errors && !booking.warnings) { return {}; }
	// 	let fieldLocationError = (booking.errors || []).find(el => el.field == 'location');
	// 	if (!fieldLocationError || fieldLocationError.type == "_BAD_FORMAT") {
	// 		let fieldAddressError = (booking.errors || []).find(el => el.field == 'addressInfo');
	// 		if (!fieldAddressError) { return; }
	// 		await this.bookingsService.fixLocation({ field: 'location' }, this.rowData, singleCorrection);
	// 		let updatedLocationError = (booking.errors || []).find(el => el.field == 'location');
	// 		let updatedAddressError = (booking.errors || []).find(el => el.field == 'addressInfo');
	// 		if (updatedLocationError || updatedAddressError) {
	// 			await this.fixAddressInfo(item, booking, singleCorrection);
	// 		}
	// 	} else {
	// 		await this.fixAddressInfo(item, booking, singleCorrection);
	// 	}
	// }

	/**
	 * Fix address info
	 * 
	 * @param item 
	 * @param booking 
	 * @param singleCorrection 
	 * @returns 
	 */
	// async fixAddressInfo(item, booking, singleCorrection) {
	// 	booking[item['field']] = this.lodgingService.fixFormatText(booking[item['field']]);
	// 	if (booking[item['field']] == null || booking[item['field']] == '') { 
	// 		return singleCorrection ? this.commons.generateToast('_ERROR', '_EMPTY_FIELD', 'error') : null; 
	// 	}
	// 	let suggestions = await this.googleService.getPlacePredictions(booking[item['field']]);

	// 	if (suggestions.success) {
	// 		let infoPlace = await this.googleService.getDetailsFromPlace(suggestions.data[0].place_id);
	// 		if (infoPlace.success) {
	// 			let nearbyData = await this.googleService.getNearbyData({
	// 				lat: infoPlace.data.geometry.location.lat(),
	// 				lng: infoPlace.data.geometry.location.lng()
	// 			})

	// 			console.log('NEARBY DATA PLACES', nearbyData);
	// 			if (nearbyData.success) { }
	// 		}
	// 		if (suggestions.data.length == 1) {
	// 		} else { }
	// 	}
	// }
}