import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { items 				} from './data/items';
import { EntityService } from 'src/app/demo/service/entity.service';

@Component({
  selector    	: 'app-tour-wizard-services',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class TourWizardServicesComponent {

	@Input("type")      type	:string;	
	@Input("info")		info	:any	= {};
	
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {}
	draggedItem			: any = {};
	
	  
	constructor( 
		private commons			: CommonsService,
		private entityService   : EntityService	
  	) {}

	ngOnInit()					{
		this.info.services	= this.info.services || { included: [], notIncluded: [] };
	}
	
	action($type,$item){		
		switch($type){			
		}
	}

	dragStart($event,item) 		{ this.draggedItem 	= { item: item }; 	}
	dragEnd($event) 			{ this.draggedItem = null; 				}	
    drop(group,$event) 			{		
		switch(group){
			case "included"		: 
				if(this.info.services.included.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_SERVICE_ALREADY_INCLUDED","info");
					return false;
				}
				if(this.info.services.notIncluded.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_NOT_INCLUDED","error");
					return false;
				}				
				this.info.services.included.push(this.draggedItem.item);
				break;
			case "notIncluded"	: 
				if(this.info.services.notIncluded.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_NOT_INCLUDED","info");
					return false;
				}
				if(this.info.services.included.some(item=>item.id==this.draggedItem.item.id)){
					this.commons.generateToast("_ERROR","_ITEM_ALREADY_INCLUDED","error");
					return false;
				}
				this.info.services.notIncluded.push(this.draggedItem.item); 	
				break;
		}
	}

	remove($type,$item){
		switch($type){
			case "included"		: this.info.services.included 		= this.info.services.included.filter(item=>item.id!=$item.id); 	break;
			case "notIncluded"	: this.info.services.notIncluded	= this.info.services.notIncluded.filter(item=>item.id!=$item.id); 	break;
		}
	}

	getFilteredEntity($entity){
		switch($entity){
			case "services"		: 	return this.info.entities[$entity].filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
		}
	}
}
