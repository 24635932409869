import { pictures } from './data/pictures';
import { EntityService 			} from './../../../../../demo/service/entity.service';
import { FirebaseService 		} from 'src/app/demo/service/database/firebase.service';
import { DragulaService 		} from 'ng2-dragula';
import { Component, 
         Input, 
         Output, 
         EventEmitter,		
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { videos 				} from './data/videos';
import { audios 				} from './data/audios';

let createPlayer = require('web-audio-player');
@Component({
  selector    		: 'app-tour-wizard-resources',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class TourWizardResourcesComponent {

	@Input("type")      type	:string	= "";
	@Input("info")		info	:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  			: any = {};
	draggedItem			: any = {};
	audio				: any = {};
	
	constructor(
		private commons			: CommonsService, 
		private entityService	: EntityService,
		private dragulaCtrl 	: DragulaService,
		private firebase		: FirebaseService
	){ 
		this.init(); 
	}	

	init()
	{
		this.pageInfo.tabs		= {
			items 			: [
				{ id:0, label: this.commons.getTranslate('_PICTURES'),	icon : 'fa fa-picture-o' 	, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:1, label: this.commons.getTranslate('_VIDEOS'	), 	icon : 'fa fa-video-camera' , command: ($event)=> { this.setTabItem($event)} 	},
				{ id:2, label: this.commons.getTranslate('_AUDIOS'	), 	icon : 'fa fa-volume-up' 	, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:3, label: this.commons.getTranslate('_MAP'		), 	icon : 'fa fa-map-marker' 	, command: ($event)=> { this.setTabItem($event)} 	}
			],
			selected		: 0
		};

		this.pageInfo.selected 	= [];
	
		this.pageInfo.pictures	= {
			view			: "list",
			uploadedFiles	: [],
			buttons 		: [
				{ 	name: 'new_picture',	label: '_NEW_PICTURE', 		icon: 'fa fa-plus' 			, type: 'button' 	},
				{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	},
				{ 	name: 'import',			label: '_IMPORT', 			icon: 'fa fa-upload' 		, type: 'upload'	}
			],
			features		: [
				{	value: 'active', 		icon: 'fa fa-check'				},
				{	value: 'default', 		icon: 'fa fa-heart'				},
				{	value: 'position', 		icon: 'fa fa-map-marker'		},
				{	value: 'big', 			icon: 'fa fa-desktop'			},
				{	value: 'small', 		icon: 'fa fa-mobile'			},
				{	value: 'grayscale', 	icon: 'fa fa-paint-brush'		}				
			],
			// items			: []		
		};
		
		this.pageInfo.videos	= {			
			uploadedFiles	: [], 
			buttons 		: [
				{ 	name: 'new_video',		label: '_NEW_VIDEO', 		icon: 'fa fa-plus' 			, type: 'button' 	},
				{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	},
				{ 	name: 'import',			label: '_IMPORT', 			icon: 'fa fa-upload' 		, type: 'upload'	}
			],
			langs	: {
				items	: [
					{ id: 1, 	code: "es", title: "_LANG_ES" 	},
					{ id: 2, 	code: "uk", title: "_LANG_UK" 	},
					{ id: 3, 	code: "de", title: "_LANG_DE" 	},
					{ id: 4, 	code: "fr", title: "_LANG_FR" 	},
					{ id: 5, 	code: "it", title: "_LANG_IT" 	}
				]
			},
			items	: videos,
			features: [
				{	value: 'active', 		icon: 'fa fa-check'				},
				{	value: 'default', 		icon: 'fa fa-heart'				},
				{	value: 'position', 		icon: 'fa fa-map-marker'		},
				{	value: 'big', 			icon: 'fa fa-desktop'			},
				{	value: 'small', 		icon: 'fa fa-mobile'			},
				{	value: 'grayscale', 	icon: 'fa fa-paint-brush'		}				
			],			
		};

		this.pageInfo.audios	= {			
			uploadedFiles	: [], 
			buttons 		: [
				{ 	name: 'new_audio',		label: '_NEW_AUDIO', 		icon: 'fa fa-plus' 			, type: 'button' 	},
				{ 	name: 'export',			label: '_EXPORT', 			icon: 'fa fa-download' 		, type: 'button'	},
				{ 	name: 'import',			label: '_IMPORT', 			icon: 'fa fa-upload' 		, type: 'upload'	}
			],
			langs	: {
				items	: [
					{ id: 1, 	code: "es", title: "_LANG_ES" 	},
					{ id: 2, 	code: "uk", title: "_LANG_UK" 	},
					{ id: 3, 	code: "de", title: "_LANG_DE" 	},
					{ id: 4, 	code: "fr", title: "_LANG_FR" 	},
					{ id: 5, 	code: "it", title: "_LANG_IT" 	}
				]
			},
			items	: audios
		}
	}

	setTabItem($event){	this.pageInfo.tabs.selected = $event.item.id;	}

  	ngOnInit()					{
		this.pageInfo.map				= { zoom: 10, lat: 39.639938, lng: 2.894679 };
		this.pageInfo.cols				= this.info.resources.cols || 4;

		this.pageInfo.pictures.features	= this.info.resources.features 	|| this.pageInfo.pictures.features;
		this.info.pictures				= this.info.pictures 			|| [];		
		this.pageInfo.videos.features	= this.info.resources.features 	|| this.pageInfo.videos.features;
		this.info.videos				= this.info.videos 				|| [];		
		this.pageInfo.audios.features	= this.info.resources.features 	|| this.pageInfo.audios.features;
		this.info.audios				= this.info.audios 				|| [];
		
		this.checkResourcesDefault("pictures");
		// this.checkResourcesDefault("videos");
		// this.checkResourcesDefault("audios");
	}

	ngOnDestroy() 				{}

	/**
	 * check for default element
	 * @param $type 
	 */
	checkResourcesDefault($type){		
		// this.pageInfo[$type].items.forEach(item=>{
		if(undefined===this.info[$type] || this.info[$type].length==0){ return false; }
		
		// Set default favorite if not
		if(undefined===this.pageInfo[$type].default){
			const findDefault 			= this.info[$type].findIndex(item=>item.features.some(feature=>feature=="default"));
			this.pageInfo[$type].default= this.info[$type][findDefault>0?findDefault:0].id;
		}
		
		// Set just one default into items features list 
		this.info[$type].forEach(item=>{
			item.features = item.features || [];
			if(item.id==this.pageInfo[$type].default){ 
				// Add favorite feature
				item.features.push("default");	
			} else { 
				// Remove favorite feature
				item.features = item.features.filter(feature=>feature!="default");	
			}
		});
	}

	getProvisionalId(a):number		{ return a.reduce((acc,item)=>acc>item.id?acc:(item.id+1),1); }

	// ACTIONS
    async action($type,$item,$event){
		switch($type){
			case "editor"				:	switch($event.action){
												case "close"	:	this.checkResourcesDefault("pictures");
																	this.pageInfo.pictures.view 	= "list";	
																	break;
												case "save"		:	if($event.detail!=""){ 
																		await this.saveCropped($event,$item); 
																	} else {
																		this.commons.generateToast("_ERROR","_CROP_IMAGE_FIRST","error");
																		return false;
																	}
																	$item.new 						= undefined;
																	this.checkResourcesDefault("pictures");
																	this.pageInfo.pictures.view 	= "list";
																	break;
											}
											break;

			case "new_picture"			:	this.info.pictures.push({ 	
																		new			: true,
																		id 			: this.getProvisionalId(this.info.pictures),
																		order		: (this.info.pictures.length+1), 
																		name		: this.commons.getTranslate('_NEW_PICTURE'),
																		tags		: [],
																		features	: ["active"],
																		url			: "/assets/layout/images/sights/no_sight.jpg"
																	});
											this.pageInfo.pictures.current 	= this.info.pictures[this.info.pictures.length-1];
											this.pageInfo.pictures.view 	= "editor";
											break;
									
			case "deletePicture"		:	this.deletePicture($item);
											break;

			case "viewPicture"			:	if(this.info.resources.editable!=true){ return false; }
											this.pageInfo.pictures.current 	= $item; 
											this.pageInfo.pictures.view 	= "editor";
											break;

			case "toggleLangVideo"		: 	if(this.pageInfo.videos.langs.selected==$item.code) { return; }
											this.pageInfo.videos.langs.items.forEach(item=>item.selected=false);										
											$item.selected 							= true;
											this.pageInfo.videos.langs.selected 	= $item.code;									
											break;
			
			case "toggleLangAudio"		: 	if(this.pageInfo.audios.langs.selected==$item.code) { return; }
											this.pageInfo.audios.langs.items.forEach(item=>item.selected=false);										
											$item.selected 							= true;
											this.pageInfo.audios.langs.selected 	= $item.code;									
											break;			

			case "imageFeaturesClick"	:	console.log("Event",$event);
											if($item.features.some(feature=>feature=='default')){
												this.pageInfo.pictures.default = $item.id;												
											}
											this.checkResourcesDefault("pictures");
											break;
			
			case "videoFeaturesClick"	:	console.log("Event",$event);
											if($item.features.some(feature=>feature=='default')){
												this.pageInfo.videos.default = $item.id;												
											}
											this.checkResourcesDefault("videos");
											break;

			case "toggleAudio"			:	if ($item.status=="play"){ this.audioCtrl("pause",$item); } else { this.audioCtrl("play",$item); }; break; 
		}
	}

	async deletePicture($item)							{

		if(this.info.resources.editable == true ){
			let response = await Promise.resolve(this.entityService.delete(this.entityService.getUrl("resource"),{ id: $item.id }));
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR","_ERROR_DELETING","error");
				return false;
			}
		}
		this.info.pictures 				= this.info.pictures.filter(item=>item.id!=$item.id);
		this.pageInfo.pictures.default	= undefined;
		this.checkResourcesDefault("pictures");										
	}

	// IMAGE HANDLING

	removeBase64Header(str: string, type): string 	{	return str.substring(13+parseInt(type.length));		}
	base64toBlob(base64Data: any, contentType: any) {
		contentType 			= contentType || '';
		const sliceSize 		= 1024;
		const byteCharacters 	= atob(base64Data);
		const bytesLength 		= byteCharacters.length;
		const slicesCount 		= Math.ceil(bytesLength / sliceSize);
		const byteArrays 		= new Array(slicesCount);
	
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++ sliceIndex) {
			const begin 			= sliceIndex * sliceSize;
			const end 				= Math.min(begin + sliceSize, bytesLength);
			const bytes 			= new Array(end - begin);
			for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) { bytes[i] = byteCharacters[offset].charCodeAt(0); }
			byteArrays[sliceIndex] 	= new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	async saveCropped($item,$target)									{
		const imageFormat	= "image/png";
		const extension		= "png";
		const ref 			= this.firebase.afStorage.storage.ref(`dmcs/1/sights/pictures`).child((new Date()).getTime().toString()+"."+extension);
		const str			= this.removeBase64Header($item.detail,imageFormat);
		let response		= await Promise.resolve(ref.putString(str, "base64", { contentType: imageFormat }));
		$target.url			= await Promise.resolve(ref.getDownloadURL());		
	}

	audioCtrl($action,$item){
		switch($action){
			case "play":
				$item.status 		= "play";
				if(this.audio.mp3!=$item.mp3){
					this.audio.mp3					= $item.mp3;
					this.audio.player				= createPlayer(this.audio.mp3);
					this.audio.player.crossOrigin 	= "anonymous";			
					this.audio.player.on('load',()=>{ 
						console.log("Loaded mp3 audio "+this.audio.mp3);
						$item.durationSec 	= this.audio.player.duration;
						$item.duration		= Math.floor($item.durationSec/60)+"'"+Math.round($item.durationSec%60)+'"';
						this.audio.player.play();
						this.audio.player.node.connect(this.audio.player.context.destination);
					});
					this.audio.player.on("end",()=>{
						console.log("Audio ended "+this.audio.mp3);
						$item.status	= "pause";
					})
				} else {
					if( undefined!==this.audio.player ){ this.audio.player.play(); }
				}			
				break;
			case "pause":
				$item.status		= "pause";
				this.audio.player.pause();
				break;
		}
	}
	
	remove($type,$item){
		switch($type){			
		}
	}

	dragStart(event,item) 		{ this.draggedItem = item;	}
	dragEnd(event) 				{ this.draggedItem = null; 	}
	
	drop(group,event) {
		switch(group){
			case "reorder"		:
				// Ensure items has an order
				this.info.pictures.reduce((i,item)=>{ item.order = item.order || i++; return i; },1);
				
				if(this.draggedItem.order==event.order){ return false; }
				let tmp						= this.draggedItem.order;
				this.draggedItem.order 		= event.order;
				event.order					= tmp;
				this.info.pictures			= this.info.pictures.sort((a,b)=>a.order>b.order?1:-1);
				break;
		}
	}    
}