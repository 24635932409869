import { Component, Input } from '@angular/core';

/**
 * Generated class for the BookingVoucherComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-booking-voucher',
  templateUrl: './booking-voucher.component.html',  
  styleUrls   : ['./booking-voucher.component.scss']
})
export class BookingVoucherComponent {

  @Input() voucher : any = {};
  constructor() {
  }

}
