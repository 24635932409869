import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';


@Component({
    selector   : 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls:['./legend.component.scss'] ,
    encapsulation: ViewEncapsulation.None,
})

export class LegendComponent implements OnInit{

    
    ngOnInit(){
    }
}