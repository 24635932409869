export const info 		= {
    pathStar 			 : "assets/demo/images/maps/starHotel.png",
    pathBus              : "assets/demo/images/maps/busMap.png",
    pathShuttle          : "assets/demo/images/maps/shuttleMap.png",
    pathApartment        : "assets/demo/images/maps/apartmentMap.png",
    pathHotel            : "assets/demo/images/maps/hotelMap.png",
    pathHotelCurrent     : "assets/demo/images/maps/hotelMapCurrent.png",
    pathApartmentCurrent : "assets/demo/images/maps/apartmentMapCurrent.png",
    pathInfoMarker	 	 : "assets/demo/images/maps/infoMarker.png",
    hotels               : {
                 marker1 : "assets/demo/images/maps/marker1.png",
                 marker2 : "assets/demo/images/maps/marker2.png",
                 marker3 : "assets/demo/images/maps/marker3.png",
                 marker4 : "assets/demo/images/maps/marker4.png",
                 marker5 : "assets/demo/images/maps/marker5.png"
        },
    
    airport  : {
                    pathAirport : "assets/demo/images/maps/airportMap.png",
                    coords 		: {
                        lat	: 39.549655, 
                        lng : 2.734465
                    }
    }
};