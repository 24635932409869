export const filters = {
	arrivalTransfers : [
		{
			name	: 'status',
			label	: '_STATUS',
			type	: 'multiple',
			field	: 'zones',
			icon	: 'file',		
			items	: [
				{ label: 'Transit', 	value: '_TRANSFER_ON_TRANSIT' 	},
				{ label: 'Waiting', 	value: '_TRANSFER_WAITING' 		},
				{ label: 'On route', 	value: '_TRANSFER_ON_ROUTE' 	},
				{ label: 'Finished', 	value: '_TRANSFER_FINISHED' 	}			
			],
			selected: [ 
				'_TRANSFER_ON_TRANSIT', 
				'_TRANSFER_WAITING', 
				'_TRANSFER_ON_ROUTE', 
				'_TRANSFER_FINISHED' 
			]
		},
		{
			name	: 'type',
			label	: '_TYPE',
			type	: 'multiple',
			field	: 'types',
			icon	: 'file',		
			items	: [
				{ label: 'Shuttle', 		value: 'SH' },
				{ label: 'Speedy shuttle', 	value: 'SP'	},
				{ label: 'Private', 		value: 'PR' }	
			],
			selected: [ 'SH', 'SP', 'PR' ]
		}
	],
	departureTransfers : [
		{
			name	: 'status',
			label	: '_STATUS',
			type	: 'multiple',
			field	: 'zones',
			items	: [
				{ label: 'Transit', 	value: '_TRANSFER_ON_TRANSIT' 	},
				{ label: 'Waiting', 	value: '_TRANSFER_WAITING' 		},
				{ label: 'On route', 	value: '_TRANSFER_ON_ROUTE' 	},
				{ label: 'Finished', 	value: '_TRANSFER_FINISHED' 	}			
			],
			selected: [ 
				'_TRANSFER_ON_TRANSIT', 
				'_TRANSFER_WAITING', 
				'_TRANSFER_ON_ROUTE', 
				'_TRANSFER_FINISHED' 
			]
		},
		{
			name	: 'type',
			label	: '_TYPE',
			type	: 'multiple',
			field	: 'types',
			items	: [
				{ label: 'Shuttle', 		value: 'SH' },
				{ label: 'Speedy shuttle', 	value: 'SP'	},
				{ label: 'Private', 		value: 'PR' }	
			],
			selected: [ 'SH', 'SP', 'PR' ]
		}
	],
	bookings : [{
		entity	: 'customers',
		name	: 'directions',
		label	: 'Directions',
		type	: 'multiple',
		field	: 'direction',
		items	: [
			{ label: 'Arrival', 	value: 'arrival' 		},
			{ label: 'Departure', 	value: 'departure' 		}
		],
		selected: [ 'arrival', 'departure' ]
	}],
	arrivals : [{
		entity	: 'customers',
		name	: 'status',
		label	: '_STATUS',
		type	: 'multiple',
		field	: 'status',
		icon	: 'check',
		items	: [
			{ label: 'Unknown', 	value: '_STATUS_UNKNOWN'		 	},
			{ label: 'Noshow', 		value: '_ARRIVAL_NOSHOW' 			},
			{ label: 'Gate', 		value: '_ARRIVAL_GATE' 				},
			{ label: 'Belt', 		value: '_ARRIVAL_BELT' 				},
			{ label: 'Meeting', 	value: '_ARRIVAL_MEETING_POINT' 	},
			{ label: 'Waiting', 	value: '_ARRIVAL_TRANSFER_WAITING'	},
			{ label: 'Route', 		value: '_ARRIVAL_ON_ROUTE' 			},
			{ label: 'Arrived', 	value: '_ARRIVAL_ARRIVED' 			}
		],
		selected: [ 
			'_STATUS_UNKNOWN',
			'_ARRIVAL_NOSHOW', 
			'_ARRIVAL_GATE', 
			'_ARRIVAL_BELT', 
			'_ARRIVAL_MEETING_POINT', 
			'_ARRIVAL_TRANSFER_WAITING',
			'_ARRIVAL_ON_ROUTE',
			'_ARRIVAL_ARRIVED'
		]
	}],
	departures : [{
		entity	: 'customers',
		name	: 'status',
		label	: '_STATUS',
		type	: 'multiple',
		field	: 'status',
		icon	: 'check',
		items	: [
			{ label: 'Unknown', 	value: '_STATUS_UNKNOWN'		 		},			
			{ label: 'Noshow', 		value: '_DEPARTURE_NOSHOW' 				},
			{ label: 'Waiting', 	value: '_DEPARTURE_TRANSFER_WAITING'	},
			{ label: 'On Route', 	value: '_DEPARTURE_ON_ROUTE' 			},
			{ label: 'Arrived', 	value: '_DEPARTURE_ARRIVED' 			},
			{ label: 'Gate', 		value: '_DEPARTURE_GATE'				}			
		],
		selected: [ 
			'_STATUS_UNKNOWN',			
			'_DEPARTURE_NOSHOW',
			'_DEPARTURE_TRANSFER_WAITING',
			'_DEPARTURE_ON_ROUTE',
			'_DEPARTURE_ARRIVED',
			'_DEPARTURE_GATE'
		]
	}],
	notifications : [{
		entity	: 'notification',
		name	: 'notification',
		label	: 'Notification',
		type	: 'multiple',
		field	: 'notification',
		items	: [
			{ label: 'Notification', 	value: 'notification' 	},
			{ label: 'Issue', 			value: 'issue' 			}
		],
		selected: [ 'notification', 'issue' ]
	}],
	issues : [{
		entity	: 'issue',
		name	: 'issue',
		label	: '_TYPE',
		type	: 'multiple',
		field	: 'type',
		items	: [
			{ label: 'Customer', 		value: 'customer' 		},
			{ label: 'Rep', 			value: 'rep' 			},
			{ label: 'Driver', 			value: 'driver' 		},
			{ label: 'Lodging', 		value: 'lodging' 		}
		],
		selected: [ 'customer', '_rep', '_driver', '_lodging' ]
	}],
	flights : [{
		entity	: 'flight',
		name	: 'flight',
		label	: '',
		type	: 'multiple',
		field	: 'type',
		items	: [
			{ label: 'Arrival', 		value: 'arrival' 		},
			{ label: 'Departure', 		value: 'departure' 		}
		],
		selected: [ 'arrival', 'departure' ]
	}],
};