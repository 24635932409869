import { FieldsetModule } from 'primeng/primeng';
import { DragulaService 	} from 'ng2-dragula';
import { Component, 
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';

let createPlayer = require('web-audio-player');
@Component({
  selector    	: 'app-tour-wizard-info',
  styleUrls		: ['./component.scss'],
  templateUrl 	: 'component.html'  
})
export class TourWizardInfoComponent {

	@Input("type")      type	:string	= "";
	@Input("info")		info	:any;
	@Output()			emitter			= new EventEmitter<any>();	
		
	pageInfo  			: any 	= {};
	draggedItem			: any 	= {};	
	
	constructor( public commons: CommonsService ){ this.init(); }

	init(){		
		this.pageInfo.results	= [];		
	}

	ngOnInit()					{
		this.info.item			= this.info.info;
		this.pageInfo.form 		= undefined!=this.info.forms?this.info.forms["info"]:[];
		this.pageInfo.entities	= this.info.entities;
	}

	ngOnDestroy() 				{}

	getStatusIcon(item){
		if(item.mandatory){
			if(undefined==this.info.item[item.field] || this.info.item[item.field]==""){ return "fa fa-ban"; }
			return "fa fa-check";
		}
		return "";
	}

	getStatusColor(item){
		if(item.mandatory){
			if(undefined==this.info.item[item.field] || this.info.item[item.field]==""){ return "crimson"; }
			return "forestgreen";
		}
		return "cadetblue";
	}

	search(item,$event) {
		console.log("ITEM",item,"EVENT",$event);
		if(undefined==item.entityList){ return false; }
		this.pageInfo.results =  this.pageInfo.entities[item.entityList].filter(item => item.name.includes($event.query));
	}	

    async action($type,$item,$extra){
		switch($type){
			case "toggleItem"		: 	let isSelected = this.pageInfo.selected.some(item=>item.id==$item.id);
										if(isSelected) {
											$item.selected 			= false;
											this.pageInfo.selected 	= this.pageInfo.selected.filter(item=>item.id!=$item.id);
										} else {
											$item.selected 			= true;
											$item.position			= this.pageInfo.selected.length + 1;
											this.pageInfo.selected	= [ ...this.pageInfo.selected, $item ];
										}
										break;
			case "toggleItemField"	:	switch($extra){
											case "type":	$item.editing 	= $item.editing==true?false:true; break;
										}
										break;
			case "setItemField"		:	switch($extra){
											case "type":	$item.type 		= $item.optionSelected;
															$item.editing	= false;
															break;
										}
										break;
			case "showInfo"			: 	if($item.info){ this.commons.generateToast("_INFO",$item.label+"_INFO","info"); } break;

			case "getSuggestions"	:	return this.pageInfo.entities[$item.entityList].map(item=>{ item.label = this.commons.getTranslate(item.label); return item });										
		}
	}

	remove($type,$item){
		switch($type){			
		}
	}

	dragStart(event,item) 		{ this.draggedItem = item;	}
	dragEnd(event) 				{ this.draggedItem = null; 	}
	
	drop(group,event) {
		switch(group){
			case "reorder"		: 
				if(this.draggedItem.position==event.position){ return false; }
				let tmp						= this.draggedItem.position;
				this.draggedItem.position 	= event.position;
				event.position				= tmp;
				this.pageInfo.selected		= this.pageInfo.selected.sort((a,b)=>a.position>b.position?1:-1);
				break;
		}
	}    
}
