import { Component, 
         Input, 
         Output, 
         EventEmitter,      	
		 ViewEncapsulation		} from '@angular/core';
import { CommonsService 		} from 'src/app/demo/service/commons.service';
import { buttons 				} from './data/buttons';
import { filters 				} from './data/filters';
import { EntityService 			} from 'src/app/demo/service/entity.service';
import { providerCols 			} from './data/columns';
import { detailItems, entities 	} from './../detail/data/items';

@Component({
  selector    		: 'app-transporter-conciliation',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'  
})
export class TransporterConciliationComponent {

  	@Input("current")   currentProvider:any	= {};
	@Output()			emitter			= new EventEmitter<any>();	
	
	pageInfo  : any = {
		currency		: "€",
		uploadedFiles	: [], 
		buttons 		: [],
		filters			: [],
		entities		: { providers: {}, transporters: {} }
	}
	  
	constructor( 
		private commons			: CommonsService,
		private entityService	: EntityService	
  	) 					{}

	async init()				{
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= await this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.map			= { zoom: 10, lat: 39.639938, lng: 2.894679 };	

		this.pageInfo.items			= detailItems;
		// this.pageInfo.detailInfo.entities	= entities;
		Object.keys(entities).forEach(async item=>{
			this.pageInfo.entities[item] = await this.commons.translateRecursively(entities[item]);
		});

		this.pageInfo.entities["providers"].table	= {
			cols	: providerCols,
			options	: { rowExpander: true, scrollHeight: '30vh' },
			filters	: [
				{
					entity	: 'providers',
					name	: 'type',
					label	: '_TYPE',
					type	: 'multiple',
					field	: 'type',
					icon	: 'share-alt',
					items	: [
						{ label: '_AGREGATOR',		value: 'agregator' 		},
						{ label: '_TRANSPORTER',	value: 'transporter' 	}
					],
					selected: [ "agregator", "transporter" ]
				}
			],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{
						title	: "_INFO",				
						items	: [
							{ 	field	: 'name', 		label:'_NAME', 		editable: false, 	type: 'text'	},
							{ 	field	: 'location',	label:'_LOCATION', 	editable: false, 	type: 'text'	},
						]
					}
					,{
						items	: [
							{ 	field	: 'pax', 		label:'_PAX', 		editable: true, 	type: 'text' 	},
							{ 	field	: 'address', 	label:'_ADDRESS', 	editable: true, 	type: 'text' 	}
						]
					}
					,{
						items	: [
							{ 	field	: 'phone', 		label:'_PHONE',		editable: false,	type: 'text',	renderer: 'phone'	},
							{ 	field	: 'email', 		label:'_EMAIL', 	editable: false, 	type: 'text',	renderer: 'custom', 
							  	rendererFn: ($me, $type, $col, $items) => { 
									switch ($type) {
										case 'header'	: return {};
										case 'style'	: return { color: 'red' };
										case 'content'	: return $items[$col.field];
										case 'expander'	: return {
							                'color'			: 'green',
											'font-weight'	: 700
							            };
									}								
								}	
							}
						]
					}
				]
			}
		};

		this.pageInfo.entities["transporters"].table	= {
			cols	: providerCols,
			options	: { rowExpander: true, scrollHeight: '30vh' },
			filters	: [
				{
					entity	: 'providers',
					name	: 'type',
					label	: '_TYPE',
					type	: 'multiple',
					field	: 'type',
					icon	: 'share-alt',
					items	: [
						{ label: '_AGREGATOR',		value: 'agregator' 		},
						{ label: '_TRANSPORTER',	value: 'transporter' 	}
					],
					selected: [ "agregator", "transporter" ]
				}
			],
			form	: {
				showEmptyTitle	: true,
				items			: [
					{
						title	: "_INFO",				
						items	: [
							{ 	field	: 'name', 		label:'_NAME', 		editable: false, 	type: 'text'	},
							{ 	field	: 'location',	label:'_LOCATION', 	editable: false, 	type: 'text'	},
						]
					}
					,{
						items	: [
							{ 	field	: 'pax', 		label:'_PAX', 		editable: true, 	type: 'text' 	},
							{ 	field	: 'address', 	label:'_ADDRESS', 	editable: true, 	type: 'text' 	}
						]
					}
					,{
						items	: [
							{ 	field	: 'phone', 		label:'_PHONE',		editable: false,	type: 'text',	renderer: 'phone'	},
							{ 	field	: 'email', 		label:'_EMAIL', 	editable: false, 	type: 'text',	renderer: 'custom', 
							  	rendererFn: ($me, $type, $col, $items) => { 
									switch ($type) {
										case 'header'	: return {};
										case 'style'	: return { color: 'red' };
										case 'content'	: return $items[$col.field];
										case 'expander'	: return {
							                'color'			: 'green',
											'font-weight'	: 700
							            };
									}								
								}	
							}
						]
					}
				]
			}
		};

		// INFO
		this.pageInfo.entities["providers"].form = { 
			entities	: this.pageInfo.entities,
			form		: [
				{
					cols		: 4,
					padding		: "0.5rem",
					items		: [
						{ mandatory: true, 	field	: 'id', 			label:'_CODE',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'type',			label:'_TYPE',			type: 'text', 	editor: 'autocomplete', entityList	: 'providerList' 	},
						{ mandatory: true, 	field	: 'company', 		label:'_COMPANY',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'address', 		label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'municipality', 	label:'_MUNICIPALITY',	type: 'text',	editor: 'text',			editable	: true				}
						
					]
				}
				,{
					cols		: 4,
					padding		: "0.5rem",
					items		: [
						{ mandatory: true, 	field	: 'region', 		label:'_REGION',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'country',		label:'_COUNTRY',		type: 'text', 	editor: 'autocomplete', entityList	: 'countryList' 	},
						{ mandatory: true, 	field	: 'vat', 			label:'_VAT',			type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'phone', 			label:'_PHONE',			type: 'text',	editor: 'text',			editable	: false				},
						{ mandatory: true, 	field	: 'fax', 			label:'_FAX',			type: 'text',	editor: 'text',			editable	: false				}						
					]
				},
				,{
					cols		: 4,
					padding		: "0.5rem",
					items		: [
						{ mandatory: true, 	field	: 'email', 			label:'_COMPANY',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'web', 			label:'_ADDRESS',		type: 'text',	editor: 'text',			editable	: true				},
						{ mandatory: true, 	field	: 'satisfaction', 	label:'_SATISFACTION',	type: 'text', 	editor: 'autocomplete', entityList	: 'providerList' 	},
						{ mandatory: true, 	field	: 'homologated',	label:'_HOMOLOGATED', 	type: 'text', 	editor: 'toggle',		info		: true				}
					]
				}
			]
		}		

		this.pageInfo.entities["providers"].form.info	= {
				id				: 1,
				active			: true,
				type			: 'transporter',			
				company			: "Autocares Comas",
				address			: "Carrer Setze de Juliol, 25",
				municipality	: "Palma de Mallorca",
				region			: "Illes Balears",
				country			: "Spain",
				vat				: "21",
				phone			: "971430515",
				fax				: "971430516",
				contact			: "Juan Ruíx",
				email			: "info@autocarescomas.com",
				web				: "www.autocarescomas.com",
				satisfaction	: 8,
				homologated		: true
		}
	}

	async loadEntities()		{ 
		await this.load("providers");
		await this.load("transporters");
	}
  	async ngOnInit()			{
		await this.init();
		await this.loadEntities();
		this.selectProvider(this.currentProvider);
	}
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}	
	action($type,$item)	{
		switch($type){
			case "toggle_provider"	:	this.selectProvider($item); break;			
			case "button"			:	switch($item.name){		
											case "new_provider"	: 	this.emitter.emit({ action: 'new_provider' });						break;
											case "reload"		: 	this.load("providers"); this.selectProvider(this.currentProvider);	break;
											case "edit"			:	this.emitter.emit({ action: 'view_provider', provider: $item });	break;			
											case "export"		: 	this.commons.generateToast("Button pressed","Export","info");		break;
											case "import"		: 	this.commons.generateToast("Button pressed","Import","info");		break;
										}
										break;
		}
	}
	selectProvider($item){
		if(undefined==$item){ this.pageInfo.itemSelected = false; return false; }
		this.pageInfo.entities["providers"].map(item=>{item.selected=item.id==$item.id?(item.selected?false:true):false;});
		this.pageInfo.itemSelected 	= this.pageInfo.entities["providers"].some(item=>item.selected);
		if(this.pageInfo.itemSelected){ 
			this.emitter.emit({'action':'provider_selected','item': this.pageInfo.entities["providers"].find(item=>item.selected) });
		} else {
			this.emitter.emit({'action':'provider_unselected'});
		}
	}
	getFilteredEntity($entity){
		switch($entity){
			case "providers"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
			case "transporters"	: 	return ( this.pageInfo.entities[$entity].data || [] ).filter(item=>{
										return true;
										// let isSelected 	= this.pageInfo.areas.some(areaItem=>areaItem.name==item.area);
										// item.view 		= isSelected;
										// item.color		= "red";
										// return isSelected;
									});
		}
	}
	async load($entity){
		let response = {};
		switch($entity){
			case 'providers' :
				response 								= 	await Promise.resolve(this.entityService.getRequest($entity));
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;
			case 'transporters' :
				response 								= 	await Promise.resolve(this.entityService.getRequest($entity));
				this.pageInfo.entities[$entity].data	= 	response["data"];				
				break;
		}
	}
}
