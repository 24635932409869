import { TestComponent 						} from './demo/view/test/test.component';
import { MapPricingComponent 				} from './demo/view/map_pricing/mappricing.component';
import { LandingComponent 					} from './demo/view/landing/landing.component';
import { Routes, RouterModule				} from '@angular/router';
import { ModuleWithProviders				} from '@angular/core';
import { CheckinsComponent 					} from './demo/view/checkins/checkins.component';
import { MapComponent 						} from './demo/view/map/map.component';
import { GroupsComponent 					} from './demo/view/groups/groups.component';
import { TransportsComponent 				} from './demo/view/transports/transports.component';
import { TransportsCalendarsComponent   	} from './demo/view/transportsCalendars/transportsCalendars.component';
import { HotelsComponent 					} from './demo/view/entities/hotels/hotels.component';
import { FlightsComponent 					} from './demo/view/entities/flights/flights.component';
import { VehiclesComponent 					} from './demo/view/entities/transports/vehicles.component';
import { TransportCompaniesComponent 		} from './demo/view/entities/transports/companies.component';
import { DriversComponent 					} from './demo/view/entities/transports/drivers.component';
import { MapCheckDistancesComponent 		} from './demo/view/mapconfig/mapcheckdistances/mapcheckdistances.component';
import { AuthGuard 							} from './demo/service/auth/auth.guard';
import { StaffComponent 					} from './demo/view/entities/staff/staff.component';
import { ProfileComponent 					} from './demo/view/profile/profile.component';
import { ProvidersComponent					} from './demo/view/entities/providers/providers.component';
import { TourContainerComponent        		} from './demo/view/tours/main/component';
import { ChartBookingsComponent         	} from './demo/view/statistics/bookings/bookings.component';
import { PickupsZonesComponent        		} from './demo/view/mapconfig/pickupsZones/pickupszones.component';
import { AdminContainerComponent 			} from './demo/view/admin/main/component';
import { ResellerContainerComponent 		} from './demo/view/reseller/main/component';
import { CompanyContainerComponent 			} from './demo/view/company/main/component';
import { CompanyTransporterComponent 		} from './demo/view/company/transporters/component';
import { CompanyProvidersComponent 			} from './demo/view/company/providers/component';
import { InvoicingCompanyComponent 			} from './demo/view/invoicing/company/component';
import { CompanyDriverComponent       		} from './demo/view/company/drivers/component';
import { DashboardComponent             	} from './demo/view/dashboard/component';
import { DashboardAggregatorComponent 		} from './demo/view/dashboard_aggregator/component';
import { CreateDestinationComponent     	} from './demo/view/destinations/create/create-destination.component';
import { CreateCompanyComponent         	} from './demo/view/company/create/create-company.component';
import { MapGeneratorDestinationsComponent 	} from './demo/view/mapconfig/generatorDestinations/mapGeneratorDestinations.component';
import { DashboardResellerComponent     	} from './demo/view/reseller/dashboard/component';
import { ManageTransportsComponent      	} from './demo/view/company/manage-transports/manage-transports.component';
import { ManageZonesComponent           	} from './demo/view/company/zones/manage-zones.component';
import { ManageListPricesComponent      	} from './demo/view/company/manage-list-prices/manage-list-prices.component';
import { ImporterComponent                  } from './demo/view/importer/importer.component';
import { BookingsComponent                  } from './demo/view/bookings/bookings.component';
import { ExternalsComponent 				} from './demo/view/externals/externals.component';
import { TabletemplateComponent             } from './demo/view/tabletemplate/tabletemplate.component';
import { Checkins2Component } from './demo/view/checkins2/checkins2.component';

export const routes: Routes = [
	// {path: '',                  	        component: DashboardDemoComponent			},
    // {path: '',                  			component: CheckinsComponent 				},
    // {path: '',                  			component: DashboardComponent 				},
    // {path: '',                  			component: TransportsComponent              },
    
    {path: '',                  			component: LandingComponent 				},
	{path: 'landing',                  		component: LandingComponent 				},
    {path: 'login',                  		component: LandingComponent 				},
    
    // {path: '', 					            component: BookingsComponent,                   canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN' ], _module : '_BOOKINGS'    }},
    
    {   
        path        : 'checkins2', 						
        component   : CheckinsComponent,                   
        canActivate : [ AuthGuard ],	
        data        : { 
            roles:	[ 
                'ROLE_ADMIN', 
                'ROLE_MAP_AREAS' 
            ], 
            module : '_WELCOME'    
        }
    },

    {   
        path        : 'checkins', 						
        component   : Checkins2Component,                  
        canActivate : [ AuthGuard ],	
        data        : { 
            roles:	[ 
                'ROLE_ADMIN', 
                'ROLE_MAP_AREAS' 
            ], 
            module : '_WELCOME'    
        }
    },

    {path: 'map', 						    component: MapComponent				        },

    // BOOKINGS
    {path: 'bookings', 					    component: BookingsComponent,                   canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN' ], _module : '_BOOKINGS'    }},
    {path: 'importer', 						component: ImporterComponent,				    canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN', 'ROLE_DMC_IMPORTER' ], _module : '_BOOKINGS'    }},
    {path: 'table', 						component: TabletemplateComponent,			    canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_DMC_ADMIN', 'ROLE_DMC_IMPORTER' ], _module : '_BOOKINGS'    }},

    // TRANSPORT
    {path: 'transport_groups', 	    		component: GroupsComponent,					    canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_MAP_AREAS' ], module : '_TRANSPORT'    }},
    {path: 'transport_compositor',  		component: TransportsComponent				},
    {path: 'transport_calendars',			component: TransportsCalendarsComponent		},
    {path: 'transport_mappricing',        	component: MapPricingComponent              },
    {path: 'list_prices',               	component: ManageListPricesComponent        },
   
    {path: 'externals',  		            component: ExternalsComponent			    },
   
    {path: 'hotels',		 				component: HotelsComponent					},
    {path: 'flights', 						component: FlightsComponent					},
    {path: 'vehicles', 			            component: VehiclesComponent			    },
    {path: 'transport_companies',			component: TransportCompaniesComponent		},
    {path: 'drivers',	 		           	component: DriversComponent		            },
    {path: 'manage_zones',  	       		component: ManageZonesComponent, 			    canActivate: 	[ AuthGuard ],	data : { roles:	[ 'ROLE_ADMIN', 'ROLE_MAP_AREAS','ROLE_DMC_ADMIN' ],  modules: [ 'transport_service' ] }},
    {path: 'mapdistances',      		   	component: MapCheckDistancesComponent       },
    {path: 'landing',		            	component: LandingComponent                	},
    {path: 'profile',       		        component: ProfileComponent                 },
    {path: 'providers',         		    component: ProvidersComponent               },
    {path: 'test',                  		component: TestComponent                    },
    {path: 'tour_main',         		   	component: TourContainerComponent,	    	    canActivate:	[ AuthGuard ], 	data : { roles : [ 'ROLE_ADMIN' ]}              },
    {path: 'staff',                 		component: StaffComponent,      			    canActivate:	[ AuthGuard ], 	data : { roles : [ 'ROLE_ADMIN' ]}				},
    {path: 'chart_bookings',    			component: ChartBookingsComponent,      	    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
    {path: 'pickupzones',       			component: PickupsZonesComponent,       	    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},

	{path: 'invoicing_company',		        component: InvoicingCompanyComponent,	        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
    
    {path: 'admin_main',       			    component: AdminContainerComponent,		        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
    {path: 'reseller_main',       			component: ResellerContainerComponent,		    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN','ROLE_RESELLER_ADMIN' ]}				},

    {path: 'company_main',       			component: CompanyContainerComponent,		    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ]}				},
    {path: 'company_providers',  			component: CompanyProvidersComponent,		    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
	{path: 'company_transporters',  		component: CompanyTransporterComponent,	        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
	// {path: 'company_fleet',  				component: CompanyFleetComponent,	    	canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
	{path: 'company_fleet',  				component: ManageTransportsComponent,	        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
    {path: 'company_drivers',  				component: CompanyDriverComponent,	    	    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ]}				},
    
    {path: 'dashboard',  				    component: DashboardComponent,	    	        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN','ROLE_DMC_ADMIN' ]}				},
    {path: 'dashboard_aggregator',		    component: DashboardAggregatorComponent,	    canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_AGREGATOR_ADMIN' ]}	},
    {path: 'dashboard_reseller',		    component: DashboardResellerComponent,	        canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_RESELLER_ADMIN' ]}		},

    {path: 'create_destination',            component: CreateDestinationComponent,          canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN' ,'ROLE_RESELLER_OPERATOR' ]}              },
    {path: 'create_company',                component: CreateCompanyComponent,              canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN', 'ROLE_RESELLER' ]}              },
    {path: 'map_generate_destination',      component: MapGeneratorDestinationsComponent,   canActivate:	[ AuthGuard ],	data : { roles : [ 'ROLE_ADMIN', ]}              }
    

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload' });
